import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectedRowsMultiDTO} from '../VociMenu/maskDetails/maskDetails.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';


@Component({
    selector: 'app-dialogcombodto',
    styleUrls: ['comboDTO-css.css'],
    templateUrl: 'dialog-content-comboDTO.html'
})

export class DialogComboDTOComponent {
    ELEMENT_DATA: Array<object> = new Array<object>();
    arrayToObject: { [index: string]: { content: string } } = {};
    dataSource: MatTableDataSource<object>;
    selection: SelectionModel<object>;
    displayedColumns: Array<string> = new Array<string>();
    squareColor: string;
    dataResult: Array<string> = new Array<string>();
    dataToSave: Array<any> = new Array();
    additionalFieldComboDTOValue: any = {};
    fieldName: string;
    selectField: string;
    find = false;
    isSelected = false;

    constructor(public dialogRef: MatDialogRef<DialogComboDTOComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService) {
        this.fieldName = data.fieldName;
        this.selectField = data.selectField;
        this.additionalFieldComboDTOValue = data.additionalFieldComboDTO;
        data.additionalFieldComboDTO.forEach(element => {
            this.displayedColumns = new Array<string>();
            this.arrayToObject = {};
            this.displayedColumns.push(element.id);
            this.arrayToObject[element.id] = element.value;
            this.ELEMENT_DATA.push(this.arrayToObject);
        });
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.squareColor = this.apiService.squareColor;
        this.selection = new SelectionModel<object>(true, []);
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    deleteField(): void{
        this.dialogRef.close({dataResult: 'delete'});
    }
    selectedRow(element): void {
        this.isSelected = true;
        this.dialogRef.close({dataResult: element});
    }

    closeDialog(): void {
        this.dialogRef.close({dataResult: false});
    }
}
