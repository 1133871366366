<div style="text-align: center; color: black">
    <h2>Aggiungi tema</h2>
    <hr>
    <br>
    <div style="width: 100%; display: flex; justify-content: center;">
        <div style = "margin: 5%;">
            <h2>Colore primario</h2>
            <color-chrome [color]="this.primaryColor"
                          (onChangeComplete)="handleChangePrimary($event)"></color-chrome>
        </div>
        <div style = "margin: 5%;">
            <h2>Colore secondario</h2>
            <color-chrome [color]="this.secondaryColor"
                          (onChangeComplete)="handleChangeSecondary($event)"></color-chrome>
        </div>
    </div>
    <mat-form-field style = "width: 80%;">
        <mat-label>Descrizione</mat-label>
        <input matInput required [(ngModel)]="this.description">
    </mat-form-field>
    <div style="width: 100%">
        <button mat-stroked-button (click)="addTheme()">Aggiungi</button>
        <button title="Chiudi" (click)="closeDialog()" mat-stroked-button color="warn">
            Chiudi
        </button>
    </div>

</div>
