<div class="center">
    <mat-card class="cardcomponentMasks1" style="width: 30%" id = "style-4">
        <mat-card-content>

            <!--hai cliccato la maschera con id: {{this.id}}-->
            <div style="display: flex" class="bigbox">
                <div style="width: 100%" class="tree">

                        <dx-tree-view *ngIf="this.openId"
                                      #treeview
                                      style="text-align: left !important;"
                                      [rootValue]="this.apiService.parentFolderId"
                                      [searchEnabled]="true"
                                      dataStructure="plain"
                                      searchMode="contains"
                                      displayExpr="name"
                                      [createChildren]="createChildren"
                                      hasItemsExpr="hasChilds"
                                      [searchEditorOptions]="{placeholder: 'Cerca...'}"
                                      (onItemClick)="folderClick($event)"
                                      itemTemplate="itemTemplate"
                                      (onInitialized)="saveViewInstance($event)"

                        >
                            <div *dxTemplate="let itemObj of 'itemTemplate'">
                                {{itemObj.name}}
                                <button style="float: right; margin-top: -10px" mat-icon-button
                                        [matMenuTriggerFor]="afterMenu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #afterMenu="matMenu" xPosition="after">
                                    <div style="text-align: center;"><h3 style="margin-bottom: 0px;">
                                        <b>{{itemObj.name}}</b></h3></div>
                                    <hr>
                                  <button mat-menu-item (click) = "this.openAddFolderDialog(itemObj.id)">
                                    <mat-icon>add</mat-icon>
                                    <span>Nuovo fascicolo</span></button>
                                  <button mat-menu-item (click) = "this.openEditFolderDialog(itemObj.id, itemObj.name)">
                                    <mat-icon>create</mat-icon>
                                    <span>Rinomina</span></button>
                                  <button mat-menu-item (click) = "this.deleteFolder(itemObj.id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Elimina</span></button>
                                  <button mat-menu-item (click) = "openInfoDialog(itemObj.id, itemObj.authorCompleteName, itemObj.name)">
                                    <mat-icon>info</mat-icon>
                                    <span>Info</span></button>
                                </mat-menu>
                            </div>
                        </dx-tree-view>
                        <dx-tree-view *ngIf="!this.openId"
                                      #treeview
                                      style="text-align: left !important;"
                                      [rootValue]="0"
                                      [searchEnabled]="true"
                                      dataStructure="plain"
                                      searchMode="contains"
                                      displayExpr="name"
                                      [createChildren]="createChildren"
                                      hasItemsExpr="hasChilds"
                                      [searchEditorOptions]="{placeholder: 'Cerca...'}"
                                      (onItemClick)="folderClick($event)"
                                      itemTemplate="itemTemplate"

                        >
                            <div *dxTemplate="let itemObj of 'itemTemplate'">
                                {{itemObj.name}}
                                <button title = "{{itemObj.name}}" style="float: right; margin-top: -10px" mat-icon-button
                                        [matMenuTriggerFor]="afterMenu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #afterMenu="matMenu" xPosition="after">
                                    <div style="text-align: center;"><h3 style="margin-bottom: 0px;">
                                        <b>{{itemObj.name}}</b></h3></div>
                                    <hr>
                                    <button mat-menu-item (click) = "this.openAddFolderDialog(itemObj.id)">
                                        <mat-icon>add</mat-icon>
                                        <span>Nuovo fascicolo</span></button>
                                    <button mat-menu-item (click) = "this.openEditFolderDialog(itemObj.id, itemObj.name)">
                                        <mat-icon>create</mat-icon>
                                        <span>Rinomina</span></button>
                                    <button mat-menu-item (click) = "this.deleteFolder(itemObj.id)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Elimina</span></button>
                                  <button mat-menu-item (click) = "openInfoDialog(itemObj.id, itemObj.authorCompleteName, itemObj.name)">
                                    <mat-icon>info</mat-icon>
                                    <span>Info</span></button>
                                    <!--<button mat-menu-item>
                                        <mat-icon>lock</mat-icon>
                                        <span>Permessi</span></button>-->
                                   <!-- <button mat-menu-item>
                                        <mat-icon>share</mat-icon>
                                        <span>Condividi</span></button>-->
                                    <!--<button mat-menu-item>
                                        <mat-icon>integration_instructions</mat-icon>
                                        <span>Modalità di profilazione</span></button>-->
                                    <!--<button mat-menu-item>
                                        <mat-icon>backup</mat-icon>
                                        <span>Sincronizzazione con ARXdrive</span></button>-->
                                    <!--<button mat-menu-item>
                                        <mat-icon>update</mat-icon>
                                        <span>Aggiorna elenco</span></button>-->

                                </mat-menu>
                            </div>
                        </dx-tree-view>
                        ​<!--(onItemClick)="onItemClick($event)"-->
                </div>



            </div>


        </mat-card-content>

    </mat-card>
    <mat-card class="cardcomponentMasks1" style="width: 68%" id = "style-4">
        <div style="width: 100%" class="tree">

            <div style="display: flex; justify-content: center">
                <mat-progress-bar *ngIf="isLoading"
                                  mode="buffer"
                                  class="tree-progress-bar"></mat-progress-bar>
            </div>
            <ng-container *ngIf="showTable && isLoading == false">
                <h1 style="color: {{this.dashColor}};">{{this.clickedFolderName}}</h1>
                <div class="example-container mat-elevation-z8" id = "style-4">

                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container *ngFor="let colonna of displayedColumns" matColumnDef="{{colonna}}">
                            <ng-container>

                                <ng-container
                                        *ngIf="colonna != 'RELAZIONI,Relazioni' && colonna != '-1,Azioni' && colonna != 'INOUT,Origine' && colonna != 'NOTE,Note' && colonna != 'WORKFLOW,Workflow' && colonna != 'ALLEGATI,Allegati' && colonna != 'STATO,Stato' && colonna != 'FORMATO,Formato' && colonna != 'REVISIONE,Revisione' && colonna != 'DOCNUMBER,System ID'">
                                    <th mat-header-cell style="background-color: white;"
                                        *matHeaderCellDef>{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</th>
                                </ng-container>
                                <ng-container
                                        *ngIf="colonna == 'RELAZIONI,Relazioni' || colonna == '-1,Azioni' || colonna == 'INOUT,Origine' || colonna == 'NOTE,Note' || colonna == 'WORKFLOW,Workflow' || colonna == 'ALLEGATI,Allegati' || colonna == 'STATO,Stato' || colonna == 'FORMATO,Formato' || colonna == 'REVISIONE,Revisione' || colonna == 'DOCNUMBER,System ID'">
                                    <th mat-header-cell style="width: 80px !important; background-color: white;"
                                        *matHeaderCellDef><ng-container *ngIf="colonna != 'FORMATO,Formato'">{{(colonna.substr(colonna.indexOf(',') + 1, colonna.length))}}</ng-container><ng-container *ngIf="colonna == 'FORMATO,Formato'">Documento</ng-container></th>
                                </ng-container>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="(colonna.startsWith('-1'))">
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Azioni del profilo">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item
                                                    (click)="goToProfile(element['DOCNUMBER,System ID'])">
                                                <mat-icon>subject</mat-icon>
                                                <span>Profilo</span>
                                            </button>
                                            <button mat-menu-item
                                                    (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                                                <mat-icon>launch</mat-icon>
                                                <span>Apri</span>
                                            </button>
                                            <button mat-menu-item
                                                    (click)="exportToCSV(element['DOCNUMBER,System ID'])">
                                                <mat-icon>call_made</mat-icon>
                                                <span>Esporta csv</span>
                                            </button>
                                            <button mat-menu-item [matMenuTriggerFor]="modifica"
                                                    style="color: black">
                                                <mat-icon>create</mat-icon>
                                                <span>Modifica documento</span>
                                            </button>
                                            <mat-menu #modifica="matMenu">
                                                <button mat-menu-item
                                                        (click)="openEditFileDialog(element['DOCNUMBER,System ID'])"
                                                        style="color: black">
                                                    <mat-icon>attach_file</mat-icon>
                                                    <span>Da file</span>
                                                </button>
                                                <button mat-menu-item
                                                        (click)="openEditRevisionDialog(element['DOCNUMBER,System ID'])">
                                                    <mat-icon>skip_previous</mat-icon>
                                                    <span>Da revisionare</span>
                                                </button>
                                            </mat-menu>
                                            <button mat-menu-item
                                                    (click)="openShowAttachmentsDialog(element['DOCNUMBER,System ID'])">
                                                <mat-icon>attach_file</mat-icon>
                                                <span>Elenco allegati</span>
                                            </button>
                                            <ng-container *ngIf="this.apiService.IsDocuSignLogged">
                                                <hr>
                                                <button mat-menu-item (click)="signDoc(element['DOCNUMBER,System ID'])">
                                                    <mat-icon>send</mat-icon>
                                                    <span>Invia con DocuSign</span>
                                                </button>
                                            </ng-container>
                                        </mat-menu>

                                    </ng-container>
                                    <ng-container
                                            *ngIf="(colonna.startsWith('STATO'))">
                                        <button mat-icon-button>
                                            <mat-icon title="{{element[colonna]}}">
                                                {{this.apiService.getState(element[colonna])}}
                                            </mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="(colonna.startsWith('DOCNUMBER'))">
                                        <button mat-button (click)="goToProfile(element['DOCNUMBER,System ID'])">
                                            Profilo
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="(colonna.startsWith('INOUT'))">
                                        <ng-container *ngIf="element[colonna] == '0'">
                                            <mat-icon title="Uscita">call_made</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="element[colonna] == '1'">
                                            <mat-icon title="Entrata">call_received</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="element[colonna] == '2'">
                                            <mat-icon title="Interno">swap_horizontal</mat-icon>
                                        </ng-container>

                                    </ng-container>
                                    <ng-container
                                            *ngIf="(colonna.startsWith('ALLEGATI'))">
                                        <ng-container *ngIf="element[colonna] == '1'">
                                            <button mat-button>
                                                <mat-icon>attachment</mat-icon>
                                            </button>
                                        </ng-container>

                                    </ng-container>
                                    <ng-container
                                            *ngIf="((colonna.substr(colonna.indexOf(',')+1, colonna.length)) == 'Workflow')">
                                        <ng-container *ngIf="(element[colonna]) == 1">
                                            <button mat-button
                                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                                                <mat-icon title="Attivo">engineering</mat-icon>
                                            </button>
                                            <br>
                                        </ng-container>
                                        <ng-container *ngIf="(element[colonna]) == 2">
                                            <button mat-button
                                                    (click)="goToProcess(element['DOCNUMBER,System ID'])">
                                                <mat-icon title="In attesa">more_horiz</mat-icon>
                                            </button>
                                            <br>
                                        </ng-container>
                                        <ng-container *ngIf="(element[colonna]) == 0">
                                            <button mat-button
                                                    (click)="goToProcess(element['DOCNUMBER,System ID'])"><b>Concluso</b>
                                                <mat-icon title="Concluso">done_all</mat-icon>
                                            </button>
                                            <br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="((colonna.substr(colonna.indexOf(',')+1, colonna.length)) == 'Formato' && element[colonna] != '')">
                                        <button mat-button (click)="downloadDoc(element['DOCNUMBER,System ID'])">
                                            <ng-container
                                                    *ngIf="element[colonna]=='pdf'">
                                                <mat-icon>picture_as_pdf</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='PDF'">
                                                <mat-icon>picture_as_pdf</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='docx'">
                                                <mat-icon>description</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='doc'">
                                                <mat-icon>article</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='xlsx'">
                                                <mat-icon>ballot</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='csv'">
                                                <mat-icon>dashboard</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='msg'">
                                                <mat-icon>email</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='zip'">
                                                <mat-icon>topic</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]=='jpg'">
                                                <mat-icon>insert_photo</mat-icon>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="element[colonna]!='PDF' && element[colonna]!='zip' && element[colonna]!='jpg' && element[colonna]!='msg' && element[colonna]!='docx' && element[colonna]!='pdf' && element[colonna]!='doc' && element[colonna]!='xlsx' && element[colonna]!='csv'">
                                                {{element[colonna]}}
                                            </ng-container>
                                        </button>

                                    </ng-container>
                                    <ng-container
                                            *ngIf="colonna.startsWith('DATA')">
                                        {{this.TransformDataFormat(element[colonna])}}
                                    </ng-container>
                                    <ng-container
                                            *ngIf="colonna.startsWith('RELAZIONI')">
                                        <button *ngIf="element[colonna] == '1'" mat-menu-item>
                                            <mat-icon>account_tree</mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="colonna.startsWith('ALLEGATI') == false && colonna.startsWith('RELAZIONI') == false && colonna.startsWith('STATO') == false && colonna.startsWith('-1') == false && colonna.startsWith('INOUT') == false && colonna.startsWith('DATA') == false && colonna.startsWith('DOCNUMBER') == false  && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Workflow' && (colonna.substr(colonna.indexOf(',')+1, colonna.length)) != 'Formato'">{{element[colonna]}} </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                </div>

            </ng-container>
            <ng-container *ngIf="!showTable && isLoading == false"><h1 style="color: {{this.dashColor}};">
                Nessun elemento da visualizzare</h1></ng-container>
        </div>
    </mat-card>
</div>
