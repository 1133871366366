<div>
  <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div style=" width: 95%; text-align: center;">
    <div style="display:block;">
        <mat-form-field style="width: 65%; padding-right: 5%; height: 10%;" id="esito"
                        appearance="standard">
            <mat-label>Azione</mat-label>
            <mat-select name="esito" (selectionChange)="exitSelected($event.value)">
                <mat-option *ngFor="let esito of this.exitCodes" [value]="esito.value">
                    {{esito.value}}
                </mat-option>
            </mat-select>

        </mat-form-field>
        <button mat-button (click)="openCommentDialog()" style=" width: 25%; height: 10%;">
            Note <mat-icon svgIcon="CommentIcon" style="margin-right: 8px"></mat-icon>
        </button>
        <!-- <mat-form-field style="width: 50%; height: auto; " appearance="fill">
             <mat-label>Commento</mat-label>
             <textarea matInput rows="5"></textarea>
         </mat-form-field>-->
    </div>
    <textarea id="style-4" matInput autosize style="resize: none;width:95%;" disabled
              [value]="this.comment"> </textarea><br>
    <div style="width: 100%; height: auto; " appearance="fill">
       <!-- <button  mat-button (click)="onNoClick()" style="text-align: center">Annulla</button>-->
        <button mat-stroked-button (click) = "this.exitTask(this.comment)" style=" width: fit-content;">Invia</button>
    </div>

</div>
