import {Component, OnInit, Renderer2} from '@angular/core';
import {ApiService} from './api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, interval} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from './bodyFieldToSave';
import {DatePipe} from '@angular/common';
import {$locationShim, $locationShimProvider} from '@angular/common/upgrade';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  dashColor: string;

  backColor: string;
  squareColor: string;
  cardColor: string;
  sub: any;
  first = true;
  todayDate = new Date();
  OAUTHCODE: string;
  code: string;
  anagraDocN: string;
  isFornitore = false;


  constructor(private route: ActivatedRoute, private router: Router, public apiService: ApiService,
              private renderer: Renderer2, private snackBar: MatSnackBar, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
    if (window.location.pathname.startsWith('/anagrafiche')) {

      this.isFornitore = true;
      this.anagraDocN = window.location.pathname.split(';')[1];

    }

    /*this.activatedRoute.queryParams.subscribe(params => { http://localhost:4200/anagrafiche;13471
        let code = params['code'];

        // console.log(code);// OUTPUT 1534
    });*/
    this.apiService.getStates().subscribe(states => {
      this.apiService.allStates = states;
    });
    this.apiService.getTheme().subscribe(data => {
      this.apiService.getUserInfo().subscribe(info => {
        this.apiService.userInfo = info;
        if (this.apiService.isLoggedIn()) {
          this.apiService.getBetUserSetting().subscribe(currentSetting => {
            if (currentSetting.error) {
              if (currentSetting.error === 'No user settings') {
                this.apiService.setBetUserSetting(data[0].id).subscribe(us => {
                  this.dashColor = data[0].dash_color;
                  this.backColor = data[0].back_color;
                  this.squareColor = data[0].square_color;
                  this.cardColor = data[0].card_color;
                  this.apiService.dashColor = this.dashColor;
                  this.apiService.backColor = this.backColor;
                  this.apiService.squareColor = this.squareColor;
                  this.apiService.cardColor = this.cardColor;
                  this.renderer.setStyle(document.body, 'background', this.backColor);
                });
              } else {
                this.apiService.updateBetUserSetting(data[0].id).subscribe(us => {
                  this.dashColor = data[0].dash_color;
                  this.backColor = data[0].back_color;
                  this.squareColor = data[0].square_color;
                  this.cardColor = data[0].card_color;
                  this.apiService.dashColor = this.dashColor;
                  this.apiService.backColor = this.backColor;
                  this.apiService.squareColor = this.squareColor;
                  this.apiService.cardColor = this.cardColor;
                  this.renderer.setStyle(document.body, 'background', this.backColor);
                });
              }
            } else {
              this.dashColor = currentSetting[0].dash_color;
              this.backColor = currentSetting[0].back_color;
              this.squareColor = currentSetting[0].square_color;
              this.cardColor = currentSetting[0].card_color;
              this.apiService.dashColor = this.dashColor;
              this.apiService.backColor = this.backColor;
              this.apiService.squareColor = this.squareColor;
              this.apiService.cardColor = this.cardColor;
              this.renderer.setStyle(document.body, 'background', this.backColor);
            }
          });
        } else {
          this.dashColor = data[0].dash_color;
          this.backColor = data[0].back_color;
          this.squareColor = data[0].square_color;
          this.cardColor = data[0].card_color;
          this.apiService.dashColor = this.dashColor;
          this.apiService.backColor = this.backColor;
          this.apiService.squareColor = this.squareColor;
          this.apiService.cardColor = this.cardColor;
          this.renderer.setStyle(document.body, 'background', this.backColor);
        }
      });

    });
    this.sub = interval(2000)
      .subscribe((val) => {
        this.todayDate = new Date();
        // // console.log(localStorage.getItem('expires'));
        let expired = new Date(localStorage.getItem('expires'));
        if (this.todayDate > expired) {
          this.router.navigate(['login', {expired: true}]);
          this.apiService.setLoggedOut();
        }
      });
    if (this.apiService.isLoggedIn()) {
      this.sub = interval(10000)
        .subscribe((val) => {
          this.apiService.postTasksId().subscribe(value => {
            if (this.first === true) {
              this.apiService.taskNumber = value.data.length;
              this.first = false;
            }
            // // console.log('Task number ->' + this.apiService.taskNumber);
            // // console.log('Risultato chiamata -> ' + value.data.length);
            if (value.data.length > 0) {
              if (value.data.length > this.apiService.taskNumber) {
                // // console.log('è entrato');
                this.snackBar.open('Un nuovo task è stato avviato', null, {
                  duration: 2500,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-info-bg']
                });
                this.apiService.taskNumber = value.data.length;
              } else {
                this.apiService.taskNumber = value.data.length;
              }
            } else {
              // // console.log(value.data.length);
              this.apiService.taskNumber = value.data.length;
            }
          });
        });
    }
  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy h:mm:ss', '', 'en-GB');
  }

  ngOnInit(): void {
    if (localStorage.getItem('waitforcodedocu') === 'true') {
      this.activatedRoute.queryParams.subscribe(params => {
        this.code = params['code'];
        if (this.code !== undefined) {
          this.apiService.OAUTHCODE = this.code;
          localStorage.setItem('waitforcodedocu', 'false');
          if (localStorage.getItem('loggedIn')) {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['login']);
          }
        }
      });
    } else if (localStorage.getItem('waitforcodegoogle') === 'true') {
      this.activatedRoute.queryParams.subscribe(params => {
        this.code = params.code;
        if (this.code !== undefined) {
          this.apiService.AUTHCODE = this.code;
          localStorage.setItem('waitforcodegoogle', 'false');
          if (localStorage.getItem('loggedIn')) {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['login']);
          }
        }
      });

      /*if (localStorage.getItem('loggedIn')) {
          this.router.navigate(['dashboard']);
      } else {
          this.router.navigate(['login']);
      }*/
    } else {
      if (this.isFornitore) {
        const credentialUser: JSON = {
          languageCultureName: localStorage.getItem('lang'),
          username: 'fornitoreService',
          password: '@#F0rn1t0r32023!#@',
          clientId: this.apiService.CLIENT_ID,
          clientSecret: this.apiService.CLIENT_SECRET
        } as unknown as JSON;
        this.apiService.login(credentialUser).subscribe(data => {
            this.apiService.isFornitore = true;
            if (!this.anagraDocN.includes('=')) {
              this.apiService.setLoggedInInformation(data);
              localStorage.setItem('username', 'fornitoreService');
              localStorage.setItem('expires', data.expires);
              this.router.navigate(['dashboard/dashnav/profile', {
                idDocument: this.anagraDocN,
                taskId: '0'
              }]);
            }

          },
          // tslint:disable-next-line:no-shadowed-variable
          error => {
            this.snackBar.open(error.error.userMessage, null, {
              duration: 2000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });

      } else {
        if (localStorage.getItem('loggedIn')) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['login']);
        }
      }

    }
  }
}
