<!--<mat-card-content>
  <mat-grid-list  cols="3" rows rowHeight="1:1">
    <mat-grid-tile><p>Benvenuto, {{this.nomeUtente}}</p></mat-grid-tile>
    <mat-grid-tile><p>ei</p></mat-grid-tile>
    <mat-grid-tile>
    <p>
        Home page: Benvenuto {{this.nomeUtente}}
    </p>
    </mat-grid-tile>

  </mat-grid-list>
</mat-card-content>

-->

<!-- !!!!!!!!!!!!!!!!!!!TASK!!!!!!!!!!!!!!!!!!!!! -->
<div class="center">
    <mat-card class="cardcomponentMasks" id="style-4">

        <h3 class="">
            <mat-card-title style="color:{{this.dashColor}};"><p>Task: </p>
                <h4 style="text-align: center;">Numero
                    task: {{this.nTask}}</h4>
            </mat-card-title>
            <button mat-icon-button title="Aggiorna" (click)="this.refreshTasks()" style = "float: right; color: {{this.apiService.dashColor}}">
                <mat-icon>autorenew</mat-icon>
            </button>
        </h3>
        <mat-form-field style="width: 80%; text-align: left">
            <mat-label>Filtra</mat-label>
            <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
            <button mat-button matSuffix mat-icon-button aria-label="Filtri" style="color: {{this.dashColor}}"
                    (click)="openFilterDialog()">
                <mat-icon>filter_list</mat-icon>
            </button>
            <button mat-button *ngIf="this.filter" style="color: {{this.dashColor}}" matSuffix mat-icon-button
                    aria-label="Cancella" (click)="this.filter=''; this.filterChange()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>


        <hr class="riga" style="color:{{this.dashColor}};">
        <br>
        <cdk-virtual-scroll-viewport *ngIf="nTask == 0" style="margin-left: 3%; margin-right: 3%; padding-bottom: 70%;"
                                     id="style-4"
                                     itemSize="6"
                                     class="cdk">
            <h1 style="text-align: center; color: {{this.dashColor}}">NESSUN TASK TROVATO</h1>
        </cdk-virtual-scroll-viewport>
        <mat-card-content *ngIf="nTask != 0 && this.typeOfTaskView == 'panel'">
            <div style="width: 100%;">
                <ng-container class="cdkcontains">
                    <div *ngFor="let task of this.taskList">
                        <mat-selection-list #tasks [multiple]="false">
                            <mat-accordion class="example-headers-align"
                                           *ngIf="task.disabledForFilter == false && task.disabledForPriority == false &&
                                                task.disabledForExpired == false && task.disabledForRead == false">
                                <mat-expansion-panel [expanded]="allTasksOpen" style="display: contents; "
                                                     (opened)="panelOpenState = true"
                                                     (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header
                                            style=" height: fit-content;text-align: left!important">
                                        <mat-panel-title>
                                            <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'S'"
                                                      style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}}">
                                                drafts
                                            </mat-icon>
                                            <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'N'"
                                                      style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}}">
                                                markunread
                                            </mat-icon>
                                            <div style="margin-left: 2%">
                                                <b>{{task.defaultFields.get('NOMETASK')}}  </b></div>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <b>Autore: {{task.defaultFields.get('DM_PROFILE_AUTOREDESCRIPTION')}}</b>
                                            <!-- Scadenza: {{this.TransformDataFormat(task[17])}} -->
                                            <ng-container
                                                    *ngIf="this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) < this.todayDate"><span
                                                    style="color: red">Scaduto</span></ng-container>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <ng-container><br>
                                        <div style="text-align: left!important" mat-line>
                                            <i>ID: {{task.defaultFields.get('ID')}}/
                                                Stato: <b>{{task.defaultFields.get('DM_PROFILE_STATO')}}</b>/
                                                Priorità:
                                                <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==0">
                                                    <b>Bassa</b>
                                                </ng-container>
                                                <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==1">
                                                    <b>Media</b>
                                                </ng-container>
                                                <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==2">
                                                    <b>Alta</b>
                                                </ng-container>
                                            </i><br><span
                                                [innerHTML]="task.defaultFields.get('DESCTASK')"></span>
                                            <br>
                                            <ng-container *ngFor="let additional of task.additionalFields">
                                                <ng-container *ngIf="additional.description">
                                                    <b>{{additional.label}}:</b> {{additional.description}}<br>
                                                </ng-container>
                                            </ng-container>
                                            <!--<ng-container *ngIf="task[1]"><br><b>Ragione sociale
                                                dell'azienda: </b>{{task[1]}}
                                            </ng-container>
                                            <ng-container *ngIf="task[2]"><br><b>Ragione sociale: </b>{{task[2]}}
                                            </ng-container>
                                            <ng-container *ngIf="task[3]"><br><b>Nome del trattamento: </b>{{task[3]}}
                                            </ng-container>
                                            <ng-container *ngIf="task[4]"><br><b>Ragione sociale
                                                cliente: </b>{{task[4]}}
                                            </ng-container>-->
                                            <br>
                                            <ng-container><br>
                                                <button mat-button
                                                        (click)="clickTask(task.defaultFields.get('ID'))">
                                                    <mat-icon svgIcon="DetailsIcon"></mat-icon>
                                                </button>
                                                <ng-container
                                                        *ngIf="task.defaultFields.get('LETTO') == 'S'">
                                                    <button mat-button
                                                            (click)="unreadTask(task.defaultFields.get('ID'))">
                                                        <mat-icon>markunread</mat-icon>
                                                        Segna come non letto
                                                    </button>
                                                </ng-container>
                                                <button mat-button [matMenuTriggerFor]="menu"
                                                        aria-label="Example icon-button with a menu">
                                                    <mat-icon>priority_high</mat-icon>
                                                    Seleziona priorità
                                                </button>
                                                <mat-menu #menu="matMenu" xPosition="after">
                                                    <button mat-menu-item
                                                            (click)="setPriority(task.defaultFields.get('ID'),0)">
                                                        <span>Priorità bassa</span>
                                                    </button>
                                                    <button mat-menu-item
                                                            (click)="setPriority(task.defaultFields.get('ID'),1)">
                                                        <span>Priorità media</span>
                                                    </button>
                                                    <button mat-menu-item
                                                            (click)="setPriority(task.defaultFields.get('ID'),2)">
                                                        <span>Priorità alta</span>
                                                    </button>
                                                </mat-menu>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </mat-selection-list>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="nTask != 0 && this.typeOfTaskView == 'plain'">
            <div style="width: 100%;">
                <ng-container class="cdkcontains">
                    <div style="text-align: left">
                        <mat-selection-list #tasks [multiple]="false">
                            <div *ngFor="let task of this.taskList">
                                <ng-container
                                        *ngIf="task.disabledForFilter == false && task.disabledForPriority == false &&
                                                task.disabledForExpired == false && task.disabledForRead == false">
                                    <ng-container style="width: 20%">
                                        <ng-container
                                        >
                                            <button mat-button style="float:right; color:{{this.dashColor}};"
                                                    (click)="unreadTask(task.defaultFields.get('ID'))">
                                                <mat-icon>markunread</mat-icon>
                                            </button>
                                        </ng-container>
                                        <button mat-button [matMenuTriggerFor]="menu"
                                                style="float:right; color:{{this.dashColor}};"
                                        >
                                            <mat-icon>priority_high</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu" xPosition="after">
                                            <button mat-menu-item
                                                    (click)="setPriority(task.defaultFields.get('ID'),0)">
                                                <span>Priorità bassa</span>
                                            </button>
                                            <button mat-menu-item
                                                    (click)="setPriority(task.defaultFields.get('ID'),1)">
                                                <span>Priorità media</span>
                                            </button>
                                            <button mat-menu-item
                                                    (click)="setPriority(task.defaultFields.get('ID'),2)">
                                                <span>Priorità alta</span>
                                            </button>
                                        </mat-menu>
                                    </ng-container>
                                    <mat-list-item (click)="clickTask(task.defaultFields.get('ID'))"
                                                   style="width: 80%;">
                                        <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'S'"
                                                  style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}}">
                                            drafts
                                        </mat-icon>
                                        <mat-icon *ngIf="task.defaultFields.get('LETTO') == 'N'"
                                                  style="color: {{this.getColor(task.defaultFields.get('DM_PROCESSI_COLORE'))}}">
                                            markunread
                                        </mat-icon>
                                        <div style="margin-left: 2%; width: 30%;">
                                            <b>{{task.defaultFields.get('NOMETASK')}}  </b></div>
                                        <div style="margin-left: 2%; width: 30%;">
                                            <b>Autore: {{task.defaultFields.get('DM_PROFILE_AUTOREDESCRIPTION')}}</b>
                                        </div>
                                        <div style="margin-left: 2%; width: 30%;">
                                            <i> Stato: <b>{{task.defaultFields.get('DM_PROFILE_STATO')}}</b>
                                                <br>Priorità:
                                                <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==0">
                                                    <b>Bassa</b>
                                                </ng-container>
                                                <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==1">
                                                    <b>Media</b>
                                                </ng-container>
                                                <ng-container *ngIf="task.defaultFields.get('ASSOCIA')==2">
                                                    <b>Alta</b>
                                                </ng-container>
                                            </i>
                                        </div>
                                        <span style="width: 10%; color: red">
                                                     <ng-container
                                                             *ngIf="this.convertStringToDate(task.defaultFields.get('DATA_SCADENZA')) < this.todayDate">Scaduto</ng-container></span>
                                    </mat-list-item>
                                </ng-container>
                            </div>
                        </mat-selection-list>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
        <div style="width: 90%; display: inline-block;">
            <mat-form-field style="padding: 10px; width: 30%;">
                <mat-label>Filtro lettura</mat-label>
                <mat-select [(value)]="this.selectedRead" (selectionChange)="readChange()">
                    <mat-option value="Tutti">Tutti</mat-option>
                    <mat-option value="S">Letti</mat-option>
                    <mat-option value="N">Non letti</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="padding: 10px; width: 30%;">
                <mat-label>Filtro scadenza</mat-label>
                <mat-select [(value)]="this.selectedExpired" (selectionChange)="expiredChange()">
                    <mat-option value="Tutti">Tutti</mat-option>
                    <mat-option value="Scaduti">Scaduti</mat-option>
                    <mat-option value="Non scaduti">Non scaduti</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="padding: 10px; width: 30%;">
                <mat-label>Filtro priorità</mat-label>
                <mat-select [(value)]="this.selectedPriority" (selectionChange)="priorityChange()">
                    <mat-option value="Tutti">Tutti</mat-option>
                    <mat-option value="2">Alta</mat-option>
                    <mat-option value="1">Media</mat-option>
                    <mat-option value="0">Bassa</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card>
</div>
<!--ELENCO VISTE
<mat-grid-tile class="gridtile">
    <mat-card class="cardcomponent">
        <h3 class="titles">
            <mat-card-title><p>Elenco viste:</p></mat-card-title>
            <mat-form-field class="searchdimension" ><mat-label><mat-icon svgIcon="RicercaIcon"  style="margin-right: 8px"></mat-icon>Cerca</mat-label>
                <input matInput [ngModel]="searchModelViews" (ngModelChange)="updateSearchModelViste($event)">
                <button mat-button *ngIf="searchModelViews" matSuffix mat-icon-button aria-label="Clear" (click)="searchModelViews=''">
                    <mat-icon>close</mat-icon>
                </button></mat-form-field>
        </h3><hr class="riga"><br>
        <mat-card-content>



            <cdk-virtual-scroll-viewport itemSize="50" class="viewport" id="style-4" [(searchModel)]="searchModelViews">
                <div  style="text-align: left;">
                    <mat-selection-list #viste [multiple]="false" >
                        <mat-list-item (click)= "clickVista(vista.id)" class="item" *cdkVirtualFor="let vista of this.views | vistaFilter: searchModelViews" [value]="vista.description">
                            <mat-icon svgIcon="VisteIcon"  style="margin-left: 8px"></mat-icon>
                            <div mat-line><b>{{vista.description}}</b></div>
                            <div mat-line>Utente: {{vista.userCompleteName}}</div>
                        </mat-list-item>
                    </mat-selection-list>
                </div>
            </cdk-virtual-scroll-viewport>




        </mat-card-content>
    </mat-card>
</mat-grid-tile>
-->

