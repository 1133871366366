import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendario-maia',
  templateUrl: './calendario-maia.component.html',
  styleUrls: ['./calendario-maia.component.css']
})
export class CalendarioMaiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
