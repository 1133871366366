import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-strumenti',
  templateUrl: './strumenti.component.html',
  styleUrls: ['./strumenti.component.css']
})
export class StrumentiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
