<div class="center">
    <mat-card class="cardcomponentMasks" id = "style-4">
        <!--<button style="float:left" style="color:{{this.dashColor}};" title="Indietro" (click)="goBack()" mat-icon-button
                aria-label="icon-button send">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>-->
        <h3 class="titles" style="color:{{this.dashColor}};">
            <mat-card-title><p>Processo: {{this.procId}}</p>
            </mat-card-title>

        </h3>
        <hr class="riga" style="color:{{this.dashColor}};">
        <br>
        <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->

        </mat-card-content>
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Task">
                <div style="text-align: left;" *ngFor="let proc of this.process">
                    <mat-accordion class="">

                        <mat-expansion-panel (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header style=" text-align: left!important">
                                <mat-panel-title>
                                    <ng-container *ngIf="proc.kind == 0">
                                        <mat-icon title="Standard">wysiwyg</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="proc.kind == 1">
                                        <mat-icon title="Connessione">link</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="proc.kind == 2">
                                        <mat-icon title="Collegato">extension</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="proc.startDate != '' && proc.startDate != null">
                                        <b style="margin-left: 2%;text-align: left">Inizio: {{this.TransformDataFormat(proc.startDate)}}</b>
                                    </ng-container>
                                    <ng-container *ngIf="proc.endDate != '' && proc.endDate != null">
                                        <b style="width:50%;margin-left: 2%">Fine: {{this.TransformDataFormat(proc.endDate)}}</b>
                                    </ng-container>


                                </mat-panel-title>
                                <mat-panel-description style="text-align: left; justify-content: space-between;">

                                    <ng-container *ngIf="proc.outcome != '' && proc.outcome != null">
                                        <b style="width: 20%;margin-left: 2%">Esito: {{proc.outcome}}</b>
                                    </ng-container>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-container>
                                <ng-container *ngIf="proc.expireDate != '' && proc.expireDate != null">
                                    Scadenza
                                    prevista: {{this.TransformDataFormat(proc.expireDate)}}<br>
                                </ng-container>
                                <ng-container *ngIf="proc.taskName != '' && proc.taskName != null">
                                    <b>Nome task:</b> {{proc.taskName}}<br>
                                </ng-container>
                                <ng-container *ngIf="proc.taskName != '' && proc.taskName != null">
                                    <b>Descrizione task:</b> <span [innerHTML]="proc.taskDescription"></span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.state == 1">
                                    <span><b>Stato:</b> Attivo</span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.state == 2">
                                    <span><b>Stato:</b> In attesa</span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.state == 0">
                                    <span><b>Stato:</b> Concluso</span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.kind == 0">
                                    <span><b>Tipo:</b> Standard</span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.kind == 1">
                                    <span><b>Tipo:</b> Connessione</span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.kind == 2">
                                    <span><b>Tipo:</b> Processo collegato</span><br>
                                </ng-container>
                                <ng-container *ngIf="proc.systemId != '' && proc.systemId != null">
                                    <b>System id:</b> {{proc.systemId}}
                                </ng-container>

                                <ng-container *ngIf="proc.userCompleteName != '' && proc.userCompleteName != null">
                                    <b>Utente:</b> {{proc.userCompleteName}}<br>
                                </ng-container>

                            </ng-container>
                            <button mat-icon-button title="Elimina task" (click)="deleteTask(proc.taskId)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <br>
                            <hr>
                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
            </mat-tab>
            <mat-tab label="Allegati">
                <br>
                <h3 style="color: {{this.dashColor}}">Nessun elemento da visualizzare</h3>
            </mat-tab>
            <mat-tab label="Note">
                <br>
                <h3 style="color: {{this.dashColor}}">Nessun elemento da visualizzare</h3>
            </mat-tab>
            <mat-tab label="Documenti">
                <br>
                <h3 style="color: {{this.dashColor}}">Nessun elemento da visualizzare</h3>
            </mat-tab>
            <mat-tab label="Figure">
                <br>
                <h3 style="color: {{this.dashColor}}">Nessun elemento da visualizzare</h3>
            </mat-tab>
            <mat-tab label="Variabili">
                <br>
                <h3>Nessun elemento da visualizzare</h3>
            </mat-tab>
            <mat-tab label="Chrono">
                <br>
                <h3>Nessun elemento da visualizzare</h3>
            </mat-tab>
        </mat-tab-group>


    </mat-card>
</div>
