import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectedRowsMultiDTO} from '../VociMenu/maskDetails/maskDetails.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';
import {MatPaginator} from '@angular/material/paginator';


@Component({
    selector: 'app-dialogaddressbook',
    styleUrls: ['addressBook-css.css'],
    templateUrl: 'dialog-content-addressBook.html'
})

export class DialogAddressBookComponent implements AfterViewInit{
    ELEMENT_DATA: Array<object> = new Array<object>();
    arrayToObject: { [index: string]: { content: string } } = {};
    dataSource: MatTableDataSource<object>;
    selection: SelectionModel<object>;
    displayedColumns: Array<string> = new Array<string>();
    squareColor: string;
    dataResult: Array<string> = new Array<string>();
    dataToSave: Array<any> = new Array();
    dataToSend: Array<any> = new Array();
    additionalFieldAddressBookValue: Array<any> = new Array();
    fieldName: string;
    selectField: string;
    find = false;
    addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
    isFrom = false;
    disableCheckBox = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }
    constructor(public dialogRef: MatDialogRef<DialogAddressBookComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService) {
        this.fieldName = data.fieldName;


        if (this.fieldName === 'From') {
            this.isFrom = true;
        }
        if (data.multipleSelect === true) {
            this.isFrom = false;
        }
        this.selectField = data.selectField;
        this.additionalFieldAddressBookValue = data.additionalFieldAddressBook;
        data.additionalFieldAddressBook.forEach(element => {
            this.displayedColumns = new Array<string>();
            this.displayedColumns.push('select');
            this.arrayToObject = {};
            element.forEach(obj => {
                this.displayedColumns.push(obj.id);
                this.arrayToObject[obj.id] = obj.value;
            });
            this.ELEMENT_DATA.push(this.arrayToObject);
        });
        console.log(this.ELEMENT_DATA);
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.squareColor = this.apiService.squareColor;
        this.addressBookSelected = data.addressBookSelected;
        this.selection = new SelectionModel<object>(true, []);
        if (this.addressBookSelected.size > 0 && this.addressBookSelected.get(this.fieldName) !== undefined) {
            this.addressBookSelected.get(this.fieldName).forEach(itemMap => {
                this.dataSource.data.forEach(row => {
                    if (itemMap === row[this.selectField]) {
                        this.selection.select(row);
                    }
                });
            });
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): any {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    saveSelectedValues(): void {
        this.dataResult = new Array<string>();
        this.dataToSave = new Array();
        this.selection.selected.forEach(itemSelected => {
            this.dataResult.push(itemSelected[this.selectField]);
            this.additionalFieldAddressBookValue.forEach(element => {
                this.find = false;
                element.forEach(arrayRow => {
                    if (arrayRow.value === itemSelected[this.selectField]) {
                        this.find = true;
                    }
                });
                if (this.find) {
                    this.dataToSave.push(element);
                    this.dataToSend.push(itemSelected);
                }
            });
        });
        console.log(this.dataResult);
        console.log(this.dataToSave);
        this.dialogRef.close({dataResult: this.dataResult, dataToSave: this.dataToSave});
    }

    closeDialog(): void {
        this.dialogRef.close({dataResult: false});
    }
}
