<div style="width: 100%">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; display: flex">
    <h2 style="text-align: center; width: 100%;">Fascicolo: {{this.name}}</h2>
</div>
<div style="text-align: center">
    <p>
        <b>ID: </b>{{this.id}}
    </p>
    <p>
        <b>Autore: </b>{{this.author}}
    </p>
    <p>
        <b>N° documenti contenuti: </b>{{this.nDocs}}
    </p>
    <p>
        <b>N° fascicoli contenuti: </b>{{this.nChilds}}
    </p>
</div>

