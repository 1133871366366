import {Component, Inject, OnInit} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DatePipe, DOCUMENT} from '@angular/common';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ThemePalette} from '@angular/material/core';
import * as fileSaver from 'file-saver';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PreviewComponent, ShowAttachmentsDialogComponent} from '../../MenuItemsV2/view/view.component';
import {UntypedFormGroup} from '@angular/forms';
import {error} from 'selenium-webdriver';
import {FilterDialogComponent} from '../default-home/default-home.component';


export interface CommentoDialog {
  commento: string;
}

export interface NoteDialog {
  nota: string;
}

@Component({
  selector: 'app-dettaglitask',
  templateUrl: './dettaglitask.component.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class DettaglitaskComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  Task: [];
  taskName: string;
  splitTaskName: string;
  splitTaskOrder: string;
  splitTaskCat: string;
  taskDescription: string;
  activeButtons = true;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  processId: string;
  documentFields: Array<any> = new Array<any>();
  systemId: string;
  panelOpenState = false;
  documentFieldsDialog: Array<object> = new Array<object>();
  taskOperations: [];
  taskWorkCommandsOperations: [];
  taskWorkDocumentOperations: Array<any> = new Array<any>();
  taskWorkDynamicJobOperation: [];
  taskWorkOperatingInstructions: [];
  taskWorkProfessionalRoleOperations: [];
  taskWorkSignOperations: [];
  taskWorkVariablesOperation: any = {};
  booleanVariables: [];
  comboVariables: Array<any> = new Array<any>();
  docs: Array<any> = new Array<any>();
  dateTimeVariables: [];
  doubleVariables: any;
  stringVariables: Array<any> = new Array<any>();
  tableVariables: [];
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  note: [];
  comboValues: Array<object> = new Array<object>();
  processVariableId: string;
  noteDescription: string;
  canExit = true;
  inputText: string;
  takeCharge: boolean;
  activeDocNumber: string;
  isLoading = true;
  showProcessVisible = false;
  procDocId: string;
  nomiAoo: string;
  notesNumber: number;
  previewBufferId: string;
  hasPreview = false;
  docNumber: string;
  revision: string;
  imageURL: string;
  uploadForm: UntypedFormGroup;
  image: any;
  index = 0;
  maxPages: number;
  previewCardStyle = {};
  exitCodes: [];
  selected = '';
  exitError = '';

  constructor(private snackBar: MatSnackBar, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) {
    iconRegistry.addSvgIcon(
      'CommentIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/add_comment-24px.svg'));
    iconRegistry.addSvgIcon(
      'PointsIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/drag_indicator-24px.svg'));
    iconRegistry.addSvgIcon(
      'DocumentIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/description-24px.svg'));
    iconRegistry.addSvgIcon(
      'ProfileIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/subject-24px.svg'));
    iconRegistry.addSvgIcon(
      'OpenIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tab-24px.svg'));
    iconRegistry.addSvgIcon(
      'AttachIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/attach_file-24px.svg'));
    iconRegistry.addSvgIcon(
      'NotesIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/note-24px.svg'));
    iconRegistry.addSvgIcon(
      'ExportIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/arrow_right_alt-24px.svg'));
    iconRegistry.addSvgIcon(
      'EditIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/create-24px.svg'));
    iconRegistry.addSvgIcon(
      'SendIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/mail-24px.svg'));
    iconRegistry.addSvgIcon(
      'ShareIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/folder_shared-24px.svg'));
    iconRegistry.addSvgIcon(
      'DetailsIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/visibility-24px.svg'));
  }

  ngOnInit(): void {
    this.apiService.exitcodes(this.id).subscribe(response => {
      this.exitCodes = response;
      if (response.length === 1){
        this.selected = response[0].value;
      }
    });
    this.apiService.namePath = this.apiService.namePath.split('-')[0];
    this.apiService.getAoo().subscribe(data => {
      this.nomiAoo = data;
    });
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.isLoading = true;
    this.apiService.readTask(this.id).subscribe(data => {
    });
    this.canExit = true;
    this.documentFields = new Array<object>();
    this.apiService.taskId = this.id;
    this.apiService.getTaskDetail(this.id).subscribe(data => {
      this.Task = data;
      // console.log(data);
      if (data.takingChargeRequired) {
        this.takeCharge = true;
      } else {
        this.takeCharge = false;
      }
      this.taskName = data.taskName;
      this.apiService.namePath += ' - ' + this.taskName;
      this.apiService.addPath('Task: ' + this.id, 'dashboard/dashnav/dettaglitask', {id: this.id, path: this.path});
      // console.log(this.apiService.namePathV2);
      this.splitTaskCat = this.taskName.split('-')[0];
      this.splitTaskOrder = this.taskName.split('-')[1];
      this.splitTaskName = this.taskName.split('-')[2];
      this.taskDescription = data.taskDescription.replace(/\n/g, '<br/>');
      this.processId = data.processId;
      this.showProcessVisible = data.showProcessVisible;
      this.reloadNotes();
      // tslint:disable-next-line:no-shadowed-variable
      this.apiService.getDocsState(this.processId).subscribe(data => {
        data.forEach(val => {
          if (val.state === 1) {
            this.activeDocNumber = val.docnumber;
            this.revision = val.currentRevision;
            this.apiService.postDocumnets(this.processId).subscribe(datas => {
              this.docs = datas.data;
              datas.data.forEach(vall => {
                // console.log(vall);
                // console.log(this.activeDocNumber);
                if (vall[0] === this.activeDocNumber) {
                  this.procDocId = vall[2];
                  // console.log(this.procDocId);
                }
                this.apiService.getDocDetails(vall[0]).subscribe(datadoc => {
                  this.documentFields.push(datadoc);
                  this.documentFields = this.documentFields.sort((a, b) => {
                    return a.id - b.id;
                  });
                  // console.log(this.documentFields);
                });

              });
              // this.loadTaskPreview();

            });
          }
        });
      });

    });
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
    this.setOperations();
    this.apiService.canFinalize(this.id).subscribe(data => {
      data.forEach(val => {
        if (val.eligibleToClose === false) {
          this.canExit = false;
        } else {
          this.canExit = true;
        }
      });
    });

    console.log(this.documentFields);
  }

  setOperations(): void {
    this.apiService.getTaskOperations(this.id).subscribe(data => {
      console.log(data);
      this.taskWorkCommandsOperations = data.taskWorkCommandsOperations;
      this.taskWorkDocumentOperations = data.taskWorkDocumentOperations;
      this.taskWorkVariablesOperation = data.taskWorkVariablesOperation;
      this.taskWorkDocumentOperations.forEach(val => {
        // console.log(val);
        if (val.isRequired === true && val.isExecuted === false) {
          this.activeButtons = false;
        }
      });

      this.taskWorkDynamicJobOperation = data.taskWorkDynamicJobOperation.operations;
      this.taskWorkOperatingInstructions = data.taskWorkOperatingInstructions;
      this.taskWorkProfessionalRoleOperations = data.taskWorkProfessionalRoleOperations.operations;
      this.taskWorkVariablesOperation = data.taskWorkVariablesOperation;
      this.booleanVariables = this.taskWorkVariablesOperation.processVariablesFields.booleanVariables;
      this.comboVariables = this.taskWorkVariablesOperation.processVariablesFields.comboVariables;
      this.comboVariables.forEach(item => {
        this.processVariableId = item.taskWorkVariableId;
        this.generateComboBox();
      });

      this.dateTimeVariables = this.taskWorkVariablesOperation.processVariablesFields.dateTimeVariables;
      this.doubleVariables = this.taskWorkVariablesOperation.processVariablesFields.doubleVariables;
      console.log(this.doubleVariables);
      this.doubleVariables.forEach(v => {
        this.doubleChange(v.value, v.name, v.className, v.taskWorkVariableId);
      });
      this.stringVariables = this.taskWorkVariablesOperation.processVariablesFields.stringVariables;


      this.tableVariables = this.taskWorkVariablesOperation.processVariablesFields.tableVariables;

      this.taskWorkSignOperations = data.taskWorkSignOperations;

      if (this.taskWorkSignOperations.length === 0) {
        this.taskWorkSignOperations = null;
      }
      if (this.taskWorkDocumentOperations.length === 0) {
        this.taskWorkDocumentOperations = null;
      }
      if (this.taskWorkCommandsOperations.length === 0) {
        this.taskWorkCommandsOperations = null;
      }
      if (this.taskWorkDynamicJobOperation.length === 0) {
        this.taskWorkDynamicJobOperation = null;
      }
      if (this.taskWorkOperatingInstructions.length === 0) {
        this.taskWorkOperatingInstructions = null;
      }
      if (this.taskWorkProfessionalRoleOperations.length === 0) {
        this.taskWorkProfessionalRoleOperations = null;
      }


    });
  }

  loadExitCodes(): void {
    this.apiService.exitcodes(this.id).subscribe(response => {
      this.exitCodes = response;
      if (response.length === 1){
        this.selected = response[0].value;
      }
    }, errorLog => {
      /*this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });*/
    });
  }
  loadExitCodesWithError(): void {
    this.apiService.exitcodes(this.id).subscribe(response => {
      this.exitCodes = response;
      if (response.length === 1){
        this.selected = response[0].value;
      }
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }
  openFullPreview(): void {
    const dialogRef = this.dialog.open(FullPreviewComponent, {
      width: 'fit-content',
      data: {id: this.id, procDocId: this.procDocId, revision: this.revision}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  loadTaskPreview(): void {
    this.apiService.getTaskWorkDocumentsBuffer(this.procDocId, this.id).subscribe(res => {
      if (res) {
        this.previewBufferId = res;
        this.previewCardStyle = {height: '60% !important'};
        this.apiService.getFileTotalPagesPreviewForTask(this.previewBufferId, this.revision).subscribe(info => {
          this.maxPages = info.countPages;
          this.apiService.getFilePreviewForTask(this.previewBufferId, this.revision, 0).subscribe(res => {
            const objectURL = URL.createObjectURL(res);
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.hasPreview = true;

          }, errorLog => {
            this.hasPreview = false;
          });
        }, errorLog => {
          this.hasPreview = false;
        });
      } else {
        this.hasPreview = false;
      }

    });
  }

  hidePreview(): void {
    this.hasPreview = false;
  }

  canFinalize(): void {
    this.apiService.canFinalize(this.id).subscribe(data => {
      data.forEach(val => {
        if (val.eligibleToClose === false) {
          this.canExit = false;
          this.activeButtons = false;
        } else {
          this.canExit = true;
          this.activeButtons = true;
        }
      });
    });
  }

  profileClick(idDoc: string): void {
    this.router.navigate(['../profile', {idDocument: idDoc, taskId: this.id, firstnodepath: this.path}], {relativeTo: this.route});
  }

  nextPage(): void {
    this.apiService.getFilePreview(this.docNumber, this.revision, ++this.index).subscribe(res => {
      const objectURL = URL.createObjectURL(res);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, errorLog => {
      this.snackBar.open('Errore nel caricamento dell\'anteprima', null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  prevPage(): void {
    this.apiService.getFilePreview(this.docNumber, this.revision, --this.index).subscribe(res => {
      const objectURL = URL.createObjectURL(res);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, errorLog => {
      this.snackBar.open('Errore nel caricamento dell\'anteprima', null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  processClick(): void {
    this.router.navigate(['../process', {
      processId: this.processId,
      taskId: this.id,
      path: 'dettaglitask',
      firstnodepath: this.path
    }], {relativeTo: this.route});
  }

  deleteNote(id: string): void {

    this.apiService.deleteNote(id).subscribe(data => {
      this.apiService.getTaskNotes(this.processId).subscribe(data2 => {
        this.note = data2;
      });
    });
  }

  downloadDoc(Id: string, nome: string): void {
    this.apiService.downloadDocument(Id).subscribe(response => {
      const blob = new Blob([response], {type: 'application/octet-stream'});
      fileSaver.saveAs(blob, nome);
      // tslint:disable-next-line:no-unused-expression
    }, errorLog => {
      this.snackBar.open('Al profilo non è associato nessun documento', null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  reloadNotes(): void {

    this.apiService.getTaskNotes(this.processId).subscribe(data => {
      this.note = data;
      this.notesNumber = data.length;
    });
  }

  doTakeCharge(): void {
    this.apiService.takeCharge(this.id).subscribe(data => {
      // // console.log(data);
      this.ngOnInit();
    });
  }

  boolChange(selValue: string, nameC: string, classNameC: string, taskId: string): void {

    const body: JSON = {
      booleanVariables: [
        {
          name: nameC,
          externalId: 'string',
          description: 'string',
          order: 0,
          dataSource: 'string',
          required: true,
          formula: 'string',
          className: classNameC,
          locked: true,
          comboGruppiId: 'string',
          dependencyFields: [
            {
              fieldClassName: 'string',
              fieldId: 'string'
            }
          ],
          associations: [
            {
              fieldName: 'string',
              association: 'string'
            }
          ],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: 'string',
          visibilityCondition: 'string',
          addressBookDefaultFilter: 0,
          enabledAddressBook: [
            0
          ],
          columns: 0,
          value: selValue,
          additionalFieldType: 0,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: taskId,
          validationType: 0,
          validationString: 'string'
        }
      ]
    } as unknown as JSON;
    this.apiService.setProcessVariables(this.id, body).subscribe(data => {
      this.loadExitCodes();
    });
    this.canFinalize();

  }

  exitTask(comment: string): void {
    this.apiService.canFinalize(this.id).subscribe(data => {
      data.forEach(val => {
        if (val.eligibleToClose === false) {
          this.canExit = false;
          this.activeButtons = false;
        } else {
          this.canExit = true;
          this.activeButtons = true;
        }
      });
      if (this.canExit) {
        this.canExit = false;
        this.apiService.finalizeTask(comment, this.selected, this.apiService.taskId).subscribe(data => {
          this.apiService.taskExitSnack();
          window.location.reload();

        }, errorLog => {
          this.canExit = true;
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 7000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });
  }

  exitSelected(value): void {
    this.selected = value;
  }

  doubleChange(selValue: string, nameC: string, classNameC: string, taskId: string): void {

    const body: JSON = {
      doubleVariables: [
        {
          name: nameC,
          externalId: 'string',
          description: 'string',
          order: 0,
          dataSource: 'string',
          required: true,
          formula: 'string',
          className: classNameC,
          locked: true,
          comboGruppiId: 'string',
          dependencyFields: [
            {
              fieldClassName: 'string',
              fieldId: 'string'
            }
          ],
          associations: [
            {
              fieldName: 'string',
              association: 'string'
            }
          ],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: 'string',
          visibilityCondition: 'string',
          addressBookDefaultFilter: 0,
          enabledAddressBook: [
            0
          ],
          columns: 0,
          displayValue: 'string',
          value: selValue,
          numMaxChar: 0,
          numRows: 0,
          additionalFieldType: 0,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: taskId,
          validationType: 0,
          validationString: 'string'
        }
      ]
    } as unknown as JSON;
    this.apiService.setProcessVariables(this.id, body).subscribe(data => {
      this.loadExitCodes();
    });
    this.canFinalize();

  }

  stringChange(selValue: string, nameC: string, classNameC: string, taskId: string): void {

    const body: JSON = {
      stringVariables: [
        {
          name: nameC,
          externalId: 'string',
          description: 'string',
          order: 0,
          dataSource: 'string',
          required: true,
          formula: 'string',
          className: classNameC,
          locked: true,
          comboGruppiId: 'string',
          dependencyFields: [
            {
              fieldClassName: 'string',
              fieldId: 'string'
            }
          ],
          associations: [
            {
              fieldName: 'string',
              association: 'string'
            }
          ],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: 'string',
          visibilityCondition: 'string',
          addressBookDefaultFilter: 0,
          enabledAddressBook: [
            0
          ],
          columns: 0,
          displayValue: 'string',
          value: selValue,
          numMaxChar: 0,
          numRows: 0,
          additionalFieldType: 0,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: taskId,
          validationType: 0,
          validationString: 'string'
        }
      ]
    } as unknown as JSON;
    this.apiService.setProcessVariables(this.id, body).subscribe(data => {
      this.loadExitCodes();
    });
    this.canFinalize();

  }

  comboSelected(selValue: string, nameC: string, classNameC: string, taskId: string): void {
    const body: JSON = {
      comboVariables: [
        {
          name: nameC,
          externalId: 'string',
          description: 'string',
          order: 0,
          dataSource: 'string',
          required: true,
          formula: 'string',
          className: classNameC,
          locked: true,
          comboGruppiId: 'string',
          dependencyFields: [
            {
              fieldClassName: 'string',
              fieldId: 'string'
            }
          ],
          associations: [
            {
              fieldName: 'string',
              association: 'string'
            }
          ],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: 'string',
          visibilityCondition: 'string',
          addressBookDefaultFilter: 0,
          enabledAddressBook: [
            0
          ],
          columns: 0,
          limitToList: true,
          displayValue: 'string',
          value: selValue,
          numMaxChar: 0,
          numRows: 0,
          additionalFieldType: 3,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: taskId,
          validationType: 0,
          validationString: 'string'
        }
      ]
    } as unknown as JSON;
    this.apiService.setProcessVariables(this.id, body).subscribe(data => {
      this.loadExitCodes();
    });
    this.canFinalize();
  }

  generateComboBox(): void {
    const body: JSON = {
      processVariablesFields: {
        booleanVariables: [
          {
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: [
              {
                fieldName: 'string',
                association: 'string'
              }
            ],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [
              0
            ],
            columns: 0,
            value: true,
            additionalFieldType: 0,
            groupId: 0,
            binderFieldId: 0,
            taskWorkVariableId: 0,
            validationType: 0,
            validationString: 'string'
          }
        ],
        stringVariables: [
          {
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: [
              {
                fieldName: 'string',
                association: 'string'
              }
            ],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [
              0
            ],
            columns: 0,
            displayValue: 'string',
            value: 'string',
            numMaxChar: 0,
            numRows: 0,
            additionalFieldType: 0,
            groupId: 0,
            binderFieldId: 0,
            taskWorkVariableId: 0,
            validationType: 0,
            validationString: 'string'
          }
        ],
        comboVariables: [
          {
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: [
              {
                fieldName: 'string',
                association: 'string'
              }
            ],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [
              0
            ],
            columns: 0,
            limitToList: true,
            displayValue: 'string',
            value: 'string',
            numMaxChar: 0,
            numRows: 0,
            additionalFieldType: 0,
            groupId: 0,
            binderFieldId: 0,
            taskWorkVariableId: 0,
            validationType: 0,
            validationString: 'string'
          }
        ],
        dateTimeVariables: [
          {
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: [
              {
                fieldName: 'string',
                association: 'string'
              }
            ],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [
              0
            ],
            columns: 0,
            value: '2020-09-22T06:26:43.594Z',
            additionalFieldType: 0,
            groupId: 0,
            binderFieldId: 0,
            taskWorkVariableId: 0,
            validationType: 0,
            validationString: 'string'
          }
        ],
        doubleVariables: [
          {
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: [
              {
                fieldName: 'string',
                association: 'string'
              }
            ],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [
              0
            ],
            columns: 0,
            value: 0,
            decimals: 0,
            additionalFieldType: 0,
            groupId: 0,
            binderFieldId: 0,
            taskWorkVariableId: 0,
            validationType: 0,
            validationString: 'string'
          }
        ],
        tableVariables: [
          {
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: [
              {
                fieldName: 'string',
                association: 'string'
              }
            ],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [
              0
            ],
            columns: 0,
            limitToList: true,
            displayValue: 'string',
            value: 'string',
            numMaxChar: 0,
            numRows: 0,
            additionalFieldType: 0,
            groupId: 0,
            binderFieldId: 0,
            taskWorkVariableId: 0,
            validationType: 0,
            validationString: 'string'
          }
        ]
      },
      variableFilters: {
        dateTimeFields: [
          {
            groupId: 0,
            fieldType: 0,
            additionalFieldType: 0,
            defaultOperator: 0,
            tableName: 'string',
            binderFieldId: 0,
            multiple: 'string',
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: {},
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            operator: 0,
            valore1: '2020-09-22T06:26:43.594Z',
            valore2: '2020-09-22T06:26:43.594Z'
          }
        ],
        stringFields: [
          {
            groupId: 0,
            fieldType: 0,
            additionalFieldType: 0,
            defaultOperator: 0,
            tableName: 'string',
            binderFieldId: 0,
            multiple: 'string',
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: {},
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            operator: 0,
            valore1: 'string',
            valore2: 'string'
          }
        ],
        intFields: [
          {
            groupId: 0,
            fieldType: 0,
            additionalFieldType: 0,
            defaultOperator: 0,
            tableName: 'string',
            binderFieldId: 0,
            multiple: 'string',
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: {},
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            operator: 0,
            valore1: 0,
            valore2: 0
          }
        ],
        boolFields: [
          {
            groupId: 0,
            fieldType: 0,
            additionalFieldType: 0,
            defaultOperator: 0,
            tableName: 'string',
            binderFieldId: 0,
            multiple: 'string',
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: {},
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            operator: 0,
            valore1: true,
            valore2: true
          }
        ],
        doubleFields: [
          {
            groupId: 0,
            fieldType: 0,
            additionalFieldType: 0,
            defaultOperator: 0,
            tableName: 'string',
            binderFieldId: 0,
            multiple: 'string',
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: {},
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            decimals: 0,
            operator: 0,
            valore1: 0,
            valore2: 0
          }
        ],
        stringListFields: [
          {
            groupId: 0,
            fieldType: 0,
            additionalFieldType: 0,
            defaultOperator: 0,
            tableName: 'string',
            binderFieldId: 0,
            multiple: 'string',
            name: 'string',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: 'string',
            locked: true,
            comboGruppiId: 'string',
            dependencyFields: [
              {
                fieldClassName: 'string',
                fieldId: 'string'
              }
            ],
            associations: {},
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            operator: 0,
            and: true,
            valore1: [
              'string'
            ],
            valore2: [
              'string'
            ]
          }
        ]
      }
    } as unknown as JSON;

    this.apiService.getProcessValues(body, this.processVariableId, this.id).subscribe(data => {
      // // console.log(data);
      this.comboValues = data.dataSource;
    });


  }

  openMask(maskId: string, opId: string, moduleId: string, viewId: string): void {
    if (moduleId !== null) {
      this.router.navigate(['../modeldetail', {
          idTask: this.id, operationId: opId, firstnodepath: this.path,
          idModel: moduleId
        }],
        {relativeTo: this.route});
    } else if (maskId !== '') {
      this.router.navigate(['../maskDetails', {id: maskId, idTask: this.id, operationId: opId, firstnodepath: this.path}],
        {relativeTo: this.route});
    } else if (viewId !== '' && maskId === '') {
      this.router.navigate(['../viewTask', {id: viewId, idTask: this.id, operationId: opId, firstnodepath: this.path}],
        {relativeTo: this.route});
    } else {
      this.router.navigate(['../archiviazione', {idTask: this.id, operationId: opId, firstnodepath: this.path}],
        {relativeTo: this.route});
    }
  }

  goBack(): void {
    this.router.navigate(['../' + this.path], {relativeTo: this.route});
  }

  openActionDialog(): void {
    const dialogRef = this.dialog.open(ActionDialogComponent, {
      width: '40%',
      data: {name: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.goBack();
      } else {

      }
    });
  }

  openNotesClick(): void {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      width: '40%',
      data: {procId: this.processId}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadNotes();
    });
  }

  openEditFileDialog(procId: string): void {
    let processId;
    this.docs.forEach(val => {
      if (val[0] === procId) {
        processId = val[2];
      }
    });
    const dialogRef = this.dialog.open(EditFromFileComponent, {
      width: '20%',
      data: {taskId: this.id, procDoc: processId}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  openEditRevisionDialog(procId: string): void {
    let processId;
    this.docs.forEach(val => {
      if (val[0] === procId) {
        processId = val[2];
      }
    });
    const dialogRef = this.dialog.open(EditRevisionComponent, {
      width: '20%',
      data: {taskId: this.id, procDoc: processId}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  openAttachmentsDialog(): void {
    const dialogRef = this.dialog.open(ShowAttachmentsDialogComponent, {
      width: '60%',
      data: {docN: this.activeDocNumber}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openPreview(docNumber: string, revision: string): void {
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '60%',
      panelClass: 'custom-dialog-container',
      data: {docN: docNumber, rev: revision}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  openPreviewDialog(id: string): void {
    this.documentFieldsDialog = new Array<object>();
    this.apiService.postDocumnets(this.processId).subscribe(datas => {

      datas.data.forEach(val => {
        if (val[0] === id) {
          this.apiService.getDocPreview(val[0]).subscribe(datadoc => {
            // // console.log(datadoc);
            datadoc.fieldsGroups.forEach(value => {

              this.documentFieldsDialog.push(value);

            });


          });
        }
      });
      const dialogRef = this.dialog.open(PreviewDialogComponent, {
        width: '80%',
        height: '80%',
        id: 'style-4',
        data: {DocumentProfile: this.documentFieldsDialog}
      });

      dialogRef.afterClosed().subscribe(result => {


      });
    });

  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  openAddNoteDialog(): void {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      width: '350px',
      data: {name: this.noteDescription}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadNotes();
      if (result) {
        this.noteDescription = result;
      }
    });
  }
}

@Component({
  selector: 'app-commentodialog',
  templateUrl: 'commentoDialog.html',
})
export class CommentoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CommentoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentoDialog) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-addnote',
  templateUrl: 'addNote.html',
})
export class AddNoteDialogComponent {

  constructor(private snackBar: MatSnackBar, public apiService: ApiService,
              public dialogRef: MatDialogRef<AddNoteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NoteDialog) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNote(note: string): void {
    if (note.length > 0) {
      this.apiService.insertNote(this.apiService.taskId, note).subscribe(data => {
        this.snackBar.open('Nota aggiunta correttamente', null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-commentodialog',
  templateUrl: 'actionDialog.html',
})
export class ActionDialogComponent {
  comment = '';
  exitCodes: [];
  selected = '';
  canExit = true;

  constructor(private snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, public dialog: MatDialog,
              public apiService: ApiService,
              public dialogRef: MatDialogRef<ActionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.apiService.exitcodes(this.apiService.taskId).subscribe(response => {
      this.exitCodes = response;
      if (response.length === 1){
        this.selected = response[0].value;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  openCommentDialog(): void {
    const dialogRef = this.dialog.open(CommentoDialogComponent, {
      width: '20%',
      data: {name: this.comment}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.comment = result;
      }
    });
  }

  exitTask(comment: string): void {
    if (this.canExit) {
      this.canExit = false;
      this.apiService.finalizeTask(comment, this.selected, this.apiService.taskId).subscribe(data => {
        this.apiService.taskExitSnack();
        window.location.reload();

      }, errorLog => {
        this.canExit = true;
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }
  }

  exitSelected(value): void {
    this.selected = value;
  }
}


@Component({
  selector: 'app-notesdialog',
  templateUrl: 'notesDialog.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class NotesDialogComponent {
  note = [];
  squareColor: string;
  panelOpenState = false;
  noteDescription: string;
  processId: string;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<NotesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, public dialog: MatDialog, private datePipe: DatePipe) {
    // // console.log(data.DocumentProfile);
    // // console.log(this.ELEMENT_DATA);
    this.squareColor = this.apiService.squareColor;
    this.processId = data.procId;
    this.reloadNotes();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openAddNoteDialog(): void {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      width: '350px',
      data: {name: this.noteDescription}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadNotes();
      if (result) {
        this.noteDescription = result;
      }
    });
  }

  reloadNotes(): void {
    this.apiService.getTaskNotes(this.processId).subscribe(data => {
      this.note = data;
    });
  }

  deleteNote(id: string): void {

    this.apiService.deleteNote(id).subscribe(data => {
      this.apiService.getTaskNotes(this.processId).subscribe(data2 => {
        this.note = data2;
      });
      this.snackBar.open('Nota eliminata correttamente', null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    });
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }
}


@Component({
  selector: 'app-previewdialog',
  templateUrl: 'profilePreviewDialog.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class PreviewDialogComponent {

  ELEMENT_DATA: object[] = new Array();
  dataSource: MatTableDataSource<object>;
  displayedColumns: string[] = ['name', 'value'];
  squareColor: string;
  panelOpenState = false;

  constructor(public dialogRef: MatDialogRef<PreviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    // // console.log(data.DocumentProfile);
    this.ELEMENT_DATA = data.DocumentProfile;
    // // console.log(this.ELEMENT_DATA);
    this.squareColor = this.apiService.squareColor;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-editfiledialog',
  templateUrl: 'editFromFileDialog.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class EditFromFileComponent {
  fileListMain: FileList;
  fileMain: File;
  fileinserito = '';
  fileToBase: File; // file
  selectedOption: string;
  formDataMain: FormData;
  cacheCode: string;
  taskId: string;
  bufferIdInfo: Array<any> = new Array<any>();
  badgeBufferMain = 0;
  procDocId: string;
  NomeFile: string;

  constructor(private snackBar: MatSnackBar, @Inject(DOCUMENT) private document: Document,
              public dialogRef: MatDialogRef<EditFromFileComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.taskId = data.taskId;
    this.procDocId = data.procDoc;
  }


  onClick(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  removeFile(): void {
    this.badgeBufferMain = 0;
  }

  // tslint:disable-next-line:typedef
  fileChangeEventOld(event) {
    this.fileListMain = event.target.files;
    this.formDataMain = new FormData();
    this.formDataMain.append('file', this.fileMain, this.fileMain.name);
    this.apiService.insertFileInCache(this.formDataMain).subscribe(data => {
      this.cacheCode = data;
    });
    this.fileinserito = 'File inserito correttamente';
  }

  fileChangeEvent(event): void {
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.NomeFile = this.fileMain.name;
      this.apiService.insertFileInCache(this.formDataMain).subscribe(data => {
        this.cacheCode = data;
        this.fileinserito = 'File inserito correttamente';
        this.badgeBufferMain = 1;

      });
    }

  }

  sendFile(): void {
    this.apiService.checkInOutFileTask(this.procDocId, this.taskId, this.cacheCode).subscribe(data => {
      // console.log(data);
      this.snackBar.open('File caricato correttamente', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
    this.closeDialog();
  }


  // tslint:disable-next-line:typedef
  handleFile(event) {
    const binaryString = event.target.result;
    this.fileToBase = binaryString;
    // console.log(this.fileToBase);
  }

}

@Component({
  selector: 'app-editrevisiondialog',
  templateUrl: 'editRevisionDialog.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class EditRevisionComponent {
  taskId: string;
  procDocId: string;
  revisionNumber: string;
  ELEMENT_DATA: object[] = new Array();
  dataSource: MatTableDataSource<object>;
  displayedColumns: string[] = ['name', 'value'];
  squareColor: string;
  panelOpenState = false;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditRevisionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.taskId = data.taskId;
    this.procDocId = data.procDoc;
  }

  sendRevision(): void {
    this.apiService.revisionTask(this.procDocId, this.taskId, this.revisionNumber).subscribe(data => {
      // console.log(data);
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-attachmentsdialog',
  templateUrl: 'attachmentsDialog.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class AttachmentsDialogComponent {
  docId: string;
  attachments = [];

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<AttachmentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.docId = data.docId;
    this.apiService.docAttachments(this.docId).subscribe(val => {
      this.attachments = val;
      // console.log(this.attachments);
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-fullpreview',
  templateUrl: 'fullPreview.html',
  styleUrls: ['./dettaglitask.component.css']
})
export class FullPreviewComponent {
  docNumber: string;
  revision: string;
  imageURL: string;
  uploadForm: UntypedFormGroup;
  image: any;
  index = 0;
  maxPages: number;
  previewBufferId: string;
  procDocId: string;
  id: string;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<FullPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private sanitizer: DomSanitizer) {
    this.procDocId = data.procDocId;
    this.revision = data.revision;
    this.id = data.id;
    this.apiService.getTaskWorkDocumentsBuffer(this.procDocId, this.id).subscribe(res => {
      if (res) {
        this.previewBufferId = res;
        this.apiService.getFileTotalPagesPreviewForTask(this.previewBufferId, this.revision).subscribe(info => {
          this.maxPages = info.countPages;
          this.apiService.getFilePreviewForTask(this.previewBufferId, this.revision, 0).subscribe(res => {
            const objectURL = URL.createObjectURL(res);
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);


          }, errorLog => {
          });
        }, errorLog => {
        });
      } else {
      }

    });
  }

  nextPage(): void {
    this.apiService.getFilePreview(this.docNumber, this.revision, ++this.index).subscribe(res => {
      const objectURL = URL.createObjectURL(res);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, errorLog => {
      this.snackBar.open('Errore nel caricamento dell\'anteprima', null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  prevPage(): void {
    this.apiService.getFilePreview(this.docNumber, this.revision, --this.index).subscribe(res => {
      const objectURL = URL.createObjectURL(res);
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    }, errorLog => {
      this.snackBar.open('Errore nel caricamento dell\'anteprima', null, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
