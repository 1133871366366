<div class="center">
  <mat-card class="cardcomponent" >
     <h3 class="titles">
        <mat-card-title><p>Profili:</p></mat-card-title>
     </h3>
      <hr class="riga"><br>
    <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->

    </mat-card-content>

  </mat-card>
</div>
