import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {DatePipe} from '@angular/common';
import {DialogMultiDTOComponent} from '../../additionalDialog/dialogMultiDTOComponent';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {DialogAddressBookComponent} from '../../additionalDialog/dialogAddressBookComponent';
import {DialogComboDTOComponent} from '../../additionalDialog/dialogComboDTOComponents';
import {DialogMaskComponent} from '../../additionalDialog/dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from '../../additionalDialog/dialogClassViewDTOComponent';
import {DialogSearchDocComponent} from '../../additionalDialog/dialogSearchDocComponents';
import {DialogDocumentalClassComponent} from '../../additionalDialog/dialogDocumentalClass';
import {MultiSelectModule} from 'primeng/multiselect';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {AddExternalAttachmentComponent} from '../../MenuItemsV2/view/view.component';
import {buffer} from 'rxjs/operators';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';
import moment from 'moment';


export interface SelectedRowsTableDTO {
  selected: [];
}

export interface SelectedRowsMultiDTO {
  selected: [];
}


@Component({
  selector: 'app-archiviazione',
  templateUrl: './archiviazione.component.html',

  styleUrls: ['./archiviazione.component.css']
})
export class ArchiviazioneComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  idTask = this.activatedRoute.snapshot.paramMap.get('idTask');
  operationId = this.activatedRoute.snapshot.paramMap.get('operationId');
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  maskName: string;
  splitMaskName: string;
  splitMaskOrder: string;
  splitMaskCat: string;
  classProfileType: string;
  classProfileName: string;
  classProfileValue: string;
  fields: Array<any> = new Array<any>();
  fieldsNoAdditional: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  additionalFields: Array<any> = new Array<any>();
  documenTypes: Array<any> = new Array<any>();
  stateFields: Array<any> = new Array<any>();
  toFields: Array<any> = new Array<any>();
  FromFields: Array<any> = new Array<any>();
  Row: [];
  breakpoint: number;
  fieldDataSource: Array<any> = [];
  multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();

  addressBookSelectedToShow = '';
  fieldName = '';
  keyField = '';
  mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
  mapTableValueToSave: any;
  tableAssociationField: object = {};
  comboAssociationField: any = {};
  mapTableValueToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookToSee: Map<string, string> = new Map<string, string>();
  mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
  // CLASSE BOX
  ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
  arrayToObjectClass: { [index: string]: { content: string } } = {};
  dataSourceClass: MatTableDataSource<object>;
  displayedColumnsClass: Array<string> = new Array<string>();
  docNumberClasseboxTemp: Array<string> = new Array<string>();
  optionsMask: any = {};
  dataMaskToSave: any = {};
  dataValueAssociations: any = {};
  validationCheck: boolean;
  jsonToSendArxivar: any = {};
  fileListMain: FileList;
  fileMain: File;
  baseFields: Array<any> = new Array<any>();
  twDocumentOperationId: string;
  formDataMain: FormData;
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  arrayToObject: { [index: string]: { content: string } } = {};
  mapAddressBookSave: Array<any> = new Array<any>();
  idContact: string;
  comboValues: any = {};
  tempCombo: Array<any> = new Array<any>();
  selectToAdd: string;
  keyToAdd: string;
  rowToAddMulti: any = {};
  multiId: string;
  tempMulti: Array<any> = new Array<any>();
  multiValues: any = {};
  selectedValue: any = {};
  cities: City[];
  whatToShow = 'default';
  notes: Array<any> = new Array<any>();
  addNoteText = '';
  selectedCities: City[];

  // chip list
  mapMultiValueToSee: Map<string, any> = new Map<string, any>();
  multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
  visible = true;
  selectable = true;
  removable = true;

  // attachments section
  bufferIdInfo2: Array<any> = new Array<any>();


  // tslint:disable-next-line:max-line-length variable-name
  constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService,
              private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar,
              private bodyFieldToSave: BodyFieldToSave, private datePipe: DatePipe) {
    iconRegistry.addSvgIcon(
      'BackIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/keyboard_backspace-24px.svg'));
    this.cities = [
      {name: 'New York', code: 'NY'},
      {name: 'Rome', code: 'RM'},
      {name: 'London', code: 'LDN'},
      {name: 'Istanbul', code: 'IST'},
      {name: 'Paris', code: 'PRS'}
    ];
  }

  ngOnInit(): void {
    this.badgeBufferMain = this.bufferIdInfo.length;
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
    this.apiService.getAoo().subscribe(data => {
      this.nomiAoo = data;
    });
    this.apiService.getOrigins().subscribe(data => {
      this.originsFields = data;
    });

    this.apiService.getStates().subscribe(data => {
      this.stateFields = data;
    });
    if (this.idTask === '0') {
      this.apiService.getProfiles().subscribe(data => {
        // console.log(data);
        this.optionsMask = data.options;
        data.fields = data.fields.sort((a, b) => {
          return a.order - b.order;
        });
        this.fields = data.fields;
        this.baseFields = data.fields;
        // console.log(this.fields);
        data.fields.forEach(findClass => {
          if (findClass.visible) {
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.dataValueAssociations[findClass.name] = findClass.associations;
          }
          if (findClass.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(findClass.name, findClass.value);
            findClass.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (findClass.className === 'ToFieldDTO') {
            let selected: Array<string> = new Array<string>('');
            // console.log(findClass);
            this.addressBookSelectedToShow = '';
            if (findClass.value) {
              findClass.value.forEach(item => {
                this.addressBookSelectedToShow += item.society + ';\n';
                selected.push(item.society);

              });
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            } else {
              this.mapAddressBookToSee.set(findClass.name, '');
            }

          }
          if (findClass.className === 'FromFieldDTO') {
            let selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = '';
            if (findClass.value) {
              this.addressBookSelectedToShow += findClass.value.society + ';\n';
              selected.push(findClass.value.society);
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            } else {
              this.mapAddressBookToSee.set(findClass.name, '');
            }


          }
          if (findClass.className === 'CcFieldDTO') {
            let selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = '';
            if (findClass.value) {
              findClass.value.forEach(item => {
                // console.log(item);
                this.addressBookSelectedToShow += item.society + ';\n';
                selected.push(item.society);

              });
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            } else {
              this.mapAddressBookToSee.set(findClass.name, '');
            }

          }
          if (findClass.className === 'DocumentTypeFieldDTO') {
            this.classProfileType = findClass.className;
            this.classProfileName = findClass.name;
            this.classProfileValue = findClass.value;
          }
        });
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
        });
        this.apiService.getDocTypeModeWithAoo('0', this.dataMaskToSave.Aoo).subscribe(data => {
          data.forEach(v => {
            if (v.isLeaf) {
              this.documenTypes.push(v);
            }
          });
        });
        this.checkFormula();
      });
    } else {
      this.apiService.getStandardProfileForTask(this.idTask, this.operationId).subscribe(data => {
        // console.log(data);
        this.optionsMask = data.options;
        data.fields = data.fields.sort((a, b) => {
          return a.order - b.order;
        });
        this.fields = data.fields;
        this.baseFields = data.fields;
        // console.log(this.fields);
        data.fields.forEach(findClass => {
          if (findClass.visible) {
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.dataValueAssociations[findClass.name] = findClass.associations;
          }
          if (findClass.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(findClass.name, findClass.value);
            findClass.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (findClass.className === 'DocumentTypeFieldDTO') {
            this.classProfileType = findClass.className;
            this.classProfileName = findClass.name;
            this.classProfileValue = findClass.value;
          }
        });
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
        });
        this.apiService.getDocTypeModeWithAoo('0', this.dataMaskToSave.Aoo).subscribe(data => {
          data.forEach(v => {
            if (v.isLeaf) {
              this.documenTypes.push(v);
            }
          });
        });
        this.checkFormula();
      });
    }

  }

  deleteAttach(bufferId: string): void {
    this.bufferIdInfo2.forEach((res, index) => {
      if (res.buffer === bufferId) {
        this.bufferIdInfo2.splice(index, 1);
        this.snackBar.open('Allegato eliminato con successo', null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
      }
    });
  }

  addAttach(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo2.push(this.fileInfo);
      });
    }
  }

  aooChanged(): void {
    this.apiService.getDocTypeModeWithAoo('0', this.dataMaskToSave.Aoo).subscribe(data => {
      this.documenTypes = new Array<any>();
      data.forEach(v => {
        if (v.isLeaf) {
          this.documenTypes.push(v);
        }
      });
    });
  }

  cleanFields(): void {
    this.ngOnInit();
  }

  remove(v: string, fieldName: string): void {
    this.multiValuteDTOSelectedToShow = new Array<any>();
    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value === v) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.multiValuteDTOSelectedToShow.push(value);
    });
    this.mapMultiValueToSee = new Map<string, any>();
    this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
  }

  checkComboAssociations(field: any): void {
    let comboResult: any = {};
    let selectedElement;
    this.fieldName = field.name;
    this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
      comboResult = results;
      this.comboAssociationField = {};
      this.comboAssociationField = Object.keys(results.associations);
      this.fieldDataSource = new Array<any>();
      this.keyField = results.keyField;
      if (results.dataSource != null) {

        results.dataSource.forEach(item => {
          // console.log(item);
          item.columns.forEach(v => {
            if (v.id.toUpperCase() === this.keyField.toUpperCase() && v.value === field.value) {
              selectedElement = item;
            }
          });

        });
      } else {
        // EMPTY COMBO
      }
      this.comboAssociationField.forEach(res => {
        selectedElement.columns.forEach(v2 => {
          if (v2.id === comboResult.associations[res]) {
            this.dataMaskToSave[res.toUpperCase()] = v2.value;
          }
        });
      });
      this.getAllProfileValue();
    });
  }

  getAllNullProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (this.comboValues[val.name]) {
        console.log(this.comboValues[val.name]);
        if (this.comboValues[val.name].length === 1) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[val.name] = this.tempCombo;
                if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]) {
                  this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                }
              });
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                    if (item.name === val.name) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      } else {
        if (!val.value) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[val.name] = this.tempCombo;
                if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]) {
                  this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                }
              });
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                    if (item.name === val.name) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      }

    });
  }

  getAllProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (val.className === 'AdditionalFieldComboDTO') {
        this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
          // console.log(results);
          this.tempCombo = new Array<any>();
          results.dataSource.forEach(res => {
            this.selectToAdd = '';
            this.keyToAdd = '';
            res.columns.forEach(r => {
              if (results.selectField.includes(r.id)) {
                this.selectToAdd += r.value + ' - ';
              }
              if (results.keyField.includes(r.id)) {
                this.keyToAdd += r.value + ' - ';
              }
            });
            this.tempCombo.push({
              selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
              keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
            });
            this.comboValues[val.name] = this.tempCombo;
          });
        });
      } else {
        if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
          this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
            this.tableAssociationField = {};
            this.tableAssociationField = Object.keys(results.associations)
              .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
            console.log(this.tableAssociationField);
            if (results.dataSource.length === 0) {
              this.fields.forEach((item, subindex) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
                if (item.name === val.name) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
              });
            }
          });
        }
      }

    });
    this.checkFormula();

  }

  checkFormula(): void {
    this.formulaCalculator(0);
  }

  formulaCalculator(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }

            if (this.fields[i + 1]) {
              this.formulaCalculator(i + 1);
            }
            found = true;

            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          if (this.fields[i + 1]) {
            this.formulaCalculator(i + 1);
          }
          if (!this.fields[i + 1]) {
            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculator(i + 1);
      } else {
        this.getAllNullProfileValue();
      }
    }
  }

  getUpdatedFields(name: string, form: string): any {
    this.jsonToSendArxivar =
      {
        fieldName: name,
        formula: form,
        searchFilterDto: {
          fields: []
        }
      };
    this.fields.forEach((field, index) => {
      if (field.visible || this.dataMaskToSave[field.name]) {
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }
      }
      this.jsonToSendArxivar.searchFilterDto.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  opendocumentalClassDialog(): void {
    this.apiService.classDocMode = '0';
    this.apiService.classDocAoo = this.dataMaskToSave.Aoo;
    const dialogRef = this.dialog.open(DialogDocumentalClassComponent, {
      width: '40%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.fields.forEach(v => {
          if (v.name === 'DocumentType') {
            this.dataMaskToSave[v.name] = Number(result.docClassId);
            v.value = Number(result.docClassId);
            this.documentChanged({isUserInput: true}, result.docType, result.type1, result.type2);
          }
        });
      }
    });
  }

  openMultiValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        // console.log(results);
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
          id: 'style-4',
          width: '40%',
          height: '60%',
          data: {
            additionalFieldMultiDTO: this.fieldDataSource,
            fieldName: field.name,
            multiValueToSelected: this.multiValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[this.fieldName] = '';
            this.mapMultiValueToSee.set(this.fieldName, '');
            this.multiValuteDTOSelected.set(this.fieldName, []);
          }
          this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
            result.dataResult.forEach(item => {
              this.multiValuteDTOSelectedToShow.push(item);
            });
            this.dataMaskToSave[this.fieldName] = result.dataResult;
            this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
            this.multiValuteDTOSelected.set(this.fieldName, result.dataResult); // non serve probabilmente
            this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
            console.log(this.mapMultiValueToSee);
          }
          this.getAllProfileValue();
          this.checkFormula();
        });
      });
    }
  }

  checkInputFormula(): void {
    this.formulaCalculatorNoLoading(0);
  }

  formulaCalculatorNoLoading(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }
            if (this.fields[i + 1]) {
              this.formulaCalculatorNoLoading(i + 1);
            }
            found = true;
            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          if (this.fields[i + 1]) {
            this.formulaCalculatorNoLoading(i + 1);
          }
          if (!this.fields[i + 1]) {

            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculatorNoLoading(i + 1);
      } else {
        this.getAllNullProfileValue();
      }
    }
  }

  openTableDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.tableAssociationField = {};
        this.tableAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogTableDTOComponent, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            additionalFieldTableDTO: this.fieldDataSource,
            fieldName: field.name,
            tableValueToSelected: this.tableValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
            });
          }
          if (result.dataResult !== false) {
            this.mapTableValueToSave = result.dataResult;
            this.fields.forEach((item, index) => {
              if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.SearchAllAddressBook().subscribe(results => {
        this.apiService.viewDataToShow = results;
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            fieldName: field.name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE'
          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = '';
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach(val => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // console.log(res);
              if (type === '0') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }

            });
          });
          if (result.dataResult !== false) {
            result.contactsNames.forEach(item => {
              this.addressBookSelectedToShow += item + ';\n';
            });
            if (type === '0') {
              this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
            } else {
              this.dataMaskToSave[this.fieldName] = fromFieldValue;
            }
            this.addressBookSelected.set(this.fieldName, result.dataResult);
            this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
          }
          this.getAllProfileValue();
          this.checkFormula();
          /*this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
              result.dataResult.forEach(item => {
                  this.multiValuteDTOSelectedToShow.push(item);
              });
              this.dataMaskToSave[this.fieldName] = result.dataResult;
              this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
              this.multiValuteDTOSelected.set(this.fieldName, result.dataResult);
              this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
          }*/
          this.getAllProfileValue();
          this.checkFormula();
        });
      });
    }
  }


  openComboValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.comboAssociationField = {};
        this.comboAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            // console.log(item);
            this.fieldDataSource.push(item.columns[0]);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogComboDTOComponent, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            additionalFieldComboDTO: this.fieldDataSource,
            fieldName: field.name,
            comboValueToSelected: this.comboValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
            });
          }
          if (result.dataResult !== false) {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.comboAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  documentChanged(event: any, tipo1: string, tipo2: string, tipo3: string): void {
    if (event.isUserInput) {
      this.apiService.additionalProfiles(tipo1, tipo2, tipo3, this.fields[0].value).subscribe(data => {
        this.fieldsNoAdditional = new Array<any>();
        this.fields.forEach(v => {
          if (v.isAdditional === false) {
            this.fieldsNoAdditional.push(v);
          }
        });
        this.additionalFields = data;
        this.additionalFields.forEach(v => {
          this.fieldsNoAdditional.push(v);
        });
        this.fieldsNoAdditional.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
          if (v.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(v.name, v.value);
            v.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (v.className === 'AdditionalFieldMultivalueDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempMulti = new Array<string>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.multiId = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.multiId = r.value;
                  }
                  this.selectToAdd += r.value + ' - ';
                });
                this.selectToAdd.substr(0, this.selectToAdd.length - 3);
                this.tempMulti.push({name: this.selectToAdd, id: this.multiId});
                this.multiValues[v.name] = this.tempMulti;
              });
              // console.log(this.multiValues);
            });
          }
        });

        this.fields = this.fieldsNoAdditional;

      });
    }
  }

  multiValueChanged(): void {
    // console.log(this.dataMaskToSave);
  }

  datamask(): void {

  }

  insertMaskFromClassDTO(field: any): void {
    if (!field.locked) {
      if (this.bufferIdInfo.length > 0) {
        this.jsonToSendArxivar = {
          document: {
            fileNames: [],
            bufferIds: []
          },
          fields: [],
          notes: this.notes
        };
        this.bufferIdInfo.forEach(file => {
          this.jsonToSendArxivar.document.fileNames.push(file.name);
          this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
        });
      } else {
        this.jsonToSendArxivar = {
          fields: [],
          notes: this.notes
        };
      }
      this.fields.forEach((field1, index) => {
        if (field1.visible) {
          // // // console.log(this.dataMaskToSave);
          if (field1.className === 'AdditionalFieldDateTimeDTO') {
            this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field1.name]);
          } else if (field1.className === 'AdditionalFieldDoubleDTO') {
            if (this.dataMaskToSave[field1.name]) {
              this.fields[index].value = (this.dataMaskToSave[field1.name].toString().replace(',', '.'));
            } else {
              this.fields[index].value = this.dataMaskToSave[field1.name];
            }
          } else {
            this.fields[index].value = this.dataMaskToSave[field1.name];
          }
        }
        this.jsonToSendArxivar.fields.push(this.fields[index]);
      });
      this.fieldName = field.name;
      const dialogRef = this.dialog.open(DialogMaskComponent, {
        width: '80%',
        data: {
          json: this.jsonToSendArxivar,
          id: field.insertMaskId,
          fieldName: field.name,
          dataMask: this.dataMaskToSave,
          dataAssociations: this.dataValueAssociations
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // // // console.log(result);
        if (result.hasOwnProperty('docNumber')) {
          this.docNumberClasseboxTemp = new Array<string>();
          this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
          this.docNumberClasseboxTemp.push(result.docNumber);
          this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);
          this.arrayToObject = {};
          this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
            callResults.fields.forEach(fieldClass => {
              this.displayedColumnsClass.forEach(colum => {
                if (colum === fieldClass.description) {
                  this.arrayToObject[colum] = fieldClass.value;
                }
              });
            });
            this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
            // // // console.log(this.ELEMENT_DATA_CLASS);
          });
        }
      });
    }
  }

  openClassMasks(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
        width: '80%',
        height: 'fit-content',
        data: {
          classField: field,
          mapClassValue: this.mapClasseBookToSave.get(field.name)
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // // console.log(this.mapClasseBookToSave.get(result.fieldName));
      });
    }
  }

  searchMaskFromClassDTO(field: any): void {
    if (!field.locked) {
      const dialogRef = this.dialog.open(DialogSearchDocComponent, {
        width: '80%',
        height: 'fit-content',
        data: {
          classField: field
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        //// // console.log(this.mapClasseBookToSave.get(result.fieldName));
        // // console.log(res);
        res.dataResult.forEach(result => {
          this.docNumberClasseboxTemp = new Array<string>();
          // // console.log(this.mapClasseBookToSave);
          this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
          // // console.log(this.docNumberClasseboxTemp);

          this.docNumberClasseboxTemp.push(result.docNumber);
          this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);
          this.arrayToObject = {};
          this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
            callResults.fields.forEach(fieldClass => {
              this.displayedColumnsClass.forEach(colum => {
                if (colum === fieldClass.description) {
                  this.arrayToObject[colum] = fieldClass.value;
                }
              });
            });
            this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
            // // console.log(this.ELEMENT_DATA_CLASS);
          });
        });
      });
    }
  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }

  showFileSelection(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo.push(this.fileInfo);
        this.badgeBufferMain = this.bufferIdInfo.length;
      });
    }
  }

  showAttachments(): void {
    this.whatToShow = 'attachments';
  }

  showNotes(): void {
    this.whatToShow = 'note';
  }

  showDesk(): void {
    this.whatToShow = 'default';
  }

  deleteNote(note: any): void {
    this.notes.forEach((v, index) => {
      if (v.comment === note.comment) {
        this.notes.splice(index, 1);
        this.snackBar.open('Nota eliminata correttamente', null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
      }
    });
  }

  addNote(): void {
    if (this.addNoteText !== '') {
      this.notes.push({
        aosflag: false,
        comment: this.addNoteText,
        docnumber: null,
        isEdit: false,
        isPaNote: false
      });
      this.snackBar.open('Nota aggiunta correttamente', null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    }
    this.addNoteText = '';
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name + ' eliminato!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  saveMask(): void {
    this.validationCheck = true;
    this.fields.forEach(field => {
      if (field.visible) {
        if (field.required) {
          if (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null) {
            this.snackBar.open('Il campo ' + ((field.description === 'Aoo') ? 'Azienda' : field.description) + ' è obbligatorio', null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
            this.validationCheck = false;
          }
        }
      }
    });
    if (this.validationCheck) {
      this.buildDataMask();
    }
  }


  buildDataMask(): void {
    const attach: Array<string> = new Array<string>();
    this.bufferIdInfo2.forEach(res => {
      attach.push(res.buffer);
    });
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: [],
        notes: this.notes,
        attachments: attach
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: [],
        notes: this.notes,
        attachments: attach
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    // console.log(this.jsonToSendArxivar);
    if (this.idTask === '0') {
      this.apiService.insertProfileToArchive(this.jsonToSendArxivar).subscribe(results => {
        if (results.showMessage) {
          this.snackBar.open('Profilo caricato correttamente con id: ' + results.docNumber, null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.goBack();
        } else {
          this.snackBar.open('Errore nel caricamento del profilo', null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        }
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.apiService.insertTaskByStandard(this.idTask, this.operationId, this.jsonToSendArxivar).subscribe(results => {
        if (results.showMessage) {
          this.snackBar.open('Profilo caricato correttamente con id: ' + results.docNumber, null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.goBack();
        } else {
          this.snackBar.open('Errore nel caricamento del profilo', null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        }
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }
  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    }
    return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
  }


  getJsonFields(): any {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  goBack(): void {
    if (this.idTask !== '0') {
      this.router.navigate(['../dettaglitask', {id: this.idTask, path: this.firstnodepath}], {relativeTo: this.route});
    } else {
      this.ngOnInit();
    }
  }
}

interface City {
  name: string;
  code: string;
}

