import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Output, EventEmitter, Input} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {DatePipe} from '@angular/common';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
    ora: number;

    breakpoint: number;
    Viewdesk: [];
    Maskdesk: [];
    Quikdesk: [];
    Profiledesk: [];
    Modelsdesk: [];
    Folderdesk: [];
    views: [];
    Task: [];
    value = '';
    TaskAttive: [];
    ColonneTask: [];
    nTask = 0;
    panelOpenState = false;
    showFiller = false;
    searchModelMaschere: string;
    searchModelViews: string;
    valueRowHeight: string = 'fit';
    utente = localStorage.getItem('username');

    constructor(private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, public apiService: ApiService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon(
            'RicercaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/search-24px.svg'));
        iconRegistry.addSvgIcon(
            'DoneIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/done-24px.svg'));
        iconRegistry.addSvgIcon(
            'VisteIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/preview-24px.svg'));
        iconRegistry.addSvgIcon(
            'MaschereIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/tab-24px.svg'));
    }

    ngOnInit(): void {
        this.apiService.postTasksId().subscribe(data => {
            // console.log(data);
            this.TaskAttive = data.data;
            this.TaskAttive.forEach(element => {
                this.nTask++;
            });
            this.ColonneTask = data.columns;
        });
        this.breakpoint = (window.innerWidth <= 1080) ? 1 : 3;
        this.valueRowHeight = (window.innerWidth <= 1080) ? '1:1' : 'fit';
        this.ora = Date.now();
        this.apiService.getMasks().subscribe(data => {
            this.Maskdesk = data.masks;
            this.Viewdesk = data.views;
            this.Folderdesk = data.folders;
            this.Modelsdesk = data.models;
            this.Profiledesk = data.profiles;
            this.Quikdesk = data.quickSearches;
        });
        this.apiService.getViews().subscribe(datas => {
            this.views = datas;
        });
        if (this.apiService.homeReload) {
            this.apiService.homeReload = false;
        }

    }

    onResize(event) {
        this.breakpoint = (event.target.innerWidth <= 1080) ? 1 : 3;
        this.valueRowHeight = (window.innerWidth <= 1080) ? '1:1' : 'fit';
    }

    updateDate() {
        this.ora = Date.now();
    }

    updateSearchModelMaschere(value) {
        this.searchModelMaschere = value;
        this.searchModelChange.emit(this.searchModelMaschere);
    }

    updateSearchModelViste(value) {
        this.searchModelViews = value;
        this.searchModelChange.emit(this.searchModelViews);
    }

    clickMaschera(idMask: String) {
        this.router.navigate(['../maschere', {id: idMask}], {relativeTo: this.route});
    }

    clickVista(idView: String) {
        this.router.navigate(['../viste', {id: idView}], {relativeTo: this.route});
    }

    clickTask(idt: string): void {
        this.router.navigate(['../dettaglitask', {id: idt}], {relativeTo: this.route});
    }

    TransformDataFormat(Data: string): string {
        return this.datePipe.transform(Data, 'dd/MM/yyyy, h:mm:ss a', '', 'en-GB');

    }
}
