<div class="center">
    <mat-card class="cardcomponentMasks" >
        <h3 class="titles" style="color:{{this.dashColor}};">
            <mat-card-title><p>Elenco viste:</p></mat-card-title>
            <mat-form-field class="searchdimension"><mat-label><mat-icon svgIcon="RicercaIcon"  style="margin-right: 8px"></mat-icon>Cerca</mat-label>
                <input matInput [ngModel]="searchModelViews" (ngModelChange)="updateSearchModelViste($event)">
                <button mat-button *ngIf="searchModelViews" matSuffix mat-icon-button aria-label="Clear" (click)="searchModelViews=''">
                    <mat-icon>close</mat-icon>
                </button></mat-form-field>
        </h3>
        <hr class="riga" style="color:{{this.dashColor}};"><br>
        <mat-card-content>
        </mat-card-content>
        <cdk-virtual-scroll-viewport itemSize="50" class="cdk" id="style-4" [(searchModel)]="searchModelViews">
            <div  style="text-align: left;">
                <mat-selection-list #viste [multiple]="false" >
                    <ng-container *ngFor="let vista of this.views | vistaFilter: searchModelViews ">
                        <mat-list-item *ngIf="vista.description.startsWith('Registri - ')" style="border-bottom: 1px solid {{this.squareColor}}" (click)= "clickVista(vista.id)" class="item" [value]="vista.id"> <!---->
                            <div mat-line style="color:{{this.squareColor}};"><b>{{vista.description.split("- ").pop()}}</b></div>
                            <div mat-line>Utente: {{vista.userCompleteName}}</div>
                            <!--<mat-icon class="icon"  svgIcon="CondividiIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon"  svgIcon="ModificaIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon" svgIcon="PermessiiIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon" svgIcon="EliminaIcon"  style="margin: 8px"></mat-icon>
                            *ngIf="vista.description.startsWith('Registri - ')"
                            -->
                        </mat-list-item>
                    </ng-container>
                </mat-selection-list>
            </div>
        </cdk-virtual-scroll-viewport>
    </mat-card>



</div>
