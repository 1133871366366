<!-- Resources -->
<div class="center" role="main" style="height: 100%; margin: auto; opacity: 100%" *ngIf = "!this.apiService.isDemo">
  <div class="formo">
    <mat-card class="card">
      <img class="pointer" style="vertical-align: middle; margin-bottom: 5%; max-width: 60%;"
           alt="Logo" [src]="img"/>
      <mat-card-content *ngIf="this.changePasswordVar == false && this.tempLocalUser != 'fornitoreService' ">
        <form *ngIf="this.forgotPassword == false">

          <p *ngIf="this.expired" style="color: red">Sessione scaduta</p>
          <mat-form-field class="full-width-input" appearance="outline" *ngIf = "!this.isMultiFactorAuth">
            <mat-label>Username</mat-label>
            <input matInput [(ngModel)]="username" [formControl]="usernameFormControl" [errorStateMatcher]="matcher"
                   placeholder="Username">
            <mat-error *ngIf="usernameFormControl.hasError('required')">
              Inserisci l'<strong>username</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline" *ngIf = "!this.isMultiFactorAuth">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" [formControl]="passwordFormControl" [errorStateMatcher]="matcher"
                   [type]="hide ? 'password' : 'text'" placeholder="Password">
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              Inserisci la <strong>password</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline" *ngIf = "this.isMultiFactorAuth && this.isOOB && !this.isOTP">
            <mat-label>Inserisci il codice verifica multifattore ricevuto per mail</mat-label>
            <input matInput [(ngModel)]="this.mfaCodeVerifica" [ngModelOptions]="{standalone: true}"
                   placeholder="Inserisci il codice verifica multifattore ricevuto per mail">

          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline" *ngIf = "this.isMultiFactorAuth && this.isOTP && this.isOOB">
            <mat-label>Inserisci il codice verifica multifattore OTP</mat-label>
            <input matInput [(ngModel)]="this.otpCode" [ngModelOptions]="{standalone: true}"
                   placeholder="Inserisci il codice verifica multifattore OTP">

          </mat-form-field>
          <mat-card-actions>
            <button type="submit" class="login-button" (click)="login()" mat-stroked-button
                    *ngIf="this.forgotPassword == false">Accedi</button>
            <button type="submit" class="login-button" (click)="this.retrievePasswordClick()" mat-stroked-button
                    *ngIf="this.forgotPassword == true">Recupera password</button>
          </mat-card-actions>
        </form>
        <form *ngIf="this.forgotPassword == true">
          <h2>Inserisci il nome utente per il recupero della password</h2>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput [(ngModel)]="username" [formControl]="usernameFormControl" [errorStateMatcher]="matcher"
                   placeholder="Username">
            <mat-error *ngIf="usernameFormControl.hasError('required')">
              Inserisci l'username
            </mat-error>
          </mat-form-field>
          <p *ngIf="this.expired == true" style="color: red">Sessione scaduta</p>
          <mat-card-actions>
            <button type="submit" class="login-button" (click)="login()" mat-stroked-button
                    *ngIf="forgotPassword == false">Accedi</button>
            <button type="submit" class="login-button" (click)="retrievePasswordClick()" mat-stroked-button
                    *ngIf="forgotPassword == true">Invia</button>
          </mat-card-actions>
        </form>
        <div style="margin-top: 2%">
          <button class="link" (click)="forgotClicked()"
                  *ngIf="forgotPassword == false">Hai dimenticato la password?
          </button>
          <button mat-stroked-button (click)="forgotClicked()"
                  *ngIf="forgotPassword == true">Indietro</button>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="this.changePasswordVar == true">
        <div style="text-align: center">
          <h2>Cambia password</h2>
          <hr>
          <br>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Vecchia password</mat-label>
            <input matInput required (change)="checkErrors()" [(ngModel)]="this.oldPassword" type="password">
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Nuova password</mat-label>
            <input matInput required (change)="checkErrors()" [(ngModel)]="this.newPassword" type="password">
          </mat-form-field>
          <mat-form-field class="full-width-input" appearance="outline">
            <mat-label>Conferma password</mat-label>
            <input matInput required (change)="checkErrors()" [(ngModel)]="this.confirmPassword" type="password">
          </mat-form-field>
          <button mat-stroked-button (click)="changePassword()">Cambia password</button>
          <br>
          <p style="color: red;">{{this.error}}</p>
        </div>
      </mat-card-content>
      <!--<mat-tab-group headerPosition="below" style = "width: 100%" mat-align-tabs="center">
        <mat-tab style = "width: 100%">
          <ng-template mat-tab-label style = "width: 50%">
            <button type="submit" class="login-button"  mat-stroked-button>Accesso</button>
          </ng-template>

        </mat-tab>

        <mat-tab style = "width: 100%">
          <ng-template mat-tab-label style = "width: 50%">
            <button type="submit" class="login-button" (click)="setSettings()" mat-stroked-button>
              <mat-icon>settings</mat-icon>
            </button>
          </ng-template>
          <div *ngIf="this.isSettingVisible">
            <h2>Impostazioni di connessione ad Arxivar</h2>
            <mat-form-field class="full-width-input" appearance="outline">
              <mat-label>URL</mat-label>
              <input matInput [(ngModel)]="this.URL" placeholder="URL">
            </mat-form-field>
            <mat-form-field class="full-width-input" appearance="outline">
              <mat-label>ClientID</mat-label>
              <input matInput [(ngModel)]="this.ID" placeholder="ClientID">
            </mat-form-field>
            <mat-form-field class="full-width-input" appearance="outline">
              <mat-label>ClientSecret</mat-label>
              <input matInput [(ngModel)]="this.SECRET" placeholder="ClientSecret">
            </mat-form-field>
            <button type="submit" class="login-button" style="width: 40% !important;" (click)="saveConnection()"
                    mat-raised-button >Salva connessione
            </button>
            <h4 style="margin-top: 1%">Versione: 1.1.0</h4>
          </div>
        </mat-tab>
      </mat-tab-group>-->
    </mat-card>
  </div>

</div>

<!-- Resources -->



<footer style="text-align: center">
  <p>
    <span style="color: white">Created by </span>
    <a target="_blank" href="https://www.betsoft-srl.it/" style="color: #3c97bf;">Betsoft S.r.l</a>
  </p>
</footer>

<div class="bg"></div>
<div class="bg bg2"></div>
<div class="bg bg3"></div>


