<div class="center">


    <mat-card id="style-4" class="cardcomponentMasks">
        <div style="display: flex;">
            <button style="float: left;color:{{this.dashColor}};" title="Profila" (click)="saveMask()" mat-icon-button
                    aria-label="icon-button send">
                <mat-icon>search</mat-icon>
            </button>
            <button style="float: left;color:{{this.dashColor}};" title="Cancella campi" (click)="cleanFields()"
                    mat-icon-button
                    aria-label="icon-button send">
                <mat-icon>format_paint</mat-icon>
            </button>
        </div>
        <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
        <br>
        <mat-card-content>

            <mat-tab-group mat-align-tabs="center">

                <mat-tab label="Campi di profilo">
                    <br>
                    <form class="flexform">
                        <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
                        <ng-container *ngFor="let field of fields">
                            <!--mat-grid-tile [colspan]="4"
                                           [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                                           *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
                            <ng-container *ngIf="field.isAdditional == true">

                                <ng-container [ngSwitch]="field.className"
                                              *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                                              style="height:fit-content; width: 100%;">
                                    <!-- NumberFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'NumberFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [ngModelOptions]="{standalone: true}" [id]="field.name"
                                               [(ngModel)]="dataMaskToSave[field.name]"
                                               [required]="field.required"
                                               [disabled]="field.locked" [value]="field.value || ''"
                                               maxlength="{{field.numMaxChar || 100}}"
                                               matInput placeholder="{{field.description}}">
                                    </mat-form-field>
                                    <!-- DocumentDateFieldDTO -->
                                    <div *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                         appearance="outline">
                                        <mat-form-field class="form-field-docType" style="width: 86%"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input style="color:#29648A;" matInput [matDatepicker]="picker"
                                                   [(ngModel)]="dataMaskToSave[field.name]"
                                                   [ngModelOptions]="{standalone: true}"
                                                   [value]="field.value || ''"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked">
                                            <mat-datepicker-toggle matSuffix
                                                                   [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker disabled="false" #picker
                                                            color="primary"></mat-datepicker>
                                        </mat-form-field>
                                        <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                    </div>
                                    <!-- SubjectFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [ngModelOptions]="{standalone: true}"
                                               [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>
                                    <!-- ToFieldDTO -->
                                    <mat-form-field (click)="openAddressBook(field)"
                                                    *ngSwitchCase="'ToFieldDTO'"
                                                    class="form-field" appearance="outline">
                                        <!--A-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <textarea class="style-4" ngModel [ngModelOptions]="{standalone: true}"
                                                  [value]="field.value || ''"
                                                  [id]="field.name" [required]="field.required"
                                                  [disabled]="field.locked"
                                                  maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                  style="resize: none;width:95%;"
                                                  matInput [value]="this.mapAddressBookToSee.get(field.name)"
                                                  placeholder="{{field.description}}"
                                        ></textarea>
                                        <button mat-button matSuffix mat-icon-button
                                                (click)="openAddressBook(field)">
                                            <mat-icon>notes</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <ng-container *ngSwitchCase="'FieldBaseForSearchListDto'">
                                        <mat-form-field  class="form-field"
                                        >
                                            <!--MultiValueDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <mat-chip-list #chipList aria-label="MultiValue" >
                                                <div style = "width: 100%; display: flex;">
                                                    <div style = "width: 80%">
                                                        <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false" [disabled]="field.locked"
                                                                  [removable]="removable" (removed)="remove(element, field.name)">
                                                            {{element}}
                                                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
                                                    <div style = "width: 20%; text-align: right">
                                                        <button  matSuffix mat-icon-button (click)="openMultiValueDTO(field)" style = "color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                                                            <mat-icon>notes</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-chip-list>
                                        </mat-form-field>
                                    </ng-container>
                                    <!-- FromFieldDTO -->
                                    <div *ngSwitchCase="'FieldBaseForSearchContactDto'" class="form-field"
                                         appearance="outline">
                                        <!--DA-->
                                        <mat-form-field (click)="openAddressBook(field)"
                                                        class="form-field-docType"
                                                        style="width: 86%"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <textarea class="style-4" ngModel
                                                      [ngModelOptions]="{standalone: true}"
                                                      [value]="field.value || ''"
                                                      [id]="field.name" [required]="field.required"
                                                      [disabled]="field.locked"
                                                      maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                      style="resize: none;width:95%;"
                                                      matInput
                                                      [value]="this.mapAddressBookToSee.get(field.name)"
                                                      placeholder="{{field.description}}"
                                            ></textarea>
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openAddressBook(field)">
                                                <mat-icon>notes</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                    </div>
                                    <mat-form-field style="width: 86%"
                                                    *ngSwitchCase="'FieldBaseForSearchBoolDto'" class="form-field"
                                                    appearance="outline">
                                        <!--MultiValueDTO-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                    [required]="field.required"
                                                    [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                    [disabled]="field.locked">
                                            <mat-option>---Cancella---</mat-option>
                                            <mat-option [value]="true">SI</mat-option>
                                            <mat-option [value]="false">NO</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- CcFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'CcFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--CC-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>

                                    <!-- BusinessUnitFieldDTO -->
                                    <div *ngSwitchCase="'FieldBaseForSearchAooDto'" class="form-field"
                                         appearance="outline">
                                        <!--Aoo-->
                                        <mat-form-field class="form-field-docType" style="width: 86%"
                                                        appearance="outline">
                                            <mat-label>Azienda</mat-label>
                                            <mat-select [id]="field.name" [id]="field.name"
                                                        [value]="field.value || ''"
                                                        [required]="field.required"
                                                        [(ngModel)]="dataMaskToSave[field.name]"
                                                        name="{{field.name}}"
                                                        [disabled]="field.locked">
                                                <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                                                    {{aoo.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                    </div>

                                    <!-- DocumentTypeFieldDTO -->
                                    <div *ngSwitchCase="'FieldBaseForSearchDocumentTypeDto'" class="form-field">


                                        <mat-form-field class="form-field-docType" style="width: 80%"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <mat-select [value]="field.value || ''" [id]="field.name"
                                                        [required]="field.required"
                                                        [(ngModel)]="dataMaskToSave[field.name]"
                                                        name="{{field.name}}"
                                                        [disabled]="field.locked"
                                            >
                                                <mat-option
                                                        (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                                                        *ngFor="let documenType of documenTypes"
                                                        [value]="documenType.id">
                                                    {{documenType.description}} - {{documenType.key}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                        <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                    <button [disabled]="field.locked" matSuffix mat-icon-button
                                            style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon style="vertical-align: bottom;">more_vert</mat-icon>
                                    </button>
                                </span>
                                    </div>
                                    <!-- OriginFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'OriginFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--Origine-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <mat-select [id]="field.name" [id]="field.name"
                                                    [value]="field.value || ''"
                                                    [required]="field.required"
                                                    [(ngModel)]="dataMaskToSave[field.name]"
                                                    name="{{field.name}}"
                                                    [disabled]="field.locked">
                                            <mat-option *ngFor="let originField of originsFields"
                                                        [value]="originField.value">
                                                {{originField.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <!-- StateFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'StateFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--Stato, dipende da documento-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <mat-select [id]="field.name" [id]="field.name"
                                                    [value]="field.value || ''"
                                                    [required]="field.required"
                                                    [(ngModel)]="dataMaskToSave[field.name]"
                                                    name="{{field.name}}"
                                                    [disabled]="field.locked">
                                            <mat-option *ngFor="let stateField of stateFields"
                                                        [value]="stateField.id">
                                                {{stateField.description}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <!-- BinderFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'BinderFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--Pratiche-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>

                                    <!-- DocumentDateExpiredFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'"
                                                    class="form-field"
                                                    appearance="outline">
                                        <!--Scadenza-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input style="color:#29648A;" [value]="field.value || ''" matInput
                                               [matDatepicker]="picker"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker disabled="false" #picker
                                                        color="primary"></mat-datepicker>

                                    </mat-form-field>

                                    <!-- ImportantFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--Campo "Importante"-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>

                                    <!-- SendersFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'SendersFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--Altri da-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>

                                    <!-- OriginalFieldDTO -->
                                    <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" class="form-field"
                                                    appearance="outline">
                                        <!--Originale-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>

                                    <!-- AdditionalFieldGroupDTO -->
                                    <h3 *ngSwitchCase="'FieldBaseForSearchDTO'" style="width: 90%"
                                        class="form-field"
                                        appearance="outline">
                                        <!--Campo aggiuntivo "Generale"-->
                                        <br>{{field.description}}<br>
                                        <hr>
                                        <br>
                                    </h3>

                                    <!-- AdditionalFieldStringDTO FieldBaseForSearchIntDto-->
                                    <div *ngSwitchCase="'FieldBaseForSearchIntDto'" class="form-field"
                                         appearance="outline">
                                        <!--Tipo documento-->
                                        <mat-form-field class="form-field-docType" style="width: 86%"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <ng-container *ngIf="field.name == 'Origine'">

                                                <mat-select [id]="field.name" [id]="field.name"
                                                            [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let originField of originsFields"
                                                                [value]="originField.value">
                                                        {{originField.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="field.name == 'Autore'">

                                                <mat-select [id]="field.name" [id]="field.name"
                                                            [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let author of this.users"
                                                                [value]="author.user">
                                                        {{author.completeName}}
                                                    </mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="field.name != 'Origine' && field.name != 'Autore'">
                                                <input ngModel [value]="field.value || ''" type="number"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}"></ng-container>
                                        </mat-form-field>
                                        <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                    </div>
                                    <div *ngSwitchCase="'FieldBaseForSearchStringDto'" class="form-field"
                                         appearance="outline">
                                        <!--Tipo documento-->
                                        <mat-form-field class="form-field-docType" style="width: 86%"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <ng-container *ngIf="field.name == 'WorkFlow'">
                                                <mat-select [id]="field.name" [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let wf of this.workFlow"
                                                                [value]="wf.id">
                                                        {{wf.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container *ngIf="field.name == 'Stato'">

                                                <mat-select [id]="field.name" [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let stateField of stateFields"
                                                                [value]="stateField.id">
                                                        {{stateField.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="field.name != 'Stato' && field.name != 'WorkFlow'">

                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">

                                            </ng-container>
                                        </mat-form-field>
                                        <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                    </div>

                                    <!-- AdditionalFieldTableDTO -->
                                    <mat-form-field *ngSwitchCase="'AdditionalFieldTableDTO'" class="form-field"
                                                    appearance="outline" (click)="openTableDTO(field)">
                                        <!--TableDTO-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                        <button mat-button matSuffix mat-icon-button
                                                (click)="openTableDTO(field)">
                                            <mat-icon>table_chart</mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <!-- AdditionalFieldMultivalueDTO -->
                                    <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'"
                                                    class="form-field"
                                                    appearance="outline" (click)="openMultiValueDTO(field)">
                                        <!--MultiValueDTO-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <textarea class="style-4" ngModel
                                                  [value]="field.value || ''"
                                                  [id]="field.name" [required]="field.required"
                                                  [disabled]="field.locked"
                                                  [value]="this.mapMultiValueToSee.get(field.name)"
                                                  [ngModelOptions]="{standalone: true}"
                                                  maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                  style="resize: none;width:95%;"
                                                  matInput
                                                  placeholder="{{field.description}}"
                                        ></textarea>
                                        <button mat-button matSuffix mat-icon-button
                                                (click)="openMultiValueDTO(field)">
                                            <mat-icon>notes</mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <!-- AdditionalFieldComboDTO -->
                                    <mat-form-field *ngSwitchCase="'AdditionalFieldComboDTO'" class="form-field"
                                                    appearance="outline" (click)="openComboValueDTO(field)">
                                        <!--Tipo documento-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                        <button mat-button matSuffix mat-icon-button
                                                (click)="openComboValueDTO(field)">
                                            <mat-icon>list</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field *ngSwitchCase="'AdditionalFieldDateTimeDTO'"
                                                    class="form-field"
                                                    appearance="outline">
                                        <!--AdditionalFieldDateTimeDTO-->
                                        <mat-label>{{field.description}}</mat-label>
                                        <input style="color:#29648A;" [value]="field.value || ''" matInput
                                               [matDatepicker]="picker"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               [id]="field.name" [required]="field.required"
                                               [disabled]="field.locked" (click)="picker.open()">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker disabled="false" #picker
                                                        color="primary"></mat-datepicker>
                                    </mat-form-field>
                                    <!--AdditionalFieldIntDTO-->
                                    <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'" class="form-field"
                                                    appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [id]="field.name" type="number" [required]="field.required"
                                               [disabled]="field.locked"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>
                                    <mat-form-field *ngSwitchCase="'FieldBaseForSearchDoubleDto'" class="form-field"
                                                    appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <input ngModel [value]="field.value || ''"
                                               [id]="field.name" type="number" [required]="field.required"
                                               [disabled]="field.locked"
                                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                               placeholder="{{field.description}}">
                                    </mat-form-field>

                                    <!-- AdditionalFieldClasseDTO -->

                                    <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                                        <div>
                                            <h4 style="float:left;">{{field.description}} </h4>
                                            <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                                                    style="float:right; color:{{this.squareColor}};"
                                                    title="Vedi maschere"
                                                    (click)="openClassMasks(field)" mat-icon-button
                                                    aria-label="icon-button send">
                                                <mat-icon
                                                        [matBadge]="mapClasseBookToSave.get(field.name).length">
                                                    preview
                                                </mat-icon>
                                            </button>
                                            <button style="float:right; color:{{this.squareColor}};"
                                                    title="Inserisci maschera"
                                                    (click)="insertMaskFromClassDTO(field)" mat-icon-button
                                                    aria-label="icon-button send">
                                                <mat-icon>post_add</mat-icon>
                                            </button>
                                            <button style="float:right; color:{{this.squareColor}};"
                                                    title="Cerca maschera"
                                                    (click)="searchMaskFromClassDTO(field)" mat-icon-button
                                                    aria-label="icon-button send">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </div>

                                        <!--div class="z8 example-container">
                                          <table mat-table [dataSource]="dataSourceClass">
                                            <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                                          matColumnDef="{{disCol}}">
                                              <ng-container>
                                                <th mat-header-cell *matHeaderCellDef
                                                    style="font-weight: 600; font-size:14px; color: {{this.squareColor}};">{{disCol}}</th>
                                                <td mat-cell *matCellDef="let element">{{element[disCol]}}
                                                </td>
                                              </ng-container>
                                              <mat-divider [vertical]="true"></mat-divider>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                                          </table>

                                        </div-->
                                    </div>
                                    <!--<mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <input matInput placeholder="{{field.description}}">
                                    </mat-form-field>--> <!--nome timbro, sostitutiva, protocollo pa-->
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="field.isAdditional == false">
                                <ng-container *ngFor="let voce of this.menuNameFields">
                                    <ng-container *ngIf="voce.name == field.name && voce.group == 0">
                                        <ng-container [ngSwitch]="field.className"
                                                      *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                                                      style="height:fit-content; width: 100%;">
                                            <!-- NumberFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'NumberFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [ngModelOptions]="{standalone: true}"
                                                       [id]="field.name"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       [required]="field.required"
                                                       [disabled]="field.locked" [value]="field.value || ''"
                                                       maxlength="{{field.numMaxChar || 100}}"
                                                       matInput placeholder="{{field.description}}">
                                            </mat-form-field>
                                            <!-- DocumentDateFieldDTO -->
                                            <div *ngSwitchCase="'FieldBaseForSearchDateTimeDto'"
                                                 class="form-field"
                                                 appearance="outline">
                                                <mat-form-field class="form-field-docType" style="width: 86%"
                                                                appearance="outline">
                                                    <mat-label>{{field.description}}</mat-label>
                                                    <input style="color:#29648A;" matInput
                                                           [matDatepicker]="picker"
                                                           [(ngModel)]="dataMaskToSave[field.name]"
                                                           [ngModelOptions]="{standalone: true}"
                                                           [value]="field.value || ''"
                                                           [id]="field.name" [required]="field.required"
                                                           [disabled]="field.locked">
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker disabled="false" #picker
                                                                    color="primary"></mat-datepicker>
                                                </mat-form-field>
                                                <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                            </div>
                                            <!-- SubjectFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [ngModelOptions]="{standalone: true}"
                                                       [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>
                                            <!-- ToFieldDTO -->
                                            <mat-form-field (click)="openAddressBook(field)"
                                                            *ngSwitchCase="'ToFieldDTO'"
                                                            class="form-field" appearance="outline">
                                                <!--A-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <textarea class="style-4" ngModel
                                                          [ngModelOptions]="{standalone: true}"
                                                          [value]="field.value || ''"
                                                          [id]="field.name" [required]="field.required"
                                                          [disabled]="field.locked"
                                                          maxlength="{{field.numMaxChar || 100}}" rows="3"
                                                          autosize
                                                          style="resize: none;width:95%;"
                                                          matInput
                                                          [value]="this.mapAddressBookToSee.get(field.name)"
                                                          placeholder="{{field.description}}"
                                                ></textarea>
                                                <button mat-button matSuffix mat-icon-button
                                                        (click)="openAddressBook(field)">
                                                    <mat-icon>notes</mat-icon>
                                                </button>
                                            </mat-form-field>

                                            <!-- FromFieldDTO -->
                                            <div *ngSwitchCase="'FieldBaseForSearchContactDto'"
                                                 class="form-field"
                                                 appearance="outline">
                                                <!--DA-->
                                                <mat-form-field (click)="openAddressBook(field)"
                                                                class="form-field-docType"
                                                                style="width: 86%"
                                                                appearance="outline">
                                                    <mat-label>{{field.description}}</mat-label>
                                                    <textarea class="style-4" ngModel
                                                              [ngModelOptions]="{standalone: true}"
                                                              [value]="field.value || ''"
                                                              [id]="field.name" [required]="field.required"
                                                              [disabled]="field.locked"
                                                              maxlength="{{field.numMaxChar || 100}}" rows="3"
                                                              autosize
                                                              style="resize: none;width:95%;"
                                                              matInput
                                                              [value]="this.mapAddressBookToSee.get(field.name)"
                                                              placeholder="{{field.description}}"
                                                    ></textarea>
                                                    <button mat-button matSuffix mat-icon-button
                                                            (click)="openAddressBook(field)">
                                                        <mat-icon>notes</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                            </div>

                                            <!-- CcFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'CcFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <!--CC-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>

                                            <!-- BusinessUnitFieldDTO -->
                                            <div *ngSwitchCase="'FieldBaseForSearchAooDto'" class="form-field"
                                                 appearance="outline">
                                                <!--Aoo-->
                                                <mat-form-field class="form-field-docType" style="width: 86%"
                                                                appearance="outline">
                                                    <mat-label>Azienda</mat-label>
                                                    <mat-select [id]="field.name" [id]="field.name"
                                                                [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}" (ngModelChange)="aooChanged()"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let aoo of nomiAoo"
                                                                    [value]="aoo.code">
                                                            {{aoo.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                            </div>

                                            <!-- DocumentTypeFieldDTO -->
                                            <div *ngSwitchCase="'FieldBaseForSearchDocumentTypeDto'"
                                                 class="form-field">


                                                <mat-form-field class="form-field-docType" style="width: 80%"
                                                                appearance="outline">
                                                    <mat-label>{{field.description}}</mat-label>
                                                    <mat-select [value]="field.value || ''" [id]="field.name"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked"
                                                    >
                                                        <mat-option
                                                                (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                                                                *ngFor="let documenType of documenTypes"
                                                                [value]="documenType.id">
                                                            {{documenType.description}} - {{documenType.key}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                                <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                    <button [disabled]="field.locked" matSuffix mat-icon-button
                                            style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon style="vertical-align: bottom;">more_vert</mat-icon>
                                    </button>
                                </span>
                                            </div>
                                            <!-- OriginFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'OriginFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <!--Origine-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <mat-select [id]="field.name" [id]="field.name"
                                                            [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let originField of originsFields"
                                                                [value]="originField.value">
                                                        {{originField.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <!-- StateFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'StateFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <!--Stato, dipende da documento-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <mat-select [id]="field.name" [id]="field.name"
                                                            [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let stateField of stateFields"
                                                                [value]="stateField.id">
                                                        {{stateField.description}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <!-- BinderFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'BinderFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <!--Pratiche-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>

                                            <!-- DocumentDateExpiredFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'"
                                                            class="form-field"
                                                            appearance="outline">
                                                <!--Scadenza-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input style="color:#29648A;" [value]="field.value || ''"
                                                       matInput
                                                       [matDatepicker]="picker"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker disabled="false" #picker
                                                                color="primary"></mat-datepicker>

                                            </mat-form-field>

                                            <!-- ImportantFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'ImportantFieldDTO'"
                                                            class="form-field"
                                                            appearance="outline">
                                                <!--Campo "Importante"-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>

                                            <!-- SendersFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'SendersFieldDTO'" class="form-field"
                                                            appearance="outline">
                                                <!--Altri da-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>

                                            <!-- OriginalFieldDTO -->
                                            <mat-form-field *ngSwitchCase="'OriginalFieldDTO'"
                                                            class="form-field"
                                                            appearance="outline">
                                                <!--Originale-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>

                                            <!-- AdditionalFieldGroupDTO -->
                                            <h3 *ngSwitchCase="'FieldBaseForSearchDTO'" style="width: 90%"
                                                class="form-field"
                                                appearance="outline">
                                                <!--Campo aggiuntivo "Generale"-->
                                                <br>{{field.description}}<br>
                                                <hr>
                                                <br>
                                            </h3>

                                            <!-- AdditionalFieldStringDTO FieldBaseForSearchIntDto-->
                                            <div *ngSwitchCase="'FieldBaseForSearchIntDto'" class="form-field"
                                                 appearance="outline">
                                                <!--Tipo documento-->
                                                <mat-form-field class="form-field-docType" style="width: 86%"
                                                                appearance="outline">
                                                    <mat-label>{{field.description}}</mat-label>
                                                    <ng-container *ngIf="field.name == 'Origine'">

                                                        <mat-select [id]="field.name" [id]="field.name"
                                                                    [value]="field.value || ''"
                                                                    [required]="field.required"
                                                                    [(ngModel)]="dataMaskToSave[field.name]"
                                                                    name="{{field.name}}"
                                                                    [disabled]="field.locked">
                                                            <mat-option
                                                                    *ngFor="let originField of originsFields"
                                                                    [value]="originField.value">
                                                                {{originField.description}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.name == 'Autore'">

                                                        <mat-select [id]="field.name" [id]="field.name"
                                                                    [value]="field.value || ''"
                                                                    [required]="field.required"
                                                                    [(ngModel)]="dataMaskToSave[field.name]"
                                                                    name="{{field.name}}"
                                                                    [disabled]="field.locked">
                                                            <mat-option *ngFor="let author of this.users"
                                                                        [value]="author.user">
                                                                {{author.completeName}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </ng-container>
                                                    <ng-container
                                                            *ngIf="field.name != 'Origine' && field.name != 'Autore'">
                                                        <input ngModel [value]="field.value || ''" type="number"
                                                               [(ngModel)]="dataMaskToSave[field.name]"
                                                               name="{{field.name}}"
                                                               [id]="field.name" [required]="field.required"
                                                               [disabled]="field.locked"
                                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                                               placeholder="{{field.description}}">
                                                    </ng-container>
                                                </mat-form-field>
                                                <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                            </div>
                                            <div *ngSwitchCase="'FieldBaseForSearchStringDto'"
                                                 class="form-field"
                                                 appearance="outline">
                                                <!--Tipo documento-->
                                                <mat-form-field class="form-field-docType" style="width: 86%"
                                                                appearance="outline">
                                                    <mat-label>{{field.description}}</mat-label>
                                                    <ng-container *ngIf="field.name == 'WorkFlow'">
                                                        <mat-select [id]="field.name"
                                                                    [value]="field.value || ''"
                                                                    [required]="field.required"
                                                                    [(ngModel)]="dataMaskToSave[field.name]"
                                                                    name="{{field.name}}"
                                                                    [disabled]="field.locked">
                                                            <mat-option *ngFor="let wf of this.workFlow"
                                                                        [value]="wf.id">
                                                                {{wf.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.name == 'Stato'">

                                                        <mat-select [id]="field.name"
                                                                    [value]="field.value || ''"
                                                                    [required]="field.required"
                                                                    [(ngModel)]="dataMaskToSave[field.name]"
                                                                    name="{{field.name}}"
                                                                    [disabled]="field.locked">
                                                            <mat-option *ngFor="let stateField of stateFields"
                                                                        [value]="stateField.id">
                                                                {{stateField.description}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </ng-container>
                                                    <ng-container
                                                            *ngIf="field.name != 'Stato' && field.name != 'WorkFlow'">

                                                        <input ngModel [value]="field.value || ''"
                                                               [(ngModel)]="dataMaskToSave[field.name]"
                                                               name="{{field.name}}"
                                                               [id]="field.name" [required]="field.required"
                                                               [disabled]="field.locked"
                                                               maxlength="{{field.numMaxChar || 100}}" matInput
                                                               placeholder="{{field.description}}">

                                                    </ng-container>
                                                </mat-form-field>
                                                <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                            </div>

                                            <!-- AdditionalFieldTableDTO -->
                                            <mat-form-field *ngSwitchCase="'AdditionalFieldTableDTO'"
                                                            class="form-field"
                                                            appearance="outline" (click)="openTableDTO(field)">
                                                <!--TableDTO-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                                <button mat-button matSuffix mat-icon-button
                                                        (click)="openTableDTO(field)">
                                                    <mat-icon>table_chart</mat-icon>
                                                </button>
                                            </mat-form-field>

                                            <!-- AdditionalFieldMultivalueDTO -->
                                            <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'"
                                                            class="form-field"
                                                            appearance="outline"
                                                            (click)="openMultiValueDTO(field)">
                                                <!--MultiValueDTO-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <textarea class="style-4" ngModel
                                                          [value]="field.value || ''"
                                                          [id]="field.name" [required]="field.required"
                                                          [disabled]="field.locked"
                                                          [value]="this.mapMultiValueToSee.get(field.name)"
                                                          [ngModelOptions]="{standalone: true}"
                                                          maxlength="{{field.numMaxChar || 100}}" rows="3"
                                                          autosize
                                                          style="resize: none;width:95%;"
                                                          matInput
                                                          placeholder="{{field.description}}"
                                                ></textarea>
                                                <button mat-button matSuffix mat-icon-button
                                                        (click)="openMultiValueDTO(field)">
                                                    <mat-icon>notes</mat-icon>
                                                </button>
                                            </mat-form-field>

                                            <!-- AdditionalFieldComboDTO -->
                                            <mat-form-field *ngSwitchCase="'AdditionalFieldComboDTO'"
                                                            class="form-field"
                                                            appearance="outline"
                                                            (click)="openComboValueDTO(field)">
                                                <!--Tipo documento-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                                <button mat-button matSuffix mat-icon-button
                                                        (click)="openComboValueDTO(field)">
                                                    <mat-icon>list</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <mat-form-field *ngSwitchCase="'AdditionalFieldDateTimeDTO'"
                                                            class="form-field"
                                                            appearance="outline">
                                                <!--AdditionalFieldDateTimeDTO-->
                                                <mat-label>{{field.description}}</mat-label>
                                                <input style="color:#29648A;" [value]="field.value || ''"
                                                       matInput
                                                       [matDatepicker]="picker"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked" (click)="picker.open()">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker disabled="false" #picker
                                                                color="primary"></mat-datepicker>
                                            </mat-form-field>
                                            <!--AdditionalFieldIntDTO-->
                                            <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'"
                                                            class="form-field"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <input ngModel [value]="field.value || ''"
                                                       [id]="field.name" type="number"
                                                       [required]="field.required"
                                                       [disabled]="field.locked"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       name="{{field.name}}"
                                                       maxlength="{{field.numMaxChar || 100}}" matInput
                                                       placeholder="{{field.description}}">
                                            </mat-form-field>

                                            <!-- AdditionalFieldClasseDTO -->

                                            <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                                                <div>
                                                    <h4 style="float:left;">{{field.description}} </h4>
                                                    <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                                                            style="float:right; color:{{this.squareColor}};"
                                                            title="Vedi maschere"
                                                            (click)="openClassMasks(field)" mat-icon-button
                                                            aria-label="icon-button send">
                                                        <mat-icon
                                                                [matBadge]="mapClasseBookToSave.get(field.name).length">
                                                            preview
                                                        </mat-icon>
                                                    </button>
                                                    <button style="float:right; color:{{this.squareColor}};"
                                                            title="Inserisci maschera"
                                                            (click)="insertMaskFromClassDTO(field)"
                                                            mat-icon-button
                                                            aria-label="icon-button send">
                                                        <mat-icon>post_add</mat-icon>
                                                    </button>
                                                    <button style="float:right; color:{{this.squareColor}};"
                                                            title="Cerca maschera"
                                                            (click)="searchMaskFromClassDTO(field)"
                                                            mat-icon-button
                                                            aria-label="icon-button send">
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                </div>

                                                <!--div class="z8 example-container">
                                                  <table mat-table [dataSource]="dataSourceClass">
                                                    <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                                                  matColumnDef="{{disCol}}">
                                                      <ng-container>
                                                        <th mat-header-cell *matHeaderCellDef
                                                            style="font-weight: 600; font-size:14px; color: {{this.squareColor}};">{{disCol}}</th>
                                                        <td mat-cell *matCellDef="let element">{{element[disCol]}}
                                                        </td>
                                                      </ng-container>
                                                      <mat-divider [vertical]="true"></mat-divider>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                                                  </table>

                                                </div-->
                                            </div>
                                            <!--<mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <input matInput placeholder="{{field.description}}">
                                            </mat-form-field>--> <!--nome timbro, sostitutiva, protocollo pa-->
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>


                        <!--/mat-grid-list-->
                        <mat-card-actions>
                        </mat-card-actions>


                    </form>

                </mat-tab>
                <mat-tab label="Campi di sistema"><br>

                    <form class="flexform">
                        <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
                        <ng-container *ngFor="let field of fields">
                            <!--mat-grid-tile [colspan]="4"
                                           [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                                           *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
                            <ng-container *ngFor="let voce of this.menuNameFields">
                                <ng-container *ngIf="voce.name == field.name && voce.group == 1">
                                    <ng-container [ngSwitch]="field.className"
                                                  *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                                                  style="height:fit-content; width: 100%;">
                                        <!-- NumberFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'NumberFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [ngModelOptions]="{standalone: true}" [id]="field.name"
                                                   [(ngModel)]="dataMaskToSave[field.name]"
                                                   [required]="field.required"
                                                   [disabled]="field.locked" [value]="field.value || ''"
                                                   maxlength="{{field.numMaxChar || 100}}"
                                                   matInput placeholder="{{field.description}}">
                                        </mat-form-field>
                                        <!-- DocumentDateFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                             appearance="outline">
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <input style="color:#29648A;" matInput [matDatepicker]="picker"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [value]="field.value || ''"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker disabled="false" #picker
                                                                color="primary"></mat-datepicker>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>
                                        <!-- SubjectFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [ngModelOptions]="{standalone: true}"
                                                   [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>
                                        <!-- ToFieldDTO -->
                                        <mat-form-field (click)="openAddressBook(field)"
                                                        *ngSwitchCase="'ToFieldDTO'"
                                                        class="form-field" appearance="outline">
                                            <!--A-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <textarea class="style-4" ngModel [ngModelOptions]="{standalone: true}"
                                                      [value]="field.value || ''"
                                                      [id]="field.name" [required]="field.required"
                                                      [disabled]="field.locked"
                                                      maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                      style="resize: none;width:95%;"
                                                      matInput [value]="this.mapAddressBookToSee.get(field.name)"
                                                      placeholder="{{field.description}}"
                                            ></textarea>
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openAddressBook(field)">
                                                <mat-icon>notes</mat-icon>
                                            </button>
                                        </mat-form-field>

                                        <!-- FromFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchContactDto'" class="form-field"
                                             appearance="outline">
                                            <!--DA-->
                                            <mat-form-field (click)="openAddressBook(field)"
                                                            class="form-field-docType"
                                                            style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <textarea class="style-4" ngModel
                                                          [ngModelOptions]="{standalone: true}"
                                                          [value]="field.value || ''"
                                                          [id]="field.name" [required]="field.required"
                                                          [disabled]="field.locked"
                                                          maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                          style="resize: none;width:95%;"
                                                          matInput
                                                          [value]="this.mapAddressBookToSee.get(field.name)"
                                                          placeholder="{{field.description}}"
                                                ></textarea>
                                                <button mat-button matSuffix mat-icon-button
                                                        (click)="openAddressBook(field)">
                                                    <mat-icon>notes</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>

                                        <!-- CcFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'CcFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--CC-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- BusinessUnitFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchAooDto'" class="form-field"
                                             appearance="outline">
                                            <!--Aoo-->
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>Azienda</mat-label>
                                                <mat-select [id]="field.name" [id]="field.name"
                                                            [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                                                        {{aoo.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>

                                        <!-- DocumentTypeFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchDocumentTypeDto'" class="form-field">


                                            <mat-form-field class="form-field-docType" style="width: 80%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <mat-select [value]="field.value || ''" [id]="field.name"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked"
                                                >
                                                    <mat-option
                                                            (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                                                            *ngFor="let documenType of documenTypes"
                                                            [value]="documenType.id">
                                                        {{documenType.description}} - {{documenType.key}}
                                                    </mat-option>
                                                </mat-select>

                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                    <button [disabled]="field.locked" matSuffix mat-icon-button
                                            style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon style="vertical-align: bottom;">more_vert</mat-icon>
                                    </button>
                                </span>
                                        </div>
                                        <!-- OriginFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'OriginFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Origine-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <mat-select [id]="field.name" [id]="field.name"
                                                        [value]="field.value || ''"
                                                        [required]="field.required"
                                                        [(ngModel)]="dataMaskToSave[field.name]"
                                                        name="{{field.name}}"
                                                        [disabled]="field.locked">
                                                <mat-option *ngFor="let originField of originsFields"
                                                            [value]="originField.value">
                                                    {{originField.description}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- StateFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'StateFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Stato, dipende da documento-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <mat-select [id]="field.name" [id]="field.name"
                                                        [value]="field.value || ''"
                                                        [required]="field.required"
                                                        [(ngModel)]="dataMaskToSave[field.name]"
                                                        name="{{field.name}}"
                                                        [disabled]="field.locked">
                                                <mat-option *ngFor="let stateField of stateFields"
                                                            [value]="stateField.id">
                                                    {{stateField.description}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- BinderFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'BinderFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Pratiche-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- DocumentDateExpiredFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'"
                                                        class="form-field"
                                                        appearance="outline">
                                            <!--Scadenza-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input style="color:#29648A;" [value]="field.value || ''" matInput
                                                   [matDatepicker]="picker"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker disabled="false" #picker
                                                            color="primary"></mat-datepicker>

                                        </mat-form-field>

                                        <!-- ImportantFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Campo "Importante"-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- SendersFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'SendersFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Altri da-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- OriginalFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Originale-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- AdditionalFieldGroupDTO -->
                                        <h3 *ngSwitchCase="'FieldBaseForSearchDTO'"
                                            style="width: 90%; color: {{this.dashColor}}"
                                            class="form-field"
                                            appearance="outline">
                                            <!--Campo aggiuntivo "Generale"-->
                                            <br>{{field.description}}<br>
                                            <hr>
                                            <br>
                                        </h3>

                                        <!-- AdditionalFieldStringDTO FieldBaseForSearchIntDto-->
                                        <div *ngSwitchCase="'FieldBaseForSearchIntDto'" class="form-field"
                                             appearance="outline">
                                            <!--Tipo documento-->
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <ng-container *ngIf="field.name == 'Origine'">

                                                    <mat-select [id]="field.name" [id]="field.name"
                                                                [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let originField of originsFields"
                                                                    [value]="originField.value">
                                                            {{originField.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container *ngIf="field.name == 'Autore'">

                                                    <mat-select [id]="field.name" [id]="field.name"
                                                                [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let author of this.users"
                                                                    [value]="author.user">
                                                            {{author.completeName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="field.name != 'Origine' && field.name != 'Autore'">
                                                    <input ngModel [value]="field.value || ''" type="number"
                                                           [(ngModel)]="dataMaskToSave[field.name]"
                                                           name="{{field.name}}"
                                                           [id]="field.name" [required]="field.required"
                                                           [disabled]="field.locked"
                                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                                           placeholder="{{field.description}}"></ng-container>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>
                                        <div *ngSwitchCase="'FieldBaseForSearchStringDto'" class="form-field"
                                             appearance="outline">
                                            <!--Tipo documento-->
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <ng-container *ngIf="field.name == 'WorkFlow'">
                                                    <mat-select [id]="field.name" [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let wf of this.workFlow"
                                                                    [value]="wf.id">
                                                            {{wf.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container *ngIf="field.name == 'Stato'">

                                                    <mat-select [id]="field.name" [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let stateField of stateFields"
                                                                    [value]="stateField.id">
                                                            {{stateField.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="field.name != 'Stato' && field.name != 'WorkFlow'">

                                                    <input ngModel [value]="field.value || ''"
                                                           [(ngModel)]="dataMaskToSave[field.name]"
                                                           name="{{field.name}}"
                                                           [id]="field.name" [required]="field.required"
                                                           [disabled]="field.locked"
                                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                                           placeholder="{{field.description}}">

                                                </ng-container>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>

                                        <!-- AdditionalFieldTableDTO -->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldTableDTO'" class="form-field"
                                                        appearance="outline" (click)="openTableDTO(field)">
                                            <!--TableDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openTableDTO(field)">
                                                <mat-icon>table_chart</mat-icon>
                                            </button>
                                        </mat-form-field>

                                        <!-- AdditionalFieldMultivalueDTO -->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'"
                                                        class="form-field"
                                                        appearance="outline" (click)="openMultiValueDTO(field)">
                                            <!--MultiValueDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <textarea class="style-4" ngModel
                                                      [value]="field.value || ''"
                                                      [id]="field.name" [required]="field.required"
                                                      [disabled]="field.locked"
                                                      [value]="this.mapMultiValueToSee.get(field.name)"
                                                      [ngModelOptions]="{standalone: true}"
                                                      maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                      style="resize: none;width:95%;"
                                                      matInput
                                                      placeholder="{{field.description}}"
                                            ></textarea>
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openMultiValueDTO(field)">
                                                <mat-icon>notes</mat-icon>
                                            </button>
                                        </mat-form-field>

                                        <!-- AdditionalFieldComboDTO -->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldComboDTO'" class="form-field"
                                                        appearance="outline" (click)="openComboValueDTO(field)">
                                            <!--Tipo documento-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openComboValueDTO(field)">
                                                <mat-icon>list</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldDateTimeDTO'"
                                                        class="form-field"
                                                        appearance="outline">
                                            <!--AdditionalFieldDateTimeDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input style="color:#29648A;" [value]="field.value || ''" matInput
                                                   [matDatepicker]="picker"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked" (click)="picker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker disabled="false" #picker
                                                            color="primary"></mat-datepicker>
                                        </mat-form-field>
                                        <!--AdditionalFieldIntDTO-->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'" class="form-field"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [id]="field.name" type="number" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- AdditionalFieldClasseDTO -->

                                        <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                                            <div>
                                                <h4 style="float:left;">{{field.description}} </h4>
                                                <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                                                        style="float:right; color:{{this.squareColor}};"
                                                        title="Vedi maschere"
                                                        (click)="openClassMasks(field)" mat-icon-button
                                                        aria-label="icon-button send">
                                                    <mat-icon
                                                            [matBadge]="mapClasseBookToSave.get(field.name).length">
                                                        preview
                                                    </mat-icon>
                                                </button>
                                                <button style="float:right; color:{{this.squareColor}};"
                                                        title="Inserisci maschera"
                                                        (click)="insertMaskFromClassDTO(field)" mat-icon-button
                                                        aria-label="icon-button send">
                                                    <mat-icon>post_add</mat-icon>
                                                </button>
                                                <button style="float:right; color:{{this.squareColor}};"
                                                        title="Cerca maschera"
                                                        (click)="searchMaskFromClassDTO(field)" mat-icon-button
                                                        aria-label="icon-button send">
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                            </div>

                                            <!--div class="z8 example-container">
                                              <table mat-table [dataSource]="dataSourceClass">
                                                <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                                              matColumnDef="{{disCol}}">
                                                  <ng-container>
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="font-weight: 600; font-size:14px; color: {{this.squareColor}};">{{disCol}}</th>
                                                    <td mat-cell *matCellDef="let element">{{element[disCol]}}
                                                    </td>
                                                  </ng-container>
                                                  <mat-divider [vertical]="true"></mat-divider>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                                              </table>

                                            </div-->
                                        </div>
                                        <!--<mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input matInput placeholder="{{field.description}}">
                                        </mat-form-field>--> <!--nome timbro, sostitutiva, protocollo pa-->
                                    </ng-container>
                                </ng-container>

                            </ng-container>
                        </ng-container>

                        <!--/mat-grid-list-->
                        <mat-card-actions>
                        </mat-card-actions>

                    </form>

                </mat-tab>
                <mat-tab label="Avanzate"><br>

                    <form class="flexform">
                        <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
                        <ng-container *ngFor="let field of fields">
                            <!--mat-grid-tile [colspan]="4"
                                           [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                                           *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
                            <ng-container *ngFor="let voce of this.menuNameFields">
                                <ng-container *ngIf="voce.name == field.name && voce.group == 2">
                                    <ng-container [ngSwitch]="field.className"
                                                  *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                                                  style="height:fit-content; width: 100%;">
                                        <!-- NumberFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'NumberFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [ngModelOptions]="{standalone: true}" [id]="field.name"
                                                   [(ngModel)]="dataMaskToSave[field.name]"
                                                   [required]="field.required"
                                                   [disabled]="field.locked" [value]="field.value || ''"
                                                   maxlength="{{field.numMaxChar || 100}}"
                                                   matInput placeholder="{{field.description}}">
                                        </mat-form-field>
                                        <!-- DocumentDateFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchDateTimeDto'" class="form-field"
                                             appearance="outline">
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <input style="color:#29648A;" matInput [matDatepicker]="picker"
                                                       [(ngModel)]="dataMaskToSave[field.name]"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [value]="field.value || ''"
                                                       [id]="field.name" [required]="field.required"
                                                       [disabled]="field.locked">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker disabled="false" #picker
                                                                color="primary"></mat-datepicker>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>
                                        <!-- SubjectFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [ngModelOptions]="{standalone: true}"
                                                   [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>
                                        <!-- ToFieldDTO -->
                                        <mat-form-field (click)="openAddressBook(field)"
                                                        *ngSwitchCase="'ToFieldDTO'"
                                                        class="form-field" appearance="outline">
                                            <!--A-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <textarea class="style-4" ngModel [ngModelOptions]="{standalone: true}"
                                                      [value]="field.value || ''"
                                                      [id]="field.name" [required]="field.required"
                                                      [disabled]="field.locked"
                                                      maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                      style="resize: none;width:95%;"
                                                      matInput [value]="this.mapAddressBookToSee.get(field.name)"
                                                      placeholder="{{field.description}}"
                                            ></textarea>
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openAddressBook(field)">
                                                <mat-icon>notes</mat-icon>
                                            </button>
                                        </mat-form-field>

                                        <!-- FromFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchContactDto'" class="form-field"
                                             appearance="outline">
                                            <!--DA-->
                                            <mat-form-field (click)="openAddressBook(field)"
                                                            class="form-field-docType"
                                                            style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <textarea class="style-4" ngModel
                                                          [ngModelOptions]="{standalone: true}"
                                                          [value]="field.value || ''"
                                                          [id]="field.name" [required]="field.required"
                                                          [disabled]="field.locked"
                                                          maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                          style="resize: none;width:95%;"
                                                          matInput
                                                          [value]="this.mapAddressBookToSee.get(field.name)"
                                                          placeholder="{{field.description}}"
                                                ></textarea>
                                                <button mat-button matSuffix mat-icon-button
                                                        (click)="openAddressBook(field)">
                                                    <mat-icon>notes</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanFieldContact(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>

                                        <!-- CcFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'CcFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--CC-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- BusinessUnitFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchAooDto'" class="form-field"
                                             appearance="outline">
                                            <!--Aoo-->
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>Azienda</mat-label>
                                                <mat-select [id]="field.name" [id]="field.name"
                                                            [value]="field.value || ''"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked">
                                                    <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                                                        {{aoo.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>

                                        <!-- DocumentTypeFieldDTO -->
                                        <div *ngSwitchCase="'FieldBaseForSearchDocumentTypeDto'" class="form-field">


                                            <mat-form-field class="form-field-docType" style="width: 80%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <mat-select [value]="field.value || ''" [id]="field.name"
                                                            [required]="field.required"
                                                            [(ngModel)]="dataMaskToSave[field.name]"
                                                            name="{{field.name}}"
                                                            [disabled]="field.locked"
                                                >
                                                    <mat-option
                                                            (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                                                            *ngFor="let documenType of documenTypes"
                                                            [value]="documenType.id">
                                                        {{documenType.description}} - {{documenType.key}}
                                                    </mat-option>
                                                </mat-select>

                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                    <button [disabled]="field.locked" matSuffix mat-icon-button
                                            style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon style="vertical-align: bottom;">more_vert</mat-icon>
                                    </button>
                                </span>
                                        </div>
                                        <!-- OriginFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'OriginFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Origine-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <mat-select [id]="field.name" [id]="field.name"
                                                        [value]="field.value || ''"
                                                        [required]="field.required"
                                                        [(ngModel)]="dataMaskToSave[field.name]"
                                                        name="{{field.name}}"
                                                        [disabled]="field.locked">
                                                <mat-option *ngFor="let originField of originsFields"
                                                            [value]="originField.value">
                                                    {{originField.description}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- StateFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'StateFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Stato, dipende da documento-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <mat-select [id]="field.name" [id]="field.name"
                                                        [value]="field.value || ''"
                                                        [required]="field.required"
                                                        [(ngModel)]="dataMaskToSave[field.name]"
                                                        name="{{field.name}}"
                                                        [disabled]="field.locked">
                                                <mat-option *ngFor="let stateField of stateFields"
                                                            [value]="stateField.id">
                                                    {{stateField.description}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- BinderFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'BinderFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Pratiche-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- DocumentDateExpiredFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'"
                                                        class="form-field"
                                                        appearance="outline">
                                            <!--Scadenza-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input style="color:#29648A;" [value]="field.value || ''" matInput
                                                   [matDatepicker]="picker"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker disabled="false" #picker
                                                            color="primary"></mat-datepicker>

                                        </mat-form-field>

                                        <!-- ImportantFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Campo "Importante"-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- SendersFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'SendersFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Altri da-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- OriginalFieldDTO -->
                                        <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" class="form-field"
                                                        appearance="outline">
                                            <!--Originale-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- AdditionalFieldGroupDTO -->
                                        <h3 *ngSwitchCase="'FieldBaseForSearchDTO'" style="width: 90%"
                                            class="form-field"
                                            appearance="outline">
                                            <!--Campo aggiuntivo "Generale"-->
                                            <br>{{field.description}}<br>
                                            <hr>
                                            <br>
                                        </h3>

                                        <!-- AdditionalFieldStringDTO FieldBaseForSearchIntDto-->
                                        <div *ngSwitchCase="'FieldBaseForSearchIntDto'" class="form-field"
                                             appearance="outline">
                                            <!--Tipo documento-->
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <ng-container *ngIf="field.name == 'Origine'">

                                                    <mat-select [id]="field.name" [id]="field.name"
                                                                [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let originField of originsFields"
                                                                    [value]="originField.value">
                                                            {{originField.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container *ngIf="field.name == 'Autore'">

                                                    <mat-select [id]="field.name" [id]="field.name"
                                                                [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let author of this.users"
                                                                    [value]="author.user">
                                                            {{author.completeName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="field.name != 'Origine' && field.name != 'Autore'">
                                                    <input ngModel [value]="field.value || ''" type="number"
                                                           [(ngModel)]="dataMaskToSave[field.name]"
                                                           name="{{field.name}}"
                                                           [id]="field.name" [required]="field.required"
                                                           [disabled]="field.locked"
                                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                                           placeholder="{{field.description}}"></ng-container>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>
                                        <div *ngSwitchCase="'FieldBaseForSearchStringDto'" class="form-field"
                                             appearance="outline">
                                            <!--Tipo documento-->
                                            <mat-form-field class="form-field-docType" style="width: 86%"
                                                            appearance="outline">
                                                <mat-label>{{field.description}}</mat-label>
                                                <ng-container *ngIf="field.name == 'WorkFlow'">
                                                    <mat-select [id]="field.name" [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let wf of this.workFlow"
                                                                    [value]="wf.id">
                                                            {{wf.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container *ngIf="field.name == 'Stato'">

                                                    <mat-select [id]="field.name" [value]="field.value || ''"
                                                                [required]="field.required"
                                                                [(ngModel)]="dataMaskToSave[field.name]"
                                                                name="{{field.name}}"
                                                                [disabled]="field.locked">
                                                        <mat-option *ngFor="let stateField of stateFields"
                                                                    [value]="stateField.id">
                                                            {{stateField.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="field.name != 'Stato' && field.name != 'WorkFlow'">

                                                    <input ngModel [value]="field.value || ''"
                                                           [(ngModel)]="dataMaskToSave[field.name]"
                                                           name="{{field.name}}"
                                                           [id]="field.name" [required]="field.required"
                                                           [disabled]="field.locked"
                                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                                           placeholder="{{field.description}}">

                                                </ng-container>
                                            </mat-form-field>
                                            <span>
                                    <button style="width: 7%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon style="vertical-align: bottom;">cleaning_services</mat-icon>
                                    </button>
                                </span>
                                        </div>

                                        <!-- AdditionalFieldTableDTO -->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldTableDTO'" class="form-field"
                                                        appearance="outline" (click)="openTableDTO(field)">
                                            <!--TableDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openTableDTO(field)">
                                                <mat-icon>table_chart</mat-icon>
                                            </button>
                                        </mat-form-field>

                                        <!-- AdditionalFieldMultivalueDTO -->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'"
                                                        class="form-field"
                                                        appearance="outline" (click)="openMultiValueDTO(field)">
                                            <!--MultiValueDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <textarea class="style-4" ngModel
                                                      [value]="field.value || ''"
                                                      [id]="field.name" [required]="field.required"
                                                      [disabled]="field.locked"
                                                      [value]="this.mapMultiValueToSee.get(field.name)"
                                                      [ngModelOptions]="{standalone: true}"
                                                      maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                                      style="resize: none;width:95%;"
                                                      matInput
                                                      placeholder="{{field.description}}"
                                            ></textarea>
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openMultiValueDTO(field)">
                                                <mat-icon>notes</mat-icon>
                                            </button>
                                        </mat-form-field>

                                        <!-- AdditionalFieldComboDTO -->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldComboDTO'" class="form-field"
                                                        appearance="outline" (click)="openComboValueDTO(field)">
                                            <!--Tipo documento-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                            <button mat-button matSuffix mat-icon-button
                                                    (click)="openComboValueDTO(field)">
                                                <mat-icon>list</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldDateTimeDTO'"
                                                        class="form-field"
                                                        appearance="outline">
                                            <!--AdditionalFieldDateTimeDTO-->
                                            <mat-label>{{field.description}}</mat-label>
                                            <input style="color:#29648A;" [value]="field.value || ''" matInput
                                                   [matDatepicker]="picker"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   [id]="field.name" [required]="field.required"
                                                   [disabled]="field.locked" (click)="picker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker disabled="false" #picker
                                                            color="primary"></mat-datepicker>
                                        </mat-form-field>
                                        <!--AdditionalFieldIntDTO-->
                                        <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'" class="form-field"
                                                        appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input ngModel [value]="field.value || ''"
                                                   [id]="field.name" type="number" [required]="field.required"
                                                   [disabled]="field.locked"
                                                   [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                   maxlength="{{field.numMaxChar || 100}}" matInput
                                                   placeholder="{{field.description}}">
                                        </mat-form-field>

                                        <!-- AdditionalFieldClasseDTO -->


                                        <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                                            <div>
                                                <h4 style="float:left;">{{field.description}} </h4>
                                                <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                                                        style="float:right; color:{{this.squareColor}};"
                                                        title="Vedi maschere"
                                                        (click)="openClassMasks(field)" mat-icon-button
                                                        aria-label="icon-button send">
                                                    <mat-icon
                                                            [matBadge]="mapClasseBookToSave.get(field.name).length">
                                                        preview
                                                    </mat-icon>
                                                </button>
                                                <button style="float:right; color:{{this.squareColor}};"
                                                        title="Inserisci maschera"
                                                        (click)="insertMaskFromClassDTO(field)" mat-icon-button
                                                        aria-label="icon-button send">
                                                    <mat-icon>post_add</mat-icon>
                                                </button>
                                                <button style="float:right; color:{{this.squareColor}};"
                                                        title="Cerca maschera"
                                                        (click)="searchMaskFromClassDTO(field)" mat-icon-button
                                                        aria-label="icon-button send">
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                            </div>

                                            <!--div class="z8 example-container">
                                              <table mat-table [dataSource]="dataSourceClass">
                                                <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                                              matColumnDef="{{disCol}}">
                                                  <ng-container>
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="font-weight: 600; font-size:14px; color: {{this.squareColor}};">{{disCol}}</th>
                                                    <td mat-cell *matCellDef="let element">{{element[disCol]}}
                                                    </td>
                                                  </ng-container>
                                                  <mat-divider [vertical]="true"></mat-divider>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                                              </table>

                                            </div-->
                                        </div>
                                        <!--<mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                                            <mat-label>{{field.description}}</mat-label>
                                            <input matInput placeholder="{{field.description}}">
                                        </mat-form-field>--> <!--nome timbro, sostitutiva, protocollo pa-->
                                    </ng-container>
                                </ng-container>

                            </ng-container>
                        </ng-container>

                        <!--/mat-grid-list-->
                        <mat-card-actions>
                        </mat-card-actions>

                    </form>

                </mat-tab>
            </mat-tab-group>

        </mat-card-content>

    </mat-card>
</div>
