import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Output, EventEmitter, Input} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {DatePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {interval} from 'rxjs';

@Component({
    selector: 'app-home-v2',
    templateUrl: './home-v2.component.html',
    styleUrls: ['./home-v2.component.css']
})
export class HomeV2Component implements OnInit {
    @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
    ora: number;
    panelOpenState = false;
    breakpoint: number;
    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;
    Viewdesk: [];
    Maskdesk: [];
    Quikdesk: [];
    Profiledesk: [];
    Modelsdesk: [];
    Folderdesk: [];
    views: [];
    documentFields: Array<any> = new Array<any>();
    Task: [];
    value = '';
    activeDocNumber: string;
    TaskAttive: [];
    ColonneTask: [];
    sub: any;
    nTask = 0;
    todayDate = new Date();
    showFiller = false;
    allTasksOpen: boolean;
    priorityTasksOpen: boolean;
    expiredTasksOpen: boolean;
    searchModelMaschere: string;
    searchModelViews: string;
    valueRowHeight = 'fit';
    utente = localStorage.getItem('username');

    // tslint:disable-next-line:max-line-length
    constructor(private snackBar: MatSnackBar, private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, public apiService: ApiService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon(
            'RicercaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/search-24px.svg'));
        iconRegistry.addSvgIcon(
            'DoneIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/done-24px.svg'));
        iconRegistry.addSvgIcon(
            'VisteIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/preview-24px.svg'));
        iconRegistry.addSvgIcon(
            'MaschereIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/tab-24px.svg'));
        iconRegistry.addSvgIcon(
            'DetailsIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/visibility-24px.svg'));
    }

    refreshTasks(): void {
        this.apiService.postTasksId().subscribe(data => {
            if (this.nTask < data.data.length) {
                this.nTask = data.data.length;
                // // console.log(data);
                data.data.forEach((item, index) => {
                    data.data[index][18] = item[18].replace(/\n/g, '<br/>');
                });
                this.TaskAttive = data.data;
                // // console.log(this.TaskAttive);
                this.ColonneTask = data.columns;
            }
        });

    }

    ngOnInit(): void {
        this.sub = interval(5000)
            .subscribe((val) => {
                this.refreshTasks();
            });
        this.todayDate = new Date();
        // // console.log(this.todayDate);
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
        this.apiService.postTasksId().subscribe(data => {
            // console.log(data);
            data.data.forEach((item, index) => {
                data.data[index][22] = item[22].replace(/\n/g, '<br/>');
            });
            // // console.log(data.data);
            this.TaskAttive = data.data;
            // // console.log(this.TaskAttive);
            this.TaskAttive.forEach(element => {
                this.nTask++;
            });
            this.ColonneTask = data.columns;
        });
        this.breakpoint = (window.innerWidth <= 1080) ? 1 : 2;
        this.valueRowHeight = (window.innerWidth <= 1080) ? '1:1' : 'fit';
        this.ora = Date.now();
        this.apiService.getMasks().subscribe(data => {
            this.Maskdesk = data.masks;
            this.Viewdesk = data.views;
            this.Folderdesk = data.folders;
            this.Modelsdesk = data.models;
            this.Profiledesk = data.profiles;
            this.Quikdesk = data.quickSearches;
        });
        this.apiService.getViews().subscribe(datas => {
            this.views = datas;
        });
        if (this.apiService.homeReload) {
            this.apiService.homeReload = false;
        }
        // @ts-ignore

    }


    sortTaskList(): void{


    }
    // tslint:disable-next-line:typedef
    onResize(event) {
        this.breakpoint = (event.target.innerWidth <= 1080) ? 1 : 2;
        this.valueRowHeight = (window.innerWidth <= 1080) ? '1:1' : 'fit';
    }

    // tslint:disable-next-line:typedef
    updateSearchModelMaschere(value) {
        this.searchModelMaschere = value;
        this.searchModelChange.emit(this.searchModelMaschere);
    }

    // tslint:disable-next-line:typedef
    clickMaschera(idMask: string) {
        this.router.navigate(['../maschere', {id: idMask}], {relativeTo: this.route});
    }


    clickTask(idt: string): void {
        this.router.navigate(['../dettaglitask', {id: idt}], {relativeTo: this.route});
    }

    unreadTask(id: string): void {
        this.apiService.unread(id).subscribe(data => {
            this.snackBar.open('Task impostato come non letto', null, {
                duration: 3000,
                horizontalPosition: 'end',
verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-success-bg']
            });
        });
        this.refreshTasks();


    }

    convertStringToDate(data: string): Date {
        return new Date(data);

    }

    TransformDataFormat(Data: Date): string {
        return this.datePipe.transform(Data, 'dd/MM/yyyy, H:MM', '', 'en-GB');

    }

    setPriority(id: string, priority: number): void {
        this.apiService.setPriority(id, priority).subscribe(data => {
            this.snackBar.open('Priorità impostata correttamente', null, {
                duration: 3000,
                horizontalPosition: 'end',
verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-success-bg']
            });
        });
        this.refreshTasks();
    }

    getColor(colorInt: number): string {
        return '#' + colorInt.toString(16);
    }
}
