import {Component, Inject, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {MatTableDataSource} from '@angular/material/table';
import {DialogMultiDTOComponent} from '../../additionalDialog/dialogMultiDTOComponent';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {DialogAddressBookComponent} from '../../additionalDialog/dialogAddressBookComponent';
import {DialogComboDTOComponent} from '../../additionalDialog/dialogComboDTOComponents';
import {DialogMaskComponent} from '../../additionalDialog/dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from '../../additionalDialog/dialogClassViewDTOComponent';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {DialogSearchDocComponent} from '../../additionalDialog/dialogSearchDocComponents';
import {DialogDocumentalClassComponent} from '../../additionalDialog/dialogDocumentalClass';
import * as fileSaver from 'file-saver';
import {AddExternalAttachmentComponent} from '../view/view.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';
import {Observable} from 'rxjs';
import moment from 'moment';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  docId = this.activatedRoute.snapshot.paramMap.get('idDocument');
  taskId = this.activatedRoute.snapshot.paramMap.get('taskId');
  idView = this.activatedRoute.snapshot.paramMap.get('idv');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  folderId = this.activatedRoute.snapshot.paramMap.get('folderId');
  id: string;
  profile: Array<any> = new Array<any>();
  breakpoint: number;
  edit = false;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  maskName: string;
  splitMaskName: string;
  splitMaskOrder: string;
  splitMaskCat: string;
  classProfileType: string;
  classProfileName: string;
  classProfileValue: string;
  fields: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  documenTypes: Array<any> = new Array<any>();
  stateFields: Array<any> = new Array<any>();
  toFields: Array<any> = new Array<any>();
  FromFields: Array<any> = new Array<any>();
  Row: [];
  fieldDataSource: Array<any> = [];
  multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelectedToShow: any;
  fieldName = '';
  keyField = '';
  fieldsNoAdditional: Array<any> = new Array<any>();
  additionalFields: Array<any> = new Array<any>();
  mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
  mapTableValueToSave: Map<string, object[]> = new Map<string, object[]>();
  tableAssociationField: object = {};
  comboAssociationField: any = {};
  mapTableValueToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookToSee: Map<string, any> = new Map<string, any>();
  mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
  // CLASSE BOX
  ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
  tempSkipProfileValue: string;
  arrayToObjectClass: { [index: string]: { content: string } } = {};
  dataSourceClass: MatTableDataSource<object>;
  displayedColumnsClass: Array<string> = new Array<string>();
  docNumberClasseboxTemp: Array<string> = new Array<string>();
  optionsMask: any = {};
  dataMaskToSave: any = {};
  dataValueAssociations: any = {};
  validationCheck: boolean;
  jsonToSendArxivar: any = {};
  fileListMain: FileList;
  fileMain: File;
  formDataMain: FormData;
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  arrayToObject: { [index: string]: { content: string } } = {};
  canUnlock = true;
  comboControl: any = new Array<any>();
  idContact: string;
  checked: boolean;
  mapAddressBookSave: Array<any> = new Array<any>();
  comboValues: any = {};
  tempCombo: Array<any> = new Array<any>();
  selectToAdd: string;
  keyToAdd: string;
  whatToShow = 'default';
  notes: Array<any> = new Array<any>();
  bufferIdInfo2: Array<any> = new Array<any>();
  previews: string[] = [];
  // note section
  notesList: any;
  showAddNote = false;
  newNote: string;
  addLabel = 'Aggiungi';

  // attachments section
  attachments = [];

// chip list
  mapMultiValueToSee: Map<string, any> = new Map<string, any>();
  multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  progressSpinnerValue = 0;
  ccFieldData: any = new Array<any>();
  workflowEvents: Array<any> = new Array<any>();
  fileRequired = false;

  constructor(private translate: TranslateService, private snackBar: MatSnackBar, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService,
              private bodyFieldToSave: BodyFieldToSave, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (!localStorage.getItem('lang')) {
      this.useLanguage('it');
    } else {
      this.translate.use(localStorage.getItem('lang'));
    }

    this.apiService.addPath('Profilo documento: ' + this.docId, 'dashboard/dashnav/profile', {
      idDocument: this.docId,
      taskId: this.taskId, idv: this.idView, path: this.path, firstnodepath: this.firstnodepath, folderId: this.folderId
    });
    // @ts-ignore
    this.apiService.getWorkflowEvents([this.docId as number]).subscribe(res => {
      this.workflowEvents = res;
    });
    this.apiService.getNotes(this.docId).subscribe(v => {
      this.notesList = v;
    });
    this.apiService.docAttachments(this.docId).subscribe(val => {
      this.attachments = val;
      // // console.log(this.attachments);
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
    this.apiService.namePath += ' - ' + 'Profilo documento: ' + this.docId;
    this.edit = true;
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
    if (this.taskId !== '0') {
      this.apiService.getProfileDetails(this.docId, this.taskId).subscribe(data => {
        this.fields = data.fields;
        // console.log(this.fields);
        this.id = data.maskId;
        this.apiService.getDocTypeMode('2').subscribe(datas => {
          // console.log(datas);
          datas.forEach(v => {
            if (v.isLeaf) {
              this.documenTypes.push(v);
            }
          });
          // console.log(this.documenTypes);
        });
        this.optionsMask = data.options;
        // // console.log(data);
        if (data.editOptions.canUnlockProfile === false) {
          this.canUnlock = false;
        }

        data.fields.forEach(findClass => {
          if (true) { // check if visible
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.dataValueAssociations[findClass.name] = findClass.associations;
          }
          if (findClass.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(findClass.name, findClass.value);
            findClass.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (findClass.className === 'AdditionalFieldMultivalueDTO') {
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[findClass.name].forEach(v => {
              this.multiValuteDTOSelectedToShow.push(v);
            });

            this.multiValuteDTOSelected.set(this.fieldName, this.dataMaskToSave[findClass.name]);
            this.mapMultiValueToSee.set(findClass.name, this.multiValuteDTOSelectedToShow);
          }
          if (findClass.className === 'ToFieldDTO') {
            const selected: Array<string> = new Array<string>();
            // // console.log(findClass);
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.contactId);

              });

              this.addressBookSelected.set(findClass.name, selected);
              // // console.log(this.addressBookSelected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'FromFieldDTO') {
            const selected: Array<string> = new Array<string>();
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              this.addressBookSelectedToShow.push({name: findClass.value.society, id: findClass.value.contactId});
              selected.push(findClass.value.contactId);
              // // console.log(this.addressBookSelectedToShow);
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }


          }
          if (findClass.className === 'CcFieldDTO') {
            const selected: Array<string> = new Array<string>();
            // // console.log(findClass);
            this.addressBookSelectedToShow = [];

            if (findClass.value) {
              findClass.value.forEach(item => {
                // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.contactId);

              });

              this.addressBookSelected.set(findClass.name, selected);
              // // console.log(this.addressBookSelected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'DocumentTypeFieldDTO') {
            this.classProfileType = findClass.className;
            this.classProfileName = findClass.name;
            this.classProfileValue = findClass.value;
          }
        });
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {

              if (results.dataSource) {
                this.tempCombo = new Array<any>();
                results.dataSource.forEach(res => {
                  this.selectToAdd = '';
                  this.keyToAdd = '';
                  res.columns.forEach(r => {
                    if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                      this.selectToAdd += r.value + ' - ';
                    }
                    if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                      this.keyToAdd += r.value + ' - ';
                    }
                  });
                  this.tempCombo.push({
                    selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                    keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                    visible: true
                  });
                  this.comboValues[v.name] = this.tempCombo;
                  let found = false;
                  if (!v.limitToList && v.value) {
                    this.comboValues[v.name].forEach(cycle => {
                      if (cycle.keyField === v.value) {
                        this.comboControl[v.name] = cycle.selectField;
                        found = true;
                      }
                    });
                    if (!found) {
                      this.comboControl[v.name] = v.value;
                    }
                  }
                });
              }
            });
          }
        });
        this.checkFormula();
      });
    } else {
      this.apiService.getProfileSchemaSwitched(this.docId).subscribe(data => {
        this.id = data.maskId;
        this.fields = data.fields;
        // console.log(data);
        this.apiService.getDocTypeMode('2').subscribe(datas => {
          // console.log(datas);
          datas.forEach(v => {
            if (v.isLeaf) {
              this.documenTypes.push(v);
            }
          });

        });
        this.optionsMask = data.options;
        // // console.log(data);
        if (data.editOptions.canUnlockProfile === false) {
          this.canUnlock = false;
        }


        data.fields.forEach(findClass => {

          if (findClass.name === 'CHECK281_22') {
            console.log(findClass.name);
            console.log(findClass.value);
            this.fileRequired = findClass.value;
          }
          if (true) {  // check if visible
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.dataValueAssociations[findClass.name] = findClass.associations;
          }
          if (findClass.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(findClass.name, findClass.value);
            findClass.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (findClass.className === 'ToFieldDTO') {
            const selected: Array<string> = new Array<string>();
            // // console.log(findClass);
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.contactId);

              });

              this.addressBookSelected.set(findClass.name, selected);
              // // console.log(this.addressBookSelected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'FromFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              this.addressBookSelectedToShow.push({name: findClass.value.society, id: findClass.value.contactId});
              selected.push(findClass.value.contactId);
              // // console.log(this.addressBookSelectedToShow);
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }


          }
          if (findClass.className === 'CcFieldDTO') {
            const selected: Array<string> = new Array<string>();
            console.log(findClass);
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.contactId);

              });

              this.addressBookSelected.set(findClass.name, selected);
              // // console.log(this.addressBookSelected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'AdditionalFieldMultivalueDTO') {
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[findClass.name].forEach(v => {
              this.multiValuteDTOSelectedToShow.push(v);
            });

            this.multiValuteDTOSelected.set(this.fieldName, this.dataMaskToSave[findClass.name]);
            this.mapMultiValueToSee.set(findClass.name, this.multiValuteDTOSelectedToShow);
          }

          if (findClass.className === 'DocumentTypeFieldDTO') {
            this.classProfileType = findClass.className;
            this.classProfileName = findClass.name;
            this.classProfileValue = findClass.value;
          }
        });
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {

              if (results.dataSource) {
                this.tempCombo = new Array<any>();
                results.dataSource.forEach(res => {
                  this.selectToAdd = '';
                  this.keyToAdd = '';
                  res.columns.forEach(r => {
                    if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                      this.selectToAdd += r.value + ' - ';
                    }
                    if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                      this.keyToAdd += r.value + ' - ';
                    }
                  });
                  this.tempCombo.push({
                    selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                    keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                    visible: true
                  });
                  this.comboValues[v.name] = this.tempCombo;
                  let found = false;
                  if (!v.limitToList && v.value) {
                    this.comboValues[v.name].forEach(cycle => {
                      if (cycle.keyField === v.value) {
                        this.comboControl[v.name] = cycle.selectField;
                        found = true;
                      }
                    });
                    if (!found) {
                      this.comboControl[v.name] = v.value;
                    }
                  }
                });
              }
            });
          }
        });
        this.checkFormula();
        console.log(this.dataMaskToSave);
      });
    }
    this.apiService.getAoo().subscribe(data => {
      this.nomiAoo = data;
    });
    this.apiService.getOrigins().subscribe(data => {
      this.originsFields = data;
    });

    this.apiService.getStates().subscribe(data => {
      this.stateFields = data;
    });


  }

  useLanguage(language: string): void {
    if (language !== localStorage.getItem('lang')) {
      this.translate.use(language);
      localStorage.setItem('lang', language);
      const credentialUser: JSON = {
        languageCultureName: localStorage.getItem('lang'),
        username: 'fornitoreService',
        password: '@#F0rn1t0r32023!#@',
        clientId: this.apiService.CLIENT_ID,
        clientSecret: this.apiService.CLIENT_SECRET
      } as unknown as JSON;
      this.apiService.login(credentialUser).subscribe(data => {
          this.apiService.isFornitore = true;
          this.apiService.setLoggedInInformation(data);
          this.ngOnInit();

        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.snackBar.open(error.error.userMessage, null, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
    }

  }

  startEvent(eventId: string): void {
    this.apiService.canStartEventByDocnumber(this.docId.toString()).subscribe(result => {
      if (result) {
        this.apiService.startWorkflow(this.docId.toString(), eventId).subscribe(r => {
          this.snackBar.open('WorkFlow avviato correttamente', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      } else {
        this.snackBar.open('Impossibile avviare il workflow per il documento selezionato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
      }

    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  checkComboAssociations(field: any): void {
    let comboResult: any = {};
    let selectedElement;
    this.fieldName = field.name;
    console.log(this.dataMaskToSave[field.name]);
    this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {

      comboResult = results;
      this.comboAssociationField = {};
      this.comboAssociationField = Object.keys(results.associations);
      this.fieldDataSource = new Array<any>();
      this.keyField = results.keyField;
      if (results.dataSource != null) {

        results.dataSource.forEach(item => {
          item.columns.forEach(v => {
            if (v.id.toUpperCase() === this.keyField.toUpperCase()) {
              if (v.value.toString() === field.value.toString()) {
                v.value = this.dataMaskToSave[field.name];
                selectedElement = item;
              }
            }
          });

        });
      } else {
        // EMPTY COMBO
      }
      console.log(selectedElement);
      this.comboAssociationField.forEach(res => {
        if (selectedElement) {
          selectedElement.columns.forEach(v2 => {

            if (v2.id === comboResult.associations[res]) {
              if (res.toUpperCase().startsWith('DATA')) {
                this.dataMaskToSave[res.toUpperCase()] = this.TransformDataFormatToSave(v2.value);
              } else {
                this.dataMaskToSave[res.toUpperCase()] = v2.value;
              }

            }
          });
        }
      });
      this.tempSkipProfileValue = field.name;
      this.getAllProfileValue();
    });
  }

  checkInputFormula(): void {
    this.formulaCalculatorNoLoading(0);
    this.progressSpinnerValue = 100;
  }

  getAllNullProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (this.comboValues[val.name]) {
        // console.log(this.comboValues[val.name]);
        if (this.comboValues[val.name].length === 1) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                  visible: true
                });
                this.comboValues[val.name] = this.tempCombo;
                if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]) {
                  this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                  this.checkComboAssociations(val);
                }
              });
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                // console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    /*if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }*/
                    if ((item.name === val.name) && (item.limitToList === true)) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      } else {
        if (!val.value && !this.comboControl[val.name]) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              if (results.dataSource) {
                results.dataSource.forEach(res => {
                  this.selectToAdd = '';
                  this.keyToAdd = '';
                  res.columns.forEach(r => {
                    if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                      this.selectToAdd += r.value + ' - ';
                    }
                    if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                      this.keyToAdd += r.value + ' - ';
                    }
                  });
                  this.tempCombo.push({
                    selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                    keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                    visible: true
                  });
                  this.comboValues[val.name] = this.tempCombo;
                  if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]) {
                    this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                    this.checkComboAssociations(val);
                  }
                });
              }
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                // console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                    if ((item.name === val.name) && (item.limitToList === true)) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      }

    });
  }

  formulaCalculatorNoLoading(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }
            if (this.fields[i + 1]) {
              this.formulaCalculatorNoLoading(i + 1);
            }
            found = true;
            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.progressSpinnerValue = 100;
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          if (this.fields[i + 1]) {
            this.formulaCalculatorNoLoading(i + 1);
          }
          if (!this.fields[i + 1]) {

            this.progressSpinnerValue = 100;
            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.progressSpinnerValue = 100;
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculatorNoLoading(i + 1);
      } else {
        this.checkVisibility();
        this.progressSpinnerValue = 100;
        this.getAllNullProfileValue();
      }
    }
  }

  checkFormula(): void {
    this.formulaCalculator(0);
  }

  formulaCalculator(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }

            this.progressSpinnerValue = i / this.fields.length * 100;
            if (this.fields[i + 1]) {
              this.formulaCalculator(i + 1);
            }
            found = true;

            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.checkVisibility();
                this.progressSpinnerValue = 100;
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          this.progressSpinnerValue = i / this.fields.length * 100;
          if (this.fields[i + 1]) {
            this.formulaCalculator(i + 1);
          }
          if (!this.fields[i + 1]) {
            this.checkVisibility();
            this.progressSpinnerValue = 100;
            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.checkVisibility();
                    this.progressSpinnerValue = 100;
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculator(i + 1);
      } else {
        this.checkVisibility();
        this.progressSpinnerValue = 100;
        this.getAllNullProfileValue();
      }
    }
  }

  checkVisibility(): void {
    setTimeout(() => {
      this.checkMandatory();
      let pastFormulas: Array<any> = new Array<any>();
      const found = false;
      pastFormulas = new Array<any>();
      // // console.log(this.fields);
      this.fields.forEach(f => {
        if (f.visibilityCondition === null || f.visibilityCondition === '') {
        } else {
          const splittedConditions: Array<string> = new Array<string>();
          const operators: Array<string> = new Array<string>();
          let condition = f.visibilityCondition;
          let end = false;
          const i = 0;
          let result = false;
          while (!end) {
            if (condition.includes('||') && condition.includes('&&')) {
              if (condition.indexOf('||') < condition.indexOf('&&')) {
                splittedConditions.push(condition.split('||')[0]);
                condition = condition.substring(condition.indexOf('||') + 2, condition.length);
                operators.push('||');
              } else {
                splittedConditions.push(condition.split('&&')[0]);
                condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
                operators.push('&&');
              }
            } else if (condition.includes('||')) {
              splittedConditions.push(condition.split('||')[0]);
              condition = condition.substring(condition.indexOf('||') + 2, condition.length);
              operators.push('||');
            } else if (condition.includes('&&')) {
              splittedConditions.push(condition.split('&&')[0]);
              condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
              operators.push('&&');
            } else {
              splittedConditions.push(condition);
              end = true;
            }
          }

          splittedConditions.forEach((cond, index) => {
            let field: string;
            let equalTo: string;
            let isNotEqual: boolean;
            let conditionBeforeBrackets;
            if (cond.includes('<>')) {
              equalTo = cond.substring(cond.indexOf('<>') + 2, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('<>')).trim();
              isNotEqual = true;
            } else {
              equalTo = cond.substring(cond.indexOf('=') + 1, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('=')).trim();
              isNotEqual = false;
            }

            /*equalTo = equalTo.split(')').join('');
            equalTo = equalTo.split('(').join('');*/

            if (!isNotEqual) {
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }
                if (this.dataMaskToSave[field].toString().trim().toLowerCase() === equalTo.toString().trim().toLowerCase()) {
                  // // console.log('la condizione è vera: ' + cond);
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }

                } else {
                  // // console.log('la condizione è falsa: ' + cond);
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            } else {
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }

                if (this.dataMaskToSave[field].toString().trim() !== equalTo.toString().trim()) {
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }
                } else {
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            }
            /*if (f.name === 'TABLE308_12'){
                // console.log(result);
                // console.log(cond);
                // console.log(equalTo.toString().trim());
                // console.log(this.dataMaskToSave);
            }*/
          });
          f.visible = result;
        }
      });
    }, 1);
  }
  addAttach(event): void {

    this.fileListMain = event.target.files;
    const numberOfFiles = this.fileListMain.length;
    for (let i = 0; i < numberOfFiles; i++) {
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileListMain[i], this.fileListMain[i].name);
      this.addFunction(this.formDataMain, this.fileListMain[i].name, this.fileListMain[i]);
    }
  }
  displayFn(field: any, value?: any): any {
    if (value.keyField) {
      this.dataMaskToSave[field.name] = value.keyField;
      field.value = value.keyField;
      return value.selectField;
    } else {
      if (this.comboControl[field.name]) {
        this.dataMaskToSave[field.name] = this.comboControl[field.name];
        field.value = this.comboControl[field.name];
        return this.comboControl[field.name];
      }else{

      }
    }

  }
  checkField(field: any): void {
    this.dataMaskToSave[field.name] = this.comboControl[field.name];
    field.value = this.comboControl[field.name];
    this.comboValues[field.name].forEach(val => {
      if (!val.selectField.toLowerCase().includes(this.comboControl[field.name])) {
        val.visible = false;
      } else {
        val.visible = true;
      }
    });
  }
  addFunction(formdataMain: FormData, fileName: string, file: any): void{
    this.apiService.openBuffer(formdataMain).subscribe(result => {
      let fileInfovar = {name: '', buffer: '', file: {}};

      fileInfovar.name = fileName;
      fileInfovar.buffer = result[0];
      this.bufferIdInfo2.push(fileInfovar);


    });
  }
  checkMandatory(): void {
    setTimeout(() => {
      let pastFormulas: Array<any> = new Array<any>();
      const found = false;
      pastFormulas = new Array<any>();
      // // console.log(this.fields);
      this.fields.forEach(f => {
        if (f.mandatoryCondition === null || f.mandatoryCondition === '') {
        } else {


          const splittedConditions: Array<string> = new Array<string>();
          const operators: Array<string> = new Array<string>();
          let condition = f.mandatoryCondition;
          let end = false;
          const i = 0;
          let result = false;
          while (!end) {
            if (condition.includes('||') && condition.includes('&&')) {
              if (condition.indexOf('||') < condition.indexOf('&&')) {
                splittedConditions.push(condition.split('||')[0]);
                condition = condition.substring(condition.indexOf('||') + 2, condition.length);
                operators.push('||');
              } else {
                splittedConditions.push(condition.split('&&')[0]);
                condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
                operators.push('&&');
              }
            } else if (condition.includes('||')) {
              splittedConditions.push(condition.split('||')[0]);
              condition = condition.substring(condition.indexOf('||') + 2, condition.length);
              operators.push('||');
            } else if (condition.includes('&&')) {
              splittedConditions.push(condition.split('&&')[0]);
              condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
              operators.push('&&');
            } else {
              splittedConditions.push(condition);
              end = true;
            }
          }

          splittedConditions.forEach((cond, index) => {
            let field: string;
            let equalTo: string;
            let isNotEqual: boolean;
            let conditionBeforeBrackets;
            if (cond.includes('<>')) {
              equalTo = cond.substring(cond.indexOf('<>') + 2, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('<>')).trim();
              isNotEqual = true;
            } else {
              equalTo = cond.substring(cond.indexOf('=') + 1, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('=')).trim();
              isNotEqual = false;
            }

            /*equalTo = equalTo.split(')').join('');
            equalTo = equalTo.split('(').join('');*/

            if (!isNotEqual) {

              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }
                if (this.dataMaskToSave[field].toString().trim().toLowerCase() === equalTo.toString().trim().toLowerCase()) {
                  // // console.log('la condizione è vera: ' + cond);
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }

                } else {
                  // // console.log('la condizione è falsa: ' + cond);
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            } else {
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }

                if (this.dataMaskToSave[field].toString().trim() !== equalTo.toString().trim()) {
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }
                } else {
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            }
            /*if (f.name === 'TABLE308_12'){
                // console.log(result);
                // console.log(cond);
                // console.log(equalTo.toString().trim());
                // console.log(this.dataMaskToSave);
            }*/
          });
          f.required = result;
        }
      });
    }, 1);
  }

  getUpdatedFields(name: string, form: string): any {
    this.jsonToSendArxivar =
      {
        fieldName: name,
        formula: form,
        searchFilterDto: {
          fields: []
        }
      };
    this.fields.forEach((field, index) => {
      if (field.visible || this.dataMaskToSave[field.name]) {
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }
      }
      this.jsonToSendArxivar.searchFilterDto.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  deleteAttach(bufferId: string): void {
    this.bufferIdInfo2.forEach((res, index) => {
      if (res.buffer === bufferId) {
        this.bufferIdInfo2.splice(index, 1);
        this.snackBar.open('Allegato eliminato con successo', null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
      }
    });
  }

  refresh(): void {
    this.apiService.docAttachments(this.docId).subscribe(val => {
      this.attachments = val;
      // // console.log(this.attachments);
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  openAddExternalAttachmentDialog(): void {
    const dialogRef = this.dialog.open(AddExternalAttachmentComponent, {
      width: '20%',
      height: '45%',
      data: {docN: this.docId}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh();
    });
  }

  downloadDocForAttachment(Id: string, name: string): void {
    // // // console.log(name);
    this.apiService.downloadAttachment(Id, true).subscribe(response => {
      const blob = new Blob([response], {type: 'application/octet-stream'});
      fileSaver.saveAs(blob, name);
      // tslint:disable-next-line:no-unused-expression
    }, errorLog => {
      this.snackBar.open('Impossibile scaricare il documento', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy h:mm:ss', '', 'en-GB');
  }

  openRegistrationSentDialog(): void {

    const dialogRef = this.dialog.open(RegistrationSentComponent, {
      width: '30%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    });
  }


  opendocumentalClassDialog(): void {
    this.apiService.classDocMode = '2';
    const dialogRef = this.dialog.open(DialogDocumentalClassComponent, {
      width: '40%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.fields.forEach(v => {
          if (v.name === 'DocumentType') {
            this.dataMaskToSave[v.name] = Number(result.docClassId);
            v.value = Number(result.docClassId);
            this.documentChanged({isUserInput: true}, result.docType, result.type1, result.type2);
          }
        });
      }
    });
  }

  deleteNote(id: string): void {
    this.apiService.deleteNoteProfile(id).subscribe(res => {
      this.apiService.getNotes(this.docId).subscribe(v => {
        this.notesList = v;
      });
      this.snackBar.open('Nota eliminata con successo', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  saveNewNote(): void {
    if (this.newNote !== undefined && this.newNote !== '') {
      this.apiService.addNote({
        docnumber: this.docId,
        comment: this.newNote,
        isEdit: false,
        aosflag: false,
        isPaNote: false
      }).subscribe(res => {
        this.newNote = '';
        this.apiService.getNotes(this.docId).subscribe(v => {
          this.notesList = v;
        });
        this.snackBar.open('Nota aggiunta con successo', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.snackBar.open('Il testo della nuova nota non è valido', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }

  }

  addNoteTrue(): void {
    if (this.showAddNote) {
      this.showAddNote = false;
      this.addLabel = 'Aggiungi';
    } else {
      this.showAddNote = true;
      this.addLabel = 'Nascondi';
    }
  }

  searchMaskFromClassDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        const dialogRef = this.dialog.open(DialogSearchDocComponent, {
          width: '80%',
          height: 'fit-content',
          data: {
            classField: field
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // // console.log(res);
          res.dataResult.forEach(result => {
            this.docNumberClasseboxTemp = new Array<string>();
            // // console.log(this.mapClasseBookToSave);
            this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
            // // console.log(this.docNumberClasseboxTemp);

            this.docNumberClasseboxTemp.push(result.docNumber);
            this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);
            this.arrayToObject = {};
            this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
              callResults.fields.forEach(fieldClass => {
                this.displayedColumnsClass.forEach(colum => {
                  if (colum === fieldClass.description) {
                    this.arrayToObject[colum] = fieldClass.value;
                  }
                });
              });
              this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
              this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
              // // console.log(this.ELEMENT_DATA_CLASS);
            });
          });
        });
      }
    }
  }

  documentChanged(event: any, tipo1: string, tipo2: string, tipo3: string): void {
    if (event.isUserInput) {
      this.apiService.additionalProfiles(tipo1, tipo2, tipo3, this.fields[0].value).subscribe(data => {
        this.fieldsNoAdditional = new Array<any>();
        this.fields.forEach(v => {
          if (v.isAdditional === false) {
            this.fieldsNoAdditional.push(v);
          }
        });
        this.additionalFields = data;
        this.additionalFields.forEach(v => {
          this.fieldsNoAdditional.push(v);
        });
        this.fields = this.fieldsNoAdditional;
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3)
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
        });
        // console.log(this.fields);
      });
    }
  }

  openMultiValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        // console.log(results);
        this.multiValuteDTOSelected.set(this.fieldName, this.dataMaskToSave[field.name]);
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
          id: 'style-4',
          width: '40%',
          height: '60%',
          data: {
            additionalFieldMultiDTO: this.fieldDataSource,
            fieldName: field.name,
            multiValueToSelected: this.multiValuteDTOSelected,
            selectField: results.keyField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[this.fieldName] = '';
            this.mapMultiValueToSee.set(this.fieldName, '');
            this.multiValuteDTOSelected.set(this.fieldName, []);
          }
          this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
            result.dataResult.forEach(item => {
              this.multiValuteDTOSelectedToShow.push(item);
            });
            this.dataMaskToSave[this.fieldName] = result.dataResult;
            this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
            this.multiValuteDTOSelected.set(this.fieldName, result.dataResult); // non serve probabilmente
            this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
            console.log(this.mapMultiValueToSee);
          }
          this.getAllProfileValue();
          this.checkFormula();
        });
      });
    }
  }

  openTableDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        this.fieldName = field.name;
        this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
          this.tableAssociationField = {};
          this.tableAssociationField = Object.keys(results.associations)
            .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
          this.fieldDataSource = new Array<object>();
          if (results.dataSource != null) {
            this.keyField = results.keyField;
            results.dataSource.forEach(item => {
              this.fieldDataSource.push(item.columns);
            });
          } else {
            // EMPTY MULTI VALUE
          }
          const dialogRef = this.dialog.open(DialogTableDTOComponent, {
            id: 'style-4',
            width: '80%',
            height: '95%',
            data: {
              additionalFieldTableDTO: this.fieldDataSource,
              fieldName: field.name,
              tableValueToSelected: this.tableValuteDTOSelected,
              selectField: results.selectField
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.dataResult === 'delete') {
              this.fields.forEach((item, index) => {
                if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[index].value = '';
                  this.dataMaskToSave[this.fields[index].name] = '';
                }
                if (item.name === this.fieldName) {
                  this.fields[index].value = '';
                  this.dataMaskToSave[this.fields[index].name] = '';
                }
              });
            }
            if (result.dataResult !== false) {
              this.mapTableValueToSave = result.dataResult;
              this.fields.forEach((item, index) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                  this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                }
                if (item.name === this.fieldName) {
                  this.fields[index].value = result.dataResult[this.keyField];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
                }
              });
            }
            this.getAllProfileValue();
          });
        });
      }
    }
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.SearchAllAddressBook().subscribe(results => {
        console.log(this.addressBookSelected);
        this.apiService.viewDataToShow = results;
        const name = field.name;
        if (field.description === 'Anagrafica cliente') {
          // name = 'From';
        } else {

        }
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            fieldName: name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE',

          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = [];
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach((val, index) => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // console.log(res);
              if (type === '0' || type === '2') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }
              if (index === result.dataResult.length - 1) {
                if (result.dataResult !== false) {
                  result.contactsNames.forEach(item => {
                    this.addressBookSelectedToShow.push({name: item, id: val});
                  });
                  if (type === '0' || type === '2') {
                    this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
                  } else {
                    this.dataMaskToSave[this.fieldName] = fromFieldValue;
                  }
                  this.addressBookSelected.set(this.fieldName, result.dataResult);
                  this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
                }
                this.checkFormula();
                this.getAllProfileValue();
              }
            });
          });
        });
      });
    }
  }

  removeAddressBook(v: any, fieldName: string): void {
    this.addressBookSelectedToShow = [];
    this.addressBookSelected = new Map<string, string[]>();
    const tempSelected = [];
    if (fieldName !== 'From') {
      this.dataMaskToSave[fieldName].forEach((value, index) => {
        if (value.contactId === v.id) {
          this.dataMaskToSave[fieldName].splice(index, 1);
        }
      });
      this.dataMaskToSave[fieldName].forEach((value) => {
        this.addressBookSelectedToShow.push({name: value.society, id: value.contactId});
        tempSelected.push(value.contactId);
      });
    } else {
      this.dataMaskToSave[fieldName] = {};
    }

    this.mapAddressBookToSee.set(fieldName, this.addressBookSelectedToShow);
    this.addressBookSelected.set(fieldName, tempSelected);

  }

  openComboValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.comboAssociationField = {};
        this.comboAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns[0]);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogComboDTOComponent, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            additionalFieldComboDTO: this.fieldDataSource,
            fieldName: field.name,
            comboValueToSelected: this.comboValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result.dataResult === 'delete') {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
            });
          }
          if (result.dataResult !== false) {
            this.fields.forEach((item, index) => {
              if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = result.dataResult[this.comboAssociationField[item.name.toLowerCase()]];
                this.dataMaskToSave[this.fields[index].name] =
                  result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = result.dataResult[this.keyField];
                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
              }
            });
          }
          this.getAllProfileValue();
        });
      });
    }
  }

  insertMaskFromClassDTO(field: any): void {
    if (this.edit) {
      if (!field.locked) {
        if (this.bufferIdInfo.length > 0) {
          this.jsonToSendArxivar = {
            document: {
              fileNames: [],
              bufferIds: []
            },
            fields: [],
            notes: this.notes
          };
          this.bufferIdInfo.forEach(file => {
            this.jsonToSendArxivar.document.fileNames.push(file.name);
            this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
          });
        } else {
          this.jsonToSendArxivar = {
            fields: [],
            notes: this.notes
          };
        }
        this.fields.forEach((field1, index) => {
          if (field1.visible) {
            // // // console.log(this.dataMaskToSave);
            if (field1.className === 'AdditionalFieldDateTimeDTO') {
              this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field1.name]);
            } else if (field1.className === 'AdditionalFieldDoubleDTO') {
              if (this.dataMaskToSave[field1.name]) {
                this.fields[index].value = (this.dataMaskToSave[field1.name].toString().replace(',', '.'));
              } else {
                this.fields[index].value = this.dataMaskToSave[field1.name];
              }
            } else {
              this.fields[index].value = this.dataMaskToSave[field1.name];
            }
          }
          this.jsonToSendArxivar.fields.push(this.fields[index]);
        });
        this.fieldName = field.name;
        const dialogRef = this.dialog.open(DialogMaskComponent, {
          width: '80%',
          data: {
            json: this.jsonToSendArxivar,
            id: field.insertMaskId,
            fieldName: field.name,
            dataMask: this.dataMaskToSave,
            dataAssociations: this.dataValueAssociations
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // // // console.log(result);
          if (result.hasOwnProperty('docNumber')) {
            this.docNumberClasseboxTemp = new Array<string>();
            this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
            this.docNumberClasseboxTemp.push(result.docNumber);
            this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);
            this.arrayToObject = {};
            this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
              callResults.fields.forEach(fieldClass => {
                this.displayedColumnsClass.forEach(colum => {
                  if (colum === fieldClass.description) {
                    this.arrayToObject[colum] = fieldClass.value;
                  }
                });
              });
              this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
              this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
              // // // console.log(this.ELEMENT_DATA_CLASS);
            });
          }
        });
      }
    }
  }

  openClassMasks(field: any): void {

    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getClassAdditionalFieldsV2(field.name, this.classProfileValue, this.mapClasseBookToSave.get(field.name)).subscribe(callResults => {
        this.apiService.viewDataToShow = callResults;
        const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
          width: '80%',
          height: '80%',
          data: {
            classField: field,
            mapClassValue: this.mapClasseBookToSave.get(field.name),
            docType: this.classProfileValue
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // // // // console.log(this.mapClasseBookToSave.get(result.fieldName));
        });
      });


    }

  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }

  downloadDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // // // console.log(name);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const blob = new Blob([response], {type: 'application/octet-stream'});
        fileSaver.saveAs(blob, name);
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open('Nessun documento associato', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }


  showFileSelection(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.insertFileInCache(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo.push(this.fileInfo);
        this.badgeBufferMain = this.bufferIdInfo.length;
      });
    }
  }

  showAttachments(): void {
    this.whatToShow = 'attachments';
  }

  showNotes(): void {
    this.whatToShow = 'note';
  }

  showDesk(): void {
    this.whatToShow = 'default';
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name + ' eliminato!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  saveMask(): void {
    if (false) {
      this.openRegistrationSentDialog();
    } else {
      this.whatToShow = 'default';
      this.validationCheck = true;
      this.fields.forEach(field => {

        if (field.required) {
          // // console.log(this.dataMaskToSave);
          if (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null) {
            this.snackBar.open(this.translate.instant('labels.theField') + ((field.description === 'Aoo') ? this.translate.instant('labels.company') : field.description) + ' ' +
              this.translate.instant('labels.isMandatory'), null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
            this.validationCheck = false;
          }
        }


      });
      if (this.fileRequired && this.badgeBufferMain !== 1) {
        this.validationCheck = false;
        this.snackBar.open('File ' + this.translate.instant('labels.isMandatory'), null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      }
      if (this.validationCheck) {
        this.buildDataMask();
      }
    }

  }

  getJsonFields(): any {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  buildDataMask(): void {
    const attach: Array<string> = new Array<string>();
    this.bufferIdInfo2.forEach(res => {
      attach.push(res.buffer);
      this.apiService.insertExternal(this.docId, res.buffer, '').subscribe(att => {

      });
    });
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.apiService.checkInOutProfile(this.docId, file.buffer, 0 , false).subscribe(doc => {

        });
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // console.log(this.dataMaskToSave[field.name]);

        // // // console.log(field);
      }
      if (field.name === 'Origine'){
        field.value = 1;
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    // console.log(this.fields);
    // // console.log(this.dataMaskToSave);
    this.apiService.updateProfile(this.jsonToSendArxivar, this.docId).subscribe(results => {
      // // console.log(results);
      this.openRegistrationSentDialog();
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 7000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });

    // this.goBack();
    this.edit = true;
  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    }
    return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
  }

  lock(): void {
    this.whatToShow = 'default';
    this.edit = true;

  }

  unLock(): void {
    if (this.canUnlock) {
      this.edit = true;
    } else {
      // // console.log('non sbloccabile');
    }
  }

  remove(v: string, fieldName: string): void {
    this.multiValuteDTOSelectedToShow = new Array<any>();
    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value === v) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.multiValuteDTOSelectedToShow.push(value);
    });
    this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  getAllProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (val.className === 'AdditionalFieldComboDTO' && (this.tempSkipProfileValue !== val.name || !this.tempSkipProfileValue)) {
        this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
          this.tempSkipProfileValue = null;
          // // console.log(results);
          this.tempCombo = new Array<any>();
          if (results.dataSource) {
            results.dataSource.forEach(res => {
              this.selectToAdd = '';
              this.keyToAdd = '';
              res.columns.forEach(r => {
                if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                  this.selectToAdd += r.value + ' - ';
                }
                if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                  this.keyToAdd += r.value + ' - ';
                }
              });
              this.tempCombo.push({
                selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                visible: true
              });
              this.comboValues[val.name] = this.tempCombo;
            });
          }
        });
      } else {
        if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
          this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
            this.tableAssociationField = {};
            this.tableAssociationField = Object.keys(results.associations)
              .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
            // console.log(this.tableAssociationField);
            if (results.dataSource.length === 0) {
              this.fields.forEach((item, subindex) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase()) && (item.limitToList === true)) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
                if ((item.name === val.name) && (item.limitToList === true)) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
              });
            }
          });
        }
      }

    });
    this.checkFormula();

  }

  goBack(): void {
    if (this.taskId !== '0' && this.path == null) {
      this.router.navigate(['../dettaglitask', {id: this.taskId, path: this.firstnodepath}], {relativeTo: this.route});
    } else {
      this.router.navigate(['../view', {id: this.idView, firstnodepath: this.firstnodepath}], {relativeTo: this.route});
    }
    if (this.path) {
      if (this.folderId) {
        this.router.navigate(['../' + this.path, {openId: this.folderId}], {relativeTo: this.route});
      } else {
        this.router.navigate(['../' + this.path], {relativeTo: this.route});
      }

    }
  }
}

@Component({
  selector: 'app-registrationsent',
  templateUrl: 'registrationSent.html',
  styleUrls: ['./profile.component.css']
})
export class RegistrationSentComponent {


  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<RegistrationSentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    dialogRef.disableClose = true;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}
