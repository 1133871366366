<div class="center">


  <mat-card id="style-4" class="cardcomponentMasks">
    <div style="display: flex;">
      <button style="float: left;color:{{this.dashColor}};" title="Cerca" (click)="saveMask()" mat-icon-button
              aria-label="icon-button send">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
    <br>
    <mat-card-content>
      <form class="flexform">
        <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
        <ng-container *ngFor="let field of fields">
          <!--mat-grid-tile [colspan]="4"
                         [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                         *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->


          <ng-container [ngSwitch]="field.className"
                        *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                        style="height:fit-content; width: 100%;">
            <ng-container *ngIf="field.name.startsWith('NUMERIC')">
              <div *ngSwitchCase="'FieldBaseForSearchIntDto'" class="form-field"
                   appearance="outline">
                <!--Tipo documento-->
                <mat-form-field class="form-field-docType" style="width: 86%"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <!-- <button mat-icon-button matPrefix
                           style="margin-right: 5px; border-right: 0.5px solid {{this.apiService.squareColor}}">
                       <mat-icon style="color: {{this.apiService.squareColor}}">filter_alt</mat-icon>
                   </button>-->

                  <ng-container
                  >
                    <input ngModel [value]="field.value || ''" type="number"
                           [(ngModel)]="dataMaskToSave[field.name]"
                           name="{{field.name}}"
                           [id]="field.name" [required]="field.required"
                           [disabled]="field.locked"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </ng-container>
                </mat-form-field>
                <span>
                                    <button
                                      style="color: {{this.dashColor}};float: right;" title="Cancella campo"
                                      (click)="cleanFieldContact(field.name)"
                                      mat-icon-button
                                      aria-label="icon-button send">
                                        <mat-icon>cleaning_services</mat-icon>
                                    </button>
                                </span>
              </div>
            </ng-container>
            <div *ngSwitchCase="'FieldBaseForSearchDocumentTypeDto'" class="form-field">


              <mat-form-field class="form-field-docType" style="width: 80%"
                              appearance="outline">
                <mat-label>{{field.description}}</mat-label>
                <mat-select [value]="field.value || ''" [id]="field.name"
                            [required]="field.required"
                            [(ngModel)]="dataMaskToSave[field.name]"
                            name="{{field.name}}"
                            [disabled]="field.locked"
                >
                  <mat-option
                    (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                    *ngFor="let documenType of documenTypes"
                    [value]="documenType.id">
                    {{documenType.description}} - {{documenType.key}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
              <span>
                                    <button style="color: {{this.dashColor}};" title="Cancella campo"
                                            (click)="cleanField(field.name)"
                                            mat-icon-button
                                            aria-label="icon-button send">
                                        <mat-icon>cleaning_services</mat-icon>
                                    </button>
                <!-- <button [disabled]="field.locked" matSuffix mat-icon-button
                         style="color: {{this.dashColor}};float: right;"
                         (click)="opendocumentalClassDialog()">
                     <mat-icon>more_vert</mat-icon>
                 </button>-->
                                </span>
            </div>
          </ng-container>
        </ng-container>


        <!--/mat-grid-list-->
        <mat-card-actions>
        </mat-card-actions>


      </form>
      <div *ngIf="this.renderResult">
        <div style="display: flex; justify-content: center">
          <mat-progress-bar *ngIf="!resultReady"
                            mode="buffer"
                            class="tree-progress-bar"></mat-progress-bar>
        </div>
        <div *ngIf="resultReady" style="text-align: center; color: {{this.dashColor}} !important;">
          <h1>Esito Check-up</h1>
          <hr class="riga" style="border-top: 2px solid {{this.dashColor}}; width: 50%; margin: auto;">
          <br>
          <ng-container *ngFor="let data of this.classes">
            <h2>Classe {{data.class}}</h2>
            <ng-container *ngIf="data.resultNumber === undefined">
              <h4 style="color: red !important;">Non è stato possibile recuperare il numero dei profili,
                controllare se esistono profili
                associati alla classe doumentale selezionata</h4>
            </ng-container>
            <ng-container *ngIf="data.resultNumber !== undefined">
              <h3>L'ultimo documento inserito è il numero: <b>{{data.resultNumber}}</b></h3>
              <ng-container *ngIf="data.resultMissingNumber.length === 0">
                Nessun profilo mancante
              </ng-container>
              <ng-container *ngIf="data.resultMissingNumber.length !== 0">
                <ng-container *ngIf="data.resultMissingNumber.length == 1">
                  <h3>E' stato trovato {{data.resultMissingNumber.length}} profilo mancante:
                    <ng-container *ngFor="let missingNumber of data.resultMissingNumber">
                      <br># => <b>{{missingNumber}}</b>
                    </ng-container>
                  </h3>
                </ng-container>
                <ng-container *ngIf="data.resultMissingNumber.length != 1">
                  <h3>Sono stati trovati {{data.resultMissingNumber.length}} profili mancanti:
                    <ng-container *ngFor="let missingNumber of data.resultMissingNumber">
                      <br># => <b>{{missingNumber}}</b>
                    </ng-container>
                  </h3>
                </ng-container>

              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-card-content>

  </mat-card>
</div>
