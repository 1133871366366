import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import {Product, Service} from '../../app.service';
import {NgModule, enableProdMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {DxTreeViewModule, DxSelectBoxModule, DxDataGridComponent, DxTreeViewComponent} from 'devextreme-angular';
import TreeView from 'devextreme/ui/tree_view';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  EditFromFileProfileComponent,
  EditRevisionProfileComponent,
  ShowAttachmentsDialogComponent, SignerDetailsComponent
} from '../../MenuItemsV2/view/view.component';
import {ViewBodyToSave} from '../../viewBodyToSave';

/** Flat node with expandable and level information */


@Component({
  selector: 'app-fascicoli',
  templateUrl: './fascicoli.component.html',
  styleUrls: ['./fascicoli.component.css'],
  providers: [Service]
})
export class FascicoliComponent implements OnInit, AfterViewInit {
  @ViewChild(DxTreeViewComponent, {static: true}) treeView: DxTreeViewComponent;
  displayedColumns: string[] = ['Origine', 'Note', 'Workflow', 'Allegati', 'Stato', 'Formato', 'SystemId', 'Revisione', 'Da', 'A', 'Oggetto', 'Numero', 'DataDocumento', 'Aoo'];
  row: any = {};
  v: any = {};
  clickedFolderName = '';
  docResult = [];
  sysId: Array<string> = new Array<string>();
  aoo: Array<string> = new Array<string>();
  showTable = false;
  isLoading = false;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  datas: Array<any> = new Array<any>();
  nodesPath: Array<any> = new Array<any>();
  openId = this.activatedRoute.snapshot.paramMap.get('openId');
  index: number;
  dataMap: Map<string, string[]> = new Map<string, string[]>();
  dataToInsert: Array<any> = new Array<any>();
  products: Product[];
  currentItem: Product;
  treeData: Array<any> = new Array<any>();
  private dataSource: MatTableDataSource<any>;
  tempData: Array<object> = new Array<object>();
  viewInstance: TreeView;

  Result: Array<any> = new Array<any>();
  noItems = true;

  buildResult: Array<string> = new Array<string>();
  currentTable: any;

  saveViewInstance(e): void {
    // console.log(e);
    this.viewInstance = e.component;
    // console.log(this.viewInstance);
  }


  constructor(private snackBar: MatSnackBar, service: Service, private datePipe: DatePipe, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              // tslint:disable-next-line:max-line-length
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {

  }


  selectItem(e): void {
    this.currentItem = e.itemData;
  }



  createChildren = (parentNode) => {
    if (parentNode) {
      return this.apiService.getChilds(parentNode.itemData.id).toPromise();
    } else {
      if (this.openId) {
        return this.apiService.getChilds(this.openId).toPromise();
      } else {
        return this.apiService.getChilds('0').toPromise();
      }
    }
  }

  onItemClick(e): void {

  }

  exportToCSV(id: string): void {
    this.Result.forEach(res => {
      if (res['DOCNUMBER,System ID'] === id) {
        this.apiService.getUserSettings().subscribe(response => {
          // console.log(response);
          if (response.profile) {
            const header = Object.keys(res);
            const tempArray: Array<any> = new Array<any>();
            header.forEach((v, index) => {
              header[index] = v.substr(v.indexOf(',') + 1, v.length);
              let value: string;
              value = res[v];

              value = value.toString().replace(',', '');
              tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
            });
            this.apiService.exportCSV([tempArray], 'profile', header);
          } else {
            // console.log(res);
            const header = Object.keys(res);
            const tempArray: Array<any> = new Array<any>();
            header.forEach((v, index) => {
              header[index] = v.substr(v.indexOf(',') + 1, v.length);
              let value: string;
              value = res[v];
              if (value) {
                value = value.toString().replace(',', '');
              }
              tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
            });
            this.apiService.exportCSV([tempArray], 'profile', header);
          }
        });
      }
    });
  }

  signDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // console.log(result);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        const self = this;
        // tslint:disable-next-line:only-arrow-functions
        reader.onloadend = function(): void {
          let base64data = reader.result.toString();
          base64data = base64data.substr(base64data.indexOf(',') + 1);
          const dialogRef = self.dialog.open(SignerDetailsComponent, {
            width: '20%',
            data: {}
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res.signerEmail) {
              self.apiService.docusignSendEnvelope(base64data, name,
                name.substr(name.lastIndexOf('.') + 1), res.signerEmail, res.signerName).subscribe(resu => {
                // console.log(resu);
                self.snackBar.open('Operazione effettuata con successo!', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
              });
            } else {
              self.snackBar.open('Operazione annullata', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
              });
            }
          });
        };
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open('Nessun documento associato', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  ngAfterViewInit(): void {


  }

  fillChilds(id: string): any {
    // console.log('blabal');
    const dataToInsertChild = new Array<any>();
    this.apiService.getChilds(id).subscribe(dynamicData => {
      this.treeData.push(dynamicData);
    });
  }

  ngOnInit(): void {
    // console.log('lalalalalal');
    this.apiService.namePath = this.apiService.namePath.split('-')[0];

    /* if (this.openId) {
         this.apiService.getFolder(this.openId).subscribe(v => {
             this.treeData.push({id: v.id, name: v.name, parentId: 0, hasChilds: v.hasChilds});
             // console.log(v);
         });
     } else {
         this.apiService.getChilds('1').subscribe(data => {
             this.treeData.push({id: 1, name: 'Pubblici', hasChilds: true});
         });
     }*/
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  downloadDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // // // console.log(name);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const blob = new Blob([response], {type: 'application/octet-stream'});
        fileSaver.saveAs(blob, name);
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open('Nessun documento associato', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  openEditFileDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditFromFileProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openEditRevisionDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditRevisionProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openShowAttachmentsDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ShowAttachmentsDialogComponent, {
      width: '60%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  refreshView(): void {
    this.folderClick(this.currentTable);
  }

  goToProcess(proceId: string): void {
    this.apiService.getWorkflowId(proceId).subscribe(result => {
      result.forEach(val => {
        this.router.navigate(['../process', {
          processId: val.id,
          path: 'fascicoli',
          folderId: this.openId
        }], {relativeTo: this.route});
      });
    });
  }

  goToProfile(docNum: string): void {
    if (this.openId) {
      this.router.navigate(['../profile', {
        idDocument: docNum,
        taskId: '0',
        path: 'fascicoli',
        folderId: this.openId
      }], {relativeTo: this.route});
    } else {
      this.router.navigate(['../profile', {
        idDocument: docNum,
        taskId: '0',
        path: 'fascicoli'
      }], {relativeTo: this.route});
    }

  }

  folderClick(e): void {
    if (e.itemData.hasChilds === false) {
      this.currentTable = e;
      const id = e.itemData.id;
      const name = e.itemData.name;
      this.docResult = new Array<any>();
      this.apiService.getDocsId(id).subscribe(data => {
        this.isLoading = true;
        this.clickedFolderName = name;
        this.displayedColumns = [];
        this.displayedColumns.push('-1,Azioni');
        this.displayedColumns.push('STATO,Stato');
        this.displayedColumns.push('ORIGINALE,Nome file');
        this.displayedColumns.push('MITTENTE,Da');
        this.displayedColumns.push('DOCNAME,Oggetto');
        this.displayedColumns.push('DATADOC,Data documento');
        /* data.columns.forEach(val => {
          if (val.visible || val.id === 'DOCNUMBER') {
            this.displayedColumns.push(val.id + ',' + val.label);
          }
        }); */
        // console.log(this.displayedColumns);
        // // console.log(data);
        this.Result = new Array<any>();
        data.data.forEach(res => {
          this.buildResult = new Array<string>();
          this.noItems = false;
          data.columns.forEach((item, index) => {
            this.buildResult[data.columns[index].id + ',' +
            data.columns[index].label] = res[index];
          });
          // // console.log(this.Result);
          this.Result.push(this.buildResult);
          // // // console.log(this.buildResult);

        });
        this.dataSource = new MatTableDataSource(this.Result);
        // // console.log(this.Result);

        this.isLoading = false;

        if (data.data.length === 0) {
          this.showTable = false;
        } else {
          this.showTable = true;
        }
      });
    }


  }

  openInfoDialog(id: string, autore: string, nome: string): void {
    console.log('lalalala');
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '20%',
      data: {folderId: id, author: autore, name: nome}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openEditFolderDialog(id: string, name: string): void {
    const dialogRef = this.dialog.open(RenameFolderDialogComponent, {
      width: '20%',
      data: {folderId: id, folderName: name}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reRender();
    });
  }

  openAddFolderDialog(id: string): void {
    const dialogRef = this.dialog.open(AddFolderDialogComponent, {
      width: '20%',
      data: {folderId: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reRender();
    });
  }

  reRender(): void {
    if (this.openId) {
      this.router.navigate(['dashboard/dashnav/redirectfolder', {openId: this.openId}]);
    } else {
      this.router.navigate(['dashboard/dashnav/redirectfolder']);
    }
  }

  deleteFolder(id: string): void {
    this.apiService.deleteFolder(id).subscribe(result => {
      this.reRender();
      this.snackBar.open('Cartella eliminata con successo', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }


}

@Component({
  selector: 'app-info',
  templateUrl: 'infoDialog.html',
  styleUrls: ['./fascicoli.component.css']
})
export class InfoDialogComponent {
  id: string;
  author: string;
  name: string;
  nChilds = 0;
  nDocs: number;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<InfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.id = data.folderId;
    this.author = data.author;
    this.name = data.name;

    this.apiService.getChilds(this.id).subscribe(res => {
      res.forEach(v => {
        this.nChilds++;
      });
    });
    this.apiService.getDocsId(this.id).subscribe(res => {
      // console.log(res);
      this.nDocs = res.data.length;
    });

  }


  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-renamefolder',
  templateUrl: 'renameFolderDialog.html',
  styleUrls: ['./fascicoli.component.css']
})
export class RenameFolderDialogComponent {
  id: string;
  name: string;


  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<RenameFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.id = data.folderId;
    this.name = data.folderName;
  }

  send(): void {
    if (this.name !== undefined) {
      this.apiService.renameFolder(this.id, this.name).subscribe(result => {
        this.snackBar.open('Fascicolo rinominato correttamente', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.closeDialog();
      });
    } else {
      this.snackBar.open('Nome non valido', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-addfolder',
  templateUrl: 'addFolderDialog.html',
  styleUrls: ['./fascicoli.component.css']
})
export class AddFolderDialogComponent {
  id: string;
  name: string;


  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.id = data.folderId;
  }

  send(): void {
    if (this.name !== undefined) {
      this.apiService.addFolder(this.id, this.name).subscribe(result => {
        this.snackBar.open('Fascicolo creato correttamente', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.closeDialog();
      });
    } else {
      this.snackBar.open('Nome non valido', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
