<div class="center">
    <mat-card id="style-4" class="cardcomponentMasks" style = "overflow: hidden">
        <div style="display: flex; width: 80%; height: 10%">
            <div style="width: 90%">
                <h3 class="titles" style="color:{{this.dashColor}};">
                    <mat-form-field class="searchdimension">
                        <mat-label>
                            <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                            Cerca
                        </mat-label>
                        <input matInput [ngModel]="searchModelMaschere"
                               (ngModelChange)="updateSearchModelMaschere($event)">
                        <button mat-button *ngIf="searchModelMaschere" matSuffix mat-icon-button aria-label="Clear"
                                (click)="searchModelMaschere=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                </h3>
            </div>
            <div style="width: 20%; display: flex;
                text-align: right;
                justify-content: center;
                align-items: center;">
                <mat-slide-toggle [(ngModel)]="this.showSystemMasks" style = "color: {{this.apiService.dashColor}}">
                    Mostra maschere di sistema
                </mat-slide-toggle>
            </div>

        </div>

      <hr class="riga"
          style="color:{{this.dashColor}}; width: 90%; text-align: center; margin-right: auto; margin-left: auto;">
      <br>

        <div style="text-align: left;
  margin: auto; overflow: auto; height: 88%;
 ">
            <mat-selection-list #maschere [multiple]="false">
                <ng-container *ngFor="let maschera of this.Maschere | maskFilter: searchModelMaschere">
                    <mat-list-item *ngIf="maschera.maskName && (maschera.kind == 0 || this.showSystemMasks)"
                                   style="border-bottom: 1px solid {{this.dashColor}}"
                                   (click)="clickMaschera(maschera.id)" class="item" [value]="maschera.id">

                        <div mat-line style="color:{{this.dashColor}};"><b>{{maschera.maskName}}</b></div>
                        <div mat-line> {{maschera.maskDescription}}</div>
                        <!--<mat-icon class="icon"  svgIcon="CondividiIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon"  svgIcon="ModificaIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon" svgIcon="ClonaIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon" svgIcon="PermessiiIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon" svgIcon="EliminaIcon"  style="margin: 8px"></mat-icon>
                          -->

                    </mat-list-item>
                </ng-container>
            </mat-selection-list>
        </div>
        <div *ngIf="this.Maschere.length == 0" style="color: {{this.dashColor}}">
            <h2>Nessuna maschera da visualizzare</h2>
        </div>
    </mat-card>
</div>


