import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectedRowsMultiDTO} from '../VociMenu/maskDetails/maskDetails.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-dialogsearchdoc',
  styleUrls: ['classDTO-css.css'],
  templateUrl: 'dialog-content-searchDoc.html'
})

export class DialogSearchDocComponent implements OnInit {
  dataSource: MatTableDataSource<object>;
  displayedColumns: Array<string> = new Array<string>();
  squareColor: string;
  field: any;
  selectField: string;
  dataResult: Array<any> = new Array<any>();
  dataToSave: Array<any> = new Array();
  selection: SelectionModel<any>;
  find = false;
  searchResult: any = {};
  row: any = {};
  v: any = {};
  fieldNames: Array<string> = new Array<string>();
  buildResult: Array<string> = new Array<string>();
  Result: Array<any> = new Array<any>();
  noItems = true;
  searchFieldValue: Array<any> = new Array();

  constructor(public dialogRef: MatDialogRef<DialogSearchDocComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router,
              public dialog: MatDialog, private datePipe: DatePipe) {
    this.field = data.classField;
    // // console.log(this.field);

    /*this.docNumbers = data.mapClassValue;
    this.displayedColumns = new Array<string>();
    this.squareColor = this.apiService.squareColor;*/
  }

  ngOnInit(): void {
    this.selection = new SelectionModel<object>(true, []);
    this.apiService.searchDocument(this.field.documentType).subscribe(res => {
      this.searchResult = res;
      // // console.log(res);
      this.displayedColumns.push('select');
      this.searchResult.columns.forEach(val => {
        if (val.visible) {
          this.displayedColumns.push(val.label);
        }
      });
      this.searchResult.data.forEach(resu => {
        this.buildResult = new Array<string>();
        this.noItems = false;
        this.searchResult.columns.forEach((item, index) => {
          this.buildResult[this.searchResult.columns[index].label] = resu[index];
        });
        this.Result.push(this.buildResult);
      });
    });
    // // console.log(this.displayedColumns);
    // console.log(this.Result);
    this.dataSource = new MatTableDataSource(this.Result);

    /*this.field.columns.forEach(item => {
      this.displayedColumns.push(item.label);
    });
    this.displayedColumns.push('Azioni');
    this.docNumbers.forEach(systemId => {
      this.dataSource = new MatTableDataSource<object>();
      this.ELEMENT_DATA = new Array<object>();
      this.apiService.getProfileDetailsClass(systemId).subscribe(callResults => {
        this.arrayToObject = {};
        callResults.fields.forEach(fieldClass => {
          this.systemIdObject = {systemId: ''};
          this.displayedColumns.forEach(colum => {
            if (colum === fieldClass.description) {
              this.arrayToObject[colum] = fieldClass.value;
            }
          });
        });
        this.systemIdObject.systemId = systemId;
        this.arrayToObject.Azioni = this.systemIdObject.systemId;
        this.ELEMENT_DATA.push(this.arrayToObject);
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    });*/
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): any {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  saveSelectedValues(): void {
    this.dataResult = new Array<any>();
    this.dataToSave = new Array();
    this.fieldNames  = new Array<string>();
    this.selection.selected.forEach(itemSelected => {
      // // console.log(itemSelected);
      this.dataResult.push({docNumber: itemSelected['System ID'], fieldName: this.field.name});
      /*this.searchFieldValue.forEach(element => {
        this.find = false;
        element.forEach(arrayRow => {
          if (arrayRow.value === itemSelected[this.selectField]) {
            this.find = true;
          }
        });
        if (this.find) {
          this.dataToSave.push(element);
        }
      });*/
    });
    this.dialogRef.close({dataResult: this.dataResult});
  }
  closeDialog(): void {
    this.dialogRef.close({dataResult: false});
  }
}
