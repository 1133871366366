<div>
    <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="text-align: center"><h2>Rinomina categoria:<br></h2>
    <mat-form-field>
        <input [(ngModel)]="this.newCat" matInput name="Nome"
               placeholder="Nome categoria"
        >
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click)="rename()">Rinomina</button>
</div>
