import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {current} from 'devextreme/ui/themes';

@Component({
  selector: 'app-redirect-components',
  templateUrl: './redirect-components.component.html',
  styleUrls: ['./redirect-components.component.css']
})
export class RedirectComponentsComponent{
  current = this.activatedRoute.snapshot.paramMap.get('current');
  constructor(private route: ActivatedRoute,
              private router: Router,
              public apiService: ApiService, private activatedRoute: ActivatedRoute) {
    this.router.navigate([this.apiService.tempParamsForRedirect.destination, this.apiService.tempParamsForRedirect.parameters]);
  }
}
