<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%;text-align: left">
        <!-- Name Column -->
        <ng-container matColumnDef="idNot">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id Notifica </th>
            <td mat-cell *matCellDef="let element"> {{element.idNot}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
            <td mat-cell *matCellDef="let element"> {{element.state}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="date" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Sistema </th>
            <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>
        <ng-container matColumnDef="idFatt" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id Fattura </th>
            <td mat-cell *matCellDef="let element"> {{element.idFatt}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
