<div class="center">
    <mat-card id = "style-4" class="cardcomponentMasks">

            <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->
                <div *ngFor="let cartella of this.Cartelle" class="cartelle">
                    <mat-selection-list #maschere [multiple]="false">


                        <!-- cartella -->

                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true"
                                                 (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="item">
                                        <mat-icon style="margin-top: 12px; margin-right: 8px;"
                                                  svgIcon="CartellaIcon"></mat-icon>
                                        <span style="margin-top: 14px;">{{cartella.name}}</span>
                                    </mat-panel-title>
                                    <mat-panel-description>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <ng-container *ngFor="let modello of this.Modelli">
                                    <mat-list-item *ngIf="modello.groupName == cartella.name" class="item" style="border-bottom: 1px solid {{this.dashColor}}"
                                                   [value]="modello.id">

                                        <div mat-line style="color:{{this.dashColor}};">

                                            <b>{{modello.description}}</b></div>

                                        <mat-icon class="icon" svgIcon="CondividiIcon" style="margin: 8px"></mat-icon>
                                        <mat-icon class="icon" svgIcon="ModificaIcon" style="margin: 8px"></mat-icon>
                                        <mat-icon class="icon" svgIcon="ClonaIcon" style="margin: 8px"></mat-icon>
                                        <mat-icon class="icon" svgIcon="PermessiiIcon" style="margin: 8px"></mat-icon>
                                        <mat-icon class="icon" svgIcon="EliminaIcon" style="margin: 8px"></mat-icon>
                                    </mat-list-item>
                                </ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-selection-list>
                </div>
                    <div style="text-align: left;">
                        <mat-selection-list #maschere [multiple]="false">
                            <ng-container *ngFor="let modello of this.Modelli">
                                <mat-list-item (click) = "openModel(modello.id, modello.maskId)" *ngIf="modello.groupName == ''" class="item" [value]="modello.id" style="border-bottom: 1px solid {{this.dashColor}}">
                                    <ng-container *ngIf="modello.extension == 'xlsx'">
                                        <mat-icon title = "xlxs">ballot</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="modello.extension == 'doc' || modello.extension == 'docx'">
                                        <mat-icon title = "doc">article</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="modello.extension == 'pdf'">
                                        <mat-icon title = "pdf">picture_as_pdf</mat-icon>
                                    </ng-container>
                                    <div mat-line style="color:{{this.dashColor}};"><b>{{modello.description}}</b></div>
                                </mat-list-item>
                            </ng-container>
                        </mat-selection-list>
                    </div>
            </mat-card-content>
    </mat-card>
</div>
