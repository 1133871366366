import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-viewsregisters',
  templateUrl: './viewsRegisters.component.html',
  styleUrls: ['./viewsRegisters.component.css']
})
export class ViewRegistersComponent implements OnInit {
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  searchModelViews: string;
  views: Array<any> = new Array<any>();

  id = this.activatedRoute.snapshot.paramMap.get('id');
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;

  constructor(private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
              public apiService: ApiService, private activatedRoute: ActivatedRoute) {
    iconRegistry.addSvgIcon(
      'RicercaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/search-24px.svg'));
    iconRegistry.addSvgIcon(
      'CondividiIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/share-24px.svg'));
    iconRegistry.addSvgIcon(
      'ModificaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/create-24px.svg'));
    iconRegistry.addSvgIcon(
      'ClonaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/content_copy-24px.svg'));
    iconRegistry.addSvgIcon(
      'PermessiiIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/lock-24px.svg'));
    iconRegistry.addSvgIcon(
      'EliminaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/delete-24px.svg'));
  }

  ngOnInit(): void {
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.apiService.getViews().subscribe(datas => {
      this.views = datas;
    });
  }

  updateSearchModelViste(value): void {
    this.searchModelViews = value;
    this.searchModelChange.emit(this.searchModelViews);
  }

  clickVista(idView: string): void {
    this.router.navigate(['../viewDetails', {id: idView, path: 'viewregisters'}], {relativeTo: this.route});
  }
}
