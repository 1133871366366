// @ts-ignore
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class ProfileValuelsBody {
  generateBody(fieldNameToSearch: string, classType: string, className: string, classValue: string): JSON {
    return {
      searchFilterDto: {
        id: 0,
        fields: [
          {
            name: className,
            className: classType,
            value: classValue
          }
        ],
        postProfilationActions: [
          {
            shortDescription: '',
            description: '',
            action: 0,
            visible: true,
            value: true
          }
        ],
        constrainRoleBehaviour: 0,
        attachments: [
          'string'
        ],
        notes: [
          {
            id: 0,
            docnumber: 0,
            user: 0,
            userCompleteName: '',
            creationDate: '2020-09-11T13:00:18.374Z',
            comment: '',
            revision: 0,
            aosflag: true,
            countersTable: 0,
            externalId: 0
          }
        ],
        paNotes: [
          'string'
        ],
        authorityData: {
          id: 0,
          docNumber: 0,
          protocol: '',
          protocolDate: '2020-09-11T13:00:18.374Z',
          office: '',
          person: '',
          shipping: '',
          yourReferent: ''
        },
        generatePaProtocol: true
      },
      fieldName: fieldNameToSearch,
      filterValue: '',
      filterId: ''
    } as unknown as JSON;
  }

  generateBodyV2(fieldNameToSearch: string, fields: any): JSON {
    return {
      searchFilterDto: {
          fields
      },
      fieldName: fieldNameToSearch
    } as unknown as JSON;
  }
  generateBodyAoo(fieldNameToSearch: string, classType: string, className: string, classValue: string,
                  AooName: string, AooClass: string, AooValue: string): JSON {
    return {
      searchFilterDto: {
        id: 0,
        fields: [
          {
            name: className,
            className: classType,
            value: classValue
          }, {
            name: AooName,
            className: AooClass,
            value: AooValue
          }
        ],
        postProfilationActions: [
          {
            shortDescription: '',
            description: '',
            action: 0,
            visible: true,
            value: true
          }
        ],
        constrainRoleBehaviour: 0,
        attachments: [
          'string'
        ],
        notes: [
          {
            id: 0,
            docnumber: 0,
            user: 0,
            userCompleteName: '',
            creationDate: '2020-09-11T13:00:18.374Z',
            comment: '',
            revision: 0,
            aosflag: true,
            countersTable: 0,
            externalId: 0
          }
        ],
        paNotes: [
          'string'
        ],
        authorityData: {
          id: 0,
          docNumber: 0,
          protocol: '',
          protocolDate: '2020-09-11T13:00:18.374Z',
          office: '',
          person: '',
          shipping: '',
          yourReferent: ''
        },
        generatePaProtocol: true
      },
      fieldName: fieldNameToSearch,
      filterValue: '',
      filterId: ''
    } as unknown as JSON;
  }
}
