import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-maskclients',
  templateUrl: './maskclients.component.html',
  styleUrls: ['./maskclients.component.css']
})
export class MaskclientsComponent implements OnInit {
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  id = this.activatedRoute.snapshot.paramMap.get('id');
  displayedColumns = ['nome', 'descrizione'];
  schema: [];
  Maschere: [];
  searchModelMaschere: string;
  dataSource: [];
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;

  // tslint:disable-next-line:max-line-length variable-name
  constructor(private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) {
    iconRegistry.addSvgIcon(
      'CondividiIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/share-24px.svg'));
    iconRegistry.addSvgIcon(
      'ModificaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/create-24px.svg'));
    iconRegistry.addSvgIcon(
      'ClonaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/content_copy-24px.svg'));
    iconRegistry.addSvgIcon(
      'PermessiiIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/lock-24px.svg'));
    iconRegistry.addSvgIcon(
      'EliminaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/delete-24px.svg'));


  }

  ngOnInit(): void {
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.apiService.getAllMasks().subscribe(data => {
      this.Maschere = data;
    });

  }

  // tslint:disable-next-line:typedef
  updateSearchModelMaschere(value) {
    this.searchModelMaschere = value;
    this.searchModelChange.emit(this.searchModelMaschere);
  }

// tslint:disable-next-line:typedef

  // tslint:disable-next-line:ban-types typedef
  clickMaschera(idMask: String) {
    this.router.navigate(['../maskDetails', {id: idMask, idTask: 0, path: 'maskclients'}], {relativeTo: this.route});
  }

  goBack(): void {
    this.router.navigate(['../maskclients'], {relativeTo: this.route});
  }
}
