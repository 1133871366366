import {AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort, MatSortable} from '@angular/material/sort';
import {ViewBodyToSave} from '../../viewBodyToSave';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {MatPaginator} from '@angular/material/paginator';
import {fromEvent, Subscription} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';
import {filter, take} from 'rxjs/operators';
import {FilterDialogComponent} from '../../VociMenu/default-home/default-home.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import * as fileSaver from 'file-saver';
import {
  EditFromFileProfileComponent,
  EditRevisionProfileComponent,
  ProfileNotesComponent, ShowAttachmentsDialogComponent,
  SignerDetailsComponent
} from '../view/view.component';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.css']
})
export class RelationsComponent implements OnInit, AfterViewInit {

  id = this.activatedRoute.snapshot.paramMap.get('id');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  displayedColumns: Array<string> = new Array<string>();
  row: any = {};
  v: any = {};
  buildResult: Array<string> = new Array<string>();
  Result: Array<any> = new Array<any>();
  noItems = true;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  rowsNum: string;
  private dataSource: MatTableDataSource<any>;
  selection = new SelectionModel(true, []);
  selectedDocN: string;
  filter: string;
  filterTags: Array<any> = new Array<any>();
  commands: any;
  folders: Array<any> = new Array<any>();
  docNumber = this.activatedRoute.snapshot.paramMap.get('relDocN');
  jsonForRelationCallChild: any = {};
  jsonForRelationCallSon: any = {};
  relations: any;
  isSelectedDocArrayToUse = true;
  numSelected = 0;
  selectedDocNArray: Array<any> = new Array<any>();
  lastSelectedIndex: number;
  generalExpanded = true;
  maxLevel = 0;

  constructor(private viewBody: ViewBodyToSave, public dialog: MatDialog, private snackBar: MatSnackBar, private datePipe: DatePipe, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              // tslint:disable-next-line:max-line-length
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute, public overlay: Overlay,
              public viewContainerRef: ViewContainerRef) {
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;


  dontSelectWhenClicked(docN: string): void {
    this.dataSource.filteredData.forEach((data) => {
      if (data['DOCNUMBER,System ID'] === docN) {
        if (data.selected) {
          data.selected = false;
          this.selectedDocNArray.splice(this.selectedDocNArray.indexOf(docN), 1);
          this.numSelected--;
        } else {
          data.selected = true;
          this.selectedDocNArray.push(data['DOCNUMBER,System ID']);
          this.numSelected++;
        }
      }
    });
  }

  clickedRow(event: any, row: any, docN: string): void {

    if (this.selectedDocNArray.length > 0 && event.shiftKey) {
      let currentIndex: number;
      console.log(this.dataSource);
      this.dataSource.filteredData.forEach((data, index) => {
        if (data['DOCNUMBER,System ID'] === docN) {
          currentIndex = index;
        }
      });
      if (currentIndex > this.lastSelectedIndex) {
        this.dataSource.filteredData.forEach((data, index) => {
          if ((index > this.lastSelectedIndex) && (index <= currentIndex)) {
            if (data.selected === false) {
              data.selected = true;
              this.selectedDocNArray.push(data['DOCNUMBER,System ID']);
              this.numSelected++;
              if (index === this.dataSource.filteredData.length) {
                this.lastSelectedIndex = index;
              }
            }
          }
        });
      } else if (currentIndex === this.lastSelectedIndex) {
        this.selectedDocNArray.splice(this.selectedDocNArray.indexOf(docN), 1);
        row.selected = false;
        this.numSelected--;
      } else {
        this.dataSource.filteredData.forEach((data, index) => {
          if ((index >= currentIndex) && (index < this.lastSelectedIndex)) {
            if (data.selected === false) {
              data.selected = true;
              this.selectedDocNArray.push(data['DOCNUMBER,System ID']);
              this.numSelected++;
              if (index === this.dataSource.filteredData.length) {
                this.lastSelectedIndex = index;
              }
            }
          }
        });
      }
    } else {
      if (row.selected) {
        this.selectedDocNArray.splice(this.selectedDocNArray.indexOf(docN), 1);
        row.selected = false;
        this.numSelected--;
      } else {
        this.selectedDocNArray.push(docN);
        row.selected = true;
        this.numSelected++;
        this.dataSource.filteredData.forEach((data, index) => {
          if (data['DOCNUMBER,System ID'] === docN) {
            this.lastSelectedIndex = index;
          }
        });
      }
    }

  }

  DBclickedRow(event: any, row: any, docN: string): void {
    if (this.numSelected === 0) {
      this.goToProfile(docN);
    } else {
      this.cancelSelection();
    }
  }

  cancelSelection(): void {
    this.dataSource.filteredData.forEach(data => {
      if (data.selected) {
        data.selected = false;
      }
    });
    this.numSelected = 0;
    this.selectedDocNArray = new Array<any>();
  }

  open({x, y}: MouseEvent, user, docN): void {
    this.selectedDocN = docN;
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());
  }

  delete(user): void {
    // delete user
    this.close();
  }

  close(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngAfterViewInit(): void {


    // this.dataSource.sort = this.sort;
    // this.sort.sort(({id: 'level', start: 'asc'}) as MatSortable);


    this.dataSource.paginator = this.paginator;

  }

  generalExpandClicked(): void {
    this.generalExpanded = !this.generalExpanded;
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '40%',
      data: {tags: this.filterTags}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.resultFilter) {
        this.filterTags = result.resultFilter;
        this.filterChange();
      }
    });
  }

  filterChange(): void {
    if (this.filter !== null && this.filter !== undefined) {
      let found;
      const tempRes: Array<any> = new Array<any>();
      let tempColumns: Array<any> = new Array<any>();
      tempColumns = this.filterTags;
      this.displayedColumns.forEach(d => {
        tempColumns.forEach(t => {
          if (d.includes(t.tag)) {
            t.tag = d;
          }
        });
      });
      this.Result.forEach(r => {
        found = false;
        tempColumns.forEach(dc => {
          if (dc.active) {
            if (r[dc.tag]) {
              if (r[dc.tag].toString().toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())) {
                found = true;
              }
            }
          }

        });
        if (found) {
          tempRes.push(r);
        }
      });
      this.dataSource = new MatTableDataSource(tempRes);

    }
  }

  enableFolder(id): void {
    this.folders.forEach(fol => {
      if (fol.id === id) {
        if (fol.enabled) {
          fol.enabled = false;
        } else {
          fol.enabled = true;
        }
      }
    });
  }

  startEvent(eventId: string, docN: number): void {
    if (this.selectedDocNArray.length > 0 && this.isSelectedDocArrayToUse) {
      this.isSelectedDocArrayToUse = false;
      this.selectedDocNArray.forEach(d => {
        this.startEvent(eventId, d);
      });
      this.isSelectedDocArrayToUse = true;
    } else {
      this.apiService.getWorkflowEvents([docN]).subscribe(res => {
        res.forEach(v => {
          if (v.eventId.toString() === eventId) {
            this.apiService.canStartEventByDocnumber(docN.toString()).subscribe(result => {
              if (result) {
                this.apiService.startWorkflow(docN.toString(), eventId).subscribe(r => {
                  this.snackBar.open('WorkFlow avviato correttamente', null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                  });
                });
              }
            });
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.selectedDocNArray = new Array<any>();
    this.numSelected = this.selectedDocNArray.length;
    this.apiService.addPath('Relazioni documento: ' + this.docNumber, 'dashboard/dashnav/relations', {
      relDocN: this.docNumber,
    });
    this.jsonForRelationCallChild = {
      docNumber: this.docNumber,
      relationExploringMethod: 2,
      select: {
        fields: [
          {
            externalId: null,
            fieldType: 0,
            index: 1,
            label: 'Note',
            name: 'NOTE'
          },
          {
            selected: true,
            sqlSelectField: 'DM_PROFILE.NOTE',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 3,
            label: 'Allegati',
            name: 'ALLEGATI',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.ALLEGATI',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 4,
            label: 'Stato',
            name: 'STATO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.STATO AS STATO',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 0,
            label: 'Origine',
            name: 'INOUT',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: '{INOUT}',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 2,
            label: 'Workflow',
            name: 'WORKFLOW',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.WORKFLOW',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 13,
            label: 'Relazioni',
            name: 'RELAZIONI',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: '{Relazioni}',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 5,
            label: 'Formato',
            name: 'FORMATO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: '{Formato}',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 6,
            label: 'System ID',
            name: 'DOCNUMBER',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DOCNUMBER',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 7,
            label: 'Revisione',
            name: 'REVISIONE',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.REVISIONE',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 10,
            label: 'Oggetto',
            name: 'DOCNAME',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DOCNAME',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 12,
            label: 'Data documento',
            name: 'DATADOC',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DATADOC',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 8,
            label: 'Da',
            name: 'MITTENTE',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.MITTENTE',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 9,
            label: 'A',
            name: 'DESTINATARIO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DESTINATARIO',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 11,
            label: 'Numero',
            name: 'NUMERO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.NUMERO',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          }
        ]
      }
    };
    this.jsonForRelationCallSon = {
      docNumber: this.docNumber,
      relationExploringMethod: 1,
      select: {
        fields: [
          {
            externalId: null,
            fieldType: 0,
            index: 1,
            label: 'Note',
            name: 'NOTE'
          },
          {
            selected: true,
            sqlSelectField: 'DM_PROFILE.NOTE',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 3,
            label: 'Allegati',
            name: 'ALLEGATI',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.ALLEGATI',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 4,
            label: 'Stato',
            name: 'STATO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.STATO AS STATO',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 0,
            label: 'Origine',
            name: 'INOUT',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: '{INOUT}',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 2,
            label: 'Workflow',
            name: 'WORKFLOW',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.WORKFLOW',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 13,
            label: 'Relazioni',
            name: 'RELAZIONI',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: '{Relazioni}',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 5,
            label: 'Formato',
            name: 'FORMATO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: '{Formato}',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 0
          },
          {
            externalId: null,
            fieldType: 0,
            index: 6,
            label: 'System ID',
            name: 'DOCNUMBER',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DOCNUMBER',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 7,
            label: 'Revisione',
            name: 'REVISIONE',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.REVISIONE',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 10,
            label: 'Oggetto',
            name: 'DOCNAME',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DOCNAME',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 12,
            label: 'Data documento',
            name: 'DATADOC',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DATADOC',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 8,
            label: 'Da',
            name: 'MITTENTE',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.MITTENTE',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 9,
            label: 'A',
            name: 'DESTINATARIO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.DESTINATARIO',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          },
          {
            externalId: null,
            fieldType: 0,
            index: 11,
            label: 'Numero',
            name: 'NUMERO',
            orderBy: {direction: 0, index: 0},
            selected: true,
            sqlSelectField: 'DM_PROFILE.NUMERO',
            toCalculate: false,
            userSelectionEnabled: true,
            userSelectionGroup: 1
          }
        ]
      }
    };
    this.generalExpanded = true;
    this.apiService.postRelations(this.jsonForRelationCallChild).subscribe(result => {
      this.generalExpanded = true;
      this.Result = new Array<any>();
      this.displayedColumns = new Array<string>();
      this.buildResult = new Array<string>();
      this.dashColor = this.apiService.dashColor;
      this.backColor = this.apiService.backColor;
      this.squareColor = this.apiService.squareColor;
      this.cardColor = this.apiService.cardColor;
      this.folders = this.apiService.commandFolders;
      this.commands = this.apiService.profileCommands;
      this.relations = result;
      // // console.log(result);
      this.rowsNum = result.composedValues.length;
      this.displayedColumns.push('expand');
      result.composedValues[0].columns.forEach(res => {
        if (res.visible) {
          this.displayedColumns.push(res.id + ',' + res.label);
        }
      });
      result.composedValues.forEach(v => {
        this.buildResult = new Array<string>();
        this.noItems = false;

        v.columns.forEach((item, index) => {
          if (item.columnType === 'DateTime') {
            this.buildResult[item.id + ',' +
            item.label] = this.TransformDataFormat(item.value);
          } else {
            this.buildResult[item.id + ',' +
            item.label] = item.value;
          }
          if (item.id === 'DOCNUMBER') {
            if (item.value === result.rootNode.docNumber) {
              this.buildResult['level'] = 0;
            } else {
              this.buildResult['level'] = this.getLevel(item.value, result.rootNode.childs, 1);
              if (this.buildResult['level'] > this.maxLevel){
                this.maxLevel = this.buildResult['level'];
              }
            }
          }
        });
        this.buildResult['selected'] = false;
        this.Result.push(this.buildResult);
        this.Result.sort((a, b) => (a.level > b.level) ? 1 : -1);
        console.log(this.Result);
      });

      if (this.filterTags.length === 0) {
        this.displayedColumns.forEach(v => {
          this.filterTags.push({tag: v.substr(v.indexOf(',') + 1, v.length), active: true});
        });
      }
      this.dataSource = new MatTableDataSource<any>(this.Result);
      console.log(this.dataSource);
      this.filterChange();

    });

  }
  deleteDocument(docN: string): void{
    this.apiService.deleteProfile(docN).subscribe(res => {
      this.close();
      this.refreshView();
      this.snackBar.open('Profilo eliminato con successo', null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });
    }, errorLog => {
      this.close();
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }
  childClick(): void {
    this.selectedDocNArray = new Array<any>();
    this.numSelected = this.selectedDocNArray.length;
    this.apiService.postRelations(this.jsonForRelationCallChild).subscribe(result => {
      this.generalExpanded = true;
      this.Result = new Array<any>();
      this.displayedColumns = new Array<string>();
      this.buildResult = new Array<string>();
      this.folders = this.apiService.commandFolders;
      this.commands = this.apiService.profileCommands;
      this.relations = result;
      // // console.log(result);
      this.rowsNum = result.composedValues.length;
      this.displayedColumns.push('expand');
      result.composedValues[0].columns.forEach(res => {
        if (res.visible) {
          this.displayedColumns.push(res.id + ',' + res.label);
        }
      });
      result.composedValues.forEach(v => {
        this.buildResult = new Array<string>();
        this.noItems = false;

        v.columns.forEach((item, index) => {
          if (item.columnType === 'DateTime') {
            this.buildResult[item.id + ',' +
            item.label] = this.TransformDataFormat(item.value);
          } else {
            this.buildResult[item.id + ',' +
            item.label] = item.value;
          }
          if (item.id === 'DOCNUMBER') {
            if (item.value === result.rootNode.docNumber) {
              this.buildResult['level'] = 0;
            } else {
              this.buildResult['level'] = this.getLevel(item.value, result.rootNode.childs, 1);
            }
          }
        });
        this.buildResult['selected'] = false;
        this.Result.push(this.buildResult);
        this.Result.sort((a, b) => (a.level > b.level) ? 1 : -1);
      });
      this.Result.forEach(resRow => {
        if (resRow['ALLEGATI,Allegati'] === '1') {
          this.apiService.docAttachments(resRow['DOCNUMBER,System ID']).subscribe(val => {
            resRow['ALLEGATI,Allegati'] = val.length;
          });
        }
        if (resRow['NOTE,Note'] === 'S') {
          this.apiService.getNotes(resRow['DOCNUMBER,System ID']).subscribe(val => {
            resRow['NOTE,Note'] = val.length;
          });
        }
      });
      if (this.filterTags.length === 0) {
        this.displayedColumns.forEach(v => {
          this.filterTags.push({tag: v.substr(v.indexOf(',') + 1, v.length), active: true});
        });
      }
      this.dataSource = new MatTableDataSource<any>(this.Result);
      this.filterChange();
    });
  }

  getLevel(id: string, childs: any, lev: number): any {
    let found = false;
    if (childs === []) {
      return null;
    } else {
      childs.forEach(v => {
        if (v.docNumber === id) {
          found = true;
        }
      });
      if (found) {
        return lev;
      } else {
        childs.forEach(v => {
          let lev;
          lev = this.getLevel(id, v.childs, lev + 1);
          if (lev === null) {

          } else {
            return lev;
          }
        });
      }
    }
  }

  sonClick(): void {
    this.selectedDocNArray = new Array<any>();
    this.numSelected = this.selectedDocNArray.length;
    this.apiService.postRelations(this.jsonForRelationCallSon).subscribe(result => {
      this.generalExpanded = true;
      this.Result = new Array<any>();
      this.displayedColumns = new Array<string>();
      this.buildResult = new Array<string>();
      this.dashColor = this.apiService.dashColor;
      this.backColor = this.apiService.backColor;
      this.squareColor = this.apiService.squareColor;
      this.cardColor = this.apiService.cardColor;
      this.folders = this.apiService.commandFolders;
      this.commands = this.apiService.profileCommands;
      this.relations = result;
      // // console.log(result);
      this.rowsNum = result.composedValues.length;
      this.displayedColumns.push('expand');
      result.composedValues[0].columns.forEach(res => {
        if (res.visible) {
          this.displayedColumns.push(res.id + ',' + res.label);
        }
      });
      result.composedValues.forEach(v => {
        this.buildResult = new Array<string>();
        this.noItems = false;
        v.columns.forEach((item, index) => {
          if (item.columnType === 'DateTime') {
            this.buildResult[item.id + ',' +
            item.label] = this.TransformDataFormat(item.value);
          } else {
            this.buildResult[item.id + ',' +
            item.label] = item.value;
          }
          if (item.id === 'DOCNUMBER') {
            if (item.value === result.rootNode.docNumber) {
              this.buildResult['level'] = 0;
            } else {
              this.buildResult['level'] = this.getLevel(item.value, result.rootNode.childs, 1);
            }
          }
        });
        this.buildResult['selected'] = false;
        this.Result.push(this.buildResult);
        this.Result.sort((a, b) => (a.level > b.level) ? 1 : -1);
      });
      this.Result.forEach(resRow => {
        if (resRow['ALLEGATI,Allegati'] === '1') {
          this.apiService.docAttachments(resRow['DOCNUMBER,System ID']).subscribe(val => {
            resRow['ALLEGATI,Allegati'] = val.length;
          });
        }
        if (resRow['NOTE,Note'] === 'S') {
          this.apiService.getNotes(resRow['DOCNUMBER,System ID']).subscribe(val => {
            resRow['NOTE,Note'] = val.length;
          });
        }
      });
      if (this.filterTags.length === 0) {
        this.displayedColumns.forEach(v => {
          this.filterTags.push({tag: v.substr(v.indexOf(',') + 1, v.length), active: true});
        });
      }
      this.dataSource = new MatTableDataSource<any>(this.Result);
      this.filterChange();
    });
  }

  classClick(): void {

  }
  getClass(selected: boolean): string {
    if (!selected) {
      return 'tableRow';
    } else {
      return 'tableRowSelected';
    }
  }
  openRelationsDialog(docNumber: string): void {
    this.router.navigate(['../relations', {relDocN: docNumber}], {relativeTo: this.route});
  }
  openProfileNotesDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ProfileNotesComponent, {
      width: '50%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }
  exportMultipleToCSV(): void {
    let header;
    const data: Array<any> = new Array<any>();
    this.Result.forEach(res => {
      header = Object.keys(res);
      const tempArray: Array<any> = new Array<any>();
      console.log(res['selected']);
      if (res['selected'] === true) {
        header.forEach((v, index) => {
          let value: string;
          value = res[v];
          if (value) {
            value = value.toString().replace(',', '');
          }
          tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
        });

        data.push(tempArray);
      }
      header.forEach((v, index) => {
        header[index] = v.substr(v.indexOf(',') + 1, v.length);
      });
    });
    this.exportAll(data, header);
  }
  exportAll(data: any, header): void {
    this.apiService.exportCSV(data, 'profiles', header);
  }
  exportToCSV(id: string): void {
    if (this.selectedDocNArray.length > 0) {
      this.exportMultipleToCSV();
    } else {
      this.Result.forEach(res => {
        if (res['DOCNUMBER,System ID'] === id) {
          this.apiService.getUserSettings().subscribe(response => {
            if (response.profile) {
              const header = Object.keys(res);
              const tempArray: Array<any> = new Array<any>();
              header.forEach((v, index) => {
                header[index] = v.substr(v.indexOf(',') + 1, v.length);
                let value: string;
                // // console.log(res);
                // // console.log(header);
                if (res[v] !== null) {
                  value = res[v];
                } else {
                  value = '';
                }
                value = value.toString().replace(',', '');
                tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
              });
              this.apiService.exportCSV([tempArray], 'profile', header);
            } else {
              const header = Object.keys(res);
              const tempArray: Array<any> = new Array<any>();
              header.forEach((v, index) => {
                header[index] = v.substr(v.indexOf(',') + 1, v.length);
                let value: string;
                value = res[v];
                if (value) {
                  value = value.toString().replace(',', '');
                }
                tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
              });
              this.apiService.exportCSV([tempArray], 'profile', header);
            }
          });
        }
      });
    }

  }

  signDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // // console.log(result);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        const self = this;
        // tslint:disable-next-line:only-arrow-functions
        reader.onloadend = function(): void {
          let base64data = reader.result.toString();
          base64data = base64data.substr(base64data.indexOf(',') + 1);
          const dialogRef = self.dialog.open(SignerDetailsComponent, {
            width: '20%',
            data: {}
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res.signerEmail) {
              self.apiService.docusignSendEnvelope(base64data, name,
                name.substr(name.lastIndexOf('.') + 1), res.signerEmail, res.signerName).subscribe(resu => {
                // // console.log(resu);
                self.snackBar.open('Operazione effettuata con successo!', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
              });
            } else {
              self.snackBar.open('Operazione annullata', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
              });
            }
          });
        };
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open('Nessun documento associato', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  refreshView(): void {
    if (this.firstnodepath === 'ricerca') {
      this.apiService.doSearch(this.apiService.viewBody).subscribe(results => {
        this.apiService.viewDataToShow = results;
        this.ngOnInit();
      });
    } else {
      this.apiService.getViewFilter(this.apiService.viewBody).subscribe(data => {
        this.apiService.viewDataToShow = data;
        this.ngOnInit();
      });
    }

  }

  goToProfile(docNum: string): void {
    this.router.navigate(['../profile', {
      idDocument: docNum,
      taskId: '0',
      idv: this.id,
      firstnodepath: this.firstnodepath
    }], {relativeTo: this.route});
  }

  goBack(): void {
    if (this.firstnodepath === 'ricerca') {
      this.router.navigate(['../ricerca'], {relativeTo: this.route});
    } else {
      this.router.navigate(['../viewDetails', {id: this.id, firstnodepath: this.firstnodepath}], {relativeTo: this.route});
    }
  }

  openEditFileDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditFromFileProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openEditRevisionDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditRevisionProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openShowAttachmentsDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ShowAttachmentsDialogComponent, {
      width: '60%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }


  downloadDoc(Id: string): void {
    if (this.selectedDocNArray.length > 0 && this.isSelectedDocArrayToUse) {
      this.isSelectedDocArrayToUse = false;
      this.selectedDocNArray.forEach(d => {
        this.downloadDoc(d);
      });
      this.isSelectedDocArrayToUse = true;
    } else {
      let name = '';
      this.apiService.getProfileDetailsClass(Id).subscribe(result => {
        name = result.profileInfo.fileName;
        // // // console.log(name);
        this.apiService.downloadDocument(Id).subscribe(response => {
          const blob = new Blob([response], {type: 'application/octet-stream'});
          fileSaver.saveAs(blob, name);
          // tslint:disable-next-line:no-unused-expression
        }, errorLog => {
          this.snackBar.open('Nessun documento associato', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }

  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  getFormat(doc: string): any {
    let format = '';
    this.apiService.getProfileDetailsClass(doc).subscribe(result => {
      format = result.profileInfo.fileName;
      return format;
    });

  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  goToProcess(docId: string): void {
    let id = '';
    this.apiService.getWorkflowId(docId).subscribe(result => {
      result.forEach(val => {
        id = val.id;
        this.router.navigate(['../process', {
          processId: id,
          path: 'view',
          idv: this.id,
          firstnodepath: this.firstnodepath
        }], {relativeTo: this.route});
      });
    });
  }

}
