<div class="center" style = "height: unset !important;">
   
    <mat-card class="cardcomponentMasks">
        <button title="Salva" (click)="closeDialog()" style="float: right;" mat-icon-button color="warn" aria-label="icon-button save">
            <mat-icon>close</mat-icon>
        </button>
        <h3 class="titles" style="color: {{this.dashColor}};">
            <mat-card-title><p>Classi documento:</p></mat-card-title>
        </h3>
        <hr class="riga" style="color: {{this.dashColor}};">
        <br>
        <mat-card-content>

            <!--hai cliccato la maschera con id: {{this.id}}-->
            <div style="display: flex" class="bigbox">
                <div style="width: 100%" class="tree">
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
                                  style="background: transparent;">
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                                <button mat-icon-button disabled></button>
                                <button mat-button (click)="docClassClick(node.item.substr(node.item.indexOf('#@')+2, node.item.length))"
                                >{{node.item.substr(0, node.item.indexOf('#@'))}}</button>
                            </mat-tree-node>
                            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                                <button mat-icon-button
                                        [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <ng-container
                                        *ngIf="node.item != '[ROOT] - Elenco classi documentali'">{{node.item.substr(0, node.item.indexOf('#@'))}}</ng-container>
                                <ng-container
                                        *ngIf="node.item == '[ROOT] - Elenco classi documentali'">{{node.item}}</ng-container>

                            </mat-tree-node>
                        </mat-tree>
                </div>
            </div>


        </mat-card-content>

    </mat-card>
</div>
