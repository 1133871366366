<div style="width: 100%; height: 5%;position: -webkit-sticky;
  position: sticky; top: 0;">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="text-align: left">
    <h2>Scarica il report generato:</h2>
    <i style="text-align: center">Dopo avere eseguito il report, in questa sezione sarà possibile scaricarlo nei formati
        scelti</i><br><br>
    <ng-container *ngFor="let row of this.downloadRows">
        <p>
            <button mat-button (click) = "this.downloadReport(row.data.id, row.data.filename)">
                <mat-icon>get_app</mat-icon>
            </button>
            <ng-container [ngSwitch]="row.id">
                <ng-container *ngSwitchCase="'29'">
                    <b style = "margin-left: 10px">HTML </b><ng-container *ngIf="row.data.fileSize > 1000000">({{(row.data.fileSize/(1.049 * (1000000))).toFixed(2)}} MB)</ng-container><ng-container *ngIf="row.data.fileSize <= 1000000">({{(row.data.fileSize/1024).toFixed(2)}} KB)</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'17'">
                    <b style = "margin-left: 10px">CSV </b><ng-container *ngIf="row.data.fileSize > 1000000">({{(row.data.fileSize/(1.049 * (1000000))).toFixed(2)}} MB)</ng-container><ng-container *ngIf="row.data.fileSize <= 1000000">({{(row.data.fileSize/1024).toFixed(2)}} KB)</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'14'">
                    <b style = "margin-left: 10px">EXCEL2007 </b><ng-container *ngIf="row.data.fileSize > 1000000">({{(row.data.fileSize/(1.049 * (1000000))).toFixed(2)}} MB)</ng-container><ng-container *ngIf="row.data.fileSize <= 1000000">({{(row.data.fileSize/1024).toFixed(2)}} KB)</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'1'">
                    <b style = "margin-left: 10px">PDF </b><ng-container *ngIf="row.data.fileSize > 1000000">({{(row.data.fileSize/(1.049 * (1000000))).toFixed(2)}} MB)</ng-container><ng-container *ngIf="row.data.fileSize <= 1000000">({{(row.data.fileSize/1024).toFixed(2)}} KB)</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'15'">
                    <b style = "margin-left: 10px">WORD2007 </b><ng-container *ngIf="row.data.fileSize > 1000000">({{(row.data.fileSize/(1.049 * (1000000))).toFixed(2)}} MB)</ng-container><ng-container *ngIf="row.data.fileSize <= 1000000">({{(row.data.fileSize/1024).toFixed(2)}} KB)</ng-container>
                </ng-container>
            </ng-container>
        </p>
    </ng-container>


</div>

