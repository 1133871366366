<div class="center">
    <mat-card class="cardcomponentMasks" id="style-4">
        <h3 class="titles" style="color:{{this.dashColor}};">
            <mat-form-field class="searchdimension">
                <mat-label>
                    <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                    Cerca
                </mat-label>
                <input matInput [ngModel]="searchModelViews" (ngModelChange)="updateSearchModelViste($event)">
                <button mat-button *ngIf="searchModelViews" matSuffix mat-icon-button aria-label="Clear"
                        (click)="searchModelViews=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </h3>
        <hr class="riga"
            style="color:{{this.dashColor}}; width: 90%; text-align: center; margin-right: auto; margin-left: auto;">
        <br>
        <mat-card-content>
        </mat-card-content>
        <div style="text-align: left;">
            <mat-selection-list #viste [multiple]="false">
                <ng-container *ngFor="let vista of this.views | vistaFilter: searchModelViews ">
                    <mat-list-item *ngIf="vista.description" style="border-bottom: 1px solid {{this.dashColor}}"
                                   (click)="clickVista(vista.id)" class="item"> <!---->
                        <div mat-line style="color:{{this.dashColor}};"><b>{{vista.description}}</b></div>
                        <div mat-line>Utente: {{vista.userCompleteName}}</div>
                        <!--<mat-icon class="icon"  svgIcon="CondividiIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon"  svgIcon="ModificaIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon" svgIcon="PermessiiIcon"  style="margin: 8px"></mat-icon>
                        <mat-icon class="icon" svgIcon="EliminaIcon"  style="margin: 8px"></mat-icon>
                        *ngIf="vista.description.startsWith('Registri - ')"
                        -->
                    </mat-list-item>
                </ng-container>
            </mat-selection-list>
        </div>
        <div *ngIf="this.views.length == 0" style="color: {{this.dashColor}}">
            <h2>Nessuna vista da visualizzare</h2>
        </div>
    </mat-card>
</div>
