import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NavBarComponent} from './layout/nav-bar/nav-bar.component';
import {HomeComponent} from './VociMenu/home/home.component';
import {RubricaComponent} from './VociMenu/rubrica/rubrica.component';
import {DashnavComponent} from './dashnav/dashnav.component';

import {ArchiviazioneComponent} from './VociMenu/archiviazione/archiviazione.component';
import {RicercaComponent} from './VociMenu/ricerca/ricerca.component';
import {UltimaricercaComponent} from './VociMenu/ultimaricerca/ultimaricerca.component';
import {VisteComponent} from './VociMenu/viste/viste.component';
import {MaschereComponent} from './VociMenu/maschere/maschere.component';
import {TaskComponent} from './VociMenu/task/task.component';
import {FascicoliComponent} from './VociMenu/fascicoli/fascicoli.component';
import {StrumentiComponent} from './VociMenu/strumenti/strumenti.component';
import {GestioneComponent} from './VociMenu/gestione/gestione.component';
import {MaskDetailsComponent} from './VociMenu/maskDetails/maskDetails.component';
import {ViewDetailsComponent} from './VociMenu/viewDetails/viewDetails.component';
import {ModelliComponent} from './VociMenu/modelli/modelli.component';
import {ProfiliComponent} from './VociMenu/profili/profili.component';
import {DettaglirubricaComponent} from './VociMenu/dettaglirubrica/dettaglirubrica.component';
import {DettaglitaskComponent} from './VociMenu/dettaglitask/dettaglitask.component';
import {SettingsComponent} from './VociMenu/settings/settings.component';
import {HomeV2Component} from './MenuItemsV2/home-v2/home-v2.component';
import {DashboardV2Component} from './dashboard-v2/dashboard-v2.component';
import {MaskagreementsComponent} from './MenuItemsV2/maskagreements/maskagreements.component';
import {MaskclientsComponent} from './MenuItemsV2/maskclients/maskclients.component';
import {MaskdatabreachComponent} from './MenuItemsV2/maskdatabreach/maskdatabreach.component';
import {MaskrequestsComponent} from './MenuItemsV2/maskrequests/maskrequests.component';
import {MaskresourcesComponent} from './MenuItemsV2/maskresources/maskresources.component';
import {MasksupplierComponent} from './MenuItemsV2/masksuppliers/masksuppliers.component';
import {MasktreatmentsComponent} from './MenuItemsV2/masktreatments/masktreatments.component';
import {ReportComponent} from './MenuItemsV2/report/report.component';
import {ViewRegistersComponent} from './MenuItemsV2/viewsRegisters/viewsRegisters.component';
import {ProfileComponent} from './MenuItemsV2/profile/profile.component';
import {ProcessComponent} from './MenuItemsV2/process/process.component';
import {ViewComponent} from './MenuItemsV2/view/view.component';
import {SearchandeditComponent} from './MenuItemsV2/searchandedit/searchandedit.component';
import {DefaultHomeComponent} from './VociMenu/default-home/default-home.component';
import {ModelDetailComponent} from './VociMenu/model-detail/model-detail.component';
import {IxfeComponent} from './ixfe/ixfe.component';
import {RedirectViewComponent} from './MenuItemsV2/redirect-view/redirect-view.component';
import {RedirectFoldersComponent} from './MenuItemsV2/redirect-fodlers/redirect-folders.component';
import {ReportdetailsComponent} from './MenuItemsV2/reportdetails/reportdetails.component';
import {RedirectComponentsComponent} from './redirect-components/redirect-components.component';
import {CheckUpComponent} from './AdditionalComponents/check-up/check-up.component';
import {AppComponent} from './app.component';
import {RedirectMaskComponent} from './MenuItemsV2/redirect-mask/redirect-mask.component';
import {PowerBiComponent} from './MenuItemsV2/power-biA/power-bi.component';
import {PowerBiCTEAMComponent} from './MenuItemsV2/power-bi-cteam/power-bi-cteam.component';
import {PowerBiTecniciComponent} from './MenuItemsV2/power-bi-tecnici/power-bi-tecnici.component';
import {RelationsComponent} from './MenuItemsV2/relations/relations.component';
import {CalendarioComponent} from './MenuItemsV2/calendario/calendario.component';
import {ViewDetailsForTaskComponent} from './VociMenu/viewDetails/viewDetailsForTask';
import {CalendarioTratComponent} from './MenuItemsV2/calendario-trat/calendario-trat.component';
import {TrattativeComponent} from './MenuItemsV2/trattative/trattative.component';
import {GantateamComponent} from './MenuItemsV2/gantateam/gantateam.component';
import {CalendarioMaiaComponent} from './MenuItemsV2/calendario-maia/calendario-maia.component';
import {GoogleMapComponent} from './VociMenu/google-map/google-map.component';
import {TabellaPresenzeComponent} from './MenuItemsV2/tabella-presenze/tabella-presenze.component';
import {ProgrammaPresenzeComponent} from './VociMenu/programma-presenze/programma-presenze.component';

const routes: Routes = [
  // {path: '', redirectTo: '/login', pathMatch: 'full'}, // redirect to `first-component`
  {path: 'login', component: LoginComponent, runGuardsAndResolvers: 'always'},
  // ------ VERSIONE 2 ------
  {
    runGuardsAndResolvers: 'always',
    path: 'dashboardv2', component: DashboardV2Component,
    children: [
      {
        path: 'dashnav', component: DashnavComponent,
        children: [
          {
            path: 'desktop', component: HomeComponent,
          },
          {
            path: 'task', component: TaskComponent,
          },
          {
            path: 'viste', component: VisteComponent,
          },
          {
            path: 'fascicoli', component: FascicoliComponent,
          },
          {
            path: 'settings', component: SettingsComponent,
          },
          {
            path: 'maskagreements', component: MaskagreementsComponent,
          },
          {
            path: 'maskclients', component: MaskclientsComponent,
          },
          {
            path: 'maskdatabreach', component: MaskdatabreachComponent,
          },
          {
            path: 'maskrequests', component: MaskrequestsComponent,
          },
          {
            path: 'maskresources', component: MaskresourcesComponent,
          },
          {
            path: 'masksuppliers', component: MasksupplierComponent,
          },
          {
            path: 'masktreatments', component: MasktreatmentsComponent,
          },
          {
            path: 'report', component: ReportComponent,
          },
          {
            path: 'viewregisters', component: ViewRegistersComponent,
          },
          {
            path: 'homev2', component: HomeV2Component,
          },
          {
            path: 'maskDetails', component: MaskDetailsComponent,
          },
          {
            path: 'viewDetails', component: ViewDetailsComponent,
          },
          {
            path: 'dettaglitask', component: DettaglitaskComponent,
          },
          {
            path: 'rubrica', component: RubricaComponent,
          },
          {
            path: 'profile', component: ProfileComponent,
          },
          {
            path: 'process', component: ProcessComponent,
          },
          {
            path: 'view', component: ViewComponent,
          },
          {
            path: 'search', component: SearchandeditComponent,
          },
        ]
      },

    ]
  },
  // ------ VERSIONE STANDARD ------


  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      {
        path: 'dashnav', component: DashnavComponent,
        children: [
          {
            path: 'desktop', component: HomeComponent,
          },
          {
            path: 'rubrica', component: RubricaComponent,
          },
          {
            path: 'archiviazione', component: ArchiviazioneComponent,
          },
          {
            path: 'modeldetail', component: ModelDetailComponent,
          },
          {
            path: 'ricerca', component: RicercaComponent,
          },
          {
            path: 'presenze', component: ProgrammaPresenzeComponent,
          },
          {
            path: 'ultimaricerca', component: UltimaricercaComponent,
          },
          {
            path: 'viste', component: VisteComponent,
          },
          {
            path: 'map', component: GoogleMapComponent,
          },
          {
            path: 'maschere', component: MaschereComponent,
          },
          {
            path: 'task', component: TaskComponent,
          },
          {
            path: 'fascicoli', component: FascicoliComponent,
          },
          {
            path: 'strumenti', component: StrumentiComponent,
          },
          {
            path: 'tabella', component: TabellaPresenzeComponent,
          },
          {
            path: 'redirectview', component: RedirectViewComponent,
          },
          {
            path: 'redirectfolder', component: RedirectFoldersComponent,
          },
          {
            path: 'desktop', component: HomeComponent,
          },
          {
            path: 'task', component: TaskComponent,
          },
          {
            path: 'viste', component: VisteComponent,
          },
          {
            path: 'fascicoli', component: FascicoliComponent,
          },
          {
            path: 'fascicolo', component: FascicoliComponent,
          },
          {
            path: 'settings', component: SettingsComponent,
          },
          {
            path: 'viewTask', component: ViewDetailsForTaskComponent,
          },
          {
            path: 'maskagreements', component: MaskagreementsComponent,
          },
          {
            path: 'maskclients', component: MaskclientsComponent,
          },
          {
            path: 'ixfe', component: IxfeComponent,
          },
          {
            path: 'maskdatabreach', component: MaskdatabreachComponent,
          },
          {
            path: 'maskrequests', component: MaskrequestsComponent,
          },
          {
            path: 'maskresources', component: MaskresourcesComponent,
          },
          {
            path: 'gantateam', component: GantateamComponent,
          },
          {
            path: 'calendariotrattative', component: CalendarioTratComponent,
          },
          {
            path: 'calendarioMaia', component: CalendarioMaiaComponent,
          },
          {
            path: 'trattative', component: TrattativeComponent,
          },
          {
            path: 'relations', component: RelationsComponent,
          },
          {
            path: 'powercteam', component: PowerBiCTEAMComponent,
          },
          {
            path: 'powertecnici', component: PowerBiTecniciComponent,
          },
          {
            path: 'masksuppliers', component: MasksupplierComponent,
          },
          {
            path: 'masktreatments', component: MasktreatmentsComponent,
          },
          {
            path: 'report', component: ReportComponent,
          },
          {
            path: 'reportdetails', component: ReportdetailsComponent,
          },
          {
            path: 'viewregisters', component: ViewRegistersComponent,
          },
          {
            path: 'homev2', component: HomeV2Component,
          },
          {
            path: 'maskDetails', component: MaskDetailsComponent,
          },
          {
            path: 'viewDetails', component: ViewDetailsComponent,
          },
          {
            path: 'dettaglitask', component: DettaglitaskComponent,
          },
          {
            path: 'rubrica', component: RubricaComponent,
          },
          {
            path: 'profile', component: ProfileComponent,
          },
          {
            path: 'process', component: ProcessComponent,
          },
          {
            path: 'view', component: ViewComponent,
          },
          {
            path: 'search', component: SearchandeditComponent,
          },
          {
            path: 'gestione', component: GestioneComponent,
          },
          {
            path: 'maskDetails', component: MaskDetailsComponent,
          },
          {
            path: 'powerbi', component: PowerBiComponent,
          },
          {
            path: 'Calendario', component: CalendarioComponent,
          },
          {
            path: 'viewDetails', component: ViewDetailsComponent,
          },
          {
            path: 'dettaglirubrica', component: DettaglirubricaComponent,
          },
          {
            path: 'modelli', component: ModelliComponent,
          },
          {
            path: 'profili', component: ProfiliComponent,
          },
          {
            path: 'dettaglitask', component: DettaglitaskComponent,
          },
          {
            path: 'settings', component: SettingsComponent,
          },
          {
            path: 'home', component: HomeComponent,
          },
          {
            path: 'defaulthome', component: DefaultHomeComponent,
          },
          {
            path: 'redirect', component: RedirectComponentsComponent,
          },
          {
            path: 'redirectmask', component: RedirectMaskComponent,
          },
          {
            path: 'checkup', component: CheckUpComponent,
          },
        ]
      },

    ]
  },
  {path: '**', component: AppComponent},

  {path: 'navbar', component: NavBarComponent},

  {path: 'home', component: HomeComponent, outlet: 'choices'},

];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
