<div style="width: 100%">
    <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; height: 50px; display: flex;">
    <h2 style="text-align: center; width: 100%;">Elenco allegati({{this.attachments.length}})</h2>
</div>
<div style="text-align: center">
    <cdk-virtual-scroll-viewport itemSize="50" class="cdk" id="style-4">
        <ng-container *ngIf="this.attachments.length == 0">
            Nessun allegato presente
        </ng-container>
        <div style="text-align: left;">
            <mat-selection-list #viste [multiple]="false">
                <ng-container *ngIf="this.attachments.length != 0">
                    <div *ngFor="let attack of this.attachments" >
                        <button style="float:right; color:{{this.squareColor}};"
                                title="Elimina allegato"
                                mat-button
                                aria-label="Elimina allegato" (click) = deleteAttach(attack.id)>
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-list-item (click)=downloadDoc(attack.id,attack.originalname) style="width: 80%;">
                            <ng-container
                                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='pdf'">
                                <mat-icon>picture_as_pdf</mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='docx'">
                                <mat-icon>description</mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='doc'">
                                <mat-icon>article</mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='xlsx'">
                                <mat-icon>ballot</mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))=='csv'">
                                <mat-icon>dashboard</mat-icon>
                            </ng-container>
                            <ng-container
                                    *ngIf="(attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='docx' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='pdf' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='doc' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='xlsx' && (attack.originalname.substr(attack.originalname.indexOf('.')+1, attack.originalname.length))!='csv'">
                                {{(attack.originalname.substr(attack.originalname.indexOf('.') + 1, attack.originalname.length))}}
                            </ng-container>
                            <span style="margin-left: 10px;">{{attack.originalname}}</span>
                            <span *ngIf="attack.comment != ''" style="margin-left: 10px; color: {{this.dashColor}};">{{attack.comment}}</span>
                            <span style="margin-left: 10px; color: darkgrey;">{{this.TransformDataFormatToShow(attack.importdate)}}</span>
                        </mat-list-item>

                    </div>

                </ng-container>
            </mat-selection-list>
        </div>
    </cdk-virtual-scroll-viewport>
    <br>
    <hr>
    <br>
    <button mat-stroked-button style="margin-right: 20px" (click)="openAddExternalAttachmentDialog()">Aggiungi allegato
        esterno
    </button>
    <!-- TODO ALLEGATO INTERNO  -->
    <button mat-stroked-button [disabled]="true">Aggiungi allegato interno</button>
</div>

