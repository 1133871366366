import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../api.service';
import {MenuBodies} from '../../menuBodies';
import {DOCUMENT} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ColorEvent} from 'ngx-color';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    dati: Array<any> = new Array<any>();
    fileToBase: string;
    selectedOption: number;
    fileinserito = '';
    defaultVisibility = false;
    RubricaVisibility = false;
    ArchiviazioneVisibility = false;
    RicercaVisibility = false;
    ModelliVisibility = false;
    VisteVisibility = false;
    MaschereVisibility = false;
    TaskVisibility = false;
    FascicoliVisibility = false;
    tempJson: any;
    details: Array<any> = new Array<any>();
    isSystem = false;
    newMenu: Array<any> = new Array<any>();
    body: any;
    menuLabel: string;
    menuBody: string;
    currentOption: number;
    user = localStorage.getItem('username');

    constructor(public apiService: ApiService, private menuB: MenuBodies, @Inject(DOCUMENT) private document: Document, public dialog: MatDialog,
                private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {

        this.apiService.getLayoutMenu(localStorage.getItem('userId')).subscribe(datas => {
            this.body = datas;
            this.isSystem = datas.isSystem;
            // console.log(datas.details);
            this.details = datas.details;
            this.details.forEach(v => {
                v.active = true;
            });
            datas.details.forEach(v => {
                switch (v.elementType) {
                    case '38':
                        this.defaultVisibility = true;
                        break;
                    case '17':
                        this.RubricaVisibility = true;
                        break;
                    case '6':
                        this.ArchiviazioneVisibility = true;
                        break;
                    case '1':
                        this.RicercaVisibility = true;
                        break;
                    case '8':
                        this.ModelliVisibility = true;
                        break;
                    case '2':
                        this.VisteVisibility = true;
                        break;
                    case '7':
                        this.MaschereVisibility = true;
                        break;
                    case '41':
                        this.TaskVisibility = true;
                        break;
                    case '14':
                        this.FascicoliVisibility = true;
                        break;
                }
            });
            datas.details.forEach(v => {
                switch (v.elementType) {
                    case 'Default':
                        this.defaultVisibility = true;
                        break;
                    case 'Rubrica':
                        this.RubricaVisibility = true;
                        break;
                    case 'Archiviazione':
                        this.ArchiviazioneVisibility = true;
                        break;
                    case 'Ricerca':
                        this.RicercaVisibility = true;
                        break;
                    case 'Modelli':
                        this.ModelliVisibility = true;
                        break;
                    case 'Viste':
                        this.VisteVisibility = true;
                        break;
                    case 'Maschere':
                        this.MaschereVisibility = true;
                        break;
                    case 'Task':
                        this.TaskVisibility = true;
                        break;
                    case 'Fascicoli':
                        this.FascicoliVisibility = true;
                        break;
                }
            });
            this.tempJson = datas;
            this.tempJson.details = [];
            this.tempJson.users = [];
            // console.log(this.tempJson);
        });
        this.apiService.getSettings().subscribe(data => {
            this.dati = data;
            // console.log(data);
            this.apiService.getBetUserSetting().subscribe(s => {
                this.dati.forEach(v => {
                    if (v.id === s[0].id) {
                        this.currentOption = v.id;
                        this.selectedOption = v.id;
                    }
                });
            });

        });
    }

    // tslint:disable-next-line:typedef
    onClick() {
        this.apiService.updateBetUserSetting(this.selectedOption).subscribe(us => {

        });
        /*
        this.apiService.saveSettings(this.selectedOption).subscribe(data => {
        });*/

        this.apiService.saveLogo(this.fileToBase).subscribe(data => {
            this.document.defaultView.location.reload();
        });
    }

    saveMenu(): void {
        this.details.forEach(value => {
            if (value.active === true) {
                this.newMenu.push(value);
            }
        });
        this.body.details = this.newMenu;
        this.putMenu();
    }

    putMenu(): void {

        this.apiService.putMenu(this.body).subscribe(data => {
            this.document.defaultView.location.reload();
        });
    }

    remove(chip: any): void {
        chip.active = false;
    }


    add(chip: any): void {
        chip.active = true;
    }

    deleteTheme(): void {
        if (this.currentOption === this.selectedOption) {
            this.snackBar.open('Non è possibile eliminare il tema corrente', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
        } else {
            this.apiService.deleteSetting(this.selectedOption).subscribe(v => {
                this.ngOnInit();
                this.snackBar.open('Tema eliminato correttamente', null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-warning-bg']
                });
            });
        }

    }

    // tslint:disable-next-line:typedef
    fileChangeEvent(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = this.handleFile.bind(this);
        reader.readAsBinaryString(file);
        this.fileinserito = 'Logo inserito correttamente';
    }

    // tslint:disable-next-line:typedef
    handleFile(event) {
        const binaryString = event.target.result;
        this.fileToBase = btoa(binaryString);
        // console.log(this.fileToBase);
    }

    openAddColorDialog(): void {
        const dialogRef = this.dialog.open(AddColorComponent, {
            width: '40%',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
        });
    }

}

@Component({
    selector: 'app-addcolor',
    templateUrl: 'addColor.html',
    styleUrls: ['./settings.component.css']
})
export class AddColorComponent {
    primaryColor = '#fff';
    secondaryColor = '#fff';
    description = '';

    constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddColorComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService) {


    }

    addTheme(): void {
        if (this.description === '') {
            this.snackBar.open('Inserire una descrizione', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
        } else {
            this.apiService.addSetting(this.primaryColor, '#fafafa', this.secondaryColor, '#ffffff', this.description, 1).subscribe(res => {
                this.snackBar.open('Tema aggiunto correttamente', null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                });
                this.closeDialog();
            });
        }
    }

    handleChangePrimary(event: ColorEvent): void {
        this.primaryColor = event.color.hex;
    }

    handleChangeSecondary(event: ColorEvent): void {
        this.secondaryColor = event.color.hex;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
