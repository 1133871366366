import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-redirect-view',
  templateUrl: './redirect-view.component.html',
  styleUrls: ['./redirect-view.component.css']
})
export class RedirectViewComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  path = this.activatedRoute.snapshot.paramMap.get('path');

  constructor(private route: ActivatedRoute, private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.router.navigate(['dashboard/dashnav/viewDetails', {id: this.id, path: this.path}]);

  }

}
