<div>
  <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn"
          aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div>
  <mat-accordion *ngFor="let revision of this.revisions">
    <mat-expansion-panel hideToggle  expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Revisione n. {{revision.revision}}: {{revision.originalName}}
        </mat-panel-title>
        <mat-panel-description>
          Utente: {{revision.userDescription}} Data
          revisione: {{this.apiService.TransformDataFormatToShow(revision.profileDate)}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div style = "width: 50%; display: flex">
        <button mat-menu-item (click) = "downloadDocFromRevision(revision.revision, revision.originalName, true)"
        >
          <mat-icon>file_download</mat-icon>
          <span>Apri</span>
        </button>
        <button mat-menu-item (click) = "downloadDocFromRevision(revision.revision, revision.originalName, false)"
        >
          <mat-icon>open_in_new_off</mat-icon>
          <span>Esporta</span>
        </button>
        <button mat-menu-item (click) = "setRevision(revision.revision)"
        >
          <mat-icon>published_with_changes</mat-icon>
          <span>Revisiona</span>
        </button>
        <button mat-menu-item (click) = "deleteRevision(revision.id)"
        >
          <mat-icon>delete</mat-icon>
          <span>Elimina</span>
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #userMenu let-user>
  <section class="user-menu">
    <button mat-menu-item
    >
      <mat-icon>help</mat-icon>
      <span>Info</span>
    </button>
    <button mat-menu-item
    >
      <mat-icon>file_download</mat-icon>
      <span>Apri</span>
    </button>
    <button mat-menu-item
    >
      <mat-icon>open_in_new_off</mat-icon>
      <span>Esporta</span>
    </button>
    <button mat-menu-item
    >
      <mat-icon>published_with_changes</mat-icon>
      <span>Revisiona</span>
    </button>
    <button mat-menu-item
    >
      <mat-icon>delete</mat-icon>
      <span>Elimina</span>
    </button>
  </section>
</ng-template>
