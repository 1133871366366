import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-redirect-mask',
  templateUrl: './redirect-mask.component.html',
  styleUrls: ['./redirect-mask.component.css']
})
export class RedirectMaskComponent implements OnInit {
    id = this.activatedRoute.snapshot.paramMap.get('id');
    path = this.activatedRoute.snapshot.paramMap.get('path');

    constructor(private route: ActivatedRoute, private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.router.navigate(['dashboard/dashnav/maskDetails', {id: this.id, idTask: 0, path: this.path}]);

    }
}
