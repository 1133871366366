import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleMapComponent} from '../../VociMenu/google-map/google-map.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {parseISO, startOfDay} from 'date-fns';
import {CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EditRevisionProfileComponent} from '../view/view.component';
import {DatePipe} from '@angular/common';
import {colors} from '@angular/cli/src/utilities/color';
import {ContextMenuComponent} from 'ngx-contextmenu';
import moment from 'moment';

@Component({
  selector: 'app-tabella-presenze',
  templateUrl: './tabella-presenze.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tabella-presenze.component.css']
})

export class TabellaPresenzeComponent implements OnInit {
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  dataRifIndex: number;
  tipologiaIndex: number;
  dipendenteIndex: number;
  docnumberIndex: number;
  refresh: Subject<any> = new Subject();

  view: CalendarView = CalendarView.Month;
  bodyGetPresenze = {
    id: '52f2fdf7ef274699b914deb2f65ca1c0',
    searchFilterDto: {
      description: null,
      daAAndOr: 0,
      fields: []
    },
    selectFilterDto: {
      fields: [
        {
          fieldType: 2,
          selected: true,
          name: 'DATA144_14',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 2,
          selected: true,
          name: 'COMBO146_14',
          index: 1,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 2,
          selected: true,
          name: 'COMBO147_14',
          index: 2,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'DOCNUMBER',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'REVISIONE',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'AOO',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'ORIGINALE',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'CLASSEDOC',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'WFVERSIONSTATE',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        }
      ],
      maxItems: 0
    },
    maxItems: 0
  };

  viewDate: Date = new Date();


  locale = 'it';
  // @ts-ignore
  events: CalendarEvent[] = [];

  /*{
        start: startOfDay(new Date()),
        title: 'presenzaFull',
        cssClass: 'presenzaFull',
      },
      {
        start: startOfDay(new Date()),
        title: 'presenzaMattino',
        cssClass: 'presenzaMattino',
      },
      {
        start: startOfDay(new Date()),
        title: 'presenzaPomeriggio',
        cssClass: 'presenzaPomeriggio',
      },
      {
        start: startOfDay(new Date()),
        title: 'smartWorking',
        cssClass: 'smartWorking',
      },
      {
        start: startOfDay(new Date()),
        title: 'offWorking',
        cssClass: 'offWorking',
      }*/

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  CalendarView = CalendarView;

  dataSource: any;

  constructor(public apiService: ApiService, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute, private router: Router, public mapComponent: GoogleMapComponent, private snackBar: MatSnackBar) {

    this.apiService.getViewFilter(this.bodyGetPresenze).subscribe(result => {
      this.dataSource = result;
      result.columns.forEach((v, index) => {
        if (v.id === 'DATA144_14') {
          this.dataRifIndex = index;
        } else if (v.id === 'COMBO147_14') {
          this.dipendenteIndex = index;
        } else if (v.id === 'COMBO146_14') {
          this.tipologiaIndex = index;
        } else if (v.id === 'DOCNUMBER') {
          this.docnumberIndex = index;
        }
      });
      result.data.forEach(v => {
        switch (v[this.tipologiaIndex]) {
          case 'Giornata intera in presenza':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaFull',
              }
            );
            break;
          case 'Mattino in presenza':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaMattino',
              }
            );
            break;
          case 'Off- Ferie, Malattia, Permesso':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'offWorking',
              }
            );
            break;
          case 'Pomeriggio in presenza':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaPomeriggio',
              }
            );
            break;
          case 'Smart Working':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'smartWorking',
              }
            );
            break;
        }

      });
      this.refresh.next();
    });
  }

  cellClicked(event: any): void {
    console.log(this.dataSource);
    console.log(event);
    const tempEventsToPass = [];
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.day.date.getTime()) {

        tempEventsToPass.push({data: event.day.date, dipendente: v[this.dipendenteIndex], tipologia: v[this.tipologiaIndex]});
      }
    });
    const dialogRef = this.dialog.open(ShowEventComponent, {
      width: '20%',
      data: {events: tempEventsToPass, day: event.day.date}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reloadCalendar();
    });
  }

  reloadCalendar(): void {
    this.apiService.getViewFilter(this.bodyGetPresenze).subscribe(result => {
      this.dataSource = result;
      this.events = new Array<any>();
      result.columns.forEach((v, index) => {
        if (v.id === 'DATA144_14') {
          this.dataRifIndex = index;
        } else if (v.id === 'COMBO147_14') {
          this.dipendenteIndex = index;
        } else if (v.id === 'COMBO146_14') {
          this.tipologiaIndex = index;
        } else if (v.id === 'DOCNUMBER') {
          this.docnumberIndex = index;
        }
      });
      result.data.forEach(v => {
        switch (v[this.tipologiaIndex]) {
          case 'Giornata intera in presenza':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaFull',
              }
            );
            break;
          case 'Mattino in presenza':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaMattino',
              }
            );
            break;
          case 'Off- Ferie, Malattia, Permesso':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'offWorking',
              }
            );
            break;
          case 'Pomeriggio in presenza':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'presenzaPomeriggio',
              }
            );
            break;
          case 'Smart Working':
            this.events.push({
                start: startOfDay(parseISO(v[this.dataRifIndex])),
                title: v[this.tipologiaIndex],
                cssClass: 'smartWorking',
              }
            );
            break;
        }

      });
      this.refresh.next();
    });
  }

  addEvent(event: any, tipo: string): void {
    const json = {
      fields: [
        {
          value: this.TransformDataFormatToSave(event),
          additionalFieldType: 1,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: 0,
          validationType: 0,
          validationString: '',
          name: 'DATA144_14',
          externalId: '',
          description: 'Data di riferimento',
          order: 0,
          dataSource: null,
          required: false,
          formula: '',
          className: 'AdditionalFieldDateTimeDTO',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: [],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: null,
          visibilityCondition: '',
          mandatoryCondition: '',
          addressBookDefaultFilter: null,
          enabledAddressBook: [],
          columns: null
        },
        {
          limitToList: true,
          displayValue: null,
          value: tipo,
          numMaxChar: 100,
          numRows: 0,
          additionalFieldType: 3,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: 0,
          validationType: 0,
          validationString: '',
          name: 'COMBO146_14',
          externalId: '',
          description: 'Tipologia',
          order: 1,
          dataSource: null,
          required: false,
          formula: '',
          className: 'AdditionalFieldComboDTO',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: [],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: null,
          visibilityCondition: '',
          mandatoryCondition: '',
          addressBookDefaultFilter: null,
          enabledAddressBook: [],
          columns: null
        },
        {
          limitToList: true,
          displayValue: null,
          value: localStorage.getItem('username'),
          numMaxChar: 100,
          numRows: 0,
          additionalFieldType: 3,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: 0,
          validationType: 0,
          validationString: '',
          name: 'COMBO147_14',
          externalId: '',
          description: 'Dipendente',
          order: 2,
          dataSource: null,
          required: false,
          formula: '',
          className: 'AdditionalFieldComboDTO',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: [],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: null,
          visibilityCondition: '',
          mandatoryCondition: '',
          addressBookDefaultFilter: null,
          enabledAddressBook: [],
          columns: null
        }
      ]
    };
    let found = false;
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
        found = true;
        this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
          this.apiService.insertNewProfile(json, 'fabc8498bdf74a3396257a72f39ebc85').subscribe(res => {
            this.snackBar.open('Evento aggiunto correttamente', null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-success-bg']
            });
            this.reloadCalendar();

          }, errorLog => {
            this.snackBar.open(errorLog.error.userMessage, null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });
    if (!found) {
      this.apiService.insertNewProfile(json, 'fabc8498bdf74a3396257a72f39ebc85').subscribe(res => {
        this.snackBar.open('Evento aggiunto correttamente', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.reloadCalendar();

      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });

    }
  }

  removeEvent(event: any): void {
    console.log(this.dataSource);
    let found = false;
    this.dataSource.data.forEach(v => {
      const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      if (date1.getTime() === event.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
        found = true;
        this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
          this.snackBar.open('Evento eliminato con successo', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.reloadCalendar();
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });
    if (!found) {
      this.snackBar.open('Nessun evento da eliminare', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-warning-bg']
      });

    }
    this.refresh.next();
  }

  isEventSetForUser(date: Date): boolean {
    let found = false;
    if (this.dataSource) {
      this.dataSource.data.forEach(v => {
        const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        if (date1.getTime() === date.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
          found = true;
        }
      });
    }

    if (found) {
      return true;
    } else {
      return false;
    }
  }

  getEventType(date: Date): string {
    let found = false;
    let tipologia = '';
    if (this.dataSource) {
      this.dataSource.data.forEach(v => {
        const date1 = new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        if (date1.getTime() === date.getTime() && localStorage.getItem('username') === v[this.dipendenteIndex]) {
          found = true;
          tipologia = v[this.tipologiaIndex];

        }
      });
    }
    if (!found) {
      return '';
    } else {
      return tipologia;
    }

  }
  TransformDataFormatToSave(Data: string): string {

    if (Data === '' || !Data) {
      return '0001-01-01T00:00:00';
    }
    // // console.log(Data);
    const dateMomentObject = moment(Data, 'DD/MM/YYYY'); // 1st argument - string, 2nd argument - format
    const dateObject = dateMomentObject.toDate();
    // // console.log(dateMomentObject);
    if (dateMomentObject.isValid()) {
      return this.datePipe.transform(dateObject, 'yyyy-MM-dd', '', 'en-GB');
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

  }
  ngOnInit(): void {
    console.log(this.apiService.userInfo);
    // @ts-ignore
    /* const calendar = document.getElementById('calendario')
     console.log(calendar);*/
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    console.log(date);
    // let x=this.adminService.dateFormat(date)
    // this.openAppointmentList(x)
  }
}

@Component({
  selector: 'app-showeventdialog',
  templateUrl: 'showEventDialog.html',
  styleUrls: ['./tabella-presenze.component.css']
})
export class ShowEventComponent {
  events: Array<any> = new Array<any>();
  eventDay: any;

  constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ShowEventComponent>, private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.events = data.events;
    this.events.forEach(v => {
      switch (v.tipologia) {
        case 'Giornata intera in presenza':
          v.color = '#de7565';
          break;
        case 'Mattino in presenza':
          v.color = '#f4ccac';
          break;
        case 'Off- Ferie, Malattia, Permesso':
          v.color = '#6a6a6a';
          break;
        case 'Pomeriggio in presenza':
          v.color = '#c2cf9d';
          break;
        case 'Smart Working':
          v.color = '#b8c1df';
          break;
      }
    });
    console.log(this.events);
    this.eventDay = data.day;
  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  sendRevision(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

