<div>
    <button title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<cdk-virtual-scroll-viewport style="margin-left: 3%; margin-right: 3%" id="style-4" itemSize="6"
                             class="cdkD">
    <div>
        <mat-form-field>
            <mat-label>Cerca</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Cerca..." #input>
        </mat-form-field>
        <button title="Salva" (click)="this.saveSelectedValues()" mat-icon-button aria-label="icon-button save">
            <mat-icon>save</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="noItems == false">
        <div class="example-container mat-elevation-z8" id="style-4">

            <table mat-table [dataSource]="dataSource">

                <ng-container *ngFor="let colonna of displayedColumns" matColumnDef="{{colonna}}">
                    <ng-container *ngIf="colonna == 'select'">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                                          [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          (change)="$event ? selection.toggle(row) : null"
                                          [checked]="selection.isSelected(row)"
                                          [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="colonna != 'select'">
                        <th mat-header-cell
                            *matHeaderCellDef>{{colonna}}</th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="colonna == 'Origine'">
                                <ng-container *ngIf="element[colonna] == '0'">
                                    <mat-icon title="Uscita">upgrade</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="element[colonna] == '1'">
                                    <mat-icon title="Entrata">get_app</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="element[colonna] == '2'">
                                    <mat-icon title="Interno">swap_horizontal</mat-icon>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="colonna == 'Data documento'">
                                {{this.TransformDataFormat(element[colonna])}}
                            </ng-container>
                            <ng-container *ngIf="colonna != 'Origine' && colonna != 'Data documento'">
                                {{element[colonna]}}
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>

    </ng-container>
    <ng-container *ngIf="noItems == true">
        <h1>Nessun risultato trovato</h1>
    </ng-container>
</cdk-virtual-scroll-viewport>
