import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class MenuBodies {
    defaultMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '0',
        elementType: 38,
        favourite: false,
        id: 18,
        label: 'Default',
        layoutId: 0,
        operation: 0,
        orderIndex: 0,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    RubricaMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 17,
        favourite: false,
        id: 78,
        label: 'Rubrica',
        layoutId: 0,
        operation: 0,
        orderIndex: 1,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    TaskMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 41,
        favourite: false,
        id: 56,
        label: 'Task',
        layoutId: 0,
        operation: 0,
        orderIndex: 2,
        parentId: 0,
        translations: null

    } as unknown as JSON;
    VisteMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 2,
        favourite: false,
        id: 21,
        label: 'Viste',
        layoutId: 0,
        operation: 0,
        orderIndex: 3,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    MaschereMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 7,
        favourite: false,
        id: 23,
        label: 'Maschere',
        layoutId: 0,
        operation: 0,
        orderIndex: 4,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    RicercaMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 1,
        favourite: false,
        id: 19,
        label: 'Ricerca',
        layoutId: 0,
        operation: 0,
        orderIndex: 5,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    FascicoliMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 14,
        favourite: false,
        id: 76,
        label: 'Fascicoli',
        layoutId: 0,
        operation: 0,
        orderIndex: 6,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    ModelliMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 8,
        favourite: false,
        id: 77,
        label: 'Modelli',
        layoutId: 0,
        operation: 0,
        orderIndex: 7,
        parentId: 0,
        translations: null
    } as unknown as JSON;
    ArchiviazioneMenuBody: JSON = {
        childs: null,
        elementAction: 0,
        elementId: '',
        elementType: 6,
        favourite: false,
        id: 69,
        label: 'Archiviazione',
        layoutId: 0,
        operation: 0,
        orderIndex: 8,
        parentId: 0,
        translations: null
    } as unknown as JSON;
}
