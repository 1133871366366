<div class="center">
    <mat-card class="cardcomponentMasks" id="style-4">
        <div style="display: flex">
            <button style="float:right; color:{{this.dashColor}};" title="Profila" (click)="saveMask()" mat-icon-button
                    aria-label="icon-button send">
                <mat-icon>save</mat-icon>
            </button>
            <ng-container *ngIf="idTask != '0'">
                <button style="float:left" title="Indietro" style="color:{{this.dashColor}};" (click)="goBack()"
                        mat-icon-button
                        aria-label="icon-button send">
                    <mat-icon>keyboard_backspace</mat-icon>
                </button>
            </ng-container>
            <button style="float:right; color:{{this.dashColor}};" title="Cancella campi" (click)="cleanFields()"
                    mat-icon-button
                    aria-label="icon-button send">
                <mat-icon>cleaning_services</mat-icon>
            </button>
            <button style="float:right; color:{{this.dashColor}};" title="Desk"
                    (click)="showDesk()" mat-icon-button>
                <mat-icon>computer</mat-icon>
            </button>
            <button *ngIf="optionsMask.showNotes" style="float:right; color:{{this.dashColor}};" title="Note"
                    (click)="showNotes()" mat-icon-button>
                <mat-icon>note</mat-icon>
            </button>
            <button *ngIf="optionsMask.showAttachments" style="float:right; color:{{this.dashColor}};"
                    title="Allegati"
                    (click)="showAttachments()" mat-icon-button>
                <mat-icon>attach_file</mat-icon>
            </button>
            <button *ngIf="badgeBufferMain >0" style="float:right; color:{{this.dashColor}};"
                    [matMenuTriggerFor]="menuFile"
                    aria-label="Vedi File"
                    mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuFile="matMenu">
                <ng-container *ngFor="let file of bufferIdInfo">
                    <button mat-menu-item (click)="removeFile(file)">
                        <mat-icon>close</mat-icon>
                        <span style="font-size: 16px; font-weight: 500;">{{file.name}}</span>
                    </button>
                </ng-container>
            </mat-menu>
            <button *ngIf="optionsMask.showFileSelection" style="float:right; color:{{this.dashColor}};"
                    title="Carica file"
                    (click)="fileInput.click()"
                    mat-icon-button>
                <mat-icon [matBadge]="badgeBufferMain">cloud_upload</mat-icon>
            </button>
            <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload" name="fileUpload"
                   multiple="multiple" accept="*"/>


            <h3 class="titles" style="color:{{this.dashColor}};">
                <mat-card-title>
                    <p>
                        <ng-container *ngIf="this.idTask != '0'">
                            <b> - Profilazione in task {{this.idTask}}</b>
                        </ng-container>
                    </p>
                </mat-card-title>
            </h3>
        </div>
        <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
        <br>

        <mat-card-content>
            <ng-container [ngSwitch]="this.whatToShow">
                <ng-container *ngSwitchCase="'default'">
                    <form class="flexform" (input)="checkInputFormula()">
                        <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
                        <ng-container *ngFor="let field of fields">
                            <!--mat-grid-tile [colspan]="4"
                                           [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                                           *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
                            <ng-container [ngSwitch]="field.className"
                                          *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                                          style="height:fit-content; width: 100%;">
                                <!-- NumberFieldDTO -->
                                <mat-form-field *ngSwitchCase="'NumberFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [ngModelOptions]="{standalone: true}" [id]="field.name"
                                           [required]="field.required"
                                           [(ngModel)]="dataMaskToSave[field.name]" [disabled]="field.locked"
                                           [value]="field.value || ''"
                                           maxlength="{{field.numMaxChar || 100}}"
                                           matInput placeholder="{{field.description}}">
                                </mat-form-field>
                                <!-- DocumentDateFieldDTO -->
                                <mat-form-field *ngSwitchCase="'DocumentDateFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                                           [matDatepicker]="picker"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                                </mat-form-field>
                                <!-- SubjectFieldDTO -->
                                <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [ngModelOptions]="{standalone: true}" [value]="field.value || ''"
                                           [id]="field.name" [required]="field.required"
                                           [(ngModel)]="dataMaskToSave[field.name]" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>
                                <!-- ToFieldDTO -->
                                <mat-form-field (click)="openAddressBook(field)" *ngSwitchCase="'ToFieldDTO'"
                                                class="form-field"
                                                appearance="outline">
                                    <!--A-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <textarea class="style-4"
                                              name="{{field.name}}"
                                              [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                              maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                              style="resize: none;width:95%;"
                                              matInput [value]="this.mapAddressBookToSee.get(field.name)"
                                              placeholder="{{field.description}}"
                                    ></textarea>
                                    <button mat-button matSuffix mat-icon-button style = "color: {{this.apiService.dashColor}}">
                                            <mat-icon>notes</mat-icon>
                                        </button>
                                </mat-form-field>

                                <!-- FromFieldDTO -->
                                <mat-form-field (click)="openAddressBook(field)" *ngSwitchCase="'FromFieldDTO'"
                                                class="form-field" appearance="outline">
                                    <!--DA-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <textarea class="style-4"
                                              name="{{field.name}}"
                                              [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                              maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                              style="resize: none;width:95%;"
                                              matInput [value]="this.mapAddressBookToSee.get(field.name)"
                                              placeholder="{{field.description}}"
                                    ></textarea>
                                    <button mat-button matSuffix mat-icon-button style = "color: {{this.apiService.dashColor}}">
                                            <mat-icon>notes</mat-icon>
                                        </button>
                                </mat-form-field>

                                <!-- CcFieldDTO -->
                                <mat-form-field (click)="openAddressBook(field)" *ngSwitchCase="'CcFieldDTO'"
                                                class="form-field"
                                                appearance="outline">
                                    <!--CC-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <textarea class="style-4"
                                              name="{{field.name}}"
                                              [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                              maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                                              style="resize: none;width:95%;"
                                              matInput [value]="this.mapAddressBookToSee.get(field.name)"
                                              placeholder="{{field.description}}"
                                    ></textarea>
                                    <button mat-button matSuffix mat-icon-button style = "color: {{this.apiService.dashColor}}">
                                            <mat-icon>notes</mat-icon>
                                        </button>
                                </mat-form-field>

                                <!-- BusinessUnitFieldDTO -->
                                <mat-form-field *ngSwitchCase="'BusinessUnitFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Aoo-->
                                    <mat-label>Azienda</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked" (ngModelChange)="aooChanged()">
                                        <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                                            {{aoo.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <!-- DocumentTypeFieldDTO -->
                                <div *ngSwitchCase="'DocumentTypeFieldDTO'" class="form-field">


                                    <mat-form-field class="form-field-docType" style="width: 100%"
                                                    appearance="outline">
                                        <mat-label>{{field.description}}</mat-label>
                                        <mat-select [value]="field.value || ''" [id]="field.name"
                                                    [required]="field.required"
                                                    [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                    [disabled]="field.locked"
                                        >
                                            <mat-option
                                                    (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                                                    *ngFor="let documenType of documenTypes"
                                                    [value]="documenType.id">
                                                {{documenType.description}} - {{documenType.key}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                    <span>
                                    <button [disabled]="field.locked" matSuffix mat-icon-button
                                            style="width: 0%;display: flex;"
                                            style="color: {{this.dashColor}};float: right;"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon style="vertical-align: bottom;">more_vert</mat-icon>
                                    </button>
                                         </span>
                                </div>

                                <!-- OriginFieldDTO -->
                                <mat-form-field *ngSwitchCase="'OriginFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Origine-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked">
                                        <mat-option *ngFor="let originField of originsFields"
                                                    [value]="originField.value">
                                            {{originField.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <!-- StateFieldDTO -->
                                <mat-form-field *ngSwitchCase="'StateFieldDTO'" class="form-field" appearance="outline">
                                    <!--Stato, dipende da documento-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked">
                                        <mat-option *ngFor="let stateField of stateFields" [value]="stateField.id">
                                            {{stateField.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <!-- BinderFieldDTO -->
                                <mat-form-field *ngSwitchCase="'BinderFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Pratiche-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>
                                <mat-form-field
                                        *ngSwitchCase="'AdditionalFieldBooleanDTO'" class="form-field"
                                        appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked">
                                        <mat-option>---Cancella---</mat-option>
                                        <mat-option [value]="true">SI</mat-option>
                                        <mat-option [value]="false">NO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- DocumentDateExpiredFieldDTO -->
                                <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Scadenza-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                                           [matDatepicker]="picker"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>

                                </mat-form-field>

                                <!-- ImportantFieldDTO -->
                                <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Campo "Importante"-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>

                                <!-- SendersFieldDTO -->
                                <mat-form-field *ngSwitchCase="'SendersFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Altri da-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>

                                <!-- OriginalFieldDTO -->
                                <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Originale-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>

                                <!-- AdditionalFieldGroupDTO -->
                                <h3 *ngSwitchCase="'AdditionalFieldGroupDTO'" class="form-field"
                                    style="width: 90%!important; color: {{this.apiService.dashColor}}"
                                    appearance="outline">
                                    <!--Campo aggiuntivo "Generale"-->
                                    <br>{{field.description}}<br>
                                    <hr>
                                    <br><br>
                                </h3>

                                <!-- AdditionalFieldStringDTO -->
                                <mat-form-field *ngSwitchCase="'AdditionalFieldStringDTO'" class="form-field"
                                                appearance="outline">
                                    <!--Tipo documento-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>

                                <!-- AdditionalFieldTableDTO -->
                                <mat-form-field *ngSwitchCase="'AdditionalFieldTableDTO'" class="form-field"
                                                appearance="outline" (click)="openTableDTO(field)">
                                    <!--TableDTO-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                    <button mat-button matSuffix mat-icon-button>
                                        <mat-icon>table_chart</mat-icon>
                                    </button>
                                </mat-form-field>

                                <!-- AdditionalFieldMultivalueDTO -->
                                <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'" class="form-field"
                                >
                                    <!--MultiValueDTO-->
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-chip-list #chipList aria-label="MultiValue" [required]="field.required">
                                        <div style="width: 100%; display: flex;">
                                            <div style="width: 80%">
                                                <mat-chip
                                                        *ngFor="let element of this.mapMultiValueToSee.get(field.name)"
                                                        [selectable]="selectable"
                                                        [removable]="removable" (removed)="remove(element, field.name)">
                                                    {{element}}
                                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip>
                                            </div>
                                            <div style="width: 20%; text-align: right">
                                                <button matSuffix mat-icon-button (click)="openMultiValueDTO(field)" style = "color: {{this.apiService.dashColor}}">
                                                    <mat-icon>notes</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </mat-chip-list>
                                </mat-form-field>
                                <!--<p-multiSelect [ngModelOptions]="{standalone: true}"  placeholder = "{{field.description}}"  ngModel [options]="multiValues[field.name]"[(ngModel)]="dataMaskToSave[field.name]" display="chip" optionLabel="name"></p-multiSelect>
                                                               <span><button (click)="openMultiValueDTO(field)">apri</button></span>
                                                               <span><button (click)="datamask()">datamask</button></span>-->
                                <!-- AdditionalFieldComboDTO -->
                              <ng-container *ngSwitchCase="'AdditionalFieldComboDTO'">
                                <ng-container *ngIf="field.limitToList">
                                  <mat-form-field
                                    [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                    appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <mat-select [id]="field.name" [value]="field.value || ''"
                                                [required]="field.required" (selectionChange)="this.checkFormula()"
                                                (selectionChange)="this.checkComboAssociations(field)"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [disabled]="field.locked">
                                      <mat-option>--Cancella--</mat-option>
                                      <mat-option *ngFor="let value of this.comboValues[field.name]"
                                                  [value]="value.keyField">
                                        {{value.selectField}}
                                      </mat-option>

                                    </mat-select>

                                  </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="!field.limitToList">

                                  <mat-form-field style="width: 45%"
                                                  [ngClass]="{
                                                         'form-field': field.columns !== 1,
                                                         'form-field-expanded': field.columns === 1
                                                      }"
                                                  appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           [id]="field.name" [required]="field.required"
                                           [disabled]="field.locked" [ngModelOptions]="{standalone: true}"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                      <mat-option *ngFor="let value of this.comboValues[field.name]"
                                                  [value]="value.keyField">
                                        {{value.selectField}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngSwitchCase="'AdditionalFieldDateTimeDTO'">
                                <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                                appearance="outline"
                                >
                                  <!--AdditionalFieldDateTimeDTO-->
                                  <mat-label>{{field.description}}</mat-label>
                                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                                         [matDatepicker]="picker"
                                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                         [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                                         [disabled]="field.locked" (click)="picker.open()">
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                                </mat-form-field>
                                <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                                appearance="outline"
                                >
                                  <!--AdditionalFieldDateTimeDTO-->
                                  <mat-label>{{field.description}}</mat-label>
                                  <input style="color:#29648A;" [value]="'' || ''" matInput
                                         [matDatepicker]="picker"
                                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                         [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                                         [disabled]="field.locked" (click)="picker.open()">
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                                </mat-form-field>
                              </ng-container>
                                <!--AdditionalFieldIntDTO-->
                                <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'" class="form-field"
                                                appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [id]="field.name" type="number" [required]="field.required"
                                           [disabled]="field.locked"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>
                                <mat-form-field *ngSwitchCase="'AdditionalFieldDoubleDTO'" class="form-field"
                                                appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input ngModel [value]="field.value || ''"
                                           [id]="field.name" type="number" [required]="field.required"
                                           [disabled]="field.locked"
                                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                           maxlength="{{field.numMaxChar || 100}}" matInput
                                           placeholder="{{field.description}}">
                                </mat-form-field>

                                <!-- AdditionalFieldClasseDTO -->

                                <ng-container *ngSwitchCase="'AdditionalFieldClasseDTO'">
                                    <div class="classBox">

                                        <div>
                                            <h4 style="float:left; color: {{this.apiService.dashColor}}">{{field.description}} </h4>
                                            <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                                                    style="float:right; color:{{this.apiService.dashColor}};"
                                                    title="Vedi maschere"
                                                    (click)="openClassMasks(field)" mat-icon-button>
                                                <mat-icon [matBadge]="mapClasseBookToSave.get(field.name).length">
                                                    preview
                                                </mat-icon>
                                            </button>
                                            <button style="float:right; color:{{this.apiService.dashColor}};"
                                                    title="Inserisci maschera"
                                                    (click)="insertMaskFromClassDTO(field)" mat-icon-button>
                                                <mat-icon>post_add</mat-icon>
                                            </button>
                                            <button style="float:right; color:{{this.apiService.dashColor}};"
                                                    title="Cerca maschera"
                                                    (click)="searchMaskFromClassDTO(field)" mat-icon-button>
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                                    <mat-label>{{field.description}}</mat-label>
                                    <input matInput placeholder="{{field.description}}">
                                </mat-form-field>

                            </ng-container>

                        </ng-container>
                        <!--/mat-grid-list-->
                        <mat-card-actions>
                        </mat-card-actions>
                    </form>
                </ng-container>
                <ng-container *ngSwitchCase="'note'">
                    <div style="color: {{this.dashColor}}; text-align: left"><h1>Note: </h1>
                        <mat-form-field style="width: 90%"
                                        appearance="outline">
                            <mat-label>Aggiungi nota</mat-label>
                            <textarea [(ngModel)]="this.addNoteText" matInput
                                      placeholder="Scrivere nota qui"></textarea>
                        </mat-form-field>
                        <span><button mat-icon-button (click)="addNote()"><mat-icon>add</mat-icon></button></span></div>
                    <br>
                    <mat-selection-list #viste [multiple]="false">

                        <div *ngFor="let note of this.notes">
                            <button style="float:right; color:red;"
                                    title="Elimina nota"
                                    mat-button
                                    aria-label="Elimina allegato" (click)=deleteNote(note)>
                                <mat-icon>delete</mat-icon>
                            </button>
                            <mat-list-item style="width: 90%">
                                {{note.comment}}
                            </mat-list-item>


                        </div>
                    </mat-selection-list>
                </ng-container>
                <ng-container *ngSwitchCase="'attachments'">
                    <div style="color: {{this.dashColor}}; text-align: left"><h1>Allegati: </h1>
                        <div style="text-align: center; color: {{this.apiService.dashColor}};">
                            <div style="text-align: left; color: {{this.apiService.dashColor}};">
                                <mat-selection-list #viste [multiple]="false">
                                        <div *ngFor="let file of this.bufferIdInfo2">
                                            <button style="float:right; color:{{this.squareColor}};"
                                                    title="Elimina allegato"
                                                    mat-button
                                                    aria-label="Elimina allegato" (click)="deleteAttach(file.buffer)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                            <mat-list-item
                                                    style="width: 80%;">
                                               {{file.name}}
                                            </mat-list-item>

                                        </div>
                                </mat-selection-list>
                            </div>
                            <br>
                            <hr>
                            <div style="text-align: center; margin-top: 5%">
                                <button style="color: {{this.dashColor}};margin-right: 20px; float: right"
                                        title="Carica file"
                                        (click)="fileInput2.click()"
                                        mat-stroked-button>Aggiungi allegato
                                </button>
                                <input type="file" (change)="addAttach($event)" hidden #fileInput2
                                       id="fileUpload2" name="fileUpload2"
                                       multiple="multiple" accept="*"/>
                            </div>
                        </div>
                    </div>
                    <br>
                </ng-container>
            </ng-container>
        </mat-card-content>
    </mat-card>
</div>
