import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {ApiService} from '../api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {GoogleMapComponent} from '../VociMenu/google-map/google-map.component';
import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  Utente: string;
  dashcolor: string;
  userId: string;
  menuLayout: any;
  defaultVisibility = false;
  RubricaVisibility = false;
  ArchiviazioneVisibility = false;
  RicercaVisibility = false;
  ModelliVisibility = false;
  VisteVisibility = false;
  MaschereVisibility = false;
  TaskVisibility = false;
  img: string;
  FascicoliVisibility = false;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  opened = true;
  openedComponentName = 'Benvenuto';
  folders: Array<any> = new Array<any>();
  user = localStorage.getItem('username');
  firstMenuElemet: any;


  constructor(public apiService: ApiService, private route: ActivatedRoute, private router: Router, public mapComponent: GoogleMapComponent, private snackBar: MatSnackBar,
              iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'RubricaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/contacts-24px.svg'));
    iconRegistry.addSvgIcon(
      'DesktopIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/house-24px.svg'));
    iconRegistry.addSvgIcon(
      'ArchiviazioneIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/archive-24px.svg'));
    iconRegistry.addSvgIcon(
      'RicercaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/search-24px.svg'));
    iconRegistry.addSvgIcon(
      'UltimaRicercaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/history-24px.svg'));
    iconRegistry.addSvgIcon(
      'VisteIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/preview-24px.svg'));
    iconRegistry.addSvgIcon(
      'MaschereIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tab-24px.svg'));
    iconRegistry.addSvgIcon(
      'TaskIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/add_task-24px.svg'));
    iconRegistry.addSvgIcon(
      'FascicoliIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/folder-24px.svg'));
    iconRegistry.addSvgIcon(
      'StrumentiIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/architecture-24px.svg'));
    iconRegistry.addSvgIcon(
      'GestioneIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/settings-24px.svg'));
    iconRegistry.addSvgIcon(
      'ModelliIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/widgets-24px.svg'));
    iconRegistry.addSvgIcon(
      'ProfiliIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/account_box-24px.svg'));
    iconRegistry.addSvgIcon(
      'SettingsIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/settings-24px.svg'));
  }

  showFiller = false;
  formatLabel(value: number): any {
    return value + 'Km';
  }
  goTo(menuItem: any): void {

    switch (menuItem.elementType) {
      case 38:
        this.desktopClick(menuItem.label, -1);
        break;
      case 17:
        this.rubricaClick(menuItem.label);
        break;
      case 6:
        this.archiviazioneClick(menuItem.label);
        break;
      case 1:
        this.ricercaClick(menuItem.label);
        break;
      case 8:
        this.modelliClick(menuItem.label);
        break;
      case 2:
        this.visteClick(menuItem.label);
        break;
      case 7:
        this.maschereClick(menuItem.label);
        break;
      case 41:
        this.taskClick(menuItem.label);
        break;
      case 14:
        this.fascicoliClick(menuItem.label);
        break;
      case 28:
        this.goToView(menuItem.elementId, menuItem.label);
        break;
      case 32:
        this.openFoldersId(menuItem.elementId, menuItem.label);
        break;
      case 49:
        this.openReportList(menuItem.label);
        break;
      case 27:
        this.goToMask(menuItem.elementId, menuItem.label);
        break;
      case 39:
        this.powerClick(menuItem.label, menuItem.elementId);
        break;
      default:
        this.desktopClick('default', -1);
    }
  }

  ngOnInit(): void {
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.apiService.getTokenArxAssistant().subscribe(res => {
      console.log(res);
    });
    this.apiService.getStates().subscribe(states => {
      this.apiService.allStates = states;
    });
    this.apiService.checkInOut().subscribe(res => {
      console.log(res);
    });
    this.apiService.getLogo().subscribe(data => {
        this.img = 'data:image/png;base64,' + data[0].logo;
      }
    );
    this.router.onSameUrlNavigation = 'reload';
    this.apiService.getUserInfo().subscribe(data => {
      this.userId = data.user;
      localStorage.setItem('userId', this.userId);
      this.apiService.getLayoutMenu(this.userId).subscribe(datas => {
        let i = 0;
        while (datas.details[i].elementType === 34) {
          i++;
        }
        this.firstMenuElemet = datas.details[i];
        this.folders = new Array<any>();
        this.menuLayout = datas.details;
        if (!this.apiService.isFornitore && localStorage.getItem('username') !== 'fornitoreService'){
          this.openPresenzeTable();
        }
        if (localStorage.getItem('username') === 'fornitoreService' && !this.apiService.isFornitore){
          this.router.navigate(['login']);
        }

        datas.details.forEach(v => {
          if (v.elementType === 34) {
            this.folders.push({id: v.id, enabled: false});
            if (v.childs){
              this.addChildFolder(v.childs);
            }
          }
        });
        console.log(this.folders);
      });
      this.apiService.getLayoutCommands(this.userId).subscribe(datas => {
        this.apiService.commandFolders = new Array<any>();
        this.apiService.profileCommands = datas;
        datas.details.forEach(v => {
          if (v.elementType === 70) {
            this.apiService.commandFolders.push({id: v.id, enabled: false});
          }
        });
      });
    });

    this.apiService.getTheme().subscribe(data => {
      this.apiService.getUserInfo().subscribe(info => {
        this.apiService.userInfo = info;
        this.apiService.getBetUserSetting().subscribe(currentSetting => {
          if (currentSetting.error) {
            if (currentSetting.error === 'No user settings') {
              this.dashColor = data[0].dash_color;
              this.backColor = data[0].back_color;
              this.squareColor = data[0].square_color;
              this.cardColor = data[0].card_color;
              this.apiService.dashColor = this.dashColor;
              this.apiService.backColor = this.backColor;
              this.apiService.squareColor = this.squareColor;
              this.apiService.cardColor = this.cardColor;
              this.apiService.setBetUserSetting(data[0].id).subscribe(us => {

              });
            } else {
              this.apiService.updateBetUserSetting(data[0].id).subscribe(us => {
                this.dashColor = data[0].dash_color;
                this.backColor = data[0].back_color;
                this.squareColor = data[0].square_color;
                this.cardColor = data[0].card_color;
                this.apiService.dashColor = this.dashColor;
                this.apiService.backColor = this.backColor;
                this.apiService.squareColor = this.squareColor;
                this.apiService.cardColor = this.cardColor;
              });
            }
          } else {
            this.dashColor = currentSetting[0].dash_color;
            this.backColor = currentSetting[0].back_color;
            this.squareColor = currentSetting[0].square_color;
            this.cardColor = currentSetting[0].card_color;
            this.apiService.dashColor = this.dashColor;
            this.apiService.backColor = this.backColor;
            this.apiService.squareColor = this.squareColor;
            this.apiService.cardColor = this.cardColor;
          }
        });
      });

    });

    this.Utente = localStorage.getItem('username');
    if (!this.apiService.isLoggedIn()) {
      this.router.navigate(['login']);
    }

  }
  addChildFolder(child: any): void{
    child.forEach(v => {
      if (v.elementType === 34) {
        this.folders.push({id: v.id, enabled: false});
        if (v.childs){
          this.addChildFolder(v.childs);
        }
      }
    });
  }
  enableFolder(id): void {
    this.folders.forEach(fol => {
      if (fol.id === id) {
        if (fol.enabled) {
          fol.enabled = false;
        } else {
          fol.enabled = true;
        }
      }
    });
  }

  pathClick(path: string, params: any): void {
    this.apiService.isMenuClick = false;
    this.apiService.namePathV2.forEach((v, index) => {
      if (v.route === path && v.params === params) {
        v.active = true;
        this.apiService.namePathV2.push(v);
        this.apiService.namePathV2.splice(index, 1);
      } else {
        v.active = false;
      }
    });
    this.apiService.tempParamsForRedirect = {destination: path, parameters: params};
    this.router.navigate(['dashnav/redirect', {current: this.router.url}], {relativeTo: this.route});
  }
  startWorkflow(id: string): void{
      this.apiService.startWorkflowByEvent(id).subscribe(res => {
        this.snackBar.open('Workflow avviato con successo', null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
  }
  desktopClick(label: string, id: number): void {
    if (this.apiService.BASE_URL === 'http://next.betsoft-srl.it//arxivarnextwebapi/api/') {
      switch (id) {
        case 1171:
          window.open('http://next.betsoft-srl.it/ARXivarNextWebPortal/#!/chat/', '_blank');
          break;
        case 716:
          window.open('http://next.betsoft-srl.it/ARXivarNextWebPortal/#!/desktop/5', '_blank');
          break;
        case 826:
          window.open('http://next.betsoft-srl.it/ARXivarNextWebPortal/#!/desktop/6', '_blank');
          break;
        case 827:
          window.open('http://next.betsoft-srl.it/ARXivarNextWebPortal/#!/desktop/7', '_blank');
          break;
        case 828:
          window.open('http://next.betsoft-srl.it/ARXivarNextWebPortal/#!/desktop/8', '_blank');
          break;
        case 832:
          window.open('http://next.betsoft-srl.it/ARXivarNextWebPortal/#!/desktop/9', '_blank');
          break;
        default:
          this.apiService.isMenuClick = true;
          this.openedComponentName = 'Benvenuto';
          this.router.navigate(['dashnav/defaulthome', {lbl: label}], {relativeTo: this.route});
      }
    } else {
      this.apiService.isMenuClick = true;
      this.openedComponentName = 'Benvenuto';
      this.router.navigate(['dashnav/defaulthome', {lbl: label}], {relativeTo: this.route});
    }

  }

  powerClick(label: string, id: string): void {
    switch (id) {
      case 'eece3568-497e-4829-9000-5f3b27187b98':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powerbi', {});
        this.router.navigate(['dashnav/powerbi', {lbl: label}], {relativeTo: this.route});
        break;
      case '2461d979-49eb-44fe-bc40-1c09a442be02':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powercteam', {});
        this.router.navigate(['dashnav/powercteam', {lbl: label}], {relativeTo: this.route});
        break;
      case 'c34ad36a-337b-4693-9315-454378b56686':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/Calendario', {});
        this.router.navigate(['dashnav/Calendario', {lbl: label}], {relativeTo: this.route});
        break;
      case 'e3d0a787-da2b-4346-8ae9-c5fd30a17a4b':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powertecnici', {});
        this.router.navigate(['dashnav/powertecnici', {lbl: label}], {relativeTo: this.route});
        break;
      case '4db2ff39-0594-4798-9d03-0f350182f98f':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powerbi', {});
        this.router.navigate(['dashnav/powerbi', {lbl: label}], {relativeTo: this.route});
        break;
      case '511435ca-c7b4-4961-b739-85c876812c66':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powercteam', {});
        this.router.navigate(['dashnav/powercteam', {lbl: label}], {relativeTo: this.route});
        break;
      case '75b23143-85ac-42a1-a4ce-a653c75cef5f':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powertecnici', {});
        this.router.navigate(['dashnav/powertecnici', {lbl: label}], {relativeTo: this.route});
        break;
      case 'f45s2r2f-23rt-5g43-a476-et9h49329846':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/powerbi', {});
        this.router.navigate(['dashnav/powerbi', {lbl: label}], {relativeTo: this.route});
        break;
      case '0b4cbef5-af41-4b36-8867-47e79a6ff825':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/calendariotrattative', {});
        this.router.navigate(['dashnav/calendariotrattative', {lbl: label}], {relativeTo: this.route});
        break;
      case 'c8a83e6e-cc3f-4be4-b0be-24880f0fff03':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/gantateam', {});
        this.router.navigate(['dashnav/gantateam', {lbl: label}], {relativeTo: this.route});
        break;
      case '91021f92-fa5e-45d7-83e1-3d0dfbc75653':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/trattative', {});
        this.router.navigate(['dashnav/trattative', {lbl: label}], {relativeTo: this.route});
        break;
      case 'cee8113d-f3ee-4f98-842f-94d024362d99':
        this.apiService.isMenuClick = true;
        this.apiService.addPath(label, 'dashboard/dashnav/calendarioMaia', {});
        this.router.navigate(['dashnav/calendarioMaia', {lbl: label}], {relativeTo: this.route});
        break;
    }
  }
  openPresenzeTable(): void{
    this.apiService.isMenuClick = true;
    this.apiService.addPath('Programma presenze', 'dashboard/dashnav/presenze', {});
    this.router.navigate(['dashnav/presenze', {lbl: 'Programma presenze'}], {relativeTo: this.route});
  }
  openReportList(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/report', {});
    this.router.navigate(['dashnav/report', {lbl: label}], {relativeTo: this.route});
  }

  rubricaClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/rubrica', {});
    this.router.navigate(['dashnav/rubrica', {lbl: label}], {relativeTo: this.route});
  }

  archiviazioneClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/archiviazione', {idTask: '0'});
    this.router.navigate(['dashnav/archiviazione', {idTask: '0', lbl: label}], {relativeTo: this.route});
  }

  ricercaClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/ricerca', {});
    this.router.navigate(['dashnav/ricerca', {lbl: label}], {relativeTo: this.route});
  }

  ultimaricercaClick(): void {
    this.apiService.isMenuClick = true;
    this.router.navigate(['dashnav/ultimaricerca'], {relativeTo: this.route});
  }

  modelliClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/modelli', {});
    this.router.navigate(['dashnav/modelli', {lbl: label}], {relativeTo: this.route});
  }

  visteClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/viste', {id: '0'});
    this.router.navigate(['dashnav/viste', {id: '0', lbl: label}], {relativeTo: this.route});
  }

  maschereClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/maschere', {id: '0'});
    this.router.navigate(['dashnav/maschere', {id: '0', lbl: label}], {relativeTo: this.route});
  }

  taskClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/task', {});
    this.router.navigate(['dashnav/task', {lbl: label}], {relativeTo: this.route});
  }

  fascicoliClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/redirectfolder', {});
    this.router.navigate(['dashnav/redirectfolder', {lbl: label}], {relativeTo: this.route});
  }

  goToView(viewId: string, label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/redirectview', {id: viewId, path: 'defaulthome'});
    this.router.navigate(['dashnav/redirectview', {id: viewId, path: 'defaulthome', lbl: label}], {relativeTo: this.route});
  }

  goToMask(maskId: string, label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/redirectmask', {id: maskId, path: 'defaulthome'});
    this.router.navigate(['dashnav/redirectmask', {id: maskId, path: 'defaulthome', lbl: label}], {relativeTo: this.route});
  }

  openFoldersId(node: string, label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.getFolder(node).subscribe(v => {
      this.apiService.parentFolderId = v.parentId;
      this.apiService.addPath(label, 'dashboard/dashnav/redirectfolder', {openId: v.parentId});
      this.router.navigate(['dashnav/redirectfolder', {openId: v.parentId, lbl: label}], {relativeTo: this.route});
    });

  }

  strumentiClick(): void {
    this.router.navigate(['dashnav/strumenti'], {relativeTo: this.route});
  }

  gestioneClick(): void {
    this.router.navigate(['dashnav/gestione'], {relativeTo: this.route});
  }

  profiliClick(): void {
    this.router.navigate(['dashnav/profili'], {relativeTo: this.route});
  }

  settingsClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/settings', {});
    this.router.navigate(['dashnav/settings', {lbl: label}], {relativeTo: this.route});
  }
  rangeChanged(): void{

    this.apiService.reloadRange = true;
  }
  mapClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/map', {});
    this.router.navigate(['dashnav/map', {lbl: label}], {relativeTo: this.route});
  }

  checkUpClick(label: string): void {
    this.apiService.isMenuClick = true;
    this.apiService.addPath(label, 'dashboard/dashnav/checkup', {});
    this.router.navigate(['dashnav/checkup', {lbl: label}], {relativeTo: this.route});
  }

  openedChange(): void {
    if (this.opened === true) {
      this.opened = false;
    } else {
      this.opened = true;
    }
  }
  remove(cat: any): void{
    const index = this.apiService.categories.indexOf(cat.name);

    if (index >= 0) {
      this.apiService.categories.splice(index, 1);
    }
  }
}
