import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class BodyFieldToSave {
    generateStringfield(field: any, fieldValue: any): any {
        const bodyField = {
            name: '',
            value: '',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: '',
            locked: true,
            comboGruppiId: 'string',
            displayValue: 'string',
            dependencyFields: [],
            associations: [],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: field.addressBookDefaultFilter,
            enabledAddressBook: [],
            additionalFieldType: field.additionalFieldType,
            binderFieldId: field.binderFieldId,
            columns: field.columns,
            focus: field.focus,
            groupId: field.groupId,
            numMaxChar: field.numMaxChar,
            numRows: field.numRows,
            taskWorkVariableId: field.taskWorkVariableId,
            validationString: field.validationString,
            validationType: field.validationType,

        };
        bodyField.name = field.name;
        bodyField.value = fieldValue;
        bodyField.externalId = field.externalId;
        bodyField.description = field.description;
        bodyField.order = field.order;
        bodyField.dataSource = field.dataSource;
        bodyField.required = field.required;
        bodyField.formula = field.formula;
        bodyField.className = field.className;
        bodyField.locked = field.locked;
        bodyField.comboGruppiId = field.comboGruppiId;
        bodyField.dependencyFields = field.dependencyFields;
        bodyField.associations = field.associations;
        bodyField.isAdditional = field.isAdditional;
        bodyField.visible = field.visible;
        bodyField.predefinedProfileFormula = field.predefinedProfileFormula;
        bodyField.visibilityCondition = field.visibilityCondition;
        bodyField.addressBookDefaultFilter = field.addressBookDefaultFilter;
        bodyField.enabledAddressBook = field.enabledAddressBook;
        bodyField.columns = field.columns;
        bodyField.displayValue = field.displayValue;
        return bodyField;
    }

    generateDefaultFieldBody(field: any, fieldValue: any): any {
        // // console.log(field);
        const bodyField = {
            name: '',
            value: '',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: '',
            locked: true,
            comboGruppiId: 'string',
            displayValue: 'string',
            dependencyFields: [],
            associations: [],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [],
            columns: 0
        };
        bodyField.name = field.name;
        bodyField.value = fieldValue;
        bodyField.externalId = field.externalId;
        bodyField.description = field.description;
        bodyField.order = field.order;
        bodyField.dataSource = field.dataSource;
        bodyField.required = field.required;
        bodyField.formula = field.formula;
        bodyField.className = field.className;
        bodyField.locked = field.locked;
        bodyField.comboGruppiId = field.comboGruppiId;
        bodyField.dependencyFields = field.dependencyFields;
        bodyField.associations = field.associations;
        bodyField.isAdditional = field.isAdditional;
        bodyField.visible = field.visible;
        bodyField.predefinedProfileFormula = field.predefinedProfileFormula;
        bodyField.visibilityCondition = field.visibilityCondition;
        bodyField.addressBookDefaultFilter = field.addressBookDefaultFilter;
        bodyField.enabledAddressBook = field.enabledAddressBook;
        bodyField.columns = field.columns;
        bodyField.displayValue = field.displayValue;
        return bodyField;
    }

    generateMultiFieldBody(field: any, fieldValue: any[]): any {
        const fieldValueString = [];
        if (fieldValue !== undefined) {
            fieldValue.forEach(item => {
                fieldValueString.push(item[0].value);
            });
        }
        const bodyField = {
            name: '',
            value: [],
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: '',
            focus: true,
            locked: true,
            comboGruppiId: 'string',
            displayValue: 'string',
            dependencyFields: [],
            associations: [],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [],
            columns: 0
        };
        bodyField.name = field.name;
        bodyField.value = fieldValueString;
        bodyField.externalId = field.externalId;
        bodyField.description = field.description;
        bodyField.order = field.order;
        bodyField.dataSource = field.dataSource;
        bodyField.required = field.required;
        bodyField.formula = field.formula;
        bodyField.className = field.className;
        bodyField.locked = field.locked;
        bodyField.comboGruppiId = field.comboGruppiId;
        bodyField.dependencyFields = field.dependencyFields;
        bodyField.associations = field.associations;
        bodyField.isAdditional = field.isAdditional;
        bodyField.visible = field.visible;
        bodyField.predefinedProfileFormula = field.predefinedProfileFormula;
        bodyField.visibilityCondition = field.visibilityCondition;
        bodyField.addressBookDefaultFilter = field.addressBookDefaultFilter;
        bodyField.enabledAddressBook = field.enabledAddressBook;
        bodyField.columns = field.columns;
        bodyField.displayValue = field.displayValue;
        return bodyField;
    }

    generateComboFieldBody(field: any): any {

        const bodyField = {
            name: '',
            value: '',
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: '',
            locked: true,
            comboGruppiId: 'string',
            displayValue: 'string',
            dependencyFields: [],
            associations: [],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: null,
            additionalFieldType: field.additionalFieldType,
            enabledAddressBook: [],
            binderFieldId: field.binderFieldId,
            columns: field.columns,
            groupId: field.groupId,
            limitToList: field.limitToList,
            numMaxChar: field.numMaxChar,
            numRows: field.numRows,
            taskWorkVariableId: field.taskWorkVariableId,
            validationString: field.validationString,
            validationType: field.validationType
        };
        bodyField.name = field.name;
        bodyField.value = field.value;
        bodyField.externalId = field.externalId;
        bodyField.description = field.description;
        bodyField.order = field.order;
        bodyField.dataSource = field.dataSource;
        bodyField.required = field.required;
        bodyField.formula = field.formula;
        bodyField.className = field.className;
        bodyField.locked = field.locked;
        bodyField.comboGruppiId = field.comboGruppiId;
        bodyField.dependencyFields = field.dependencyFields;
        bodyField.associations = field.associations;
        bodyField.isAdditional = field.isAdditional;
        bodyField.visible = field.visible;
        bodyField.predefinedProfileFormula = field.predefinedProfileFormula;
        bodyField.visibilityCondition = field.visibilityCondition;
        bodyField.addressBookDefaultFilter = field.addressBookDefaultFilter;
        bodyField.enabledAddressBook = field.enabledAddressBook;
        bodyField.columns = field.columns;
        bodyField.displayValue = field.displayValue;
        return bodyField;
    }

    generateClassFieldBody(field: any, value: Array<string>): any {
        const bodyField = {
            name: '',
            value: [],
            externalId: 'string',
            description: 'string',
            order: 0,
            dataSource: 'string',
            required: true,
            formula: 'string',
            className: '',
            locked: true,
            displayValue: 'string',
            focus: true,
            comboGruppiId: 'string',
            dependencyFields: [],
            associations: [],
            isAdditional: true,
            visible: true,
            predefinedProfileFormula: 'string',
            visibilityCondition: 'string',
            addressBookDefaultFilter: 0,
            enabledAddressBook: [],
            columns: 0
        };
        bodyField.displayValue = field.displayValue;
        bodyField.name = field.name;
        bodyField.value = value;
        bodyField.externalId = field.externalId;
        bodyField.description = field.description;
        bodyField.order = field.order;
        bodyField.dataSource = field.dataSource;
        bodyField.required = field.required;
        bodyField.formula = field.formula;
        bodyField.className = field.className;
        bodyField.locked = field.locked;
        bodyField.comboGruppiId = field.comboGruppiId;
        bodyField.dependencyFields = field.dependencyFields;
        bodyField.associations = field.associations;
        bodyField.isAdditional = field.isAdditional;
        bodyField.visible = field.visible;
        bodyField.predefinedProfileFormula = field.predefinedProfileFormula;
        bodyField.visibilityCondition = field.visibilityCondition;
        bodyField.addressBookDefaultFilter = field.addressBookDefaultFilter;
        bodyField.enabledAddressBook = field.enabledAddressBook;
        bodyField.columns = field.columns;
        return bodyField;
    }
}
