import {AfterViewInit, Component, Inject, Injectable, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DatePipe} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {CollectionViewer, SelectionChange} from '@angular/cdk/collections';
import {map} from 'rxjs/operators';


@Component({
    selector: 'app-dialogdocumentalclassdto',
    styleUrls: ['documentalClass-css.css'],
    templateUrl: 'dialog-content-documentalClass.html'
})
export class DialogDocumentalClassComponent {
    docTypes: Array<any> = new Array<any>();

    constructor(public dialogRef: MatDialogRef<DialogDocumentalClassComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router,
                public dialog: MatDialog, database: DynamicDatabase) {
        // // console.log(this.apiService.classDocMode);
        if (this.apiService.classDocAoo !== undefined) {
            this.apiService.getDocTypeModeWithAoo(this.apiService.classDocMode, this.apiService.classDocAoo).subscribe(dat => {
                this.docTypes = dat;
            });
        } else {
            this.apiService.getAllDocTypes().subscribe(dat => {
                this.docTypes = dat;
            });
        }

        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
        this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new DynamicDataSource(this.treeControl, database);
        this.dataSource.data = database.initialData();
        database.start();
    }

    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;
    treeControl: FlatTreeControl<DynamicFlatNode>;

    dataSource: DynamicDataSource;

    docClassClick(id: number): void {
        let type, tipo1, tipo2;
        this.docTypes.forEach(v => {
            // // console.log(v.id);
            // // console.log(id);
            if (v.id.toString() === id) {
                type = v.documentType;
                tipo1 = v.type2;
                tipo2 = v.type3;
                if (tipo1 === undefined) {
                    tipo1 = '0';
                }
                if (tipo2 === undefined) {
                    tipo2 = '0';
                }
                if (type === undefined) {
                    type = '0';
                }
                this.dialogRef.close({docClassId: id, docType: type, type1: tipo1, type2: tipo2});
            }
        });

    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getLevel = (node: DynamicFlatNode) => node.level;

    isExpandable = (node: DynamicFlatNode) => node.expandable;

    // tslint:disable-next-line:variable-name
    hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

}

export class DynamicFlatNode {
    constructor(public item: string, public level = 1, public expandable = false,
                public isLoading = false) {
    }
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({providedIn: 'root'})
export class DynamicDatabase {
    documentTypes: Array<any> = new Array<any>();
    dataMap: Map<string, string[]> = new Map<string, string[]>();
    rootLevelNodes: string[];
    dataToInsert: Array<any> = new Array<any>();

    constructor(private datePipe: DatePipe, private route: ActivatedRoute,
                private router: Router, iconRegistry: MatIconRegistry,
                sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) {
        this.start();
    }

    start(): void {
        this.rootLevelNodes = ['[ROOT] - Elenco classi documentali'];
        /** Initial data from database */
        if (this.apiService.classDocAoo !== undefined) {
            this.apiService.getDocTypeModeWithAoo(this.apiService.classDocMode, this.apiService.classDocAoo).subscribe(dat => {
                this.dataToInsert = new Array<any>();
                this.dataMap = new Map<string, string[]>();
                this.documentTypes = dat;
                dat.forEach(val => {
                    if (val.idParent === 0) {
                        this.dataToInsert.push(val.key + ' - ' + val.description + '#@' + val.id);
                        if (val.isLeaf === false) {
                            this.fillChilds(val.id, val.key + ' - ' + val.description + '#@' + val.id);
                        }
                    }

                });
                this.dataToInsert.sort((a, b) => a.localeCompare(b));
                // // console.log(this.dataToInsert);
                this.dataMap.set('[ROOT] - Elenco classi documentali', this.dataToInsert);
                // // console.log(this.dataMap);
            });
        } else {
            this.apiService.getAllDocTypes().subscribe(dat => {
                this.dataToInsert = new Array<any>();
                this.dataMap = new Map<string, string[]>();
                this.documentTypes = dat;
                dat.forEach(val => {
                    if (val.idParent === 0) {
                        this.dataToInsert.push(val.key + ' - ' + val.description + '#@' + val.id);
                        if (val.isLeaf === false) {
                            this.fillChilds(val.id, val.key + ' - ' + val.description + '#@' + val.id);
                        }
                    }

                });
                this.dataToInsert.sort((a, b) => a.localeCompare(b));
                // // console.log(this.dataToInsert);
                this.dataMap.set('[ROOT] - Elenco classi documentali', this.dataToInsert);
                // // console.log(this.dataMap);
            });
        }

    }

    fillChilds(id: string, name: string): any {
        const dataToInsertChild = new Array<any>();
        this.documentTypes.forEach(val => {
            if (val.idParent === id) {
                dataToInsertChild.push(val.key + ' - ' + val.description + '#@' + val.id);
                if (val.isLeaf === false) {
                    this.fillChilds(val.id, val.key + ' - ' + val.description + '#@' + val.id);
                }
            }
        });
        dataToInsertChild.sort((a, b) => a.localeCompare(b));
        this.dataMap.set(name, dataToInsertChild);
    }

    /** Initial data from database */
    initialData(): DynamicFlatNode[] {
        return this.rootLevelNodes.map(name => new DynamicFlatNode(name, 0, true));
    }

    getChildren(node: string): string[] | undefined {
        return this.dataMap.get(node);
    }

    isExpandable(node: string): boolean {
        return this.dataMap.has(node);
    }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {

    dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

    get data(): DynamicFlatNode[] {
        return this.dataChange.value;
    }

    set data(value: DynamicFlatNode[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    // tslint:disable-next-line:variable-name
    constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
                // tslint:disable-next-line:variable-name
                private _database: DynamicDatabase) {
    }

    connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
        this._treeControl.expansionModel.changed.subscribe(change => {
            if ((change as SelectionChange<DynamicFlatNode>).added ||
                (change as SelectionChange<DynamicFlatNode>).removed) {
                this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    disconnect(collectionViewer: CollectionViewer): void {
    }

    /** Handle expand/collapse behaviors */
    // tslint:disable-next-line:typedef
    handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
        if (change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
        }
    }

    /**
     * Toggle the node, remove from display list
     */


    // tslint:disable-next-line:typedef
    toggleNode(node: DynamicFlatNode, expand: boolean) {
        const children = this._database.getChildren(node.item);
        const index = this.data.indexOf(node);
        if (!children || index < 0) { // If no children, or cannot find the node, no op
            return;
        }

        node.isLoading = true;

        setTimeout(() => {
            if (expand) {
                const nodes = children.map(name =>
                    new DynamicFlatNode(name, node.level + 1, this._database.isExpandable(name)));
                this.data.splice(index + 1, 0, ...nodes);
            } else {
                let count = 0;
                for (let i = index + 1; i < this.data.length
                && this.data[i].level > node.level; i++, count++) {
                }
                this.data.splice(index + 1, count);
            }

            // notify the change
            this.dataChange.next(this.data);
            node.isLoading = false;
        }, 0);
    }
}
