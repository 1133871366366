import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class AddressBookField {
    addressBookForSearchFilter = {
      addressBookCategoryId: [
            -3
      ],
      filterFields: [
        {
          label: 'Ragione sociale',
          keyField: 'Dm_Rubrica.RAGIONE_SOCIALE',
          selected: true,
          index: 0
        },
        {
          label: 'Codice',
          keyField: 'Dm_Rubrica.CODICE',
          selected: true,
          index: 0
        },
        {
          label: 'Indirizzo',
          keyField: 'Dm_Rubrica.INDIRIZZO',
          selected: false,
          index: 0
        },
        {
          label: 'Località',
          keyField: 'Dm_Rubrica.LOCALITA',
          selected: false,
          index: 0
        },
        {
          label: 'Cap',
          keyField: 'Dm_Rubrica.CAP',
          selected: false,
          index: 0
        },
        {
          label: 'Provincia',
          keyField: 'Dm_Rubrica.PROVINCIA',
          selected: false,
          index: 0
        },
        {
          label: 'Nazione',
          keyField: 'Dm_Rubrica.NAZIONE',
          selected: false,
          index: 0
        },
        {
          label: 'E-Mail',
          keyField: 'Dm_Rubrica.MAIL',
          selected: false,
          index: 0
        },
        {
          label: 'Fax',
          keyField: 'Dm_Rubrica.FAX',
          selected: false,
          index: 0
        },
        {
          label: 'Telefono',
          keyField: 'Dm_Rubrica.TEL',
          selected: false,
          index: 0
        },
        {
          label: 'Codice fiscale',
          keyField: 'Dm_Rubrica.CODFIS',
          selected: false,
          index: 0
        },
        {
          label: 'Partita Iva',
          keyField: 'Dm_Rubrica.PARTIVA',
          selected: false,
          index: 0
        },
        {
          label: 'Nominativo (Contatto)',
          keyField: 'CONTATTO',
          selected: false,
          index: 0
        },
        {
          label: 'Mansione (Contatto)',
          keyField: 'MANSIONE',
          selected: false,
          index: 0
        },
        {
          label: 'Telefono (Contatto)',
          keyField: 'TEL',
          selected: false,
          index: 0
        },
        {
          label: 'Cellulare (Contatto)',
          keyField: 'CELL',
          selected: false,
          index: 0
        },
        {
          label: 'Fax (Contatto)',
          keyField: 'FAX',
          selected: false,
          index: 0
        },
        {
          label: 'Abitazione (Contatto)',
          keyField: 'ABITAZIONE',
          selected: false,
          index: 0
        },
        {
          label: 'Reparto (Contatto)',
          keyField: 'REPARTO',
          selected: false,
          index: 0
        },
        {
          label: 'Ufficio (Contatto)',
          keyField: 'UFFICIO',
          selected: false,
          index: 0
        },
        {
          label: 'E-Mail (Contatto)',
          keyField: 'EMAIL',
          selected: false,
          index: 0
        },
        {
          label: 'Nome completo',
          keyField: 'Dm_Rubrica.UCONTATTI',
          selected: false,
          index: 0
        },
        {
          label: 'Abilitato FEA (Contatto)',
          keyField: 'FEAENABLED',
          selected: false,
          index: 0
        },
        {
          label: 'Scadenza FEA (Contatto)',
          keyField: 'FEAEXPIREDATE',
          selected: false,
          index: 0
        },
        {
          label: 'Nome (Contatto)',
          keyField: 'NOME',
          selected: false,
          index: 0
        },
        {
          label: 'Cognome (Contatto)',
          keyField: 'COGNOME',
          selected: false,
          index: 0
        },
        {
          label: 'Pec (Contatto)',
          keyField: 'PEC',
          selected: false,
          index: 0
        },
        {
          label: 'Codice ufficio',
          keyField: 'Dm_Rubrica.CODICEUFFICIO',
          selected: false,
          index: 0
        },
        {
          label: 'Codice Ipa',
          keyField: 'Dm_Rubrica.CODICEIPA',
          selected: false,
          index: 0
        },
        {
          label: 'Pec (Rubrica)',
          keyField: 'Dm_Rubrica.PECRUBRICA',
          selected: false,
          index: 0
        }
      ],
      selectFields: [
        {
          label: 'Abitazione Nominativo',
          keyField: 'ABITAZIONE',
          selected: false,
          index: 0
        },
        {
          label: 'Cellulare Nominativo',
          keyField: 'CELL',
          selected: false,
          index: 0
        },
        {
          label: 'E-Mail Nominativo',
          keyField: 'EMAIL',
          selected: false,
          index: 0
        },
        {
          label: 'Fax Nominativo',
          keyField: 'FAX',
          selected: false,
          index: 0
        },
        {
          label: 'Mansione',
          keyField: 'MANSIONE',
          selected: false,
          index: 0
        },
        {
          label: 'Nominativo',
          keyField: 'CONTATTO',
          selected: false,
          index: 0
        },
        {
          label: 'Reparto Nominativo',
          keyField: 'REPARTO',
          selected: false,
          index: 0
        },
        {
          label: 'Telefono nominativo',
          keyField: 'TEL',
          selected: false,
          index: 0
        },
        {
          label: 'Ufficio Nominativo',
          keyField: 'UFFICIO',
          selected: false,
          index: 0
        },
        {
          label: 'Aoo',
          keyField: 'DM_RUBRICA_AOO',
          selected: false,
          index: 0
        },
        {
          label: 'Cap',
          keyField: 'DM_RUBRICA_CAP',
          selected: false,
          index: 0
        },
        {
          label: 'Codice',
          keyField: 'DM_RUBRICA_CODICE',
          selected: false,
          index: 0
        },
        {
          label: 'E-Mail Rubrica',
          keyField: 'DM_RUBRICA_MAIL',
          selected: false,
          index: 0
        },
        {
          label: 'Fax',
          keyField: 'DM_RUBRICA_FAX',
          selected: false,
          index: 0
        },
        {
          label: 'Indirizzo',
          keyField: 'DM_RUBRICA_INDIRIZZO',
          selected: false,
          index: 0
        },
        {
          label: 'Località',
          keyField: 'DM_RUBRICA_LOCALITA',
          selected: false,
          index: 0
        },
        {
          label: 'Nazione',
          keyField: 'DM_RUBRICA_NAZIONE',
          selected: false,
          index: 0
        },
        {
          label: 'Provincia',
          keyField: 'DM_RUBRICA_PROVINCIA',
          selected: false,
          index: 0
        },
        {
          label: 'Rubrica',
          keyField: 'DM_CATRUBRICHE_RUBRICA',
          selected: false,
          index: 0
        },
        {
          label: 'Id Rubrica',
          keyField: 'DM_CATRUBRICHE_ID',
          selected: false,
          index: 0
        },
        {
          label: 'Categoria',
          keyField: 'DM_RUBRICA_CLASSE',
          selected: false,
          index: 0
        },
        {
          label: 'Codice Fiscale',
          keyField: 'DM_RUBRICA_CODFIS',
          selected: false,
          index: 0
        },
        {
          label: 'Partita Iva',
          keyField: 'DM_RUBRICA_PARTIVA',
          selected: false,
          index: 0
        },
        {
          label: 'Telefono',
          keyField: 'DM_RUBRICA_TEL',
          selected: false,
          index: 0
        },
        {
          label: 'Ragione sociale',
          keyField: 'DM_RUBRICA_RAGIONE_SOCIALE',
          selected: false,
          index: 1
        },
        {
          label: 'Descrizione',
          keyField: 'DM_RUBRICA_UCONTATTI',
          selected: false,
          index: 0
        },
        {
          label: 'Rubrica',
          keyField: 'DM_RUBRICA_SYSTEM_ID',
          selected: false,
          index: 2
        },
        {
          label: 'Id Contatto',
          keyField: 'ID',
          selected: false,
          index: 0
        },
        {
          label: 'Aoo rubrica',
          keyField: 'DM_RUBRICA_AOORUBRICA',
          selected: false,
          index: 0
        },
        {
          label: 'Abilitato FEA (Contatto)',
          keyField: 'FEAENABLED',
          selected: false,
          index: 0
        },
        {
          label: 'Scadenza FEA (Contatto)',
          keyField: 'FEAEXPIREDATE',
          selected: false,
          index: 0
        },
        {
          label: 'Nome (Contatto)',
          keyField: 'NOME',
          selected: false,
          index: 0
        },
        {
          label: 'Cognome (Contatto)',
          keyField: 'COGNOME',
          selected: false,
          index: 0
        },
        {
          label: 'Pec (Contatto)',
          keyField: 'PEC',
          selected: false,
          index: 0
        },
        {
          label: 'Codice ufficio',
          keyField: 'DM_RUBRICA_CODICEUFFICIO',
          selected: false,
          index: 0
        },
        {
          label: 'Codice Ipa',
          keyField: 'DM_RUBRICA_CODICEIPA',
          selected: false,
          index: 0
        },
        {
          label: 'Pec (Rubrica)',
          keyField: 'DM_RUBRICA_PECRUBRICA',
          selected: false,
          index: 0
        }
      ],
      filter: ''
    };
    addressBookAllFieldsBody: JSON = {
        searchdto: {
            dateTimeFields: null,
            stringFields: [{
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'ABITAZIONE',
                externalId: null,
                description: 'Abitazione',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'CELL',
                externalId: null,
                description: 'Cellulare',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'CONTATTO',
                externalId: null,
                description: 'Nominativo',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'EMAIL',
                externalId: null,
                description: 'Email',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            },
              {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'FAX',
                externalId: null,
                description: 'Fax',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'MANSIONE',
                externalId: null,
                description: 'Mansione',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'REPARTO',
                externalId: null,
                description: 'Reparto',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'TEL',
                externalId: null,
                description: 'Telefono',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'UFFICIO',
                externalId: null,
                description: 'Ufficio',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.AOO',
                externalId: null,
                description: 'Aoo',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.AOORUBRICA',
                externalId: null,
                description: 'Aoo rubrica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.CAP',
                externalId: null,
                description: 'Cap',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.CELL',
                externalId: null,
                description: 'Cellulare',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.CLASSE',
                externalId: null,
                description: 'Classe',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.CODFIS',
                externalId: null,
                description: 'Codice Fiscale',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.CODICE',
                externalId: null,
                description: 'Codice',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.FAX',
                externalId: null,
                description: 'Fax',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.INDIRIZZO',
                externalId: null,
                description: 'Indirizzo',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.LOCALITA',
                externalId: null,
                description: 'Località',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.MAIL',
                externalId: null,
                description: 'E-Mail Rubrica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.NAZIONE',
                externalId: null,
                description: 'Nazione',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.NOTERUBRICA',
                externalId: null,
                description: 'Note rubrica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.PARTIVA',
                externalId: null,
                description: 'Partita Iva',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.PROVINCIA',
                externalId: null,
                description: 'Provincia',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.RAGIONE_SOCIALE',
                externalId: null,
                description: 'Ragione sociale',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 1,
                valore1: 'P',
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: 'A',
                name: 'Dm_Rubrica.STATO',
                externalId: null,
                description: 'Stato',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.TEL',
                externalId: null,
                description: 'Telefono',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: 'D',
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.TIPO',
                externalId: null,
                description: 'Tipologia rubrica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.UCONTATTI',
                externalId: null,
                description: 'Dettaglio anagrafica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 0,
                defaultOperator: 4,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_CatRubriche.RUBRICA',
                externalId: null,
                description: 'Rubrica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchStringDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }],
            intFields: [{
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 2,
                defaultOperator: 3,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'ID',
                externalId: null,
                description: 'Id',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchIntDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: true,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 2,
                defaultOperator: 3,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'RUBRICA',
                externalId: null,
                description: 'Non trovata',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchIntDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: false,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 2,
                defaultOperator: 3,
                tableName: null,
                binderFieldId: 0,
                multiple: -1,
                name: 'Dm_Rubrica.CATEGORIA',
                externalId: null,
                description: 'Rubrica',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchIntDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: false,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 2,
                defaultOperator: 3,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_Rubrica.SYSTEM_ID',
                externalId: null,
                description: 'Id',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchIntDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: false,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 2,
                defaultOperator: 3,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_CatRubriche.ID',
                externalId: null,
                description: 'CategoryId',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchIntDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: false,
                predefinedProfileFormula: null
            }, {
                operator: 0,
                valore1: null,
                valore2: null,
                groupId: 0,
                fieldType: 0,
                additionalFieldType: 2,
                defaultOperator: 3,
                tableName: null,
                binderFieldId: 0,
                multiple: null,
                name: 'Dm_CatRubriche.TIPO',
                externalId: null,
                description: 'CategoryType',
                order: 0,
                dataSource: null,
                required: false,
                formula: null,
                className: 'FieldBaseForSearchIntDto',
                locked: false,
                comboGruppiId: null,
                dependencyFields: [],
                associations: null,
                isAdditional: false,
                visible: false,
                predefinedProfileFormula: null
            }],
            boolFields: null,
            doubleFields: null,
            stringListFields: null,
            maxItems: 0
        }, selectdto: {
            maxItems: 99999,
            fields: [{
                sqlSelectField: null,
                toCalculate: false,
                index: 0,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Aoo rubrica',
                name: 'DM_RUBRICA_AOORUBRICA',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: true,
                sort: {},
                canSelectField: true
            },
                {
                    sqlSelectField: null,
                    toCalculate: false,
                    index: 0,
                    selected: true,
                    fieldType: 0,
                    orderBy: {direction: 0, index: 0},
                    externalId: null,
                    label: 'Id contatto',
                    name: 'ID',
                    userSelectionEnabled: true,
                    userSelectionGroup: 0,
                    visible: true,
                    sort: {},
                    canSelectField: true
                },
                {
                sqlSelectField: null,
                toCalculate: false,
                index: 1,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Codice',
                name: 'DM_RUBRICA_CODICE',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: true,
                sort: {},
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 2,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Rubrica',
                name: 'DM_RUBRICA_SYSTEM_ID',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                sort: {},
                canSelectField: false
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 3,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Ragione sociale',
                name: 'DM_RUBRICA_RAGIONE_SOCIALE',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: true,
                sort: {direction: 'desc', index: 0},
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 4,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'E-Mail Rubrica',
                name: 'DM_RUBRICA_MAIL',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: true,
                sort: {},
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 5,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Indirizzo',
                name: 'DM_RUBRICA_INDIRIZZO',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: true,
                sort: {},
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 6,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Località',
                name: 'DM_RUBRICA_LOCALITA',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: true,
                sort: {},
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Aoo',
                name: 'DM_RUBRICA_AOO',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Cap',
                name: 'DM_RUBRICA_CAP',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Fax',
                name: 'DM_RUBRICA_FAX',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Nazione',
                name: 'DM_RUBRICA_NAZIONE',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Provincia',
                name: 'DM_RUBRICA_PROVINCIA',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Rubrica',
                name: 'DM_CATRUBRICHE_RUBRICA',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Categoria',
                name: 'DM_RUBRICA_CLASSE',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Codice Fiscale',
                name: 'DM_RUBRICA_CODFIS',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: false,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Partita Iva',
                name: 'DM_RUBRICA_PARTIVA',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }, {
                sqlSelectField: null,
                toCalculate: false,
                index: 7,
                selected: true,
                fieldType: 0,
                orderBy: {direction: 0, index: 0},
                externalId: null,
                label: 'Telefono',
                name: 'DM_RUBRICA_TEL',
                userSelectionEnabled: true,
                userSelectionGroup: 0,
                visible: false,
                canSelectField: true
            }]
        }
    } as unknown as JSON;

    addressBookFildBody: JSON = {
        addressBookCategoryId: -3,
        filterFields: [
            {
                label: '',
                keyField: '',
                selected: true,
                index: 0
            }
        ],
        selectFields: [
            {
                keyField: 'CONTATTO',
                selected: true,
                index: 0
            }, {
                keyField: 'DM_CATRUBRICHE_ID',
                selected: true,
                index: 0
            }, {
                keyField: 'DM_RUBRICA_AOO',
                selected: true,
                index: 0
            }, {
                keyField: 'DM_RUBRICA_MAIL',
                selected: true,
                index: 0
            }, {
                keyField: 'DM_RUBRICA_RAGIONE_SOCIALE',
                selected: true,
                index: 0
            }, {
                keyField: 'DM_RUBRICA_SYSTEM_ID',
                selected: true,
                index: 0
            }, {
                keyField: 'DM_RUBRICA_UCONTATTI',
                selected: true,
                index: 0
            }, {
                keyField: 'ID',
                selected: true,
                index: 0
            }, {
                keyField: 'address',
                selected: true,
                index: 0
            }, {
                keyField: 'addressBookId',
                selected: true,
                index: 0
            }, {
                keyField: 'code',
                selected: true,
                index: 0
            }, {
                keyField: 'contact',
                selected: true,
                index: 0
            }, {
                keyField: 'contactId',
                selected: true,
                index: 0
            }, {
                keyField: 'department',
                selected: true,
                index: 0
            }, {
                keyField: 'description',
                selected: true,
                index: 0
            }, {
                keyField: 'docNumber',
                selected: true,
                index: 0
            }, {
                keyField: 'email',
                selected: true,
                index: 0
            }, {
                keyField: 'externalId',
                selected: true,
                index: 0
            }, {
                keyField: 'fax',
                selected: true,
                index: 0
            }, {
                keyField: 'faxName',
                selected: true,
                index: 0
            }, {
                keyField: 'fiscalCode',
                selected: true,
                index: 0
            }, {
                keyField: 'house',
                selected: true,
                index: 0
            }, {
                keyField: 'id',
                selected: true,
                index: 0
            }, {
                keyField: 'job',
                selected: true,
                index: 0
            }, {
                keyField: 'locality',
                selected: true,
                index: 0
            }, {
                keyField: 'mail',
                selected: true,
                index: 0
            }, {
                keyField: 'manual',
                selected: true,
                index: 0
            }, {
                keyField: 'mobilePhone',
                selected: true,
                index: 0
            }, {
                keyField: 'nation',
                selected: true,
                index: 0
            }, {
                keyField: 'office',
                selected: true,
                index: 0
            }, {
                keyField: 'phone',
                selected: true,
                index: 0
            }, {
                keyField: 'postalCode',
                selected: true,
                index: 0
            }, {
                keyField: 'priority',
                selected: true,
                index: 0
            }, {
                keyField: 'province',
                selected: true,
                index: 0
            }, {
                keyField: 'reference',
                selected: true,
                index: 0
            }, {
                keyField: 'selected',
                selected: true,
                index: 0
            }, {
                keyField: 'society',
                selected: true,
                index: 0
            }, {
                keyField: 'telName',
                selected: true,
                index: 0
            }, {
                keyField: 'type',
                selected: true,
                index: 0
            }, {
                keyField: 'vat',
                selected: true,
                index: 0
            },
        ],
        filter: 'string'
    } as unknown as JSON;
}
