<div class="center">
    <mat-card class="cardcomponentMasks1" style="width: 30%; color: {{this.dashColor}}" id="style-4">
        <div style="text-align: center; color: {{this.dashColor}}"><h2>Formati disponibili</h2><h4><i>Il formato HTML viene sempre generato per
            poter
            mostrare l'anteprima del report</i></h4></div>
        <div style="display: flex">
            <mat-slide-toggle [checked]="true" disabled>HTML</mat-slide-toggle>
        </div>
        <div style="display: flex">
            <mat-slide-toggle [(ngModel)]="this.CSV">CSV</mat-slide-toggle>
        </div>
        <div style="display: flex">
            <mat-slide-toggle [(ngModel)]="this.EXCEL2007">EXCEL2007</mat-slide-toggle>
        </div>
        <div style="display: flex">
            <mat-slide-toggle [(ngModel)]="this.PDF">PDF</mat-slide-toggle>
        </div>
        <div style="display: flex">
            <mat-slide-toggle [(ngModel)]="this.WORD2007">WORD2007</mat-slide-toggle>
        </div>
        <br>
        <div style="text-align: left">
            <ng-container *ngFor="let parameter of this.report.parameters">
                <ng-container [ngSwitch]="parameter.configuration.className"
                              *ngIf="parameter.configuration.visible"
                              style="height:fit-content; width: 100%;">
                    <mat-form-field *ngSwitchCase="'FieldBaseForSearchStringDto'" class="form-field"
                                    appearance="outline">
                        <mat-label>{{parameter.configuration.description}}</mat-label>
                        <input ngModel [value]="parameter.configuration.valore1 || ''"
                               [(ngModel)]="parameter.configuration.valore1" name="{{parameter.configuration.name}}"
                               [id]="parameter.configuration.name" [required]="parameter.configuration.required"
                               [disabled]="parameter.configuration.locked"
                               maxlength="{{parameter.configuration.numMaxChar || 100}}" matInput
                               placeholder="{{parameter.configuration.description}}">
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </div>


    </mat-card>
    <mat-card class="cardcomponentMasks1" style="width: 65%; padding: 0px !important;" id="style-4">
        <div style="width: 100%; height: 5%;position: -webkit-sticky;
  position: sticky; top: 0; background-color: white">
            <button style="float:left; color: {{this.dashColor}}" title="Genera report" mat-icon-button
                    aria-label="icon-button send" (click)="generateReport()">
                <mat-icon>print</mat-icon>
            </button>
            <button style="float:left; color: {{this.dashColor}}" title="Info" mat-icon-button
                    aria-label="icon-button send" (click)="openInfoDialog()">
                <mat-icon>info</mat-icon>
            </button>
            <button style="float:left; color: {{this.dashColor}}" title="Scarica report" mat-icon-button
                    aria-label="icon-button send" (click)="openDownloadDialog()">
                <mat-icon>get_app</mat-icon>
            </button>
            <button style="float:left; color: {{this.dashColor}}" title="Espandi" mat-icon-button
                    aria-label="icon-button send" (click)="openExpandDialog()">
                <mat-icon>aspect_ratio</mat-icon>
            </button>
        </div>
        <div style = "color: {{this.dashColor}}">
            <ng-container [ngSwitch]="this.state"
                          style="height:fit-content; width: 100%;">
                <ng-container *ngSwitchCase="'noreport'">
                    <h2>Nessun report generato</h2>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <h2>Errore nella generazione del report</h2>
                </ng-container>
                <ng-container *ngSwitchCase="'loading'">
                    <br><br>
                    <mat-progress-bar mode="buffer"></mat-progress-bar>
                </ng-container>
                <div *ngSwitchCase="'found'">
                    <div style="width: 90%; display: inline-block;">
                        <div [innerHTML]="this.fileHTML" style="width: 100%; color: black"></div>
                    </div>
                </div>
            </ng-container>
        </div>

    </mat-card>
</div>
