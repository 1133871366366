<!--<app-dashnav></app-dashnav>
<div class="center" role="main">
  <div class="formo">
    <mat-card class="card">
      <h3 style="text-align: center;">
        <mat-card-title><p>MENU:</p></mat-card-title>
      </h3>
        <p></p>
      <mat-card-content>
        <div class="contenitore">
          <app-home></app-home>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Opzione 1</button>
        <button mat-button>Opzione 2</button>
        <button mat-button>Opzione 3</button>
        <button mat-button>Opzione 4</button>
        <button mat-button>Opzione 5</button>
      </mat-card-actions>
    </mat-card>
  </div>(click)="drawer.toggle()"
</div>
-->
<router-outlet></router-outlet>

<mat-drawer-container class="main-container" id=style-4 autosize *ngIf = "false">
  <mat-toolbar class="shadow" style="background: white; height: 8%!important;  border-bottom: 1px solid #dee2e6;">

    <button *ngIf="this.opened" class="menu-button" (click)="drawer.toggle()" (click)="openedChange()"
            mat-icon-button>
      <mat-icon style="color: black">arrow_back_ios</mat-icon>
    </button>
    <button *ngIf="!this.opened" class="menu-button" (click)="drawer.toggle()" (click)="openedChange()"
            mat-icon-button>
      <mat-icon style="color: black">arrow_forward_ios</mat-icon>
    </button>
    <!--<span style="color: black">
        {{this.apiService.namePath}}
    </span> -->
    <mat-button-toggle-group *ngFor="let name of this.apiService.namePathV2" appearance="legacy"
                             style="overflow: inherit;">
      <mat-button-toggle *ngIf="!name.active" style="font-weight: bold; font: 15px Arial, sans-serif;"
                         (click)="this.pathClick(name.route, name.params)"
                         value="{{name.label}}">{{name.label}}</mat-button-toggle>
      <mat-button-toggle *ngIf="name.active" (click)="this.pathClick(name.route, name.params)"
                         value="{{name.label}}">{{name.label}}</mat-button-toggle>
    </mat-button-toggle-group>
    <div style="text-align: right; width: 100%; color: black !important">
      <span class="example-spacer"></span>
      <app-nav-bar *ngIf="this.apiService.isLoggedIn()"></app-nav-bar>
    </div>
  </mat-toolbar>

  <mat-drawer
    [ngStyle]="this.apiService.mapsMenu?{'background-color': '#f8f9fa'} : {'background-color': this.apiService.dashColor}"
    style=" border-right: 1px solid #dee2e6;  max-width: 220px"
    class="card" #drawer
    mode="side" opened="false">
    <div class="buttons">
            <span style="padding: 10px; text-align: center;background-color: white; margin-bottom: 6%"><!--img  width="150" alt="Angular Logo" src="../../../assets/img/isComply.png" /-->
                <img class="pointer"
                     style="vertical-align: middle; width: 100%; height: auto;"
                     alt="Logo" [src]="img"/>
                <br>
            </span>
    </div>
    <div class="buttons" id="style-4" *ngIf="!this.apiService.mapsMenu">
      <!--<hr style="width: 80%; text-align: center; margin-right: auto; margin-left: auto">
      <br>-->
      <!--<button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}"  (click) = "ultimaricercaClick()"><mat-icon svgIcon="UltimaRicercaIcon"  style="margin-right: 8px"></mat-icon>Ultima ricerca</button>-->
      <!--<button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.defaultVisibility" (click) = "desktopClick()" autofocus><mat-icon svgIcon="DesktopIcon"  style="margin-right: 8px" ></mat-icon>Desktop</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.RubricaVisibility" (click) = "rubricaClick()"><mat-icon svgIcon="RubricaIcon"  style="margin-right: 8px"></mat-icon>Rubrica</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.ArchiviazioneVisibility" (click) = "archiviazioneClick()"><mat-icon svgIcon="ArchiviazioneIcon"  style="margin-right: 8px"></mat-icon>Archiviazione</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.RicercaVisibility" (click) = "ricercaClick()"><mat-icon svgIcon="RicercaIcon"  style="margin-right: 8px"></mat-icon>Ricerca</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.ModelliVisibility" (click) = "modelliClick()"><mat-icon svgIcon="ModelliIcon"  style="margin-right: 8px"></mat-icon>Modelli</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.VisteVisibility" (click) = "visteClick()"><mat-icon svgIcon="VisteIcon"  style="margin-right: 8px"></mat-icon>Viste</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.MaschereVisibility" (click) = "maschereClick()"><mat-icon svgIcon="MaschereIcon"  style="margin-right: 8px"></mat-icon>Maschere</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}"  *ngIf="this.TaskVisibility"(click) = "taskClick()"><mat-icon svgIcon="TaskIcon"  style="margin-right: 8px"></mat-icon>Task</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}" *ngIf="this.FascicoliVisibility" (click) = "fascicoliClick()"><mat-icon svgIcon="FascicoliIcon"  style="margin-right: 8px"></mat-icon>Fascicoli</button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "{{menu.label}}"  (click) = "settingsClick()"><mat-icon svgIcon="SettingsIcon"  style="margin-right: 8px"></mat-icon>Impostazioni</button>-->
      <ng-container *ngFor="let menu of this.menuLayout">
        <ng-container [ngSwitch]="menu.elementType">
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'28'" (click)="goToView(menu.elementId, menu.label)">
            <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
        </ng-container>
      </ng-container>
      <!--
      <ng-container *ngFor="let menu of this.menuLayout">
        <ng-container [ngSwitch]="menu.elementType">
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'38'" (click)="desktopClick(menu.label, menu.id)">
            <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span></button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'17'" (click)="rubricaClick(menu.label)">
            <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'48'" (click)="startWorkflow(menu.elementId)">
            <mat-icon  style="margin-right: 8px">engineering</mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'6'" (click)="archiviazioneClick(menu.label)">
            <mat-icon svgIcon="ArchiviazioneIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'1'" (click)="ricercaClick(menu.label)">
            <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'8'" (click)="modelliClick(menu.label)">
            <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'2'" (click)="visteClick(menu.label)">
            <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'7'" (click)="maschereClick(menu.label)">
            <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'41'" (click)="taskClick(menu.label)">
            <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'14'" (click)="fascicoliClick(menu.label)">
            <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'28'" (click)="goToView(menu.elementId, menu.label)">
            <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'32'" (click)="openFoldersId(menu.elementId, menu.label)">
            <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'49'" (click)="openReportList(menu.label)">
            <mat-icon style="margin-right: 8px">assessment</mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'51'">
            <mat-icon style="margin-right: 8px">account_tree</mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'52'">
            <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  *ngSwitchCase="'53'">
            <mat-icon style="margin-right: 8px">bolt</mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                  title="{{menu.label}}"
                  (click)="goToMask(menu.elementId, menu.label)"
                  *ngSwitchCase="'27'">
            <mat-icon style="margin-right: 8px">tab</mat-icon>
            <span class="spanlabel">{{menu.label}}</span>
          </button>
          <ng-container *ngIf="menu.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                    (click)="powerClick(menu.label, menu.elementId)" class="align-left-buttons"
                    title="{{menu.label}}"
                    *ngSwitchCase="'39'">
              <mat-icon style="margin-right: 8px">summarize</mat-icon>
              <span class="spanlabel">{{menu.label}}</span>
            </button>
          </ng-container>
          <ng-container>
            <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
                    title="{{menu.label}}"
                    *ngSwitchCase="'34'" (click)="enableFolder(menu.id)">
              <mat-icon style="margin-right: 8px">folder_open</mat-icon>
              <span class="spanlabel">{{menu.label}}</span>
              <ng-container *ngFor="let folder of this.folders">
                <ng-container *ngIf="folder.id == menu.id && folder.enabled == true">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </ng-container>
                <ng-container *ngIf="folder.id == menu.id && folder.enabled == false">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </ng-container>
              </ng-container>
            </button>
            <ng-container *ngFor="let folder of this.folders">
              <ng-container *ngIf="folder.id == menu.id && folder.enabled == true">
                <ng-container *ngFor="let child of menu.childs">
                  <ng-container [ngSwitch]="child.elementType">
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'38'" (click)="desktopClick(child.label, child.id)" autofocus>
                      <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span></button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'17'" (click)="rubricaClick(child.label)">
                      <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'6'" (click)="archiviazioneClick(child.label)">
                      <mat-icon svgIcon="ArchiviazioneIcon"
                                style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'1'" (click)="ricercaClick(child.label)">
                      <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'8'" (click)="modelliClick(child.label)">
                      <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'2'" (click)="visteClick(child.label)">
                      <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'7'" (click)="maschereClick(child.label)">
                      <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'41'" (click)="taskClick(child.label)">
                      <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <ng-container *ngIf="child.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                              (click)="powerClick(child.label, child.elementId)"
                              class="align-left-buttons-child" title="{{child.label}}"
                              *ngSwitchCase="'39'">
                        <mat-icon style="margin-right: 8px">summarize</mat-icon>
                        <span class="spanlabel">{{child.label}}</span>
                      </button>
                    </ng-container>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'14'" (click)="fascicoliClick(child.label)">
                      <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'28'"
                            (click)="goToView(child.elementId, child.label)">
                      <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <ng-container>
                      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons-child"
                              title="{{child.label}}"
                              *ngSwitchCase="'34'" (click)="enableFolder(child.id)">
                        <mat-icon style="margin-right: 8px">folder_open</mat-icon>
                        <span class="spanlabel">{{child.label}}</span>
                        <ng-container *ngFor="let folder of this.folders">
                          <ng-container *ngIf="folder.id == child.id && folder.enabled == true">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                          </ng-container>
                          <ng-container *ngIf="folder.id == child.id && folder.enabled == false">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                          </ng-container>
                        </ng-container>
                      </button>
                      <ng-container *ngFor="let folder of this.folders">
                        <ng-container *ngIf="folder.id == child.id && folder.enabled == true">
                          <ng-container *ngFor="let child2 of child.childs">
                            <ng-container [ngSwitch]="child2.elementType">
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'38'" (click)="desktopClick(child2.label, child2.id)" autofocus>
                                <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span></button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'17'" (click)="rubricaClick(child2.label)">
                                <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'6'" (click)="archiviazioneClick(child2.label)">
                                <mat-icon svgIcon="ArchiviazioneIcon"
                                          style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'1'" (click)="ricercaClick(child2.label)">
                                <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <ng-container>
                                <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-more-left-buttons-child"
                                        title="{{child2.label}}"
                                        *ngSwitchCase="'34'" (click)="enableFolder(child2.id)">
                                  <mat-icon style="margin-right: 8px">folder_open</mat-icon>
                                  <span class="spanlabel">{{child2.label}}</span>
                                  <ng-container *ngFor="let folder of this.folders">
                                    <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                      <mat-icon>keyboard_arrow_down</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="folder.id == child2.id && folder.enabled == false">
                                      <mat-icon>keyboard_arrow_right</mat-icon>
                                    </ng-container>
                                  </ng-container>
                                </button>
                                <ng-container *ngFor="let folder of this.folders">
                                  <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                    <ng-container *ngFor="let child2 of child2.childs">
                                      <ng-container [ngSwitch]="child2.elementType">
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'38'" (click)="desktopClick(child2.label, child2.id)" autofocus>
                                          <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span></button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'17'" (click)="rubricaClick(child2.label)">
                                          <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'6'" (click)="archiviazioneClick(child2.label)">
                                          <mat-icon svgIcon="ArchiviazioneIcon"
                                                    style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'1'" (click)="ricercaClick(child2.label)">
                                          <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'8'" (click)="modelliClick(child2.label)">
                                          <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <ng-container>
                                          <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-more-left-buttons-child"
                                                  title="{{child2.label}}"
                                                  *ngSwitchCase="'34'" (click)="enableFolder(child2.id)">
                                            <mat-icon style="margin-right: 8px">folder_open</mat-icon>
                                            <span class="spanlabel">{{child2.label}}</span>
                                            <ng-container *ngFor="let folder of this.folders">
                                              <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                <mat-icon>keyboard_arrow_down</mat-icon>
                                              </ng-container>
                                              <ng-container *ngIf="folder.id == child2.id && folder.enabled == false">
                                                <mat-icon>keyboard_arrow_right</mat-icon>
                                              </ng-container>
                                            </ng-container>
                                          </button>
                                          <ng-container *ngFor="let folder of this.folders">
                                            <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                              <ng-container *ngFor="let child2 of child2.childs">
                                                <ng-container [ngSwitch]="child2.elementType">
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'38'" (click)="desktopClick(child2.label, child2.id)" autofocus>
                                                    <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span></button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'17'" (click)="rubricaClick(child2.label)">
                                                    <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'6'" (click)="archiviazioneClick(child2.label)">
                                                    <mat-icon svgIcon="ArchiviazioneIcon"
                                                              style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'1'" (click)="ricercaClick(child2.label)">
                                                    <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'8'" (click)="modelliClick(child2.label)">
                                                    <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'2'" (click)="visteClick(child2.label)">
                                                    <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'7'" (click)="maschereClick(child2.label)">
                                                    <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'41'" (click)="taskClick(child2.label)">
                                                    <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <ng-container *ngIf="child2.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                                                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                            (click)="powerClick(child2.label, child2.elementId)"
                                                            class="align-more-left-buttons-child" title="{{child2.label}}"
                                                            *ngSwitchCase="'39'">
                                                      <mat-icon style="margin-right: 8px">summarize</mat-icon>
                                                      <span class="spanlabel">{{child2.label}}</span>
                                                    </button>
                                                  </ng-container>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'14'" (click)="fascicoliClick(child2.label)">
                                                    <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'28'"
                                                          (click)="goToView(child2.elementId, child2.label)">
                                                    <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'32'"
                                                          (click)="openFoldersId(child2.elementId, child2.label)">
                                                    <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <ng-container>
                                                    <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-more-left-buttons-child"
                                                            title="{{child2.label}}"
                                                            *ngSwitchCase="'34'" (click)="enableFolder(child2.id)">
                                                      <mat-icon style="margin-right: 8px">folder_open</mat-icon>
                                                      <span class="spanlabel">{{child2.label}}</span>
                                                      <ng-container *ngFor="let folder of this.folders">
                                                        <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                          <mat-icon>keyboard_arrow_down</mat-icon>
                                                        </ng-container>
                                                        <ng-container *ngIf="folder.id == child2.id && folder.enabled == false">
                                                          <mat-icon>keyboard_arrow_right</mat-icon>
                                                        </ng-container>
                                                      </ng-container>
                                                    </button>
                                                    <ng-container *ngFor="let folder of this.folders">
                                                      <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                        <ng-container *ngFor="let child2 of child2.childs">
                                                          <ng-container [ngSwitch]="child2.elementType">
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'38'" (click)="desktopClick(child2.label, child2.id)" autofocus>
                                                              <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span></button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'17'" (click)="rubricaClick(child2.label)">
                                                              <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'6'" (click)="archiviazioneClick(child2.label)">
                                                              <mat-icon svgIcon="ArchiviazioneIcon"
                                                                        style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'1'" (click)="ricercaClick(child2.label)">
                                                              <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'8'" (click)="modelliClick(child2.label)">
                                                              <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'2'" (click)="visteClick(child2.label)">
                                                              <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'7'" (click)="maschereClick(child2.label)">
                                                              <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'41'" (click)="taskClick(child2.label)">
                                                              <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <ng-container *ngIf="child2.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                                                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                      (click)="powerClick(child2.label, child2.elementId)"
                                                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                      *ngSwitchCase="'39'">
                                                                <mat-icon style="margin-right: 8px">summarize</mat-icon>
                                                                <span class="spanlabel">{{child2.label}}</span>
                                                              </button>
                                                            </ng-container>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'14'" (click)="fascicoliClick(child2.label)">
                                                              <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'28'"
                                                                    (click)="goToView(child2.elementId, child2.label)">
                                                              <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <ng-container>
                                                              <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-more-left-buttons-child"
                                                                      title="{{child2.label}}"
                                                                      *ngSwitchCase="'34'" (click)="enableFolder(child2.id)">
                                                                <mat-icon style="margin-right: 8px">folder_open</mat-icon>
                                                                <span class="spanlabel">{{child2.label}}</span>
                                                                <ng-container *ngFor="let folder of this.folders">
                                                                  <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                                                  </ng-container>
                                                                  <ng-container *ngIf="folder.id == child2.id && folder.enabled == false">
                                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                                  </ng-container>
                                                                </ng-container>
                                                              </button>
                                                              <ng-container *ngFor="let folder of this.folders">
                                                                <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                                  <ng-container *ngFor="let child2 of child2.childs">
                                                                    <ng-container [ngSwitch]="child2.elementType">
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'38'" (click)="desktopClick(child2.label, child2.id)" autofocus>
                                                                        <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span></button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'17'" (click)="rubricaClick(child2.label)">
                                                                        <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'6'" (click)="archiviazioneClick(child2.label)">
                                                                        <mat-icon svgIcon="ArchiviazioneIcon"
                                                                                  style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'1'" (click)="ricercaClick(child2.label)">
                                                                        <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'8'" (click)="modelliClick(child2.label)">
                                                                        <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'2'" (click)="visteClick(child2.label)">
                                                                        <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'7'" (click)="maschereClick(child2.label)">
                                                                        <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'41'" (click)="taskClick(child2.label)">
                                                                        <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <ng-container>
                                                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-more-left-buttons-child"
                                                                                title="{{child2.label}}"
                                                                                *ngSwitchCase="'34'" (click)="enableFolder(child2.id)">
                                                                          <mat-icon style="margin-right: 8px">folder_open</mat-icon>
                                                                          <span class="spanlabel">{{child2.label}}</span>
                                                                          <ng-container *ngFor="let folder of this.folders">
                                                                            <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                                              <mat-icon>keyboard_arrow_down</mat-icon>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="folder.id == child2.id && folder.enabled == false">
                                                                              <mat-icon>keyboard_arrow_right</mat-icon>
                                                                            </ng-container>
                                                                          </ng-container>
                                                                        </button>
                                                                        <ng-container *ngFor="let folder of this.folders">
                                                                          <ng-container *ngIf="folder.id == child2.id && folder.enabled == true">
                                                                            <ng-container *ngFor="let child2 of child2.childs">
                                                                              <ng-container [ngSwitch]="child2.elementType">
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'38'" (click)="desktopClick(child2.label, child2.id)" autofocus>
                                                                                  <mat-icon svgIcon="DesktopIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span></button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'17'" (click)="rubricaClick(child2.label)">
                                                                                  <mat-icon svgIcon="RubricaIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'6'" (click)="archiviazioneClick(child2.label)">
                                                                                  <mat-icon svgIcon="ArchiviazioneIcon"
                                                                                            style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'1'" (click)="ricercaClick(child2.label)">
                                                                                  <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'8'" (click)="modelliClick(child2.label)">
                                                                                  <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'2'" (click)="visteClick(child2.label)">
                                                                                  <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'7'" (click)="maschereClick(child2.label)">
                                                                                  <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'41'" (click)="taskClick(child2.label)">
                                                                                  <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <ng-container *ngIf="child2.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                                                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                          (click)="powerClick(child2.label, child2.elementId)"
                                                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                          *ngSwitchCase="'39'">
                                                                                    <mat-icon style="margin-right: 8px">summarize</mat-icon>
                                                                                    <span class="spanlabel">{{child2.label}}</span>
                                                                                  </button>
                                                                                </ng-container>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'14'" (click)="fascicoliClick(child2.label)">
                                                                                  <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'28'"
                                                                                        (click)="goToView(child2.elementId, child2.label)">
                                                                                  <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'32'"
                                                                                        (click)="openFoldersId(child2.elementId, child2.label)">
                                                                                  <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'51'">
                                                                                  <mat-icon style="margin-right: 8px">bolt</mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'52'">
                                                                                  <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'53'">
                                                                                  <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        (click)="goToMask(child2.elementId, child2.label)"
                                                                                        *ngSwitchCase="'27'">
                                                                                  <mat-icon style="margin-right: 8px">tab</mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                        *ngSwitchCase="'49'" (click)="openReportList(child2.label)">
                                                                                  <mat-icon style="margin-right: 8px">assessment</mat-icon>
                                                                                  <span class="spanlabel">{{child2.label}}</span>
                                                                                </button>
                                                                              </ng-container>
                                                                            </ng-container>
                                                                          </ng-container>
                                                                        </ng-container>
                                                                      </ng-container>
                                                                      <ng-container *ngIf="child2.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                                                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                                (click)="powerClick(child2.label, child2.elementId)"
                                                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                                *ngSwitchCase="'39'">
                                                                          <mat-icon style="margin-right: 8px">summarize</mat-icon>
                                                                          <span class="spanlabel">{{child2.label}}</span>
                                                                        </button>
                                                                      </ng-container>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'14'" (click)="fascicoliClick(child2.label)">
                                                                        <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'28'"
                                                                              (click)="goToView(child2.elementId, child2.label)">
                                                                        <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'32'"
                                                                              (click)="openFoldersId(child2.elementId, child2.label)">
                                                                        <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'51'">
                                                                        <mat-icon style="margin-right: 8px">bolt</mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'52'">
                                                                        <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'53'">
                                                                        <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              (click)="goToMask(child2.elementId, child2.label)"
                                                                              *ngSwitchCase="'27'">
                                                                        <mat-icon style="margin-right: 8px">tab</mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                      <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                              class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                              *ngSwitchCase="'49'" (click)="openReportList(child2.label)">
                                                                        <mat-icon style="margin-right: 8px">assessment</mat-icon>
                                                                        <span class="spanlabel">{{child2.label}}</span>
                                                                      </button>
                                                                    </ng-container>
                                                                  </ng-container>
                                                                </ng-container>
                                                              </ng-container>
                                                            </ng-container>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'32'"
                                                                    (click)="openFoldersId(child2.elementId, child2.label)">
                                                              <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'51'">
                                                              <mat-icon style="margin-right: 8px">bolt</mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'52'">
                                                              <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'53'">
                                                              <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    (click)="goToMask(child2.elementId, child2.label)"
                                                                    *ngSwitchCase="'27'">
                                                              <mat-icon style="margin-right: 8px">tab</mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                            <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                                    class="align-more-left-buttons-child" title="{{child2.label}}"
                                                                    *ngSwitchCase="'49'" (click)="openReportList(child2.label)">
                                                              <mat-icon style="margin-right: 8px">assessment</mat-icon>
                                                              <span class="spanlabel">{{child2.label}}</span>
                                                            </button>
                                                          </ng-container>
                                                        </ng-container>
                                                      </ng-container>
                                                    </ng-container>
                                                  </ng-container>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'51'">
                                                    <mat-icon style="margin-right: 8px">bolt</mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'52'">
                                                    <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'53'">
                                                    <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          (click)="goToMask(child2.elementId, child2.label)"
                                                          *ngSwitchCase="'27'">
                                                    <mat-icon style="margin-right: 8px">tab</mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                  <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                          class="align-more-left-buttons-child" title="{{child2.label}}"
                                                          *ngSwitchCase="'49'" (click)="openReportList(child2.label)">
                                                    <mat-icon style="margin-right: 8px">assessment</mat-icon>
                                                    <span class="spanlabel">{{child2.label}}</span>
                                                  </button>
                                                </ng-container>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>
                                        </ng-container>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'2'" (click)="visteClick(child2.label)">
                                          <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'7'" (click)="maschereClick(child2.label)">
                                          <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'41'" (click)="taskClick(child2.label)">
                                          <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <ng-container *ngIf="child2.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                                          <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                  (click)="powerClick(child2.label, child2.elementId)"
                                                  class="align-more-left-buttons-child" title="{{child2.label}}"
                                                  *ngSwitchCase="'39'">
                                            <mat-icon style="margin-right: 8px">summarize</mat-icon>
                                            <span class="spanlabel">{{child2.label}}</span>
                                          </button>
                                        </ng-container>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'14'" (click)="fascicoliClick(child2.label)">
                                          <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'28'"
                                                (click)="goToView(child2.elementId, child2.label)">
                                          <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'32'"
                                                (click)="openFoldersId(child2.elementId, child2.label)">
                                          <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'51'">
                                          <mat-icon style="margin-right: 8px">bolt</mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'52'">
                                          <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'53'">
                                          <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                (click)="goToMask(child2.elementId, child2.label)"
                                                *ngSwitchCase="'27'">
                                          <mat-icon style="margin-right: 8px">tab</mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                        <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                                class="align-more-left-buttons-child" title="{{child2.label}}"
                                                *ngSwitchCase="'49'" (click)="openReportList(child2.label)">
                                          <mat-icon style="margin-right: 8px">assessment</mat-icon>
                                          <span class="spanlabel">{{child2.label}}</span>
                                        </button>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'8'" (click)="modelliClick(child2.label)">
                                <mat-icon svgIcon="ModelliIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'2'" (click)="visteClick(child2.label)">
                                <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'7'" (click)="maschereClick(child2.label)">
                                <mat-icon svgIcon="MaschereIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'41'" (click)="taskClick(child2.label)">
                                <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <ng-container *ngIf="child2.elementId !== 'cee8113d-f3ee-4f98-842f-94d024362d99'">
                                <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                        (click)="powerClick(child2.label, child2.elementId)"
                                        class="align-more-left-buttons-child" title="{{child2.label}}"
                                        *ngSwitchCase="'39'">
                                  <mat-icon style="margin-right: 8px">summarize</mat-icon>
                                  <span class="spanlabel">{{child2.label}}</span>
                                </button>
                              </ng-container>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'14'" (click)="fascicoliClick(child2.label)">
                                <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'28'"
                                      (click)="goToView(child2.elementId, child2.label)">
                                <mat-icon svgIcon="VisteIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'32'"
                                      (click)="openFoldersId(child2.elementId, child2.label)">
                                <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'51'">
                                <mat-icon style="margin-right: 8px">bolt</mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'52'">
                                <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'53'">
                                <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      (click)="goToMask(child2.elementId, child2.label)"
                                      *ngSwitchCase="'27'">
                                <mat-icon style="margin-right: 8px">tab</mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                              <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                                      class="align-more-left-buttons-child" title="{{child2.label}}"
                                      *ngSwitchCase="'49'" (click)="openReportList(child2.label)">
                                <mat-icon style="margin-right: 8px">assessment</mat-icon>
                                <span class="spanlabel">{{child2.label}}</span>
                              </button>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'32'"
                            (click)="openFoldersId(child.elementId, child.label)">
                      <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'51'">
                      <mat-icon style="margin-right: 8px">bolt</mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'52'">
                      <mat-icon style="margin-right: 8px">pie_chart</mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'53'">
                      <mat-icon style="margin-right: 8px">account_tree</mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            (click)="goToMask(child.elementId, child.label)"
                            *ngSwitchCase="'27'">
                      <mat-icon style="margin-right: 8px">tab</mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                    <button mat-flat-button style="background:{{this.apiService.dashColor}};"
                            class="align-left-buttons-child" title="{{child.label}}"
                            *ngSwitchCase="'49'" (click)="openReportList(child.label)">
                      <mat-icon style="margin-right: 8px">assessment</mat-icon>
                      <span class="spanlabel">{{child.label}}</span>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>


      </ng-container>
-->
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
              (click)="openPresenzeTable()">
        <mat-icon style="margin-right: 8px">table</mat-icon>
        Pianificazione presenze
      </button>
      <button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
              (click)="settingsClick('Impostazioni')" *ngIf="this.user.toLocaleLowerCase() == 'admin'">
        <mat-icon svgIcon="SettingsIcon" style="margin-right: 8px"></mat-icon>
        Impostazioni
      </button>
      <!--<button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons"
              (click)="mapClick('Mappa'); this.apiService.mapsMenuClick()"
              *ngIf="this.user.toLocaleLowerCase() == 'admin'">
        <mat-icon style="margin-right: 8px">map</mat-icon>
        Mappa
      </button>-->


      <!--<button mat-flat-button style="background:{{this.apiService.dashColor}};" class="align-left-buttons" title = "Check-up"
              (click)="checkUpClick('Check-up')">
          <mat-icon style="margin-right: 8px">done_outline</mat-icon>
          Check-up
      </button>-->


    </div>
    <div class="buttons" id="style-4" style="height: 92% !Important" *ngIf="this.apiService.mapsMenu">
      <div class="align-left-buttons"
           style="background-color: #f8f9fa !important; height: 100%; padding-left: 0px !important;">
        <div style="text-align: center;margin-bottom: 10%;">
          <h2>Impostazioni</h2>
        </div>
        <div style="text-align: left; padding-left: 15px; display: flex; flex-direction: column;">
          <!-- <div style="width: 100%; margin-bottom: 10%;">
             <b style="width: 80%">Clienti</b>
             <mat-slide-toggle style="float: right"></mat-slide-toggle>
           </div>
           <div style="width: 100%; margin-bottom: 10%;">
             <b style="width: 80%">Non clienti</b>
             <mat-slide-toggle style="float: right"></mat-slide-toggle>
           </div>-->
          <div style="width: 100%; margin-bottom: 10%;">
            <b style="width: 100%">Raggio</b><br>
            <mat-slider style="width: 100%; margin-top: 10%"
                        thumbLabel
                        [displayWith]="formatLabel"
                        tickInterval="100"
                        step="1"
                        min="0"
                        max="50"
                        aria-label="units"
                        [(ngModel)]="this.apiService.mapRange"
                        (ngModelChange)="this.rangeChanged()"
            ></mat-slider>
          </div>
          <div style="width: 100%; margin-bottom: 10%;">
            <b style="width: 100%;">Ricerca per:</b>
            <mat-button-toggle-group name="Ricerca" aria-label="Ricerca" style="margin-top: 5%"
                                     [(ngModel)]="this.apiService.typeSearch">
              <mat-button-toggle value="Categoria">Categoria</mat-button-toggle>
              <mat-button-toggle value="Parola">Parola chiave</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="width: 100%; margin-bottom: 10%;" *ngIf="this.apiService.typeSearch == 'Categoria'">
            <mat-form-field appearance="fill">
              <mat-label>Categorie selezionate</mat-label>
              <mat-select [formControl]="this.apiService.formCategories" multiple>
                <mat-option *ngFor="let cat of this.apiService.categories" [value]="cat.Id">{{cat.Name}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>
          <div style="width: 100%; margin-bottom: 10%;" *ngIf="this.apiService.typeSearch == 'Parola'">
            <mat-form-field appearance="fill">
              <mat-label>Inserisci parola chiave</mat-label>
              <input ngModel
                     [(ngModel)]="this.apiService.placeToSearch"
                     matInput
                     placeholder="Inserire filtro">
            </mat-form-field>
          </div>
          <!--<div style="width: 100%; margin-bottom: 10%;">
           <button mat-stroked-button (click) = "this.mapComp.searchProspect()" style ="white-space: break-spaces;">Ricerca prospect nel raggio di {{this.apiService.mapRange}}Km</button>
          </div>-->

        </div>
        <button mat-flat-button style="background:{{this.apiService.dashColor}};    width: 100%;" style="position: absolute;
                bottom: 0px;"
                (click)="this.apiService.mapsMenuClick()" *ngIf="this.user.toLocaleLowerCase() == 'admin'">
          <mat-icon style="margin-right: 8px">list_alt</mat-icon>
          Torna al menu
        </button>
      </div>


    </div>
  </mat-drawer>
</mat-drawer-container>

