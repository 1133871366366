import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class SearchJSON {
    getBody(docN): any{
        return {
            searchFilterDtoList: [
                {
                    description: 'string',
                    daAAndOr: 0,
                    fields: [{
                        additionalFieldType: 0,
                        and: null,
                        associations: null,
                        binderFieldId: 0,
                        className: 'FieldBaseForSearchDocumentTypeDto',
                        comboGruppiId: '',
                        dataSource: '',
                        defaultOperator: 3,
                        dependencyFields: [],
                        description: 'Classe documento',
                        externalId: '',
                        fieldType: 0,
                        formula: '',
                        groupId: 0,
                        isAdditional: false,
                        locked: true,
                        multiple: null,
                        name: 'DocumentType',
                        operator: 3,
                        order: 1,
                        predefinedProfileFormula: null,
                        required: false,
                        tableName: null,
                        valore1: docN,
                        valore2: null,
                        value: 56,
                        visible: true
                    }

                    ]
                }
            ],
            selectFilterDto: {
                fields: [
                    {
                        sqlSelectField: 'DM_PROFILE.IMPORTANTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Importante',
                        name: 'IMPORTANTE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ISARCHIVE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'ISARCHIVE',
                        name: 'ISARCHIVE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.SENDERS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Altri Da',
                        name: 'SENDERS',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.NOTE',
                        toCalculate: false,
                        index: 1,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Note',
                        name: 'NOTE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ALLEGATI',
                        toCalculate: false,
                        index: 3,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Allegati',
                        name: 'ALLEGATI',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.STATO AS STATO',
                        toCalculate: false,
                        index: 4,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Stato',
                        name: 'STATO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: '{INOUT}',
                        toCalculate: false,
                        index: 0,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Origine',
                        name: 'INOUT',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ASSOCIAZIONI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Associazioni',
                        name: 'ASSOCIAZIONI',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.WORKFLOW',
                        toCalculate: false,
                        index: 2,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Workflow',
                        name: 'WORKFLOW',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: '{Relazioni}',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Relazioni',
                        name: 'RELAZIONI',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: '{Formato}',
                        toCalculate: false,
                        index: 5,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 1,
                            index: 0
                        },
                        externalId: null,
                        label: 'Formato',
                        name: 'FORMATO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.PATH',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'PATH',
                        name: 'PATH',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.FILENAME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Nome file',
                        name: 'FILENAME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.GESTREV',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'GESTREV',
                        name: 'GESTREV',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DEVICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DEVICE',
                        name: 'DEVICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ETICHETTACD',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'ETICHETTACD',
                        name: 'ETICHETTACD',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.OPENDOC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'OPENDOC',
                        name: 'OPENDOC',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.CSTRING',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'CSTRING',
                        name: 'CSTRING',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.PSWZIP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'PSWZIP',
                        name: 'PSWZIP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.AUTORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Autore',
                        name: 'AUTORE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.PROTOCOLLO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Protocollo Pa',
                        name: 'PROTOCOLLO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DOCNUMBER',
                        toCalculate: false,
                        index: 6,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'System ID',
                        name: 'DOCNUMBER',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ANNO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Anno Protocollo Pa',
                        name: 'ANNO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.REVISIONE',
                        toCalculate: false,
                        index: 7,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Revisione',
                        name: 'REVISIONE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.AOO',
                        toCalculate: false,
                        index: 13,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Aoo',
                        name: 'AOO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DOCNAME',
                        toCalculate: false,
                        index: 10,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Oggetto',
                        name: 'DOCNAME',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DATADOC',
                        toCalculate: false,
                        index: 12,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Data documento',
                        name: 'DATADOC',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.MITTENTE',
                        toCalculate: false,
                        index: 8,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Da',
                        name: 'MITTENTE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DESTINATARIO',
                        toCalculate: false,
                        index: 9,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'A',
                        name: 'DESTINATARIO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.CC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'CC',
                        name: 'CC',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.SCADENZA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Scadenza',
                        name: 'SCADENZA',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.NUMERO',
                        toCalculate: false,
                        index: 11,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Numero',
                        name: 'NUMERO',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.CREATION_DATE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Data registrazione',
                        name: 'CREATION_DATE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ORIGINALE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Nome file',
                        name: 'ORIGINALE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'Pratiche',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Pratiche',
                        name: 'PRATICHE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: '{StatoProcDesc}',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Stato esteso',
                        name: 'STATOPROCDESC',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.ISAOS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'ISAOS',
                        name: 'ISAOS',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: '{CLASSEDOCDESC}',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Classe Doc. descrizione',
                        name: 'CLASSEDOCDESC',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_TIPIDOCUMENTO.CODICE AS CLASSEDOC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Classe documento',
                        name: 'CLASSEDOC',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: '{Autoredescription}',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Autore descrizione',
                        name: 'AUTOREDESCRIPTION',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.FLAG',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'FLAG',
                        name: 'FLAG',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DATAFILE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DATAFILE',
                        name: 'DATAFILE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.IMPRONTA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'IMPRONTA',
                        name: 'IMPRONTA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DOCUMENTTYPE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DOCUMENTTYPE',
                        name: 'DOCUMENTTYPE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.TIPO2',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'TIPO2',
                        name: 'TIPO2',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.TIPO3',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'TIPO3',
                        name: 'TIPO3',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.DATAPROT',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Data protocollo Pa',
                        name: 'DATAPROT',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.ID AS DM_NPCE_OUT_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_ID',
                        name: 'DM_NPCE_OUT_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DOC_NUMBER AS DM_NPCE_OUT_DOC_NUMBER',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DOC_NUMBER',
                        name: 'DM_NPCE_OUT_DOC_NUMBER',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DATA AS DM_NPCE_OUT_DATA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DATA',
                        name: 'DM_NPCE_OUT_DATA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DATA_INVIO AS DM_NPCE_OUT_DATA_INVIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DATA_INVIO',
                        name: 'DM_NPCE_OUT_DATA_INVIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DATA_INVIO_RICHIESTO AS DM_NPCE_OUT_DATA_INVIO_RICH',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DATA_INVIO_RICH',
                        name: 'DM_NPCE_OUT_DATA_INVIO_RICH',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DATA_AUTORIZZAZIONE AS DM_NPCE_OUT_DATA_AUTORIZ',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DATA_AUTORIZ',
                        name: 'DM_NPCE_OUT_DATA_AUTORIZ',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.UTENTE_INVIO AS DM_NPCE_OUT_UTENTE_INVIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_UTENTE_INVIO',
                        name: 'DM_NPCE_OUT_UTENTE_INVIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.UTENTE_AUTORIZZAZIONE AS DM_NPCE_OUT_UTENTE_AUTORIZ',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_UTENTE_AUTORIZ',
                        name: 'DM_NPCE_OUT_UTENTE_AUTORIZ',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.ID_RUBRICA AS DM_NPCE_OUT_ID_RUBRICA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_ID_RUBRICA',
                        name: 'DM_NPCE_OUT_ID_RUBRICA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DESTINATARIO AS DM_NPCE_OUT_DESTINATARIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DESTINATARIO',
                        name: 'DM_NPCE_OUT_DESTINATARIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.INDIRIZZO AS DM_NPCE_OUT_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_INDIRIZZO',
                        name: 'DM_NPCE_OUT_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.LOCALITA AS DM_NPCE_OUT_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_LOCALITA',
                        name: 'DM_NPCE_OUT_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.CAP AS DM_NPCE_OUT_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_CAP',
                        name: 'DM_NPCE_OUT_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.PROVINCIA AS DM_NPCE_OUT_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_PROVINCIA',
                        name: 'DM_NPCE_OUT_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NAZIONE AS DM_NPCE_OUT_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NAZIONE',
                        name: 'DM_NPCE_OUT_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.USA_MITTENTE_PREDEFINITO AS DM_NPCE_OUT_USA_MITTENTE_PRE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_USA_MITTENTE_PRE',
                        name: 'DM_NPCE_OUT_USA_MITTENTE_PRE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.MITTENTE AS DM_NPCE_OUT_MITTENTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_MITTENTE',
                        name: 'DM_NPCE_OUT_MITTENTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.M_INDIRIZZO AS DM_NPCE_OUT_M_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_M_INDIRIZZO',
                        name: 'DM_NPCE_OUT_M_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.M_LOCALITA AS DM_NPCE_OUT_M_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_M_LOCALITA',
                        name: 'DM_NPCE_OUT_M_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.M_CAP AS DM_NPCE_OUT_M_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_M_CAP',
                        name: 'DM_NPCE_OUT_M_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.M_PROVINCIA AS DM_NPCE_OUT_M_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_M_PROVINCIA',
                        name: 'DM_NPCE_OUT_M_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.M_NAZIONE AS DM_NPCE_OUT_M_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_M_NAZIONE',
                        name: 'DM_NPCE_OUT_M_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.STATO AS DM_NPCE_OUT_STATO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_STATO',
                        name: 'DM_NPCE_OUT_STATO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NPCE_TIPO AS DM_NPCE_OUT_NPCE_TIPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NPCE_TIPO',
                        name: 'DM_NPCE_OUT_NPCE_TIPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.STATO_LAYOUT AS DM_NPCE_OUT_STATO_LAYOUT',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_STATO_LAYOUT',
                        name: 'DM_NPCE_OUT_STATO_LAYOUT',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NPCE_ID_RICHIESTA AS DM_NPCE_OUT_NPCE_ID_RICHIESTA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NPCE_ID_RICHIESTA',
                        name: 'DM_NPCE_OUT_NPCE_ID_RICHIESTA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NPCE_GUID_UTENTE AS DM_NPCE_OUT_NPCE_GUID_UTENTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NPCE_GUID_UTENTE',
                        name: 'DM_NPCE_OUT_NPCE_GUID_UTENTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NPCE_ID_ORDINE AS DM_NPCE_OUT_NPCE_ID_ORDINE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NPCE_ID_ORDINE',
                        name: 'DM_NPCE_OUT_NPCE_ID_ORDINE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NPCE_NUMERO_RACCOMANDATA AS DM_NPCE_OUT_NPCE_NUMERO_RACC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NPCE_NUMERO_RACC',
                        name: 'DM_NPCE_OUT_NPCE_NUMERO_RACC',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.COSTO AS DM_NPCE_OUT_COSTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_COSTO',
                        name: 'DM_NPCE_OUT_COSTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.DETTAGLIO_COSTI AS DM_NPCE_OUT_DETTAGLIO_COSTI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_DETTAGLIO_COSTI',
                        name: 'DM_NPCE_OUT_DETTAGLIO_COSTI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.NOTE AS DM_NPCE_OUT_NOTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_NOTE',
                        name: 'DM_NPCE_OUT_NOTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: '(SELECT Description FROM DM_UTENTI WHERE UTENTE = DM_NPCE_OUT.UTENTE_INVIO) AS DM_NPCE_OUT_UTENTE_INVIO_DESC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'NPCE_OUT_UTENTE_INVIO_DESC',
                        name: 'NPCE_OUT_UTENTE_INVIO_DESC',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: '(SELECT Description FROM DM_UTENTI WHERE UTENTE = DM_NPCE_OUT.UTENTE_AUTORIZZAZIONE) AS DM_NPCE_OUT_UTENTE_AUTO_DESC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_UTENTE_AUTO_DESC',
                        name: 'DM_NPCE_OUT_UTENTE_AUTO_DESC',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_BARCODE.TIPOIMPORTA AS DM_BARCODE_TIPOIMPORTA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_TIPOIMPORTA',
                        name: 'DM_BARCODE_TIPOIMPORTA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_BARCODE.TIPOBARCODE AS DM_BARCODE_TIPOBARCODE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_TIPOBARCODE',
                        name: 'DM_BARCODE_TIPOBARCODE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_BARCODE.IDBARCODE AS DM_BARCODE_IDBARCODE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_IDBARCODE',
                        name: 'DM_BARCODE_IDBARCODE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_BARCODE.NUMLETT AS DM_BARCODE_NUMLETT',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_NUMLETT',
                        name: 'DM_BARCODE_NUMLETT',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_BARCODE.NUMBARCODE AS DM_BARCODE_NUMBARCODE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_NUMBARCODE',
                        name: 'DM_BARCODE_NUMBARCODE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_BARCODE.BARCODE AS DM_BARCODE_BARCODE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_BARCODE',
                        name: 'DM_BARCODE_BARCODE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: '{BarcodePageControl}',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_BARCODE_PAGECONTROL',
                        name: 'DM_BARCODE_PAGECONTROL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'SD_ASSOCDOC.IDERP AS SD_ASSOCDOC_IDERP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_SDASSOCDOC_IDERP',
                        name: 'DM_SDASSOCDOC_IDERP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'SD_ASSOCDOC.PATHERP AS SD_ASSOCDOC_PATHERP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_SDASSOCDOC_PATHERP',
                        name: 'DM_SDASSOCDOC_PATHERP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.UTENTE AS DM_DOCOPEN_UTENTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_UTENTE',
                        name: 'DM_DOCOPEN_UTENTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.DATATIME AS DM_DOCOPEN_DATATIME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_DATATIME',
                        name: 'DM_DOCOPEN_DATATIME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.MOD AS DM_DOCOPEN_MOD',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_MOD',
                        name: 'DM_DOCOPEN_MOD',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.FILEOPEN AS DM_DOCOPEN_FILEOPEN',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_FILEOPEN',
                        name: 'DM_DOCOPEN_FILEOPEN',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.IMPRONTA AS DM_DOCOPEN_IMPRONTA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_IMPRONTA',
                        name: 'DM_DOCOPEN_IMPRONTA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.MACCHINA AS DM_DOCOPEN_MACCHINA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_MACCHINA',
                        name: 'DM_DOCOPEN_MACCHINA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.UTENTE_DESCRIPTION AS DM_DOCOPEN_UTENTE_DESCRIPTION',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_UTENTE_DESCRIPTION',
                        name: 'DM_DOCOPEN_UTENTE_DESCRIPTION',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DOCOPEN.UTENTE_AOO AS DM_DOCOPEN_UTENTE_AOO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DOCOPEN_UTENTE_AOO',
                        name: 'DM_DOCOPEN_UTENTE_AOO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_FILEINFOLDER.REVISIONE AS DM_FILEINFOLDER_REVISIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_FILEINFOLDER_REVISIONE',
                        name: 'DM_FILEINFOLDER_REVISIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_FASCICOLI.ID AS DM_FASCICOLI_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_FASCICOLI_ID',
                        name: 'DM_FASCICOLI_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_FASCICOLI.ID_PADRE AS DM_FASCICOLI_ID_PADRE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_FASCICOLI_ID_PADRE',
                        name: 'DM_FASCICOLI_ID_PADRE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_FASCICOLI.AUTORE AS DM_FASCICOLI_AUTORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_FASCICOLI_AUTORE',
                        name: 'DM_FASCICOLI_AUTORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_FASCICOLI.DATA_CREATE AS DM_FASCICOLI_DATA_CREATE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_FASCICOLI_DATA_CREATE',
                        name: 'DM_FASCICOLI_DATA_CREATE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_FASCICOLI.NOME AS DM_FASCICOLI_NOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_FASCICOLI_NOME',
                        name: 'DM_FASCICOLI_NOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_RELAZIONI.DOCNUMBER_TO AS DM_RELAZIONI_DOCNUMBER_TO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_RELAZIONI_DOCNUMBER_TO',
                        name: 'DM_RELAZIONI_DOCNUMBER_TO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_RELAZIONI.DOCNUMBER_FROM AS DM_RELAZIONI_DOCNUMBER_FROM',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_RELAZIONI_DOCNUMBER_FROM',
                        name: 'DM_RELAZIONI_DOCNUMBER_FROM',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.ID AS DM_REVISIONI_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_ID',
                        name: 'DM_REVISIONI_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.NUMDOC AS DM_REVISIONI_DOCNUMBER',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_DOCNUMBER',
                        name: 'DM_REVISIONI_DOCNUMBER',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.REVISIONE AS DM_REVISIONI_REVISIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_REVISIONE',
                        name: 'DM_REVISIONI_REVISIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.AUTORE AS DM_REVISIONI_AUTORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_AUTORE',
                        name: 'DM_REVISIONI_AUTORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.DATADOC AS DM_REVISIONI_DATADOC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_DATADOC',
                        name: 'DM_REVISIONI_DATADOC',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.DATAPROF AS DM_REVISIONI_DATAPROF',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_DATAPROF',
                        name: 'DM_REVISIONI_DATAPROF',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.PERCORSO AS DM_REVISIONI_PERCORSO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_PERCORSO',
                        name: 'DM_REVISIONI_PERCORSO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.NOMEFILE AS DM_REVISIONI_NOMEFILE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_NOMEFILE',
                        name: 'DM_REVISIONI_NOMEFILE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.ORIGINALE AS DM_REVISIONI_ORIGINALE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_ORIGINALE',
                        name: 'DM_REVISIONI_ORIGINALE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.DATAFILE AS DM_REVISIONI_DATAFILE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_DATAFILE',
                        name: 'DM_REVISIONI_DATAFILE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.ALLEGATI AS DM_REVISIONI_ALLEGATI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_ALLEGATI',
                        name: 'DM_REVISIONI_ALLEGATI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.IMPRONTA AS DM_REVISIONI_IMPRONTA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_IMPRONTA',
                        name: 'DM_REVISIONI_IMPRONTA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.PSWZIP AS DM_REVISIONI_PSWZIP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_PSWZIP',
                        name: 'DM_REVISIONI_PSWZIP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.DEVICE AS DM_REVISIONI_DEVICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_DEVICE',
                        name: 'DM_REVISIONI_DEVICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.ETICHETTACD AS DM_REVISIONI_ETICHETTACD',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_ETICHETTACD',
                        name: 'DM_REVISIONI_ETICHETTACD',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_REVISIONI.CSTRING AS DM_REVISIONI_CSTRING',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_REVISIONI_CSTRING',
                        name: 'DM_REVISIONI_CSTRING',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.ID AS DM_DATIPROFILO_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_ID',
                        name: 'DM_DATIPROFILO_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CAMPO AS DM_DATIPROFILO_CAMPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CAMPO',
                        name: 'DM_DATIPROFILO_CAMPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.VALORE AS DM_DATIPROFILO_VALORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_VALORE',
                        name: 'DM_DATIPROFILO_VALORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CONTATTI AS DM_DATIPROFILO_CONTATTI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CONTATTI',
                        name: 'DM_DATIPROFILO_CONTATTI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.FAX AS DM_DATIPROFILO_FAX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FAX',
                        name: 'DM_DATIPROFILO_FAX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.TEL AS DM_DATIPROFILO_TEL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TEL',
                        name: 'DM_DATIPROFILO_TEL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.INDIRIZZO AS DM_DATIPROFILO_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_INDIRIZZO',
                        name: 'DM_DATIPROFILO_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.MAIL AS DM_DATIPROFILO_MAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_MAIL',
                        name: 'DM_DATIPROFILO_MAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.LOCALITA AS DM_DATIPROFILO_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_LOCALITA',
                        name: 'DM_DATIPROFILO_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CAP AS DM_DATIPROFILO_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CAP',
                        name: 'DM_DATIPROFILO_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.PROVINCIA AS DM_DATIPROFILO_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_PROVINCIA',
                        name: 'DM_DATIPROFILO_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.NAZIONE AS DM_DATIPROFILO_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_NAZIONE',
                        name: 'DM_DATIPROFILO_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CONTATTO AS DM_DATIPROFILO_CONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CONTATTO',
                        name: 'DM_DATIPROFILO_CONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.MANSIONE AS DM_DATIPROFILO_MANSIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_MANSIONE',
                        name: 'DM_DATIPROFILO_MANSIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.TELNOME AS DM_DATIPROFILO_TELNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TELNOME',
                        name: 'DM_DATIPROFILO_TELNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.FAXNOME AS DM_DATIPROFILO_FAXNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FAXNOME',
                        name: 'DM_DATIPROFILO_FAXNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CELL AS DM_DATIPROFILO_CELL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CELL',
                        name: 'DM_DATIPROFILO_CELL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.ABITAZIONE AS DM_DATIPROFILO_ABITAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_ABITAZIONE',
                        name: 'DM_DATIPROFILO_ABITAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.REPARTO AS DM_DATIPROFILO_REPARTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_REPARTO',
                        name: 'DM_DATIPROFILO_REPARTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.UFFICIO AS DM_DATIPROFILO_UFFICIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_UFFICIO',
                        name: 'DM_DATIPROFILO_UFFICIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.EMAIL AS DM_DATIPROFILO_EMAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_EMAIL',
                        name: 'DM_DATIPROFILO_EMAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.RIFERIMENTO AS DM_DATIPROFILO_RIFERIMENTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_RIFERIMENTO',
                        name: 'DM_DATIPROFILO_RIFERIMENTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CODFIS AS DM_DATIPROFILO_CODFIS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CODFIS',
                        name: 'DM_DATIPROFILO_CODFIS',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.PARTIVA AS DM_DATIPROFILO_PARTIVA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_PARTIVA',
                        name: 'DM_DATIPROFILO_PARTIVA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.PRIORITA AS DM_DATIPROFILO_PRIORITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_PRIORITA',
                        name: 'DM_DATIPROFILO_PRIORITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.CODICE AS DM_DATIPROFILO_CODICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CODICE',
                        name: 'DM_DATIPROFILO_CODICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.IDRUBRICA AS DM_DATIPROFILO_IDRUBRICA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_IDRUBRICA',
                        name: 'DM_DATIPROFILO_IDRUBRICA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO.IDCONTATTO AS DM_DATIPROFILO_IDCONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_IDCONTATTO',
                        name: 'DM_DATIPROFILO_IDCONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.ID AS DM_DATIPROFILO_FROM_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_ID',
                        name: 'DM_DATIPROFILO_FROM_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CAMPO AS DM_DATIPROFILO_FROM_CAMPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CAMPO',
                        name: 'DM_DATIPROFILO_FROM_CAMPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.VALORE AS DM_DATIPROFILO_FROM_VALORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_VALORE',
                        name: 'DM_DATIPROFILO_FROM_VALORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CONTATTI AS DM_DATIPROFILO_FROM_CONTATTI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CONTATTI',
                        name: 'DM_DATIPROFILO_FROM_CONTATTI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.FAX AS DM_DATIPROFILO_FROM_FAX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_FAX',
                        name: 'DM_DATIPROFILO_FROM_FAX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.TEL AS DM_DATIPROFILO_FROM_TEL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_TEL',
                        name: 'DM_DATIPROFILO_FROM_TEL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.INDIRIZZO AS DM_DATIPROFILO_FROM_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_INDIRIZZO',
                        name: 'DM_DATIPROFILO_FROM_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.MAIL AS DM_DATIPROFILO_FROM_MAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_MAIL',
                        name: 'DM_DATIPROFILO_FROM_MAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.LOCALITA AS DM_DATIPROFILO_FROM_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_LOCALITA',
                        name: 'DM_DATIPROFILO_FROM_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CAP AS DM_DATIPROFILO_FROM_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CAP',
                        name: 'DM_DATIPROFILO_FROM_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.PROVINCIA AS DM_DATIPROFILO_FROM_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_PROVINCIA',
                        name: 'DM_DATIPROFILO_FROM_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.NAZIONE AS DM_DATIPROFILO_FROM_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_NAZIONE',
                        name: 'DM_DATIPROFILO_FROM_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CONTATTO AS DM_DATIPROFILO_FROM_CONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CONTATTO',
                        name: 'DM_DATIPROFILO_FROM_CONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.MANSIONE AS DM_DATIPROFILO_FROM_MANSIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_MANSIONE',
                        name: 'DM_DATIPROFILO_FROM_MANSIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.TELNOME AS DM_DATIPROFILO_FROM_TELNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_TELNOME',
                        name: 'DM_DATIPROFILO_FROM_TELNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.FAXNOME AS DM_DATIPROFILO_FROM_FAXNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_FAXNOME',
                        name: 'DM_DATIPROFILO_FROM_FAXNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CELL AS DM_DATIPROFILO_FROM_CELL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CELL',
                        name: 'DM_DATIPROFILO_FROM_CELL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.ABITAZIONE AS DM_DATIPROFILO_FROM_ABITAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_ABITAZIONE',
                        name: 'DM_DATIPROFILO_FROM_ABITAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.REPARTO AS DM_DATIPROFILO_FROM_REPARTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_REPARTO',
                        name: 'DM_DATIPROFILO_FROM_REPARTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.UFFICIO AS DM_DATIPROFILO_FROM_UFFICIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_UFFICIO',
                        name: 'DM_DATIPROFILO_FROM_UFFICIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.EMAIL AS DM_DATIPROFILO_FROM_EMAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_EMAIL',
                        name: 'DM_DATIPROFILO_FROM_EMAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.RIFERIMENTO AS DM_DATIPROFILO_FROM_RIFERIMENTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_RIFERIMENTO',
                        name: 'DM_DATIPROFILO_FROM_RIFERIMENTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CODFIS AS DM_DATIPROFILO_FROM_CODFIS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CODFIS',
                        name: 'DM_DATIPROFILO_FROM_CODFIS',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.PARTIVA AS DM_DATIPROFILO_FROM_PARTIVA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_PARTIVA',
                        name: 'DM_DATIPROFILO_FROM_PARTIVA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.PRIORITA AS DM_DATIPROFILO_FROM_PRIORITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_PRIORITA',
                        name: 'DM_DATIPROFILO_FROM_PRIORITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.CODICE AS DM_DATIPROFILO_FROM_CODICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_CODICE',
                        name: 'DM_DATIPROFILO_FROM_CODICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.IDRUBRICA AS DM_DATIPROFILO_FROM_IDRUBRICA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_IDRUBRICA',
                        name: 'DM_DATIPROFILO_FROM_IDRUBRICA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_FROM.IDCONTATTO AS DM_DATIPROFILO_FROM_IDCONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_FROM_IDCONTATTO',
                        name: 'DM_DATIPROFILO_FROM_IDCONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.ID AS DM_DATIPROFILO_TO_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_ID',
                        name: 'DM_DATIPROFILO_TO_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CAMPO AS DM_DATIPROFILO_TO_CAMPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CAMPO',
                        name: 'DM_DATIPROFILO_TO_CAMPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.VALORE AS DM_DATIPROFILO_TO_VALORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_VALORE',
                        name: 'DM_DATIPROFILO_TO_VALORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CONTATTI AS DM_DATIPROFILO_TO_CONTATTI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CONTATTI',
                        name: 'DM_DATIPROFILO_TO_CONTATTI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.FAX AS DM_DATIPROFILO_TO_FAX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_FAX',
                        name: 'DM_DATIPROFILO_TO_FAX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.TEL AS DM_DATIPROFILO_TO_TEL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_TEL',
                        name: 'DM_DATIPROFILO_TO_TEL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.INDIRIZZO AS DM_DATIPROFILO_TO_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_INDIRIZZO',
                        name: 'DM_DATIPROFILO_TO_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.MAIL AS DM_DATIPROFILO_TO_MAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_MAIL',
                        name: 'DM_DATIPROFILO_TO_MAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.LOCALITA AS DM_DATIPROFILO_TO_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_LOCALITA',
                        name: 'DM_DATIPROFILO_TO_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CAP AS DM_DATIPROFILO_TO_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CAP',
                        name: 'DM_DATIPROFILO_TO_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.PROVINCIA AS DM_DATIPROFILO_TO_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_PROVINCIA',
                        name: 'DM_DATIPROFILO_TO_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.NAZIONE AS DM_DATIPROFILO_TO_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_NAZIONE',
                        name: 'DM_DATIPROFILO_TO_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CONTATTO AS DM_DATIPROFILO_TO_CONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CONTATTO',
                        name: 'DM_DATIPROFILO_TO_CONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.MANSIONE AS DM_DATIPROFILO_TO_MANSIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_MANSIONE',
                        name: 'DM_DATIPROFILO_TO_MANSIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.TELNOME AS DM_DATIPROFILO_TO_TELNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_TELNOME',
                        name: 'DM_DATIPROFILO_TO_TELNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.FAXNOME AS DM_DATIPROFILO_TO_FAXNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_FAXNOME',
                        name: 'DM_DATIPROFILO_TO_FAXNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CELL AS DM_DATIPROFILO_TO_CELL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CELL',
                        name: 'DM_DATIPROFILO_TO_CELL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.ABITAZIONE AS DM_DATIPROFILO_TO_ABITAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_ABITAZIONE',
                        name: 'DM_DATIPROFILO_TO_ABITAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.REPARTO AS DM_DATIPROFILO_TO_REPARTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_REPARTO',
                        name: 'DM_DATIPROFILO_TO_REPARTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.UFFICIO AS DM_DATIPROFILO_TO_UFFICIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_UFFICIO',
                        name: 'DM_DATIPROFILO_TO_UFFICIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.EMAIL AS DM_DATIPROFILO_TO_EMAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_EMAIL',
                        name: 'DM_DATIPROFILO_TO_EMAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.RIFERIMENTO AS DM_DATIPROFILO_TO_RIFERIMENTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_RIFERIMENTO',
                        name: 'DM_DATIPROFILO_TO_RIFERIMENTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CODFIS AS DM_DATIPROFILO_TO_CODFIS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CODFIS',
                        name: 'DM_DATIPROFILO_TO_CODFIS',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.PARTIVA AS DM_DATIPROFILO_TO_PARTIVA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_PARTIVA',
                        name: 'DM_DATIPROFILO_TO_PARTIVA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.PRIORITA AS DM_DATIPROFILO_TO_PRIORITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_PRIORITA',
                        name: 'DM_DATIPROFILO_TO_PRIORITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.CODICE AS DM_DATIPROFILO_TO_CODICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_CODICE',
                        name: 'DM_DATIPROFILO_TO_CODICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.IDRUBRICA AS DM_DATIPROFILO_TO_IDRUBRICA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_IDRUBRICA',
                        name: 'DM_DATIPROFILO_TO_IDRUBRICA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_TO.IDCONTATTO AS DM_DATIPROFILO_TO_IDCONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_TO_IDCONTATTO',
                        name: 'DM_DATIPROFILO_TO_IDCONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.ID AS DM_DATIPROFILO_CC_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_ID',
                        name: 'DM_DATIPROFILO_CC_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CAMPO AS DM_DATIPROFILO_CC_CAMPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CAMPO',
                        name: 'DM_DATIPROFILO_CC_CAMPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.VALORE AS DM_DATIPROFILO_CC_VALORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_VALORE',
                        name: 'DM_DATIPROFILO_CC_VALORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CONTATTI AS DM_DATIPROFILO_CC_CONTATTI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CONTATTI',
                        name: 'DM_DATIPROFILO_CC_CONTATTI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.FAX AS DM_DATIPROFILO_CC_FAX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_FAX',
                        name: 'DM_DATIPROFILO_CC_FAX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.TEL AS DM_DATIPROFILO_CC_TEL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_TEL',
                        name: 'DM_DATIPROFILO_CC_TEL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.INDIRIZZO AS DM_DATIPROFILO_CC_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_INDIRIZZO',
                        name: 'DM_DATIPROFILO_CC_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.MAIL AS DM_DATIPROFILO_CC_MAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_MAIL',
                        name: 'DM_DATIPROFILO_CC_MAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.LOCALITA AS DM_DATIPROFILO_CC_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_LOCALITA',
                        name: 'DM_DATIPROFILO_CC_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CAP AS DM_DATIPROFILO_CC_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CAP',
                        name: 'DM_DATIPROFILO_CC_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.PROVINCIA AS DM_DATIPROFILO_CC_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_PROVINCIA',
                        name: 'DM_DATIPROFILO_CC_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.NAZIONE AS DM_DATIPROFILO_CC_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_NAZIONE',
                        name: 'DM_DATIPROFILO_CC_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CONTATTO AS DM_DATIPROFILO_CC_CONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CONTATTO',
                        name: 'DM_DATIPROFILO_CC_CONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.MANSIONE AS DM_DATIPROFILO_CC_MANSIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_MANSIONE',
                        name: 'DM_DATIPROFILO_CC_MANSIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.TELNOME AS DM_DATIPROFILO_CC_TELNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_TELNOME',
                        name: 'DM_DATIPROFILO_CC_TELNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.FAXNOME AS DM_DATIPROFILO_CC_FAXNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_FAXNOME',
                        name: 'DM_DATIPROFILO_CC_FAXNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CELL AS DM_DATIPROFILO_CC_CELL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CELL',
                        name: 'DM_DATIPROFILO_CC_CELL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.ABITAZIONE AS DM_DATIPROFILO_CC_ABITAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_ABITAZIONE',
                        name: 'DM_DATIPROFILO_CC_ABITAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.REPARTO AS DM_DATIPROFILO_CC_REPARTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_REPARTO',
                        name: 'DM_DATIPROFILO_CC_REPARTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.UFFICIO AS DM_DATIPROFILO_CC_UFFICIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_UFFICIO',
                        name: 'DM_DATIPROFILO_CC_UFFICIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.EMAIL AS DM_DATIPROFILO_CC_EMAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_EMAIL',
                        name: 'DM_DATIPROFILO_CC_EMAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.RIFERIMENTO AS DM_DATIPROFILO_CC_RIFERIMENTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_RIFERIMENTO',
                        name: 'DM_DATIPROFILO_CC_RIFERIMENTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CODFIS AS DM_DATIPROFILO_CC_CODFIS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CODFIS',
                        name: 'DM_DATIPROFILO_CC_CODFIS',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.PARTIVA AS DM_DATIPROFILO_CC_PARTIVA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_PARTIVA',
                        name: 'DM_DATIPROFILO_CC_PARTIVA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.PRIORITA AS DM_DATIPROFILO_CC_PRIORITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_PRIORITA',
                        name: 'DM_DATIPROFILO_CC_PRIORITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.CODICE AS DM_DATIPROFILO_CC_CODICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_CODICE',
                        name: 'DM_DATIPROFILO_CC_CODICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.IDRUBRICA AS DM_DATIPROFILO_CC_IDRUBRICA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_IDRUBRICA',
                        name: 'DM_DATIPROFILO_CC_IDRUBRICA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_CC.IDCONTATTO AS DM_DATIPROFILO_CC_IDCONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_CC_IDCONTATTO',
                        name: 'DM_DATIPROFILO_CC_IDCONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.ID AS DM_EMERGENZA_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_ID',
                        name: 'DM_EMERGENZA_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.DATAINIZIO AS DM_EMERGENZA_DATAINIZIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_DATAINIZIO',
                        name: 'DM_EMERGENZA_DATAINIZIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.DATAFINE AS DM_EMERGENZA_DATAFINE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_DATAFINE',
                        name: 'DM_EMERGENZA_DATAFINE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.MOTIVO AS DM_EMERGENZA_MOTIVO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_MOTIVO',
                        name: 'DM_EMERGENZA_MOTIVO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.NUMEROPROTOCOLLI AS DM_EMERGENZA_NUMEROPROTOCOLLI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_NUMEROPROTOCOLLI',
                        name: 'DM_EMERGENZA_NUMEROPROTOCOLLI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.AOO AS DM_EMERGENZA_AOO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_AOO',
                        name: 'DM_EMERGENZA_AOO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.UTENTE AS DM_EMERGENZA_UTENTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_UTENTE',
                        name: 'DM_EMERGENZA_UTENTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.TIPO AS DM_EMERGENZA_TIPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_TIPO',
                        name: 'DM_EMERGENZA_TIPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_EMERGENZA.CREATIONDATE AS DM_EMERGENZA_CREATIONDATE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_EMERGENZA_CREATIONDATE',
                        name: 'DM_EMERGENZA_CREATIONDATE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_ASSOCIAZIONI.ASSOCIAZIONI AS DM_ASSOCIAZIONI_ASSOCIAZIONI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_ASSOCIAZIONI_ASSOCIAZIONI',
                        name: 'DM_ASSOCIAZIONI_ASSOCIAZIONI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_ASSOCIAZIONI.UTENTE AS DM_ASSOCIAZIONI_UTENTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_ASSOCIAZIONI_UTENTE',
                        name: 'DM_ASSOCIAZIONI_UTENTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_ASSOCIAZIONI.DATA AS DM_ASSOCIAZIONI_DATA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_ASSOCIAZIONI_DATA',
                        name: 'DM_ASSOCIAZIONI_DATA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_ASSOCIAZIONI.NOME AS DM_ASSOCIAZIONI_NOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_ASSOCIAZIONI_NOME',
                        name: 'DM_ASSOCIAZIONI_NOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.ID AS DM_DATI_ENTE_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_ID',
                        name: 'DM_DATI_ENTE_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.PROTOCOLLO AS DM_DATI_ENTE_PROTOCOLLO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_PROTOCOLLO',
                        name: 'DM_DATI_ENTE_PROTOCOLLO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.DATAPROT AS DM_DATI_ENTE_DATAPROT',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_DATAPROT',
                        name: 'DM_DATI_ENTE_DATAPROT',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.UFFICIO AS DM_DATI_ENTE_UFFICIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_UFFICIO',
                        name: 'DM_DATI_ENTE_UFFICIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.PERSONA AS DM_DATI_ENTE_PERSONA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_PERSONA',
                        name: 'DM_DATI_ENTE_PERSONA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.SPEDIZIONE AS DM_DATI_ENTE_SPEDIZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_SPEDIZIONE',
                        name: 'DM_DATI_ENTE_SPEDIZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATI_ENTE.VSRIF AS DM_DATI_ENTE_VSRIF',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATI_ENTE_VSRIF',
                        name: 'DM_DATI_ENTE_VSRIF',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.ID AS DM_PROCESSDOC_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_ID',
                        name: 'DM_PROCESSDOC_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.IDPROCESSO AS DM_PROCESSDOC_IDPROCESSO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_IDPROCESSO',
                        name: 'DM_PROCESSDOC_IDPROCESSO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.NUMDOC AS DM_PROCESSDOC_NUMDOC',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_NUMDOC',
                        name: 'DM_PROCESSDOC_NUMDOC',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.REV AS DM_PROCESSDOC_REV',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_REV',
                        name: 'DM_PROCESSDOC_REV',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.STATO AS DM_PROCESSDOC_STATO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_STATO',
                        name: 'DM_PROCESSDOC_STATO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.PATH AS DM_PROCESSDOC_PATH',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_PATH',
                        name: 'DM_PROCESSDOC_PATH',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.NOMEFILE  AS DM_PROCESSDOC_NOMEFILE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_NOMEFILE',
                        name: 'DM_PROCESSDOC_NOMEFILE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.MODALITA AS DM_PROCESSDOC_MODALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_MODALITA',
                        name: 'DM_PROCESSDOC_MODALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.UTENTEINUSO AS DM_PROCESSDOC_UTENTEINUSO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_UTENTEINUSO',
                        name: 'DM_PROCESSDOC_UTENTEINUSO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.DATA AS DM_PROCESSDOC_DATA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_DATA',
                        name: 'DM_PROCESSDOC_DATA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.TASKRIF AS DM_PROCESSDOC_TASKRIF',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_TASKRIF',
                        name: 'DM_PROCESSDOC_TASKRIF',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.CSTRING AS DM_PROCESSDOC_CSTRING',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_CSTRING',
                        name: 'DM_PROCESSDOC_CSTRING',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.TOSEND AS DM_PROCESSDOC_TOSEND',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_TOSEND',
                        name: 'DM_PROCESSDOC_TOSEND',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROCESSDOC.FILESIZE AS DM_PROCESSDOC_FILESIZE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_PROCESSDOC_FILESIZE',
                        name: 'DM_PROCESSDOC_FILESIZE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPDEFINITION.STAMPNAME AS DM_STAMPDEFINITION_STAMPNAME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPDEFINITION_STAMPNAME',
                        name: 'DM_STAMPDEFINITION_STAMPNAME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPDEFINITION.STAMPDESCRIPTION AS DM_STAMPDEFINITION_STAMPDESCRIPTION',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPDEFINITION_STAMPDESCRIPTION',
                        name: 'DM_STAMPDEFINITION_STAMPDESCRIPTION',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPDEFINITION.ISRESIZABLE AS DM_STAMPDEFINITION_ISRESIZABLE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPDEFINITION_ISRESIZABLE',
                        name: 'DM_STAMPDEFINITION_ISRESIZABLE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPDEFINITION.DMTIPIDOCUMENTOID AS DM_STAMPDEFINITION_DMTIPIDOCUMENTOID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPDEFINITION_DMTIPIDOCUMENTOID',
                        name: 'DM_STAMPDEFINITION_DMTIPIDOCUMENTOID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPDEFINITION.REMOVEAFTERAPPLIED AS DM_STAMPDEFINITION_REMOVEAFTERAPPLIED',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPDEFINITION_REMOVEAFTERAPPLIED',
                        name: 'DM_STAMPDEFINITION_REMOVEAFTERAPPLIED',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPDEFINITION.ID AS DM_STAMPDEFINITION_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPDEFINITION_ID',
                        name: 'DM_STAMPDEFINITION_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.ID AS DM_STAMPSINSTANCE_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_ID',
                        name: 'DM_STAMPSINSTANCE_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.UTENTE AS DM_STAMPSINSTANCE_UTENTE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_UTENTE',
                        name: 'DM_STAMPSINSTANCE_UTENTE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.DATETIMEINSERT AS DM_STAMPSINSTANCE_DATETIMEINSERT',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_DATETIMEINSERT',
                        name: 'DM_STAMPSINSTANCE_DATETIMEINSERT',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.DMSTAMPDEFINITIONID AS DM_STAMPSINSTANCE_DMSTAMPDEFINITIONID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_DMSTAMPDEFINITIONID',
                        name: 'DM_STAMPSINSTANCE_DMSTAMPDEFINITIONID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.ISRESIZABLE AS DM_STAMPSINSTANCE_ISRESIZABLE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_ISRESIZABLE',
                        name: 'DM_STAMPSINSTANCE_ISRESIZABLE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.LOCATIONX AS DM_STAMPSINSTANCE_LOCATIONX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_LOCATIONX',
                        name: 'DM_STAMPSINSTANCE_LOCATIONX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.LOCATIONY AS DM_STAMPSINSTANCE_LOCATIONY',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_LOCATIONY',
                        name: 'DM_STAMPSINSTANCE_LOCATIONY',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.WIDTH AS DM_STAMPSINSTANCE_WIDTH',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_WIDTH',
                        name: 'DM_STAMPSINSTANCE_WIDTH',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.HEIGHT AS DM_STAMPSINSTANCE_HEIGHT',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_HEIGHT',
                        name: 'DM_STAMPSINSTANCE_HEIGHT',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.PAGEINDEX AS DM_STAMPSINSTANCE_PAGEINDEX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_PAGEINDEX',
                        name: 'DM_STAMPSINSTANCE_PAGEINDEX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_STAMPSINSTANCE.APPLIED AS DM_STAMPSINSTANCE_APPLIED',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Timbro applicato',
                        name: 'DM_STAMPSINSTANCE_APPLIED',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: ' DM_STAMPSINSTANCE.REMOVEAFTERAPPLIED AS M_STAMPSINSTANCE_REMOVEAFTERAPPLIED',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPSINSTANCE_REMOVEAFTERAPPLIED',
                        name: 'DM_STAMPSINSTANCE_REMOVEAFTERAPPLIED',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'ALIAS_STAMPAPPLIED.ID AS DM_STAMPAPPLIED_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPAPPLIED_ID',
                        name: 'DM_STAMPAPPLIED_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'ALIAS_STAMPAPPLIED.DMSTAMPSINSTANCEID AS DM_STAMPAPPLIED_INSTANCEID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_STAMPAPPLIED_INSTANCEID',
                        name: 'DM_STAMPAPPLIED_INSTANCEID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.FILESIZE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Dimensione file',
                        name: 'FILESIZE',
                        userSelectionEnabled: true,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_PROFILE.COLLABORATION',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'Distribuzione documenti',
                        name: 'COLLABORATION',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.COLLABORATIONID AS DMCOLLABORATIONID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_COLLABORATIONID',
                        name: 'DM_COLLABORATION_COLLABORATIONID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.COLLABORATIONDESCRIPTION AS DMCOLLABORATIONDESCRIPTION',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_COLLABORATIONDESCRIPTION',
                        name: 'DM_COLLABORATION_COLLABORATIONDESCRIPTION',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DOCNUMBER AS DMCOLLABORATIONDOCNUMBER',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_COLLABORATIONDOCNUMBER',
                        name: 'DM_COLLABORATION_COLLABORATIONDOCNUMBER',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.CMASTERID AS DMCOLLABORATIONMID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_CMASTERID',
                        name: 'DM_COLLABORATION_CMASTERID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.CMASTERDMUTENTIID AS DMCOLLABORATIONMDMUTENTIID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_CMASTERDMUTENTIID',
                        name: 'DM_COLLABORATION_CMASTERDMUTENTIID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.CMASTERCREATIONDATE AS DMCOLLABORATIONMCREATIONDATE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_CMASTERCREATIONDATE',
                        name: 'DM_COLLABORATION_CMASTERCREATIONDATE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.CMASTERISVALID AS DMCOLLABORATIONMISVALID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_CMASTERISVALID',
                        name: 'DM_COLLABORATION_CMASTERISVALID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILID AS DMCOLLABORATIONDETAILID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILID',
                        name: 'DM_COLLABORATION_DETAILID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILID AS DMCOLLABORATIONDID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILDMUTENTIID',
                        name: 'DM_COLLABORATION_DETAILDMUTENTIID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILCOLLABORATIONMODE AS DMCOLLABORATIONDCOLLABMODE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILCOLLABORATIONMODE',
                        name: 'DM_COLLABORATION_DETAILCOLLABORATIONMODE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILREADED AS DMCOLLABORATIONDREADED',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILREADED',
                        name: 'DM_COLLABORATION_DETAILREADED',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILISVALID AS DMCOLLABORATIONDISVALID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILISVALID',
                        name: 'DM_COLLABORATION_DETAILISVALID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILTAKEOFF AS DMCOLLABORATIONDTAKEOFF',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILTAKEOFF',
                        name: 'DM_COLLABORATION_DETAILTAKEOFF',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILDEPTHLEVEL AS DMCOLLABORATIONDDEPTHLEVEL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILDEPTHLEVEL',
                        name: 'DM_COLLABORATION_DETAILDEPTHLEVEL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILPROCESSEDCAUSE AS DMCOLLABORATIONDPROCCAUSE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILPROCESSEDCAUSE',
                        name: 'DM_COLLABORATION_DETAILPROCESSEDCAUSE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_COLLABORATION.DETAILLASTOPERATIONTIME AS DMCOLLABORATIONDETAILLASTTIME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_COLLABORATION_DETAILLASTOPERATIONTIME',
                        name: 'DM_COLLABORATION_DETAILLASTOPERATIONTIME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.ID AS DM_DATIPROFILO_SENDERS_ID',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_ID',
                        name: 'DM_DATIPROFILO_SENDERS_ID',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CAMPO AS DM_DATIPROFILO_SENDERS_CAMPO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CAMPO',
                        name: 'DM_DATIPROFILO_SENDERS_CAMPO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.VALORE AS DM_DATIPROFILO_SENDERS_VALORE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_VALORE',
                        name: 'DM_DATIPROFILO_SENDERS_VALORE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CONTATTI AS DM_DATIPROFILO_SENDERS_CONTATTI',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CONTATTI',
                        name: 'DM_DATIPROFILO_SENDERS_CONTATTI',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.FAX AS DM_DATIPROFILO_SENDERS_FAX',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_FAX',
                        name: 'DM_DATIPROFILO_SENDERS_FAX',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.TEL AS DM_DATIPROFILO_SENDERS_TEL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_TEL',
                        name: 'DM_DATIPROFILO_SENDERS_TEL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.INDIRIZZO AS DM_DATIPROFILO_SENDERS_INDIRIZZO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_INDIRIZZO',
                        name: 'DM_DATIPROFILO_SENDERS_INDIRIZZO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.MAIL AS DM_DATIPROFILO_SENDERS_MAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_MAIL',
                        name: 'DM_DATIPROFILO_SENDERS_MAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.LOCALITA AS DM_DATIPROFILO_SENDERS_LOCALITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_LOCALITA',
                        name: 'DM_DATIPROFILO_SENDERS_LOCALITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CAP AS DM_DATIPROFILO_SENDERS_CAP',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CAP',
                        name: 'DM_DATIPROFILO_SENDERS_CAP',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.PROVINCIA AS DM_DATIPROFILO_SENDERS_PROVINCIA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_PROVINCIA',
                        name: 'DM_DATIPROFILO_SENDERS_PROVINCIA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.NAZIONE AS DM_DATIPROFILO_SENDERS_NAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_NAZIONE',
                        name: 'DM_DATIPROFILO_SENDERS_NAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CONTATTO AS DM_DATIPROFILO_SENDERS_CONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CONTATTO',
                        name: 'DM_DATIPROFILO_SENDERS_CONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.MANSIONE AS DM_DATIPROFILO_SENDERS_MANSIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_MANSIONE',
                        name: 'DM_DATIPROFILO_SENDERS_MANSIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.TELNOME AS DM_DATIPROFILO_SENDERS_TELNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_TELNOME',
                        name: 'DM_DATIPROFILO_SENDERS_TELNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.FAXNOME AS DM_DATIPROFILO_SENDERS_FAXNOME',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_FAXNOME',
                        name: 'DM_DATIPROFILO_SENDERS_FAXNOME',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CELL AS DM_DATIPROFILO_SENDERS_CELL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CELL',
                        name: 'DM_DATIPROFILO_SENDERS_CELL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.ABITAZIONE AS DM_DATIPROFILO_SENDERS_ABITAZIONE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_ABITAZIONE',
                        name: 'DM_DATIPROFILO_SENDERS_ABITAZIONE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.REPARTO AS DM_DATIPROFILO_SENDERS_REPARTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_REPARTO',
                        name: 'DM_DATIPROFILO_SENDERS_REPARTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.UFFICIO AS DM_DATIPROFILO_SENDERS_UFFICIO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_UFFICIO',
                        name: 'DM_DATIPROFILO_SENDERS_UFFICIO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.EMAIL AS DM_DATIPROFILO_SENDERS_EMAIL',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_EMAIL',
                        name: 'DM_DATIPROFILO_SENDERS_EMAIL',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.RIFERIMENTO AS DM_DATIPROFILO_SENDERS_RIFERIMENTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_RIFERIMENTO',
                        name: 'DM_DATIPROFILO_SENDERS_RIFERIMENTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CODFIS AS DM_DATIPROFILO_SENDERS_CODFIS',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CODFIS',
                        name: 'DM_DATIPROFILO_SENDERS_CODFIS',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.PARTIVA AS DM_DATIPROFILO_SENDERS_PARTIVA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_PARTIVA',
                        name: 'DM_DATIPROFILO_SENDERS_PARTIVA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.PRIORITA AS DM_DATIPROFILO_SENDERS_PRIORITA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_PRIORITA',
                        name: 'DM_DATIPROFILO_SENDERS_PRIORITA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.CODICE AS DM_DATIPROFILO_SENDERS_CODICE',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_CODICE',
                        name: 'DM_DATIPROFILO_SENDERS_CODICE',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.IDRUBRICA AS DM_DATIPROFILO_SENDERS_IDRUBRICA',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_IDRUBRICA',
                        name: 'DM_DATIPROFILO_SENDERS_IDRUBRICA',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_DATIPROFILO_SENDERS.IDCONTATTO AS DM_DATIPROFILO_SENDERS_IDCONTATTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_DATIPROFILO_SENDERS_IDCONTATTO',
                        name: 'DM_DATIPROFILO_SENDERS_IDCONTATTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.BW AS DM_NPCE_OUT_BW',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_BW',
                        name: 'DM_NPCE_OUT_BW',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.FRONTERETRO AS DM_NPCE_OUT_FRONTERETRO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_FRONTERETRO',
                        name: 'DM_NPCE_OUT_FRONTERETRO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_NPCE_OUT.RICEVUTA_DI_RITORNO AS DM_NPCE_OUT_RICEVUTA_RITORNO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_NPCE_OUT_RICEVUTA_RITORNO',
                        name: 'DM_NPCE_OUT_RICEVUTA_RITORNO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'DM_CAMPICLASSE_LEGAMI.DOCNUMBERTO',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: null,
                        label: 'DM_CAMPICLASSE_LEGAMI_DOCTO',
                        name: 'DM_CAMPICLASSE_LEGAMI_DOCTO',
                        userSelectionEnabled: false,
                        userSelectionGroup: 1
                    },
                    {
                        sqlSelectField: 'CLASSE334_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti Coinvolti',
                        name: 'CLASSE334_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE468_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Valutazione del Rischio',
                        name: 'CLASSE468_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE503_19',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti Condivisi',
                        name: 'CLASSE503_19',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE509_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Aggiungi Trattamenti',
                        name: 'CLASSE509_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE517_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Identificare le misure per ridurre il rischio',
                        name: 'CLASSE517_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE531_44',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sub Responsabili',
                        name: 'CLASSE531_44',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE574_70',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti Coinvolti',
                        name: 'CLASSE574_70',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE575_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti potenzialmente coinvolti',
                        name: 'CLASSE575_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'CLASSE607_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti',
                        name: 'CLASSE607_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO142_13',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'TAB Codice',
                        name: 'COMBO142_13',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO143_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Area',
                        name: 'COMBO143_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO144_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Processo',
                        name: 'COMBO144_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO148_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Base giuridica',
                        name: 'COMBO148_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO149_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Legittimo Interesse',
                        name: 'COMBO149_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO150_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità di raccolta del consenso',
                        name: 'COMBO150_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO152_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità Finalità',
                        name: 'COMBO152_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO157_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità dato',
                        name: 'COMBO157_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO160_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Impatto: Valore **',
                        name: 'COMBO160_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO162_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Formato',
                        name: 'COMBO162_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO163_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Periodo di conservazione',
                        name: 'COMBO163_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO166_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Persona Fisica/Giuridica',
                        name: 'COMBO166_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO171_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cancellazione: Automatica',
                        name: 'COMBO171_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO175_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Categoria Sistema',
                        name: 'COMBO175_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO176_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipologia',
                        name: 'COMBO176_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO182_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Archivio Cartaceo',
                        name: 'COMBO182_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO184_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trasferimento all\'Estero',
                        name: 'COMBO184_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO185_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Paese Destinazione',
                        name: 'COMBO185_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO186_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo Garanzia se il Paese è extra UE',
                        name: 'COMBO186_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO201_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Status del rapporto',
                        name: 'COMBO201_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO204_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trasferimento dati Extra AEE?',
                        name: 'COMBO204_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO236_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Stato implementazione',
                        name: 'COMBO236_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO245_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Volume dei dati personali oggetto di violazione',
                        name: 'COMBO245_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO246_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Numero (anche approssimativo) di interessati coinvolti nella violazione',
                        name: 'COMBO246_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO247_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Natura della violazione',
                        name: 'COMBO247_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO248_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Causa della violazione',
                        name: 'COMBO248_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO255_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Comunicazione al Garante',
                        name: 'COMBO255_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO256_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo di notifica',
                        name: 'COMBO256_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO257_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Comunico la violazione agli interessati?',
                        name: 'COMBO257_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO258_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'La violazione è stata comunicata agli interessati?',
                        name: 'COMBO258_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO262_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Violazione ancora in corso?',
                        name: 'COMBO262_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO267_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'La violazione è stata notificata ad altre autorità di controllo?',
                        name: 'COMBO267_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO268_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'La violazione è stata notificata ad altri organismi di vigilanza o di controllo in virtù di ulteriori disposizioni normative?',
                        name: 'COMBO268_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO269_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'E’ stata effettuata una segnalazione all’autorità giudiziaria o di polizia?',
                        name: 'COMBO269_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO277_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Stato',
                        name: 'COMBO277_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO281_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'La violazione coinvolge interessati di altri Paesi del\'EEA?',
                        name: 'COMBO281_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO282_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'La violazione coinvolge interessati di Paesi non appartenenti all\'EEA?',
                        name: 'COMBO282_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO285_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ruolo ricoperto dall\'azienda',
                        name: 'COMBO285_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO302_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Provincia',
                        name: 'COMBO302_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO305_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ora di rilevazione dell\'evento',
                        name: 'COMBO305_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO309_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo Data Breach',
                        name: 'COMBO309_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO311_23',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità Sistema : Valore',
                        name: 'COMBO311_23',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO319_10',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tempistiche per la notifica di un data breach',
                        name: 'COMBO319_10',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO321_16',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Servizi',
                        name: 'COMBO321_16',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO336_32',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ruolo dell\'Azienda',
                        name: 'COMBO336_32',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO355_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Livello di Sicurezza richiesto',
                        name: 'COMBO355_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO364_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo Nomina',
                        name: 'COMBO364_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO371_35',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo Documento',
                        name: 'COMBO371_35',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO372_35',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome MODELLO Base',
                        name: 'COMBO372_35',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO374_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ruolo del Fornitore',
                        name: 'COMBO374_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO375_30',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Categoria',
                        name: 'COMBO375_30',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO376_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Lingua',
                        name: 'COMBO376_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO385_35',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Livello di Sicurezza(Nomina)',
                        name: 'COMBO385_35',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO386_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipologia di Cloud',
                        name: 'COMBO386_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO387_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipologia di servizi Cloud',
                        name: 'COMBO387_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO388_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ubicazione',
                        name: 'COMBO388_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO389_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Gestione in carico a',
                        name: 'COMBO389_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO390_35',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Raggruppamento ',
                        name: 'COMBO390_35',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO403_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Categorie di software',
                        name: 'COMBO403_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO408_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ragione Sociale dell\'Azienda',
                        name: 'COMBO408_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO418_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Processo decisionale automatizzato',
                        name: 'COMBO418_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO439_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'L\'interessato può disattivare il \nsistema',
                        name: 'COMBO439_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO450_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Considerazioni',
                        name: 'COMBO450_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO451_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Consultazione parti interessate',
                        name: 'COMBO451_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO456_32',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Necessaria DPIA',
                        name: 'COMBO456_32',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO457_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: '\nProbabilità di danno',
                        name: 'COMBO457_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO458_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Gravità del danno',
                        name: 'COMBO458_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO483_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Risposta all\'interessato',
                        name: 'COMBO483_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO489_53',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo di documento',
                        name: 'COMBO489_53',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO500_54',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamento Condiviso',
                        name: 'COMBO500_54',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO514_57',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Effetto sul rischio',
                        name: 'COMBO514_57',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO515_57',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischio residuo',
                        name: 'COMBO515_57',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO516_57',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure approvate',
                        name: 'COMBO516_57',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO521_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Provincia Cliente',
                        name: 'COMBO521_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO522_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Stato Cliente',
                        name: 'COMBO522_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO526_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Vuoi caricare da file?',
                        name: 'COMBO526_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO532_44',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ruolo del cliente',
                        name: 'COMBO532_44',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO539_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Provincia dell\'Azienda',
                        name: 'COMBO539_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO540_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Stato dell\'Azienda',
                        name: 'COMBO540_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO557_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Location',
                        name: 'COMBO557_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO572_70',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'La violazione riguarda la tua area?',
                        name: 'COMBO572_70',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO576_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'E\' un data breach?',
                        name: 'COMBO576_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO585_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Gravità della violazione',
                        name: 'COMBO585_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO597_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Livello Misure di Sicurezza',
                        name: 'COMBO597_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO598_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'DPIA approvata?',
                        name: 'COMBO598_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO601_76',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipologia di Utente per Task',
                        name: 'COMBO601_76',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO623_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Notifica al Garante?',
                        name: 'COMBO623_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO624_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Notifica ai Titolari?',
                        name: 'COMBO624_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO627_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo di notifica',
                        name: 'COMBO627_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO632_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Area di competenza',
                        name: 'COMBO632_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'COMBO652_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Esistono trattamenti in essere?',
                        name: 'COMBO652_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA207_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data ultimo audit',
                        name: 'DATA207_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA239_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data di rilevazione dell\'evento',
                        name: 'DATA239_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA261_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data violazione',
                        name: 'DATA261_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA263_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data inizio violazione',
                        name: 'DATA263_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA264_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data fine violazione',
                        name: 'DATA264_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA306_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data scadenza piano miglioramento',
                        name: 'DATA306_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA365_30',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Inizio Validità',
                        name: 'DATA365_30',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA366_30',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Fine Validità',
                        name: 'DATA366_30',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA367_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Invio',
                        name: 'DATA367_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA368_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Ultimo Sollecito ',
                        name: 'DATA368_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA370_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Conferma',
                        name: 'DATA370_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA412_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data di nascita',
                        name: 'DATA412_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA416_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Richiesta pervenuta in data',
                        name: 'DATA416_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA426_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data di redazione',
                        name: 'DATA426_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA477_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data della risposta',
                        name: 'DATA477_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'DATA496_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data della nomina ',
                        name: 'DATA496_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI154_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati del trattamento',
                        name: 'MULTI154_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI155_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati personali trattati',
                        name: 'MULTI155_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI167_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Responsabili trattamento',
                        name: 'MULTI167_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI169_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Contitolari',
                        name: 'MULTI169_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI170_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Autorizzati',
                        name: 'MULTI170_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI174_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sistemi Utilizzati',
                        name: 'MULTI174_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI180_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza adottate',
                        name: 'MULTI180_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI193_16',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Titolare del trattamento',
                        name: 'MULTI193_16',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI202_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Standard applicati',
                        name: 'MULTI202_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI205_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Paese di destinazione',
                        name: 'MULTI205_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI249_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Possibili conseguenze della violazione - In caso di perdita di confidenzialità:',
                        name: 'MULTI249_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI252_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Danno',
                        name: 'MULTI252_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI253_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Potenziali effetti negativi per gli interessati',
                        name: 'MULTI253_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI271_16',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati',
                        name: 'MULTI271_16',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI274_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Possibili conseguenze della violazione - In caso di perdita di integrità:',
                        name: 'MULTI274_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI275_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Possibili conseguenze della violazione - In caso di perdita di disponibilità: ',
                        name: 'MULTI275_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI284_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti Coinvolti - Elenco',
                        name: 'MULTI284_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI288_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: '*Dati personali trattati',
                        name: 'MULTI288_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI290_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: '*Interessato (Titolare)',
                        name: 'MULTI290_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI291_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: '*Interessato (Resp.)',
                        name: 'MULTI291_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI292_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: '*Sistemi Utilizzati',
                        name: 'MULTI292_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI294_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: '*Misure di sicurezza adottate',
                        name: 'MULTI294_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI317_19',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo di garanzia se il Paese di destinazione è extra UE',
                        name: 'MULTI317_19',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI346_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'TRATTAMENTI REG.TITOLARE',
                        name: 'MULTI346_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI347_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'TRATTAMENTI REG.RESPONSABILE',
                        name: 'MULTI347_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI394_32',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Fonte dei dati',
                        name: 'MULTI394_32',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI399_47',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Componenti:',
                        name: 'MULTI399_47',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI404_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione breve misure di sicurezza tecnologiche implementate',
                        name: 'MULTI404_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI421_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Operazione richiesta',
                        name: 'MULTI421_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI432_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipologia dati raccolti',
                        name: 'MULTI432_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI465_32',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sistema utilizzato per il trattamento',
                        name: 'MULTI465_32',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI482_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Motivazioni diniego',
                        name: 'MULTI482_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI498_51',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ambito',
                        name: 'MULTI498_51',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI499_51',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ruolo del nominato',
                        name: 'MULTI499_51',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI513_57',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza da implementare',
                        name: 'MULTI513_57',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI563_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischi',
                        name: 'MULTI563_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI564_39',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'ID Archivi Cartacei',
                        name: 'MULTI564_39',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI571_66',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Certificazioni applicate',
                        name: 'MULTI571_66',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI581_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Natura della violazione',
                        name: 'MULTI581_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI587_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipo di violazione',
                        name: 'MULTI587_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI611_31',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Personal Data Multivalue',
                        name: 'MULTI611_31',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI612_31',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Subject Multivalue',
                        name: 'MULTI612_31',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI613_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Processing Activity Multi',
                        name: 'MULTI613_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI617_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Inserisci i Sistemi oggetto della violazione:',
                        name: 'MULTI617_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI618_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Inserisci gli Archivi oggetto della violazione:',
                        name: 'MULTI618_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI619_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Inserisci il Fornitore che ha inviato la segnalazione:',
                        name: 'MULTI619_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI629_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione breve misure di sicurezza fisiche implementate',
                        name: 'MULTI629_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'MULTI640_66',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione breve delle misure Organizzative',
                        name: 'MULTI640_66',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC151_14',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'TAB Valore Numerico',
                        name: 'NUMERIC151_14',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC153_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità Finalità: Valore ',
                        name: 'NUMERIC153_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC158_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità Dato: Valore',
                        name: 'NUMERIC158_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC159_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Impatto: Valore Calcolato',
                        name: 'NUMERIC159_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC259_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Numero di interessati a cui è stata comunicata la violazione',
                        name: 'NUMERIC259_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC338_31',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Riferimento ID',
                        name: 'NUMERIC338_31',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC478_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Probabilità di danno Numerico',
                        name: 'NUMERIC478_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC479_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Gravità di danno Numerico',
                        name: 'NUMERIC479_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC480_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischio complessivo Numerico',
                        name: 'NUMERIC480_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC486_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Numero misure di sicurezza',
                        name: 'NUMERIC486_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC488_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Livello sicurezza reale',
                        name: 'NUMERIC488_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC494_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischio complessivo medio numerico',
                        name: 'NUMERIC494_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC535_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Docnumber riferimento trattamento',
                        name: 'NUMERIC535_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC588_71',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Docnumber registro',
                        name: 'NUMERIC588_71',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC608_77',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Docnumber Trattamento',
                        name: 'NUMERIC608_77',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'NUMERIC615_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Controllo',
                        name: 'NUMERIC615_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE315_23',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sistema ( x ricerca )',
                        name: 'TABLE315_23',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE329_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ricerca Cod.Anagrafica',
                        name: 'TABLE329_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE332_23',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure Sicurezza',
                        name: 'TABLE332_23',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE343_31',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cod.Trattamento',
                        name: 'TABLE343_31',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE384_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ragione Sociale Cliente (Se Sub-Responsabile)',
                        name: 'TABLE384_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE392_42',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ricerca Codice Cliente',
                        name: 'TABLE392_42',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE508_56',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamento autorizzato',
                        name: 'TABLE508_56',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE511_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischio',
                        name: 'TABLE511_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE527_58',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ragione Sociale Fornitore sub-responsabile',
                        name: 'TABLE527_58',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE555_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome del Trattamento',
                        name: 'TABLE555_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE561_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Fornitore (Se Esterno):',
                        name: 'TABLE561_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE600_76',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Utente',
                        name: 'TABLE600_76',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TABLE606_77',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamento',
                        name: 'TABLE606_77',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO141_13',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: 'SAP_TABCLI_DESCRIZIONE',
                        label: 'TAB-Descrizione',
                        name: 'TESTO141_13',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO145_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Processo: Owner (Ruolo Aziendale)',
                        name: 'TESTO145_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO147_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamento: Descrizione Breve',
                        name: 'TESTO147_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO156_13',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'TAB Valore Alfabetico',
                        name: 'TESTO156_13',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO164_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ragione Sociale',
                        name: 'TESTO164_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO165_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cancellazione: Descrizione Modalità',
                        name: 'TESTO165_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO172_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Titolari autonomi',
                        name: 'TESTO172_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO173_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome Componente',
                        name: 'TESTO173_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO178_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Categoria',
                        name: 'TESTO178_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO183_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Archivio Cartaceo : Locazione',
                        name: 'TESTO183_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO190_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ubicazione',
                        name: 'TESTO190_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO192_10',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Codice',
                        name: 'TESTO192_10',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO195_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Processo: Descrizione',
                        name: 'TESTO195_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO196_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Indirizzo',
                        name: 'TESTO196_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO198_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Città',
                        name: 'TESTO198_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO203_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Breve descrizione del servizio',
                        name: 'TESTO203_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO210_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Amministratori di Sistema Interni',
                        name: 'TESTO210_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO219_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Subfornitori dichiarati',
                        name: 'TESTO219_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO227_10',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'DPO Ragione Sociale o Nome e Cognome',
                        name: 'TESTO227_10',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO230_10',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'DPO E-mail',
                        name: 'TESTO230_10',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO233_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Partita Iva',
                        name: 'TESTO233_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO234_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Funzione del sistema',
                        name: 'TESTO234_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO235_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza',
                        name: 'TESTO235_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO237_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Piano di miglioramento',
                        name: 'TESTO237_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO238_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Responsabile',
                        name: 'TESTO238_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO241_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Breve descrizione della violazione',
                        name: 'TESTO241_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO242_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione dell\'incidente di sicurezza alla base della violazione',
                        name: 'TESTO242_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO243_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ulteriori considerazioni sulle possibili conseguenze',
                        name: 'TESTO243_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO244_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure tecniche e organizzative adottate per porre rimedio alla violazione',
                        name: 'TESTO244_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO254_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure tecniche e organizzative per prevenire violazioni future',
                        name: 'TESTO254_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO260_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Canale utilizzato per la comunicazione ',
                        name: 'TESTO260_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO265_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ulteriori informazioni circa le date in cui è avvenuta la violazione',
                        name: 'TESTO265_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO266_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'In caso di notifica oltre le 72 ore, quali sono i motivi del ritardo?',
                        name: 'TESTO266_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO273_15',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Impatto',
                        name: 'TESTO273_15',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO276_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cap',
                        name: 'TESTO276_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO278_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità con la quale si è venuti a conoscenza della violazione',
                        name: 'TESTO278_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO279_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Non sarà comunicata perché: ',
                        name: 'TESTO279_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO280_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Numero notifica fascicolo',
                        name: 'TESTO280_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO283_17',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ulteriori dettagli circa le categorie di interessati',
                        name: 'TESTO283_17',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO303_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cognome',
                        name: 'TESTO303_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO304_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome',
                        name: 'TESTO304_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO308_18',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Motivazione della decisione',
                        name: 'TESTO308_18',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO312_23',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità Sistema : Descrizione',
                        name: 'TESTO312_23',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO313_23',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Criticità Sistema : Livello Sicurezza',
                        name: 'TESTO313_23',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO318_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità di Adozione',
                        name: 'TESTO318_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO320_23',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Amministratori di Sistema Esterni',
                        name: 'TESTO320_23',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO322_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'E-mail/PEC',
                        name: 'TESTO322_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO325_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cod.Fiscale',
                        name: 'TESTO325_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO335_32',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome del Trattamento',
                        name: 'TESTO335_32',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO341_32',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Codice Trattamento',
                        name: 'TESTO341_32',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO348_30',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Alias',
                        name: 'TESTO348_30',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO349_30',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome Contatto',
                        name: 'TESTO349_30',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO352_30',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Altra E-Mail',
                        name: 'TESTO352_30',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO356_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Telefono',
                        name: 'TESTO356_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO357_9',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cellulare',
                        name: 'TESTO357_9',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO358_31',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati',
                        name: 'TESTO358_31',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO359_31',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati',
                        name: 'TESTO359_31',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO369_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Note',
                        name: 'TESTO369_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO395_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome del software',
                        name: 'TESTO395_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO396_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Scopo del software',
                        name: 'TESTO396_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO406_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Livello di sicurezza applicato',
                        name: 'TESTO406_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO407_44',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'DPO n. di telefono',
                        name: 'TESTO407_44',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO409_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome',
                        name: 'TESTO409_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO410_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cognome',
                        name: 'TESTO410_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO411_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Luogo di nascita',
                        name: 'TESTO411_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO413_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Comune',
                        name: 'TESTO413_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO414_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Precisazioni',
                        name: 'TESTO414_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO415_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Luogo ',
                        name: 'TESTO415_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO417_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Origine dei dati',
                        name: 'TESTO417_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO422_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Motivazione della richiesta',
                        name: 'TESTO422_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO423_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Riferimenti del soggetto al quale verranno\ntrasmessi i dati (in caso di richiesta di \nportabilità)',
                        name: 'TESTO423_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO425_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome del progetto e descrizione',
                        name: 'TESTO425_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO427_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione del progetto',
                        name: 'TESTO427_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO428_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità di raccolta dati',
                        name: 'TESTO428_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO429_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità di conservazione dati',
                        name: 'TESTO429_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO430_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Condivisione dei dati',
                        name: 'TESTO430_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO431_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione rischi per \nl\'interessato',
                        name: 'TESTO431_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO433_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Frequenza conservazione',
                        name: 'TESTO433_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO434_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Numero individui soggetti al \nmonitoraggio',
                        name: 'TESTO434_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO435_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Area geografica coperta dal \ntrattamento',
                        name: 'TESTO435_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO436_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Categorie di interessati a rischio',
                        name: 'TESTO436_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO437_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione del rapporto con gli\ninteressati',
                        name: 'TESTO437_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO438_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità per informare \nl\'interessato',
                        name: 'TESTO438_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO440_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Se SI, descrivere le modalità',
                        name: 'TESTO440_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO441_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Se NO, descrivere quali modalità\nsono adottate per l\'esercizio dei\ndiritti dell\'interessato',
                        name: 'TESTO441_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO442_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Finalità del trattamento',
                        name: 'TESTO442_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO444_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrivere come e quando\nvengono consultati gli interessati',
                        name: 'TESTO444_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO445_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione principio di \nminimizzazione',
                        name: 'TESTO445_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO446_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Come viene fornita l\'informativa?',
                        name: 'TESTO446_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO447_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure a supporto degli interessati',
                        name: 'TESTO447_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO448_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrivere le modalità di verifica della compliance dei\nResponsabili del trattamento \n(D.P.O.)',
                        name: 'TESTO448_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO449_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Analisi dei risultati della DPIA',
                        name: 'TESTO449_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO453_47',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Livello medio di sicurezza',
                        name: 'TESTO453_47',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO462_19',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ragione sociale Contitolare',
                        name: 'TESTO462_19',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO463_47',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'ID-SISAPP (Servizi)',
                        name: 'TESTO463_47',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO466_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Scopo del trattamento',
                        name: 'TESTO466_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO467_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Obiettivi di business da raggiungere',
                        name: 'TESTO467_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO469_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Tipologia documento',
                        name: 'TESTO469_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO470_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Estremi del documento',
                        name: 'TESTO470_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO471_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ulteriori dettagli (in caso di processo decisionale automatizzato)',
                        name: 'TESTO471_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO481_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischio Complessivo',
                        name: 'TESTO481_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO490_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Rischio Complessivo Medio',
                        name: 'TESTO490_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO495_46',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza tecnologiche applicate:',
                        name: 'TESTO495_46',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO501_54',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati del trattamento',
                        name: 'TESTO501_54',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO502_54',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati dal trattamento',
                        name: 'TESTO502_54',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO510_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'E-mail',
                        name: 'TESTO510_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO512_52',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Codice rischio',
                        name: 'TESTO512_52',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO518_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Indirizzo Cliente',
                        name: 'TESTO518_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO519_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'CAP Cliente',
                        name: 'TESTO519_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO520_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Città Cliente',
                        name: 'TESTO520_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO525_20',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati importati',
                        name: 'TESTO525_20',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO528_58',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti del Fornitore',
                        name: 'TESTO528_58',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO529_58',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati dai Trattamenti',
                        name: 'TESTO529_58',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO530_58',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati dei Trattamenti',
                        name: 'TESTO530_58',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO536_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'PIVA dell\'Azienda',
                        name: 'TESTO536_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO537_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Indirizzo dell\'Azienda',
                        name: 'TESTO537_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO538_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'CAP dell\'Azienda',
                        name: 'TESTO538_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO541_24',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Città dell\'Azienda',
                        name: 'TESTO541_24',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO542_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati dal Trattamenti',
                        name: 'TESTO542_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO543_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati del Trattamento',
                        name: 'TESTO543_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO544_50',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Durata della conservazione',
                        name: 'TESTO544_50',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO547_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Fonte dei dati',
                        name: 'TESTO547_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO548_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sistemi utilizzati',
                        name: 'TESTO548_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO549_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Paese di destinazione',
                        name: 'TESTO549_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO550_61',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sub-responsabili del trattamento',
                        name: 'TESTO550_61',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO551_60',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Responsabili del trattamento',
                        name: 'TESTO551_60',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO552_60',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Contitolari',
                        name: 'TESTO552_60',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO554_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione della richiesta',
                        name: 'TESTO554_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO556_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Identificativo Archivio',
                        name: 'TESTO556_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO558_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sede di (Se Interno):',
                        name: 'TESTO558_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO559_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Piano (Se Interno):',
                        name: 'TESTO559_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO560_65',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ufficio (Se Interno):',
                        name: 'TESTO560_65',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO565_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Archivi Cartacei',
                        name: 'TESTO565_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO566_39',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data processing Activity',
                        name: 'TESTO566_39',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO567_39',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Personal Data',
                        name: 'TESTO567_39',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO568_39',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Subject',
                        name: 'TESTO568_39',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO569_39',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Data Retention',
                        name: 'TESTO569_39',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO570_28',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione Inglese',
                        name: 'TESTO570_28',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO573_70',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Informazioni preliminari',
                        name: 'TESTO573_70',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO577_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti Registro Responsabile',
                        name: 'TESTO577_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO578_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti Registro Titolare',
                        name: 'TESTO578_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO579_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza adottate sui sistemi',
                        name: 'TESTO579_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO580_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza adottate per l\'archivio cartaceo',
                        name: 'TESTO580_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO582_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Possibili conseguenze della violazione - In caso di perdita di confidenzialità: Altro',
                        name: 'TESTO582_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO583_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Possibili conseguenze della violazione - In caso di perdita di integrità: Altro:',
                        name: 'TESTO583_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO584_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Possibili conseguenze della violazione - In caso di perdita di disponibilità: Altro:',
                        name: 'TESTO584_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO586_61',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Titolari del Trattamento',
                        name: 'TESTO586_61',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO589_75',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Titolare del Trattamento',
                        name: 'TESTO589_75',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO590_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome',
                        name: 'TESTO590_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO591_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Cognome',
                        name: 'TESTO591_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO593_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Recapito telefonico',
                        name: 'TESTO593_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO594_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Funzione rivestita',
                        name: 'TESTO594_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO595_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Numero di protocollo del DPO',
                        name: 'TESTO595_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO596_64',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Email',
                        name: 'TESTO596_64',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO599_44',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ragione Sociale Cliente',
                        name: 'TESTO599_44',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO603_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione Breve Misura di Sicurezza',
                        name: 'TESTO603_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO604_22',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Codice Misura di Sicurezza',
                        name: 'TESTO604_22',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO605_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ulteriori operazioni richieste',
                        name: 'TESTO605_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO609_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti da responsabile',
                        name: 'TESTO609_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO610_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Titolare del trattamento',
                        name: 'TESTO610_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO614_44',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'E-mail Cliente',
                        name: 'TESTO614_44',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO616_47',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nome Sistema\\Software',
                        name: 'TESTO616_47',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO620_77',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Fonte del dato',
                        name: 'TESTO620_77',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO621_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza adottate sui sistemi',
                        name: 'TESTO621_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO622_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza adottate per l\'archivio cartaceo',
                        name: 'TESTO622_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO625_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Ruolo',
                        name: 'TESTO625_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO626_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'DPO Recapito telefonico',
                        name: 'TESTO626_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO628_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di sicurezza fisiche applicate',
                        name: 'TESTO628_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO630_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Elenco codici implementati Tecnologici',
                        name: 'TESTO630_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO631_21',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Elenco codici implementati Fisici',
                        name: 'TESTO631_21',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO633_77',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Motivazione conferma',
                        name: 'TESTO633_77',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO634_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità di attuazione della richiesta',
                        name: 'TESTO634_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO635_49',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Modalità di accesso ai dati in caso di portabilità',
                        name: 'TESTO635_49',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO636_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Dati',
                        name: 'TESTO636_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO637_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Interessati',
                        name: 'TESTO637_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO638_78',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Varibile con tutte i campi',
                        name: 'TESTO638_78',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO639_66',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure di Sicurezza Organizzative applicate',
                        name: 'TESTO639_66',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO641_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sistemi Utilizzati (ricerca)',
                        name: 'TESTO641_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO642_59',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Archivi Cartacei (ricerca)',
                        name: 'TESTO642_59',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO643_61',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Titolari del Trattamento (ricerca)',
                        name: 'TESTO643_61',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO644_61',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Sub-responsabili del trattamento (ricerca)',
                        name: 'TESTO644_61',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO645_60',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Contitolari (ricerca)',
                        name: 'TESTO645_60',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO646_60',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Responsabili del trattamento (ricerca)',
                        name: 'TESTO646_60',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO647_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure organizzative dell\'Azienda',
                        name: 'TESTO647_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO648_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Trattamenti coinvolti',
                        name: 'TESTO648_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO649_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Durata dell\'incidente',
                        name: 'TESTO649_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO650_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione delle categorie di dati personali oggetto della violazione',
                        name: 'TESTO650_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO651_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Descrizione dei sistemi e delle infrastrutture IT coinvolti nell\'incidente , con l\'ubicazione',
                        name: 'TESTO651_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO653_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Indicare le motivazioni della gravità della violazione',
                        name: 'TESTO653_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO654_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Contenuto della comunicazione',
                        name: 'TESTO654_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO655_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Email dell\'Azienda',
                        name: 'TESTO655_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO656_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Telefono Azienda',
                        name: 'TESTO656_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO657_64',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Telefono',
                        name: 'TESTO657_64',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO658_69',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Altro soggetto da contattare',
                        name: 'TESTO658_69',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO659_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Provincia dell\'Azienda',
                        name: 'TESTO659_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO660_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Nazione dell\'Azienda',
                        name: 'TESTO660_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO661_66',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Misure per utilizzo',
                        name: 'TESTO661_66',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO662_68',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Certificazioni Azienda',
                        name: 'TESTO662_68',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    },
                    {
                        sqlSelectField: 'TESTO663_33',
                        toCalculate: false,
                        index: 0,
                        selected: false,
                        fieldType: 2,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: '',
                        label: 'Non Risultano Ulteriori Trattamenti Assegnati al Fornitore',
                        name: 'TESTO663_33',
                        userSelectionEnabled: true,
                        userSelectionGroup: 2
                    }
                ],
                maxItems: 0
            }
        }
    }


}
