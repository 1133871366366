<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">
    <div style="display: flex;">
      <button style="float:right; color:{{this.dashColor}};" title="Profila" (click)="saveMask()" mat-icon-button
              aria-label="icon-button send">
        <mat-icon>save</mat-icon>
      </button>
      <button style="float:right; color:{{this.dashColor}};" title="Desk"
              (click)="showDesk()" mat-icon-button>
        <mat-icon>computer</mat-icon>
      </button>
      <button *ngIf="optionsMask.showNotes" style="float:right; color:{{this.dashColor}};" title="Note"
              (click)="showNotes()" mat-icon-button>
        <mat-icon>note</mat-icon>
      </button>
      <button *ngIf="badgeBufferMain >0" style="float:right; color:{{this.dashColor}};"
              [matMenuTriggerFor]="menuFile"
              aria-label="Vedi File"
              mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menuFile="matMenu">
        <ng-container *ngFor="let file of bufferIdInfo">
          <button mat-menu-item (click)="removeFile(file)">
            <mat-icon>close</mat-icon>
            <span style="font-size: 16px; font-weight: 500;">{{file.name}}</span>
          </button>
        </ng-container>
      </mat-menu>
      <button style="float:right; color:{{this.dashColor}};"
              title="Carica file"
              (click)="fileInput.click()"
              mat-icon-button>
        <mat-icon [matBadge]="badgeBufferMain">cloud_upload</mat-icon>
      </button>
      <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload" name="fileUpload"
             multiple="multiple" accept="*"/>


      <h3 class="titles" style="margin-block-end: 0px; color:{{this.dashColor}};text-align: right;
    width: 100%;">
        <mat-card-title>
          <p>
            <ng-container *ngIf="this.idTask != '0'"><b>Inserimento in task {{this.idTask}}</b>
            </ng-container>

          </p>
        </mat-card-title>
      </h3>

    </div>
    <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
    <br>
    <mat-card-content>
      <ng-container [ngSwitch]="this.whatToShow">
        <ng-container *ngSwitchCase="'default'">
          <form class="flexform" (input)="checkFormula()">
            <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
            <ng-container *ngFor="let field of fields">
              <!--mat-grid-tile [colspan]="4"
                             [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                             *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
              <ng-container [ngSwitch]="field.className"
                            *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                            style="height:fit-content; width: 100%;">
                <!-- NumberFieldDTO -->
                <mat-form-field *ngSwitchCase="'NumberFieldDTO'" class="form-field"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [ngModelOptions]="{standalone: true}" [id]="field.name"
                         [(ngModel)]="dataMaskToSave[field.name]"
                         [required]="field.required"
                         [disabled]="field.locked" [value]="field.value || ''"
                         maxlength="{{field.numMaxChar || 100}}"
                         matInput placeholder="{{field.description}}">
                </mat-form-field>
                <!-- DocumentDateFieldDTO -->
                <mat-form-field *ngSwitchCase="'DocumentDateFieldDTO'" class="form-field"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input style="color:#29648A;" matInput [matDatepicker]="picker"
                         [(ngModel)]="dataMaskToSave[field.name]"
                         [ngModelOptions]="{standalone: true}"
                         [value]="field.value || ''"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'AdditionalFieldDoubleDTO'" class="form-field"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [id]="field.name" type="number" [required]="field.required"
                         [disabled]="field.locked"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>
                <!-- SubjectFieldDTO -->
                <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" class="form-field"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [ngModelOptions]="{standalone: true}" [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>
                <!-- ToFieldDTO -->
                <mat-form-field (click)="openAddressBook(field)" *ngSwitchCase="'ToFieldDTO'"
                                class="form-field" appearance="outline">
                  <!--A-->
                  <mat-label>{{field.description}}</mat-label>
                  <textarea class="style-4"
                            [value]="field.value || ''"
                            [required]="field.required" [disabled]="field.locked"
                            maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                            style="resize: none;width:95%;"
                            matInput [value]="this.mapAddressBookToSee.get(field.name)"
                  ></textarea>
                  <button mat-button matSuffix mat-icon-button style="color: {{this.apiService.dashColor}}">
                    <mat-icon>notes</mat-icon>
                  </button>
                </mat-form-field>

                <!-- FromFieldDTO -->
                <mat-form-field (click)="openAddressBook(field)" *ngSwitchCase="'FromFieldDTO'"
                                class="form-field" appearance="outline">
                  <!--DA-->
                  <mat-label>{{field.description}}</mat-label>
                  <textarea class="style-4" matInput [value]="this.mapAddressBookToSee.get(field.name)"
                            [required]="field.required" [disabled]="field.locked"
                            maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                            style="resize: none;width:95%;"
                  ></textarea>
                  <button mat-button matSuffix mat-icon-button style="color: {{this.apiService.dashColor}}">
                    <mat-icon>notes</mat-icon>
                  </button>
                </mat-form-field>

                <!-- CcFieldDTO -->
                <mat-form-field *ngSwitchCase="'CcFieldDTO'" class="form-field" appearance="outline">
                  <!--CC-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- BusinessUnitFieldDTO -->
                <mat-form-field *ngSwitchCase="'BusinessUnitFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Aoo-->
                  <mat-label>Azienda</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                      {{aoo.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- DocumentTypeFieldDTO -->
                <div *ngSwitchCase="'DocumentTypeFieldDTO'" class="form-field">


                  <mat-form-field class="form-field-docType" style="width: 80%"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <mat-select [value]="field.value || ''" [id]="field.name"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                [disabled]="field.locked"
                    >
                      <mat-option
                        (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                        *ngFor="let documenType of documenTypes" [value]="documenType.id">
                        {{documenType.description}} - {{documenType.key}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                  <span>
                                    <button [disabled]="field.locked" matSuffix mat-button
                                            style="width: 20%;display: flex; justify-content: center;"
                                            style="color: {{this.dashColor}};float: right;"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                </span>
                </div>

                <!-- OriginFieldDTO -->
                <mat-form-field *ngSwitchCase="'OriginFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Origine-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let originField of originsFields"
                                [value]="originField.value">
                      {{originField.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- StateFieldDTO -->
                <mat-form-field *ngSwitchCase="'StateFieldDTO'" class="form-field" appearance="outline">
                  <!--Stato, dipende da documento-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let stateField of stateFields" [value]="stateField.id">
                      {{stateField.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- BinderFieldDTO -->
                <mat-form-field *ngSwitchCase="'BinderFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Pratiche-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- DocumentDateExpiredFieldDTO -->
                <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Scadenza-->
                  <mat-label>{{field.description}}</mat-label>
                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                         [matDatepicker]="picker"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>

                </mat-form-field>

                <!-- ImportantFieldDTO -->
                <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Campo "Importante"-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- SendersFieldDTO -->
                <mat-form-field *ngSwitchCase="'SendersFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Altri da-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- OriginalFieldDTO -->
                <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" class="form-field"
                                appearance="outline">
                  <!--Originale-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- AdditionalFieldGroupDTO -->
                <h3 *ngSwitchCase="'AdditionalFieldGroupDTO'" class="form-field" style="width: 90%"
                    appearance="outline">
                  <!--Campo aggiuntivo "Generale"-->
                  <br>{{field.description}}<br>
                  <hr>
                  <br>
                </h3>

                <!-- AdditionalFieldStringDTO -->
                <mat-form-field *ngSwitchCase="'AdditionalFieldStringDTO'" class="form-field"
                                appearance="outline">
                  <!--Tipo documento-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- AdditionalFieldTableDTO -->
                <mat-form-field *ngSwitchCase="'AdditionalFieldTableDTO'" class="form-field"
                                appearance="outline" (click)="openTableDTO(field)">
                  <!--TableDTO-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                  <button mat-button matSuffix mat-icon-button>
                    <mat-icon>table_chart</mat-icon>
                  </button>
                </mat-form-field>

                <!-- AdditionalFieldMultivalueDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldMultivalueDTO'">
                  <mat-form-field class="form-field"
                  >
                    <!--MultiValueDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue" [required]="field.required">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false"
                                    [disabled]="field.locked"
                                    [removable]="removable" (removed)="remove(element, field.name)">
                            {{element}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                          </mat-chip>
                        </div>
                        <div style="width: 20%; text-align: right">
                          <button matSuffix mat-icon-button (click)="openMultiValueDTO(field)"
                                  style="color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>
                  </mat-form-field>
                </ng-container>

                <!-- AdditionalFieldComboDTO -->
                <mat-form-field *ngSwitchCase="'AdditionalFieldComboDTO'" class="form-field"
                                appearance="outline" (click)="openComboValueDTO(field)">
                  <!--Tipo documento-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''" (selectionChange)="this.checkFormula()"
                         (selectionChange)="this.checkComboAssociations(field)"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                  <button mat-button matSuffix mat-icon-button>
                    <mat-icon>list</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'AdditionalFieldDateTimeDTO'" class="form-field"
                                appearance="outline">
                  <!--AdditionalFieldDateTimeDTO-->
                  <mat-label>{{field.description}}</mat-label>
                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                         [matDatepicker]="picker"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" #picker color="primary"></mat-datepicker>
                </mat-form-field>
                <!--AdditionalFieldIntDTO-->
                <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'" class="form-field"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [id]="field.name" type="number" [required]="field.required"
                         [disabled]="field.locked"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- AdditionalFieldClasseDTO -->

                <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                  <div>
                    <h4 style="float:left;">{{field.description}} </h4>
                    <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                            style="float:right; color:{{this.squareColor}};" title="Vedi maschere"
                            (click)="openClassMasks(field)" mat-icon-button
                            aria-label="icon-button send">
                      <mat-icon [matBadge]="mapClasseBookToSave.get(field.name).length">preview
                      </mat-icon>
                    </button>
                    <button style="float:right; color:{{this.squareColor}};"
                            title="Inserisci maschera"
                            (click)="insertMaskFromClassDTO(field)" mat-icon-button
                            aria-label="icon-button send">
                      <mat-icon>post_add</mat-icon>
                    </button>
                    <button style="float:right; color:{{this.squareColor}};" title="Cerca maschera"
                            (click)="searchMaskFromClassDTO(field)" mat-icon-button
                            aria-label="icon-button send">
                      <mat-icon>search</mat-icon>
                    </button>
                  </div>

                  <!--div class="z8 example-container">
                    <table mat-table [dataSource]="dataSourceClass">
                      <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                    matColumnDef="{{disCol}}">
                        <ng-container>
                          <th mat-header-cell *matHeaderCellDef
                              style="font-weight: 600; font-size:14px; color: {{this.squareColor}};">{{disCol}}</th>
                          <td mat-cell *matCellDef="let element">{{element[disCol]}}
                          </td>
                        </ng-container>
                        <mat-divider [vertical]="true"></mat-divider>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                    </table>

                  </div-->
                </div>
                <mat-form-field *ngSwitchDefault class="form-field" appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input matInput placeholder="{{field.description}}">
                </mat-form-field>
              </ng-container>
            </ng-container>
            <!--/mat-grid-list-->
            <mat-card-actions>
            </mat-card-actions>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'note'">
          <div style="color: {{this.dashColor}}; text-align: left"><h1>Note: </h1>
            <mat-form-field style="width: 90%"
                            appearance="outline">
              <mat-label>Aggiungi nota</mat-label>
              <textarea [(ngModel)]="this.addNoteText" matInput
                        placeholder="Scrivere nota qui"></textarea>
            </mat-form-field>
            <span><button mat-icon-button (click)="addNote()"><mat-icon>add</mat-icon></button></span></div>
          <br>
          <mat-selection-list #viste [multiple]="false">

            <div *ngFor="let note of this.notes">
              <button style="float:right; color:red;"
                      title="Elimina nota"
                      mat-button
                      aria-label="Elimina allegato" (click)=deleteNote(note)>
                <mat-icon>delete</mat-icon>
              </button>
              <mat-list-item style="width: 90%">
                {{note.comment}}
              </mat-list-item>


            </div>
          </mat-selection-list>
        </ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
