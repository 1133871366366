<div class="center">
    <mat-card class="cardcomponentMasks" id="style-4">
        <div style="display: flex;" *ngIf="token != null && idAoos != null">
            <button style="float:left" title="Indietro" (click) = "this.goBack()"  style="color:{{this.dashColor}};"
                    mat-icon-button
                    aria-label="icon-button send">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
        <h3 class="titles" style="color:{{this.dashColor}};">
            <mat-card-title><p>IX FE:</p></mat-card-title>
        </h3>
        <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
        <br>
        <mat-card-content>

            <!-- metti le cose qui -->
            <div *ngIf=" token == null" style="display: flex; justify-content: center;">
                <form class="example-form">
                    <mat-form-field class="example-full-width">
                        <mat-label>Nome utente</mat-label>
                        <input matInput placeholder="Nome Utente" ngModel [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="this.username" [value]="this.username">
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="Password" ngModel [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="this.password" [value]="this.password">
                    </mat-form-field>
                    <button mat-button (click)="login()" style="color: {{this.dashColor}}">Entra</button>
                </form>
            </div>

            <div *ngIf="token != null && test != 0 && idAoos === null">

                <form class="flexform">
                    <mat-form-field class="form-field"
                                    appearance="outline">
                        <mat-select (selectionChange)="setIdentificativo($event)">
                            <mat-option *ngFor="let aoo of this.test" [value]="aoo.identificativo">
                                {{aoo.nome}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            <div *ngIf="token != null && idAoos != null">
                <mat-tab-group style="width: 100%; " dynamicHeight mat-align-tabs="center">
                    <mat-tab label="Fatture Trasmesse">
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource" matSort>
                                <!-- Name Column -->
                                <ng-container matColumnDef="idFatt">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificativo IX</th>
                                    <td mat-cell *matCellDef="let element"> {{element.idFatt}} </td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="lottoIndex">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Indice Lotto</th>
                                    <td mat-cell *matCellDef="let element"> {{element.lottoIndex}} </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="docNumber">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero documento</th>
                                    <td mat-cell *matCellDef="let element"> {{element.docNumber}} </td>
                                </ng-container>
                                <ng-container matColumnDef="docDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Ricezione</th>
                                    <td mat-cell *matCellDef="let element"> {{element.docDate}} </td>
                                </ng-container>
                                <ng-container matColumnDef="elState">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Esito</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon
                                                *ngIf="element.elState === 'INELABORAZIONE' || element.elState === 'TRASMESSA'">
                                            shopping_cart
                                        </mat-icon>
                                        <mat-icon *ngIf="element.elState === 'SCARTATA'">block</mat-icon>
                                        <mat-icon
                                                *ngIf="element.elState === 'CONSEGNATA' || element.elState === 'ESITOPOSITIVO'">
                                            mail
                                        </mat-icon>
                                        <mat-icon
                                                *ngIf="element.elState === 'NONCONSEGNATA' || element.elState === 'ESITONEGATIVO' || element.elState === 'INERRORE' || element.elState === 'DECORRENZATERMINI' ">
                                            report_problem
                                        </mat-icon>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="state">s
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon *ngIf="element.state === 'PRESAINCARICO'">shopping_cart</mat-icon>
                                        <mat-icon
                                                *ngIf="element.state === 'ATTESASTORICIZZAZIONE' || element.elState === 'ATTESACONSERVAZIONE' || element.elState === 'ESECUZIONECONSERVAZIONE' || element.elState === 'ESECUZIONESTORICIZZAZIONE'">
                                            settings
                                        </mat-icon>
                                        <mat-icon
                                                *ngIf="element.state === 'CONSERVAZIONEEFFETTUATA' || element.elState === 'STORICIZZAZIONEEFFETTUATA'">
                                            save
                                        </mat-icon>
                                        <mat-icon
                                                *ngIf="element.state === 'CONSERVAZIONECONERRORI' || element.elState === 'CONSERVAZIONEFALLITA' || element.elState === 'STORICIZZAZIONEFALLITA' || element.elState === 'STORICIZZAZIONECONERRORI'">
                                            report_problem
                                        </mat-icon>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="descState">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione Stato</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="element.state === 'PRESAINCARICO'">Presa in carico</div>
                                        <div *ngIf="element.state === 'ATTESACONSERVAZIONE'">Attesa conservazione</div>
                                        <div *ngIf="element.state === 'ESECUZIONECONSERVAZIONE'">Esecuzione della
                                            conservazione
                                        </div>
                                        <div *ngIf="element.state === 'CONSERVAZIONEEFFETTUATA'">Conservazione
                                            Effettuata
                                        </div>
                                        <div *ngIf="element.state === 'CONSERVAZIONEFALLITA'">Conservazione Fallita
                                        </div>
                                        <div *ngIf="element.state === 'CONSERVAZIONECONERRORI'">Conservazione con
                                            errori
                                        </div>
                                        <div *ngIf="element.state === 'ATTESASTORICIZZAZIONE'">Attesa Storicizzazione
                                        </div>
                                        <div *ngIf="element.state === 'ESECUZIONESTORICIZZAZIONE'">Esecuzione
                                            Storicizzazione
                                        </div>
                                        <div *ngIf="element.state === 'STORICIZZAZIONEEFFETTUATA'">Storicizzazione
                                            Effettuata
                                        </div>
                                        <div *ngIf="element.state === 'STORICIZZAZIONEFALLITA'">Storicizzazione
                                            Fallita
                                        </div>
                                        <div *ngIf="element.state === 'STORICIZZAZIONECONERRORI'">Storicizzazione con
                                            Errori
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="download">
                                    <th mat-header-cell *matHeaderCellDef>File</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-button [matMenuTriggerFor]="menu">
                                            <mat-icon>cloud_download</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu" yPosition="below">
                                            <button mat-menu-item
                                                    (click)="downloadPDF(element.idFatt, element.lottoIndex, element.fileName)">
                                                PDF
                                            </button>
                                            <button mat-menu-item
                                                    (click)="downloadHTML(element.idFatt, element.fileName)">HTML
                                            </button>
                                            <button mat-menu-item
                                                    (click)="downloadFILE(element.idFatt, element.fileName)">FILE
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="notification">
                                    <th mat-header-cell *matHeaderCellDef>Notifiche</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button (click)="openDialog(element.idFatt)">
                                            <mat-icon>sms</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
                        </div>
                    </mat-tab>
                    <mat-tab label="Fatture Ricevute">
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSourceRic" matSort>
                                <!-- Name Column -->
                                <ng-container matColumnDef="idSdi">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificativo IX</th>
                                    <td mat-cell *matCellDef="let element"> {{element.idSdi}} </td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="lottoIndex">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Indice Lotto</th>
                                    <td mat-cell *matCellDef="let element"> {{element.lottoIndex}} </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="docNumber">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero documento</th>
                                    <td mat-cell *matCellDef="let element"> {{element.docNumber}} </td>
                                </ng-container>
                                <ng-container matColumnDef="stateDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Documento</th>
                                    <td mat-cell *matCellDef="let element"> {{element.stateDate}} </td>
                                </ng-container>
                                <ng-container matColumnDef="docDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Ricezione</th>
                                    <td mat-cell *matCellDef="let element"> {{element.ricDate}} </td>
                                </ng-container>
                                <ng-container matColumnDef="elState">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Esito</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon *ngIf="element.state === 'PRESAINCARICO'">shopping_cart</mat-icon>
                                        <mat-icon *ngIf="element.state === 'ATTESAESITOPOSITIVO'">help</mat-icon>
                                        <mat-icon *ngIf="element.state === 'NOTIFICAESITOPOSITIVO'">thumb_up</mat-icon>
                                        <mat-icon *ngIf="element.state === 'NOTIFICAESITONEGATIVO'">thumb_down
                                        </mat-icon>
                                        <mat-icon *ngIf="element.state === 'ATTESAESITONEGATIVO'">help_outline
                                        </mat-icon>
                                        <mat-icon *ngIf="element.state === 'NOTIFICASCARTOESITO'">block</mat-icon>
                                        <mat-icon *ngIf="element.state === 'NOTIFICADECORRENZATEMINI'">access-time
                                        </mat-icon>
                                        <mat-icon *ngIf="element.state === 'INERRORE'">highlight_off</mat-icon>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="state">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon *ngIf="element.elState === 'PRESAINCARICO'">shopping_cart</mat-icon>
                                        <mat-icon
                                                *ngIf="element.elState === 'ATTESASTORICIZZAZIONE' || element.elState === 'ATTESACONSERVAZIONE' || element.elState === 'ESECUZIONECONSERVAZIONE' || element.elState === 'ESECUZIONESTORICIZZAZIONE'">
                                            settings
                                        </mat-icon>
                                        <mat-icon
                                                *ngIf="element.elState === 'CONSERVAZIONEEFFETTUATA' || element.elState === 'STORICIZZAZIONEEFFETTUATA'">
                                            done
                                        </mat-icon>
                                        <mat-icon
                                                *ngIf="element.elState === 'CONSERVAZIONECONERRORI' || element.elState === 'CONSERVAZIONEFALLITA' || element.elState === 'STORICIZZAZIONEFALLITA' || element.elState === 'STORICIZZAZIONECONERRORI'">
                                            report_problem
                                        </mat-icon>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="descState">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione Stato</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="element.state === 'PRESAINCARICO'">Presa in carico</div>
                                        <div *ngIf="element.state === 'ATTESACONSERVAZIONE'">Attesa conservazione</div>
                                        <div *ngIf="element.state === 'ESECUZIONECONSERVAZIONE'">Esecuzione della
                                            conservazione
                                        </div>
                                        <div *ngIf="element.state === 'CONSERVAZIONEEFFETTUATA'">Conservazione
                                            Effettuata
                                        </div>
                                        <div *ngIf="element.state === 'CONSERVAZIONEFALLITA'">Conservazione Fallita
                                        </div>
                                        <div *ngIf="element.state === 'CONSERVAZIONECONERRORI'">Conservazione con
                                            errori
                                        </div>
                                        <div *ngIf="element.state === 'ATTESASTORICIZZAZIONE'">Attesa Storicizzazione
                                        </div>
                                        <div *ngIf="element.state === 'ESECUZIONESTORICIZZAZIONE'">Esecuzione
                                            Storicizzazione
                                        </div>
                                        <div *ngIf="element.state === 'STORICIZZAZIONEEFFETTUATA'">Storicizzazione
                                            Effettuata
                                        </div>
                                        <div *ngIf="element.state === 'STORICIZZAZIONEFALLITA'">Storicizzazione
                                            Fallita
                                        </div>
                                        <div *ngIf="element.state === 'STORICIZZAZIONECONERRORI'">Storicizzazione con
                                            Errori
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="download">
                                    <th mat-header-cell *matHeaderCellDef>File</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-button [matMenuTriggerFor]="menu">
                                            <mat-icon>cloud_download</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu" yPosition="below">
                                            <button mat-menu-item
                                                    (click)="downloadPDFRic(element.idFatt, element.lottoIndex, element.fileName)">
                                                PDF
                                            </button>
                                            <button mat-menu-item
                                                    (click)="downloadHTMLRic(element.idFatt, element.fileName)">HTML
                                            </button>
                                            <button mat-menu-item
                                                    (click)="downloadFILERic(element.idFatt, element.fileName)">FILE
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="notification">
                                    <th mat-header-cell *matHeaderCellDef>Notifiche</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button (click)="openDialogRic(element.idFatt)">
                                            <mat-icon>sms</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsRic"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsRic;"></tr>

                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>


        </mat-card-content>
    </mat-card>
</div>
