import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';
import {ViewBodyToSave} from '../../viewBodyToSave';
import {DialogComboDTOComponent} from '../../additionalDialog/dialogComboDTOComponents';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogAddressBookComponent} from '../../additionalDialog/dialogAddressBookComponent';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {ProgressSpinnerDialogComponent} from '../../additionalDialog/progress-spinner-dialog/progress-spinner-dialog.component';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';

@Component({
  selector: 'app-dettaglivistatask',
  templateUrl: './viewDetails.component.html',
  styleUrls: ['./viewDetails.component.css']
})
export class ViewDetailsForTaskComponent implements OnInit {
  viewName = '';
  splitViewName: string;
  fieldName = '';
  splitViewOrder: string;
  keyField = '';
  fieldDataSource: Array<object> = [];
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  splitViewCat: string;
  fields: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  breakpoint: number;
  mapTableValueToSave: any;
  id = this.activatedRoute.snapshot.paramMap.get('id');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  idTask = this.activatedRoute.snapshot.paramMap.get('idTask');
  operationId = this.activatedRoute.snapshot.paramMap.get('operationId');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  aoo: string;
  tableAssociationField: object = {};
  dataMaskToSave: any = {};
  fieldsOptions: any = {};
  jsonToSendArxivar: Array<any> = new Array<any>();
  documenTypes: any = {};
  comboValues: any = {};
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
  multiValuteDTOSelectedToShow = '';
  addressBookSelectedToShow = '';
  idContact: string;
  mapAddressBookToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookSave: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  users: Array<any> = new Array<any>();
  observable: any;

  constructor(public dialog: MatDialog, private viewBodyToSave: ViewBodyToSave, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }
  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    const subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        // handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        dialogRef.close();
      }
    );
  }
  myObservable(observer): void {
    setTimeout(() => {
      observer.next('done waiting for 5 sec');
      observer.complete();
    }, 500);
  }
  ngOnInit(): void {
    this.apiService.namePath = this.apiService.namePath.split('-')[0];
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.apiService.viewDataToShow = new Array<any>();
    this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
    this.apiService.getAoo().subscribe(datas => {
      this.nomiAoo = datas;
      // // console.log(datas);
    });
    this.apiService.getOrigins().subscribe(data => {
      this.originsFields = data;
      // console.log(this.originsFields);
    });
    this.apiService.getUsers().subscribe(data => {
      this.users = data;
      // console.log(this.users);
    });
    this.apiService.getTaskViewDetails(this.idTask, this.operationId).subscribe(data => {
      // console.log(data);
      if (data.documentType != null) {
        this.apiService.getDocTypeMode('1').subscribe(datas => {
          // console.log(datas);
          datas.forEach(v => {

            if (v.documentType === data.documentType && v.type2 === data.type2 && v.type3 === data.type3) {
              this.documenTypes = v;
            }

          });
          // console.log(this.documenTypes);
        });
      }
      this.viewName = data.description;
      this.apiService.namePath += ' - ' + this.viewName;
      this.apiService.addPath(this.viewName, 'dashboard/dashnav/viewDetails', {
        id: this.id,
        path: this.path, firstnodepath: this.firstnodepath
      });
      this.splitViewCat = this.viewName.split('-')[0];
      this.splitViewOrder = this.viewName.split('-')[1];
      this.splitViewName = this.viewName.split('-')[2];
      if (data.searchFilterDto.fields === null) {   // VISTA CON NESSUN CAMPO, QUINDI MANDA SUBITO ALLA TABELLA
        this.viewBodyToSave.noFieldsBody(this.id);
        this.apiService.viewBody = this.viewBodyToSave.bodyField;
        this.apiService.getViewFilter(this.viewBodyToSave.bodyField).subscribe(data => {
          this.apiService.viewDataToShow = data;
          this.router.navigate(['../view', {id: this.id, firstnodepath: this.path}], {relativeTo: this.route});
        });
      } else {
        this.fields = data.searchFilterDto.fields;
        // // console.log(this.fields);

        this.fields.forEach(value => {
          this.fieldsOptions[value.name] = '';
          // // console.log(value);
          if (value.name.startsWith('COMBO')) {
            this.comboValues[value.name] = this.getValues(value.name, 'COMBO');
          }
          if (value.name.startsWith('TABLE')) {
            this.comboValues[value.name] = this.getValues(value.name, 'TABLE');
          }
        });
        this.fields.forEach(val => {
          this.dataMaskToSave[val.name] = '';
        });
      }
    });
    console.log(this.fieldsOptions);
  }

  setFieldOptions(fieldName: string, option: string): void{
    this.fieldsOptions[fieldName] = option;
  }

  getValues(name: string, type: string): Array<string> {
    let partialValues = [];
    const values = [];
    this.apiService.getComboValues(name).subscribe(data => {
      // // console.log(data);
      data.dataSource.forEach((val) => {

        if (type === 'TABLE') {
          val.columns.forEach((v, index) => {
            partialValues.push(v.value + ',');

          });
          values.push(partialValues);
          partialValues = [];
        } else {
          values.push(val.columns[0].value);
        }
      });
    });
    // // console.log(values);
    return values;
  }

  cleanField(name: string): void {
    this.dataMaskToSave[name] = '';
    this.fields.forEach(val => {
      if (val.name === name) {
        val.value = '';
      }
    });
  }

  cleanFields(): void {
    this.ngOnInit();
  }

  cleanFieldContact(name: string): void {
    this.dataMaskToSave[name] = '';
    this.fields.forEach(val => {
      if (val.name === name) {
        val.value = '';
      }
    });
    this.mapAddressBookToSee.set(name, '');
    this.addressBookSelected = new Map<string, string[]>();
  }

  openTableDTO(field: any): void {
    this.fieldName = field.name;
    this.apiService.getComboValues(this.fieldName).subscribe(results => {
      this.tableAssociationField = {};
      this.tableAssociationField = Object.keys(results.associations)
        .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
      this.fieldDataSource = new Array<object>();
      if (results.dataSource != null) {
        this.keyField = results.keyField;
        results.dataSource.forEach(item => {
          this.fieldDataSource.push(item.columns);
        });
      } else {
        // EMPTY MULTI VALUE
      }
      const dialogRef = this.dialog.open(DialogTableDTOComponent, {
        id: 'style-4',
        width: '80%',
        height: '95%',
        data: {
          additionalFieldTableDTO: this.fieldDataSource,
          fieldName: field.name,
          tableValueToSelected: this.tableValuteDTOSelected,
          selectField: results.selectField
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.dataResult !== false) {
          this.mapTableValueToSave = result.dataResult;
          this.fields.forEach((item, index) => {
            if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
              this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
              this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
            }
            if (item.name === this.fieldName) {
              this.fields[index].value = result.dataResult[this.keyField];
              this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
            }
          });
        }
      });
    });
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.SearchAllAddressBook().subscribe(results => {
        this.apiService.viewDataToShow = results;
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            fieldName: field.name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE'
          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = '';
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach(val => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // console.log(res);
              if (type === '0') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }

            });
          });
          if (result.dataResult !== false) {
            result.contactsNames.forEach(item => {
              this.addressBookSelectedToShow += item + ';\n';
            });
            if (type === '0') {
              this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
            } else {
              this.dataMaskToSave[this.fieldName] = fromFieldValue;
            }
            this.addressBookSelected.set(this.fieldName, result.dataResult);
            this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
          }

          /*this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
              result.dataResult.forEach(item => {
                  this.multiValuteDTOSelectedToShow.push(item);
              });
              this.dataMaskToSave[this.fieldName] = result.dataResult;
              this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
              this.multiValuteDTOSelected.set(this.fieldName, result.dataResult);
              this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
          }*/
        });
      });
    }
  }


  setOperator(fieldName: string, operator: number): void {
    this.fields.forEach(field => {
      if (field.name === fieldName) {
        field.operator = operator;
      }
    });
  }

  filterClick(): void {
    this.observable = new Observable(this.myObservable);
    this.showProgressSpinnerUntilExecuted(this.observable);
    this.viewBodyToSave.generateDefaultFieldBody(this.id);
    if (this.documenTypes !== {}) {
      this.viewBodyToSave.pushDocumentType(this.documenTypes);
    }
    this.fields.forEach(field => {
      // console.log(this.documenTypes);

      // // console.log(this.dataMaskToSave[field.name]);FieldBaseForSearchDTO
      if (field.className === 'FieldBaseForSearchDTO') {
        this.viewBodyToSave.pushFieldForSearch(field);
      } else if (field.className === 'FieldBaseForSearchAooDto') {
        this.viewBodyToSave.pushAoo(field, this.dataMaskToSave[field.name], field.className);
      } else if (field.className === 'FieldBaseForSearchStringDto' && this.dataMaskToSave[field.name] !== '') {
        this.viewBodyToSave.pushString(field, this.dataMaskToSave[field.name], field.className);
      } else if (field.className === 'FieldBaseForSearchDateTimeDto' && this.dataMaskToSave[field.name + 'valore1']) {
        field.operator = 7;
        field.valore1 = this.TransformDataFormatToSave(this.dataMaskToSave[field.name + 'valore1']);
        if (this.dataMaskToSave[field.name + 'valore2']){
          field.valore2 = this.TransformDataFormatToSave(this.dataMaskToSave[field.name + 'valore2']) + 'T23:59:59+01:00';
        }else{
          field.valore2 = field.valore1 + 'T23:59:59+01:00';
        }


        this.viewBodyToSave.pushDate(field);
      } else if (field.className === 'FieldBaseForSearchContactDto' && this.dataMaskToSave[field.name]) {
        let val: Array<number> = new Array<number>();
        if (this.dataMaskToSave[field.name].length) {
          this.dataMaskToSave[field.name].forEach(v => {
            val.push(v.contactId);
            field.valore1.objs = [];
            field.valore1.objs.push(v);
          });
        } else {
          val.push(this.dataMaskToSave[field.name].contactId);
          field.valore1.objs = this.dataMaskToSave[field.name];
        }
        field.valore1.values = val;
        field.operator = field.defaultOperator;
        field.valore1.modality = 1;
        this.viewBodyToSave.pushContact(field);
      } else if (field.className === 'FieldBaseForSearchIntDto' && this.dataMaskToSave[field.name]) {
        field.operator = field.defaultOperator;
        field.valore1 = this.dataMaskToSave[field.name];
        this.viewBodyToSave.pushInt(field);
      }
    });
    // console.log(this.viewBodyToSave.bodyField);
    this.apiService.viewBody = this.viewBodyToSave.bodyField;
    this.apiService.getViewFilterForTask(this.viewBodyToSave.bodyField, this.idTask, this.operationId).subscribe(data => {
      this.apiService.viewDataToShow = data;
      console.log(this.apiService.namePathV2);
      this.router.navigate(['../view', {id: this.id, firstnodepath: this.path, idTask: this.idTask, operationId: this.operationId}], {relativeTo: this.route});
    });
  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '') {
      return '0001-01-01T00:00:00';
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }
  }

  goBack(): void {
    if (this.path === null) {
      this.router.navigate(['../' + this.firstnodepath, {id: this.id}], {relativeTo: this.route});
    } else {
      this.router.navigate(['../' + this.path, {id: this.id}], {relativeTo: this.route});
    }

  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }
}
