<div>
    <mat-form-field>
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Cerca..." #input>
    </mat-form-field>
    <button title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
    <button style="color:{{this.squareColor}};" title="Chiudi" (click)="saveSelectedValues()" mat-icon-button
            aria-label="icon-button save">
        <mat-icon>save</mat-icon>
    </button>
</div>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
            <ng-container *ngIf="disCol == 'select'">
                <ng-container *ngIf="!this.isFrom">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                      [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                </ng-container>
                <ng-container *ngIf="this.isFrom">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                </ng-container>

                <td mat-cell *matCellDef="let row"  >
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)"
                                  [aria-label]="checkboxLabel(row)"
                                  [disabled]="this.isFrom == true && this.selection.selected.length > 0 && this.selection.isSelected(row) == false">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container *ngIf="disCol != 'select'">
                <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
                <td mat-cell *matCellDef="let element" #tooltip="matTooltip"
                    matTooltip="{{element[disCol]}}"
                    [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"
                    matTooltipHideDelay="100">{{element[disCol]}}
                </td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 10px !important; max-height: 10px !important;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nessun record trovato con questo filtro: "{{input.value}}"</td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[14, 20, 30]" showFirstLastButtons style="float: right"></mat-paginator>
</div>
