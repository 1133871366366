<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4" style="overflow: hidden">

    <div style="display: flex; width: 100%; height: 7%">

      <div style="width: 90%; display: flex; height: 60px">
        <button style="float:right; color:{{this.dashColor}};" title="Profila" (click)="saveMask()" mat-icon-button
                aria-label="icon-button send">
          <mat-icon>save</mat-icon>
        </button>
        <button style="float:right; color:{{this.dashColor}};" title="Desk"
                (click)="showDesk()" mat-icon-button>
          <mat-icon>computer</mat-icon>
        </button>
        <button *ngIf="optionsMask.showNotes" style="float:right; color:{{this.dashColor}};" title="Note"
                (click)="showNotes()" mat-icon-button>
          <mat-icon>note</mat-icon>
        </button>
        <button *ngIf="optionsMask.showAttachments" style="float:right; color:{{this.dashColor}};"
                title="Allegati"
                (click)="showAttachments()" mat-icon-button>
          <mat-icon>attach_file</mat-icon>
        </button>
        <button *ngIf="badgeBufferMain >0" style="float:right; color:{{this.dashColor}};"
                [matMenuTriggerFor]="menuFile"
                aria-label="Vedi File"
                mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menuFile="matMenu">
          <ng-container *ngFor="let file of bufferIdInfo">
            <button mat-menu-item (click)="removeFile(file)">
              <mat-icon>close</mat-icon>
              <span style="font-size: 16px; font-weight: 500;">{{file.name}}</span>
            </button>
          </ng-container>
        </mat-menu>
        <button *ngIf="optionsMask.showFileSelection" style="float:right; color:{{this.dashColor}};"
                title="Carica file"
                (click)="fileInput.click()"
                mat-icon-button>
          <mat-icon [matBadge]="badgeBufferMain">cloud_upload</mat-icon>
        </button>
        <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload" name="fileUpload"
               multiple="multiple" accept="*"/>


        <h3 class="titles" style="margin-block-end: 0px; color:{{this.dashColor}};text-align: right; width: 100%;">
          <mat-card-title>
            <p>
              <ng-container *ngIf="this.idTask != '0'"><b>Inserimento in task {{this.idTask}}</b>
              </ng-container>

            </p>
          </mat-card-title>
        </h3>

      </div>

      <div style="width: 10%">
        <mat-progress-spinner
          class="example-margin"
          [color]="'accent'"
          diameter="50"
          style="height: 60px"
          [mode]="'determinate'"
          [value]="this.progressSpinnerValue"
          *ngIf="this.progressSpinnerValue < 95"
        > <!--  -->
        </mat-progress-spinner>
      </div>

    </div>
    <hr class="riga" style="border-top: 2px solid {{this.dashColor}};">
    <br>


    <mat-card-content style="overflow: auto; height: 90%">
      <ng-container [ngSwitch]="this.whatToShow">
        <ng-container *ngSwitchCase="'default'">
          <form class="flexform" (input)="checkInputFormula()">
            <!--mat-grid-list [cols]="breakpoint" cols="4" rowHeight="100px" (window:resize)="onResize($event)"-->
            <ng-container *ngFor="let field of fields">
              <!--mat-grid-tile [colspan]="4"
                             [rowspan]="1" class="gridtile" [ngSwitch]="field.className"
                             *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"-->
              <ng-container [ngSwitch]="field.className"
                            *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                            style="height:fit-content; width: 100%;">
                <!-- NumberFieldDTO -->
                <mat-form-field *ngSwitchCase="'NumberFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [ngModelOptions]="{standalone: true}" [id]="field.name"
                         [(ngModel)]="dataMaskToSave[field.name]"
                         [required]="field.required"
                         [disabled]="field.locked" [value]="field.value || ''"
                         maxlength="{{field.numMaxChar || 100}}"
                         matInput placeholder="{{field.description}}">
                </mat-form-field>
                <!-- DocumentDateFieldDTO -->
                <ng-container *ngSwitchCase="'DocumentDateFieldDTO'">
                  <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                           [matDatepicker]="picker"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                  </mat-form-field>
                  <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="'' || ''" matInput
                           [matDatepicker]="picker"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                  </mat-form-field>
                </ng-container>
                <!-- SubjectFieldDTO -->
                <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [ngModelOptions]="{standalone: true}" [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>
                <ng-container *ngSwitchCase="'AdditionalFieldBooleanDTO'">

                  <div style="text-align: left !important; margin-bottom: 2%; margin-top: 1%;"
                       [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                       appearance="outline">
                    <mat-slide-toggle [id]="field.name" style="color: {{this.apiService.dashColor}}" color="warn"
                                      [required]="field.required" (toggleChange)="this.checkFormula()"
                                      [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                      [disabled]="field.locked">{{field.description}}</mat-slide-toggle>
                    <!--MultiValueDTO-->
                  </div>
                </ng-container>
                <!-- ToFieldDTO -->
                <mat-form-field *ngSwitchCase="'ToFieldDTO'"
                                [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                  <!--A-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-chip-list #chipList aria-label="MultiValue">
                    <div style="width: 100%; display: flex;">
                      <div style="width: 80%">
                        <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                  [disabled]="field.locked"
                                  [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                  [value]="element.id">
                          {{element.name}}
                          <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                        </mat-chip>
                        <input
                          placeholder="Inserisci destinatario..."
                          (input)= "this.checkAutocompleteTo(field)"
                          ngModel [(ngModel)]="toCtrl" #toField
                          [matAutocomplete]="auto" matInput
                          [matChipInputFor]="chipList" [ngModelOptions]="{standalone: true}"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          >
                      </div>
                      <div style="width: 20%; text-align: right">
                        <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                style="color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                          <mat-icon>notes</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-chip-list>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="addSelected($event, field)">
                    <mat-option *ngFor="let to of toFieldData.data" [value]="to">
                      {{to[3]}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!--<ng-container *ngSwitchCase="'ToFieldDTO'">
                    <mat-form-field  [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                    >
                        <mat-label>{{field.description}}</mat-label>
                        <mat-chip-list #chipList aria-label="MultiValue" >
                            <div style = "width: 100%; display: flex;">
                                <div style = "width: 80%">
                                    <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false" [disabled]="field.locked"
                                              [removable]="removable" (removed)="remove(element, field.name)">
                                        {{element}}
                                        <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                                    </mat-chip>
                                </div>
                                <div style = "width: 20%; text-align: right">
                                    <button  matSuffix mat-icon-button (click)="openAddressBook(field)" [disabled]="field.locked">
                                        <mat-icon>notes</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-chip-list>
                    </mat-form-field>
                </ng-container>-->
                <!-- FromFieldDTO -->
                <mat-form-field  *ngSwitchCase="'FromFieldDTO'"
                                [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                  <!--DA-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-chip-list #chipList aria-label="MultiValue">
                    <div style="width: 100%; display: flex;">
                      <div style="width: 80%">
                        <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                  [disabled]="field.locked"
                                  [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                  [value]="element.id">
                          {{element.name}}
                          <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                        </mat-chip>
                        <input
                          placeholder="Inserisci destinatario..."
                          (input)= "this.checkAutocompleteTo(field)"
                          ngModel [(ngModel)]="fromCtrl" #fromField
                          [matAutocomplete]="auto" matInput
                          [matChipInputFor]="chipList" [ngModelOptions]="{standalone: true}"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        >
                      </div>
                      <div style="width: 20%; text-align: right">
                        <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                style="color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                          <mat-icon>notes</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-chip-list>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="addSelected($event, field)">
                    <mat-option *ngFor="let from of fromFieldData.data" [value]="from">
                      {{from[3]}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <!-- CcFieldDTO -->
                <mat-form-field (click)="openAddressBook(field)" *ngSwitchCase="'CcFieldDTO'"
                                [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                  <!--CC-->
                  <mat-label>{{field.description}}</mat-label>
                  <textarea class="style-4"
                            name="{{field.name}}"
                            [id]="field.name" [required]="field.required" [disabled]="field.locked"
                            maxlength="{{field.numMaxChar || 100}}" rows="3" autosize
                            style="resize: none;width:95%;"
                            matInput [value]="this.mapAddressBookToSee.get(field.name)"
                            placeholder="{{field.description}}"
                  ></textarea>
                  <button mat-button matSuffix mat-icon-button style="color: {{this.apiService.dashColor}}">
                    <mat-icon>notes</mat-icon>
                  </button>
                </mat-form-field>
                <!-- BusinessUnitFieldDTO -->
                <mat-form-field *ngSwitchCase="'BusinessUnitFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Aoo-->
                  <mat-label>Azienda</mat-label>
                  <mat-select [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                      {{aoo.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- DocumentTypeFieldDTO -->
                <mat-form-field *ngSwitchCase="'DocumentTypeFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Classe documento, dipende da "Aoo"-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let documenType of documenTypes" [value]="documenType.id">
                      {{documenType.description}} - {{documenType.key}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- OriginFieldDTO -->
                <mat-form-field *ngSwitchCase="'OriginFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Origine-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let originField of originsFields"
                                [value]="originField.value">
                      {{originField.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- StateFieldDTO -->
                <mat-form-field *ngSwitchCase="'StateFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                  <!--Stato, dipende da documento-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked">
                    <mat-option *ngFor="let stateField of stateFields" [value]="stateField.id">
                      {{stateField.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngSwitchCase="'InstructionFieldDTO'"  [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline" style = "text-align: left; color: {{this.apiService.squareColor}}" >
                  <br><p>{{field.description}}</p>
                </div>
                <!-- BinderFieldDTO -->
                <mat-form-field *ngSwitchCase="'BinderFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Pratiche-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- DocumentDateExpiredFieldDTO -->
                <ng-container *ngSwitchCase="'DocumentDateExpiredFieldDTO'">
                  <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                           [matDatepicker]="picker"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                  </mat-form-field>
                  <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="'' || ''" matInput
                           [matDatepicker]="picker"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                  </mat-form-field>
                </ng-container>

                <!-- ImportantFieldDTO -->
                <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Campo "Importante"-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- SendersFieldDTO -->
                <mat-form-field *ngSwitchCase="'SendersFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Altri da-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- OriginalFieldDTO -->
                <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                appearance="outline">
                  <!--Originale-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required" [disabled]="field.locked"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- AdditionalFieldGroupDTO -->
                <h3 *ngSwitchCase="'AdditionalFieldGroupDTO'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                    style="width: 90%; margin-bottom: 2%; color: {{this.dashColor}}"
                    appearance="outline">
                  <!--Campo aggiuntivo "Generale"-->
                  {{field.description}}<br>
                  <hr>
                </h3>

                <!-- AdditionalFieldStringDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldStringDTO'">
                  <mat-form-field
                    [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                    appearance="outline">
                    <!--Tipo documento-->
                    <mat-label>{{field.description}}</mat-label>
                    <ng-container *ngIf="field.numMaxChar > 400">
                                      <textarea ngModel [value]="field.value || ''"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [id]="field.name" [required]="field.required" [disabled]="field.locked"
                                                maxlength="{{field.numMaxChar || 100}}" matInput rows="{{field.numRows}}" autosize
                                                style="resize: none;width:95%;"
                                                placeholder="{{field.description}}"></textarea>
                    </ng-container>
                    <ng-container *ngIf="field.numMaxChar <= 400">
                      <input ngModel [value]="field.value || ''"
                             [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                             [id]="field.name" [required]="field.required"
                             [disabled]="field.locked" [ngModelOptions]="{standalone: true}"
                             maxlength="{{field.numMaxChar || 100}}" matInput
                             placeholder="{{field.description}}">
                    </ng-container>
                  </mat-form-field>
                </ng-container>
                <!-- AdditionalFieldTableDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldTableDTO'">
                  <mat-form-field
                    [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                    appearance="outline" (click)="openTableDTO(field)">
                    <!--TableDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" [disabled]="field.locked"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                    <button mat-button matSuffix mat-icon-button style="color: {{this.dashColor}}">
                      <mat-icon>table_chart</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
                <!-- AdditionalFieldMultivalueDTO -->

                <ng-container *ngSwitchCase="'AdditionalFieldMultivalueDTO'">
                  <mat-form-field [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                  >
                    <!--MultiValueDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <mat-chip-list #chipList aria-label="MultiValue" [required]="field.required">
                      <div style="width: 100%; display: flex;">
                        <div style="width: 80%">
                          <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false"
                                    [disabled]="field.locked"
                                    [removable]="removable" (removed)="remove(element, field.name)">
                            {{element}}
                            <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                          </mat-chip>
                        </div>
                        <div style="width: 20%; text-align: right; height: 60px;">
                          <button matSuffix mat-icon-button (click)="openMultiValueDTO(field)"
                                  style="color: {{this.apiService.dashColor}}" [disabled]="field.locked">
                            <mat-icon>notes</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-chip-list>
                  </mat-form-field>
                </ng-container>
                <!-- AdditionalFieldComboDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldComboDTO'">
                  <ng-container *ngIf="field.limitToList">
                    <mat-form-field
                      [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                      appearance="outline">
                      <!--Tipo documento-->
                      <mat-label>{{field.description}}</mat-label>
                      <mat-select [id]="field.name" [value]="field.value || ''"
                                  [required]="field.required" (selectionChange)="this.checkFormula()"
                                  (selectionChange)="this.checkComboAssociations(field)"
                                  [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                  [disabled]="field.locked">
                        <mat-option>--Cancella--</mat-option>
                        <mat-option *ngFor="let value of this.comboValues[field.name]"
                                    [value]="value.keyField">
                          {{value.selectField}}
                        </mat-option>

                      </mat-select>

                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!field.limitToList">

                    <mat-form-field style="width: 45%"
                                    [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                    appearance="outline">
                      <!--Tipo documento-->
                      <mat-label>{{field.description}}</mat-label>
                      <input ngModel
                             name="{{field.name}}" (ngModelChange) = "this.checkComboAssociations(field);" [(ngModel)] = "this.comboControl[field.name]"
                             [id]="field.name" [required]="field.required" (input)="checkField(field)"
                             [disabled]="field.locked" [ngModelOptions]="{standalone: true}"
                             maxlength="{{field.numMaxChar || 100}}" matInput
                             placeholder="{{field.description}}" [matAutocomplete]="auto">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this, field)">
                        <ng-container *ngFor="let value of this.comboValues[field.name]">
                          <mat-option *ngIf = "value.visible"
                                      [value]="value">
                            {{value.selectField}}
                          </mat-option>
                        </ng-container>

                      </mat-autocomplete>
                      <!--<mat-select [id]="field.name" [value]="field.value || ''"
                                  [required]="field.required" (selectionChange)="this.checkFormula()"
                                  (selectionChange)="this.checkComboAssociations(field)"
                                  [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                  [disabled]="field.locked">
                        <mat-option>--Cancella--</mat-option>
                        <mat-option *ngFor="let value of this.comboValues[field.name]"
                                    [value]="value.keyField">
                          {{value.selectField}}
                        </mat-option>

                      </mat-select>-->

                    </mat-form-field>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'AdditionalFieldDateTimeDTO'">
                  <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                           [matDatepicker]="picker"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="true" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                  </mat-form-field>
                  <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="'' || ''" matInput
                           [matDatepicker]="picker"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked}}" #picker color="primary"></mat-datepicker>

                  </mat-form-field>
                </ng-container>
                <!--AdditionalFieldIntDTO-->
                <ng-container *ngSwitchCase="'AdditionalFieldIntDTO'">
                  <mat-form-field [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"

                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value || ''"
                           [id]="field.name" type="number" [required]="field.required"
                           [disabled]="field.locked"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'AdditionalFieldDoubleDTO'">
                  <mat-form-field [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <mat-label>{{field.description}}</mat-label>
                    <input ngModel [value]="field.value"
                           [id]="field.name" type="text" [required]="field.required"
                           [disabled]="field.locked"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           maxlength="{{field.numMaxChar || 100}}" matInput
                           placeholder="{{field.description}}">
                  </mat-form-field>
                </ng-container>
                <!-- AdditionalFieldClasseDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldClasseDTO'">
                  <div class="classBox">

                    <div>
                      <h4 style="float:left; color: {{this.apiService.dashColor}}">{{field.description}} </h4>
                      <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                              style="float:right; color:{{this.dashColor}};" title="Vedi maschere"
                              (click)="openClassMasks(field)" mat-icon-button
                              aria-label="icon-button send">
                        <mat-icon [matBadge]="mapClasseBookToSave.get(field.name).length">
                          preview
                        </mat-icon>
                      </button>
                      <button style="float:right; color:{{this.dashColor}};"
                              title="Inserisci maschera"
                              (click)="insertMaskFromClassDTO(field)" mat-icon-button
                              aria-label="icon-button send">
                        <mat-icon>post_add</mat-icon>
                      </button>
                      <!--<button style="float:right; color:{{this.dashColor}};"
                               title="Cerca maschera"
                               (click)="searchMaskFromClassDTO(field)" mat-icon-button
                               aria-label="icon-button send">
                           <mat-icon>search</mat-icon>
                       </button>-->
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <mat-form-field
                    [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <input matInput placeholder="{{field.description}}">
                  </mat-form-field>
                </ng-container>
              </ng-container>
            </ng-container>
            <!--/mat-grid-list-->
            <mat-card-actions>
            </mat-card-actions>
            <i aria-hidden="true"></i>
            <i aria-hidden="true"></i>

          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'note'">
          <div style="color: {{this.dashColor}}; text-align: left"><h1>Note: </h1>
            <mat-form-field style="width: 90%"
                            appearance="outline">
              <mat-label>Aggiungi nota</mat-label>
              <textarea [(ngModel)]="this.addNoteText" matInput
                        placeholder="Scrivere nota qui"></textarea>
            </mat-form-field>
            <span><button mat-icon-button (click)="addNote()"><mat-icon>add</mat-icon></button></span></div>
          <br>
          <mat-selection-list #viste [multiple]="false">

            <div *ngFor="let note of this.notes">
              <button style="float:right; color:red;"
                      title="Elimina nota"
                      mat-button
                      aria-label="Elimina allegato" (click)=deleteNote(note)>
                <mat-icon>delete</mat-icon>
              </button>
              <mat-list-item style="width: 90%">
                {{note.comment}}
              </mat-list-item>


            </div>
          </mat-selection-list>
        </ng-container>
        <ng-container *ngSwitchCase="'attachments'">
          <div style="color: {{this.dashColor}}; text-align: left"><h1>Allegati: </h1>
            <div style="text-align: center; color: {{this.apiService.dashColor}};">
              <div style="text-align: left; color: {{this.apiService.dashColor}};">
                <mat-selection-list #viste [multiple]="false">
                  <div *ngFor="let file of this.bufferIdInfo2">
                    <button style="float:right; color:{{this.squareColor}};"
                            title="Elimina allegato"
                            mat-button
                            aria-label="Elimina allegato" (click)="deleteAttach(file.buffer)">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <mat-list-item
                      style="width: 80%;">
                      {{file.name}}
                    </mat-list-item>

                  </div>
                </mat-selection-list>
              </div>
              <br>
              <hr>
              <div style="text-align: center; margin-top: 5%">
                <button style="color: {{this.dashColor}};margin-right: 20px; float: right"
                        title="Carica file"
                        (click)="fileInput2.click()"
                        mat-stroked-button>Aggiungi allegato
                </button>
                <input type="file" (change)="addAttach($event)" hidden #fileInput2
                       id="fileUpload2" name="fileUpload2"
                       multiple="multiple" accept="*"/>
              </div>
            </div>
          </div>
          <br>
        </ng-container>
      </ng-container>
    </mat-card-content>

  </mat-card>
</div>
