<div style="width: 100%; height: 5%;">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>


<div style="text-align: left">
    <h2>Info report:</h2>
    <p>
        <b>Nome: </b>{{this.nome}}
    </p>
    <p>
        <b>Descrizione: </b>{{this.descrizione}}
    </p>
    <p>
        <b>Autore: </b>{{this.autore}}
    </p>
    <p>
        <b>Id Esterno: </b>{{this.idesterno}}
    </p>
    <p>
        <b>Data di creazione: </b>{{this.TransformDataFormat(this.datacreazione)}}
    </p>
    <p>
        <b>Ultimo aggiornamento: </b>{{this.TransformDataFormat(this.ultimoaggiornamento)}}
    </p>
    <p>
        <b>Autore ultimo aggiornamento: </b>{{this.autoreultimoaggiornamento}}
    </p>
</div>

