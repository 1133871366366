import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trattative',
  templateUrl: './trattative.component.html',
  styleUrls: ['./trattative.component.css']
})
export class TrattativeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
