import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-redirect-fodlers',
  templateUrl: './redirect-fodlers.component.html',
  styleUrls: ['./redirect-fodlers.component.css']
})
export class RedirectFoldersComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('openId');
  constructor(private route: ActivatedRoute, private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.id){
      this.router.navigate(['dashboard/dashnav/fascicolo', {openId: this.id}]);
    }else{
      this.router.navigate(['dashboard/dashnav/fascicoli']);
    }
  }

}
