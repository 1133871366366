<!--<mat-card-content>
  <mat-grid-list  cols="3" rows rowHeight="1:1">
    <mat-grid-tile><p>Benvenuto, {{this.nomeUtente}}</p></mat-grid-tile>
    <mat-grid-tile><p>ei</p></mat-grid-tile>
    <mat-grid-tile>
    <p>
        Home page: Benvenuto {{this.nomeUtente}}
    </p>
    </mat-grid-tile>

  </mat-grid-list>
</mat-card-content>

-->

<!-- !!!!!!!!!!!!!!!!!!!TASK!!!!!!!!!!!!!!!!!!!!! -->
<div class="center">
    <mat-card class="cardcomponentMasks">

        <h3 class="">
            <mat-card-title style="color:{{this.dashColor}};"><p>Task: </p><h4 style="text-align: center;">Numero task: {{this.nTask}}</h4>
            </mat-card-title>
        </h3>
        <hr class="riga" style="color:{{this.dashColor}};">
        <br>
        <mat-card-content>

        </mat-card-content>
        <cdk-virtual-scroll-viewport style="margin-left: 3%; margin-right: 3%" id="style-4" itemSize="6"
                                     class="cdk">
            <div style="width: 100%;">
                <h1 *ngIf="nTask == 0" style="text-align: center;">NESSUN TASK TROVATO</h1>
                <ng-container class="cdkcontains">
                    <mat-accordion class="example-headers-align">

                        <mat-expansion-panel [expanded] = "true" (closed)="allTasksOpen = false;" (opened)="allTasksOpen = null;"style="display: contents; "  (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header style=" height: fit-content;text-align: left!important">
                                <mat-panel-title>
                                    <h1 style="text-align: left;margin-top: 1%;">Tutti i task</h1>
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div *ngFor="let task of this.TaskAttive">
                                <mat-selection-list #tasks [multiple]="false">


                                    <mat-accordion class="example-headers-align">

                                        <mat-expansion-panel [expanded]="allTasksOpen" style="display: contents; " (opened)="panelOpenState = true"
                                                             (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header style=" height: fit-content;text-align: left!important">
                                                <mat-panel-title>
                                                    <mat-icon *ngIf="task[15] == 'S'" style="color:{{this.getColor(task[0])}}">
                                                        drafts
                                                    </mat-icon>
                                                    <mat-icon *ngIf="task[15] == 'N'" style="color:{{this.getColor(task[0])}}">
                                                        markunread
                                                    </mat-icon>
                                                    <div style="margin-left: 2%"><b>{{task[21]}}  </b></div>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    {{task[11]}}
                                                    <!-- Scadenza: {{this.TransformDataFormat(task[17])}} -->
                                                    <ng-container
                                                            *ngIf="this.convertStringToDate(task[20]) < this.todayDate"><span
                                                            style="color: red">Scaduto</span></ng-container>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <ng-container><br>
                                                <div style="text-align: left!important" mat-line><i>ID: {{task[9]}}/
                                                    Stato: <b>{{task[16]}}</b>/ Priorità:
                                                    <ng-container *ngIf="task[5]==0"><b>Bassa</b></ng-container>
                                                    <ng-container *ngIf="task[5]==1"><b>Media</b></ng-container>
                                                    <ng-container *ngIf="task[5]==2"><b>Alta</b></ng-container>
                                                </i><br><span [innerHTML]="task[22]"></span>
                                                    <ng-container *ngIf="task[1]"><br><b>Ragione sociale
                                                        dell'azienda: </b>{{task[1]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[2]"><br><b>Ragione sociale: </b>{{task[2]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[3]"><br><b>Nome del trattamento: </b>{{task[3]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[4]"><br><b>Ragione sociale
                                                        cliente: </b>{{task[4]}}
                                                    </ng-container>
                                                    <br>
                                                    <ng-container><br>
                                                        <button mat-button (click)="clickTask(task[9])">
                                                            <mat-icon svgIcon="DetailsIcon"></mat-icon>
                                                        </button>
                                                        <ng-container *ngIf="task[15] == 'S'">
                                                            <button mat-button (click)="unreadTask(task[9])">
                                                                <mat-icon>markunread</mat-icon>
                                                                Segna come non letto
                                                            </button>
                                                        </ng-container>
                                                        <button mat-button [matMenuTriggerFor]="menu"
                                                                aria-label="Example icon-button with a menu">
                                                            <mat-icon>priority_high</mat-icon>
                                                            Seleziona priorità
                                                        </button>
                                                        <mat-menu #menu="matMenu" xPosition="after">
                                                            <button mat-menu-item (click)="setPriority(task[9],0)">
                                                                <span>Priorità bassa</span>
                                                            </button>
                                                            <button mat-menu-item (click)="setPriority(task[9],1)">
                                                                <span>Priorità media</span>
                                                            </button>
                                                            <button mat-menu-item (click)="setPriority(task[9],2)">
                                                                <span>Priorità alta</span>
                                                            </button>
                                                        </mat-menu>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                        </mat-expansion-panel>

                                    </mat-accordion>


                                </mat-selection-list>

                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>


                </ng-container>

                <hr style="margin-top: 2%">
                <ng-container class="cdkcontains">

                    <mat-accordion class="example-headers-align">

                        <mat-expansion-panel (closed)="priorityTasksOpen = false" (opened)="priorityTasksOpen = null" style="display: contents; " (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header style=" height: fit-content;text-align: left!important">
                                <mat-panel-title>
                                    <h1 style="text-align: left;margin-top: 1%; color:orange">Task priorità alta</h1>
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div *ngFor="let task of this.TaskAttive">
                                <mat-selection-list #tasks [multiple]="false">


                                    <mat-accordion class="example-headers-align" *ngIf="task[5]==2">

                                        <mat-expansion-panel [expanded]="priorityTasksOpen" style="display: contents; "
                                                             (opened)="panelOpenState = true"
                                                             (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header
                                                    style=" height: fit-content;text-align: left!important">
                                                <mat-panel-title>
                                                    <mat-icon *ngIf="task[15] == 'S'"
                                                              style="color:{{this.getColor(task[0])}}">
                                                        drafts
                                                    </mat-icon>
                                                    <mat-icon *ngIf="task[15] == 'N'"
                                                              style="color:{{this.getColor(task[0])}}">
                                                        markunread
                                                    </mat-icon>
                                                    <div style="margin-left: 2%"><b>{{task[21]}}  </b></div>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    {{task[11]}}
                                                    <!-- Scadenza: {{this.TransformDataFormat(task[17])}} -->
                                                    <ng-container
                                                            *ngIf="this.convertStringToDate(task[20]) < this.todayDate"><span
                                                            style="color: red">Scaduto</span></ng-container>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <ng-container><br>
                                                <div style="text-align: left!important" mat-line><i>ID: {{task[9]}}/
                                                    Stato: <b>{{task[16]}}</b>/ Priorità:
                                                    <ng-container *ngIf="task[5]==0"><b>Bassa</b></ng-container>
                                                    <ng-container *ngIf="task[5]==1"><b>Media</b></ng-container>
                                                    <ng-container *ngIf="task[5]==2"><b>Alta</b></ng-container>
                                                </i><br><span [innerHTML]="task[22]"></span>

                                                    <ng-container *ngIf="task[1]"><br><b>Ragione sociale
                                                        dell'azienda: </b>{{task[1]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[2]"><br><b>Ragione
                                                        sociale: </b>{{task[2]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[3]"><br><b>Nome del
                                                        trattamento: </b>{{task[3]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[4]"><br><b>Ragione sociale
                                                        cliente: </b>{{task[4]}}
                                                    </ng-container>
                                                    <br>
                                                    <ng-container><br>
                                                        <button mat-button (click)="clickTask(task[9])">
                                                            <mat-icon svgIcon="DetailsIcon"></mat-icon>
                                                        </button>
                                                        <ng-container *ngIf="task[15] == 'S'">
                                                            <button mat-button (click)="unreadTask(task[9])">
                                                                <mat-icon>markunread</mat-icon>
                                                                Segna come non letto
                                                            </button>
                                                        </ng-container>
                                                        <button mat-button [matMenuTriggerFor]="menu"
                                                                aria-label="Example icon-button with a menu">
                                                            <mat-icon>priority_high</mat-icon>
                                                            Seleziona priorità
                                                        </button>
                                                        <mat-menu #menu="matMenu" xPosition="after">
                                                            <button mat-menu-item (click)="setPriority(task[9],0)">
                                                                <span>Priorità bassa</span>
                                                            </button>
                                                            <button mat-menu-item (click)="setPriority(task[9],1)">
                                                                <span>Priorità media</span>
                                                            </button>
                                                            <button mat-menu-item (click)="setPriority(task[9],2)">
                                                                <span>Priorità alta</span>
                                                            </button>
                                                        </mat-menu>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                        </mat-expansion-panel>

                                    </mat-accordion>


                                </mat-selection-list>

                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>

                </ng-container>
                <hr style="margin-top: 2%">
                <ng-container class="cdkcontains">
                    <mat-accordion class="example-headers-align">

                        <mat-expansion-panel (closed)="expiredTasksOpen = false" (opened)="expiredTasksOpen = null" style="display: contents; " (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header style=" height: fit-content;text-align: left!important">
                                <mat-panel-title>
                                    <h1 style="text-align: left; color: red;">Task scaduti</h1>
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div *ngFor="let task of this.TaskAttive">
                                <mat-selection-list #tasks [multiple]="false"
                                                    *ngIf="this.convertStringToDate(task[20]) < this.todayDate">


                                    <mat-accordion class="example-headers-align">

                                        <mat-expansion-panel [expanded]="expiredTasksOpen" style="display: contents; " (opened)="panelOpenState = true"
                                                             (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header style=" height: fit-content;text-align: left!important">
                                                <mat-panel-title>
                                                    <mat-icon *ngIf="task[15] == 'S'" style="color:{{this.getColor(task[0])}}">
                                                        drafts
                                                    </mat-icon>
                                                    <mat-icon *ngIf="task[15] == 'N'" style="color:{{this.getColor(task[0])}}">
                                                        markunread
                                                    </mat-icon>
                                                    <div style="margin-left: 2%"><b>{{task[21]}}  </b></div>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    {{task[11]}}
                                                    <!-- Scadenza: {{this.TransformDataFormat(task[17])}} -->
                                                    <ng-container
                                                            *ngIf="this.convertStringToDate(task[20]) < this.todayDate"><span
                                                            style="color: red">Scaduto</span></ng-container>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <ng-container><br>
                                                <div style="text-align: left!important" mat-line><i>ID: {{task[9]}}/
                                                    Stato: <b>{{task[14]}}</b>/ Priorità:
                                                    <ng-container *ngIf="task[5]==0"><b>Bassa</b></ng-container>
                                                    <ng-container *ngIf="task[5]==1"><b>Media</b></ng-container>
                                                    <ng-container *ngIf="task[5]==2"><b>Alta</b></ng-container>
                                                </i><br><span [innerHTML]="task[22]"></span>
                                                    <ng-container *ngIf="task[1]"><br><b>Ragione sociale
                                                        dell'azienda: </b>{{task[1]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[2]"><br><b>Ragione sociale: </b>{{task[2]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[3]"><br><b>Nome del trattamento: </b>{{task[3]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task[4]"><br><b>Ragione sociale
                                                        cliente: </b>{{task[4]}}
                                                    </ng-container>
                                                    <br>
                                                    <ng-container><br>
                                                        <button mat-button (click)="clickTask(task[9])">
                                                            <mat-icon svgIcon="DetailsIcon"></mat-icon>
                                                        </button>
                                                        <ng-container *ngIf="task[15] == 'S'">
                                                            <button mat-button (click)="unreadTask(task[9])">
                                                                <mat-icon>markunread</mat-icon>
                                                                Segna come non letto
                                                            </button>
                                                        </ng-container>
                                                        <button mat-button [matMenuTriggerFor]="menu"
                                                                aria-label="Example icon-button with a menu">
                                                            <mat-icon>priority_high</mat-icon>
                                                            Seleziona priorità
                                                        </button>
                                                        <mat-menu #menu="matMenu" xPosition="after">
                                                            <button mat-menu-item (click)="setPriority(task[9],0)">
                                                                <span>Priorità bassa</span>
                                                            </button>
                                                            <button mat-menu-item (click)="setPriority(task[9],1)">
                                                                <span>Priorità media</span>
                                                            </button>
                                                            <button mat-menu-item (click)="setPriority(task[9],2)">
                                                                <span>Priorità alta</span>
                                                            </button>
                                                        </mat-menu>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                        </mat-expansion-panel>

                                    </mat-accordion>


                                </mat-selection-list>

                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>


                </ng-container>
            </div>
        </cdk-virtual-scroll-viewport>
    </mat-card>
</div>
<!--ELENCO VISTE
<mat-grid-tile class="gridtile">
    <mat-card class="cardcomponent">
        <h3 class="titles">
            <mat-card-title><p>Elenco viste:</p></mat-card-title>
            <mat-form-field class="searchdimension" ><mat-label><mat-icon svgIcon="RicercaIcon"  style="margin-right: 8px"></mat-icon>Cerca</mat-label>
                <input matInput [ngModel]="searchModelViews" (ngModelChange)="updateSearchModelViste($event)">
                <button mat-button *ngIf="searchModelViews" matSuffix mat-icon-button aria-label="Clear" (click)="searchModelViews=''">
                    <mat-icon>close</mat-icon>
                </button></mat-form-field>
        </h3><hr class="riga"><br>
        <mat-card-content>



            <cdk-virtual-scroll-viewport itemSize="50" class="viewport" id="style-4" [(searchModel)]="searchModelViews">
                <div  style="text-align: left;">
                    <mat-selection-list #viste [multiple]="false" >
                        <mat-list-item (click)= "clickVista(vista.id)" class="item" *cdkVirtualFor="let vista of this.views | vistaFilter: searchModelViews" [value]="vista.description">
                            <mat-icon svgIcon="VisteIcon"  style="margin-left: 8px"></mat-icon>
                            <div mat-line><b>{{vista.description}}</b></div>
                            <div mat-line>Utente: {{vista.userCompleteName}}</div>
                        </mat-list-item>
                    </mat-selection-list>
                </div>
            </cdk-virtual-scroll-viewport>




        </mat-card-content>
    </mat-card>
</mat-grid-tile>
-->

