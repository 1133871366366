<div>
    <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="text-align: center"><h2>Scegliere file</h2>
    <button *ngIf="badgeBufferMain >0" [matMenuTriggerFor]="menuFile"
            aria-label="Vedi File"
            mat-icon-button>
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menuFile="matMenu">
        <ng-container *ngFor="let file of bufferIdInfo">
            <button mat-menu-item (click)="removeFile(file)">
                <mat-icon>close</mat-icon>
                <span style="font-size: 16px; font-weight: 500;">{{file.name}}</span>
            </button>
        </ng-container>
    </mat-menu>
    <button
            title="Carica file"
            (click)="fileInput.click()"
            mat-icon-button>
        <mat-icon [matBadge]="badgeBufferMain">cloud_upload</mat-icon>
    </button>
    <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload" name="fileUpload"
           multiple="multiple" accept="*"/>
    <br>
    <mat-form-field appearance="fill" style="width: 60%;">
        <mat-label>Commento:</mat-label>
        <textarea  matInput rows="5" [(ngModel)]="this.commento"></textarea>
    </mat-form-field>
    <br>
    <hr>
    <br>
    <button mat-stroked-button (click) = send()>Invia</button>
</div>
