<div class="center">
    <mat-card class="cardcomponentMasks" id = "style-4">
        <h3 class="titles" style="color:{{this.dashColor}};">
            <mat-card-title><p>Elenco report:</p>
            </mat-card-title>
        </h3>
        <hr class="riga" style="color:{{this.dashColor}};">
        <br>
        <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->

        </mat-card-content>
            <div style="text-align: left;">
                <mat-selection-list #report [multiple]="false">
                    <ng-container *ngFor="let report of this.reports">
                        <mat-list-item style="border-bottom: 1px solid {{this.squareColor}}"
                                       class="item" (click) = "reportClick(report.id)">

                            <div mat-line style="color:{{this.squareColor}};"><b>{{report.name}}</b></div>
                            <div mat-line> Descrizione: {{report.description}}</div>
                            <div mat-line> Id esterno: {{report.externalId}}</div>
                            <!--<mat-icon class="icon"  svgIcon="CondividiIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon"  svgIcon="ModificaIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon" svgIcon="ClonaIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon" svgIcon="PermessiiIcon"  style="margin: 8px"></mat-icon>
                            <mat-icon class="icon" svgIcon="EliminaIcon"  style="margin: 8px"></mat-icon>
                              -->

                        </mat-list-item>
                    </ng-container>
                </mat-selection-list>
            </div>
        <div *ngIf="this.reports.length === 0">
            <h2 style="color: {{this.dashColor}}">Nessun report presente</h2>

        </div>
    </mat-card>
</div>


