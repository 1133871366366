import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DatePipe} from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class ViewBodyToSave {
    bodyField: any;

    constructor(private datePipe: DatePipe) {
    }

    noFieldsBody(idV: string): void {
        this.bodyField = {};
        this.bodyField = {
            maxItems: 0,
            id: idV,
            searchFilterDto: {
                description: null,
                daAAndOr: 0,
                fields: []
            },
            selectFilterDto: {
                fields: [
                    {
                        sqlSelectField: 'string',
                        toCalculate: true,
                        index: 0,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: 'string',
                        label: 'string',
                        name: 'string',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    }
                ],
                maxItems: 0
            }
        };
    }

    generateDefaultFieldBody(idV: string): void {
        this.bodyField = {};
        this.bodyField = {
            maxItems: 0,
            id: idV,
            showGroupsMode: 1,
            searchFilterDto: {
                description: null,
                daAAndOr: 1,
                fields: []
            },
            selectFilterDto: {
                fields: [
                    {
                        sqlSelectField: 'string',
                        toCalculate: true,
                        index: 0,
                        selected: true,
                        fieldType: 0,
                        orderBy: {
                            direction: 0,
                            index: 0
                        },
                        externalId: 'string',
                        label: 'string',
                        name: 'string',
                        userSelectionEnabled: true,
                        userSelectionGroup: 0
                    }
                ],
                maxItems: 0
            }
        };
    }

    pushDocumentType(docType: any): void {
        this.bodyField.searchFilterDto.fields.push({
            additionalFieldType: 0,
            and: null,
            associations: null,
            binderFieldId: 0,
            className: 'FieldBaseForSearchDocumentTypeDto',
            comboGruppiId: '',
            dataSource: '',
            defaultOperator: 3,
            dependencyFields: [],
            description: 'Classe documento',
            externalId: '',
            fieldType: 0,
            formula: '',
            groupId: 0,
            isAdditional: false,
            locked: false,
            multiple: null,
            name: 'DocumentType',
            operator: 3,
            order: 1,
            predefinedProfileFormula: null,
            required: false,
            tableName: null,
            valore1: docType,
            valore2: {
                documentType: 0,
                type2: 0,
                type3: 0
            },
            value: docType.id,
            visible: true
        });
    }

    pushFieldForSearch(field: any): void {
        this.bodyField.searchFilterDto.fields.push({
            additionalFieldType: 0,
            associations: null,
            binderFieldId: 0,
            className: field.className,
            comboGruppiId: null,
            dataSource: null,
            defaultOperator: 0,
            dependencyFields: [],
            description: field.description,
            externalId: null,
            fieldType: 1,
            focus: true,
            formula: null,
            groupId: 0,
            isAdditional: true,
            locked: false,
            multiple: null,
            name: field.name,
            order: 0,
            predefinedProfileFormula: null,
            required: false,
            tableName: null,
            visible: true
        });
    }

    pushContact(field: any): void {
        this.bodyField.searchFilterDto.fields.push(field);
    }

    pushInt(field: any): void {
        this.bodyField.searchFilterDto.fields.push(field);
    }

    pushDate(field: any): void {
        this.bodyField.searchFilterDto.fields.push(field);
    }

    pushAoo(field: any, fieldValue: any, classNa: string): void {
        this.bodyField.searchFilterDto.fields.push({
            operator: 3,
            valore1: {
                code: fieldValue
            },
            defaultOperator: 3,
            tableName: null,
            binderFieldId: 0,
            multiple: null,
            name: field.name,
            externalId: '',
            description: field.description,
            order: 0,
            dataSource: '',
            required: false,
            formula: '',
            className: classNa,
            locked: false,
            comboGruppiId: '',
            dependencyFields: [],
            associations: null,
            isAdditional: false,
            visible: true,
            predefinedProfileFormula: null
        });
    }

    TransformDataFormat(Data: string): string {

        if (Data === '') {
            return '0001-01-01T00:00:00';
        }
        return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

    TransformDataFormat2(Data: string): string {
        return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

    pushString(field: any, fieldValue: any, classNa: string): void {
        console.log(fieldValue);
        if (field.name.startsWith('COMBO')) {
            let operator1 = 0;
            if (fieldValue !== '') {
                operator1 = field.defaultOperator;
            } else {
                operator1 = 0;
            }
            this.bodyField.searchFilterDto.fields.push({
                operator: operator1,
                comboGruppiId: field.comboGruppiId,
                defaultOperator: 3,
                binderFieldId: 0,
                multiple: null,
                name: field.name,
                externalId: '',
                description: field.description,
                order: 0,
                dataSource: '',
                required: false,
                formula: '',
                className: classNa,
                locked: false,
                dependencyFields: [],
                associations: null,
                isAdditional: field.isAdditional,
                visible: true,
                fieldType: field.fieldType,
                tableName: field.tableName,
                valore1: fieldValue,
                and: null,
                predefinedProfileFormula: null
            });
        } else if (field.additionalFieldType === 4) {
            this.bodyField.searchFilterDto.fields.push({
                operator: 1,
                defaultOperator: 3,
                binderFieldId: 0,
                multiple: null,
                name: field.name,
                externalId: '',
                description: field.description,
                order: 0,
                dataSource: '',
                required: false,
                formula: '',
                className: classNa,
                locked: false,
                comboGruppiId: field.comboGruppiId,
                dependencyFields: [],
                associations: null,
                isAdditional: field.isAdditional,
                visible: true,
                fieldType: field.fieldType,
                tableName: field.tableName,
                valore1: fieldValue,
                valore2: null,
                and: null,
                predefinedProfileFormula: null
            });
        } else if (field.additionalFieldType !== 4) {
            this.bodyField.searchFilterDto.fields.push({
                operator: 4,
                defaultOperator: 3,
                binderFieldId: 0,
                multiple: fieldValue,
                name: field.name,
                externalId: '',
                description: field.description,
                order: 0,
                dataSource: '',
                required: false,
                formula: '',
                className: classNa,
                locked: false,
                comboGruppiId: '',
                dependencyFields: [],
                associations: null,
                isAdditional: field.isAdditional,
                visible: true,
                fieldType: field.fieldType,
                tableName: field.tableName,
                valore2: null,
                and: null,
                predefinedProfileFormula: null
            });
        }
    }

}
