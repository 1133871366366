import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';
import {FilterDialogComponent} from '../VociMenu/default-home/default-home.component';


@Component({
  selector: 'app-dialogmultidto',
  styleUrls: ['multiDTO-css.css'],
  templateUrl: 'dialog-content-multiDTO.html'
})

export class DialogMultiDTOComponent {
  ELEMENT_DATA: Array<object> = new Array<object>();
  arrayToObject: { [index: string]: { content: string } } = {};
  dataSource: MatTableDataSource<object>;
  selection: SelectionModel<object>;
  displayedColumns: Array<string> = new Array<string>();
  squareColor: string;
  dataResult: Array<string> = new Array<string>();
  dataToSave: Array<any> = new Array();
  additionalFieldMultiDTOValue: Array<any> = new Array();
  fieldName: string;
  selectField: string;
  find = false;
  mapMultiValueToSave: Map<string, string[]> = new Map<string, string[]>();
  filter: string;
  additionalFieldMultiDTO: any;
  filterTags: Array<any> = new Array<any>();
  found: boolean;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DialogMultiDTOComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService) {
    this.fieldName = data.fieldName;
    this.selectField = data.selectField;
    this.additionalFieldMultiDTOValue = data.additionalFieldMultiDTO;
    this.additionalFieldMultiDTO = data.additionalFieldMultiDTO;
    data.additionalFieldMultiDTO.forEach(element => {
      this.displayedColumns = new Array<string>();
      this.displayedColumns.push('select');
      this.filterTags = new Array<any>();
      this.arrayToObject = {};
      element.forEach(obj => {
        this.displayedColumns.push(obj.id);
        this.arrayToObject[obj.id] = obj.value;
        this.filterTags.push({tag: obj.id, active: true});
      });
      this.ELEMENT_DATA.push(this.arrayToObject);
    });
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.squareColor = this.apiService.squareColor;
    this.mapMultiValueToSave = data.multiValueToSelected;
    this.selection = new SelectionModel<object>(true, []);
    if (this.mapMultiValueToSave.size > 0 && this.mapMultiValueToSave.get(this.fieldName) !==
        undefined && this.mapMultiValueToSave.get(this.fieldName)) {
      this.mapMultiValueToSave.get(this.fieldName).forEach(itemMap => {
        this.dataSource.data.forEach(row => {
          if (itemMap === row[this.selectField]) {
            this.selection.select(row);
          }
        });
      });
    }
  }
  openFilterDialog(): void {


    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '40%',
      data: {tags: this.filterTags}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.resultFilter) {
        this.filterTags = result.resultFilter;
        this.filterChange();
      }
    });
  }

  filterChange(): void {
    if (this.filter !== null && this.filter !== undefined) {
      this.ELEMENT_DATA = new Array<object>();
      this.additionalFieldMultiDTO.forEach(element => {
        this.displayedColumns = new Array<string>();
        this.displayedColumns.push('select');
        this.arrayToObject = {};
        this.found = false;
        element.forEach(obj => {
          this.displayedColumns.push(obj.id);
          this.arrayToObject[obj.id] = obj.value;
          this.filterTags.forEach(tag => {
            if (tag.tag === obj.id && tag.active === true) {
              if (obj.value.toLowerCase().includes(this.filter.toLocaleLowerCase())) {
                this.found = true;
              }
            }
          });
        });
        if (this.found) {
          this.ELEMENT_DATA.push(this.arrayToObject);
        }

      });
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.selection = new SelectionModel<object>(true, []);
      if (this.mapMultiValueToSave.size > 0 && this.mapMultiValueToSave.get(this.fieldName) !== undefined) {
        this.mapMultiValueToSave.get(this.fieldName).forEach(itemMap => {
          this.dataSource.data.forEach(row => {
            if (itemMap === row[this.selectField]) {
              this.selection.select(row);
            }
          });
        });
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): any {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  deleteField(): void{
    this.dialogRef.close({dataResult: 'delete'});
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  saveSelectedValues(): void {
    this.dataResult = new Array<string>();
    this.dataToSave = new Array();
    console.log(this.selectField);
    this.selection.selected.forEach(itemSelected => {
      this.dataResult.push(itemSelected[this.selectField]);
      this.additionalFieldMultiDTOValue.forEach(element => {
        this.find = false;
        element.forEach(arrayRow => {
          if (arrayRow.value === itemSelected[this.selectField]) {
            this.find = true;
          }
        });
        if (this.find) {
          this.dataToSave.push(element);
        }
      });
    });

    this.dialogRef.close({dataResult: this.dataResult, dataToSave: this.dataToSave});
  }

  closeDialog(): void {
    this.dialogRef.close({dataResult: false});
  }
}
