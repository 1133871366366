<div>
  <mat-form-field style="width: 60%">
    <mat-label>Filtra</mat-label>
    <input matInput [(ngModel)]="this.filter" (ngModelChange)="this.filterChange()">
    <button mat-button matSuffix mat-icon-button aria-label="Filtri"
            (click)="openFilterDialog()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button mat-button *ngIf="this.filter"  matSuffix mat-icon-button
            aria-label="Cancella" (click)="this.filter=''; this.filterChange()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
  <button title="Svuota campo" (click)="deleteField()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" style = "border-spacing: 0px !important;">
    <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
      <ng-container>
        <th mat-header-cell style="background-color: white;" *matHeaderCellDef>{{disCol}}</th>
        <td mat-cell *matCellDef="let element" (click)="selectedRow(element)">{{element[disCol]}}
        </td>
      </ng-container>
      <mat-divider [vertical]="true"></mat-divider>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="height: 25px !important; max-height: 25px !important;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height:68px !important; max-height: 68px !important;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow style="height: 10px !important; max-height: 10px !important;">
      <td class="mat-cell" colspan="4">Nessun elemento trovato: "{{input.value}}"</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</div>
