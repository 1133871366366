<div style="text-align: center; color: black">
    <h2>Cambia password</h2>
    <hr><br>
  <form autocomplete="off">
    <mat-form-field appearance="fill" autocomplete="off">
        <mat-label>Vecchia password</mat-label>
        <input autocomplete="off" matInput required [maxLength]="this.constrains.maxLength" (change)="checkErrors()" [(ngModel)]="this.oldPassword" type = "password">
    </mat-form-field>
    <mat-form-field appearance="fill" autocomplete="off">
        <mat-label>Nuova password</mat-label>
        <input autocomplete="off" matInput required [maxLength]="this.constrains.maxLength" (change)="checkErrors()" [(ngModel)]="this.newPassword" type = "password">
    </mat-form-field>
    <mat-form-field appearance="fill" autocomplete="off">
        <mat-label>Conferma password</mat-label>
        <input autocomplete="off" matInput required [maxLength]="this.constrains.maxLength" (change)="checkErrors()" [(ngModel)]="this.confirmPassword" type = "password">
    </mat-form-field>
    <button type = "submit" mat-stroked-button (click)="changePassword()">Cambia password</button><button title="Chiudi" (click)="closeDialog()" mat-stroked-button color="warn">
    Chiudi
</button></form>
    <br>
    <p style="color: red;">{{this.error}}</p>
</div>
