<!--<mat-card-content>
  <mat-grid-list  cols="3" rows rowHeight="1:1">
    <mat-grid-tile><p>Benvenuto, {{this.nomeUtente}}</p></mat-grid-tile>
    <mat-grid-tile><p>ei</p></mat-grid-tile>
    <mat-grid-tile>
    <p>
        Home page: Benvenuto {{this.nomeUtente}}
    </p>
    </mat-grid-tile>

  </mat-grid-list>
</mat-card-content>

-->

<mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)" rowspan="2"  class="card" cols="3" rows rowHeight="{{valueRowHeight}}">
    <mat-grid-tile class="gridtile">

      <mat-card class="cardcomponent">

        <h3 class="titles" >
          <mat-card-title><p>Elenco task:</p></mat-card-title>
        </h3><hr class="riga"><br>
        <mat-card-content>
            <div *ngFor = "let task of this.TaskAttive" class = "item">
                <mat-selection-list #tasks [multiple]="false" >


                    <!-- cartella -->

                    <mat-accordion>
                        <mat-expansion-panel (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="item">
                                    <div style="margin-bottom: 0.5%; text-align: left"><b>{{task[17]}} </b><br>  {{task[7]}}    Scadenza: {{this.TransformDataFormat(task[16])}}<br></div>
                                </mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-container>
                                <div mat-line ><i>ID: {{task[5]}} Stato: {{task[12]}}</i><br><span [innerHTML]="task[18]"></span></div>

                                <button mat-stroked-button (click)= "clickTask(task[5])" style="text-align: left !important">Mostra tutto</button>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>






                </mat-selection-list>

            </div>
            <h1 *ngIf="nTask == 0"> NESSUNA TASK TROVATA</h1>
            <br><br><p style="text-align: left">Numero task: {{this.nTask}}</p>
        </mat-card-content>

      </mat-card>
</mat-grid-tile>
    <!--ELENCO VISTE-->
    <mat-grid-tile class="gridtile">
      <mat-card class="cardcomponent">
        <h3 class="titles">
          <mat-card-title><p>Elenco viste:</p></mat-card-title>
          <mat-form-field class="searchdimension" ><mat-label><mat-icon svgIcon="RicercaIcon"  style="margin-right: 8px"></mat-icon>Cerca</mat-label>
            <input matInput [ngModel]="searchModelViews" (ngModelChange)="updateSearchModelViste($event)">
            <button mat-button *ngIf="searchModelViews" matSuffix mat-icon-button aria-label="Clear" (click)="searchModelViews=''">
            <mat-icon>close</mat-icon>
          </button></mat-form-field>
        </h3><hr class="riga"><br>
        <mat-card-content>



            <cdk-virtual-scroll-viewport itemSize="50" class="viewport" id="style-4" [(searchModel)]="searchModelViews">
              <div  style="text-align: left;">
                <mat-selection-list #viste [multiple]="false" >
                  <mat-list-item (click)= "clickVista(vista.id)" class="item" *cdkVirtualFor="let vista of this.views | vistaFilter: searchModelViews" [value]="vista.description">
                    <mat-icon svgIcon="VisteIcon"  style="margin-left: 8px"></mat-icon>
                    <div mat-line><b>{{vista.description}}</b></div>
                    <div mat-line>Utente: {{vista.userCompleteName}}</div>
                  </mat-list-item>
                </mat-selection-list>
                </div>
            </cdk-virtual-scroll-viewport>




        </mat-card-content>
     </mat-card>
    </mat-grid-tile>

    <!--DESKTOP-->
    <mat-grid-tile class="gridtile">
      <mat-card class="cardcomponent">
          <h3 class="titles">
            <mat-card-title><p>Desktop:</p></mat-card-title>
            <mat-form-field class="searchdimension"><mat-label><mat-icon svgIcon="RicercaIcon"  style="margin-right: 8px"></mat-icon>Cerca</mat-label><input matInput [ngModel]="searchModelMaschere" (ngModelChange)="updateSearchModelMaschere($event)">
              <button mat-button *ngIf="searchModelMaschere" matSuffix mat-icon-button aria-label="Clear" (click)="searchModelMaschere=''">
              <mat-icon>close</mat-icon>
            </button></mat-form-field>
        </h3>
        <hr class="riga"><br>
        <mat-card-content>


            <cdk-virtual-scroll-viewport itemSize="50" class="viewport" id="style-4">
                <div style="text-align: left;">
                    <mat-selection-list #maschere [multiple]="false">


                        <mat-list-item (click)="clickMaschera(maschera.id)" class="item"
                                       *cdkVirtualFor="let maschera of this.Maskdesk | maskFilter: searchModelMaschere"
                                       [value]="maschera.id">
                            <!--elenco maschere contenute nel desktop-->
                            <mat-icon svgIcon="MaschereIcon" style="margin-left: 8px"></mat-icon>
                            <div mat-line><b>{{maschera.maskName}}</b></div>
                            <div mat-line> {{maschera.maskDescription}}</div>
                        </mat-list-item>
                        <mat-list-item (click)="clickVista(vista.id)" class="item" *ngFor="let vista of this.Viewdesk "
                                       [value]="vista.id">
                            <!--elenco viste-->
                            <mat-icon svgIcon="VisteIcon" style="margin-left: 8px"></mat-icon>
                            <div mat-line><b>{{vista.description}}</b></div>
                            <div mat-line> {{vista.description}}</div>
                        </mat-list-item>
                        <mat-list-item class="item" *ngFor="let qs of this.Quikdesk " [value]="qs.id">
                            <!--elenco quick searches
                            <div mat-line><b></b></div>
                            <div mat-line> </div>
                              -->
                        </mat-list-item>
                        <mat-list-item class="item" *ngFor="let pd of this.Profiledesk" [value]="pd.id">
                            <!--elenco profiles
                            <div mat-line><b></b></div>
                            <div mat-line> </div>
                            -->
                        </mat-list-item>
                        <mat-list-item class="item" *ngFor="let mod of this.Modelsdesk" [value]="mod.id">
                            <!--elenco models
                            <div mat-line><b></b></div>
                            <div mat-line></div>-->
                        </mat-list-item>
                        <mat-list-item class="item" *ngFor="let fol of this.Folderdesk" [value]="fol.id">
                            <!--elenco folders
                            <div mat-line><b></b></div>
                            <div mat-line> </div>-->
                        </mat-list-item>


                    </mat-selection-list>

                </div>
            </cdk-virtual-scroll-viewport>


        </mat-card-content>

    </mat-card>
    </mat-grid-tile>
  </mat-grid-list>

