<!--<app-dashnav></app-dashnav>
<div class="center" role="main">
  <div class="formo">
    <mat-card class="card">
      <h3 style="text-align: center;">
        <mat-card-title><p>MENU:</p></mat-card-title>
      </h3>
        <p></p>
      <mat-card-content>
        <div class="contenitore">
          <app-home></app-home>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Opzione 1</button>
        <button mat-button>Opzione 2</button>
        <button mat-button>Opzione 3</button>
        <button mat-button>Opzione 4</button>
        <button mat-button>Opzione 5</button>
      </mat-card-actions>
    </mat-card>
  </div>(click)="drawer.toggle()"
</div>
-->
<mat-toolbar class="shadow" style="background: {{this.dashcolor}};">
  <button class="menu-button" (click)="drawer.toggle()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  DASHBOARD
  <span class="toolbar-spacer"></span>
</mat-toolbar>

<mat-drawer-container class="main-container">
  <router-outlet></router-outlet>
  <mat-drawer style="background: {{this.dashcolor}};" class="card" #drawer mode="side" opened="true">

    <div class="buttons">
    <br>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons" (click)="desktopClick()"
              autofocus>
        <mat-icon svgIcon="TaskIcon" style="margin-right: 8px"></mat-icon>
        Task
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons" (click)="searchClick()">
        <mat-icon style="margin-right: 8px">search</mat-icon>
        Cerca
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="registersClick()">
        <mat-icon style="margin-right: 8px">table_chart</mat-icon>
        Registri
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="treatmentsClick()">
        <mat-icon style="margin-right: 8px">fingerprint</mat-icon>
        Trattamenti
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="suppliersClick()">
        <mat-icon style="margin-right: 8px">store</mat-icon>
        Fornitori
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="clientsClick()">
        <mat-icon style="margin-right: 8px">apartment</mat-icon>
        Clienti
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="resourcesClick()">
        <mat-icon style="margin-right: 8px">storage</mat-icon>
        Risorse
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="agreementsClick()">
        <mat-icon style="margin-right: 8px">description</mat-icon>
        Accordi
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="databreachClick()">
        <mat-icon style="margin-right: 8px">warning</mat-icon>
        Data Breach
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="requestsClick()">
        <mat-icon style="margin-right: 8px">record_voice_over</mat-icon>
        Richieste interessati
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons" (click)="reportsClick()"
              disabled>
        <mat-icon style="margin-right: 8px">assessment</mat-icon>
        Report
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="fascicoliClick()">
        <mat-icon svgIcon="FascicoliIcon" style="margin-right: 8px"></mat-icon>
        Fascicoli
      </button>
      <button mat-flat-button style="background:{{this.dashcolor}};" class="align-left-buttons"
              (click)="settingsClick()">
        <mat-icon svgIcon="SettingsIcon" style="margin-right: 8px"></mat-icon>
        Impostazioni
      </button>

    </div>

  </mat-drawer>
</mat-drawer-container>
