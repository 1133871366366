<div>
    <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="text-align: center"><h2>Allegati</h2>
    <ng-container *ngIf="this.attachments.length == 0">
        Nessun allegato presente
    </ng-container>
    <ng-container *ngIf="this.attachments.length != 0">
        {{this.attachments.length}} allegati trovati:
        <div *ngFor="let attack of this.attachments">
            <mat-list-item>
                {{attack.originalname}}
            </mat-list-item>
        </div>
    </ng-container>
    <br>
    <hr>
    <br>
    <button mat-stroked-button>Invia</button>
</div>
