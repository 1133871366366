<div class="center">
  <mat-card class="cardcomponentMasks">
    <h3 class="titles" style="color:{{this.dashColor}};">
      <mat-card-title><p>Gestione trattamenti:</p>
      </mat-card-title>
      <mat-form-field class="searchdimension">
        <mat-label>
          <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
          Cerca
        </mat-label>
        <input matInput [ngModel]="searchModelMaschere" (ngModelChange)="updateSearchModelMaschere($event)">
        <button mat-button *ngIf="searchModelMaschere" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchModelMaschere=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </h3>
    <hr class="riga" style="color:{{this.dashColor}};">
    <br>
    <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->

    </mat-card-content>
    <cdk-virtual-scroll-viewport itemSize="50" class="cdk" id="style-4" [(searchModel)]="searchModelMaschere">
      <div style="text-align: left;">
        <mat-selection-list #maschere [multiple]="false">
          <ng-container *ngFor="let maschera of this.Maschere | maskFilter: searchModelMaschere">
            <mat-list-item *ngIf="maschera.maskName.startsWith('Trattamenti - ')"
                           style="border-bottom: 1px solid {{this.squareColor}}"
                           (click)="clickMaschera(maschera.id)" class="item" [value]="maschera.id">

              <div mat-line style="color:{{this.squareColor}};"><b>{{maschera.maskName.split("- ").pop()}}</b></div>
              <div mat-line> {{maschera.maskDescription}}</div>
              <!--<mat-icon class="icon"  svgIcon="CondividiIcon"  style="margin: 8px"></mat-icon>
              <mat-icon class="icon"  svgIcon="ModificaIcon"  style="margin: 8px"></mat-icon>
              <mat-icon class="icon" svgIcon="ClonaIcon"  style="margin: 8px"></mat-icon>
              <mat-icon class="icon" svgIcon="PermessiiIcon"  style="margin: 8px"></mat-icon>
              <mat-icon class="icon" svgIcon="EliminaIcon"  style="margin: 8px"></mat-icon>
                -->

            </mat-list-item>
          </ng-container>
        </mat-selection-list>
      </div>
    </cdk-virtual-scroll-viewport>
  </mat-card>
</div>


