import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {ApiService} from '../api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.css']
})
export class DashboardV2Component implements OnInit {
  Utente: string;
  dashcolor: string;

  // tslint:disable-next-line:max-line-length
  constructor(public apiService: ApiService, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'RubricaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/contacts-24px.svg'));
    iconRegistry.addSvgIcon(
      'DesktopIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/house-24px.svg'));
    iconRegistry.addSvgIcon(
      'ArchiviazioneIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/archive-24px.svg'));
    iconRegistry.addSvgIcon(
      'RicercaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/search-24px.svg'));
    iconRegistry.addSvgIcon(
      'UltimaRicercaIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/history-24px.svg'));
    iconRegistry.addSvgIcon(
      'VisteIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/preview-24px.svg'));
    iconRegistry.addSvgIcon(
      'MaschereIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tab-24px.svg'));
    iconRegistry.addSvgIcon(
      'TaskIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/add_task-24px.svg'));
    iconRegistry.addSvgIcon(
      'FascicoliIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/folder-24px.svg'));
    iconRegistry.addSvgIcon(
      'StrumentiIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/architecture-24px.svg'));
    iconRegistry.addSvgIcon(
      'GestioneIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/settings-24px.svg'));
    iconRegistry.addSvgIcon(
      'ModelliIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/widgets-24px.svg'));
    iconRegistry.addSvgIcon(
      'ProfiliIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/account_box-24px.svg'));
    iconRegistry.addSvgIcon(
      'SettingsIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/settings-24px.svg'));
    iconRegistry.addSvgIcon(
      'FlagIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/flag-24px.svg'));
  }

  showFiller = false;

  ngOnInit(): void {




    this.apiService.getBetUserSetting().subscribe(data => {
      // // console.log(data);
      this.dashcolor = data[0].dash_color;
      this.apiService.dashColor = data[0].dash_color;
      this.apiService.backColor = data[0].back_color;
      this.apiService.squareColor = data[0].square_color;
      this.apiService.cardColor = data[0].card_color;
    });
    this.dashcolor = this.apiService.dashColor;

    this.router.navigate(['dashnav/task'], {relativeTo: this.route});
    this.Utente = localStorage.getItem('username');
    if (!this.apiService.isLoggedIn()) {
      this.router.navigate(['login']);
    }

  }

  desktopClick(): void {
    this.router.navigate(['dashnav/homev2'], {relativeTo: this.route});
  }

  rubricaClick(): void {
    this.router.navigate(['dashnav/rubrica'], {relativeTo: this.route});
  }

  visteClick(): void {
    this.router.navigate(['dashnav/viste', {id: '0'}], {relativeTo: this.route});
  }
  searchClick(): void {
    this.router.navigate(['dashnav/search', {id: '0'}], {relativeTo: this.route});
  }
  taskClick(): void {
    this.router.navigate(['dashnav/task'], {relativeTo: this.route});
  }

  fascicoliClick(): void {
    this.router.navigate(['dashnav/fascicoli'], {relativeTo: this.route});
  }


  settingsClick(): void {
    this.router.navigate(['dashnav/settings'], {relativeTo: this.route});
  }

  treatmentsClick(): void {
    this.router.navigate(['dashnav/masktreatments', {id: '0'}], {relativeTo: this.route});
  }

  agreementsClick(): void {
    this.router.navigate(['dashnav/maskagreements', {id: '0'}], {relativeTo: this.route});
  }

  resourcesClick(): void {
    this.router.navigate(['dashnav/maskresources', {id: '0'}], {relativeTo: this.route});
  }

  clientsClick(): void {
    this.router.navigate(['dashnav/maskclients', {id: '0'}], {relativeTo: this.route});
  }

  databreachClick(): void {
    this.router.navigate(['dashnav/maskdatabreach', {id: '0'}], {relativeTo: this.route});
  }

  requestsClick(): void {
    this.router.navigate(['dashnav/maskrequests', {id: '0'}], {relativeTo: this.route});
  }

  registersClick(): void {
    this.router.navigate(['dashnav/viewregisters', {id: '0'}], {relativeTo: this.route});
  }

  reportsClick(): void {
    this.router.navigate(['dashnav/report'], {relativeTo: this.route});
  }

  suppliersClick(): void {
    this.router.navigate(['dashnav/masksuppliers', {id: '0'}], {relativeTo: this.route});
  }
}
