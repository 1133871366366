<div>
  <button title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
</div>
<cdk-virtual-scroll-viewport style="margin-left: 3%; margin-right: 3%" id="style-4" itemSize="6"
                             class="cdkD"><div>
  <mat-form-field>
    <mat-label>Cerca</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Cerca..." #input>
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
      <ng-container *ngIf="disCol != 'Azioni'">
            <th  mat-sort-header mat-header-cell *matHeaderCellDef>{{(disCol.substr(disCol.indexOf(',') + 1, disCol.length))}}</th>
        <td mat-cell *matCellDef="let element">{{element[disCol]}}
        </td>
      </ng-container>
      <ng-container *ngIf="disCol == 'Azioni'">
        <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Azioni sulla maschera">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editField(element)">
              <mat-icon>edit</mat-icon>
              <span>Modifica</span>
            </button>
            <button mat-menu-item (click)="deleteField(element)">
              <mat-icon>delete</mat-icon>
              <span>Elimina</span>
            </button>
            <ng-container *ngFor="let command of this.commands.details">
              <ng-container [ngSwitch]="command.elementType">
                <!-- Apri -->
                <button mat-menu-item *ngSwitchCase="1"
                        (click)="downloadDoc(element.Azioni)">
                  <mat-icon>launch</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Elimina documento -->
                <!-- TODO ELIMINA DOCUMENTO  -->
                <button mat-menu-item (click) = "this.deleteDocument(element.Azioni)" *ngSwitchCase="5">
                  <mat-icon>clear</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Processo di workflow singolo -->
                <button mat-menu-item *ngSwitchCase="81"
                        (click)="this.startEvent(command.elementId, element.Azioni)">
                  <mat-icon>developer_board</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Task attivo -->
                <!-- TODO Task attivo  -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="46">
                  <mat-icon>settings_suggest</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Mostra processi per il documento -->
                <!-- TODO Mostra processi per il documento  -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="77">
                  <mat-icon>visibility</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Avvia nuovo processo di workflow -->
                <!-- TODO Avvia nuovo processo di workflow  -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="76">
                  <mat-icon>playlist_add_check</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Cartella -->
                <ng-container>
                  <button mat-menu-item
                          *ngSwitchCase="70" (click)="enableFolder(command.id)">
                    <mat-icon>folder_open</mat-icon>
                    {{command.label}}
                    <ng-container *ngFor="let folder of this.folders">
                      <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                        <mat-icon style="float: right">keyboard_arrow_down</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="folder.id == command.id && folder.enabled == false">
                        <mat-icon style="float: right">keyboard_arrow_right</mat-icon>
                      </ng-container>
                    </ng-container>
                  </button>
                  <ng-container *ngFor="let folder of this.folders">
                    <ng-container *ngIf="folder.id == command.id && folder.enabled == true">
                      <ng-container *ngFor="let child of command.childs">
                        <ng-container [ngSwitch]="child.elementType">
                          <!-- Processo di workflow singolo -->
                          <button mat-menu-item *ngSwitchCase="81" style="margin-left: 10px;"
                                  (click)="this.startEvent(child.elementId, element.Azioni)">
                            <mat-icon>developer_board</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Task attivo -->
                          <!-- TODO TASK ATTIVO -->
                          <button mat-menu-item *ngSwitchCase="46" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>settings_suggest</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Mostra processi per il documento -->
                          <!-- TODO Mostra processi per il documento -->
                          <button mat-menu-item *ngSwitchCase="77" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>visibility</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Avvia nuovo processo di workflow -->
                          <!-- TODO Avvia nuovo processo di workflow -->
                          <button mat-menu-item *ngSwitchCase="76" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>playlist_add_check</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Apri -->
                          <button mat-menu-item *ngSwitchCase="1" style="margin-left: 10px;"
                                  (click)="downloadDoc(element.Azioni)">
                            <mat-icon>launch</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Elimina documento -->
                          <!-- TODO Elimina documento -->
                          <button mat-menu-item *ngSwitchCase="5" (click) = "this.deleteDocument(element.Azioni)"
                                  style="margin-left: 10px;">
                            <mat-icon>clear</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Modifica documento -->
                          <button mat-menu-item [matMenuTriggerFor]="modifica" *ngSwitchCase="11"
                                  style="margin-left: 10px;"
                                  style="color: black">
                            <mat-icon>create</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <mat-menu #modifica="matMenu">
                            <button mat-menu-item
                                    (click)="openEditFileDialog(element.Azioni)"
                                    style="color: black">
                              <mat-icon>attach_file</mat-icon>
                              <span>Da file</span>
                            </button>
                            <button mat-menu-item
                                    (click)="openEditRevisionDialog(element.Azioni)">
                              <mat-icon>skip_previous</mat-icon>
                              <span>Da revisionare</span>
                            </button>
                          </mat-menu>
                          <!-- Duplica profilo -->
                          <!-- TODO Duplica profilo -->
                          <button mat-menu-item *ngSwitchCase="3" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>content_copy</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Esporta CSV -->

                          <!-- Elenco allegati -->
                          <button mat-menu-item *ngSwitchCase="7" style="margin-left: 10px;"
                                  (click)="openShowAttachmentsDialog(element.Azioni)">
                            <mat-icon>attach_file</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Elenco note -->
                          <button mat-menu-item *ngSwitchCase="6" style="margin-left: 10px;"
                                  (click)="openProfileNotesDialog(element.Azioni)">
                            <mat-icon>note</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Elenco revisioni -->
                          <!-- TODO Elenco revisioni -->
                          <button mat-menu-item *ngSwitchCase="10"
                                  style="margin-left: 10px;" (click)="openRevisions(element.Azioni)">
                            <mat-icon>rate_review</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Separatore -->
                          <hr *ngSwitchCase="71" style="margin-left: 10px;">
                          <!-- CheckOut -->
                          <!-- TODO CheckOut -->
                          <button mat-menu-item *ngSwitchCase="12" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>first_page</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- CheckIn -->
                          <!-- TODO CheckIn -->
                          <button mat-menu-item *ngSwitchCase="13" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>input</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Fascicoli che contengono il documento -->
                          <!-- TODO Fascicoli che contengono il documento -->
                          <button mat-menu-item *ngSwitchCase="16" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>pageview</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Associazioni che contengono il documento -->
                          <!-- TODO Associazioni che contengono il documento -->
                          <button mat-menu-item *ngSwitchCase="18" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>swap_horiz</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Mostra relazioni -->

                          <!-- Inserisci in fascicolo -->
                          <!-- TODO Inserisci in fascicolo -->
                          <button mat-menu-item *ngSwitchCase="15" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>create_new_folder</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Inserisci in associazione -->
                          <!-- TODO Inserisci in associazione -->
                          <button mat-menu-item *ngSwitchCase="17" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>insert_link</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                          <!-- Nuova relazione manuale -->
                          <!-- TODO Nuova relazione manuale -->
                          <button mat-menu-item *ngSwitchCase="19" [disabled]="true"
                                  style="margin-left: 10px;">
                            <mat-icon>mediation</mat-icon>
                            <span>{{child.label}}</span>
                          </button>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- Modifica documento -->
                <button mat-menu-item [matMenuTriggerFor]="modifica" *ngSwitchCase="11"
                        style="color: black">
                  <mat-icon>create</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <mat-menu #modifica="matMenu">
                  <button mat-menu-item
                          (click)="openEditFileDialog(element.Azioni)"
                          style="color: black">
                    <mat-icon>attach_file</mat-icon>
                    <span>Da file</span>
                  </button>
                  <button mat-menu-item
                          (click)="openEditRevisionDialog(element.Azioni)">
                    <mat-icon>skip_previous</mat-icon>
                    <span>Da revisionare</span>
                  </button>
                </mat-menu>
                <!-- Profilo -->

                <!-- Duplica profilo -->
                <!-- TODO Duplica profilo -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="3">
                  <mat-icon>content_copy</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Esporta CSV -->

                <!-- Elenco allegati -->
                <button mat-menu-item *ngSwitchCase="7"
                        (click)="openShowAttachmentsDialog(element.Azioni)">
                  <mat-icon>attach_file</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Elenco note -->
                <button mat-menu-item *ngSwitchCase="6"
                        (click)="openProfileNotesDialog(element.Azioni)">
                  <mat-icon>note</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Elenco revisioni -->
                <!-- TODO Elenco revisioni -->
                <button mat-menu-item (click)="openRevisions(element.Azioni)" *ngSwitchCase="10">
                  <mat-icon>rate_review</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Separatore -->
                <hr *ngSwitchCase="71">
                <!-- CheckOut -->
                <!-- TODO CheckOut -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="12">
                  <mat-icon>first_page</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- CheckIn -->
                <!-- TODO CheckIn -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="13">
                  <mat-icon>input</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Fascicoli che contengono il documento -->
                <!-- TODO Fascicoli che contengono il documento -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="16">
                  <mat-icon>pageview</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Associazioni che contengono il documento -->
                <!-- TODO Associazioni che contengono il documento -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="18">
                  <mat-icon>swap_horiz</mat-icon>
                  <span>{{command.label}}</span>
                </button>

                <!-- Inserisci in fascicolo -->
                <!-- TODO Inserisci in fascicolo -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="15">
                  <mat-icon>create_new_folder</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Inserisci in associazione -->
                <!-- TODO Inserisci in associazione -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="17">
                  <mat-icon>insert_link</mat-icon>
                  <span>{{command.label}}</span>
                </button>
                <!-- Nuova relazione manuale -->
                <!-- TODO Nuova relazione manuale -->
                <button mat-menu-item [disabled]="true" *ngSwitchCase="19">
                  <mat-icon>mediation</mat-icon>
                  <span>{{command.label}}</span>
                </button>
              </ng-container>
            </ng-container>
          </mat-menu>
        </td>
      </ng-container>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nessun record trovato con questo filtro: "{{input.value}}"</td>
    </tr>
  </table>
</div>
</cdk-virtual-scroll-viewport>
