<div style="width: 100%">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; height: 50px; display: flex">
    <h2 style="text-align: center; width: 100%">Elenco note:</h2>

</div>
<div style="text-align: left;height: fit-content; display: block;">
    <div style="width: 100%;text-align: center">
        <button mat-button style="text-align: center;" (click)="this.openAddNoteDialog()"
                style="color: black">
            <mat-icon svgIcon="SendIcon"></mat-icon>
            <span>Aggiungi nota</span>
        </button>
        <mat-accordion *ngFor="let nota of this.note" style="text-align: left" style="color: black">
            <mat-expansion-panel hideToggle style="text-align: left">
                <mat-expansion-panel-header>
                    <b><p style="color: black">{{nota.note}}</p></b>
                </mat-expansion-panel-header>
                <p>Data: {{this.TransformDataFormat(nota.date)}}</p>
                <p>Autore: {{nota.userCompleteName}}</p>
                <button style="color: black" mat-button (click)="deleteNote(nota.id)"
                        style="text-align: right">
                    <mat-icon svgIcon="DocumentIcon" style="color: black"
                              style="margin-right: 8px"></mat-icon>Elimina

                </button>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</div>
