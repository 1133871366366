import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {ApiService} from '../api.service';
@Component({
  selector: 'app-dashnav',
  templateUrl: './dashnav.component.html',
  styleUrls: ['./dashnav.component.css']
})
export class DashnavComponent implements OnInit {
  showFiller = false;
  utente = localStorage.getItem('username');
  constructor(public apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    // this.router.navigate(['defaulthome'], {relativeTo: this.route}) ;
  }

}
