
<!--
    <mat-toolbar color="primary">
      <button mat-icon-button >
        <mat-icon>menu</mat-icon>
      </button>
      DASHBOARD
      <span class="toolbar-spacer"></span>

    </mat-toolbar>

-->


    <!--<mat-card class="card">
      <h3>
        <mat-card-title><p>MENU:</p></mat-card-title>
      </h3>
      <router-outlet></router-outlet>
    </mat-card>
  -->
  <router-outlet></router-outlet>
    

