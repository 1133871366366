import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectedRowsMultiDTO} from '../VociMenu/maskDetails/maskDetails.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiService} from '../api.service';
import {FilterDialogComponent} from '../VociMenu/default-home/default-home.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-dialogtabledto',
  styleUrls: ['tableTDO-css.css'],
  templateUrl: 'dialog-content-tableDTO.html'
})

export class DialogTableDTOComponent implements AfterViewInit  {
  ELEMENT_DATA: Array<object> = new Array<object>();
  arrayToObject: { [index: string]: { content: string } } = {};
  dataSource: MatTableDataSource<object>;
  selection: SelectionModel<object>;
  displayedColumns: Array<string> = new Array<string>();
  squareColor: string;
  dataResult: Array<string> = new Array<string>();
  dataToSave: Array<any> = new Array();
  additionalFieldTableDTOValue: Array<any> = new Array();
  fieldName: string;
  selectField: string;
  find = false;
  isSelected = false;
  mapTableValueToSave: Map<string, string[]> = new Map<string, string[]>();
  filter: string;
  additionalFieldTableDTO: any;
  filterTags: Array<any> = new Array<any>();
  found: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DialogTableDTOComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public apiService: ApiService, private snackBar: MatSnackBar) {
    // console.log(this.data);
    this.fieldName = data.fieldName;
    this.selectField = data.selectField;
    this.additionalFieldTableDTOValue = data.additionalFieldTableDTO;
    this.additionalFieldTableDTO = data.additionalFieldTableDTO;
    if (this.additionalFieldTableDTO.length === 0){
      this.dialogRef.close({dataResult: 'delete'});
      this.snackBar.open('Nessun elemento presente nella fonte dati', null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    }
    data.additionalFieldTableDTO.forEach(element => {
      this.filterTags = new Array<any>();
      this.displayedColumns = new Array<string>();
      this.arrayToObject = {};
      element.forEach(obj => {
        this.displayedColumns.push(obj.id);
        this.filterTags.push({tag: obj.id, active: true});
        this.arrayToObject[obj.id] = obj.value;
      });
      this.ELEMENT_DATA.push(this.arrayToObject);
    });
    const demoRes: Array<any> = new Array<any>();
    this.ELEMENT_DATA.forEach((v, index) => {
      if (index < 25) {
        demoRes.push(v);
      }
    });
    this.dataSource = new MatTableDataSource(demoRes);
    this.filterChange();
    this.loadRows();
    this.squareColor = this.apiService.squareColor;
    this.selection = new SelectionModel<object>(true, []);
  }
  loadRows(): void {
    setTimeout(() => {
      this.filter = '';
      this.filterChange();
    }, 1);
  }
  openFilterDialog(): void {


    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '40%',
      data: {tags: this.filterTags}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.resultFilter) {
        this.filterTags = result.resultFilter;
        this.filterChange();
      }
    });
  }

  filterChange(): void {
    if (this.filter !== null && this.filter !== undefined) {
      this.ELEMENT_DATA = new Array<object>();
      this.additionalFieldTableDTO.forEach(element => {
        this.displayedColumns = new Array<string>();
        this.arrayToObject = {};
        this.found = false;
        element.forEach(obj => {
          this.displayedColumns.push(obj.id);
          this.arrayToObject[obj.id] = obj.value;
          this.filterTags.forEach(tag => {
            if (tag.tag === obj.id && tag.active === true) {
              if (obj.value){
                if (obj.value.toString()){
                  if (obj.value.toString().toLowerCase().includes(this.filter.toLocaleLowerCase())) {
                    this.found = true;
                  }
                }
              }
            }
          });
        });
        if (this.found) {
          this.ELEMENT_DATA.push(this.arrayToObject);
        }

      });

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.selection = new SelectionModel<object>(true, []);
      this.ngAfterViewInit();
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): any {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  deleteField(): void{
    this.dialogRef.close({dataResult: 'delete'});
  }
  saveSelectedValues(): void {
    this.dataResult = new Array<string>();
    this.dataToSave = new Array();
    this.selection.selected.forEach(itemSelected => {
      this.dataResult.push(itemSelected[this.selectField]);
      this.additionalFieldTableDTOValue.forEach(element => {
        this.find = false;
        element.forEach(arrayRow => {
          if (arrayRow.value === itemSelected[this.selectField]) {
            this.find = true;
          }
        });
        if (this.find) {
          this.dataToSave.push(element);
        }
      });
    });
    this.dialogRef.close({dataResult: this.dataResult, dataToSave: this.dataToSave});
  }

  selectedRow(element): void {
    this.isSelected = true;
    this.dialogRef.close({dataResult: element});
  }

  closeDialog(): void {
    this.dialogRef.close({dataResult: false});
  }
}

