<div>
  <button style="float: right;" title="Salva" (click)="closeDialog()" mat-icon-button color="warn" aria-label="icon-button save">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div style="text-align: center"><h2>Indicare il numero di revisione di partenza</h2>
    <mat-form-field>
        <input [(ngModel)]="revisionNumber" type="number" matInput name="value"
               placeholder="Numero revisione di partenza"
        >
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click)="sendRevision()">Invia</button>
</div>
