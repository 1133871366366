<div style="text-align: center; color: black">
    <h2>Cambia url</h2>
    <hr>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>URL</mat-label>
        <input matInput required [(ngModel)]="this.url" type="text">
    </mat-form-field>
    <br>
    <button mat-stroked-button (click)="saveUrl()">Cambia url</button>
    <button title="Chiudi" (click)="closeDialog()" mat-stroked-button color="warn">
        Chiudi
    </button>
    <br>
    <p style="color: red;">{{this.error}}</p>
</div>
