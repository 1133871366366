import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';

@Component({
    selector: 'app-modelli',
    templateUrl: './modelli.component.html',
    styleUrls: ['./modelli.component.css']
})
export class ModelliComponent implements OnInit {
    searchModelModelli: string;
    Modelli: [];
    Cartelle: [];
    panelOpenState = false;
    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;
    nomeCartella: string;
    i = 0;

    constructor(private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) {
        iconRegistry.addSvgIcon(
            'CondividiIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/share-24px.svg'));
        iconRegistry.addSvgIcon(
            'ModificaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/create-24px.svg'));
        iconRegistry.addSvgIcon(
            'ClonaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/content_copy-24px.svg'));
        iconRegistry.addSvgIcon(
            'PermessiiIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/lock-24px.svg'));
        iconRegistry.addSvgIcon(
            'EliminaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/delete-24px.svg'));

        iconRegistry.addSvgIcon(
            'CartellaIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/folder-24px.svg'));
    }

    ngOnInit(): void {
        this.apiService.namePath = this.apiService.namePath.split('-')[0];
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
        this.nomeCartella = 'CartellaIcon';
        this.apiService.getModelGroups().subscribe(data => {
            this.Cartelle = data;
        });


        this.apiService.getModels().subscribe(data => {
            // console.log(data);
            this.Modelli = data;
        });


    }

    openModel(idModell: string, maskIdd: string): void {
        this.router.navigate(['../modeldetail', {idModel: idModell, maskId: maskIdd, idTask: 0, path: 'modelli'}], {relativeTo: this.route});
    }

    updateSearchModelModelli(value): void {

    }

}
