<mat-card style="text-align: center; width: 95%; height: 30%; margin: auto; resize: vertical;
  overflow: auto;" *ngIf="this.hasPreview">
  <button mat-icon-button style="float: right" (click)="this.openFullPreview()">
    <mat-icon>fullscreen</mat-icon>
  </button>
  <cdk-virtual-scroll-viewport itemSize="50" style="height: 100%; width: 100%" id="style-5">
    <button mat-icon-button title="Pagina precedente" (click)="this.prevPage()" [disabled]="this.index == 0">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <span>{{this.index + 1}}/{{this.maxPages}}</span>
    <button mat-icon-button title="Pagina successiva" [disabled]="this.index + 1 == this.maxPages"
            (click)="this.nextPage()">
      <mat-icon>arrow_right</mat-icon>
    </button>
    <div>
      <img id="myimage" [src]='this.image'/>
    </div>
  </cdk-virtual-scroll-viewport>
</mat-card>

<div class="center" [ngStyle]="{'height': this.hasPreview ? '60%' : ''}">


  <mat-card class="cardcomponentMasks1" style="position: relative" id="style-4">
    <div style="display: flex; text-align: left;   width: 100%;
    "> <!--
      <button *ngIf="this.takeCharge == false && this.canExit == true" title="Esito" style="color: black"
              (click)="openActionDialog()"
              [disabled]="!this.activeButtons" mat-button
              aria-label="Procedi">
        <mat-icon>send</mat-icon>
        Procedi

      </button>  -->
      <div style="width: 50%">
        <mat-form-field style="width: 75%; padding-right: 5%; height: 10%;" id="esito"
                        appearance="standard" *ngIf="!this.takeCharge == true">
          <mat-label>Azione</mat-label>
          <mat-select name="esito" (selectionChange)="exitSelected($event.value)" (click) = "this.loadExitCodesWithError()" [(value)]="this.selected">
            <mat-option *ngFor="let esito of this.exitCodes" [value]="esito.value">
              {{esito.value}}
            </mat-option>
          </mat-select>

        </mat-form-field>
        <button mat-stroked-button (click)="this.exitTask('')"
                style=" width: fit-content; color: black; margin-left: 5%">Invia
        </button>
      </div>


      <!-- <mat-form-field style="width: 50%; height: auto; " appearance="fill">
           <mat-label>Commento</mat-label>
           <textarea matInput rows="5"></textarea>
       </mat-form-field>-->
      <div style="width: 50%; text-align: center;
    margin: auto;
">
        <button *ngIf="this.takeCharge == true" title="Prendi in carico" (click)="doTakeCharge()" mat-button
                style="color: black"
                aria-label="Prendi in carico">
          <mat-icon>pan_tool</mat-icon>
          Prendi in carico

        </button>
        <button *ngIf="this.showProcessVisible" title="Processo" (click)="processClick()" style="color: black"
                mat-button
                aria-label="Processo">
          <mat-icon>developer_board</mat-icon>
          Processo

        </button>
        <button title="Note" (click)="openNotesClick()" style="color: black"
                mat-button matBadge="{{this.notesNumber}}" matBadgeOverlap="false"
                aria-label="Note">
          <mat-icon>note</mat-icon>
          Note

        </button>
      </div>
      <!-- <button  mat-button (click)="onNoClick()" style="text-align: center">Annulla</button>-->


    </div>
    <!--
            <h3 class="titles">
                <mat-card-title>
                    <button style="margin-right: 96%; color: {{this.dashColor}}" title="Indietro" (click)="goBack()"
                            mat-icon-button
                            aria-label="icon-button send">
                        <mat-icon>keyboard_backspace</mat-icon>
                    </button>
                </mat-card-title>
            </h3>
            <hr class="riga" style="color: {{this.dashColor}}">
            <br>
    -->
    <div style="height: fit-content;">
      <div style="text-align: left;width: 100%; color: {{this.dashColor}}">
        <h2>{{this.taskName}}</h2>
        <p [innerHTML]="this.taskDescription" style="text-align: left"></p>
      </div>
    </div>


  </mat-card>
  <mat-card class="cardcomponentMasks1" id="style-4">
    <h1 style="color: black">Documenti:</h1>
    <ng-container *ngIf="isLoading" style="text-align: center;">
      <mat-progress-bar *ngIf="this.isLoading"
                        color="warn"
                        mode="buffer"
                        class="example-tree-progress-bar"></mat-progress-bar>
    </ng-container>
    <div style="text-align: left;" *ngIf="!isLoading">
      <mat-selection-list #documenti [multiple]="false">
        <ng-container *ngFor="let doc of this.documentFields">
          <ng-container *ngFor="let campo of doc.fields">
            <b
              *ngIf="campo.name == 'DocumentType'">
              <h3>{{campo.classDescription}}:</h3>
            </b>

          </ng-container>
          <mat-accordion><!--*ngIf="doc.id == this.activeDocNumber"-->
            <mat-expansion-panel style="display:contents;!important;" [expanded]="this.documentFields.length == 1"
                                 (opened)="panelOpenState = true" (dblclick)="this.profileClick(doc.id)"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header class="hover">
                <mat-panel-title>
                  <ng-container *ngIf="doc.id == this.activeDocNumber"><b
                    style="color: {{this.squareColor}};">SystemID: {{doc.id}}</b>
                  </ng-container>
                  <b *ngIf="doc.id != this.activeDocNumber">SystemID: {{doc.id}} </b>
                </mat-panel-title>
                <mat-panel-description>
                  <ng-container *ngFor="let campo of doc.fields">
                    <b
                      *ngIf="campo.className == 'BusinessUnitFieldDTO'">Azienda:
                      <ng-container *ngFor="let aoo of nomiAoo">
                        <ng-container *ngIf="campo.value === aoo.code">
                          {{aoo.name}}
                        </ng-container>
                      </ng-container>
                    </b>

                  </ng-container>
                  <ng-container *ngIf="doc.profileInfo.fileName != ''">
                    <mat-icon style="margin-left: 15px; color:{{this.dashColor}}">article
                    </mat-icon>
                  </ng-container>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let campo of doc.fields">
                <p *ngIf="campo.className == 'DocumentDateFieldDTO' && campo.value != null"> Data
                  documento: {{this.TransformDataFormat(campo.value)}}</p>
                <p *ngIf="campo.className == 'SubjectFieldDTO' && campo.value != ''">
                  Oggetto: {{campo.value}}</p>
                <p *ngIf="campo.className == 'FromFieldDTO' && campo.value != null">Da:
                  <ng-container
                  >{{campo.value.society}}</ng-container>
                </p>
                <p *ngIf="campo.className == 'ToFieldDTO' && campo.value != null">A:
                  <ng-container
                    *ngFor="let field of campo.value">{{field.society}},
                  </ng-container>
                </p>
                <p *ngIf="campo.className == 'NumberFieldDTO' && campo.value != ''">
                  Numero: {{campo.value}}</p>
              </ng-container>

              <div align="start">
                <!--<button mat-button (click)="openPreviewDialog(doc.id)" style="text-align: right">
                    Anteprima
                    <mat-icon svgIcon="DocumentIcon" style="margin-right: 8px"></mat-icon>
                </button>-->
                <button mat-button (click)="openPreviewDialog(doc.id)">
                  <mat-icon style="margin-right: 15px;" svgIcon="DocumentIcon"></mat-icon>
                  Anteprima profilo

                </button>
                <button (click)="this.profileClick(doc.id)"
                        style="color: black; " mat-button
                        aria-label="icon-button send">
                  <mat-icon style="color: #29648A;" svgIcon="ProfileIcon" style="margin-right: 15px;"
                            style="color: black"></mat-icon>
                  Apri profilo
                </button>
                <button style="color: black; " mat-button
                        (click)="this.downloadDoc(doc.id, doc.profileInfo.fileName)"
                        aria-label="icon-button send">
                  <mat-icon svgIcon="OpenIcon" style="color: black" style="margin-right: 15px;"></mat-icon>
                  Scarica documento
                </button>
                <button mat-button (click)="openAttachmentsDialog()" style="color: black; ">
                  <mat-icon style="color: black" svgIcon="AttachIcon" style="margin-right: 15px;"></mat-icon>
                  <span style="color: black">Elenco allegati</span>
                </button>
                <!--<button mat-menu-item>
                    <mat-icon svgIcon="NotesIcon"></mat-icon>
                    <span>Elenco note</span>
                </button>-->
                <!--<button mat-menu-item disabled>
                    <mat-icon svgIcon="ExportIcon"></mat-icon>
                    <span>Esporta</span>
                </button>-->
                <button mat-button [matMenuTriggerFor]="modifica" style="color: black; ">
                  <mat-icon style="color: black" svgIcon="EditIcon" style="margin-right: 15px;"></mat-icon>
                  <span style="color: black">Modifica documento</span>
                </button>

                <!-- <button mat-menu-item disabled>
                     <mat-icon svgIcon="ShareIcon"></mat-icon>
                     <span>Condivisione documento</span>
                 </button>-->
                <mat-menu #modifica="matMenu" style="color: black; ">
                  <button mat-menu-item (click)="openEditFileDialog(doc.id)" style="color: black">
                    <mat-icon style="color: black">attach_file</mat-icon>
                    <span style="color: black">Da file</span>
                  </button>
                  <button mat-menu-item style="color: black"
                          (click)="openEditRevisionDialog(doc.id)">
                    <mat-icon style="color: black">skip_previous</mat-icon>
                    <span>Da revisionare</span>
                  </button>
                </mat-menu>
                <button mat-button (click)="openPreview(doc.id, doc.profileInfo.revision)" style="color: black; "
                        *ngIf="false">
                  <mat-icon style="color: black" style="margin-right: 15px;">preview</mat-icon>
                  <span style="color: black">Anteprima documento</span>
                </button>
                <button mat-button (click)="loadTaskPreview()" style="color: black; "
                        *ngIf="!this.hasPreview && doc.id == this.activeDocNumber">
                  <mat-icon style="color: black" style="margin-right: 15px;">cached</mat-icon>
                  <span style="color: black">Elabora anteprima</span>
                </button>
                <button mat-button (click)="hidePreview()" style="color: black;"
                        *ngIf="this.hasPreview && doc.id == this.activeDocNumber">
                  <mat-icon style="color: black" style="margin-right: 15px;">hide_image</mat-icon>
                  <span style="color: black">Nascondi anteprima</span>
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-selection-list>
    </div>
    <div style="height: fit-content; display: flex;">
      <div style="width: 100%;">
        <ng-container *ngIf="this.taskWorkDocumentOperations != null">
          <h2 style="color: black">Operazioni:</h2>
          <ng-container *ngFor="let operazione of this.taskWorkDocumentOperations">
            <button mat-button
                    (click)="openMask(operazione.maskId, operazione.id, operazione.moduleId, operazione.viewId)">
              <mat-icon style="color: black">add</mat-icon>
              <ng-container *ngIf="operazione.isExecuted">
                <mat-icon style="color: black">check</mat-icon>
              </ng-container>
              <span style="color: black">{{operazione.description}}
                <ng-container
                  *ngIf="operazione.isRequired == true && operazione.isExecuted == false">*</ng-container></span>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.taskWorkSignOperations != null">
          <h2 style="color: black">Sign Operations:</h2>
          <ng-container *ngFor="let operazione of this.taskWorkDocumentOperations">

            <button mat-button style="color: black">
              <mat-icon style="color: black">add</mat-icon>
              <span style="color: black">{{operazione.description}}</span>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.taskWorkCommandsOperations != null">
          <h2 style="color: black">Commands Operations:</h2>
          <ng-container *ngFor="let operazione of this.taskWorkCommandsOperations">

            <button mat-button style="color: black">
              <mat-icon style="color: black">add</mat-icon>
              <span style="color: black">{{operazione.description}}</span>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.taskWorkDynamicJobOperation != null">
          <h2 style="color: black">Dynamic Operations:</h2>
          <ng-container>

            <button mat-button style="color: black">
              <mat-icon style="color: black">add</mat-icon>
              <span style="color: black">{{operazione.description}}</span>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.taskWorkOperatingInstructions != null">
          <h2 style="color: black">Operating Operations:</h2>
          <ng-container *ngFor="let operazione of this.taskWorkOperatingInstructions">

            <button mat-button style="color: black">
              <mat-icon style="color: black">add</mat-icon>
              <span style="color: black">{{operazione.description}}</span>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.taskWorkProfessionalRoleOperations != null">
          <h2 style="color: black">Professional Operations:</h2>
          <ng-container>

            <button mat-button style="color: black">
              <mat-icon style="color: black">add</mat-icon>
              <span style="color: black">{{operazione.description}}</span>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.taskWorkVariablesOperation.processVariables">
          <h2 style="color: black">
            <ng-container style="color: red"
                          *ngIf="taskWorkVariablesOperation.required == true && taskWorkVariablesOperation.executed == false">
              *
            </ng-container>
            Variabili:
          </h2>
          <ng-container *ngFor="let operazione of this.booleanVariables">
            <section class="example-section" style="color: black">
              <mat-slide-toggle [(ngModel)]="operazione.value" style="color: black"
                                (ngModelChange)="boolChange($event, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                                class="example-margin"
                                [color]="color"
                                [checked]="operazione.value">
                {{operazione.description}}
              </mat-slide-toggle>
            </section>
          </ng-container>
          <ng-container *ngFor="let operazione of this.stringVariables">
            <mat-form-field class="example-full-width" style="color: black">
              <mat-label style="color: black">{{operazione.description}}</mat-label>
              <input matInput [required]="operazione.isRequired" style="color: black"
                     (ngModelChange)="stringChange($event, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                     placeholder="{{operazione.description}}" [ngModel]="operazione.value">
            </mat-form-field>
          </ng-container>
          <ng-container *ngFor="let operazione of this.comboVariables">
            <mat-form-field appearance="fill" style="margin-left: 10%; width: 60%">
              <mat-label style="color: black">{{operazione.description}}</mat-label>
              <mat-select [(value)]="operazione.value" style="color: black"
                          (selectionChange)="comboSelected($event.value, operazione.name, operazione.className, operazione.taskWorkVariableId);">
                <ng-container *ngFor="let valore of this.comboValues">
                  <ng-container *ngFor="let value of valore.columns">
                    <mat-option [value]="value.value">

                      {{value.value}}

                    </mat-option>
                  </ng-container>
                </ng-container>
              </mat-select>
            </mat-form-field>

          </ng-container>
          <ng-container *ngFor="let operazione of this.doubleVariables">
            <mat-form-field class="example-full-width">
              <mat-label style="color: black">{{operazione.description}}</mat-label>
              <input style="color: black" matInput type="number" [required]="operazione.required"
                     (ngModelChange)="doubleChange($event, operazione.name, operazione.className, operazione.taskWorkVariableId);"
                     placeholder="{{operazione.description}}" [ngModel]="operazione.value">
            </mat-form-field>

          </ng-container>
        </ng-container>
      </div>

    </div>
  </mat-card>
</div>
