import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../api.service';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DialogAddressBookComponent} from '../additionalDialog/dialogAddressBookComponent';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


export interface tabElement {
    idFatt: string;
    lottoIndex: number;
    docNumber: string;
    docDate: string;
    state: string;
    elState: string;
    fileName: string;
}


const contTab: tabElement[] = [];

export interface tabElementRic {
    idSdi: string;
    idFatt: string;
    fileName: string;
    lottoIndex: number;
    docNumber: string;
    docDate: string;
    ricDate: string;
    stateDate: string;
    state: string;
    elState: string;
}

const contTabRic: tabElementRic[] = [];

export interface invNotRic{
    idNot: string;
    state: string;
    date: string;
    idFatt: string;
}

const tabInvRic: invNotRic[] = [];

@Component({
    selector: 'app-ixfe',
    templateUrl: './ixfe.component.html',
    styleUrls: ['./ixfe.component.css']
})
export class IxfeComponent implements OnInit {
    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;
    token: string;
    username: string;
    password: string;
    idAoos: string;
    identificativi: string[];
    aoosNames: string[];
    test: any;
    @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
    @ViewChildren(MatSort) sort = new QueryList<MatSort>();

    dataSource;
    dataSourceRic;
    displayedColumns: string[] = ['idFatt', 'lottoIndex', 'docNumber', 'docDate', 'elState', 'state', 'descState', 'notification', 'download'];
    displayedColumnsRic: string[] = ['idSdi', 'lottoIndex', 'docNumber', 'stateDate', 'docDate', 'elState', 'state', 'descState', 'notification', 'download'];


    constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
                sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;

    }

    login(): void {
        this.apiService.loginIxFe(this.username, this.password).subscribe(data => {
            this.token = data.access_token;
            this.apiService.getAoosDetails(this.token).subscribe(data => {
                // console.log(data);
                // console.log(data.aoos);
                if (data.aoos.length === 1) {
                    this.idAoos = data.aoos[0].identificativo;
                    this.loadSent();
                    this.loadRic();
                } else if (data.aoos.length > 1) {
                    this.test = data.aoos;
                }
            });
        }, errorLog => {
            this.snackBar.open(errorLog.error.error, null, {
                duration: 5000,
                horizontalPosition: 'end',
verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
        });
    }

    setIdentificativo(e): void {
        // console.log(e);
        this.idAoos = e.value;
        this.loadSent();
        this.loadRic();
    }

    loadSent(): void {
        this.apiService.getSentInvoices(this.token, this.idAoos).subscribe(data => {
            // console.log(data);
            data.fatture.forEach(v => {
              contTab.push({
                            idFatt: v.identificativo,
                            lottoIndex: v.profiliFattura[0].indiceLotto,
                            elState: v.profiliFattura[0].statoElaborazione,
                            docDate: this.datePipe.transform(v.profiliFattura[0].dgdData, 'yyyy-MM-dd', '', 'en-GB'),
                            docNumber: v.profiliFattura[0].dgdNumero,
                            state: v.profiliFattura[0].stato,
                            fileName: v.nomeFile});
            });
            this.dataSource = new MatTableDataSource(contTab);
            this.dataSource.sort = this.sort.toArray()[0];
            this.dataSource.paginator = this.paginator.toArray()[0];
        });
    }

    loadRic(): void {
        this.apiService.getRicInvoices(this.token, this.idAoos).subscribe(data => {
            // console.log(data);
            data.fatture.forEach( v => {
                contTabRic.push({
                    idSdi: v.identificativoSdI,
                    idFatt: v.identificativo,
                    lottoIndex: v.profiliFattura[0].indiceLotto,
                    docNumber: v.profiliFattura[0].dGDNumero,
                    stateDate: this.datePipe.transform(v.profiliFattura[0].dataStato, 'yyyy-MM-dd', '', 'en-GB'),
                    docDate: this.datePipe.transform(v.profiliFattura[0].dGDData, 'yyyy-MM-dd', '', 'en-GB'),
                    ricDate: this.datePipe.transform(v.dataRicezione, 'yyyy-MM-dd', '', 'en-GB'),
                    state: v.profiliFattura[0].stato,
                    elState: v.profiliFattura[0].statoElaborazione,
                    fileName: v.nomeFile
                });
            });
            this.dataSourceRic = new MatTableDataSource(contTabRic);
            this.dataSourceRic.sort = this.sort.toArray()[1];
            this.dataSourceRic.paginator = this.paginator.toArray()[1];
        });
    }

    downloadPDF(idFatt: string, lottoIndex: string, fileName: string): void{
        this.apiService.downloadPDF(this.token, idFatt, lottoIndex).subscribe( data => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName + '.pdf';
            link.click();
        });
    }

    downloadHTML(idFatt: string, fileName: string): void{
        this.apiService.downloadHTML(this.token, idFatt).subscribe( data => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName + '.html';
            link.click();
        });
    }

    downloadFILE(idFatt: string, fileName: string): void{
        this.apiService.downloadFILE(this.token, idFatt).subscribe( data => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName;
            link.click();
        });
    }

    downloadPDFRic(idFatt: string, lottoIndex, fileName: string): void{
        this.apiService.downloadPDFRic(this.token, idFatt, lottoIndex).subscribe( data => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName + '.pdf';
            link.click();
        });
    }

    downloadHTMLRic(idFatt: string, fileName: string): void{
        this.apiService.downloadHTMLRic(this.token, idFatt).subscribe( data => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName + '.html';
            link.click();
        });
    }

    downloadFILERic(idFatt: string, fileName: string): void{
        this.apiService.downloadFILERic(this.token, idFatt).subscribe( data => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName;
            link.click();
        });
    }

    openDialog(idFatt: string): void{
        const dialogRef = this.dialog.open(NotifySentDialogComponent, {
            width: '100%',
            data: {
                idFattura: idFatt,
                token: this.token
            }
        });
        dialogRef.afterClosed().subscribe(result => {});
    }

    openDialogRic(idFatt: string): void{
        const dialogRef = this.dialog.open(NotifyRicDialogComponent, {
            width: '100%',
            data: {
                idFattura: idFatt,
                token: this.token
            }
        });
        dialogRef.afterClosed().subscribe(result => {});
    }
    goBack(): void{
        this.token = null;
    }
}

@Component({
    selector: 'notifySentDialog',
    templateUrl: 'notifySentDialog.html',
})
export class NotifySentDialogComponent {

    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns = ['idNot', 'state', 'date', 'idFatt'];
    constructor(
        public dialogRef: MatDialogRef<NotifySentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, public apiService: ApiService) {
        this.apiService.getSentNotifications(data.token, data.idFattura).subscribe( data => {
            tabInvRic.forEach( v => {
                tabInvRic.pop();
            });
            data.statiComuni.forEach( v => {
                    tabInvRic.push({
                        idNot: v.identificativo,
                        state: v.tipo,
                        date: v.dataOra,
                        idFatt: data.idFattura
                    });
                });
            this.dataSource = new MatTableDataSource(tabInvRic);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}



@Component({
    selector: 'notifyRicDialog',
    templateUrl: 'notifyRicDialog.html',
})
export class NotifyRicDialogComponent {

    dataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns = ['idNot', 'state', 'date', 'idFatt'];
    constructor(
        public dialogRef: MatDialogRef<NotifyRicDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, public apiService: ApiService) {
            tabInvRic.forEach( v => {
                tabInvRic.pop();
            });
            this.apiService.getRicNotifications(data.token, data.idFattura).subscribe( abc => {
                // console.log(abc);
                abc.notifiche.forEach( v => {
                    tabInvRic.push({
                        idNot: v.identificativo,
                        state: v.stato,
                        date: v.dataStato,
                        idFatt: v.fattura.identificativo
                    });
                });
                this.dataSource = new MatTableDataSource(tabInvRic);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}


