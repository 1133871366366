<div style="width: 100%; height: 5%;">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>

<h2>Filtra su:</h2>
<div style="display: block">
    <ng-container *ngFor="let tag of this.filterTags">
        <div style="width: 33%">
            <mat-checkbox style="margin: 0 10px;"
                          [(ngModel)]="tag.active">
                {{tag.tag}}
            </mat-checkbox>
        </div>
    </ng-container>
</div>
<div style = "float: right;">
    <button mat-stroked-button (click) = "selectAll()">
        Seleziona tutti
    </button>
    <button mat-stroked-button (click) = "deselectAll()">
        Deseleziona tutti
    </button>
    <button style = "color: green" mat-stroked-button (click) = "saveDialog()">
        Salva
    </button>

</div>

