<div style = "text-align: center;" class="sticky">
  <button mat-icon-button title = "Chiudi" (click)="this.closeDialog()" style = "float: right; color: red;"><mat-icon>close</mat-icon></button>
  <button mat-icon-button title = "Pagina precedente" (click)="this.prevPage()" [disabled]="this.index == 0"><mat-icon>arrow_left</mat-icon></button>
  <span>{{this.index + 1}}/{{this.maxPages}}</span>
  <button mat-icon-button title = "Pagina successiva" [disabled]="this.index + 1 == this.maxPages" (click)="this.nextPage()"><mat-icon>arrow_right</mat-icon></button>
</div>
<div>
  <img style="width: 100%; height: 100%;" id="myimage" [src]='this.image'/>
</div>

