<span *ngIf="this.apiService.isLoggedIn()" style="color: white !important;"><!--<button title="IX-FE" mat-stroked-button
                                                                                    style=" margin-right: 10px; vertical-align: middle; margin-bottom: 4px" *ngIf="this.user.toLocaleLowerCase() == 'admin'"
                                                                                    (click)="openIxFe()"><mat-icon
        style="color: black">build</mat-icon></button>--></span>





<!--       *****************         -->
<mat-menu #menud="matMenu">
    <button mat-menu-item (click)="openChangeUrlDialog()">
        Cambia url base
    </button>
</mat-menu>
<span>
    <button mat-stroked-button style="vertical-align: middle; margin-bottom: 4px; font-size: large;"
            [matMenuTriggerFor]="menu">{{this.user}}
   </button></span>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openChangePasswordDialog()">
        <mat-icon>vpn_key</mat-icon>
        <span>Cambia password</span>
    </button>
    <button mat-menu-item (click)="openSettings()">
      <mat-icon>settings</mat-icon>
      <span>Impostazioni</span>
    </button>
</mat-menu>
<mat-icon (click)="ClickLogOut()"
          style="color: black; cursor: pointer; margin-left: 10px; margin-right: 10px; vertical-align: middle; margin-bottom: 4px">
    exit_to_app
</mat-icon>
<!--<mat-icon (click)="ClickHome()" svgIcon="Home"style="margin: 20px" ></mat-icon>-->


