<style>
  .material-symbols-outlined {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
</style>

<ng-template
  #monthCellTemplate
  let-day="day"
  let-openDay="openDay"
  let-locale="locale"
  let-tooltipPlacement="tooltipPlacement"
  let-highlightDay="highlightDay"
  let-unhighlightDay="unhighlightDay"
  let-eventClicked="eventClicked"
>
  <div
    class="fill-height"
    [contextMenu]="basicMenu"
    [contextMenuSubject]="day.date"
  >
    <div class="cal-cell-top" style="text-align: left !important;">

      <div style="width: 100%; display: flex" *ngIf="this.isEventSetForUser(day.date)">
        <mat-icon >done</mat-icon>
        <span style="margin: 2%">{{this.getEventType(day.date)}}</span>
      </div>
      <hr style="color: {{this.apiService.dashColor}}; margin: 0">
      <div style="width:100%; text-align: left">
        <span *ngIf="day.badgeTotal > 0" style="margin: 2%"
        >Presenze compilate: {{ day.badgeTotal }}</span
        ></div>


      <span class="cal-day-number"
      >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
      >

    </div>

    <div class="cal-events">
      <div
        class="cal-event"
        *ngFor="let event of day.events"

        [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({event: event})"
        (mouseleave)="unhighlightDay.emit({event: event})"
        [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement"
        (click)="$event.stopPropagation(); eventClicked.emit({event: event})"
      ></div>
    </div>
  </div>
</ng-template>


<context-menu #basicMenu>
  <ng-template contextMenuItem (execute)="addEvent($event.item, 'Giornata intera in presenza')">
    Giornata intera in presenza
  </ng-template>
  <ng-template contextMenuItem (execute)="addEvent($event.item, 'Mattino in presenza')">
    Mattino in presenza
  </ng-template>
  <ng-template contextMenuItem (execute)="addEvent($event.item, 'Off- Ferie, Malattia, Permesso')">
    Off- Ferie, Malattia, Permesso
  </ng-template>
  <ng-template contextMenuItem (execute)="addEvent($event.item, 'Pomeriggio in presenza')">
    Pomeriggio in presenza
  </ng-template>
  <ng-template contextMenuItem (execute)="addEvent($event.item, 'Smart working')">
    Smart working
  </ng-template>
  <ng-template contextMenuItem (execute)="removeEvent($event.item)">
    <span style="color: red">Rimuovi</span>
  </ng-template>
</context-menu>

<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4">
    <h3 class="titles" style="color:{{this.apiService.dashColor}};">
      <mat-card-title><p>Tabella presenze:</p>

      </mat-card-title>
    </h3>
    <hr class="riga" style="color:{{this.apiService.dashColor}}; margin-bottom: 1% !important">

    <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->
      <div style="heigth: 5%; width: 100%; display: flex; margin-bottom: 1%">
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #de7565">
            radio_button_unchecked
          </mat-icon>
          <span style="width: fit-content;    margin: auto;"><b>Giornata intera in presenza</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #f4ccac">
            radio_button_unchecked
          </mat-icon>
          <span style="width: fit-content;    margin: auto;"><b>Mattino in presenza</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #6a6a6a">
            radio_button_unchecked
          </mat-icon>

          <span style="width: fit-content;    margin: auto;"><b>Off- Ferie, Malattia, Permesso</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #c2cf9d">
            radio_button_unchecked
          </mat-icon>

          <span style="width: fit-content;    margin: auto;"><b>Pomeriggio in presenza</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #b8c1df">
            radio_button_unchecked
          </mat-icon>

          <span style="width: fit-content;    margin: auto; "><b>Smart Working</b></span>
        </div>

      </div>
      <hr class="riga" style="color:{{this.apiService.dashColor}};">
      <div style="display: flex">
        <div style="width: 40%; text-align: left">
          <h1>{{ viewDate | calendarDate:(view + 'ViewTitle'):'it' }}</h1>
        </div>

        <div style="width: 60%; text-align: right">
          <div class="btn btn-primary" style = "background-color: {{this.apiService.dashColor}}; border-color: {{this.apiService.dashColor}}" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            Indietro
          </div>
          <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Oggi
          </div>
          <div class="btn btn-primary" style = "background-color: {{this.apiService.dashColor}}; border-color: {{this.apiService.dashColor}}" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
            Avanti
          </div>
        </div>


      </div>
      <mwl-calendar-month-view
        id="calendario"
        [viewDate]="viewDate"
        (dayClicked)="cellClicked($event)"
        [events]="events"
        [refresh]="refresh"
        [locale]="locale"
        [weekStartsOn]="weekStartsOn"
        [cellTemplate]="monthCellTemplate"
        [weekendDays]="weekendDays">
      </mwl-calendar-month-view>
    </mat-card-content>
    <div style="text-align: left;">

    </div>

  </mat-card>
</div>

