import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ApiService} from '../../api.service';
import {DatePipe} from '@angular/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleMapComponent} from '../google-map/google-map.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {ProgressSpinnerDialogComponent} from '../../additionalDialog/progress-spinner-dialog/progress-spinner-dialog.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ChangePasswordDialogComponent} from '../../layout/nav-bar/nav-bar.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-programma-presenze',
  templateUrl: './programma-presenze.component.html',
  styleUrls: ['./programma-presenze.component.css']
})
export class ProgrammaPresenzeComponent implements OnInit, AfterViewInit {
  groupsCluster = ['TUTTI'];
  groupsDepartment = ['TUTTI'];
  groupsZone = ['TUTTI'];
  zoneGroup = 'TUTTI';
  clusterGroup = 'TUTTI';
  departmentGroup = 'TUTTI';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  indexCategoria: number;
  oldFilter: any;
  activeBoltFilter = false;
  indexZona: number;
  indexCluster: number;
  indexDepartment: number;
  indexCognome: number;
  filter: string;
  firstIn = true;
  currentUserName = localStorage.getItem('username');
  indexCN: number;
  indexNomeVero: number;
  contatorePresenze: Array<any> = new Array<any>();
  displayedColumns: string[] = ['Cluster', 'Risorsa', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  months = [
    {value: 1, viewValue: 'Gennaio'},
    {value: 2, viewValue: 'Febbraio'},
    {value: 3, viewValue: 'Marzo'},
    {value: 4, viewValue: 'Aprile'},
    {value: 5, viewValue: 'Maggio'},
    {value: 6, viewValue: 'Giugno'},
    {value: 7, viewValue: 'Luglio'},
    {value: 8, viewValue: 'Agosto'},
    {value: 9, viewValue: 'Settembre'},
    {value: 10, viewValue: 'Ottobre'},
    {value: 11, viewValue: 'Novembre'},
    {value: 12, viewValue: 'Dicembre'}
  ];
  zoneMap: Map<string, any> = new Map<string, any>();

  bodyGetPresenze = {
    id: '52f2fdf7ef274699b914deb2f65ca1c0',
    searchFilterDto: {
      description: null,
      daAAndOr: 0,
      fields: []
    },
    selectFilterDto: {
      fields: [
        {
          fieldType: 2,
          selected: true,
          name: 'DATA144_14',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 2,
          selected: true,
          name: 'COMBO146_14',
          index: 1,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 2,
          selected: true,
          name: 'COMBO147_14',
          index: 2,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'DOCNUMBER',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'REVISIONE',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'AOO',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'ORIGINALE',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'CLASSEDOC',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        },
        {
          fieldType: 0,
          selected: true,
          name: 'WFVERSIONSTATE',
          index: 0,
          orderBy: {
            direction: 0,
            index: 0
          }
        }
      ],
      maxItems: 0
    },
    maxItems: 0
  };
  bodyGetZone = {
    id: '8a75edc2b76f4bab97cce46a0af871c7',
    searchFilterDto: {
      daAAndOr: 1
    }
  };

  bodyGetDipendenti = {
    id: 'bb200ebbb2d04a2aa428e3e57bb86359',
    searchFilterDto: {
      daAAndOr: 1
    }
  };
  countGiornataPresenza = 0;
  countGiornataMattino = 0;
  countGiornataPomeriggio = 0;
  countGiornataSmart = 0;
  countGiornataOff = 0;
  selectedMonth = new Date().getMonth() + 1;
  year = new Date().getFullYear();
  years = [this.year - 5, this.year - 4, this.year - 3, this.year - 2, this.year - 1, this.year, this.year + 1, this.year + 2, this.year + 3, this.year + 4, this.year + 5];
  tableData: Array<any> = new Array<any>();
  rowTemplate = {
    Risorsa: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    13: '',
    14: '',
    15: '',
    16: '',
    17: '',
    18: '',
    19: '',
    20: '',
    21: '',
    22: '',
    23: '',
    24: '',
    25: '',
    26: '',
    27: '',
    28: '',
    29: '',
    30: '',
    31: ''
  };
  indexNome: number;
  indexCodice: number;
  events: any;
  dataSource = new MatTableDataSource();
  bodySearchUtentiInterni = {
    searchdto: {
      dateTimeFields: [
        {
          operator: 0,
          valore1: '0001-01-01T00:00:00',
          valore2: '0001-01-01T00:00:00',
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 1,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'FEAEXPIREDATE',
          externalId: null,
          description: 'Scadenza FEA (Contatto)',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchDateTimeDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        }
      ],
      stringFields: [
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'ABITAZIONE',
          externalId: null,
          description: 'Abitazione',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'CELL',
          externalId: null,
          description: 'Cellulare',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'CONTATTO',
          externalId: null,
          description: 'Nominativo',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'EMAIL',
          externalId: null,
          description: 'Email',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'FAX',
          externalId: null,
          description: 'Fax',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'MANSIONE',
          externalId: null,
          description: 'Mansione',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'REPARTO',
          externalId: null,
          description: 'Reparto',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'TEL',
          externalId: null,
          description: 'Telefono',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'UFFICIO',
          externalId: null,
          description: 'Ufficio',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.AOO',
          externalId: null,
          description: 'Aoo',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.AOORUBRICA',
          externalId: null,
          description: 'Aoo rubrica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CAP',
          externalId: null,
          description: 'Cap',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CELL',
          externalId: null,
          description: 'Cellulare',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CLASSE',
          externalId: null,
          description: 'Classe',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CODFIS',
          externalId: null,
          description: 'Codice Fiscale',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CODICE',
          externalId: null,
          description: 'Codice',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.FAX',
          externalId: null,
          description: 'Fax',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.INDIRIZZO',
          externalId: null,
          description: 'Indirizzo',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.LOCALITA',
          externalId: null,
          description: 'Località',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.MAIL',
          externalId: null,
          description: 'E-Mail Rubrica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.NAZIONE',
          externalId: null,
          description: 'Nazione',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.NOTERUBRICA',
          externalId: null,
          description: 'Note rubrica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.PARTIVA',
          externalId: null,
          description: 'Partita Iva',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.PROVINCIA',
          externalId: null,
          description: 'Provincia',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.RAGIONE_SOCIALE',
          externalId: null,
          description: 'Ragione sociale',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 1,
          valore1: 'P',
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: 'A',
          name: 'Dm_Rubrica.STATO',
          externalId: null,
          description: 'Stato',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.TEL',
          externalId: null,
          description: 'Telefono',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 1,
          valore1: 'U',
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.TIPO',
          externalId: null,
          description: 'Tipologia rubrica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.UCONTATTI',
          externalId: null,
          description: 'Dettaglio anagrafica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_CatRubriche.RUBRICA',
          externalId: null,
          description: 'Rubrica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'NOME',
          externalId: null,
          description: 'Nome (Contatto)',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'COGNOME',
          externalId: null,
          description: 'Cognome (Contatto)',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'PEC',
          externalId: null,
          description: 'Pec (Contatto)',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CODICEUFFICIO',
          externalId: null,
          description: 'Codice ufficio',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.CODICEIPA',
          externalId: null,
          description: 'Codice Ipa',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 0,
          defaultOperator: 4,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.PECRUBRICA',
          externalId: null,
          description: 'Pec (Rubrica)',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchStringDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        }
      ],
      intFields: [
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 2,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'ID',
          externalId: null,
          description: 'Id',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchIntDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 2,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'RUBRICA',
          externalId: null,
          description: 'Non trovata',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchIntDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: false,
          predefinedProfileFormula: null
        },
        {
          operator: 3,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 2,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: 0,
          name: 'Dm_Rubrica.CATEGORIA',
          externalId: null,
          description: 'Rubrica',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchIntDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: false,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 2,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_Rubrica.SYSTEM_ID',
          externalId: null,
          description: 'Id',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchIntDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: false,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 2,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_CatRubriche.ID',
          externalId: null,
          description: 'CategoryId',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchIntDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: false,
          predefinedProfileFormula: null
        },
        {
          operator: 0,
          valore1: null,
          valore2: null,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 2,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'Dm_CatRubriche.TIPO',
          externalId: null,
          description: 'CategoryType',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchIntDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: false,
          predefinedProfileFormula: null
        }
      ],
      boolFields: [
        {
          operator: 0,
          valore1: false,
          valore2: false,
          groupId: 0,
          fieldType: 0,
          additionalFieldType: 5,
          defaultOperator: 3,
          tableName: null,
          binderFieldId: 0,
          multiple: null,
          name: 'FEAENABLED',
          externalId: null,
          description: 'Abilitato FEA (Contatto)',
          order: 0,
          dataSource: null,
          required: false,
          formula: null,
          className: 'FieldBaseForSearchBoolDto',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: null,
          isAdditional: false,
          visible: true,
          predefinedProfileFormula: null
        }
      ],
      doubleFields: null,
      stringListFields: null,
      matrixFields: null,
      maxItems: 0
    },
    selectdto: {
      maxItems: 1000,
      fields: [
        {
          toCalculate: false,
          index: 0,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rubrica',
          name: 'DM_RUBRICA_SYSTEM_ID',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false,
          sort: {}
        },
        {
          toCalculate: false,
          index: 1,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Ragione sociale',
          name: 'DM_RUBRICA_RAGIONE_SOCIALE',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: true,
          sort: {
            direction: 'desc',
            index: 0
          }
        },
        {
          toCalculate: false,
          index: 2,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice',
          name: 'DM_RUBRICA_CODICE',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: true,
          sort: {}
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Aoo',
          name: 'DM_RUBRICA_AOO',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Cap',
          name: 'DM_RUBRICA_CAP',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'E-Mail Rubrica',
          name: 'DM_RUBRICA_MAIL',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Fax',
          name: 'DM_RUBRICA_FAX',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Indirizzo',
          name: 'DM_RUBRICA_INDIRIZZO',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Località',
          name: 'DM_RUBRICA_LOCALITA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Nazione',
          name: 'DM_RUBRICA_NAZIONE',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Provincia',
          name: 'DM_RUBRICA_PROVINCIA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Rubrica',
          name: 'DM_CATRUBRICHE_RUBRICA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: true,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Categoria',
          name: 'DM_RUBRICA_CLASSE',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice Fiscale',
          name: 'DM_RUBRICA_CODFIS',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Partita Iva',
          name: 'DM_RUBRICA_PARTIVA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Telefono',
          name: 'DM_RUBRICA_TEL',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Aoo rubrica',
          name: 'DM_RUBRICA_AOORUBRICA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice ufficio',
          name: 'DM_RUBRICA_CODICEUFFICIO',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Codice Ipa',
          name: 'DM_RUBRICA_CODICEIPA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        },
        {
          toCalculate: false,
          index: 3,
          selected: false,
          fieldType: 0,
          orderBy: {
            direction: 0,
            index: 0
          },
          externalId: null,
          label: 'Pec (Rubrica)',
          name: 'DM_RUBRICA_PECRUBRICA',
          userSelectionEnabled: true,
          userSelectionGroup: 0,
          visible: false
        }
      ]
    }
  };
  dataRifIndex: number;
  dipendenteIndex: number;
  tipologiaIndex: number;
  docnumberIndex: number;
  observable: any;
  dialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;

  constructor(public apiService: ApiService, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute, private router: Router, public mapComponent: GoogleMapComponent, private snackBar: MatSnackBar) {
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  myObservableLoading(observer): void {

  }

  showProgressSpinner(observable: Observable<any>): void {
    this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    const subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        // handle response
        // console.log(response);
        this.dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        this.dialogRef.close();
      }
    );
  }

  ClickLogOut(): void {

    this.router.navigate(['login']);
    this.apiService.setLoggedOut();

  }

  ngOnInit(): void {
    const credentialUser = {
      username: 'apiUser',
      password: '@p!123',
      clientId: 'Frontendly',
      clientSecret: '2E03017AA07A4009824569A2BFD5836B'
    };
    this.apiService.login(credentialUser).subscribe(data => {
      this.apiService.setLoggedInInformation(data);
      this.apiService.tokenForSearch = data.accessToken;

      this.activeBoltFilter = false;
      this.observable = new Observable(this.myObservableLoading);
      this.showProgressSpinner(this.observable);
      this.apiService.getViewFilter(this.bodyGetPresenze).subscribe(res => {

        //  new Date(startOfDay(parseISO(v[this.dataRifIndex])));
        res.columns.forEach((v, index) => {
          if (v.id === 'DATA144_14') {
            this.dataRifIndex = index;
          } else if (v.id === 'COMBO147_14') {
            this.dipendenteIndex = index;
          } else if (v.id === 'COMBO146_14') {
            this.tipologiaIndex = index;
          } else if (v.id === 'DOCNUMBER') {
            this.docnumberIndex = index;
          }
        });
        this.apiService.getViewFilter(this.bodyGetDipendenti).subscribe(result => {
          this.groupsDepartment = ['TUTTI'];
          this.groupsZone = ['TUTTI'];
          this.groupsCluster = ['TUTTI'];
          this.contatorePresenze = new Array<any>();
          this.countGiornataPresenza = 0;
          this.countGiornataPomeriggio = 0;
          this.countGiornataSmart = 0;
          this.countGiornataOff = 0;
          this.countGiornataMattino = 0;
          this.events = res;
          this.tableData = new Array<any>();

          console.log(result);

          result.columns.forEach((v, index) => {
            if (v.id === 'TESTO189_18') {
              this.indexNome = index;
            } else if (v.id === 'DOCNUMBER') {
              this.indexCodice = index;
            } else if (v.id === 'TESTO179_18') {
              this.indexZona = index;
            } else if (v.id === 'TESTO180_18') {
              this.indexCluster = index;
            } else if (v.id === 'TESTO181_18') {
              this.indexDepartment = index;
            } else if (v.id === 'TESTO182_18') {
              this.indexCognome = index;
            } else if (v.id === 'TESTO183_18') {
              this.indexNomeVero = index;
            } else if (v.id === 'TESTO184_18') {
              this.indexCN = index;
            }
          });
          result.data.sort((a, b) => {
            if (a[this.indexZona] > b[this.indexZona]) {
              return 1;
            }
            if (a[this.indexZona] < b[this.indexZona]) {
              return -1;
            }
            if (a[this.indexCluster] > b[this.indexCluster]) {
              return 1;
            }
            if (a[this.indexCluster] < b[this.indexCluster]) {
              return -1;
            }
            if (a[this.indexCognome] > b[this.indexCognome]) {
              return 1;
            }
            if (a[this.indexCognome] < b[this.indexCognome]) {
              return -1;
            }
          });
          console.log(result.data);
          result.data.forEach(v => {
            const tempRow = {
              Zona: '',
              Username: '',
              Cluster: '',
              Risorsa: '',
              1: '',
              2: '',
              3: '',
              4: '',
              5: '',
              6: '',
              7: '',
              8: '',
              9: '',
              10: '',
              11: '',
              12: '',
              13: '',
              14: '',
              15: '',
              16: '',
              17: '',
              18: '',
              19: '',
              20: '',
              21: '',
              22: '',
              23: '',
              24: '',
              25: '',
              26: '',
              27: '',
              28: '',
              29: '',
              30: '',
              31: ''
            };
            let foundCL = false;
            this.groupsCluster.forEach(gr => {
              if (gr === v[this.indexCluster]) {
                foundCL = true;
              }
            });
            if (!foundCL) {
              this.groupsCluster.push(v[this.indexCluster]);
            }
            let foundDP = false;
            this.groupsDepartment.forEach(gr => {
              if (gr === v[this.indexDepartment]) {
                foundDP = true;
              }
            });
            if (!foundDP) {
              this.groupsDepartment.push(v[this.indexDepartment]);
            }
            let foundZN = false;
            this.groupsZone.forEach(gr => {
              if (gr === v[this.indexZona]) {
                foundZN = true;
              }
            });
            if (!foundZN) {
              this.groupsZone.push(v[this.indexZona]);
            }
            if ((v[this.indexCluster] === this.clusterGroup || this.clusterGroup === 'TUTTI') && (v[this.indexDepartment] === this.departmentGroup || this.departmentGroup === 'TUTTI') && (v[this.indexZona] === this.zoneGroup || this.zoneGroup === 'TUTTI')) {
              this.events.data.forEach(ev => {
                if (ev[this.dipendenteIndex] === v[this.indexNome]) {
                  const date = new Date(ev[this.dataRifIndex]);
                  if (date.getMonth() + 1 === this.selectedMonth && date.getFullYear() === this.year) {
                    switch (ev[this.tipologiaIndex]) {
                      case 'Giornata intera in presenza':
                        this.countGiornataPresenza++;
                        break;
                      case 'Mattino in presenza':
                        this.countGiornataMattino++;
                        break;
                      case 'Off- Ferie, Malattia, Permesso':
                        this.countGiornataOff++;
                        break;
                      case 'Pomeriggio in presenza':
                        this.countGiornataPomeriggio++;
                        break;
                      case 'Smart Working':
                        this.countGiornataSmart++;
                        break;
                    }
                    tempRow[date.getDate()] = ev[this.tipologiaIndex];
                  }
                }
              });
              tempRow.Risorsa = v[this.indexDepartment] + '^' + v[this.indexCN];
              tempRow.Username = v[this.indexNome];
              tempRow.Cluster = v[this.indexCluster];
              tempRow.Zona = v[this.indexZona];
              this.tableData.push(tempRow);
            }
          });
          console.log(this.tableData);
          this.apiService.getViewFilter(this.bodyGetZone).subscribe(zones => {
            let indZone, indValue;
            zones.columns.forEach((v, index) => {
              if (v.id === 'COMBO195_21') {
                indZone = index;
              } else if (v.id === 'NUMERIC193_21') {
                indValue = index;
              }
            });
            zones.data.forEach(datav => {
              this.zoneMap.set(datav[indZone], datav[indValue]);
            });
            this.dialogRef.close();
          });


          this.dataSource = new MatTableDataSource(this.tableData);
          this.ngAfterViewInit();
          if (this.firstIn){
            this.rapidFilter();
            this.firstIn = false;
          }

        }); // da fare visualizzazione presenze

      });

    });
  }

  exportCSV(): void {
    let header;
    const data: Array<any> = new Array<any>();
    this.tableData.forEach(res => {
      header = Object.keys(res);
      const tempArray: Array<any> = new Array<any>();
      this.array_move(header, 31, 0);
      this.array_move(header, 32, 1);
      this.array_move(header, 33, 2);
      this.array_move(header, 34, 3);
      header.forEach((v, index) => {
        let value: any;
        value = res[v];

        if (res[v] instanceof Date) {
          value = this.TransformDataFormat(res[v]);
        }
        if (value) {
          value = value.toString().replace(',', '');
        }
        tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
      });

      data.push(tempArray);

      header.forEach((v, index) => {
        header[index] = v.substr(v.indexOf(',') + 1, v.length);
      });
    });
    this.exportAll(data, header);
  }

  // tslint:disable-next-line:variable-name
  array_move(arr: any, old_index: number, new_index: number): void {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  exportAll(data: any, header): void {
    this.apiService.exportCSV(data, 'Pianificazione presenze mese ' + this.selectedMonth, header);
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  resetFilters(): void {
    this.departmentGroup = 'TUTTI';
    this.zoneGroup = 'TUTTI';
    this.clusterGroup = 'TUTTI';
    this.ngOnInit();

  }

  getCountPresenze(day: number): number {
    let count = 0;
    this.tableData.forEach(t => {
      if (t[day] !== '' && t[day] !== 'Smart Working') {
        count++;
      }
    });
    return count;
  }

  getMaxDaysInMonth(): number {
    return new Date(this.year, this.selectedMonth, 0).getDate();
  }

  reloadTableNoLoad(): void {

    this.apiService.getViewFilter(this.bodyGetPresenze).subscribe(res => {

      //  new Date(startOfDay(parseISO(v[this.dataRifIndex])));
      res.columns.forEach((v, index) => {
        if (v.id === 'DATA144_14') {
          this.dataRifIndex = index;
        } else if (v.id === 'COMBO147_14') {
          this.dipendenteIndex = index;
        } else if (v.id === 'COMBO146_14') {
          this.tipologiaIndex = index;
        } else if (v.id === 'DOCNUMBER') {
          this.docnumberIndex = index;
        }
      });
      this.apiService.getViewFilter(this.bodyGetDipendenti).subscribe(result => {
        this.groupsDepartment = ['TUTTI'];
        this.groupsCluster = ['TUTTI'];
        this.groupsZone = ['TUTTI'];
        this.countGiornataPresenza = 0;
        this.countGiornataPomeriggio = 0;
        this.countGiornataSmart = 0;
        this.countGiornataOff = 0;
        this.countGiornataMattino = 0;
        this.events = res;
        this.tableData = new Array<any>();

        console.log(result);

        result.columns.forEach((v, index) => {
          if (v.id === 'TESTO189_18') {
            this.indexNome = index;
          } else if (v.id === 'DOCNUMBER') {
            this.indexCodice = index;
          } else if (v.id === 'TESTO179_18') {
            this.indexZona = index;
          } else if (v.id === 'TESTO180_18') {
            this.indexCluster = index;
          } else if (v.id === 'TESTO181_18') {
            this.indexDepartment = index;
          } else if (v.id === 'TESTO182_18') {
            this.indexCognome = index;
          } else if (v.id === 'TESTO183_18') {
            this.indexNomeVero = index;
          } else if (v.id === 'TESTO184_18') {
            this.indexCN = index;
          }
        });
        result.data.sort((a, b) => {
          if (a[this.indexZona] > b[this.indexZona]) {
            return 1;
          }
          if (a[this.indexZona] < b[this.indexZona]) {
            return -1;
          }
          if (a[this.indexCluster] > b[this.indexCluster]) {
            return 1;
          }
          if (a[this.indexCluster] < b[this.indexCluster]) {
            return -1;
          }
          if (a[this.indexCognome] > b[this.indexCognome]) {
            return 1;
          }
          if (a[this.indexCognome] < b[this.indexCognome]) {
            return -1;
          }
        });

        result.data.forEach(v => {
          const tempRow = {
            Zona: '',
            Username: '',
            Cluster: '',
            Risorsa: '',
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: '',
            12: '',
            13: '',
            14: '',
            15: '',
            16: '',
            17: '',
            18: '',
            19: '',
            20: '',
            21: '',
            22: '',
            23: '',
            24: '',
            25: '',
            26: '',
            27: '',
            28: '',
            29: '',
            30: '',
            31: ''
          };
          let foundCL = false;
          this.groupsCluster.forEach(gr => {
            if (gr === v[this.indexCluster]) {
              foundCL = true;
            }
          });
          if (!foundCL) {
            this.groupsCluster.push(v[this.indexCluster]);
          }
          let foundDP = false;
          this.groupsDepartment.forEach(gr => {
            if (gr === v[this.indexDepartment]) {
              foundDP = true;
            }
          });
          if (!foundDP) {
            this.groupsDepartment.push(v[this.indexDepartment]);
          }
          let foundZN = false;
          this.groupsZone.forEach(gr => {
            if (gr === v[this.indexZona]) {
              foundZN = true;
            }
          });
          if (!foundZN) {
            this.groupsZone.push(v[this.indexZona]);
          }
          if ((v[this.indexCluster] === this.clusterGroup || this.clusterGroup === 'TUTTI') && (v[this.indexDepartment] === this.departmentGroup || this.departmentGroup === 'TUTTI') && (v[this.indexZona] === this.zoneGroup || this.zoneGroup === 'TUTTI')) {
            this.events.data.forEach(ev => {
              if (ev[this.dipendenteIndex] === v[this.indexNome]) {
                const date = new Date(ev[this.dataRifIndex]);
                if (date.getMonth() + 1 === this.selectedMonth && date.getFullYear() === this.year) {
                  switch (ev[this.tipologiaIndex]) {
                    case 'Giornata intera in presenza':
                      this.countGiornataPresenza++;
                      break;
                    case 'Mattino in presenza':
                      this.countGiornataMattino++;
                      break;
                    case 'Off- Ferie, Malattia, Permesso':
                      this.countGiornataOff++;
                      break;
                    case 'Pomeriggio in presenza':
                      this.countGiornataPomeriggio++;
                      break;
                    case 'Smart Working':
                      this.countGiornataSmart++;
                      break;
                  }
                  tempRow[date.getDate()] = ev[this.tipologiaIndex];
                }
              }
            });
            tempRow.Risorsa = v[this.indexDepartment] + '^' + v[this.indexCN];
            tempRow.Username = v[this.indexNome];
            tempRow.Cluster = v[this.indexCluster];
            tempRow.Zona = v[this.indexZona];
            this.tableData.push(tempRow);
          }
        });


        this.dataSource = new MatTableDataSource(this.tableData);
        this.ngAfterViewInit();
        this.dataSource.filter = this.oldFilter;
        if (this.activeBoltFilter) {
          this.activeBoltFilter = !this.activeBoltFilter;
          this.rapidFilter();
        }
        /* setTimeout(() => {
           this.dataSource.filter = this.filter.trim().toLowerCase();
         }, 10);*/
      }); // da fare visualizzazione presenze

    });


  }

  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '20%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  removeEvent(day: number, risorsa: string): void {
    const date = this.getDateFromDayToSave(day);
    const formatDate = new Date(date);
    this.events.data.forEach(v => {
      const tempDate = new Date(v[this.dataRifIndex]);
      if (tempDate.getTime() === formatDate.getTime() && v[this.dipendenteIndex] === risorsa) {
        this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
          this.snackBar.open('Evento eliminato con successo', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
          this.reloadTableNoLoad();
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });

  }

  rapidFilter(): void {
    if (!this.activeBoltFilter) {
      this.dataSource.filter = localStorage.getItem('username');
      this.activeBoltFilter = true;
    } else {
      this.dataSource.filter = '';
      this.activeBoltFilter = false;
    }
  }

  addEvent(tipo: string, giorno: number, risorsa: string): void {
    switch (tipo) {
      case 'Giornata intera in presenza':
        this.countGiornataPresenza++;
        break;
      case 'Mattino in presenza':
        this.countGiornataMattino++;
        break;
      case 'Off- Ferie, Malattia, Permesso':
        this.countGiornataOff++;
        break;
      case 'Pomeriggio in presenza':
        this.countGiornataPomeriggio++;
        break;
      case 'Smart Working':
        this.countGiornataSmart++;
        break;
    }
    const json = {
      fields: [
        {
          value: this.getDateFromDayToSave(giorno),
          additionalFieldType: 1,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: 0,
          validationType: 0,
          validationString: '',
          name: 'DATA144_14',
          externalId: '',
          description: 'Data di riferimento',
          order: 0,
          dataSource: null,
          required: false,
          formula: '',
          className: 'AdditionalFieldDateTimeDTO',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: [],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: null,
          visibilityCondition: '',
          mandatoryCondition: '',
          addressBookDefaultFilter: null,
          enabledAddressBook: [],
          columns: null
        },
        {
          limitToList: true,
          displayValue: null,
          value: tipo,
          numMaxChar: 100,
          numRows: 0,
          additionalFieldType: 3,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: 0,
          validationType: 0,
          validationString: '',
          name: 'COMBO146_14',
          externalId: '',
          description: 'Tipologia',
          order: 1,
          dataSource: null,
          required: false,
          formula: '',
          className: 'AdditionalFieldComboDTO',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: [],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: null,
          visibilityCondition: '',
          mandatoryCondition: '',
          addressBookDefaultFilter: null,
          enabledAddressBook: [],
          columns: null
        },
        {
          limitToList: true,
          displayValue: null,
          value: risorsa,
          numMaxChar: 100,
          numRows: 0,
          additionalFieldType: 3,
          groupId: 0,
          binderFieldId: 0,
          taskWorkVariableId: 0,
          validationType: 0,
          validationString: '',
          name: 'COMBO147_14',
          externalId: '',
          description: 'Dipendente',
          order: 2,
          dataSource: null,
          required: false,
          formula: '',
          className: 'AdditionalFieldComboDTO',
          locked: false,
          comboGruppiId: null,
          dependencyFields: [],
          associations: [],
          isAdditional: true,
          visible: true,
          predefinedProfileFormula: null,
          visibilityCondition: '',
          mandatoryCondition: '',
          addressBookDefaultFilter: null,
          enabledAddressBook: [],
          columns: null
        }
      ]
    };
    let found = false;
    const date = this.getDateFromDayToSave(giorno);
    const formatDate = new Date(date);
    this.events.data.forEach(v => {
      const tempDate = new Date(v[this.dataRifIndex]);
      if (tempDate.getTime() === formatDate.getTime() && v[this.dipendenteIndex] === risorsa) {
        found = true;
        this.apiService.deleteProfile(v[this.docnumberIndex]).subscribe(res => {
          this.dataSource.filteredData.forEach(v => {
            if (v['Risorsa'] === risorsa) {
              v[giorno] = tipo;
            }
          });
          this.apiService.insertNewProfile(json, 'fabc8498bdf74a3396257a72f39ebc85').subscribe(res => {
            this.snackBar.open('Evento aggiunto correttamente', null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-success-bg']
            });
            this.reloadTableNoLoad();

          }, errorLog => {
            this.snackBar.open(errorLog.error.userMessage, null, {
              duration: 4000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    });

    if (!found) {
      this.dataSource.filteredData.forEach(v => {
        if (v['Risorsa'] === risorsa) {
          v[giorno] = tipo;
        }
      });
      this.apiService.insertNewProfile(json, 'fabc8498bdf74a3396257a72f39ebc85').subscribe(res => {
        this.snackBar.open('Evento aggiunto correttamente', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.reloadTableNoLoad();

      }, errorLog => {
        this.reloadTableNoLoad();
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });

    }
  }

  isCurrentUser(username): boolean {
    return username.toLowerCase() === localStorage.getItem('username').toLowerCase();
  }

  selectedDateIsLessThanToday(day: number): boolean {
    const date = this.getDateFromDayToSave(day);
    const formatDate = new Date(date);
    const currDate = new Date();
    currDate.setDate(currDate.getDate() - 1);
    if (currDate.getTime() > formatDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  getDateFromDay(day: number): string {
    const date = day + '/' + this.selectedMonth + '/' + this.year;

    return date;
  }

  getDateFromDayToSave(day: number): string {
    const date = this.selectedMonth + '/' + day + '/' + this.year;
    return date;
  }

  getDayOfWeek(day: number): string {
    const date = this.selectedMonth + '/' + day + '/' + this.year;
    const formatDate = new Date(date);

    switch (formatDate.getDay()) {
      case 1:
        return 'Lunedì';
        break;
      case 2:
        return 'Martedì';
        break;
      case 3:
        return 'Mercoledì';
        break;
      case 4:
        return 'Giovedì';
        break;
      case 5:
        return 'Venerdì';
        break;
      case 6:
        return 'Sabato';
        break;
      case 0:
        return 'Domenica';
        break;
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.oldFilter = this.dataSource.filter;
  }
}
