
<div style="text-align: center; color: black">
    <h2>Digitare dati destinatario</h2>
    <hr><br>
    <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input matInput required  [(ngModel)]="this.email" type = "text">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput required  [(ngModel)]="this.name" type = "text">
    </mat-form-field>
    <br>
    <button mat-stroked-button (click)="checkSigner()">Invia</button>
    <button title="Chiudi" (click)="closeDialog()" mat-stroked-button color="warn">
    Annulla
</button>
    <br>
    <p style="color: red;">{{this.error}}</p>
</div>
