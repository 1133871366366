<div style="width: 100%">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; height: 50px; display: flex">
    <h2 style="text-align: center; width: 100%">Elenco note:</h2>

</div>
<div style="text-align: center">

    <ng-container *ngIf="this.notesList.length === 0">
        Nessuna nota presente
    </ng-container>
    <div style="text-align: left;">
        <mat-selection-list #viste [multiple]="false">
            <ng-container *ngIf="this.notesList.length != 0">
                <div *ngFor="let note of this.notesList">
                    <button style="float:right; color:{{this.squareColor}};"
                            title="Elimina nota"
                            mat-button
                            aria-label="Elimina nota" (click)=deleteNote(note.id)>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <mat-list-item style="width: 80%;">
                        <div>
                            <b>{{note.userCompleteName}}</b><span
                                style="margin-left: 10px;">{{note.comment}}</span><span
                                style="margin-left: 10px; color: darkgray">{{this.apiService.TransformDataFormat(note.creationDate)}}</span>
                        </div>
                    </mat-list-item>

                </div>

            </ng-container>
        </mat-selection-list>
    </div>
    <br>
    <hr>
    <div style="text-align: center; margin-top: 5%">
        <button mat-stroked-button (click)="addNoteTrue()">{{this.addLabel}}</button>
    </div>
    <div *ngIf="this.showAddNote" style="margin-top: 5%">
        <mat-form-field appearance="fill" style="width: 60%">
            <mat-label>Testo nota</mat-label>
            <textarea matInput required rows="3" [(ngModel)]="this.newNote"
                      placeholder="Inserire qui il testo della nota" type="text"></textarea>
        </mat-form-field>
        <br>
        <button mat-stroked-button (click)="saveNewNote()">Salva</button>

    </div>
    <br>
</div>

