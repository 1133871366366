<div style="width: 100%">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-conten style="width: 100%;">
    <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Nota da aggiungere:</mat-label>
        <textarea matInput rows="5" [(ngModel)]="data.nota"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()" style="text-align: center">Annulla</button>
    <button mat-button [mat-dialog-close]="data.nota" (click) = "addNote(data.nota)" style="text-align: center">Ok</button>
</div>


