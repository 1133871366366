import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {DatePipe} from '@angular/common';
import {interval} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InfoDialogComponent} from '../../VociMenu/fascicoli/fascicoli.component';
import * as fileSaver from 'file-saver';

@Component({
    selector: 'app-reportdetails',
    templateUrl: './reportdetails.component.html',
    styleUrls: ['./reportdetails.component.css']
})
export class ReportdetailsComponent implements OnInit {
    id = this.activatedRoute.snapshot.paramMap.get('id');
    dashColor: string;
    backColor: string;
    squareColor: string;
    cardColor: string;
    reportParameters: Array<any> = new Array<any>();
    fields: Array<any>;
    report: any;
    timerId: any;
    jobId: string;
    DmBufferId: string;
    fileHTML: string;
    state = 'noreport';
    // ----------------------- HTML = 29; CSV = 17; EXCEL = 14; PDF = 1; WORD2007 = 15
    CSV = false;
    EXCEL2007 = false;
    PDF = false;
    WORD2007 = false;
    reports: Array<any> = new Array<any>();
    detailedReports: Array<any> = new Array<any>();

    constructor(private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
                sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute,
                private datePipe: DatePipe, public dialog: MatDialog, private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.report = {};
        this.fields = new Array<any>();
        this.apiService.getReport(this.id).subscribe(result => {
            this.report = result;
            this.reportParameters = result.parameters;
            this.reportParameters.forEach(v => {
                this.fields.push(v.configuration);
            });
            this.apiService.addPath(this.report.name, 'dashboard/dashnav/reportdetails', {id: this.id});
        });
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
    }


    generateReport(): void {
        this.state = 'loading';
        const formatsArray: Array<number> = new Array<number>();
        formatsArray.push(29);
        if (this.CSV) {
            formatsArray.push(17);
        }
        if (this.EXCEL2007) {
            formatsArray.push(14);
        }
        if (this.PDF) {
            formatsArray.push(1);
        }
        if (this.WORD2007) {
            formatsArray.push(15);
        }
        const body: JSON = {
            formats: formatsArray,
            identifier: {
                identifier: this.report.id,
                identifierType: 1
            },
            parameters: this.report.parameters
        } as unknown as JSON;
        this.apiService.reportExecuteAsync(body).subscribe(res => {
            const queueBod: JSON = {
                count: 0,
                startItem: 0
            } as unknown as JSON;
            this.apiService.queueJobInfoList(queueBod, res.asyncRequestId).subscribe(result => {
                result.forEach(v => {
                    if (v.queueJob.methodName === 'WorkItem') {
                        this.jobId = v.queueJob.jobId;
                    }
                });
                this.timerId = setInterval(() => this.queueJob(), 2000);

            });
        });
    }

    queueJob(): void {
        this.apiService.queueJobInfo(this.jobId).subscribe(value => {
            if (value.history[0].stateName === 'Succeeded') {
                let job = JSON.parse(value.history[0].data[3].value);
                // console.log(job);
                if (job.JobError){
                    this.state = 'error';
                }else{
                    job.KeyValueParameters.forEach(v => {
                        if (v.Key === 'ReportExecuteResult') {
                            this.reports = new Array<any>();
                            this.detailedReports = new Array<any>();
                            v.Value.Reports.forEach(rep => {
                                this.reports.push({id: rep.ReportFormatEnumValue, bufferId: rep.DmBufferId});
                            });
                            this.reports.forEach(rep => {
                                if (rep.id === 29) {
                                    this.apiService.getBufferFileText(rep.bufferId).subscribe(res => {
                                        this.state = 'found';
                                        this.fileHTML = res;
                                    });
                                }
                                this.apiService.getBuffer(rep.bufferId).subscribe(result => {
                                    this.detailedReports.push({id: rep.id, data: result});
                                });
                            });

                            // console.log(this.detailedReports);
                            /*this.apiService.getBufferFileBlob(this.DmBufferId).subscribe(res => {
                                // console.log(res);
                            });*/
                        }
                    });

                }

                setTimeout(() => {
                    clearInterval(this.timerId);
                });
            }
        });
    }

    openInfoDialog(): void {
        const dialogRef = this.dialog.open(ReportInfoDialogComponent, {
            width: '20%',
            data: {
                nome: this.report.name, descrizione: this.report.description, autore: this.report.authorCompleteName,
                idesterno: this.report.externalId, datacreazione: this.report.creationDate,
                ultimoaggiornamento: this.report.lastUpdateDate, autoreultimoaggiornamento: this.report.modifierCompleteName
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openDownloadDialog(): void {
        const dialogRef = this.dialog.open(ReportDownloadDialogComponent, {
            width: '30%',
            data: {reports: this.detailedReports}
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openExpandDialog(): void {
        if (!this.fileHTML) {
            this.snackBar.open('Report non generato', null, {
                duration: 4000,
                horizontalPosition: 'end',
verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-warning-bg']
            });
        } else {
            const dialogRef = this.dialog.open(ReportExpandDialogComponent, {
                width: '90%',
                height: '90%',
                data: {HTML: this.fileHTML}
            });
            dialogRef.afterClosed().subscribe(result => {
            });
        }
    }
}

@Component({
    selector: 'app-reportinfo',
    templateUrl: 'reportInfoDialog.html',
    styleUrls: ['./reportdetails.component.css']
})
export class ReportInfoDialogComponent {
    nome: string;
    descrizione: string;
    autore: string;
    idesterno: string;
    datacreazione: string;
    ultimoaggiornamento: string;
    autoreultimoaggiornamento: string;

    constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ReportInfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService, private datePipe: DatePipe) {
        this.nome = data.nome;
        this.descrizione = data.descrizione;
        this.autore = data.autore;
        this.idesterno = data.idesterno;
        this.datacreazione = data.datacreazione;
        this.ultimoaggiornamento = data.ultimoaggiornamento;
        this.autoreultimoaggiornamento = data.autoreultimoaggiornamento;
    }

    TransformDataFormat(Data: string): string {
        return this.datePipe.transform(Data, 'dd/MM/yyyy, H:MM', '', 'en-GB');

    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-reportdownload',
    templateUrl: 'reportDownloadDialog.html',
    styleUrls: ['./reportdetails.component.css']
})
export class ReportDownloadDialogComponent {
    downloadRows: Array<any> = new Array<any>();


    constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ReportDownloadDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService) {
        this.downloadRows = data.reports;
    }

    downloadReport(id: string, name: string): void {
        this.apiService.getBufferFileBlob(id).subscribe(response => {
            const blob = new Blob([response], {type: 'application/octet-stream'});
            fileSaver.saveAs(blob, name);
            // tslint:disable-next-line:no-unused-expression
        }, errorLog => {
            this.snackBar.open('Non è stato possibile scaricare il report scelto', null, {
                duration: 3000,
                horizontalPosition: 'end',
verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-reportexpand',
    templateUrl: 'reportExpandDialog.html',
    styleUrls: ['./reportdetails.component.css']
})
export class ReportExpandDialogComponent {
    html: string;

    constructor(private snackBar: MatSnackBar, public dialogRef: MatDialogRef<ReportExpandDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService) {
        this.html = data.HTML;

    }


    closeDialog(): void {
        this.dialogRef.close();
    }
}
