<style>.mat-tab-body-content {
    height: 100%;
    overflow: hidden !important;
}

.mat-tab-group-dynamic-height .mat-tab-body-content {
    overflow: hidden
}

</style>
<div class="center">
    <mat-card class="cardcomponentMasks" id="style-4">
        <button style="float:right; color:{{this.dashColor}};" title="Nuovo" (click)="addContact()" mat-icon-button
                aria-label="icon-button send">
            <mat-icon>add</mat-icon>
        </button>
        <h3 class="titles" style="color: {{this.dashColor}}">
            <mat-card-title><p>Rubrica:&nbsp;&nbsp;</p></mat-card-title>
        </h3>
        <hr class="riga" style="color: {{this.dashColor}}">
        <br>

        <mat-card-content class="cartelle">
            <mat-tab-group style="width: 100%; " dynamicHeight mat-align-tabs="center">
                <mat-tab label="Rubriche pubbliche" style="overflow: hidden">

                    <mat-accordion>
                        <ng-container *ngFor="let subcat of this.underCatPub">
                            <mat-expansion-panel style="display: contents; " (opened)="panelOpenState = true"
                                                 (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title style="color: black">
                                        <b>{{subcat.name}}</b>
                                    </mat-panel-title>

                                </mat-expansion-panel-header>


                                <mat-selection-list [multiple]="false">

                                    <!--<ng-container *ngFor="let mm of this.utentiPubblici">
                                        <ng-container *ngIf="mm.Cat.value == subcat.name">
                                            <mat-list-item (dblclick)="clickRubrica(mm.id.value, 'pub')"
                                                           *ngIf="mm.type.value != null"
                                                           class="item">
                                                <div mat-line style="margin-top: 14px !important"><b
                                                        style="color: {{this.dashColor}}">{{mm.value.value}}</b>
                                                    <p>Codice: {{mm.type.value}}</p></div>
                                                <button title="Elimina" (click)="delete(mm.idC.value)"
                                                        mat-icon-button
                                                        color="warn" aria-label="icon-button save">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                            </mat-list-item>
                                        </ng-container>
                                    </ng-container>-->
                                    <button style="float: right; color:black;"
                                            title="Elimina categoria"
                                            (dblclick)="deleteCat(subcat.id)" mat-button
                                            aria-label="icon-button send">
                                        <mat-icon>delete</mat-icon>
                                        Elimina categoria (doppio click)
                                    </button>
                                    <button style="float: right; color:black;"
                                            title="Rinomina"
                                            (dblclick)="openRenameCategoryDialog(subcat.id, subcat.name, 'Public')"
                                            mat-button
                                            aria-label="icon-button send">
                                        <mat-icon>edit</mat-icon>
                                        Rinomina categoria (doppio click)
                                    </button>
                                    <div style="width: 100%">
                                        <mat-form-field>
                                            <mat-label>Filter</mat-label>
                                            <input matInput (keyup)="applyFilter($event, subcat.name)"
                                                   placeholder="Filtra" #input>
                                        </mat-form-field>
                                        <table mat-table [dataSource]="this.dataSourcePU[subcat.name]"
                                               class="mat-elevation-z8" style="width: 100%">
                                            <ng-container *ngFor="let colonna of displayedColumns"
                                                          matColumnDef="{{colonna}}">
                                                <ng-container *ngIf="colonna != 'id'">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        <ng-container *ngIf="colonna == 'Ragione_sociale'">Ragione
                                                            sociale
                                                        </ng-container>
                                                        <ng-container
                                                                *ngIf="colonna != 'Ragione_sociale'">{{colonna}}</ng-container>
                                                    </th>
                                                </ng-container>
                                                <ng-container *ngIf="colonna == 'id'">
                                                    <th mat-header-cell *matHeaderCellDef style="background-color: white; width: 0px; z-index: -100;">
                                                                                            {{colonna}}
                                                    </th>
                                                </ng-container>
                                                <td mat-cell
                                                    (contextmenu)="open($event, element, element['Ragione_sociale'], element['id'], 'pub'); $event. preventDefault();"
                                                    *matCellDef="let element"> {{element[colonna]}}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                            <!-- Row shown when there is no matching data. -->
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="4">No data matching the filter
                                                    "{{input.value}}"
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator class="mat-paginator-sticky" showFirstLastButtons
                                                       style="float: right"
                                                       [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                    </div>

                                </mat-selection-list>
                                <!---->
                            </mat-expansion-panel>


                        </ng-container>

                    </mat-accordion>
                    <button style="float:left; color:black; margin-top: 10px" title="Nuova categoria pubblica"
                            (click)="openAddCategoryDialog('Public')" mat-button
                            aria-label="icon-button send">
                        <mat-icon>add</mat-icon>
                        Aggiungi categoria
                    </button>
                </mat-tab>
                <mat-tab label="Rubriche private" style="overflow: hidden">
                    <mat-accordion>
                        <ng-container *ngFor="let subcat of this.underCatPriv">
                            <mat-expansion-panel style="display: contents; " (opened)="panelOpenState = true"
                                                 (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title style="color: black">
                                        <b>{{subcat.name}}</b>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-selection-list [multiple]="false">
                                    <button style="float: right; color:black;"
                                            title="Elimina categoria"
                                            (dblclick)="deleteCat(subcat.id)" mat-button
                                            aria-label="icon-button send">
                                        <mat-icon>delete</mat-icon>
                                        Elimina categoria (doppio click)
                                    </button>
                                    <button style="float: right; color:black;"
                                            title="Rinomina"
                                            (dblclick)="openRenameCategoryDialog(subcat.id, subcat.name, 'Public')"
                                            mat-button
                                            aria-label="icon-button send">
                                        <mat-icon>edit</mat-icon>
                                        Rinomina categoria (doppio click)
                                    </button>
                                    <div style="width: 100%">
                                        <mat-form-field>
                                            <mat-label>Filter</mat-label>
                                            <input matInput (keyup)="applyFilterPR($event, subcat.name)"
                                                   placeholder="Filtra" #input>
                                        </mat-form-field>
                                        <table mat-table [dataSource]="this.dataSourcePR[subcat.name]"
                                               class="mat-elevation-z8" style="width: 100%">
                                            <ng-container *ngFor="let colonna of displayedColumns"
                                                          matColumnDef="{{colonna}}">
                                                <ng-container *ngIf="colonna != 'id'">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        <ng-container *ngIf="colonna == 'Ragione_sociale'">Ragione
                                                            sociale
                                                        </ng-container>
                                                        <ng-container
                                                                *ngIf="colonna != 'Ragione_sociale'">{{colonna}}</ng-container>
                                                    </th>
                                                </ng-container>
                                                <ng-container *ngIf="colonna == 'id'">
                                                    <th mat-header-cell *matHeaderCellDef>

                                                    </th>
                                                </ng-container>
                                                <td mat-cell
                                                    (contextmenu)="open($event, element, element['Ragione_sociale'], element['id'], 'pri'); $event. preventDefault();"
                                                    *matCellDef="let element"> {{element[colonna]}}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                            <!-- Row shown when there is no matching data. -->
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="4">No data matching the filter
                                                    "{{input.value}}"
                                                </td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[10, 25, 100]" class="mat-paginator-sticky"
                                                       showFirstLastButtons style="float: right"></mat-paginator>
                                    </div>
                                </mat-selection-list>
                            </mat-expansion-panel>
                        </ng-container>
                    </mat-accordion>
                    <button style="float:left; color:black; margin-top: 10px" title="Nuova categoria privata"
                            (click)="openAddCategoryDialog('Private')" mat-button
                            aria-label="icon-button send">
                        <mat-icon>add</mat-icon>
                        Aggiungi categoria
                    </button>

                </mat-tab>
                <mat-tab label="Utenti interni" style="overflow: hidden">

                    <mat-selection-list [multiple]="false">
                        <ng-container *ngFor="let mmm of this.utentiInterni">
                            <mat-list-item (dblclick)="clickRubrica(mmm.id.value, 'int')"
                                           *ngIf="mmm.type.value != null" class="item">
                                <div mat-line><b style="color: {{this.dashColor}}">{{mmm.value.value}}</b>
                                    <p>Codice: {{mmm.type.value}}</p></div>
                                <button title="Elimina" (click)="delete(mmm.idC.value)" mat-icon-button
                                        color="warn" aria-label="icon-button save">
                                    <mat-icon>delete</mat-icon>
                                </button>

                            </mat-list-item>
                        </ng-container>
                        <!--<ng-container *ngFor = "let modello of this.Modelli" >
                          <mat-list-item *ngIf = "modello.groupName == cartella.name"  class="item"  [value]="modello.id">
                                  <div mat-line><b>{{modello.description}}</b></div>
                                  <mat-icon class="icon"  svgIcon="CondividiIcon"  style="margin: 8px"></mat-icon>
                                  <mat-icon class="icon"  svgIcon="ModificaIcon"  style="margin: 8px"></mat-icon>
                                  <mat-icon class="icon" svgIcon="ClonaIcon"  style="margin: 8px"></mat-icon>
                                  <mat-icon class="icon" svgIcon="PermessiiIcon"  style="margin: 8px"></mat-icon>
                                  <mat-icon class="icon" svgIcon="EliminaIcon"  style="margin: 8px"></mat-icon>
                          </mat-list-item>
                        </ng-container>-->
                    </mat-selection-list>
                </mat-tab>
            </mat-tab-group><!--hai cliccato la maschera con id: {{this.id}}-->


        </mat-card-content>

    </mat-card>
</div>
<ng-template #userMenu let-user>
    <section class="user-menu">
        <div style="text-align: left; display: inline-table;"><h3 style="margin-bottom: 0px;">
            <b style="color: black; text-align: center">{{this.clickedRagSoc}}</b></h3>
        </div>
        <hr>
        <button title="Apri" (click)="clickRubrica(this.clickedId, this.clickedType)"
                mat-menu-item
                aria-label="icon-button save">
            <mat-icon>menu_open</mat-icon>
            <span>Apri</span>
        </button>
        <button title="Elimina" (click)="delete(this.clickedId)"
                mat-menu-item
                aria-label="icon-button save">
            <mat-icon>delete</mat-icon>
            <span>Elimina</span>
        </button>
    </section>
</ng-template>
