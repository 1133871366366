import {Component, Inject, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import * as fileSaver from 'file-saver';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {EditFromFileComponent, EditRevisionComponent} from '../../VociMenu/dettaglitask/dettaglitask.component';
import {ViewBodyToSave} from '../../viewBodyToSave';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MatMenuTrigger} from '@angular/material/menu';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {fromEvent, Subscription} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';
import {filter, take} from 'rxjs/operators';
import {FilterDialogComponent} from '../../VociMenu/default-home/default-home.component';
import {ResizeEvent} from 'angular-resizable-element';
import {
  EditFromFileProfileComponent,
  EditRevisionProfileComponent,
  ProfileNotesComponent,
  ShowAttachmentsDialogComponent, SignerDetailsComponent
} from '../../MenuItemsV2/view/view.component';

@Component({
  selector: 'app-dialog-address-book-v2',
  templateUrl: './dialog-address-book-v2.component.html',
  styleUrls: ['./dialog-address-book-v2.component.css']
})
export class DialogAddressBookV2Component implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  displayedColumns: Array<string> = new Array<string>();
  row: any = {};
  v: any = {};
  buildResult: Array<string> = new Array<string>();
  Result: Array<any> = new Array<any>();
  noItems = true;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  rowsNum: string;
  private dataSource: MatTableDataSource<any>;
  selection = new SelectionModel(true, []);
  selectedDocN: string;
  selectedRubricaArray: Array<any> = new Array<any>();
  isSelectedDocArrayToUse = true;
  filter: string;
  filterTags: Array<any> = new Array<any>();
  commands: any;
  folders: Array<any> = new Array<any>();
  numSelected = 0;
  lastSelectedIndex: number;
  contactNameSelected: Array<string> = new Array<string>();
  categories: Array<string> = new Array<string>();
  selectedCategory = '-';
  enabled: number;

  constructor(public dialogRef: MatDialogRef<DialogAddressBookV2Component>, @Inject(MAT_DIALOG_DATA) public data: any, private viewBody: ViewBodyToSave, public dialog: MatDialog, private snackBar: MatSnackBar, private datePipe: DatePipe, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              // tslint:disable-next-line:max-line-length
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute, public overlay: Overlay,
              public viewContainerRef: ViewContainerRef) {
    this.enabled = data.enabledAddressBook;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;


  open({x, y}: MouseEvent, user, docN): void {
    this.selectedDocN = docN;
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({x, y})
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());
  }

  delete(user): void {
    // delete user
    this.close();
  }

  closeDialogNoSave(): void {

    this.dialogRef.close({dataResult: false});
  }

  closeDialog(): void {
    this.dialogRef.close({dataResult: this.selectedRubricaArray, contactsNames: this.contactNameSelected});
  }

  close(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '40%',
      data: {tags: this.filterTags}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.resultFilter) {
        this.filterTags = result.resultFilter;
        this.filterChange();
      }
    });
  }

  DBclickedRow(event: any, row: any, docN: string, nameSelected: string): void {
    if (this.data.fieldName === 'From' && this.numSelected > 0) {

    } else {
      if (row.selected) {
        this.selectedRubricaArray.splice(this.selectedRubricaArray.indexOf(docN), 1);
        this.contactNameSelected.splice(this.contactNameSelected.indexOf(nameSelected), 1);
        row.selected = false;
        this.numSelected--;
      } else {
        this.selectedRubricaArray.push(docN);
        this.contactNameSelected.push(nameSelected);
        row.selected = true;
        this.numSelected++;
        this.dataSource.filteredData.forEach((data, index) => {
          if (data['ID,Id Contatto'] === docN) {
            this.lastSelectedIndex = index;
          }
        });
        this.closeDialog();
      }
    }

  }

  clickedRow(event: any, row: any, docN: string, nameSelected: string): void {
    if (this.data.fieldName === 'From' && this.numSelected > 0) {

    } else {
      if (row.selected) {
        this.selectedRubricaArray.splice(this.selectedRubricaArray.indexOf(docN), 1);
        this.contactNameSelected.splice(this.contactNameSelected.indexOf(nameSelected), 1);
        row.selected = false;
        this.numSelected--;
      } else {
        this.selectedRubricaArray.push(docN);
        this.contactNameSelected.push(nameSelected);
        row.selected = true;
        this.numSelected++;
        this.dataSource.filteredData.forEach((data, index) => {
          if (data['ID,Id Contatto'] === docN) {
            this.lastSelectedIndex = index;
          }
        });
      }
    }

  }


  onShift(): void {

  }

  cancelSelection(): void {
    this.filter = '';
    this.filterChange();
    this.dataSource.filteredData.forEach(data => {
      if (data.selected) {
        data.selected = false;
      }
    });
    this.numSelected = 0;
    this.selectedRubricaArray = new Array<any>();
    this.contactNameSelected = new Array<string>();

  }

  addContact(): void {
    this.router.navigate(['dashboard/dashnav/dettaglirubrica', {new: 'new', cat: 'new', isFromDialog: 'true'}]);
    this.closeDialog();
  }

  categoryChanged(cat: string): void {
    if (cat) {
      let found;
      const tempRes: Array<any> = new Array<any>();
      this.Result.forEach(r => {
        found = false;
        if (r['DM_CATRUBRICHE_RUBRICA,Rubrica'] === cat) {
          found = true;
        }
        if (found) {
          tempRes.push(r);
        }
      });
      this.dataSource = new MatTableDataSource(tempRes);
      this.ngAfterViewInit();

    }
  }

  filterChange(): void {
    if (this.filter !== null && this.filter !== undefined) {
      let found;
      const tempRes: Array<any> = new Array<any>();
      let tempColumns: Array<any> = new Array<any>();
      tempColumns = this.filterTags;
      this.displayedColumns.forEach(d => {
        tempColumns.forEach(t => {
          if (d.includes(t.tag)) {
            t.tag = d;
          }
        });
      });
      this.Result.forEach(r => {
        found = false;
        if (r['DM_CATRUBRICHE_RUBRICA,Rubrica'] === this.selectedCategory || this.selectedCategory === '-' || (r['DM_CATRUBRICHE_RUBRICA,Rubrica'] === ' ' && this.selectedCategory === 'Utenti interni')) {
          tempColumns.forEach(dc => {
            if (dc.active) {
              if (r[dc.tag]) {
                if (r[dc.tag].toString().toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())) {
                  found = true;
                }
              }
            }

          });
        }
        if (found) {
          tempRes.push(r);
        }
      });
      this.dataSource = new MatTableDataSource(tempRes);
      this.ngAfterViewInit();
    }
  }

  enableFolder(id): void {
    this.folders.forEach(fol => {
      if (fol.id === id) {
        if (fol.enabled) {
          fol.enabled = false;
        } else {
          fol.enabled = true;
        }
      }
    });
  }

  startEvent(eventId: string, docN: number): void {
    if (this.selectedRubricaArray.length > 0 && this.isSelectedDocArrayToUse) {
      this.isSelectedDocArrayToUse = false;
      this.selectedRubricaArray.forEach(d => {
        this.startEvent(eventId, d);
      });
      this.isSelectedDocArrayToUse = true;
    } else {
      this.apiService.getWorkflowEvents([docN]).subscribe(res => {
        res.forEach(v => {
          if (v.eventId.toString() === eventId) {
            this.apiService.canStartEventByDocnumber(docN.toString()).subscribe(result => {
              if (result) {
                this.apiService.startWorkflow(docN.toString(), eventId).subscribe(r => {
                  this.snackBar.open('WorkFlow avviato correttamente', null, {
                    duration: 4000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                  });
                });
              }
            });
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.categories = new Array<string>();
    this.apiService.getAddressBookCategry().subscribe(cat => {  // Prende le varie categorie
      cat.forEach(v => {
        if (v.id > 0) {
          if (this.enabled) {
            if (this.enabled === v.id) {
              this.categories.push(v.addressBook);
            }
          } else {
            this.categories.push(v.addressBook);
          }
        }
      });
      if (!this.enabled) {
        this.categories.push('Utenti interni');
      }else{
        this.selectedCategory = this.categories[0];
        this.filterChange();
      }
    });
    this.selectedRubricaArray = new Array<any>();
    this.numSelected = this.selectedRubricaArray.length;
    this.folders = this.apiService.commandFolders;
    this.commands = this.apiService.profileCommands;
    this.Result = new Array<any>();
    this.displayedColumns = new Array<string>();
    this.buildResult = new Array<string>();
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.rowsNum = this.apiService.viewDataToShow.data.length;
    // this.displayedColumns.push('select');     SELEZIONE MULTIPLA
    // this.displayedColumns.push('selected');
    this.apiService.viewDataToShow.columns.forEach(val => {
      if ((val.visible || val.id === 'DOCNUMBER') && (val.id !== 'ID' && val.id !== 'DM_RUBRICA_CODICE'  && val.id !== 'DM_CATRUBRICHE_RUBRICA' && val.id !== 'DM_RUBRICA_SYSTEM_ID')) {
        this.displayedColumns.push(val.id + ',' + val.label);
      }
    });
    this.apiService.viewDataToShow.data.forEach(res => {
      this.buildResult = new Array<string>();
      this.noItems = false;
      this.apiService.viewDataToShow.columns.forEach((item, index) => {
        if (item.columnType === 'DateTime') {
          res[index] = this.TransformDataFormat(res[index]);
        }
        this.buildResult[this.apiService.viewDataToShow.columns[index].id + ',' +
        this.apiService.viewDataToShow.columns[index].label] = res[index];
      });
      let found = false;
      if (this.data.addressBookSelected.get(this.data.fieldName) !== undefined) {
        this.data.addressBookSelected.get(this.data.fieldName).forEach(itemMap => {
          if (this.buildResult['ID,Id Contatto'] === itemMap) {
            found = true;
            this.selectedRubricaArray.push(itemMap);
            this.contactNameSelected.push(this.buildResult['DM_RUBRICA_RAGIONE_SOCIALE,Ragione sociale']);
            this.numSelected++;
          }
        });
      } else {
        this.buildResult['selected'] = false;
      }
      if (found) {
        this.buildResult['selected'] = true;
      }
      this.Result.push(this.buildResult);
      // // // console.log(this.buildResult);

    });
    /*this.Result.forEach(resRow => {
         if (resRow['ALLEGATI,Allegati'] === '1') {
             this.apiService.docAttachments(resRow['DOCNUMBER,System ID']).subscribe(val => {
                 resRow['ALLEGATI,Allegati'] = val.length;
             });
         }
         if (resRow['NOTE,Note'] === 'S') {
             this.apiService.getNotes(resRow['DOCNUMBER,System ID']).subscribe(val => {
                 resRow['NOTE,Note'] = val.length;
             });
         }
     });*/
    if (this.filterTags.length === 0) {
      this.displayedColumns.forEach(v => {
        this.filterTags.push({tag: v.substr(v.indexOf(',') + 1, v.length), active: true});
      });
    }
    const demoRes: Array<any> = new Array<any>();
    this.Result.forEach((v, index) => {
      if (index < 25) {
        demoRes.push(v);
      }
    });
    this.dataSource = new MatTableDataSource(demoRes);
    this.filterChange();
    this.loadRows();


  }

  getClass(selected: boolean): string {
    if (!selected) {
      return 'tableRow';
    } else {
      return 'tableRowSelected';
    }
  }

  loadRows(): void {
    setTimeout(() => {
      this.filter = '';
      this.filterChange();
    }, 1);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  refreshView(): void {
    this.apiService.SearchAllAddressBook().subscribe(results => {
      this.apiService.viewDataToShow = results;
      this.ngOnInit();
      this.ngAfterViewInit();
    });
  }

  goToProfile(docNum: string): void {
    this.router.navigate(['../profile', {
      idDocument: docNum,
      taskId: '0',
      idv: this.id,
      firstnodepath: this.firstnodepath
    }], {relativeTo: this.route});
  }

  goBack(): void {
    if (this.firstnodepath === 'ricerca') {
      this.router.navigate(['../ricerca'], {relativeTo: this.route});
    } else if (this.firstnodepath === 'checkup') {
      this.router.navigate(['../checkup'], {relativeTo: this.route});

    } else {
      this.router.navigate(['../viewDetails', {id: this.id, firstnodepath: this.firstnodepath}], {relativeTo: this.route});

    }
  }

  openProfileNotesDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ProfileNotesComponent, {
      width: '50%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openEditFileDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditFromFileProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openEditRevisionDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(EditRevisionProfileComponent, {
      width: '20%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openShowAttachmentsDialog(docNumber: string): void {
    const dialogRef = this.dialog.open(ShowAttachmentsDialogComponent, {
      width: '60%',
      data: {docN: docNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshView();
    });
  }

  openRelationsDialog(docNumber: string): void {
    this.router.navigate(['../relations', {relDocN: docNumber}], {relativeTo: this.route});
  }

  exportMultipleToCSV(): void {
    let header;
    const data: Array<any> = new Array<any>();
    this.Result.forEach(res => {
      header = Object.keys(res);
      const tempArray: Array<any> = new Array<any>();
      console.log(res['selected']);
      if (res['selected'] === true) {
        header.forEach((v, index) => {
          let value: string;
          value = res[v];
          if (value) {
            value = value.toString().replace(',', '');
          }
          tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
        });

        data.push(tempArray);
      }
      header.forEach((v, index) => {
        header[index] = v.substr(v.indexOf(',') + 1, v.length);
      });
    });
    this.exportAll(data, header);
  }

  exportAll(data: any, header): void {
    console.log(data);
    console.log(header);
    this.apiService.exportCSV(data, 'profiles', header);
  }

  exportToCSV(id: string): void {
    if (this.selectedRubricaArray.length > 0) {
      this.exportMultipleToCSV();
    } else {
      this.Result.forEach(res => {
        if (res['DOCNUMBER,System ID'] === id) {
          this.apiService.getUserSettings().subscribe(response => {
            if (response.profile) {
              const header = Object.keys(res);
              const tempArray: Array<any> = new Array<any>();
              header.forEach((v, index) => {
                header[index] = v.substr(v.indexOf(',') + 1, v.length);
                let value: string;
                // // console.log(res);
                // // console.log(header);
                if (res[v] !== null) {
                  value = res[v];
                } else {
                  value = '';
                }
                value = value.toString().replace(',', '');
                tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
              });
              this.apiService.exportCSV([tempArray], 'profile', header);
            } else {
              const header = Object.keys(res);
              const tempArray: Array<any> = new Array<any>();
              header.forEach((v, index) => {
                header[index] = v.substr(v.indexOf(',') + 1, v.length);
                let value: string;
                value = res[v];
                if (value) {
                  value = value.toString().replace(',', '');
                }
                tempArray[v.substr(v.indexOf(',') + 1, v.length)] = value;
              });
              this.apiService.exportCSV([tempArray], 'profile', header);
            }
          });
        }
      });
    }

  }

  signDoc(Id: string): void {
    let name = '';
    this.apiService.getProfileDetailsClass(Id).subscribe(result => {
      name = result.profileInfo.fileName;
      // // console.log(result);
      this.apiService.downloadDocument(Id).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        const self = this;
        // tslint:disable-next-line:only-arrow-functions
        reader.onloadend = function(): void {
          let base64data = reader.result.toString();
          base64data = base64data.substr(base64data.indexOf(',') + 1);
          const dialogRef = self.dialog.open(SignerDetailsComponent, {
            width: '20%',
            data: {}
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res.signerEmail) {
              self.apiService.docusignSendEnvelope(base64data, name,
                name.substr(name.lastIndexOf('.') + 1), res.signerEmail, res.signerName).subscribe(resu => {
                // // console.log(resu);
                self.snackBar.open('Operazione effettuata con successo!', null, {
                  duration: 4000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                  panelClass: ['mat-toolbar', 'custom-success-bg']
                });
              });
            } else {
              self.snackBar.open('Operazione annullata', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
              });
            }
          });
        };
        // tslint:disable-next-line:no-unused-expression
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }, errorLog => {
      this.snackBar.open('Nessun documento associato', null, {
        duration: 4000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
  }

  downloadDoc(Id: string): void {
    if (this.selectedRubricaArray.length > 0 && this.isSelectedDocArrayToUse) {
      this.isSelectedDocArrayToUse = false;
      this.selectedRubricaArray.forEach(d => {
        this.downloadDoc(d);
      });
      this.isSelectedDocArrayToUse = true;
    } else {
      let name = '';
      this.apiService.getProfileDetailsClass(Id).subscribe(result => {
        name = result.profileInfo.fileName;
        // // // console.log(name);
        this.apiService.downloadDocument(Id).subscribe(response => {
          const blob = new Blob([response], {type: 'application/octet-stream'});
          fileSaver.saveAs(blob, name);
          // tslint:disable-next-line:no-unused-expression
        }, errorLog => {
          this.snackBar.open('Nessun documento associato', null, {
            duration: 4000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }, errorLog => {
        this.snackBar.open('Nessun documento associato', null, {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }

  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'yyyy/MM/dd', '', 'en-GB');
  }

  getFormat(doc: string): any {
    let format = '';
    this.apiService.getProfileDetailsClass(doc).subscribe(result => {
      format = result.profileInfo.fileName;
      return format;
    });

  }

  goToProcess(docId: string): void {
    let id = '';
    this.apiService.getWorkflowId(docId).subscribe(result => {
      result.forEach(val => {
        id = val.id;
        this.router.navigate(['../process', {
          processId: id,
          path: 'view',
          idv: this.id,
          firstnodepath: this.firstnodepath
        }], {relativeTo: this.route});
      });
    });
  }
}

