import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../api.service';
import {Output, EventEmitter, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router, ParamMap} from '@angular/router';
import {map} from 'rxjs/operators';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogMultiDTOComponent} from '../../additionalDialog/dialogMultiDTOComponent';

import {MatTableDataSource} from '@angular/material/table';
import {DialogMaskComponent} from '../../additionalDialog/dialogMaskComponents';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {DialogClassViewDTOComponentDTOComponent} from '../../additionalDialog/dialogClassViewDTOComponent';
import {DialogSearchDocComponent} from '../../additionalDialog/dialogSearchDocComponents';
import {DatePipe} from '@angular/common';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';
import {interval, Observable} from 'rxjs';
import {ProgressSpinnerDialogComponent} from '../../additionalDialog/progress-spinner-dialog/progress-spinner-dialog.component';
import moment from 'moment';
import {UntypedFormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';


export interface SelectedRowsTableDTO {
  selected: [];
}

export interface SelectedRowsMultiDTO {
  selected: [];
}


@Component({
  selector: 'app-dettaglimaschera',
  templateUrl: './maskDetails.component.html',
  styleUrls: ['./maskDetails.component.css'],
})
export class MaskDetailsComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get('id');
  idTask = this.activatedRoute.snapshot.paramMap.get('idTask');
  operationId = this.activatedRoute.snapshot.paramMap.get('operationId');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  isOpen = false;
  maskName: string;
  splitMaskName: string;
  splitMaskOrder: string;
  splitMaskCat: string;
  classProfileType: string;
  classProfileName: string;
  classProfileValue: string;
  fields: Array<any> = new Array<any>();
  nomiAoo: Array<any> = new Array<any>();
  originsFields: Array<any> = new Array<any>();
  documenTypes: Array<any> = new Array<any>();
  stateFields: Array<any> = new Array<any>();
  toFields: Array<any> = new Array<any>();
  FromFields: Array<any> = new Array<any>();
  Row: [];
  enableAddressBook: number;
  breakpoint: number;
  fieldDataSource: Array<any> = [];
  multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
  addressBookSelectedToShow: any;
  fieldName = '';
  keyField = '';
  mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
  mapTableValueToSave: any;
  sub: any;
  tableAssociationField: object = {};
  comboAssociationField: any = {};
  mapTableValueToSee: Map<string, string> = new Map<string, string>();
  mapAddressBookToSee: Map<string, any> = new Map<string, any>();
  mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
  docType: string;
  // CLASSE BOX
  ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
  arrayToObjectClass: { [index: string]: { content: string } } = {};
  dataSourceClass: MatTableDataSource<object>;
  displayedColumnsClass: Array<string> = new Array<string>();
  docNumberClasseboxTemp: Array<string> = new Array<string>();
  optionsMask: any = {};
  dataMaskToSave: any = {};
  dataValueAssociations: any = {};
  validationCheck: boolean;
  jsonToSendArxivar: any = {};
  fileListMain: FileList;
  fileMain: File;
  todayDate = new Date();
  twDocumentOperationId: string;
  formDataMain: FormData;
  bufferIdInfo: Array<any> = new Array<any>();
  fileInfo: any = {name: '', buffer: ''};
  badgeBufferMain: number;
  arrayToObject: { [index: string]: { content: string } } = {};
  idContact: string;
  mapAddressBookSave: Array<any> = new Array<any>();
  canProfile = true;
  comboValues: any = {};
  tempCombo: Array<any> = new Array<any>();
  selectToAdd: string;
  keyToAdd: string;
  whatToShow = 'default';
  notes: Array<any> = new Array<any>();
  addNoteText = '';
  // chip list
  mapMultiValueToSee: Map<string, any> = new Map<string, any>();
  multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  savedProfileId: string;
  // attachments section
  countFields: number;
  bufferIdInfo2: Array<any> = new Array<any>();

  progressSpinnerValue = 0;
  loading = false;
  observable: any;
  dialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;
  editCombo: Array<any> = new Array<any>();
  myControl = new UntypedFormControl();
  comboControl: any = new Array<any>();
  tempSkipProfileValue: string;
  toCtrl: string;
  fromCtrl: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  toFieldData: any = new Array<any>();
  fromFieldData: any = new Array<any>();

  // tslint:disable-next-line:max-line-length variable-name
  constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService,
              private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar,
              private bodyFieldToSave: BodyFieldToSave, private datePipe: DatePipe) {
    iconRegistry.addSvgIcon(
      'BackIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/keyboard_backspace-24px.svg'));
  }

  @ViewChild('toField') toField: ElementRef<HTMLInputElement>;
  @ViewChild('fromField') fromField: ElementRef<HTMLInputElement>;

  addSelected(selected: any, field: any): void {
    this.fieldName = field.name;



    this.idContact = selected.option.value[2];
    let type;
    switch (field.name) {
      case 'To':
        type = '0';
        this.toField.nativeElement.value = '';
        this.toFieldData = new Array<any>();
        this.toCtrl = '';
        break;
      case 'From':
        type = '1';
        this.fromField.nativeElement.value = '';
        this.fromCtrl = '';
        this.fromFieldData = new Array<any>();
        break;
      case 'Cc':
        type = '2';
        break;
    }
    this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
      let fromFieldValue = new Object();
      // // // console.log(res);
      this.addressBookSelectedToShow = [];

      if (type === '0') {
        this.mapAddressBookSave.push(res);
        this.addressBookSelectedToShow = this.mapAddressBookToSee.get(field.name);
      } else {
        fromFieldValue = res;
        this.dataMaskToSave[this.fieldName] = fromFieldValue;
      }

      this.addressBookSelectedToShow.push({name: selected.option.value[3], id: this.idContact});
      if (type === '0') {
        this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
      } else {
        this.dataMaskToSave[this.fieldName] = fromFieldValue;
      }
      this.addressBookSelected.set(this.fieldName, [this.idContact]);
      this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
      console.log(this.dataMaskToSave);
      this.checkFormula();
      this.checkVisibility();
    });
  }

  checkAutocompleteTo(field: any): void {
  if (field.name === 'To'){
    if (this.toCtrl.length > 2) {
      this.apiService.SearchAddressBookFilter(this.toCtrl, field.addressBookDefaultFilter).subscribe(res => {
        console.log(res);
        this.toFieldData = res;
      });
    } else {
      this.toFieldData = new Array<any>();
    }
  }else if (field.name === 'From'){
    if (this.fromCtrl.length > 2) {
      this.apiService.SearchAddressBookFilter(this.fromCtrl, field.addressBookDefaultFilter).subscribe(res => {
        console.log(res);
        this.fromFieldData = res;
      });
    } else {
      this.fromFieldData = new Array<any>();
    }
  }

  }

  ngOnInit(): void {
    this.badgeBufferMain = this.bufferIdInfo.length;
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
    this.apiService.getAoo().subscribe(data => {
      this.nomiAoo = data;
    });
    this.apiService.getOrigins().subscribe(data => {
      this.originsFields = data;
    });
    this.apiService.getDocumentType(this.id).subscribe(data => {
      this.documenTypes = data;
    });
    this.apiService.getStates().subscribe(data => {
      this.stateFields = data;
    });
    if (this.idTask === '0') {
      this.apiService.getProfileSchema(this.id).subscribe(data => {
        // // console.log(data);
        this.optionsMask = data.options;
        this.maskName = data.maskName;
        this.apiService.addPath(this.maskName, 'dashboard/dashnav/maskDetails', {
          id: this.id, idTask: this.idTask,
          operationId: this.operationId,
          path: this.path, firstnodepath: this.firstnodepath
        });
        this.apiService.namePath += ' - ' + this.maskName;
        this.splitMaskCat = this.maskName.split('-')[0];
        this.splitMaskOrder = this.maskName.split('-')[1];
        this.splitMaskName = this.maskName.split('-')[2];
        data.fields = data.fields.sort((a, b) => {
          return a.order - b.order;
        });
        this.fields = data.fields;
        data.fields.forEach(findClass => {
          if (findClass.visible) {
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.dataValueAssociations[findClass.name] = findClass.associations;
          }

          if (findClass.className === 'DocumentDateFieldDTO') {
            findClass.value = this.TransformDataFormatToSave(this.todayDate.toDateString());
            this.dataMaskToSave[findClass.name] = this.TransformDataFormatToSave(this.todayDate.toDateString());
          }
          if (findClass.className === 'AdditionalFieldMultivalueDTO') {
            // console.log(findClass);
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[findClass.name].forEach(v => {
              this.multiValuteDTOSelectedToShow.push(v);
            });
            this.mapMultiValueToSee.set(findClass.name, this.multiValuteDTOSelectedToShow);
          }
          if (findClass.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(findClass.name, findClass.value);
            findClass.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (findClass.className === 'ToFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            this.enableAddressBook = findClass.enabledAddressBook[0];
            // // // console.log(findClass);
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.society);

              });

              this.addressBookSelected.set(findClass.name, selected);
              // // // console.log(this.addressBookSelected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'FromFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              this.addressBookSelectedToShow.push({name: findClass.value.society, id: findClass.value.contactId});
              selected.push(findClass.value.society);
              // // // console.log(this.addressBookSelectedToShow);
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }


          }
          if (findClass.className === 'CcFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.society);

              });
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'DocumentTypeFieldDTO') {
            this.classProfileType = findClass.className;
            this.classProfileName = findClass.name;
            this.classProfileValue = findClass.value;
          }
        });
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              if (results.dataSource) {
                this.tempCombo = new Array<any>();
                results.dataSource.forEach(res => {
                  this.selectToAdd = '';
                  this.keyToAdd = '';
                  res.columns.forEach(r => {
                    if (results.selectField.includes(r.id)) {
                      this.selectToAdd += r.value + ' - ';
                    }
                    if (results.keyField.includes(r.id)) {
                      this.keyToAdd += r.value + ' - ';
                    }
                  });
                  this.tempCombo.push({
                    selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                    keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                    visible: true
                  });
                  this.comboValues[v.name] = this.tempCombo;
                  let found = false;
                  if (!v.limitToList && v.value) {
                    this.comboValues[v.name].forEach(cycle => {
                      if (cycle.keyField === v.value) {
                        this.comboControl[v.name] = cycle.selectField;
                        found = true;
                      }
                    });
                    if (!found) {
                      this.comboControl[v.name] = v.value;
                    }
                  }
                });
              }
            });
          }
        });
        this.checkVisibility();
        this.checkFormula();
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    } else {
      this.apiService.getMaskProfileForTask(this.idTask, this.operationId).subscribe(data => {
        // // // console.log(data);
        this.optionsMask = data.options;
        this.maskName = data.maskName;
        this.apiService.addPath(this.maskName, 'dashboard/dashnav/maskDetails', {
          id: this.id, idTask: this.idTask,
          operationId: this.operationId,
          path: this.path, firstnodepath: this.firstnodepath
        });
        this.apiService.namePath += ' - ' + this.maskName;
        this.splitMaskCat = this.maskName.split('-')[0];
        this.splitMaskOrder = this.maskName.split('-')[1];
        this.splitMaskName = this.maskName.split('-')[2];
        data.fields = data.fields.sort((a, b) => {
          return a.order - b.order;
        });
        this.fields = data.fields;
        data.fields.forEach(findClass => {
          if (findClass.visible) {
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.dataValueAssociations[findClass.name] = findClass.associations;
          }
          if (findClass.className === 'DocumentDateFieldDTO') {
            findClass.value = this.TransformDataFormatToSave(this.todayDate.toDateString());
            this.dataMaskToSave[findClass.name] = this.TransformDataFormatToSave(this.todayDate.toDateString());
          }
          if (findClass.className === 'AdditionalFieldClasseDTO') {
            this.mapClasseBookToSave.set(findClass.name, findClass.value);
            findClass.columns.forEach(column => {
              this.displayedColumnsClass.push(column.label);
            });
            this.displayedColumnsClass.push('Azioni');
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
          }
          if (findClass.className === 'AdditionalFieldMultivalueDTO') {
            // console.log(findClass);
            this.dataMaskToSave[findClass.name] = findClass.value;
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[findClass.name].forEach(v => {
              this.multiValuteDTOSelectedToShow.push(v);
            });
            this.mapMultiValueToSee.set(findClass.name, this.multiValuteDTOSelectedToShow);
          }
          if (findClass.className === 'ToFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            // // // console.log(findClass);
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.society);

              });

              this.addressBookSelected.set(findClass.name, selected);
              // // // console.log(this.addressBookSelected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'FromFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              this.addressBookSelectedToShow.push({name: findClass.value.society, id: findClass.value.contactId});
              selected.push(findClass.value.society);
              // // // console.log(this.addressBookSelectedToShow);
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
              // // // console.log(this.mapAddressBookToSee);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }


          }
          if (findClass.className === 'CcFieldDTO') {
            const selected: Array<string> = new Array<string>('');
            this.addressBookSelectedToShow = [];
            if (findClass.value) {
              findClass.value.forEach(item => {
                // // // console.log(item);
                this.addressBookSelectedToShow.push({name: item.society, id: item.contactId});
                selected.push(item.society);

              });
              this.addressBookSelected.set(findClass.name, selected);
              this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
            } else {
              this.mapAddressBookToSee.set(findClass.name, []);
            }

          }
          if (findClass.className === 'DocumentTypeFieldDTO') {
            this.classProfileType = findClass.className;
            this.classProfileName = findClass.name;
            this.classProfileValue = findClass.value;
          }
        });
        this.fields.forEach(v => {
          if (v.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(v.name, this.getJsonFields().fields).subscribe(results => {
              // // console.log(results);
              this.editCombo[v.name] = false;
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.includes(r.id)) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.includes(r.id)) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                  visible: true
                });
                this.comboValues[v.name] = this.tempCombo;
              });
            });
          }
        });
        this.checkVisibility();
        this.checkFormula();
      }, errorLog => {
        this.snackBar.open(errorLog.error.userMessage, null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-danger-bg']
        });
      });
    }
    // console.log(this.apiService.namePathV2);
  }

  checkField(field: any): void {
    this.dataMaskToSave[field.name] = this.comboControl[field.name];
    field.value = this.comboControl[field.name];
    this.comboValues[field.name].forEach(val => {
      if (!val.selectField.toLowerCase().includes(this.comboControl[field.name])) {
        val.visible = false;
      } else {
        val.visible = true;
      }
    });
  }

  displayFn(field: any, value?: any): any {
    if (value) {
      this.dataMaskToSave[field.name] = value.keyField;
      field.value = value.keyField;
      return value.selectField;
    } else {
      this.dataMaskToSave[field.name] = this.comboControl[field.name];
      field.value = this.comboControl[field.name];
      return this.comboControl[field.name];
    }

  }


  changeEditCombo(name: string): void {
    this.editCombo[name] = !this.editCombo[name];
  }

  openMultiValueDTO(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        // // console.log(results);
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
          id: 'style-4',
          width: '40%',
          height: '60%',
          data: {
            additionalFieldMultiDTO: this.fieldDataSource,
            fieldName: field.name,
            multiValueToSelected: this.multiValuteDTOSelected,
            selectField: results.keyField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);
          if (result.dataResult === 'delete') {
            this.multiValuteDTOSelectedToShow = new Array<any>();
            this.dataMaskToSave[this.fieldName] = '';
            this.mapMultiValueToSee.set(this.fieldName, '');
            this.multiValuteDTOSelected.set(this.fieldName, []);
          }
          this.multiValuteDTOSelectedToShow = new Array<any>();
          if (result.dataResult !== false) {
            result.dataResult.forEach(item => {
              this.multiValuteDTOSelectedToShow.push(item);
            });
            this.dataMaskToSave[this.fieldName] = result.dataResult;
            this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
            this.multiValuteDTOSelected.set(this.fieldName, result.dataResult);
            this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
          }
          this.getAllProfileValue();
          this.checkFormula();
        });
      });
    }
  }

  checkComboAssociations(field: any): void {
    let comboResult: any = {};
    let selectedElement;
    this.fieldName = field.name;
    console.log(this.dataMaskToSave[field.name]);
    this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {

      comboResult = results;
      this.comboAssociationField = {};
      this.comboAssociationField = Object.keys(results.associations);
      this.fieldDataSource = new Array<any>();
      this.keyField = results.keyField;
      if (results.dataSource != null) {

        results.dataSource.forEach(item => {
          item.columns.forEach(v => {
            if (v.id.toUpperCase() === this.keyField.toUpperCase()) {
              if (v.value.toString() === field.value.toString()) {
                v.value = this.dataMaskToSave[field.name];
                selectedElement = item;
              }
            }
          });

        });
      } else {
        // EMPTY COMBO
      }
      console.log(selectedElement);
      this.comboAssociationField.forEach(res => {
        if (selectedElement) {
          selectedElement.columns.forEach(v2 => {

            if (v2.id === comboResult.associations[res]) {
              if (res.toUpperCase().startsWith('DATA')) {
                this.dataMaskToSave[res.toUpperCase()] = this.TransformDataFormatToSave(v2.value);
              } else {
                this.dataMaskToSave[res.toUpperCase()] = v2.value;
              }

            }
          });
        }
      });
      console.log(this.fields);
      this.tempSkipProfileValue = field.name;
      this.getAllProfileValue();
    });
  }

  checkInputFormula(): void {
    this.formulaCalculatorNoLoading(0);
    this.progressSpinnerValue = 100;
  }

  formulaCalculatorNoLoading(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }
            if (this.fields[i + 1]) {
              this.formulaCalculatorNoLoading(i + 1);
            }
            found = true;
            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.checkVisibility();
                this.progressSpinnerValue = 100;
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          if (this.fields[i + 1]) {
            this.formulaCalculatorNoLoading(i + 1);
          }
          if (!this.fields[i + 1]) {
            this.checkVisibility();
            this.progressSpinnerValue = 100;
            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.checkVisibility();
                    this.progressSpinnerValue = 100;
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculatorNoLoading(i + 1);
      } else {
        this.checkVisibility();
        this.progressSpinnerValue = 100;
        this.getAllNullProfileValue();
      }
    }
  }

  checkFormula(): void {
    console.log(this.dataMaskToSave);
    this.formulaCalculator(0);
    // this.progressSpinnerValue = 100;
  }

  formulaCalculator(i: number): void {

    let pastFormulas: Array<any> = new Array<any>();
    let found = false;
    pastFormulas = new Array<any>();
    if (this.fields[i].formula) {
      found = false;
      pastFormulas.push({name: this.fields[i].name, formula: this.fields[i].formula});
      pastFormulas.forEach(pf => {
        if (pf.formula.toString().includes(this.fields[i].name.toString())) {
          this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
            if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
              this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
            } else {
              this.dataMaskToSave[this.fields[i].name] = res;
            }

            this.progressSpinnerValue = i / this.fields.length * 100;
            if (this.fields[i + 1]) {
              this.formulaCalculator(i + 1);
            }
            found = true;

            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
              if (pf.className === 'AdditionalFieldDateTimeDTO') {
                this.dataMaskToSave[pf.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
              } else {
                this.dataMaskToSave[pf.name] = res2;
              }
              if (!this.fields[i + 1]) {
                this.checkVisibility();
                this.progressSpinnerValue = 100;
                this.getAllNullProfileValue();
              }
            });
          });
        }
      });
      if (!found) {
        this.apiService.getProfileFormula(this.getUpdatedFields(this.fields[i].name, this.fields[i].formula)).subscribe(res => {
          if (this.fields[i].className === 'AdditionalFieldDateTimeDTO') {
            this.dataMaskToSave[this.fields[i].name] = moment(res.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
          } else {
            this.dataMaskToSave[this.fields[i].name] = res;
          }
          this.progressSpinnerValue = i / this.fields.length * 100;
          if (this.fields[i + 1]) {
            this.formulaCalculator(i + 1);
          }
          if (!this.fields[i + 1]) {
            this.checkVisibility();
            this.progressSpinnerValue = 100;
            this.getAllNullProfileValue();
          }
          this.fields.forEach(field => {
            if (field.formula) {
              if (field.formula.toString().includes(this.fields[i].name)) {
                this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                  if (field.className === 'AdditionalFieldDateTimeDTO') {
                    this.dataMaskToSave[field.name] = moment(res2.replace(' 00:00:00', ''), 'DD/MM/YYYY').toDate();
                  } else {
                    this.dataMaskToSave[field.name] = res2;
                  }
                  if (!this.fields[i + 1]) {
                    this.checkVisibility();
                    this.progressSpinnerValue = 100;
                    this.getAllNullProfileValue();
                  }
                });
              }
            }

          });
        });
      }
    } else {
      if (this.fields[i + 1]) {
        this.formulaCalculator(i + 1);
      } else {
        this.checkVisibility();
        this.progressSpinnerValue = 100;
        this.getAllNullProfileValue();
      }
    }
  }

  getUpdatedFields(name: string, form: string): any {
    this.jsonToSendArxivar =
      {
        fieldName: name,
        formula: form,
        searchFilterDto: {
          fields: []
        }
      };
    this.fields.forEach((field, index) => {
      if (field.visible || this.dataMaskToSave[field.name]) {
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }
      }
      this.jsonToSendArxivar.searchFilterDto.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  remove(v: string, fieldName: string): void {
    this.multiValuteDTOSelectedToShow = new Array<any>();
    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value === v) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.multiValuteDTOSelectedToShow.push(value);
    });
    this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
  }

  removeAddressBook(v: any, fieldName: string): void {
    this.addressBookSelectedToShow = [];
    const tempSelected = [];
    if (fieldName === 'To'){

    this.dataMaskToSave[fieldName].forEach((value, index) => {
      if (value.contactId === v.id) {
        this.dataMaskToSave[fieldName].splice(index, 1);
      }
    });
    this.dataMaskToSave[fieldName].forEach((value) => {
      this.addressBookSelectedToShow.push({name: value.society, id: value.contactId});
      tempSelected.push(value.contactId);
    });

    }else{
      this.dataMaskToSave[fieldName].value = {};
    }
    this.mapAddressBookToSee.set(fieldName, this.addressBookSelectedToShow);
    this.addressBookSelected.set(this.fieldName, tempSelected);
  }

  checkVisibility(): void {
    setTimeout(() => {
      this.checkMandatory();
      let pastFormulas: Array<any> = new Array<any>();
      const found = false;
      pastFormulas = new Array<any>();
      // // console.log(this.fields);
      this.fields.forEach(f => {
        if (f.visibilityCondition === null || f.visibilityCondition === '') {
        } else {
          const splittedConditions: Array<string> = new Array<string>();
          const operators: Array<string> = new Array<string>();
          let condition = f.visibilityCondition;
          let end = false;
          const i = 0;
          let result = false;
          while (!end) {
            if (condition.includes('||') && condition.includes('&&')) {
              if (condition.indexOf('||') < condition.indexOf('&&')) {
                splittedConditions.push(condition.split('||')[0]);
                condition = condition.substring(condition.indexOf('||') + 2, condition.length);
                operators.push('||');
              } else {
                splittedConditions.push(condition.split('&&')[0]);
                condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
                operators.push('&&');
              }
            } else if (condition.includes('||')) {
              splittedConditions.push(condition.split('||')[0]);
              condition = condition.substring(condition.indexOf('||') + 2, condition.length);
              operators.push('||');
            } else if (condition.includes('&&')) {
              splittedConditions.push(condition.split('&&')[0]);
              condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
              operators.push('&&');
            } else {
              splittedConditions.push(condition);
              end = true;
            }
          }

          splittedConditions.forEach((cond, index) => {
            let field: string;
            let equalTo: string;
            let isNotEqual: boolean;
            let conditionBeforeBrackets;
            if (cond.includes('<>')) {
              equalTo = cond.substring(cond.indexOf('<>') + 2, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('<>')).trim();
              isNotEqual = true;
            } else {
              equalTo = cond.substring(cond.indexOf('=') + 1, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('=')).trim();
              isNotEqual = false;
            }

            /*equalTo = equalTo.split(')').join('');
            equalTo = equalTo.split('(').join('');*/

            if (!isNotEqual) {
              /* console.log(this.dataMaskToSave[field]);
               console.log(equalTo);*/
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }
                if (this.dataMaskToSave[field].toString().trim() === equalTo.toString().trim()) {
                  // // console.log('la condizione è vera: ' + cond);
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }

                } else {
                  // // console.log('la condizione è falsa: ' + cond);
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            } else {
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }

                if (this.dataMaskToSave[field].toString().trim() !== equalTo.toString().trim()) {
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }
                } else {
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            }
            /*if (f.name === 'TABLE308_12'){
                // console.log(result);
                // console.log(cond);
                // console.log(equalTo.toString().trim());
                // console.log(this.dataMaskToSave);
            }*/
          });
          f.visible = result;
        }
      });
    }, 1);
  }

  checkMandatory(): void {
    setTimeout(() => {
      let pastFormulas: Array<any> = new Array<any>();
      const found = false;
      pastFormulas = new Array<any>();
      // // console.log(this.fields);
      this.fields.forEach(f => {
        if (f.mandatoryCondition === null || f.mandatoryCondition === '') {
        } else {
          const splittedConditions: Array<string> = new Array<string>();
          const operators: Array<string> = new Array<string>();
          let condition = f.mandatoryCondition;
          let end = false;
          const i = 0;
          let result = false;
          while (!end) {
            if (condition.includes('||') && condition.includes('&&')) {
              if (condition.indexOf('||') < condition.indexOf('&&')) {
                splittedConditions.push(condition.split('||')[0]);
                condition = condition.substring(condition.indexOf('||') + 2, condition.length);
                operators.push('||');
              } else {
                splittedConditions.push(condition.split('&&')[0]);
                condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
                operators.push('&&');
              }
            } else if (condition.includes('||')) {
              splittedConditions.push(condition.split('||')[0]);
              condition = condition.substring(condition.indexOf('||') + 2, condition.length);
              operators.push('||');
            } else if (condition.includes('&&')) {
              splittedConditions.push(condition.split('&&')[0]);
              condition = condition.substring(condition.indexOf('&&') + 2, condition.length);
              operators.push('&&');
            } else {
              splittedConditions.push(condition);
              end = true;
            }
          }

          splittedConditions.forEach((cond, index) => {
            let field: string;
            let equalTo: string;
            let isNotEqual: boolean;
            let conditionBeforeBrackets;
            if (cond.includes('<>')) {
              equalTo = cond.substring(cond.indexOf('<>') + 2, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('<>')).trim();
              isNotEqual = true;
            } else {
              equalTo = cond.substring(cond.indexOf('=') + 1, cond.length).trim().split('\'').join('');
              field = cond.substring(0, cond.indexOf('=')).trim();
              isNotEqual = false;
            }

            /*equalTo = equalTo.split(')').join('');
            equalTo = equalTo.split('(').join('');*/

            if (!isNotEqual) {
              /* console.log(this.dataMaskToSave[field]);
               console.log(equalTo);*/
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }
                if (this.dataMaskToSave[field].toString().trim() === equalTo.toString().trim()) {
                  // // console.log('la condizione è vera: ' + cond);
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }

                } else {
                  // // console.log('la condizione è falsa: ' + cond);
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            } else {
              if ((this.dataMaskToSave[field] !== null && equalTo !== null) && (this.dataMaskToSave[field] !== undefined && equalTo !== undefined)) {
                if (equalTo.toString().trim().charCodeAt(0) === 62 && equalTo.toString().trim().length === 1) {
                  equalTo = '';
                }

                if (this.dataMaskToSave[field].toString().trim() !== equalTo.toString().trim()) {
                  if (index !== 0 && result === false) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {
                      result = true;
                    }
                  } else {
                    /*// console.log('Risultato ----- ');
                    // console.log('-----------------------' + cond);
                    // console.log(this.dataMaskToSave[field].toString().trim() as string);
                    // console.log(equalTo.toString().trim() as string);
                    // console.log('LUNGHEZZA ' + equalTo.toString().trim().length);
                    if (this.dataMaskToSave[field].toString().trim() as string !== equalTo.toString().trim() as string) {
                      // console.log('entrato è vero!!!!');
                    }*/
                    result = true;
                  }
                } else {
                  if (index !== 0) {
                    if (operators[index - 1] === '&&') {
                      result = false;
                    } else {

                    }
                  } else {
                    result = false;
                  }

                }
              } else {
                result = false;
              }
            }
            /*if (f.name === 'TABLE308_12'){
                // console.log(result);
                // console.log(cond);
                // console.log(equalTo.toString().trim());
                // console.log(this.dataMaskToSave);
            }*/
          });
          f.required = result;
        }
      });
    }, 1);
  }

  showProgressSpinnerUntilExecuted(observable: Observable<any>): void {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    const subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        // handle response
        // console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        dialogRef.close();
      }
    );
  }

  showProgressSpinner(observable: Observable<any>): void {
    this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    const subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        // handle response
        // console.log(response);
        this.dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        this.dialogRef.close();
      }
    );
  }

  myObservableFast(observer): void {
    setTimeout(() => {
      observer.next('done waiting for 5 sec');
      observer.complete();
    }, 500);
  }

  myObservableLoading(observer): void {

  }

  openTableDTO(field: any): void {

    if (!field.locked) {
      this.observable = new Observable(this.myObservableFast);
      this.showProgressSpinnerUntilExecuted(this.observable);
      this.fieldName = field.name;
      this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
        this.tableAssociationField = {};
        this.tableAssociationField = Object.keys(results.associations)
          .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
        this.fieldDataSource = new Array<object>();
        if (results.dataSource != null) {
          this.keyField = results.keyField;
          results.dataSource.forEach(item => {
            this.fieldDataSource.push(item.columns);
          });
        } else {
          // EMPTY MULTI VALUE
        }
        const dialogRef = this.dialog.open(DialogTableDTOComponent, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            additionalFieldTableDTO: this.fieldDataSource,
            fieldName: field.name,
            tableValueToSelected: this.tableValuteDTOSelected,
            selectField: results.selectField
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);
          // console.log(this.tableAssociationField);
          if (result.dataResult === 'delete') {
            this.fields.forEach((item, index) => {
              if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
              if (item.name === this.fieldName) {
                this.fields[index].value = '';
                this.dataMaskToSave[this.fields[index].name] = '';
              }
            });
          }
          if (result.dataResult !== false && result.dataResult !== 'delete') {
            this.mapTableValueToSave = result.dataResult;
            this.fields.forEach((item, index) => {
              if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                if (item.name.startsWith('DATA')) {
                  this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                  this.dataMaskToSave[this.fields[index].name] = new Date(this.TransformDataFormatToSave(result.dataResult[this.tableAssociationField[item.name.toLowerCase()]]));
                } else {
                  this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                }
              }
              if (item.name === this.fieldName) {
                if (item.name.startsWith('DATA')) {
                  this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                  this.dataMaskToSave[this.fields[index].name] = new Date(this.TransformDataFormatToSave(result.dataResult[this.tableAssociationField[item.name.toLowerCase()]]));
                } else {
                  this.fields[index].value = result.dataResult[this.keyField];
                  this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
                }
              }
            });
          }
          // console.log(this.fields);
          // console.log(this.dataMaskToSave);
          this.getAllProfileValue();
        });
      });
    }
  }

  openAddressBook(field: any): void {
    if (!field.locked) {
      this.fieldName = field.name;
      this.observable = new Observable(this.myObservableFast);
      this.showProgressSpinnerUntilExecuted(this.observable);
      this.apiService.SearchAllAddressBook().subscribe(results => {
        this.apiService.viewDataToShow = results;
        const name = field.name;
        if (field.description === 'Anagrafica cliente') {
          // name = 'From';
        } else {

        }
        const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
          id: 'style-4',
          width: '80%',
          height: '95%',
          data: {
            enabledAddressBook: this.enableAddressBook,
            fieldName: name,
            addressBookSelected: this.addressBookSelected,
            selectField: 'DM_RUBRICA_RAGIONE_SOCIALE',

          }
        });


        dialogRef.afterClosed().subscribe(result => {
          this.mapAddressBookSave = new Array<any>();
          let fromFieldValue = new Object();
          this.addressBookSelectedToShow = [];
          // this.addressBookSelected = new Map<string, string[]>();
          let type: string;
          switch (this.fieldName) {
            case 'To':
              type = '0';
              break;
            case 'From':
              type = '1';
              break;
            case 'Cc':
              type = '2';
              break;
            case 'Senders':
              type = '3';
              break;
          }
          result.dataResult.forEach((val, index) => {

            this.idContact = val;
            this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
              // // // console.log(res);
              if (type === '0') {
                this.mapAddressBookSave.push(res);
              } else {
                fromFieldValue = res;
                this.dataMaskToSave[this.fieldName] = fromFieldValue;
              }
              if (index === result.dataResult.length - 1) {
                if (result.dataResult !== false) {
                  result.contactsNames.forEach(item => {
                    this.addressBookSelectedToShow.push({name: item, id: val});
                  });
                  if (type === '0') {
                    this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
                  } else {
                    this.dataMaskToSave[this.fieldName] = fromFieldValue;
                  }
                  this.addressBookSelected.set(this.fieldName, result.dataResult);
                  this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
                }
                this.checkFormula();
                this.getAllProfileValue();
              }
            });
          });
        });
      });
    }
  }


  deleteNote(note: any): void {
    this.notes.forEach((v, index) => {
      if (v.comment === note.comment) {
        this.notes.splice(index, 1);
        this.snackBar.open('Nota eliminata correttamente', null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
      }
    });
  }

  addNote(): void {
    if (this.addNoteText !== '') {
      this.notes.push({
        aosflag: false,
        comment: this.addNoteText,
        docnumber: null,
        isEdit: false,
        isPaNote: false
      });
      this.snackBar.open('Nota aggiunta correttamente', null, {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-success-bg']
      });
    }
    this.addNoteText = '';
  }

  insertMaskFromClassDTO(field: any): void {
    if (!field.locked) {
      if (this.bufferIdInfo.length > 0) {
        this.jsonToSendArxivar = {
          document: {
            fileNames: [],
            bufferIds: []
          },
          fields: [],
          notes: this.notes
        };
        this.bufferIdInfo.forEach(file => {
          this.jsonToSendArxivar.document.fileNames.push(file.name);
          this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
        });
      } else {
        this.jsonToSendArxivar = {
          fields: [],
          notes: this.notes
        };
      }
      this.fields.forEach((field1, index) => {
        if (field1.visible) {
          // // // // console.log(this.dataMaskToSave);
          if (field1.className === 'AdditionalFieldDateTimeDTO') {
            this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field1.name]);
          } else if (field1.className === 'AdditionalFieldDoubleDTO') {
            if (this.dataMaskToSave[field1.name]) {
              this.fields[index].value = (this.dataMaskToSave[field1.name].toString().replace(',', '.'));
            } else {
              this.fields[index].value = this.dataMaskToSave[field1.name];
            }
          } else {
            this.fields[index].value = this.dataMaskToSave[field1.name];
          }
        }
        this.jsonToSendArxivar.fields.push(this.fields[index]);
      });
      this.fieldName = field.name;
      const dialogRef = this.dialog.open(DialogMaskComponent, {
        width: '80%',
        height: '80%',
        data: {
          json: this.jsonToSendArxivar,
          id: field.insertMaskId,
          fieldName: field.name,
          dataMask: this.dataMaskToSave,
          dataAssociations: this.dataValueAssociations
        }
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result.hasOwnProperty('docNumber')) {

          this.docNumberClasseboxTemp = new Array<string>();
          this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
          this.docNumberClasseboxTemp.push(result.docNumber);
          this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);

          this.arrayToObject = {};
          this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
            callResults.fields.forEach(fieldClass => {
              this.displayedColumnsClass.forEach(colum => {
                if (colum === fieldClass.description) {
                  this.arrayToObject[colum] = fieldClass.value;
                }
              });
            });
            this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
            console.log(callResults);
          });
        }
      });
    }
  }

  openClassMasks(field: any): void {

    if (!field.locked) {
      this.fieldName = field.name;
      this.apiService.getClassAdditionalFieldsV2(field.name, this.classProfileValue, this.mapClasseBookToSave.get(field.name)).subscribe(callResults => {
        this.apiService.viewDataToShow = callResults;
        const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
          width: '80%',
          height: '80%',
          data: {
            classField: field,
            mapClassValue: this.mapClasseBookToSave.get(field.name),
            docType: this.classProfileValue
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // // // // console.log(this.mapClasseBookToSave.get(result.fieldName));
        });
      });


    }
  }

  searchMaskFromClassDTO(field: any): void {
    if (!field.locked) {
      const dialogRef = this.dialog.open(DialogSearchDocComponent, {
        width: '80%',
        height: 'fit-content',
        data: {
          classField: field
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        // // console.log(this.mapClasseBookToSave.get(result.fieldName));
        // // console.log(res);

        res.dataResult.forEach(result => {


          this.docNumberClasseboxTemp = new Array<string>();
          // // console.log(this.mapClasseBookToSave);
          this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
          // // console.log(this.docNumberClasseboxTemp);

          this.docNumberClasseboxTemp.push(result.docNumber);
          this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);

          this.arrayToObject = {};
          this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
            callResults.fields.forEach(fieldClass => {
              this.displayedColumnsClass.forEach(colum => {
                if (colum === fieldClass.description) {
                  this.arrayToObject[colum] = fieldClass.value;
                }
              });
            });
            this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
            this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
            // // // // console.log(this.ELEMENT_DATA_CLASS);
          });
        });
      });
    }
  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
  }

  showFileSelection(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo.push(this.fileInfo);
        this.badgeBufferMain = this.bufferIdInfo.length;
      });
    }
  }

  deleteAttach(bufferId: string): void {
    this.bufferIdInfo2.forEach((res, index) => {
      if (res.buffer === bufferId) {
        this.bufferIdInfo2.splice(index, 1);
        this.snackBar.open('Allegato eliminato con successo', null, {
          duration: 7000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-warning-bg']
        });
      }
    });
  }

  addAttach(event): void {
    this.fileInfo = {name: '', buffer: ''};
    this.fileListMain = event.target.files;
    if (this.fileListMain.length > 0) {
      this.fileMain = this.fileListMain[0];
      this.formDataMain = new FormData();
      this.formDataMain.append('file', this.fileMain, this.fileMain.name);
      this.apiService.openBuffer(this.formDataMain).subscribe(result => {
        this.fileInfo.name = this.fileMain.name;
        this.fileInfo.buffer = result[0];
        this.bufferIdInfo2.push(this.fileInfo);
      });
    }
  }

  showAttachments(): void {
    this.whatToShow = 'attachments';
  }

  showNotes(): void {
    this.whatToShow = 'note';
  }

  showDesk(): void {
    this.whatToShow = 'default';
  }

  removeFile(file: any): void {
    this.apiService.deleteBuffer(file).subscribe(result => {
      if (result) {
        this.snackBar.open('File ' + file.name + ' eliminato!', null, {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });
        this.bufferIdInfo.forEach((item, index) => {
          if (item.buffer === file.buffer) {
            this.bufferIdInfo.splice(index, 1);
          }
        });
        this.badgeBufferMain = this.bufferIdInfo.length;
      }
    });
  }

  saveMask(): void {
    this.validationCheck = true;
    this.fields.forEach(field => {
      if (field.visible) {
        if (field.required) {
          // // // console.log(this.dataMaskToSave);
          if ((field.value === '' || field.value == null || field.value === '0001-01-01T00:00:00')
            && (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null)) {
            this.snackBar.open('Il campo ' + field.description + ' è obbligatorio', null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
            this.validationCheck = false;
          }
        }
      }
    });
    if (this.validationCheck) {

      this.buildDataMask();
    }
  }

  buildDataMask(): void {

    const attach: Array<string> = new Array<string>();
    this.bufferIdInfo2.forEach(res => {
      attach.push(res.buffer);
    });
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: [],
        notes: this.notes,
        attachments: attach
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: [],
        notes: this.notes,
        attachments: attach
      };
    }
    this.observable = new Observable(this.myObservableLoading);
    this.showProgressSpinner(this.observable);
    this.fields.forEach((field, index) => {
      if (field.visible) {
        // // // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // // // console.log(this.dataMaskToSave[field.name]);

        // // // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    // // console.log(this.jsonToSendArxivar);
    if (this.canProfile) {
      if (this.idTask === '0') {
        this.canProfile = false;
        this.apiService.insertNewProfile(this.jsonToSendArxivar, this.id).subscribe(results => {
          if (results.showMessage) {

            this.savedProfileId = results.docNumber;
            if (false) {
              const checkWFBody = {
                searchFilterDtoList: [
                  {
                    description: 'Ricerca 1',
                    daAAndOr: 0,
                    fields: [

                      {
                        operator: 3,
                        valore1: results.docNumber,
                        valore2: null,
                        groupId: 0,
                        fieldType: 0,
                        additionalFieldType: 0,
                        defaultOperator: 3,
                        tableName: null,
                        binderFieldId: 0,
                        multiple: null,
                        name: 'DocNumber',
                        externalId: '',
                        description: 'System Id:',
                        order: 38,
                        dataSource: '',
                        required: false,
                        formula: '',
                        className: 'FieldBaseForSearchIntDto',
                        locked: false,
                        comboGruppiId: '',
                        dependencyFields: [],
                        associations: null,
                        isAdditional: false,
                        visible: true,
                        predefinedProfileFormula: null,
                        and: null
                      },

                    ]
                  }
                ],
                selectFilterDto: {
                  fields: [
                    {
                      fieldType: 0,
                      selected: true,
                      name: 'WORKFLOW',
                      index: 2,
                      orderBy: {
                        direction: 0,
                        index: 0
                      }
                    },
                    {
                      fieldType: 0,
                      selected: true,
                      name: 'DOCNUMBER',
                      index: 8,
                      orderBy: {
                        direction: 2,
                        index: 0
                      }
                    },
                  ],
                  maxItems: 0
                }
              };
              let repetitions = 0;
              this.sub = setInterval(() => {
                repetitions++;
                this.apiService.doSearch(checkWFBody).subscribe(result => {
                  if (result.data[0][0] === 1) {
                    this.dialogRef.close();
                    clearInterval(this.sub);
                    this.snackBar.open('Profilo caricato correttamente con id: ' + results.docNumber, null, {
                      duration: 5000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                      panelClass: ['mat-toolbar', 'custom-success-bg']
                    });
                    this.goBack();
                  }
                });
                if (repetitions === 20) {
                  clearInterval(this.sub);
                  this.snackBar.open('Attenzione, processo di workflow non avviato nei tempi prestabiliti, contattare l\'assistenza per verificare. Il documento è stato comunque inserito con id:' + +results.docNumber, null, {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-danger-bg']
                  });
                  this.dialogRef.close();
                  this.goBack();
                }
              }, 1000);
            } else {
              this.snackBar.open('Profilo caricato correttamente con id: ' + results.docNumber, null, {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-success-bg']
              });
              this.dialogRef.close();
              this.goBack();
            }
            /*this.observable = new Observable(this.myObservableLoading);
            this.showProgressSpinnerUntilExecuted(this.observable);*/

          } else {
            this.canProfile = true;
            this.snackBar.open('Errore nel caricamento del profilo', null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          }
        }, errorLog => {
          this.canProfile = true;
          this.dialogRef.close();
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 7000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      } else {
        this.canProfile = false;
        this.apiService.insertTaskByMask(this.idTask, this.operationId, this.jsonToSendArxivar).subscribe(results => {
          if (results.showMessage) {
            this.snackBar.open('Profilo caricato correttamente nel task con id: ' + this.idTask, null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-success-bg']
            });
            this.dialogRef.close();
            this.goBack();
          } else {
            this.canProfile = true;
            this.snackBar.open('Errore nel caricamento del profilo', null, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          }
        }, errorLog => {
          this.dialogRef.close();
          this.canProfile = true;
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 7000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    }
  }

  TransformDataFormatToSave(Data: string): string {

    if (Data === '' || !Data) {
      return '0001-01-01T00:00:00';
    }
    // // console.log(Data);
    let dateMomentObject = moment(Data, 'DD/MM/YYYY'); // 1st argument - string, 2nd argument - format
    let dateObject = dateMomentObject.toDate();
    // // console.log(dateMomentObject);
    if (dateMomentObject.isValid()) {
      return this.datePipe.transform(dateObject, 'yyyy-MM-dd', '', 'en-GB');
    } else {
      return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

  }

  TransformDataFormatToShow(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }

  TransformData(Data: string): string {
    Data = Data.replace(' 00:00:00', '');
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-US');
  }

  getAllNullProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (this.comboValues[val.name]) {
        // console.log(this.comboValues[val.name]);
        if (this.comboValues[val.name].length === 1) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              results.dataSource.forEach(res => {
                this.selectToAdd = '';
                this.keyToAdd = '';
                res.columns.forEach(r => {
                  if (results.selectField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.selectToAdd += r.value + ' - ';
                  }
                  if (results.keyField.toLowerCase().includes(r.id.toLowerCase())) {
                    this.keyToAdd += r.value + ' - ';
                  }
                });
                this.tempCombo.push({
                  selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                  keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                  visible: true
                });
                this.comboValues[val.name] = this.tempCombo;
                if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]) {
                  this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                  this.checkComboAssociations(val);
                }
              });
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                // console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    /*if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }*/
                    if ((item.name === val.name) && (item.limitToList === true)) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      } else {
        if (!val.value && !this.comboControl[val.name]) {
          if (val.className === 'AdditionalFieldComboDTO') {
            this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
              // console.log(results);
              this.tempCombo = new Array<any>();
              if (results.dataSource) {
                results.dataSource.forEach(res => {
                  this.selectToAdd = '';
                  this.keyToAdd = '';
                  res.columns.forEach(r => {
                    if (results.selectField.includes(r.id)) {
                      this.selectToAdd += r.value + ' - ';
                    }
                    if (results.keyField.includes(r.id)) {
                      this.keyToAdd += r.value + ' - ';
                    }
                  });
                  this.tempCombo.push({
                    selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                    keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                    visible: true
                  });
                  this.comboValues[val.name] = this.tempCombo;
                  if (results.dataSource.length === 1 && !this.dataMaskToSave[val.name]) {
                    this.dataMaskToSave[val.name] = this.keyToAdd.substr(0, this.keyToAdd.length - 3);
                    this.checkComboAssociations(val);
                  }
                });
              }
            });
          } else {
            if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
              this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                  .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                // console.log(this.tableAssociationField);
                if (results.dataSource.length === 0) {
                  this.fields.forEach((item, subindex) => {
                    if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                    if ((item.name === val.name) && (item.limitToList === true)) {
                      this.fields[subindex].value = '';
                      this.dataMaskToSave[this.fields[subindex].name] = '';
                    }
                  });
                }
              });
            }
          }
        }
      }

    });
  }

  getAllProfileValue(): void {
    this.fields.forEach((val, index) => {
      if (val.className === 'AdditionalFieldComboDTO' && (this.tempSkipProfileValue !== val.name || !this.tempSkipProfileValue)) {
        this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
          this.tempSkipProfileValue = null;
          // // console.log(results);
          this.tempCombo = new Array<any>();
          if (results.dataSource) {
            results.dataSource.forEach(res => {
              this.selectToAdd = '';
              this.keyToAdd = '';
              res.columns.forEach(r => {
                if (results.selectField.includes(r.id)) {
                  this.selectToAdd += r.value + ' - ';
                }
                if (results.keyField.includes(r.id)) {
                  this.keyToAdd += r.value + ' - ';
                }
              });
              this.tempCombo.push({
                selectField: this.selectToAdd.substr(0, this.selectToAdd.length - 3),
                keyField: this.keyToAdd.substr(0, this.keyToAdd.length - 3),
                visible: true
              });
              this.comboValues[val.name] = this.tempCombo;
            });
          }
        });
      } else {
        if (val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
          this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
            this.tableAssociationField = {};
            this.tableAssociationField = Object.keys(results.associations)
              .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
            // console.log(this.tableAssociationField);
            if (results.dataSource.length === 0) {
              this.fields.forEach((item, subindex) => {
                if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase()) && (item.limitToList === true)) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
                if ((item.name === val.name) && (item.limitToList === true)) {
                  this.fields[subindex].value = '';
                  this.dataMaskToSave[this.fields[subindex].name] = '';
                }
              });
            }
          });
        }
      }

    });
    this.checkFormula();

  }

  getJsonFields(): any {
    if (this.bufferIdInfo.length > 0) {
      this.jsonToSendArxivar = {
        document: {
          fileNames: [],
          bufferIds: []
        },
        fields: []
      };
      this.bufferIdInfo.forEach(file => {
        this.jsonToSendArxivar.document.fileNames.push(file.name);
        this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
      });
    } else {
      this.jsonToSendArxivar = {
        fields: []
      };
    }
    // // // // // console.log(this.dataMaskToSave);
    this.fields.forEach((field, index) => {
      if (field.visible || this.dataMaskToSave[field.name]) {
        // // // // console.log(this.dataMaskToSave);
        if (field.className === 'AdditionalFieldDateTimeDTO') {
          this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
        } else if (field.className === 'AdditionalFieldDoubleDTO') {
          if (this.dataMaskToSave[field.name]) {
            this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
          } else {
            this.fields[index].value = this.dataMaskToSave[field.name];
          }
        } else {
          this.fields[index].value = this.dataMaskToSave[field.name];
        }


        // // // // // console.log(this.dataMaskToSave[field.name]);

        // // // // // console.log(field);
      }
      this.jsonToSendArxivar.fields.push(this.fields[index]);
    });
    return this.jsonToSendArxivar;
  }

  goBack(): void {
    /*
        this.apiService.namePathV2.forEach((n, index) => {
           if (n.active){

           }
        });
    */
    if (this.idTask !== '0') {
      this.router.navigate(['../dettaglitask', {id: this.idTask, path: this.firstnodepath}], {relativeTo: this.route});
    } else {
      if (this.savedProfileId) {
        this.router.navigate(['../profile', {
          idDocument: this.savedProfileId,
          taskId: '0',
          firstnodepath: this.firstnodepath
        }], {relativeTo: this.route});
      } else {
        this.router.navigate(['../' + this.path + ''], {relativeTo: this.route});
      }

    }
  }
}
