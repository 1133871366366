<div style="width: 100%">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; display: flex">
    <h2 style="text-align: center; width: 100%;">Rinomina il nome del fascicolo</h2>
</div>
<div style="text-align: center">
    <mat-form-field>
        <input [(ngModel)]="this.name" type="text" required matInput name="value"
               placeholder="Nome fascicolo">
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click) = "this.send()">Invia</button>
</div>

