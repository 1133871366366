<style>
  .material-symbols-outlined {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
</style>
<div class="center" style="display: inline-flex">
  <mat-card class="cardcomponentMasks" id="style-4" style="overflow: unset; height: auto; text-align: left;
     overflow-y: unset">


    <h3 class="titles">
      <img width="75px" [src]="'assets/img/hearst-logo-585x761-nero.png'"/>

    </h3>
    <hr class="riga" style="color: {{this.apiService.dashColor}}">
    <h3 class="titles" style="color:{{this.apiService.dashColor}};">

      <mat-card-title>
        <p>Pianificazione presenze anno:
          <mat-select style="width: unset !important;" [(ngModel)]="this.year" (selectionChange)="this.ngOnInit()">
            <mat-option [value]="year" *ngFor="let year of this.years">{{ year }}</mat-option>
          </mat-select>
          mese di:
          <mat-select style="width: unset !important;" [(value)]="this.selectedMonth"
                      (selectionChange)="this.ngOnInit()">
            <mat-option [value]="mese.value" *ngFor="let mese of this.months">{{ mese.viewValue }}</mat-option>
          </mat-select>
          <br><br>
          Utente:
          <button mat-stroked-button
                  style="vertical-align: middle; margin-bottom: 4px; font-size: x-large; color: black"
                  [matMenuTriggerFor]="menu">{{this.currentUserName}}
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openChangePasswordDialog()" style="height: 63px !important;">
              <mat-icon>vpn_key</mat-icon>
              <span>Cambia password</span>
            </button>

          </mat-menu>
          <mat-icon (click)="ClickLogOut()"
                    style="color: black; cursor: pointer;margin-right: 10px; margin-left: 10px; margin-bottom: 6px; vertical-align: middle;">
            exit_to_app
          </mat-icon>
        </p>

      </mat-card-title>

    </h3>
    <div style="margin-bottom: 1% !important">
      <button mat-fab title="Reset filtri" (click)="this.resetFilters()" style="margin-right: 1%">
        <mat-icon>filter_alt_off</mat-icon>
      </button>
      <button mat-fab title="Esporta CSV" (click)="this.exportCSV()"
              style="margin-right: 1%; background-color: forestgreen">
        <mat-icon>summarize</mat-icon>
      </button>
      <button mat-fab title="Filtro rapido alla propria riga" (click)="this.rapidFilter()"
              style="background-color: #89CFF0" *ngIf="this.activeBoltFilter == false">
        <mat-icon>bolt</mat-icon>
      </button>
      <button mat-fab title="Filtro rapido alla propria riga" (click)="this.rapidFilter()"
              style="background-color: #89CFF0" *ngIf="this.activeBoltFilter == true">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>


    <mat-card-content><!--hai cliccato la maschera con id: {{this.id}}-->
      <div style="heigth: 5%; width: 100%; display: flex; margin-bottom: 1%">
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #de7565">
            radio_button_unchecked
          </mat-icon>
          <span
            style="width: fit-content;    margin: auto;"><b>Giornata intera in presenza ({{this.countGiornataPresenza}})</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #f4ccac">
            radio_button_unchecked
          </mat-icon>
          <span style="width: fit-content;    margin: auto;"><b>Mattino in presenza ({{this.countGiornataMattino}})</b></span>
        </div>
        <!--<div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #6a6a6a">
            radio_button_unchecked
          </mat-icon>

          <span style="width: fit-content;    margin: auto;"><b>Off- Ferie, Malattia, Permesso ({{this.countGiornataOff}})</b></span>
        </div>-->
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #c2cf9d">
            radio_button_unchecked
          </mat-icon>

          <span style="width: fit-content;    margin: auto;"><b>Pomeriggio in presenza ({{this.countGiornataPomeriggio}})</b></span>
        </div>
        <div style="display: flex; margin-right: 3%;">
          <mat-icon class="material-symbols-outlined" style="color: #b8c1df">
            radio_button_unchecked
          </mat-icon>

          <span style="width: fit-content;    margin: auto; "><b>Smart Working ({{this.countGiornataSmart}})</b></span>
        </div>

      </div>
      <hr class="riga" style="color:{{this.apiService.dashColor}};">

      <!-- <div style="width: 20%">

         <mat-form-field appearance="fill" style=" float: left; margin-right: 5%">
           <mat-label>ZONA</mat-label>
           <mat-select [(value)]="this.zoneGroup" (selectionChange)="this.ngOnInit()">
             <mat-option [value]="gruppo" *ngFor="let gruppo of this.groupsZone">{{ gruppo }}</mat-option>
           </mat-select>
         </mat-form-field>
       </div>
 -->
      <mat-form-field appearance="standard" style="width: 25%">
        <mat-label>Filtra</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="this.filter" (keyup)="applyFilter($event)"
               placeholder="Inserire filtro...">
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 10%; margin-left: 5%">
        <mat-label>CLUSTER</mat-label>
        <mat-select [(value)]="this.clusterGroup" (selectionChange)="this.ngOnInit()">
          <mat-option [value]="gruppo" *ngFor="let gruppo of this.groupsCluster">{{ gruppo }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 10%; margin-left: 5%">
        <mat-label>DEPARTMENT</mat-label>
        <mat-select [(value)]="this.departmentGroup" (selectionChange)="this.ngOnInit()">
          <mat-option [value]="gruppo" *ngFor="let gruppo of this.groupsDepartment">{{ gruppo }}</mat-option>
        </mat-select>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" tabindex="0" matSort>

        <!-- Position Column -->
        <ng-container *ngFor="let colonna of displayedColumns" matColumnDef="{{colonna}}"
                      [sticky]="colonna == 'Risorsa'">
          <ng-container *ngIf="colonna != 'Risorsa' && colonna != 'Cluster' && colonna != 'Zona'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"
                [ngClass]="{'warning' : this.getCountPresenze(colonna) == this.zoneMap.get(this.clusterGroup), 'danger' : this.getCountPresenze(colonna) > this.zoneMap.get(this.clusterGroup)}"
                style="text-align: center; padding-right: 0px !important;">{{this.getDayOfWeek(colonna)}}
              <br>{{colonna}}
            </th>
          </ng-container>
          <ng-container *ngIf="colonna == 'Risorsa'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">
              <ng-container *ngIf="this.clusterGroup == 'TUTTI'">{{colonna}}</ng-container>
              <ng-container *ngIf="this.clusterGroup != 'TUTTI'">{{this.clusterGroup}}</ng-container>
            </th>
          </ng-container>
          <ng-container *ngIf="colonna == 'Cluster' || colonna == 'Zona'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">{{colonna}}</th>
          </ng-container>
          <ng-container *ngIf="colonna != 'Risorsa' && colonna != 'Cluster' && colonna != 'Zona'">
            <td mat-cell *matCellDef="let element"
                style="text-align: left; border-right: 1px solid black; border-left: 1px solid black; padding-right: 0px !important; background-color: rgba(136,139,201,0.58)"
                [ngClass]="{'disabled' : this.isCurrentUser(element['Username']) == false || this.selectedDateIsLessThanToday(colonna) == true, 'currentDisabled': this.isCurrentUser(element['Username']) == true && this.selectedDateIsLessThanToday(colonna) == true, 'week': this.getDayOfWeek(colonna) == 'Sabato' || this.getDayOfWeek(colonna) == 'Domenica'}">
              <button mat-icon-button [matMenuTriggerFor]="menu" style="width: 75px; display: block"
                      title="{{element[colonna]}}"
                      [disabled]="!this.isCurrentUser(element['Username']) || this.selectedDateIsLessThanToday(colonna) == true"
                      *ngIf="colonna <= this.getMaxDaysInMonth()">
                <div class="CircleClass" style="margin: auto;"
                     [ngClass]="{'noSet' : element[colonna] == '', 'presenzaFull' : element[colonna] == 'Giornata intera in presenza', 'presenzaMattino' : element[colonna] == 'Mattino in presenza', 'presenzaPomeriggio' : element[colonna] == 'Pomeriggio in presenza', 'smartWorking' : element[colonna] == 'Smart Working', 'offWorking' : element[colonna] == 'Off- Ferie, Malattia, Permesso'}">
                  {{element[colonna].slice(0, 1)}}
                </div>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container>
                  <div style="width: 100%;text-align: center; margin-top: 5%; margin-bottom: 5%">
                    <b>{{element['Username']}} - {{this.getDateFromDay(colonna)}}</b></div>
                  <hr style="margin: 0px">
                  <button mat-menu-item style="margin-top: 5%; margin-bottom: 5%"
                          (click)="addEvent('Giornata intera in presenza', colonna, element['Username'])">Giornata
                    intera
                    in presenza
                  </button>
                  <button mat-menu-item style="margin-top: 5%; margin-bottom: 5%"
                          (click)="addEvent('Mattino in presenza', colonna, element['Username'])">Mattino in presenza
                  </button>
                  <!--<button mat-menu-item style="margin-top: 5%; margin-bottom: 5%"
                          (click)="addEvent('Off- Ferie, Malattia, Permesso', colonna, element['Username'])">Off- Ferie,
                    Malattia, Permesso
                  </button>-->
                  <button mat-menu-item style="margin-top: 5%; margin-bottom: 5%"
                          (click)="addEvent('Pomeriggio in presenza', colonna, element['Username'])">Pomeriggio in
                    presenza
                  </button>
                  <button mat-menu-item style="margin-top: 5%; margin-bottom: 5%"
                          (click)="addEvent('Smart Working', colonna, element['Username'])">Smart Working
                  </button>
                  <hr style="margin: 0px">
                  <button mat-menu-item *ngIf="element[colonna] != ''"
                          (click)="removeEvent(colonna, element['Username'])"><span style="color: red">Rimuovi</span>
                  </button>
                </ng-container>
                <ng-container *ngIf="!this.isCurrentUser(element['Username'])">
                  <div style="height: fit-content"><b style="color: red">Non puoi modificare la presenza
                    selezionata</b></div>
                </ng-container>
              </mat-menu>
            </td>
            <td mat-footer-cell *matFooterCellDef style="text-align: center"
                [ngClass]="{'warning' : this.getCountPresenze(colonna) == this.zoneMap.get(this.clusterGroup), 'danger' : this.getCountPresenze(colonna) > this.zoneMap.get(this.clusterGroup)}">{{this.getCountPresenze(colonna)}}</td>
          </ng-container>
          <ng-container *ngIf="colonna == 'Risorsa'">
            <td mat-cell *matCellDef="let element" style="text-align: left; padding-right: 30px !important;">
              <div style="width: max-content;">
                {{element[colonna].split('^')[0]}}<b><h2 style="margin: 0px">{{element[colonna].split('^')[1]}}</h2></b>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Numero presenze
              <ng-container *ngIf="this.clusterGroup !== 'TUTTI'"><br>(Max {{this.zoneMap.get(this.clusterGroup)}})
              </ng-container>
              :</b></td>
          </ng-container>
          <ng-container *ngIf="colonna == 'Cluster' || colonna == 'Zona'">
            <td mat-cell *matCellDef="let element" style="text-align: left; padding-right: 30px !important;">
              <div style="width: max-content;"><h4 style="margin: auto;">{{element[colonna]}}</h4></div>
            </td>
          </ng-container>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
            style="    height: 26px !important;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Nessun risultato trovato con il filtro specificato</td>
        </tr>
      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[15, 25, 50, 100]"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
