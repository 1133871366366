import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BodyFieldToSave} from '../../bodyFieldToSave';
import {DatePipe, DOCUMENT} from '@angular/common';
import {DialogMultiDTOComponent} from '../../additionalDialog/dialogMultiDTOComponent';
import {DialogTableDTOComponent} from '../../additionalDialog/dialogTableDTOComponent';
import {DialogAddressBookComponent} from '../../additionalDialog/dialogAddressBookComponent';
import {DialogComboDTOComponent} from '../../additionalDialog/dialogComboDTOComponents';
import {DialogMaskComponent} from '../../additionalDialog/dialogMaskComponents';
import {DialogClassViewDTOComponentDTOComponent} from '../../additionalDialog/dialogClassViewDTOComponent';
import {DialogSearchDocComponent} from '../../additionalDialog/dialogSearchDocComponents';
import * as fileSaver from 'file-saver';
import {RenameCategoryDialogComponent} from '../rubrica/rubrica.component';
import {DialogDocumentalClassComponent} from '../../additionalDialog/dialogDocumentalClass';
import {DialogAddressBookV2Component} from '../../additionalDialog/dialog-address-book-v2/dialog-address-book-v2.component';


export interface SelectedRowsTableDTO {
    selected: [];
}

export interface SelectedRowsMultiDTO {
    selected: [];
}

@Component({
    selector: 'app-model-detail',
    templateUrl: './model-detail.component.html',
    styleUrls: ['./model-detail.component.css']
})
export class ModelDetailComponent implements OnInit {
    idModel = this.activatedRoute.snapshot.paramMap.get('idModel');
    id = this.activatedRoute.snapshot.paramMap.get('maskId');
    idTask = this.activatedRoute.snapshot.paramMap.get('idTask');
    operationId = this.activatedRoute.snapshot.paramMap.get('operationId');
    path = this.activatedRoute.snapshot.paramMap.get('path');
    firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
    dashColor: string;
    backColor: string;
    squareColor: string;
    openModelAfterProfilation = false;
    cardColor: string;
    maskName: string;
    splitMaskName: string;
    splitMaskOrder: string;
    splitMaskCat: string;
    classProfileType: string;
    classProfileName: string;
    classProfileValue: string;
    fields: Array<any> = new Array<any>();
    nomiAoo: Array<any> = new Array<any>();
    originsFields: Array<any> = new Array<any>();
    documenTypes: Array<any> = new Array<any>();
    stateFields: Array<any> = new Array<any>();
    toFields: Array<any> = new Array<any>();
    FromFields: Array<any> = new Array<any>();
    Row: [];
    breakpoint: number;
    fieldDataSource: Array<any> = [];
    multiValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
    tableValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
    comboValuteDTOSelected: Map<string, string[]> = new Map<string, string[]>();
    addressBookSelected: Map<string, string[]> = new Map<string, string[]>();
    addressBookSelectedToShow = '';
    fieldName = '';
    keyField = '';
    docIdToDownload: string;
    mapMultiValueToSave: Map<string, object[]> = new Map<string, object[]>();
    mapTableValueToSave: any;
    tableAssociationField: object = {};
    comboAssociationField: any = {};
    mapTableValueToSee: Map<string, string> = new Map<string, string>();
    mapAddressBookToSee: Map<string, string> = new Map<string, string>();
    mapClasseBookToSave: Map<string, Array<string>> = new Map<string, Array<string>>();
    // CLASSE BOX
    ELEMENT_DATA_CLASS: Array<object> = new Array<object>();
    arrayToObjectClass: { [index: string]: { content: string } } = {};
    dataSourceClass: MatTableDataSource<object>;
    displayedColumnsClass: Array<string> = new Array<string>();
    docNumberClasseboxTemp: Array<string> = new Array<string>();
    optionsMask: any = {};
    dataMaskToSave: any = {};
    dataValueAssociations: any = {};
    validationCheck: boolean;
    jsonToSendArxivar: any = {};
    modelName: string;
    fileListMain: FileList;
    fileMain: File;
    twDocumentOperationId: string;
    formDataMain: FormData;
    bufferIdInfo: Array<any> = new Array<any>();
    fileInfo: any = {name: '', buffer: ''};
    badgeBufferMain: number;
    arrayToObject: { [index: string]: { content: string } } = {};
    idContact: string;
    mapAddressBookSave: Array<any> = new Array<any>();
    fieldsNoAdditional: Array<any> = new Array<any>();
    additionalFields: Array<any> = new Array<any>();
    whatToShow = 'default';
    notes: Array<any> = new Array<any>();
    addNoteText = '';
    // chip list
    mapMultiValueToSee: Map<string, any> = new Map<string, any>();
    multiValuteDTOSelectedToShow: Array<any> = new Array<any>();
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;

    // tslint:disable-next-line:max-line-length variable-name
    constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
                sanitizer: DomSanitizer, public apiService: ApiService,
                private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar,
                private bodyFieldToSave: BodyFieldToSave, private datePipe: DatePipe) {
        iconRegistry.addSvgIcon(
            'BackIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/img/keyboard_backspace-24px.svg'));
    }

    ngOnInit(): void {
        this.badgeBufferMain = this.bufferIdInfo.length;
        this.dashColor = this.apiService.dashColor;
        this.backColor = this.apiService.backColor;
        this.squareColor = this.apiService.squareColor;
        this.cardColor = this.apiService.cardColor;
        this.breakpoint = (window.innerWidth <= 600) ? 4 : 6;
        this.apiService.getAoo().subscribe(data => {
            this.nomiAoo = data;
        });
        this.apiService.getOrigins().subscribe(data => {
            this.originsFields = data;
        });

        this.apiService.getStates().subscribe(data => {
            this.stateFields = data;
        });
        if (this.idTask === '0') {
            this.apiService.getModelProfileSchema(this.idModel).subscribe(data => {
                // console.log(data);
                this.openModelAfterProfilation = data.openModelAfterProfilation;
                this.id = data.maskId;
                this.apiService.getDocumentTypeMode().subscribe(datas => {
                    // console.log(datas);
                    datas.forEach(v => {
                        if (v.isLeaf) {
                            this.documenTypes.push(v);
                        }
                    });
                });
                this.modelName = data.modelDescription;
                this.apiService.addPath(this.modelName, 'dashboard/dashnav/modeldetail', {
                    idModel: this.idModel, maskId: this.id,
                    idTask: this.idTask,
                    operationId: this.operationId, path: this.path, firstnodepath: this.firstnodepath
                });
                this.apiService.namePath += ' - ' + this.modelName;
                this.optionsMask = data.options;
                this.maskName = data.maskName;
                data.fields = data.fields.sort((a, b) => {
                    return a.order - b.order;
                });
                this.fields = data.fields;
                data.fields.forEach(findClass => {
                    if (findClass.visible) {
                        this.dataMaskToSave[findClass.name] = findClass.value;
                        this.dataValueAssociations[findClass.name] = findClass.associations;
                    }
                    if (findClass.className === 'AdditionalFieldClasseDTO') {
                        this.mapClasseBookToSave.set(findClass.name, findClass.value);
                        findClass.columns.forEach(column => {
                            this.displayedColumnsClass.push(column.label);
                        });
                        this.displayedColumnsClass.push('Azioni');
                        this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
                    }
                    if (findClass.className === 'DocumentTypeFieldDTO') {
                        this.classProfileType = findClass.className;
                        this.classProfileName = findClass.name;
                        this.classProfileValue = findClass.value;
                    }
                    if (findClass.className === 'FromFieldDTO' || findClass.className === 'ToFieldDTO') {
                        if (findClass.value != null) {
                            let selected = [];

                            if (findClass.value instanceof Array) {
                                findClass.value.forEach(item => {
                                    this.addressBookSelectedToShow += item.description + ';\n';
                                    selected.push(item.description);
                                });
                            } else {
                                this.addressBookSelectedToShow += findClass.value.description + ';\n';
                                selected.push(findClass.value.description);
                            }

                            // console.log(this.addressBookSelectedToShow);

                            this.addressBookSelected.set(findClass.name, selected);
                            // console.log(this.addressBookSelected);
                            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
                            // console.log(this.mapAddressBookToSee);
                        } else {
                            this.mapAddressBookToSee.set(findClass.name, '');
                        }
                    }
                });
                this.checkFormula();
            });
        } else {
            this.apiService.getModelProfileSchemaForDocument(this.idTask, this.operationId).subscribe(data => {
                // console.log(data);
                this.openModelAfterProfilation = data.openModelAfterProfilation;
                this.id = data.maskId;
                this.apiService.getDocumentTypeMode().subscribe(datas => {
                    // console.log(datas);
                    datas.forEach(v => {
                        if (v.isLeaf) {
                            this.documenTypes.push(v);
                        }
                    });
                });
                this.modelName = data.modelDescription;
                this.optionsMask = data.options;
                this.maskName = data.maskName;
                data.fields = data.fields.sort((a, b) => {
                    return a.order - b.order;
                });
                this.fields = data.fields;
                data.fields.forEach(findClass => {
                    if (findClass.visible) {
                        this.dataMaskToSave[findClass.name] = findClass.value;
                        this.dataValueAssociations[findClass.name] = findClass.associations;
                    }
                    if (findClass.className === 'AdditionalFieldClasseDTO') {
                        this.mapClasseBookToSave.set(findClass.name, findClass.value);
                        findClass.columns.forEach(column => {
                            this.displayedColumnsClass.push(column.label);
                        });
                        this.displayedColumnsClass.push('Azioni');
                        this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
                    }
                    if (findClass.className === 'DocumentTypeFieldDTO') {
                        this.classProfileType = findClass.className;
                        this.classProfileName = findClass.name;
                        this.classProfileValue = findClass.value;
                    }
                    if (findClass.className === 'FromFieldDTO' || findClass.className === 'ToFieldDTO') {
                        if (findClass.value != null) {
                            let selected = [];
                            if (findClass.value instanceof Array) {
                                findClass.value.forEach(item => {
                                    this.addressBookSelectedToShow += item.description + ';\n';
                                    selected.push(item.description);
                                });
                            } else {
                                this.addressBookSelectedToShow += findClass.value.description + ';\n';
                                selected.push(findClass.value.description);
                            }
                            this.addressBookSelected.set(findClass.name, selected);
                            this.mapAddressBookToSee.set(findClass.name, this.addressBookSelectedToShow);
                        } else {
                            this.mapAddressBookToSee.set(findClass.name, '');
                        }
                    }
                });
                this.checkFormula();
            });
        }
    }

    showNotes(): void {
        this.whatToShow = 'note';
    }

    showDesk(): void {
        this.whatToShow = 'default';
    }

    remove(v: string, fieldName: string): void {
        this.multiValuteDTOSelectedToShow = new Array<any>();
        this.dataMaskToSave[fieldName].forEach((value, index) => {
            if (value === v) {
                this.dataMaskToSave[fieldName].splice(index, 1);
            }
        });
        this.dataMaskToSave[fieldName].forEach((value) => {
            this.multiValuteDTOSelectedToShow.push(value);
        });
        this.mapMultiValueToSee.set(fieldName, this.multiValuteDTOSelectedToShow);
    }

    openMultiValueDTO(field: any): void {
        if (!field.locked) {
            this.fieldName = field.name;
            this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
                // console.log(results);
                this.fieldDataSource = new Array<object>();
                if (results.dataSource != null) {
                    results.dataSource.forEach(item => {
                        this.fieldDataSource.push(item.columns);
                    });
                } else {
                    // EMPTY MULTI VALUE
                }
                const dialogRef = this.dialog.open(DialogMultiDTOComponent, {
                    id: 'style-4',
                    width: '40%',
                    height: '60%',
                    data: {
                        additionalFieldMultiDTO: this.fieldDataSource,
                        fieldName: field.name,
                        multiValueToSelected: this.multiValuteDTOSelected,
                        selectField: results.selectField
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result.dataResult === 'delete') {
                        this.multiValuteDTOSelectedToShow = new Array<any>();
                        this.dataMaskToSave[this.fieldName] = '';
                        this.mapMultiValueToSee.set(this.fieldName, '');
                        this.multiValuteDTOSelected.set(this.fieldName, []);
                    }
                    this.multiValuteDTOSelectedToShow = new Array<any>();
                    if (result.dataResult !== false) {
                        result.dataResult.forEach(item => {
                            this.multiValuteDTOSelectedToShow.push(item);
                        });
                        this.dataMaskToSave[this.fieldName] = result.dataResult;
                        this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
                        this.multiValuteDTOSelected.set(this.fieldName, result.dataResult); // non serve probabilmente
                        this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
                    }
                    this.getAllProfileValue();
                    this.checkFormula();
                });
            });
        }
    }

    openTableDTO(field: any): void {
        if (!field.locked) {
            this.fieldName = field.name;
            this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
                this.tableAssociationField = {};
                this.tableAssociationField = Object.keys(results.associations)
                    .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                this.fieldDataSource = new Array<object>();
                if (results.dataSource != null) {
                    this.keyField = results.keyField;
                    results.dataSource.forEach(item => {
                        this.fieldDataSource.push(item.columns);
                    });
                } else {
                    // EMPTY MULTI VALUE
                }
                const dialogRef = this.dialog.open(DialogTableDTOComponent, {
                    id: 'style-4',
                    width: '80%',
                    height: '95%',
                    data: {
                        additionalFieldTableDTO: this.fieldDataSource,
                        fieldName: field.name,
                        tableValueToSelected: this.tableValuteDTOSelected,
                        selectField: results.selectField
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result.dataResult === 'delete') {
                        this.fields.forEach((item, index) => {
                            if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                                this.fields[index].value = '';
                                this.dataMaskToSave[this.fields[index].name] = '';
                            }
                            if (item.name === this.fieldName) {
                                this.fields[index].value = '';
                                this.dataMaskToSave[this.fields[index].name] = '';
                            }
                        });
                    }
                    if (result.dataResult !== false) {
                        this.mapTableValueToSave = result.dataResult;
                        this.fields.forEach((item, index) => {
                            if (this.tableAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                                this.fields[index].value = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                            }
                            if (item.name === this.fieldName) {
                                this.fields[index].value = result.dataResult[this.keyField];
                                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
                            }
                        });
                    }
                    this.getAllProfileValue();
                });
            });
        }
    }

    documentChanged(event: any, tipo1: string, tipo2: string, tipo3: string): void {
        if (event.isUserInput) {
            this.apiService.additionalProfiles(tipo1, tipo2, tipo3, this.fields[0].value).subscribe(data => {
                this.fieldsNoAdditional = new Array<any>();
                this.fields.forEach(v => {
                    if (v.isAdditional === false) {
                        this.fieldsNoAdditional.push(v);
                    }
                });
                this.additionalFields = data;
                this.additionalFields.forEach(v => {
                    this.fieldsNoAdditional.push(v);
                });
                this.fields = this.fieldsNoAdditional;
            });
        }
    }

    openAddressBook(field: any): void {
        if (!field.locked) {
            this.fieldName = field.name;
            this.apiService.SearchAllAddressBook().subscribe(results => {
                this.apiService.viewDataToShow = results;
                const dialogRef = this.dialog.open(DialogAddressBookV2Component, {
                    id: 'style-4',
                    width: '80%',
                    height: '95%',
                    data: {
                        fieldName: field.name,
                        addressBookSelected: this.addressBookSelected,
                        selectField: 'DM_RUBRICA_RAGIONE_SOCIALE'
                    }
                });


                dialogRef.afterClosed().subscribe(result => {
                    this.mapAddressBookSave = new Array<any>();
                    let fromFieldValue = new Object();
                    this.addressBookSelectedToShow = '';
                    // this.addressBookSelected = new Map<string, string[]>();
                    let type: string;
                    switch (this.fieldName) {
                        case 'To':
                            type = '0';
                            break;
                        case 'From':
                            type = '1';
                            break;
                        case 'Cc':
                            type = '2';
                            break;
                        case 'Senders':
                            type = '3';
                            break;
                    }
                    result.dataResult.forEach(val => {

                        this.idContact = val;
                        this.apiService.getContactAddressBook(this.idContact, type).subscribe(res => {
                            // // console.log(res);
                            if (type === '0') {
                                this.mapAddressBookSave.push(res);
                            } else {
                                fromFieldValue = res;
                                this.dataMaskToSave[this.fieldName] = fromFieldValue;
                            }

                        });
                    });
                    if (result.dataResult !== false) {
                        result.contactsNames.forEach(item => {
                            this.addressBookSelectedToShow += item + ';\n';
                        });
                        if (type === '0') {
                            this.dataMaskToSave[this.fieldName] = this.mapAddressBookSave;
                        } else {
                            this.dataMaskToSave[this.fieldName] = fromFieldValue;
                        }
                        this.addressBookSelected.set(this.fieldName, result.dataResult);
                        this.mapAddressBookToSee.set(this.fieldName, this.addressBookSelectedToShow);
                    }
                    this.getAllProfileValue();
                    this.checkFormula();
                    /*this.multiValuteDTOSelectedToShow = new Array<any>();
                    if (result.dataResult !== false) {
                        result.dataResult.forEach(item => {
                            this.multiValuteDTOSelectedToShow.push(item);
                        });
                        this.dataMaskToSave[this.fieldName] = result.dataResult;
                        this.mapMultiValueToSave.set(this.fieldName, result.dataToSave); // non serve probabilmente
                        this.multiValuteDTOSelected.set(this.fieldName, result.dataResult);
                        this.mapMultiValueToSee.set(this.fieldName, this.multiValuteDTOSelectedToShow);
                    }*/
                    this.getAllProfileValue();
                    this.checkFormula();
                });
            });
        }
    }


    openComboValueDTO(field: any): void {
        if (!field.locked) {
            this.fieldName = field.name;
            this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
                this.comboAssociationField = {};
                this.comboAssociationField = Object.keys(results.associations)
                    .reduce((c, k) => (c[k.toLowerCase()] = results.associations[k], c), {});
                this.fieldDataSource = new Array<object>();
                if (results.dataSource != null) {
                    this.keyField = results.keyField;
                    results.dataSource.forEach(item => {
                        // console.log(item);
                        this.fieldDataSource.push(item.columns[0]);
                    });
                } else {
                    // EMPTY MULTI VALUE
                }
                const dialogRef = this.dialog.open(DialogComboDTOComponent, {
                    id: 'style-4',
                    width: '80%',
                    height: '95%',
                    data: {
                        additionalFieldComboDTO: this.fieldDataSource,
                        fieldName: field.name,
                        comboValueToSelected: this.comboValuteDTOSelected,
                        selectField: results.selectField
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result.dataResult === 'delete') {
                        this.fields.forEach((item, index) => {
                            if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                                this.fields[index].value = '';
                                this.dataMaskToSave[this.fields[index].name] = '';
                            }
                            if (item.name === this.fieldName) {
                                this.fields[index].value = '';
                                this.dataMaskToSave[this.fields[index].name] = '';
                            }
                        });
                    }
                    if (result.dataResult !== false) {
                        this.fields.forEach((item, index) => {
                            if (this.comboAssociationField.hasOwnProperty(item.name.toLowerCase())) {
                                this.fields[index].value = result.dataResult[this.comboAssociationField[item.name.toLowerCase()]];
                                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.tableAssociationField[item.name.toLowerCase()]];
                            }
                            if (item.name === this.fieldName) {
                                this.fields[index].value = result.dataResult[this.keyField];
                                this.dataMaskToSave[this.fields[index].name] = result.dataResult[this.keyField];
                            }
                        });
                    }
                    this.getAllProfileValue();
                });
            });
        }
    }

    insertMaskFromClassDTO(field: any): void {
        if (!field.locked) {
            if (this.bufferIdInfo.length > 0) {
                this.jsonToSendArxivar = {
                    document: {
                        fileNames: [],
                        bufferIds: []
                    },
                    fields: [],
                    notes: this.notes
                };
                this.bufferIdInfo.forEach(file => {
                    this.jsonToSendArxivar.document.fileNames.push(file.name);
                    this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
                });
            } else {
                this.jsonToSendArxivar = {
                    fields: [],
                    notes: this.notes
                };
            }
            this.fields.forEach((field1, index) => {
                if (field1.visible) {
                    // // // console.log(this.dataMaskToSave);
                  if (field1.className === 'AdditionalFieldDateTimeDTO') {
                    this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field1.name]);
                  } else if (field1.className === 'AdditionalFieldDoubleDTO') {
                    if (this.dataMaskToSave[field1.name]) {
                      this.fields[index].value = (this.dataMaskToSave[field1.name].toString().replace(',', '.'));
                    } else {
                      this.fields[index].value = this.dataMaskToSave[field1.name];
                    }
                  } else {
                    this.fields[index].value = this.dataMaskToSave[field1.name];
                  }
                }
                this.jsonToSendArxivar.fields.push(this.fields[index]);
            });
            this.fieldName = field.name;
            const dialogRef = this.dialog.open(DialogMaskComponent, {
                width: '80%',
                data: {
                    json: this.jsonToSendArxivar,
                    id: field.insertMaskId,
                    fieldName: field.name,
                    dataMask: this.dataMaskToSave,
                    dataAssociations: this.dataValueAssociations
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                // // // console.log(result);
                if (result.hasOwnProperty('docNumber')) {
                    this.docNumberClasseboxTemp = new Array<string>();
                    this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldClassName);
                    this.docNumberClasseboxTemp.push(result.docNumber);
                    this.mapClasseBookToSave.set(result.fieldClassName, this.docNumberClasseboxTemp);
                    this.arrayToObject = {};
                    this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
                        callResults.fields.forEach(fieldClass => {
                            this.displayedColumnsClass.forEach(colum => {
                                if (colum === fieldClass.description) {
                                    this.arrayToObject[colum] = fieldClass.value;
                                }
                            });
                        });
                        this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
                        this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
                        // // // console.log(this.ELEMENT_DATA_CLASS);
                    });
                }
            });
        }
    }

    openClassMasks(field: any): void {
        if (!field.locked) {
            this.fieldName = field.name;
            const dialogRef = this.dialog.open(DialogClassViewDTOComponentDTOComponent, {
                width: '80%',
                height: 'fit-content',
                data: {
                    classField: field,
                    mapClassValue: this.mapClasseBookToSave.get(field.name)
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                // // console.log(this.mapClasseBookToSave.get(result.fieldName));
            });
        }
    }

  checkComboAssociations(field: any): void {
    let comboResult: any = {};
    let selectedElement;
    this.fieldName = field.name;
    this.apiService.getProfileValuesWithFilter(field.name, this.getJsonFields().fields).subscribe(results => {
      comboResult = results;
      this.comboAssociationField = {};
      this.comboAssociationField = Object.keys(results.associations);
      this.fieldDataSource = new Array<any>();
      this.keyField = results.keyField;
      if (results.dataSource != null) {

        results.dataSource.forEach(item => {
          // console.log(item);
          item.columns.forEach(v => {
            if (v.id.toUpperCase() === this.keyField.toUpperCase() && v.value === field.value) {
              selectedElement = item;
            }
          });

        });
      } else {
        // EMPTY COMBO
      }
      this.comboAssociationField.forEach(res => {
        selectedElement.columns.forEach(v2 => {
          if (v2.id === comboResult.associations[res]) {
            this.dataMaskToSave[res.toUpperCase()] = v2.value;
          }
        });
      });
      this.getAllProfileValue();
    });
  }
    checkFormula(): void {
        let pastFormulas: Array<any> = new Array<any>();
        let found = false;
        pastFormulas = new Array<any>();
        this.fields.forEach(f => {
            if (f.formula) {
                found = false;
                pastFormulas.push({name: f.name, formula: f.formula});
                pastFormulas.forEach(pf => {
                    if (pf.formula.toString().includes(f.name.toString())) {
                        this.apiService.getProfileFormula(this.getUpdatedFields(f.name, f.formula)).subscribe(res => {
                            this.dataMaskToSave[f.name] = res;
                            this.apiService.getProfileFormula(this.getUpdatedFields(pf.name, pf.formula)).subscribe(res2 => {
                                this.dataMaskToSave[pf.name] = res2;
                            });
                        });
                        found = true;
                    }
                });
                if (!found) {
                    this.apiService.getProfileFormula(this.getUpdatedFields(f.name, f.formula)).subscribe(res => {
                        this.dataMaskToSave[f.name] = res;
                        this.fields.forEach(field => {
                            if (field.formula){
                                if (field.formula.toString().includes(f.name)) {
                                    this.apiService.getProfileFormula(this.getUpdatedFields(field.name, field.formula)).subscribe(res2 => {
                                        this.dataMaskToSave[field.name] = res2;
                                    });
                                }
                            }
                        });
                    });
                }
            }
        });
    }

    getUpdatedFields(name: string, form: string): any {
        this.jsonToSendArxivar =
            {
                fieldName: name,
                formula: form,
                searchFilterDto: {
                    fields: []
                }
            };
        this.fields.forEach((field, index) => {
            if (field.visible || this.dataMaskToSave[field.name]) {
              if (field.className === 'AdditionalFieldDateTimeDTO') {
                this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
              } else if (field.className === 'AdditionalFieldDoubleDTO') {
                if (this.dataMaskToSave[field.name]) {
                  this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
                } else {
                  this.fields[index].value = this.dataMaskToSave[field.name];
                }
              } else {
                this.fields[index].value = this.dataMaskToSave[field.name];
              }
            }
            this.jsonToSendArxivar.searchFilterDto.fields.push(this.fields[index]);
        });
        return this.jsonToSendArxivar;
    }

    searchMaskFromClassDTO(field: any): void {
        if (!field.locked) {
            const dialogRef = this.dialog.open(DialogSearchDocComponent, {
                width: '80%',
                height: 'fit-content',
                data: {
                    classField: field
                }
            });
            dialogRef.afterClosed().subscribe(res => {
                //// // console.log(this.mapClasseBookToSave.get(result.fieldName));
                // // console.log(res);
                res.dataResult.forEach(result => {
                    this.docNumberClasseboxTemp = new Array<string>();
                    // // console.log(this.mapClasseBookToSave);
                    this.docNumberClasseboxTemp = this.mapClasseBookToSave.get(result.fieldName);
                    // // console.log(this.docNumberClasseboxTemp);

                    this.docNumberClasseboxTemp.push(result.docNumber);
                    this.mapClasseBookToSave.set(result.fieldName, this.docNumberClasseboxTemp);
                    this.arrayToObject = {};
                    this.apiService.getProfileDetailsClass(result.docNumber).subscribe(callResults => {
                        callResults.fields.forEach(fieldClass => {
                            this.displayedColumnsClass.forEach(colum => {
                                if (colum === fieldClass.description) {
                                    this.arrayToObject[colum] = fieldClass.value;
                                }
                            });
                        });
                        this.ELEMENT_DATA_CLASS.push(this.arrayToObject);
                        this.dataSourceClass = new MatTableDataSource(this.ELEMENT_DATA_CLASS);
                        // // console.log(this.ELEMENT_DATA_CLASS);
                    });
                });
            });
        }
    }

    onResize(event): void {
        this.breakpoint = (event.target.innerWidth <= 600) ? 4 : 6;
    }

    showFileSelection(event): void {
        this.fileInfo = {name: '', buffer: ''};
        this.fileListMain = event.target.files;
        if (this.fileListMain.length > 0) {
            this.fileMain = this.fileListMain[0];
            this.formDataMain = new FormData();
            this.formDataMain.append('file', this.fileMain, this.fileMain.name);
            this.apiService.openBuffer(this.formDataMain).subscribe(result => {
                this.fileInfo.name = this.fileMain.name;
                this.fileInfo.buffer = result[0];
                this.bufferIdInfo.push(this.fileInfo);
                this.badgeBufferMain = this.bufferIdInfo.length;
            });
        }
    }


    removeFile(file: any): void {
        this.apiService.deleteBuffer(file).subscribe(result => {
            if (result) {
                this.snackBar.open('File ' + file.name + ' eliminato!', null, {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-success-bg']
                });
                this.bufferIdInfo.forEach((item, index) => {
                    if (item.buffer === file.buffer) {
                        this.bufferIdInfo.splice(index, 1);
                    }
                });
                this.badgeBufferMain = this.bufferIdInfo.length;
            }
        });
    }

    deleteNote(note: any): void {
        this.notes.forEach((v, index) => {
            if (v.comment === note.comment) {
                this.notes.splice(index, 1);
                this.snackBar.open('Nota eliminata correttamente', null, {
                    duration: 3000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-warning-bg']
                });
            }
        });
    }

    addNote(): void {
        if (this.addNoteText !== '') {
            this.notes.push({
                aosflag: false,
                comment: this.addNoteText,
                docnumber: null,
                isEdit: false,
                isPaNote: false
            });
            this.snackBar.open('Nota aggiunta correttamente', null, {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-success-bg']
            });
        }
        this.addNoteText = '';
    }

    saveMask(): void {
        this.validationCheck = true;
        this.fields.forEach(field => {
            if (field.visible) {
                if (field.required) {
                    // console.log(this.dataMaskToSave);
                    if ((field.value === '' || field.value == null)
                        && (this.dataMaskToSave[field.name] === '' || this.dataMaskToSave[field.name] == null)) {
                        this.snackBar.open('Il campo ' + field.description + ' è obbligatorio', null, {
                            duration: 5000,
                            horizontalPosition: 'end',
                            verticalPosition: 'bottom',
                            panelClass: ['mat-toolbar', 'custom-danger-bg']
                        });
                        this.validationCheck = false;
                    }
                }
            }
        });
        if (this.validationCheck) {
            this.buildDataMask();
        }
    }

    buildDataMask(): void {
        if (this.bufferIdInfo.length > 0) {
            this.jsonToSendArxivar = {
                document: {
                    fileNames: [],
                    bufferIds: []
                },
                fields: [],
                notes: this.notes
            };
            this.bufferIdInfo.forEach(file => {
                this.jsonToSendArxivar.document.fileNames.push(file.name);
                this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
            });
        } else {
            this.jsonToSendArxivar = {
                fields: [],
                notes: this.notes
            };
        }
        // // // console.log(this.dataMaskToSave);
        this.fields.forEach((field, index) => {
            if (field.visible) {
                // // console.log(this.dataMaskToSave);
              if (field.className === 'AdditionalFieldDateTimeDTO') {
                this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
              } else if (field.className === 'AdditionalFieldDoubleDTO') {
                if (this.dataMaskToSave[field.name]) {
                  this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
                } else {
                  this.fields[index].value = this.dataMaskToSave[field.name];
                }
              } else {
                this.fields[index].value = this.dataMaskToSave[field.name];
              }

                // // // console.log(this.dataMaskToSave[field.name]);

                // // // console.log(field);
            }
            this.jsonToSendArxivar.fields.push(this.fields[index]);
        });
        if (this.idTask === '0') {
            this.apiService.insertModelProfile(this.jsonToSendArxivar, this.idModel).subscribe(results => {
                this.docIdToDownload = results.docNumber;
                if (this.openModelAfterProfilation) {
                    this.downloadDoc();
                    // this.openInsertDialog(results.docNumber);
                    this.goBack();
                }
                if (results.showMessage) {
                    this.snackBar.open('Profilo caricato correttamente con id: ' + results.docNumber, null, {
                        duration: 5000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-success-bg']
                    });
                } else {
                    this.snackBar.open('Errore nel caricamento del profilo', null, {
                        duration: 5000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                    });
                }
            }, errorLog => {
                this.snackBar.open(errorLog.error.userMessage, null, {
                    duration: 7000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-danger-bg']
                });
            });
        } else {
            this.apiService.insertTaskByModel(this.idTask, this.operationId, this.jsonToSendArxivar).subscribe(results => {
                this.docIdToDownload = results.docNumber;
                if (this.openModelAfterProfilation) {
                    this.downloadDoc();
                    // this.openInsertDialog(results.docNumber);
                    this.goBack();
                } else {
                    this.goBack();
                }
                if (results.showMessage) {
                    this.snackBar.open('Profilo caricato correttamente nel task con id: ' + this.idTask, null, {
                        duration: 5000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-success-bg']
                    });
                } else {
                    this.snackBar.open('Errore nel caricamento del profilo', null, {
                        duration: 5000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                    });
                }
            }, errorLog => {
                this.snackBar.open(errorLog.error.userMessage, null, {
                    duration: 7000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-danger-bg']
                });
            });
        }


    }

    downloadDoc(): void {
        let docName: string;
        this.apiService.downloadDocument(this.docIdToDownload).subscribe(response => {
            this.apiService.getDocDetails(this.docIdToDownload).subscribe(datadoc => {
                docName = datadoc.profileInfo.fileName;
                const blob = new Blob([response], {type: 'application/octet-stream'});
                fileSaver.saveAs(blob, docName);
            });

            // tslint:disable-next-line:no-unused-expression
        }, errorLog => {
            this.snackBar.open('Errore', null, {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
        });

    }

    TransformDataFormatToSave(Data: string): string {

        if (Data === '') {
            return '0001-01-01T00:00:00';
        }
        return this.datePipe.transform(Data, 'yyyy-MM-dd', '', 'en-GB');
    }

    opendocumentalClassDialog(): void {
        this.apiService.classDocMode = '1';
        const dialogRef = this.dialog.open(DialogDocumentalClassComponent, {
            width: '40%',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            // console.log(result);
            if (result) {
                this.fields.forEach(v => {
                    if (v.name === 'DocumentType') {
                        this.dataMaskToSave[v.name] = Number(result.docClassId);
                        v.value = Number(result.docClassId);
                        this.documentChanged({isUserInput: true}, result.docType, result.type1, result.type2);
                    }
                });
            }
        });
    }

    getAllProfileValue(): void {
        this.fields.forEach((val, index) => {
            if (val.className === 'AdditionalFieldComboDTO' || val.className === 'AdditionalFieldTableDTO' || val.className === 'AdditionalFieldMultivalueDTO') {
                this.apiService.getProfileValuesWithFilter(val.name, this.getJsonFields().fields).subscribe(results => {
                    if (results.dataSource.length === 0) {
                        this.fields[index].value = '';
                        this.dataMaskToSave[this.fields[index].name] = '';
                    }
                });
            }
        });


    }

    getJsonFields(): any {
        if (this.bufferIdInfo.length > 0) {
            this.jsonToSendArxivar = {
                document: {
                    fileNames: [],
                    bufferIds: []
                },
                fields: []
            };
            this.bufferIdInfo.forEach(file => {
                this.jsonToSendArxivar.document.fileNames.push(file.name);
                this.jsonToSendArxivar.document.bufferIds.push(file.buffer);
            });
        } else {
            this.jsonToSendArxivar = {
                fields: []
            };
        }
        // // // console.log(this.dataMaskToSave);
        this.fields.forEach((field, index) => {
            if (field.visible) {
                // // console.log(this.dataMaskToSave);
              if (field.className === 'AdditionalFieldDateTimeDTO') {
                this.fields[index].value = this.TransformDataFormatToSave(this.dataMaskToSave[field.name]);
              } else if (field.className === 'AdditionalFieldDoubleDTO') {
                if (this.dataMaskToSave[field.name]) {
                  this.fields[index].value = (this.dataMaskToSave[field.name].toString().replace(',', '.'));
                } else {
                  this.fields[index].value = this.dataMaskToSave[field.name];
                }
              } else {
                this.fields[index].value = this.dataMaskToSave[field.name];
              }


                // // // console.log(this.dataMaskToSave[field.name]);

                // // // console.log(field);
            }
            this.jsonToSendArxivar.fields.push(this.fields[index]);
        });
        return this.jsonToSendArxivar;
    }

    goBack(): void {
        if (this.idTask !== '0') {
            this.router.navigate(['../dettaglitask', {id: this.idTask, path: this.firstnodepath}], {relativeTo: this.route});
        } else {
            this.router.navigate(['../' + this.path + ''], {relativeTo: this.route});
        }
    }

    openInsertDialog(docId: string): void {
        const dialogRef = this.dialog.open(InsertFileDialogComponent, {
            width: '20%',
            data: {doc: docId}
        });

        dialogRef.afterClosed().subscribe(result => {
            this.goBack();
        });
    }
}

@Component({
    selector: 'app-insertfiledialog',
    templateUrl: 'insertFileDialog.html',
})
export class InsertFileDialogComponent {
    fileListMain: FileList;
    fileMain: File;
    fileinserito = '';
    fileToBase: File; // file
    selectedOption: string;
    formDataMain: FormData;
    cacheCode: string;
    taskId: string;
    bufferIdInfo: Array<any> = new Array<any>();
    badgeBufferMain = 0;
    docId: string;
    NomeFile: string;

    constructor(private snackBar: MatSnackBar, @Inject(DOCUMENT) private document: Document,
                public dialogRef: MatDialogRef<InsertFileDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
                public apiService: ApiService) {
        this.docId = data.doc;
    }


    onClick(): void {

    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    removeFile(): void {
        this.badgeBufferMain = 0;
    }

    // tslint:disable-next-line:typedef
    fileChangeEventOld(event) {
        this.fileListMain = event.target.files;
        this.formDataMain = new FormData();
        this.formDataMain.append('file', this.fileMain, this.fileMain.name);
        this.apiService.insertFileInCache(this.formDataMain).subscribe(data => {
            this.cacheCode = data;
        });
        this.fileinserito = 'File inserito correttamente';
    }

    fileChangeEvent(event): void {
        this.fileListMain = event.target.files;
        if (this.fileListMain.length > 0) {
            this.fileMain = this.fileListMain[0];
            this.formDataMain = new FormData();
            this.formDataMain.append('file', this.fileMain, this.fileMain.name);
            this.NomeFile = this.fileMain.name;
            this.apiService.insertFileInCache(this.formDataMain).subscribe(data => {
                this.cacheCode = data;
                this.fileinserito = 'File inserito correttamente';
                this.badgeBufferMain = 1;

            });
        }

    }

    sendFile(): void {
        this.apiService.insertDocument(this.docId, this.cacheCode).subscribe(data => {
            // console.log(data);
            this.snackBar.open('File caricato correttamente', null, {
                duration: 4000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-success-bg']
            });
        }, errorLog => {
            this.snackBar.open(errorLog.error.userMessage, null, {
                duration: 7000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
        });
        this.closeDialog();
    }


    // tslint:disable-next-line:typedef
    handleFile(event) {
        const binaryString = event.target.result;
        this.fileToBase = binaryString;
        // console.log(this.fileToBase);
    }

}
