import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ApiService} from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';
import {error} from 'selenium-webdriver';
import {ToolbarComponent} from '../layout/toolbar/toolbar.component';
import {BodyFieldToSave} from '../bodyFieldToSave';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  code = this.activatedRoute.snapshot.paramMap.get('code');
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  mfaToken: string;
  tempLocalUser = localStorage.getItem('username');
  constrains: any;
  error: string;
  expired = this.activatedRoute.snapshot.paramMap.get('expired');
  username: string;
  password: string;
  isSettingVisible = false;
  URL = this.apiService.BASE_URL;
  ID = this.apiService.CLIENT_ID;
  SECRET = this.apiService.CLIENT_SECRET;
  changePasswordVar = false;
  img: string;
  forgotPassword = false;
  usernameFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  hide = true;
  oobCode: string;
  mfaCodeVerifica: string;
  matcher = new MyErrorStateMatcher();
  isMultiFactorAuth = false;
  isOOB = false;
  isOTP = false;
  authenticatorIdOOB = '';
  authenticatorIdOTP = '';
  otpCode: string;

  constructor(public apiService: ApiService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    console.log(this.code);
    this.activatedRoute.queryParams.subscribe(params => {
      let code = params['code'];

      // console.log(code);// OUTPUT 1534
    });
    this.apiService.getLogo().subscribe(data => {
        this.img = 'data:image/png;base64,' + data[0].logo;
      }
    );
  }

  checkErrors(): void {

    if (this.newPassword === this.oldPassword) {
      this.error = 'La nuova password non può essere uguale a quella da cambiare';
    } else if (this.newPassword !== this.confirmPassword) {
      this.error = '"Nuova password" e "Conferma password" devono coincidere';
    } else {
      this.error = '';
    }

  }
  forgotClicked(): void {
    this.forgotPassword = !this.forgotPassword;
  }
  changePassword(): void {
    if (this.oldPassword !== '' && this.newPassword !== '' && this.confirmPassword !== '') {
      if (this.newPassword === this.oldPassword) {
        this.error = 'La nuova password non può essere uguale a quella da cambiare';
      } else if (this.newPassword !== this.confirmPassword) {
        this.error = '"Nuova password" e "Conferma password" devono coincidere';
      } else {
        this.apiService.changePassword(this.oldPassword, this.newPassword).subscribe(res => {
          this.router.navigate(['dashboard']);
          this.snackBar.open('Password cambiata con successo', null, {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-success-bg']
          });
        }, errorLog => {
          this.snackBar.open(errorLog.error.userMessage, null, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        });
      }
    } else {
      this.error = 'Tutti i campi devono essere compilati';
    }
  }

  openAvDemo(): void {
    window.open('http://av-demo.frontendly.it/login');
  }

  openAcDemo(): void {
    window.open('http://ac-demo.frontendly.it/login');
  }

  openGeDemo(): void {
    window.open('http://ge-demo.frontendly.it/login');
  }

  openLaDemo(): void {
    window.open('http://la-demo.frontendly.it/login');
  }

  retrievePasswordClick(): void {
    this.apiService.retrievePassword(this.username).subscribe(res => {
        this.snackBar.open('Password ripristinata con successo', null, {
          duration: 2000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['mat-toolbar', 'custom-success-bg']
        });

        this.forgotPassword = false;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.error.userMessage) {
          this.snackBar.open(error.error.userMessage, null, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });
        } else {

          this.snackBar.open('Problema con la connessione ad Arxivar', null, {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'custom-danger-bg']
          });

        }
      });
  }

  setSettings(): void {
    if (this.isSettingVisible) {
      this.isSettingVisible = false;
    } else {
      this.isSettingVisible = true;
    }
  }

  login(): void {
    if(!this.isMultiFactorAuth){
      if (!this.passwordFormControl.hasError('required') && !this.usernameFormControl.hasError('required')) {
        const credentialUser: JSON = {
          username: this.username,
          password: this.password,
          clientId: this.apiService.CLIENT_ID,
          clientSecret: this.apiService.CLIENT_SECRET
        } as unknown as JSON;
        this.apiService.login(credentialUser).subscribe(data => {
            this.changePasswordVar = false;
            this.apiService.setLoggedInInformation(data);
            localStorage.setItem('username', this.username);
            localStorage.setItem('expires', data.expires);
            if (data.arxuserMustChangePassword) {
              this.changePasswordVar = true;
            } else {
              this.router.navigate(['dashboard']);
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => {
            if (error.error.error === 'mfa_required'){
              this.mfaToken = error.error.mfaToken;
              this.apiService.getMfaAuthentication(error.error.mfaToken).subscribe(res => {
                res.forEach(mf => {
                  if (mf.authenticatorType === 'oob'){
                    this.authenticatorIdOOB = mf.authenticatorId;
                    this.isOOB = true;
                  }
                  if (mf.authenticatorType === 'otp'){
                    this.authenticatorIdOTP = mf.authenticatorId;
                    this.isOTP = true;
                  }
                });
                if(this.isOOB && this.isOTP){
                  this.apiService.challengeMfaAuthentication(this.mfaToken, this.authenticatorIdOTP).subscribe(challengeRes => {
                    // this.oobCode = challengeRes.oobCode;
                    this.isMultiFactorAuth = true;
                    },
                    // tslint:disable-next-line:no-shadowed-variable
                    error => {
                      this.snackBar.open(error.error.userMessage, null, {
                        duration: 2000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                      });
                    });
                }else if (this.isOOB){
                  this.apiService.challengeMfaAuthentication(this.mfaToken, this.authenticatorIdOOB ).subscribe(challengeRes => {
                    this.oobCode = challengeRes.oobCode;
                    this.isMultiFactorAuth = true;
                    },
                    // tslint:disable-next-line:no-shadowed-variable
                    error => {
                      this.snackBar.open(error.error.userMessage, null, {
                        duration: 2000,
                        horizontalPosition: 'end',
                        verticalPosition: 'bottom',
                        panelClass: ['mat-toolbar', 'custom-danger-bg']
                      });
                    });
                }
                },
                // tslint:disable-next-line:no-shadowed-variable
                error => {
                  this.snackBar.open(error.error.userMessage, null, {
                    duration: 2000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    panelClass: ['mat-toolbar', 'custom-danger-bg']
                  });
                });
            }else{
              this.snackBar.open(error.error.userMessage, null, {
                duration: 2000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                panelClass: ['mat-toolbar', 'custom-danger-bg']
              });
            }
          });
      }
    }else{
      if(this.isOTP && this.isOOB){
        this.apiService.mfaOtpLogin(this.mfaToken, this.otpCode).subscribe(result => {
            this.changePasswordVar = false;
            this.apiService.setLoggedInInformation(result);
            localStorage.setItem('username', this.username);
            localStorage.setItem('expires', result.expires);
            if (result.arxuserMustChangePassword) {
              this.changePasswordVar = true;
            } else {
              this.router.navigate(['dashboard']);
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => {
            this.snackBar.open(error.error.userMessage, null, {
              duration: 2000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });
      }else if (this.isOOB){
        this.apiService.mfaOobLogin(this.mfaToken, this.oobCode, this.mfaCodeVerifica).subscribe(result => {
            this.changePasswordVar = false;
            this.apiService.setLoggedInInformation(result);
            localStorage.setItem('username', this.username);
            localStorage.setItem('expires', result.expires);
            if (result.arxuserMustChangePassword) {
              this.changePasswordVar = true;
            } else {
              this.router.navigate(['dashboard']);
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => {
            this.snackBar.open(error.error.userMessage, null, {
              duration: 2000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
              panelClass: ['mat-toolbar', 'custom-danger-bg']
            });
          });
      }

    }
  }

  saveConnection(): void {
    localStorage.setItem('BASE_URL', this.URL);
    localStorage.setItem('CLIENT_ID', this.ID);
    localStorage.setItem('CLIENT_SECRET', this.SECRET);
    window.location.reload();
  }

}
