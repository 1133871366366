import { Component, OnInit } from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
  procId = this.activatedRoute.snapshot.paramMap.get('processId');
  taskId = this.activatedRoute.snapshot.paramMap.get('taskId');
  idView = this.activatedRoute.snapshot.paramMap.get('idv');
  folderId = this.activatedRoute.snapshot.paramMap.get('folderId');
  firstnodepath = this.activatedRoute.snapshot.paramMap.get('firstnodepath');
  path = this.activatedRoute.snapshot.paramMap.get('path');
  process: Array<any> = new Array<any>();
  panelOpenState = false;
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  constructor(private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.apiService.addPath('Processo: ' + this.procId, 'dashboard/dashnav/process', {
      processId: this.procId, taskId: this.taskId,
      idv: this.idView,
      folderId: this.folderId, firstnodepath: this.firstnodepath, path: this.path
    });
    this.apiService.namePath += ' - ' + 'Processo: ' + this.procId;
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
    // // console.log(this.procId);
    this.apiService.getProcessInfo(this.procId).subscribe(res => {
      // console.log(res);
      this.process = res;
    });
  }
  goBack(): void {
    if (this.path === 'view'){
      this.router.navigate(['../view', {id: this.idView, firstnodepath: this.firstnodepath}], {relativeTo: this.route});
    }else{
    this.router.navigate(['../' + this.path, {id: this.taskId, path: this.firstnodepath, openId: this.folderId}], {relativeTo: this.route});
    }
  }
  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy', '', 'en-GB');
  }
  deleteTask(taskId: string): void{
    this.apiService.deleteTask(taskId).subscribe(res => {
      this.ngOnInit();
    });
  }
}
