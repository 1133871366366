import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ApiService} from '../../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  dashColor: string;
  backColor: string;
  squareColor: string;
  cardColor: string;
  reports: Array<any> = new Array<any>();


  constructor(private route: ActivatedRoute, private router: Router, iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer, public apiService: ApiService, private activatedRoute: ActivatedRoute,
              private datePipe: DatePipe, private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.apiService.getReports().subscribe( result => {
      this.reports = result;
    }, errorLog => {
      this.snackBar.open(errorLog.error.userMessage, null, {
        duration: 4000,
        horizontalPosition: 'end',
verticalPosition: 'bottom',
        panelClass: ['mat-toolbar', 'custom-danger-bg']
      });
    });
    this.dashColor = this.apiService.dashColor;
    this.backColor = this.apiService.backColor;
    this.squareColor = this.apiService.squareColor;
    this.cardColor = this.apiService.cardColor;
  }
  TransformDataFormat(Data: string): string {
    return this.datePipe.transform(Data, 'dd/MM/yyyy, H:MM', '', 'en-GB');

  }
  reportClick(idRep: string): void{
    this.router.navigate(['../reportdetails', {id: idRep}], {relativeTo: this.route});
  }
}
