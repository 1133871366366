<div style="width: 100%">
    <button style="float: right;" title="Chiudi" (click)="closeDialog()" mat-icon-button color="warn"
            aria-label="icon-button save">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div style="width: 100%; display: flex">
    <h2 style="text-align: center; width: 100%;">Creazione categoria in<br> '{{this.name}}'</h2>
</div>
<div style="text-align: center; width: 100%">
    <mat-form-field>
        <input [(ngModel)]="this.newCat" matInput name="Nome"
               placeholder="Nome categoria"
        >
    </mat-form-field>
    <br><br>
    <hr>
    <br>
    <button mat-stroked-button (click)="create()">Crea</button>
</div>
