<div class="center">
  <mat-card class="cardcomponentMasks" id="style-4" style="overflow: hidden; display: grid !important">
    <div style="display: flex">
      <div style="width: 70%; text-align: left">

        <ng-container *ngIf="this.edit == true">
          <img width="55px" *ngIf = "this.dataMaskToSave['Aoo'] == 'HMI'" [src]="'assets/img/hearst-logo-585x761-nero.png'" style="float:left"/>
          <img height="55px" *ngIf = "this.dataMaskToSave['Aoo'] == 'HAW'" [src]="'assets/img/image002.jpg'" style="float:left"/>
          <h3 style="text-align: left; margin-left: 2%"
              appearance="outline">
            <!--Campo aggiuntivo "Generale"-->
            <br>
            <h1 style="color: {{this.apiService.dashColor}}"><b
              style="margin-left: 2%;">{{'labels.addSupplier' | translate}}</b>
            </h1>

          </h3>

          <div style="margin-top: 2%">
            <button class="link" (click)="useLanguage('it')" *ngIf="true">IT</button>
            <span *ngIf="true">/</span>
            <button class="link" (click)="useLanguage('en')" *ngIf="true">EN</button>
          </div>


        </ng-container>
      </div>

      <div style="width: 30%;">

        <button style="float:right; margin-right: 2%; color:black; background-color: #5cb85c"
                title="{{'labels.save' | translate}}" (click)="saveMask()"
                mat-fab
                aria-label="icon-button send">
          <mat-icon>save</mat-icon>
        </button>
        <button *ngIf="badgeBufferMain >0" style="float:right; margin-right: 5%; color:black; background-color: #5bc0de"
                [matMenuTriggerFor]="menuFile"
                aria-label="{{'labels.seeFile' | translate}}"
                mat-fab>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menuFile="matMenu">
          <ng-container *ngFor="let file of bufferIdInfo">
            <button mat-menu-item (click)="removeFile(file)">
              <mat-icon>close</mat-icon>
              <span style="font-size: 16px; font-weight: 500;">{{file.name}}</span>
            </button>
          </ng-container>
        </mat-menu>
        <button *ngIf="optionsMask.showFileSelection"
                style="float:right; margin-right: 5%; color:black; background-color:#5bc0de"
                title="{{'labels.uploadFile' | translate}}"
                (click)="fileInput.click()" [disabled]="badgeBufferMain > 0"
                mat-fab [matBadge]="badgeBufferMain" matBadgeColor="accent">
          <mat-icon>cloud_upload</mat-icon>
        </button>
        <input type="file" (change)="showFileSelection($event)" hidden #fileInput id="fileUpload"
               name="fileUpload"
               accept="*"/>
        <button *ngIf="optionsMask.showAttachments && badgeBufferMain > 0 && this.whatToShow != 'attachments'"
                style="float:right; color: black; margin-right: 5%; "
                title="{{'labels.addAttachmentExt' | translate}}"
                (click)="showAttachments()" mat-fab>
          <mat-icon>imagesmode</mat-icon>
        </button>
        <button style="float:right; margin-right: 5%;  color:{{this.dashColor}};" title="Desktop"
                *ngIf="this.whatToShow == 'attachments'"
                (click)="showDesk()" mat-fab>
          <mat-icon>computer</mat-icon>
        </button>
      </div>
    </div>
    <div style="color: {{this.apiService.dashColor}}; text-align: left" *ngIf="this.whatToShow == 'attachments'">
      <h1>{{'labels.attachmentListExt' | translate}}: </h1></div>
    <hr class="riga" style="color: {{this.apiService.dashColor}}; margin-bottom: 5px !important">
    <br>
    <mat-card-content style="overflow: auto; height: 98%;">

      <ng-container [ngSwitch]="this.whatToShow">
        <ng-container *ngSwitchCase="'default'">
          <form class="flexform" (input)="checkInputFormula()">
            <ng-container *ngFor="let field of this.fields">

              <ng-container [ngSwitch]="field.className"
                            *ngIf="field.visible && field.className != 'ProtocolDateFieldDTO'"
                            style="height:fit-content; width: 100%;">
                <!-- NumberFieldDTO -->
                <mat-form-field *ngSwitchCase="'NumberFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name"
                         [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         [value]="field.value || ''"
                         maxlength="{{field.numMaxChar || 100}}"
                         matInput placeholder="{{field.description}}">
                </mat-form-field>
                <!-- DocumentDateFieldDTO -->
                <mat-form-field *ngSwitchCase="'DocumentDateFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input style="color:#29648A;" matInput [matDatepicker]="picker" onkeydown="return false;"
                         [value]="field.value || ''"
                         [id]="field.name" [required]="field.required"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [disabled]="field.locked || this.edit == false">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker [disabled]="field.locked || this.edit == false" #picker
                                  color="primary"></mat-datepicker>
                </mat-form-field>
                <!-- SubjectFieldDTO -->
                <mat-form-field *ngSwitchCase="'SubjectFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [value]="field.value || ''"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>
                <!-- ToFieldDTO -->
                <mat-form-field *ngSwitchCase="'ToFieldDTO'"
                                [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                  <!--A-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-chip-list #chipList aria-label="MultiValue">
                    <div style="width: 100%; display: flex;">
                      <div style="width: 80%">
                        <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)"
                                  [selectable]="false" [disabled]="field.locked || this.edit == false"
                                  [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                  [value]="element.id">
                          {{element.name}}
                          <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                        </mat-chip>
                      </div>
                      <div style="width: 20%; text-align: right">
                        <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                style="color: {{this.apiService.dashColor}}"
                                [disabled]="field.locked || this.edit == false">
                          <mat-icon>notes</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-chip-list>
                </mat-form-field>

                <!-- FromFieldDTO -->
                <mat-form-field *ngSwitchCase="'FromFieldDTO'"
                                [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                  <!--DA-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-chip-list #chipList aria-label="MultiValue">
                    <div style="width: 100%; display: flex;">
                      <div style="width: 80%">
                        <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)"
                                  [selectable]="false" [disabled]="field.locked || this.edit == false"
                                  [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                  [value]="element.id">
                          {{element.name}}
                          <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                        </mat-chip>
                      </div>
                      <div style="width: 20%; text-align: right">
                        <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                style="color: {{this.apiService.dashColor}}"
                                [disabled]="field.locked || this.edit == false">
                          <mat-icon>notes</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-chip-list>
                </mat-form-field>

                <!-- CcFieldDTO -->
                <mat-form-field *ngSwitchCase="'CcFieldDTO'"
                                [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline">
                  <!--A-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-chip-list #chipList aria-label="MultiValue">
                    <div style="width: 100%; display: flex;">
                      <div style="width: 80%">
                        <mat-chip *ngFor="let element of this.mapAddressBookToSee.get(field.name)" [selectable]="false"
                                  [disabled]="field.locked || this.edit == false"
                                  [removable]="removable" (removed)="removeAddressBook(element, field.name)"
                                  [value]="element.id">
                          {{element.name}}
                          <mat-icon matChipRemove *ngIf="removable && !(field.locked)">cancel</mat-icon>
                        </mat-chip>

                      </div>
                      <div style="width: 20%; text-align: right">
                        <button matSuffix mat-icon-button (click)="openAddressBook(field)"
                                style="color: {{this.apiService.dashColor}}"
                                [disabled]="field.locked || this.edit == false">
                          <mat-icon>notes</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-chip-list>

                </mat-form-field>

                <!-- BusinessUnitFieldDTO -->
                <mat-form-field *ngSwitchCase="'BusinessUnitFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Aoo-->
                  <mat-label>{{'labels.company'}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked || this.edit == false">
                    <mat-option *ngFor="let aoo of nomiAoo" [value]="aoo.code">
                      {{aoo.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- DocumentTypeFieldDTO -->
                <div *ngSwitchCase="'DocumentTypeFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }">


                  <mat-form-field class="form-field-docType" style="width: 80%"
                                  appearance="outline">
                    <mat-label>{{field.description}}</mat-label>
                    <mat-select [value]="field.value || ''" [id]="field.name"
                                [required]="field.required"
                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                [disabled]="field.locked || this.edit == false"
                    >
                      <mat-option
                        (onSelectionChange)="documentChanged($event, documenType.documentType,documenType.type2,documenType.type3)"
                        *ngFor="let documenType of documenTypes"
                        [value]="documenType.id">
                        {{documenType.description}} - {{documenType.key}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                  <span>
                                    <button [disabled]="field.locked || this.edit == false" matSuffix mat-icon-button
                                            style="color: {{this.apiService.dashColor}};"
                                            (click)="opendocumentalClassDialog()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                         </span>
                </div>
                <!-- OriginFieldDTO -->
                <mat-form-field *ngSwitchCase="'OriginFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Origine-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked || this.edit == false">
                    <mat-option *ngFor="let originField of originsFields"
                                [value]="originField.value">
                      {{originField.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- StateFieldDTO -->
                <mat-form-field *ngSwitchCase="'StateFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Stato, dipende da documento-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-select [id]="field.name" [id]="field.name" [value]="field.value || ''"
                              [required]="field.required"
                              [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                              [disabled]="field.locked || this.edit == false">
                    <mat-option *ngFor="let stateField of stateFields" [value]="stateField.id">
                      {{stateField.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- BinderFieldDTO -->
                <mat-form-field *ngSwitchCase="'BinderFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Pratiche-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- DocumentDateExpiredFieldDTO -->
                <mat-form-field *ngSwitchCase="'DocumentDateExpiredFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Scadenza-->
                  <mat-label>{{field.description}}</mat-label>
                  <input style="color:#29648A;" [value]="field.value || ''" matInput
                         [matDatepicker]="picker" onkeydown="return false;"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker [disabled]="field.locked || this.edit == false" #picker
                                  color="primary"></mat-datepicker>

                </mat-form-field>

                <!-- ImportantFieldDTO -->
                <mat-form-field *ngSwitchCase="'ImportantFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Campo "Importante"-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- SendersFieldDTO -->
                <mat-form-field *ngSwitchCase="'SendersFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Altri da-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- OriginalFieldDTO -->
                <mat-form-field *ngSwitchCase="'OriginalFieldDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--Originale-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- AdditionalFieldGroupDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldGroupDTO'">
                  <ng-container
                    *ngIf="!((field.description == 'Dati Anagrafici (persone fisiche/collaborazioni redazioni)' || field.description == 'Personal data') && this.dataMaskToSave['COMBO299_22'] != 'Persona fisica/collaboratore')">
                    <ng-container
                      *ngIf="!((field.description == 'Dati Anagrafici (Società)' || field.description == 'Company master data') && this.dataMaskToSave['COMBO299_22'] != 'Società')">
                      <div class="form-field-left"
                           *ngIf="!((field.description == 'Dati Bancari' || field.description == 'Banking data') && this.fileRequired==false) && !(field.description == 'Generale')">
                        <h3 style="text-align: left"
                            appearance="outline">
                          <!--Campo aggiuntivo "Generale"-->
                          <br>
                          <h1 style="color: {{this.apiService.dashColor}}">{{field.description}}<br>
                            <hr>
                          </h1>

                        </h3>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <!-- AdditionalFieldStringDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldStringDTO'">
                  <ng-container *ngIf="field.description == 'Link' && this.edit == false">
                    <a [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                       target="_blank" href="{{dataMaskToSave[field.name]}}">{{dataMaskToSave[field.name]}}</a>
                  </ng-container>
                  <ng-container *ngIf="field.description != 'Link' || this.edit == true">
                    <mat-form-field [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                    appearance="outline">
                      <!--Tipo documento-->
                      <mat-label>{{field.description}}</mat-label>
                      <ng-container *ngIf="field.numMaxChar > 400">
                                      <textarea ngModel [value]="field.value || ''"
                                                [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                                [id]="field.name" [required]="field.required"
                                                [disabled]="field.locked || this.edit == false"
                                                maxlength="{{field.numMaxChar || 100}}" matInput
                                                rows="{{field.numRows}}" autosize
                                                style="resize: none;width:95%;"
                                                placeholder="{{field.description}}"></textarea>
                      </ng-container>
                      <ng-container *ngIf="field.numMaxChar <= 400">
                        <input ngModel [value]="field.value || ''"
                               [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                               [id]="field.name" [required]="field.required"
                               [disabled]="field.locked || this.edit == false"
                               maxlength="{{field.numMaxChar || 100}}" matInput
                               placeholder="{{field.description}}">
                      </ng-container>
                    </mat-form-field>
                  </ng-container>
                </ng-container>


                <!-- AdditionalFieldTableDTO -->
                <mat-form-field (click)="openTableDTO(field)"
                                *ngSwitchCase="'AdditionalFieldTableDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <!--TableDTO-->
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         [id]="field.name" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                  <button mat-button matSuffix mat-icon-button style="color: {{this.apiService.dashColor}};">
                    <mat-icon>table_chart</mat-icon>
                  </button>
                </mat-form-field>

                <!-- AdditionalFieldMultivalueDTO -->
                <mat-form-field *ngSwitchCase="'AdditionalFieldMultivalueDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                >
                  <!--MultiValueDTO-->
                  <mat-label>{{field.description}}</mat-label>
                  <mat-chip-list #chipList aria-label="MultiValue" [required]="field.required">
                    <div style="width: 100%; display: flex;">
                      <div style="width: 80%">
                        <mat-chip *ngFor="let element of this.mapMultiValueToSee.get(field.name)" [selectable]="false"
                                  [disabled]="field.locked || this.edit == false"
                                  [removable]="removable" (removed)="remove(element, field.name)">
                          {{element}}
                          <mat-icon matChipRemove *ngIf="removable && !(field.locked || this.edit == false)">cancel
                          </mat-icon>
                        </mat-chip>
                      </div>
                      <div style="width: 20%; text-align: right; height: 60px;">
                        <button matSuffix mat-icon-button (click)="openMultiValueDTO(field)"
                                style="color: {{this.apiService.dashColor}}"
                                [disabled]="field.locked || this.edit == false">
                          <mat-icon>notes</mat-icon>
                        </button>
                      </div>
                    </div>
                  </mat-chip-list>
                </mat-form-field>
                <div *ngSwitchCase="'InstructionFieldDTO'" style="background-color: aliceblue;"
                     [innerHtml]="field.description" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}" appearance="outline" style="text-align: left;font: message-box; color: {{this.apiService.squareColor}}">


                </div>
                <mat-form-field *ngSwitchCase="'AdditionalFieldDoubleDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [id]="field.name" type="text" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>
                <ng-container *ngSwitchCase="'AdditionalFieldBooleanDTO'">

                  <div style="text-align: left !important; margin-bottom: 2%; margin-top: 1%;"
                       [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                       appearance="outline">
                    <mat-slide-toggle [id]="field.name" style="color: {{this.apiService.dashColor}}" color="warn"
                                      [required]="field.required" (toggleChange)="this.checkFormula()"
                                      [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                      [disabled]="field.locked || this.edit == false"
                    >{{field.description}}</mat-slide-toggle>
                    <!--MultiValueDTO-->
                  </div>
                </ng-container>
                <!-- AdditionalFieldComboDTO -->
                <ng-container *ngSwitchCase="'AdditionalFieldComboDTO'">
                  <ng-container *ngIf="field.limitToList">
                    <mat-form-field
                      [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                      appearance="outline">
                      <!--Tipo documento-->
                      <mat-label>{{field.description}}</mat-label>
                      <mat-select [id]="field.name" [value]="field.value || ''"
                                  [required]="field.required" (selectionChange)="this.checkFormula()"
                                  (selectionChange)="this.checkComboAssociations(field)"
                                  [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                                  [disabled]="field.locked">
                        <mat-option>--{{'labels.cancel' | translate }}--</mat-option>
                        <mat-option *ngFor="let value of this.comboValues[field.name]"
                                    [value]="value.keyField">
                          {{value.selectField}}
                        </mat-option>

                      </mat-select>

                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!field.limitToList">

                    <mat-form-field style="width: 45%"
                                    [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                    appearance="outline">
                      <!--Tipo documento-->
                      <mat-label>{{field.description}}</mat-label>
                      <input ngModel
                             name="{{field.name}}" (ngModelChange)="this.checkComboAssociations(field);"
                             [(ngModel)]="this.comboControl[field.name]"
                             [id]="field.name" [required]="field.required" (input)="checkField(field)"
                             [disabled]="field.locked" [ngModelOptions]="{standalone: true}"
                             maxlength="{{field.numMaxChar || 100}}" matInput
                             placeholder="{{field.description}}" [matAutocomplete]="auto">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                        [displayWith]="displayFn.bind(this, field)">
                        <ng-container *ngFor="let value of this.comboValues[field.name]">
                          <mat-option *ngIf="value.visible"
                                      [value]="value">
                            {{value.selectField}}
                          </mat-option>
                        </ng-container>

                      </mat-autocomplete>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'AdditionalFieldDateTimeDTO'">
                  <mat-form-field *ngIf="field.value == '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="field.value || ''" matInput
                           [matDatepicker]="picker" onkeydown="return false;"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked || this.edit == false}}" #picker
                                    color="primary"></mat-datepicker>

                  </mat-form-field>
                  <mat-form-field *ngIf="field.value != '0001-01-01T00:00:00'" [ngClass]="{
   'form-field': field.columns !== 1,
   'form-field-expanded': field.columns === 1
}"
                                  appearance="outline"
                  >
                    <!--AdditionalFieldDateTimeDTO-->
                    <mat-label>{{field.description}}</mat-label>
                    <input style="color:#29648A;" [value]="'' || ''" matInput
                           [matDatepicker]="picker" onkeydown="return false;"
                           [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                           [id]="field.name" [required]="field.required" (dateChange)="this.checkInputFormula()"
                           [disabled]="field.locked || this.edit == false" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="{{field.locked || this.edit == false}}" #picker
                                    color="primary"></mat-datepicker>

                  </mat-form-field>
                </ng-container>
                <!--AdditionalFieldIntDTO-->
                <mat-form-field *ngSwitchCase="'AdditionalFieldIntDTO'" [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }"
                                appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input ngModel [value]="field.value || ''"
                         [id]="field.name" type="number" [required]="field.required"
                         [disabled]="field.locked || this.edit == false"
                         [(ngModel)]="dataMaskToSave[field.name]" name="{{field.name}}"
                         maxlength="{{field.numMaxChar || 100}}" matInput
                         placeholder="{{field.description}}">
                </mat-form-field>

                <!-- AdditionalFieldClasseDTO -->

                <div class="classBox" *ngSwitchCase="'AdditionalFieldClasseDTO'">

                  <div>
                    <h4
                      style="float:left; margin-left: 2.5%; color: {{this.apiService.dashColor}};">{{field.description}} </h4>
                    <button *ngIf="mapClasseBookToSave.get(field.name).length > 0"
                            style="float:right; color:{{this.apiService.dashColor}};"
                            title="{{'labels.seeMasks'}}"
                            (click)="openClassMasks(field)" mat-icon-button
                            aria-label="icon-button send">
                      <mat-icon [matBadge]="mapClasseBookToSave.get(field.name).length">
                        preview
                      </mat-icon>
                    </button>
                    <button style="float:right; color:{{this.apiService.dashColor}};"
                            title="{{'labels.insertMask'}}"
                            (click)="insertMaskFromClassDTO(field)" mat-icon-button
                            aria-label="icon-button send">
                      <mat-icon>post_add</mat-icon>
                    </button>
                    <!--<button style="float:right; color:{{this.apiService.dashColor}};"
                            title="Cerca maschera"
                            (click)="searchMaskFromClassDTO(field)" mat-icon-button
                            aria-label="icon-button send">
                      <mat-icon>search</mat-icon>
                    </button>-->
                  </div>

                  <!--div class="z8 example-container">
                    <table mat-table [dataSource]="dataSourceClass">
                      <ng-container *ngFor="let disCol of displayedColumnsClass; let colIndex = index"
                                    matColumnDef="{{disCol}}">
                        <ng-container>
                          <th mat-header-cell *matHeaderCellDef
                              style="font-weight: 600; font-size:14px; color: {{this.apiService.dashColor}};">{{disCol}}</th>
                          <td mat-cell *matCellDef="let element">{{element[disCol]}}
                          </td>
                        </ng-container>
                        <mat-divider [vertical]="true"></mat-divider>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsClass"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsClass;"></tr>
                    </table>

                  </div-->
                </div>
                <mat-form-field *ngSwitchDefault [ngClass]="{
                                           'form-field': field.columns !== 1,
                                           'form-field-expanded': field.columns === 1
                                        }" appearance="outline">
                  <mat-label>{{field.description}}</mat-label>
                  <input matInput placeholder="{{field.description}}">
                </mat-form-field>
              </ng-container>

            </ng-container>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'note'">
          <div style="color: {{this.apiService.dashColor}}; text-align: left"><h1>{{'labels.notes'}}: </h1>
            <div style="text-align: center">
              <ng-container *ngIf="this.notesList.length === 0">
                {{'labels.noNote'}}
              </ng-container>
              <div style="text-align: left;">
                <mat-selection-list #viste [multiple]="false">
                  <ng-container *ngIf="this.notesList.length != 0">
                    <div *ngFor="let note of this.notesList">
                      <button style="float:right; color:{{this.squareColor}};"
                              title="{{'labels.deleteNote'}}"
                              mat-button
                              aria-label="{{'labels.deleteNote'}}" (click)=deleteNote(note.id)>
                        <mat-icon>delete</mat-icon>
                      </button>
                      <mat-list-item style="width: 80%;">
                        <div>
                          <b>{{note.userCompleteName}}</b><span
                          style="margin-left: 10px;">{{note.comment}}</span><span
                          style="margin-left: 10px; color: darkgray">{{this.apiService.TransformDataFormat(note.creationDate)}}</span>
                        </div>
                      </mat-list-item>
                    </div>
                  </ng-container>
                </mat-selection-list>
              </div>
              <br>
              <hr>
              <div style="text-align: center; margin-top: 5%">
                <button mat-stroked-button (click)="addNoteTrue()">{{this.addLabel}}</button>
              </div>
              <div *ngIf="this.showAddNote" style="margin-top: 5%">
                <mat-form-field appearance="fill" style="width: 60%">
                  <mat-label>{{'labels.noteText'}}</mat-label>
                  <textarea matInput required rows="3" [(ngModel)]="this.newNote"
                            placeholder="{{'labels.insertNote'}}" type="text"></textarea>
                </mat-form-field>
                <br>
                <div style="    display: flex; justify-content: center;">
                  <button mat-stroked-button (click)="saveNewNote()">Salva</button>
                </div>
              </div>
              <br>
            </div>
          </div>
          <br>
        </ng-container>
        <ng-container *ngSwitchCase="'attachments'">

          <div style="text-align: center; color: {{this.apiService.dashColor}};">
            <div style="text-align: left; color: {{this.apiService.dashColor}};">
              <mat-selection-list #viste [multiple]="false">
                <mat-accordion>
                  <ng-container *ngFor="let file of this.bufferIdInfo2">
                    <div style="display: flex;">
                      <mat-list-item style="width: 80%;">
                        <mat-icon matListItemIcon>image</mat-icon>
                        <div matListItemTitle>{{file.name}}</div>

                      </mat-list-item>

                      <button style="float:right; color:{{this.squareColor}};"
                              title="{{'labels.deleteAttached' | translate}}"
                              mat-button
                              aria-label="{{'labels.deleteAttached' | translate}}"
                              (click)="deleteAttach(file.buffer)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                </mat-accordion>
              </mat-selection-list>
            </div>
            <br>
            <div style="text-align: center; margin-top: 5%">
              <button style="color: {{this.dashColor}};margin-right: 20px; float: right"
                      title="{{'labels.uploadFile' | translate}}"
                      (click)="fileInput2.click()"
                      mat-stroked-button>{{'labels.add' | translate}}
              </button>
              <input type="file" (change)="addAttach($event)" hidden #fileInput2
                     id="fileUpload2" name="fileUpload2"
                     multiple accept="*"/>
            </div>

          </div>


          <!--
          <div style = "text-align: center">
            <button style="float:right; color:{{this.dashColor}};" title="Aggiungi foto"
                    (click)="fileInput2.click()" mat-icon-button
                    aria-label="icon-button send">
              <mat-icon>add</mat-icon>
            </button>
            <input
              type="file" hidden
              id="fileUpload2" name="fileUpload2" #fileInput2
              accept="image/*" multiple
              (change)="selectFiles($event)"
            />
          </div>


        <div>

        </div>-->
          <br>
        </ng-container>
      </ng-container>

      <i aria-hidden="true"></i>
      <i aria-hidden="true"></i>

    </mat-card-content>
  </mat-card>
</div>

