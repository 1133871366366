<style>
  input[type=text] {
    width: 50%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    text-align: left;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    position: sticky !important;
  }
</style>
<div class="center">
  <mat-card id="style-4" class="cardcomponentMasks" style="overflow: auto">
    <input
      id="pac-input"
      class="controls"
      type="text" ngModel
      placeholder="Ricerca luogo" matInput
    />
    <div id="container" style="height: 77%">
      <div #mapContainer id="map" style="width: 80%"></div>
      <div style="height: 98%; width: 15%;
    display: flex; flex-direction: column">
        <div style="height: 100%; overflow: auto;">
          <ng-container *ngIf="this.foundPlaces.length > 0">
            <mat-selection-list [multiple]="false">
              <ng-container *ngFor="let place of this.foundPlaces">
                <mat-list-option *ngIf="place.category == 'noclient'" [value]="place"
                                 (click)="this.clickedPlaceTable(place)" style="height: 56px !important; text-align: left">
                  <ng-container>{{place.place.name}}</ng-container>
                </mat-list-option>

              </ng-container>
            </mat-selection-list>

          </ng-container>
          <ng-container *ngIf="this.foundPlaces.length == 0"><h2 style = "color: {{this.apiService.dashColor}}"> Nessun risultato trovato</h2></ng-container>
        </div>
        <h3 style = "color: {{this.apiService.dashColor}}">Non clienti trovati: {{this.countNoClient()}}/60</h3>
        <h3 style = "color: {{this.apiService.dashColor}}; margin-bottom: 0px">Potenziali clienti trovati: {{this.countPotClient()}}</h3>



      </div>
    </div>
    <div style="width: 100%; margin: auto;">
      <button mat-stroked-button class="searchbutton" (click)="this.searchProspect()" style="width: 40%;">Ricerca
        prospect nel raggio di {{this.apiService.mapRange}}Km
      </button>
      <button id="more" class="searchbutton" style="width: 40%;" mat-button [disabled]="this.firstSearch">Carica altri
        risultati
      </button>
    </div>

    <style>.mat-tab-body-content {
      height: 100%;
      overflow: hidden !important;
    }

    .mat-tab-group-dynamic-height .mat-tab-body-content {
      overflow: hidden
    }

    </style>
    <div class="cartelle">
      <mat-accordion>
        <ng-container *ngFor="let subcat of this.underCatPub">
          <ng-container *ngIf="subcat.name == this.nomeRubricaClienti || subcat.name == this.nomeRubricaPotClienti">
            <mat-expansion-panel style="width: 100%" (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title style="color: black">
                  <b>{{subcat.name}}</b>
                </mat-panel-title>

              </mat-expansion-panel-header>


              <mat-selection-list [multiple]="false">

                <!--<ng-container *ngFor="let mm of this.utentiPubblici">
                    <ng-container *ngIf="mm.Cat.value == subcat.name">
                        <mat-list-item (dblclick)="clickRubrica(mm.id.value, 'pub')"
                                       *ngIf="mm.type.value != null"
                                       class="item">
                            <div mat-line style="margin-top: 14px !important"><b
                                    style="color: {{this.dashColor}}">{{mm.value.value}}</b>
                                <p>Codice: {{mm.type.value}}</p></div>
                            <button title="Elimina" (click)="delete(mm.idC.value)"
                                    mat-icon-button
                                    color="warn" aria-label="icon-button save">
                                <mat-icon>delete</mat-icon>
                            </button>

                        </mat-list-item>
                    </ng-container>
                </ng-container>-->
                <div style="width: 100%">
                  <mat-form-field style="display: block">
                    <mat-label>
                      <mat-icon svgIcon="RicercaIcon" style="margin-right: 8px"></mat-icon>
                      Cerca
                    </mat-label>

                    <input matInput (keyup)="applyFilterPU($event, subcat.name)"
                           placeholder="Filtra" #input>

                  </mat-form-field>
                  <ng-container *ngIf = "subcat.name == this.nomeRubricaClienti">
                    <button mat-stroked-button (click)="this.addClientiToMapClick(subcat.name)" class="searchclients">
                      Aggiungi i clienti alla mappa (tabella)
                    </button>
                  </ng-container>
                  <ng-container *ngIf = "subcat.name == this.nomeRubricaPotClienti">
                    <button mat-stroked-button (click)="this.addClientiToMapClick(subcat.name)" class="searchclients">
                      Aggiungi i potenziali clienti alla mappa (tabella)
                    </button>
                    <button mat-stroked-button (click)="this.addAllClientiToMapClick(subcat.name)" class="searchclients">
                      Aggiungi tutti i potenziali clienti
                    </button>
                  </ng-container>
                  <button mat-stroked-button (click)="this.resetSearch(subcat.name)" class="searchclients">
                    Azzera
                  </button>


                  <table mat-table [dataSource]="this.dataSourcePU[subcat.name]"
                         class="mat-elevation-z8" style="width: 100%">
                    <ng-container *ngFor="let colonna of displayedColumnsRub"
                                  matColumnDef="{{colonna}}">
                      <ng-container *ngIf="colonna != 'id'">
                        <th mat-header-cell *matHeaderCellDef>
                          <ng-container *ngIf="colonna == 'Ragione_sociale'">Ragione
                            sociale
                          </ng-container>
                          <ng-container
                            *ngIf="colonna != 'Ragione_sociale'">{{colonna}}</ng-container>
                        </th>
                      </ng-container>
                      <ng-container *ngIf="colonna == 'id'">
                        <th mat-header-cell *matHeaderCellDef
                            style="background-color: white; width: 0px; z-index: -100;">
                          {{colonna}}
                        </th>
                      </ng-container>
                      <td mat-cell
                          (contextmenu)="open($event, element, element['Ragione_sociale'], element['id'], 'pub'); $event. preventDefault();"
                          *matCellDef="let element"> {{element[colonna]}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRub"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsRub;"></tr>
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No data matching the filter
                        "{{input.value}}"
                      </td>
                    </tr>
                  </table>
                  <mat-paginator class="mat-paginator-sticky" showFirstLastButtons
                                 style="float: right"
                                 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>

              </mat-selection-list>
              <!---->
            </mat-expansion-panel>

          </ng-container>
        </ng-container>

      </mat-accordion>


    </div>

  </mat-card>
</div>
<ng-template #userMenu let-user>
  <section class="user-menu">
    <div style="text-align: left; display: inline-table;"><h3 style="margin-bottom: 0px;">
      <b style="color: black; text-align: center">{{this.clickedRagSoc}}</b></h3>
    </div>
    <hr>
    <button title="Apri" (click)="clickRubrica(this.clickedId, this.clickedType)"
            mat-menu-item
            aria-label="icon-button save">
      <mat-icon>menu_open</mat-icon>
      <span>Apri</span>
    </button>
    <button title="Elimina" (click)="delete(this.clickedId)"
            mat-menu-item
            aria-label="icon-button save">
      <mat-icon>delete</mat-icon>
      <span>Elimina</span>
    </button>
  </section>
</ng-template>


<div *ngIf="false">
  <h2>Risultati</h2>

  <mat-form-field appearance="standard" style="width: 95%">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Inserire filtro... " #input style="width: 100%">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef> Città</th>
      <td mat-cell *matCellDef="let element"> {{element.city}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> Indirizzo</th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Telefono</th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>
    <ng-container matColumnDef="mail">
      <th mat-header-cell *matHeaderCellDef> Email</th>
      <td mat-cell *matCellDef="let element"> {{element.mail}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Impaginazione"></mat-paginator>

</div>
